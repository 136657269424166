import BottomSheetBase, {
    BottomSheetBackdropProps as BottomSheetBackdropBaseProps,
    BottomSheetProps as BottomSheetBaseProps,
    BottomSheetHandleProps as BottomSheetHandleBaseProps,
    BottomSheetModal as BottomSheetModalBase,
    BottomSheetModalProps as BottomSheetModalBaseProps,
} from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import { ReactElement, forwardRef, useEffect, useState } from 'react';

export interface BottomSheetHandleProps extends BottomSheetHandleBaseProps {
    handleKeyUpdate: () => void;
}

export interface BottomSheetBackdropProps extends BottomSheetBackdropBaseProps {
    handleKeyUpdate: () => void;
}

interface BottomSheetCommonProps {
    handleComponent?: ((props: BottomSheetHandleProps) => ReactElement<any, any>) | null;
    backdropComponent?: ((props: BottomSheetBackdropProps) => ReactElement<any, any>) | null;
}

export interface BottomSheetProps
    extends Omit<BottomSheetBaseProps, 'handleComponent' | 'backdropComponent'>,
        BottomSheetCommonProps {}

export interface BottomSheetModalProps
    extends Omit<BottomSheetModalBaseProps, 'handleComponent' | 'backdropComponent'>,
        BottomSheetCommonProps {}

export interface BottomSheetModal extends BottomSheetModalBase {}

/*
    This component adds a new function handleKeyUpdate which remounts the component.
    The function is accessable as one of the arguments of renderHandle and renderBackdrop.
    Every time we close a bottomSheet it should be remounted to avoid an error related to
    the inability to reopen the action sheet.
*/

const useBottomSheetProps = (
    handleComponent: BottomSheetCommonProps['handleComponent'],
    backdropComponent: BottomSheetCommonProps['backdropComponent'],
) => {
    const [modalKey, setModalKey] = useState(0);
    const navigation = useNavigation();

    const handleKeyUpdate = () => {
        setModalKey((key) => key + 1);
    };

    useEffect(() => {
        const unsubscribe = navigation.addListener('state', () => {
            handleKeyUpdate();
        });

        return unsubscribe;
    }, [navigation]);

    const renderHandle = handleComponent
        ? (handleProps: BottomSheetHandleBaseProps) => handleComponent({ ...handleProps, handleKeyUpdate })
        : undefined;

    const renderBackdrop = backdropComponent
        ? (backdropProps: BottomSheetBackdropBaseProps) => backdropComponent({ ...backdropProps, handleKeyUpdate })
        : undefined;

    return {
        modalKey,
        renderHandle,
        renderBackdrop,
        handleKeyUpdate,
    };
};

export const BottomSheetModal = forwardRef<BottomSheetModalBase, BottomSheetModalProps>(
    ({ children, handleComponent, backdropComponent, onDismiss, ...props }, ref) => {
        const { modalKey, renderHandle, renderBackdrop, handleKeyUpdate } = useBottomSheetProps(
            handleComponent,
            backdropComponent,
        );

        return (
            <BottomSheetModalBase
                key={modalKey}
                ref={ref}
                {...props}
                handleComponent={renderHandle}
                backdropComponent={renderBackdrop}
                onDismiss={() => {
                    onDismiss?.();
                    handleKeyUpdate();
                }}
            >
                {children}
            </BottomSheetModalBase>
        );
    },
);

export const BottomSheet = forwardRef<BottomSheetBase, BottomSheetProps>(
    ({ children, handleComponent, backdropComponent, onClose, ...props }, ref) => {
        const { modalKey, renderHandle, renderBackdrop, handleKeyUpdate } = useBottomSheetProps(
            handleComponent,
            backdropComponent,
        );

        return (
            <BottomSheetBase
                key={modalKey}
                ref={ref}
                {...props}
                handleComponent={renderHandle}
                backdropComponent={renderBackdrop}
                onClose={() => {
                    onClose?.();
                    handleKeyUpdate();
                }}
            >
                {children}
            </BottomSheetBase>
        );
    },
);
