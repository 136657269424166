import { TrainingItemStatus } from '@pg/backend';
import { Pressable, Text, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { mixins } from '~/theme';

import { TrainingAxisCellProps } from './TrainingAxisCell.types';

export const Container = styled(View)`
    flex: 1;
    flex-direction: row;
    padding: 0 16px;
    flex-basis: auto;
`;

export const AxisContainer = styled(View)`
    position: relative;
    margin-right: 12px;
    height: 62px;
    width: 12px;
`;

const dotStylesMap: Record<TrainingItemStatus, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    FINISHED: css`
        border-width: 3px;
        border-color: ${({ theme }) => theme.icon.disabled};
    `,
    IN_PROGRESS: css`
        border-width: 6px;
        border-color: ${({ theme }) => theme.text.interactive};
    `,
    NOT_STARTED: css`
        border-width: 6px;
        border-color: ${({ theme }) => theme.icon.tertiary};
    `,
};
export const Dot = styled(View)<Pick<TrainingAxisCellProps, 'status'>>`
    position: absolute;
    top: 12px;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    ${({ status }) => dotStylesMap[status]};
`;

export const Axis = styled(View)`
    position: absolute;
    top: 34px;
    left: 5.5px;
    height: 28px;
    border-width: 1.5px;
    border-style: dashed;
    border-color: ${({ theme }) => theme.palette.opacity['32'].rgba};
`;

export const AxisMax = styled(View)`
    position: absolute;
    top: 34px;
    left: 7px;
    height: 28px;
    width: 1.5px;
    background-color: ${({ theme }) => theme.fill.level1};
`;

export const ContentContainer = styled(Pressable)`
    flex: 1;
    padding: 7px 12px;
    position: relative;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
`;

export const Background = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.opacity['08'].rgba};
`;

export const TextContainer = styled(View)`
    flex: 1;
    margin-right: 8px;
`;

export const Title = styled(Text)<Pick<TrainingAxisCellProps, 'status'>>`
    ${mixins.typography.ui.medium16};
    color: ${({ theme, status }) =>
        status === TrainingItemStatus.FINISHED ? theme.text.tertiary : theme.text.primary};
    margin-bottom: 2px;
`;

export const SubtitleContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`;

export const Check = styled(View)`
    margin-right: 6px;
`;

export const Subtitle = styled(Text)<Pick<TrainingAxisCellProps, 'status'>>`
    ${mixins.typography.ui.regular12};
    color: ${({ theme, status }) =>
        status === TrainingItemStatus.FINISHED ? theme.text.tertiary : theme.text.secondary};
`;

export const ViewButton = styled(View)`
    margin-right: 8px;
`;
