import { Palette } from './types';

export const darkPalette: Palette = {
    green: {
        '00': '#132E1B',
        '05': '#1B4125',
        '10': '#225330',
        '15': '#2A663B',
        '20': '#317846',
        '30': '#398B50',
        '40': '#4CB96B',
        '50': '#67C481',
        '60': '#79CB90',
        '70': '#8BD29F',
        '80': '#9DD9AE',
        '90': '#AEE0BC',
        '100': '#C9EAD3',
    },
    neutral: {
        '00': '#0C0B0B',
        '05': '#181717',
        '10': '#242323',
        '15': '#303030',
        '20': '#3D3C3C',
        '30': '#555454',
        '40': '#6D6D6D',
        '50': '#868585',
        '60': '#9E9D9D',
        '70': '#B6B6B6',
        '80': '#CECECE',
        '90': '#E7E7E7',
        '100': '#FFFFFF',
    },
    red: {
        '00': '#330A06',
        '05': '#4D0F09',
        '10': '#66140C',
        '15': '#801A10',
        '20': '#991F13',
        '30': '#B32416',
        '40': '#CC2919',
        '50': '#FF331F',
        '60': '#FF4735',
        '70': '#FF5C4C',
        '80': '#FF7A6D',
        '90': '#FFA39A',
        '100': '#FFC2BC',
    },
    violet: {
        '00': '#1A1A40',
        '05': '#242459',
        '10': '#242459',
        '15': '#38388C',
        '20': '#4747B3',
        '30': '#5252CC',
        '40': '#5C5CE6',
        '50': '#6666FF',
        '60': '#7575FF',
        '70': '#8585FF',
        '80': '#ABABFF',
        '90': '#C9C9FF',
        '100': '#E8E8FF',
    },
    yellow: {
        '00': '#332B00',
        '05': '#594A00',
        '10': '#806A00',
        '15': '#9F8400',
        '20': '#BF9E02',
        '30': '#D7B30D',
        '40': '#E4BF19',
        '50': '#ECC727',
        '60': '#F2CE39',
        '70': '#F6D75F',
        '80': '#F6D75F',
        '90': '#FDEDBB',
        '100': '#FFF7E0',
    },
    opacity: {
        '04': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.04)' },
        '06': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.06)' },
        '08': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.08)' },
        '12': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.12)' },
        '16': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.16)' },
        '20': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.2)' },
        '24': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.24)' },
        '32': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.32)' },
        '40': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.40)' },
        '48': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.48)' },
        '56': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.56)' },
        '64': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.64)' },
        '72': { hex: '#b6b6b6', rgba: 'rgba(182,182,182,0.72)' },
        '84': { hex: '#e7e7e7', rgba: 'rgba(231,231,231,0.84)' },
        '92': { hex: '#e7e7e7', rgba: 'rgba(231,231,231,0.92)' },
        '96': { hex: '#e7e7e7', rgba: 'rgba(231,231,231,0.96)' },
        dark16: { hex: '#181717', rgba: 'rgba(24,23,23,0.16)' },
        dark32: { hex: '#181717', rgba: 'rgba(24,23,23,0.32)' },
        violet20: { hex: '#8585ff33', rgba: 'rgba(133, 133, 255, 0.2)' },
        bg80: { hex: '#0C0B0B08', rgba: 'rgba(12, 11, 11, 0.8)' },
    },
};
