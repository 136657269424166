import { MeasureType } from './MeasurementsScreen.types';

export const measurementsTypes: Record<MeasureType, { label: string; value: MeasureType; unit: 'kg' | 'cm' }> = {
    weight: { label: 'Waga', value: 'weight', unit: 'kg' },
    waist: { label: 'Pas', value: 'waist', unit: 'cm' },
    hips: { label: 'Biodra', value: 'hips', unit: 'cm' },
    arm: { label: 'Ramię', value: 'arm', unit: 'cm' },
    thigh: { label: 'Udo', value: 'thigh', unit: 'cm' },
    calf: { label: 'Łydka', value: 'calf', unit: 'cm' },
    chest: { label: 'Klatka piersiowa', value: 'chest', unit: 'cm' },
};

export const measurementsList = [
    measurementsTypes.weight,
    measurementsTypes.waist,
    measurementsTypes.hips,
    measurementsTypes.arm,
    measurementsTypes.thigh,
    measurementsTypes.chest,
    measurementsTypes.calf,
];
