export type Breakpoint = 'mobile' | 'tablet' | 'laptop' | 'desktop' | 'desktopLarge';

export type Breakpoints = Record<Breakpoint, number>;

export const breakpoints: Breakpoints = {
    mobile: 360,
    tablet: 768,
    laptop: 1024,
    desktop: 1304,
    desktopLarge: 1920,
};
