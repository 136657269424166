import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
`;

export const Name = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
`;

export const Amount = styled(Text)`
    margin-left: 8px;
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
`;
