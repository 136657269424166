import { ChatType } from '@pg/backend';
import { useNavigation } from '@react-navigation/native';

import { ChatTopic } from '../chatTopic';
import * as S from './ChatSelectionContent.native.styled';
import { ChatNavigationProp } from './ChatSelectionContent.types';

const mockData = [ChatType.TRAINING, ChatType.DIET, ChatType.GENERAL];

export const ChatSelectionContent = () => {
    const navigation = useNavigation<ChatNavigationProp>();

    const handleTopicSelection = (chatType: ChatType) => {
        navigation.navigate('ChatScreen', { chatType });
    };
    return (
        <S.Container>
            <S.Title>O czym chcesz porozmawiać?</S.Title>
            {mockData.map((chatType, index) => (
                <ChatTopic chatType={chatType} key={index} onPress={() => handleTopicSelection(chatType)} />
            ))}
            <S.Disclaimer>Odpowiadamy w ciągu 24h w dni robocze</S.Disclaimer>
        </S.Container>
    );
};
