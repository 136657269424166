import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { StandardNavigation, StandardNavigationProps } from '~/components/topNavigation';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { ExerciseContextProvider } from '~/enhancers/exercise.context';
import { ExercisesAtlasScreen } from '~/screens/exercisesAtlas';
import { SearchExercisesScreen } from '~/screens/exercisesAtlas/searchExercises';

import { ExercisesAtlasStackParamsList } from './ExercisesAtlasStackNavigation.types';

const Stack = createNativeStackNavigator<ExercisesAtlasStackParamsList>();

const ExercisesAtlasHeader = (props: NativeStackHeaderProps & Pick<StandardNavigationProps, 'rightIcon'>) => (
    <StandardNavigation
        title="Atlas ćwiczeń"
        onRightPress={() =>
            props.navigation.navigate('MoreStack', {
                screen: 'ExercisesAtlasStack',
                params: { screen: 'SearchExercises' },
            })
        }
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);

const SearchExercisesHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        onBackPress={() =>
            props.navigation.navigate('MoreStack', {
                screen: 'ExercisesAtlasStack',
                params: { screen: 'ExercisesAtlas' },
            })
        }
        {...props}
    />
);

export const ExercisesAtlasStackNavigation = () => {
    const { features } = useAppFeatures();

    return (
        <ExerciseContextProvider>
            <Stack.Navigator screenOptions={{ header: () => null }}>
                <Stack.Screen
                    name="ExercisesAtlas"
                    component={ExercisesAtlasScreen}
                    options={{
                        header: (props) =>
                            ExercisesAtlasHeader({
                                ...props,
                                rightIcon: features.exercisesAtlas ? 'search' : undefined,
                            }),
                    }}
                />
                <Stack.Screen
                    name="SearchExercises"
                    component={SearchExercisesScreen}
                    options={{
                        header: SearchExercisesHeader,
                    }}
                />
            </Stack.Navigator>
        </ExerciseContextProvider>
    );
};
