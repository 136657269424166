import { useNavigation } from '@react-navigation/native';
import { format } from 'date-fns';
import { ActivityButton } from '~/components/activityButton';

import { DashboardNavigationProp } from '../../DashboardScreen.types';
import { DayBar } from './DayBar';
import { backgroundColors, borderColors, calendarData } from './WeeklyActivity.const';
import * as S from './WeeklyActivity.styled';
import { tmpData } from './tmpData';

export const WeeklyActivity = () => {
    const navigation = useNavigation<DashboardNavigationProp>();

    const handleTrainingHistoryPress = () => {
        navigation.navigate('DashboardStack', { screen: 'ActivityHistory' });
    };

    const handleAddPress = () => {
        const today = format(new Date(), 'yyyy-MM-dd');

        navigation.navigate('DashboardStack', {
            screen: 'ActivityDetails',
            params: { date: today, sheet: true },
        });
    };

    return (
        <S.Container>
            <S.BorderGradient colors={borderColors} />
            <S.BackgroundGradient colors={backgroundColors} />
            <S.Header>
                <S.Title>Aktywność w tygodniu</S.Title>
                <S.ActionContainer>
                    <S.ActivityButton icon="training history" onPress={handleTrainingHistoryPress} />
                    <ActivityButton icon="add" onPress={handleAddPress} />
                </S.ActionContainer>
            </S.Header>
            <S.CalendarContainer>
                {calendarData.map((data, index) => (
                    <DayBar key={data.id} {...data} activities={tmpData[index]} />
                ))}
            </S.CalendarContainer>
        </S.Container>
    );
};
