import { FC } from 'react';
import { Pressable, ScrollView } from 'react-native';
import BasePopup from 'reactjs-popup';
import { useTheme } from 'styled-components/native';
import { PlanMenuItem } from '~/components/planMenuItem';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './PlanSelectionModal.styled';

type DataItem = {
    id: string;
    selected: boolean;
    title: string;
    onPress: () => void;
};

interface PlanSelectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    triggerComponent: JSX.Element;
    data: DataItem[];
}

// TODO: Change name to popup and move to popup folder
export const PlanSelectionModal: FC<PlanSelectionModalProps> = ({ isOpen, data, triggerComponent, onClose }) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    return (
        <BasePopup open={isOpen} trigger={triggerComponent} onClose={onClose} arrow={false}>
            <S.Container style={S.BaseStyle.shadow}>
                <S.Header>
                    <S.Spacer />
                    <S.Title>Wybierz plan</S.Title>

                    <Pressable {...pressableProps} onPress={onClose}>
                        <PressableIcon
                            pressedColor={theme.icon.primary}
                            pressableAnimationProgress={pressableAnimationProgress}
                            name="close"
                            size={20}
                            color={theme.icon.tertiary}
                        />
                    </Pressable>
                </S.Header>
                <ScrollView showsVerticalScrollIndicator={false}>
                    {data.map(({ title, ...props }, index) => (
                        <PlanMenuItem key={props.id} index={data.length - index} {...props}>
                            {title}
                        </PlanMenuItem>
                    ))}
                </ScrollView>
            </S.Container>
        </BasePopup>
    );
};
