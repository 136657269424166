import { FC, useMemo } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Logo } from '~/components/logo';

import * as S from './AuthLayout.styled';
import { AuthLayoutProps } from './AuthLayout.types';

export const AuthLayout: FC<AuthLayoutProps> = ({ children, style, imageSrc, webImagePosition, nativeImageSize }) => {
    const inset = useSafeAreaInsets();
    const theme = useTheme();

    const contentContainerStyle: StyleProp<ViewStyle> = useMemo(() => {
        if (theme.isWebDesktop) {
            return {
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100%',
            };
        }

        return undefined;
    }, [theme.isWebDesktop]);

    return (
        <S.Container inset={inset} style={style}>
            <S.ImageContainer nativeImageSize={nativeImageSize}>
                {imageSrc ? (
                    <S.Image
                        source={imageSrc}
                        webImagePosition={webImagePosition}
                        nativeImageSize={nativeImageSize}
                        resizeMode={theme.isWebDesktop && webImagePosition === 'full' ? 'cover' : 'contain'}
                    />
                ) : (
                    <S.LogoContainer>
                        <Logo size={80} />
                    </S.LogoContainer>
                )}
            </S.ImageContainer>
            <S.Content
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={contentContainerStyle}
                automaticallyAdjustKeyboardInsets
            >
                <S.Inner>{children}</S.Inner>
            </S.Content>
        </S.Container>
    );
};
