import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { ButtonVariant } from '~/components/button/Button.types';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface ButtonAnimationsRenderPropValue {
    backgroundColor: string;
}

export interface ButtonAnimationsProps extends PressableAnimationsProps<ButtonAnimationsRenderPropValue> {
    variant: ButtonVariant;
    disabled?: boolean;
}

export const ButtonBackgroundAnimation = ({ progress, variant, children, disabled }: ButtonAnimationsProps) => {
    const theme = useTheme();

    const backgroundProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const backgroundMap: Record<ButtonVariant, { default: string; hovered: string; pressed: string }> = {
        primary: {
            default: theme.interactive.primaryDefault,
            hovered: theme.interactive.primaryHover,
            pressed: theme.interactive.primaryPress,
        },
        secondary: {
            default: 'transparent',
            hovered: theme.interactive.opacityHover,
            pressed: theme.interactive.opacityPress,
        },
        'on-error': {
            default: theme.palette.red['00'],
            hovered: theme.palette.red['05'],
            pressed: theme.palette.red['10'],
        },
        'on-informative': {
            default: theme.palette.violet['00'],
            hovered: theme.palette.violet['05'],
            pressed: theme.palette.violet['10'],
        },
        'on-background': {
            default: theme.palette.opacity.dark32.rgba,
            hovered: theme.palette.opacity.dark16.rgba,
            pressed: theme.palette.opacity.dark16.rgba,
        },
        error: {
            default: theme.interactive.critical,
            hovered: theme.palette.red[70],
            pressed: theme.palette.red[40],
        },
        positive: {
            default: theme.interactive.positive,
            hovered: theme.interactive.positive,
            pressed: theme.interactive.positive,
        },
    };

    const currentBackground = backgroundMap[variant as ButtonVariant];

    const animatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: disabled
                ? theme.border['12']
                : interpolateColor(
                      backgroundProgress.value,
                      [0, 1, 2],
                      [currentBackground.default, currentBackground.hovered, currentBackground.pressed],
                  ),
        };
    }, [disabled, variant]);

    return children(animatedStyle);
};
