import { useEffect, useState } from 'react';
import { EmitterEvent, EmitterEventMappedType } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import { ShippingListDateRange } from './ShoppingListScreen.types';

export const useShoppingListState = () => {
    const [range, setRange] = useState<ShippingListDateRange>({ startingDay: null, endingDay: null });
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleOnChange = (_range: ShippingListDateRange) => {
        handleClose();
        setRange(_range);
    };
    const [screen, setScreen] = useState<'categories' | 'recipes'>('categories');

    const handleEmitter = (e: EmitterEventMappedType[EmitterEvent.shoppingListTabChange]) => {
        setScreen(e.value);
    };

    useEffect(() => {
        Emitter.on(EmitterEvent.shoppingListTabChange, handleEmitter);
        return () => {
            Emitter.off(EmitterEvent.shoppingListTabChange, handleEmitter);
        };
    });

    return {
        range,
        isOpen,
        handleOpen,
        handleOnChange,
        screen,
        handleClose,
    };
};
