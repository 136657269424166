import React, { FC } from 'react';
import ContentLoader, { IContentLoaderProps, Rect } from 'react-content-loader/native';
import { useTheme } from 'styled-components/native';

export interface SkeletonProps extends IContentLoaderProps {
    width?: string;
    height?: string;
    speed?: number;
    backgroundColor?: string;
    foregroundColor?: string;
    rx?: number;
    children?: React.ReactNode;
}

export const Skeleton: FC<SkeletonProps> = ({
    width = '100%',
    height = '100%',
    backgroundColor,
    foregroundColor,
    children,
    rx = 6,
    ...props
}) => {
    const theme = useTheme();

    return (
        <ContentLoader
            width={width}
            height={height}
            speed={1}
            backgroundColor={backgroundColor || theme.fill.level3}
            foregroundColor={foregroundColor || theme.fill.level4}
            {...props}
        >
            {children ? children : <Rect width="100%" height="100%" rx={rx} />}
        </ContentLoader>
    );
};
