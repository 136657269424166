import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface MobileMenuTileAnimationsRenderPropValue {
    backgroundColor: string;
}

export interface MobileMenuTileAnimationsProps
    extends PressableAnimationsProps<MobileMenuTileAnimationsRenderPropValue> {}

export const MobileMenuTileAnimation = ({ progress, children }: MobileMenuTileAnimationsProps) => {
    const theme = useTheme();

    const backgroundProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const currentBackground: Record<string, string> = {
        default: 'transparent',
        hovered: theme.palette.opacity['12'].rgba,
        pressed: theme.palette.opacity['12'].rgba,
    };

    const animatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(
                backgroundProgress.value,
                [0, 1, 2],
                [currentBackground.default, currentBackground.hovered, currentBackground.pressed],
            ),
        };
    }, []);

    return children(animatedStyle);
};
