import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { TileGradientButton } from '~/components/tileGradientButton';

export const Content = styled(View)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ButtonsContainer = styled(View)`
    padding: 0 20px;
    margin-bottom: 24px;
    flex-direction: row;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-top: 16px;
        `}
`;

export const Button = styled(TileGradientButton)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
    max-width: ${({ theme }) => (theme.isWebDesktop ? 264 : 190)}px;
`;

export const ChartContainer = styled(View)`
    margin-bottom: 20px;
`;

export const MeasurementsTableWrapper = styled(View)`
    display: flex;
    margin: 0 0 24px;
    padding: 0 20px;
    flex: 1;
`;

export const TableWrapper = styled(View)<{ empty?: boolean }>`
    width: 100%;

    ${({ empty }) =>
        empty &&
        css`
            flex: 1;
            min-height: 260px;
        `}
`;

export const PaginationWrapper = styled(View)`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 24px;
`;

export const SidebarWrapper = styled(View)`
    padding: 0 20px;
`;
