import { LazyMeasurement } from '@pg/backend';
import { useState } from 'react';
import { useTheme } from 'styled-components/native';

interface UsePaginationProps {
    data?: LazyMeasurement[];
}

const SIZE = 10;

export const useMeasurementsPagination = ({ data }: UsePaginationProps) => {
    const { isWebDesktop } = useTheme();
    const [currentPage, setCurrentPage] = useState(1);
    const measurements = data || [];
    const totalPages = Math.ceil(measurements.length / SIZE);
    const paginatedData = isWebDesktop
        ? measurements.slice((currentPage - 1) * SIZE, currentPage * SIZE)
        : measurements;

    return {
        paginatedData,
        pagination: isWebDesktop
            ? {
                  page: currentPage,
                  totalPages,
                  onPageChange: setCurrentPage,
              }
            : undefined,
    };
};
