import { Pressable, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { Loader } from '~/components/loader';

export const Footer = styled(View)`
    padding: ${({ theme }) => (theme.isWebDesktop ? '24px 20px 48px' : '24px 16px 48px')};
`;

export const PrimaryButtonContainer = styled(View)`
    margin-bottom: 16px;
`;

export const Container = styled(View)`
    flex: 1;
    background: ${({ theme }) => theme.fill.level2};
    position: relative;

    ${({ theme }) =>
        theme.isWeb &&
        css`
            overflow: auto;
        `}

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 100%;
            border-bottom-left-radius: 12px;
            border-top-left-radius: 12px;
        `}
`;

export const ExerciseHeaderContainer = styled(View)`
    padding: ${({ theme }) => (theme.isWebDesktop ? '24px 20px 16px' : '24px 16px 16px')};
    background: ${({ theme }) => theme.fill.level2};
`;

export const ExerciseSubHeaderContainer = styled(View)`
    padding-top: 24px;
`;

export const Wrapper = styled(View)`
    align-items: flex-start;
    max-width: ${({ theme }) => (theme.isWebDesktop ? '650px' : '100%')};
    width: 100%;
`;

export const DHeaderWrapper = styled(View)`
    flex: 1;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: sticky;
    z-index: 1;
    padding-right: 32px;
    background: ${({ theme }) => theme.fill.level2};
    min-height: 71px;
`;

export const DButtonWrapper = styled(View)`
    gap: 16px;
    flex-direction: row;
`;

export const DMuteButtonWrapper = styled(Pressable)`
    align-self: center;
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.fill.level2};
    z-index: 999;
`;

export const WebSyncLoader = styled(Loader)`
    position: relative;
    top: 5px;
    align-self: flex-start;
`;
