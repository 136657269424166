import { Text, TextInput, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    display: flex;
`;

export const InputContainer = styled(View)`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 8px;
`;

export const Input = styled(TextInput)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
`;

export const ErrorText = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.critical};
    margin-left: 10px;
    margin-bottom: 8px;
`;
