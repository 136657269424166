import React from 'react';
import { ComingSoon } from '~/components/comingSoon';

export const MeasurementsComingSoon = () => (
    <ComingSoon
        title="Wkrótce w tym miejscu:"
        features={['Dodawanie pomiarów swojego ciała', 'Metamorfoza', 'Wykres prezentujący postępy', 'Tabela pomiarów']}
        imageSrc={require('~/assets/images/measurements_coming_soon.png')}
    />
);
