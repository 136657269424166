import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled(View)`
    flex: 1;
    flex-direction: row;
`;

export const Children = styled(View)`
    flex: 1;
    background-color: ${({ theme }) => theme.fill.level1};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-left: -10px;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-left-style: solid;
            border-left-width: 1px;
            border-left-color: ${theme.palette.opacity[12].rgba};
            shadow-color: #000;
            shadow-offset: -12px 0px;
            shadow-opacity: 0.5;
            shadow-radius: 32px;
        `}
`;
