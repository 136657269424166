import { useEffect, useState } from 'react';

// mm:ss
const formatTime = (time: number): string => {
    const minutes: number = Math.floor(time / 60);
    const seconds: number = time % 60;

    const formattedMinutes: string = String(minutes).padStart(2, '0');
    const formattedSeconds: string = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
};

let timer: NodeJS.Timer;

export const useCountdownTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState<number>(0);

    const reset = () => {
        clearInterval(timer);
        setTimeRemaining(0);
    };

    useEffect(() => {
        if (timeRemaining) {
            timer = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [timeRemaining]);

    return {
        counting: timeRemaining > 0,
        timeRemaining: formatTime(timeRemaining),
        start: (seconds = 60) => setTimeRemaining(seconds),
        reset,
    };
};
