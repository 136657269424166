import { FC, ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/loader';
import { Toasts } from '~/components/toasts';
import { ChatLayoutStyledProps } from '~/layouts/native/chatLayout/ChatLayout.types';

import * as S from './ChatLayout.styled';

export interface ChatLayoutProps extends ChatLayoutStyledProps {
    children: ReactNode;
    style?: ViewStyle;
    isLoading?: boolean;
    isLinearGradientBg?: boolean;
}

export const ChatLayout: FC<ChatLayoutProps> = ({ children, fullWidth, style, isLoading, isLinearGradientBg }) => {
    const inset = useSafeAreaInsets();
    const theme = useTheme();

    const renderContent = () => {
        if (isLinearGradientBg) {
            return (
                <S.LinearGradient>
                    <S.ContentContainer fullWidth={fullWidth}>{children}</S.ContentContainer>
                </S.LinearGradient>
            );
        }
        return <S.ContentContainer fullWidth={fullWidth}>{children}</S.ContentContainer>;
    };

    return (
        <S.ContentWrapper>
            <S.Container inset={inset} style={style}>
                {isLoading && (
                    <S.LoaderWrapper>
                        <Loader size="xl" color={theme.icon.interactive} />
                    </S.LoaderWrapper>
                )}
                {renderContent()}
            </S.Container>

            <Toasts />
        </S.ContentWrapper>
    );
};
