import React from 'react';
import { Skeleton } from '~/components/skeleton';
import { UnitSuffix, UnitSuffixProps } from '~/components/unitSuffix';

import * as S from './MacroTile.styled';

interface MacroTileProps {
    value: string | number;
    unit?: UnitSuffixProps['unit'];
    label: string;
}

export const MacroTile = ({ value, unit, label }: MacroTileProps) => {
    return (
        <S.Container>
            <S.Value>{unit ? <UnitSuffix unit={unit}>{value}</UnitSuffix> : value}</S.Value>
            <S.Label>{label}</S.Label>
        </S.Container>
    );
};

export const MacroTileSkeleton = () => {
    return (
        <S.Container>
            <S.Value>
                <Skeleton width="40" height="16" />
            </S.Value>
            <S.Label>
                <Skeleton width="60" height="12" />
            </S.Label>
        </S.Container>
    );
};

MacroTile.Skeleton = React.memo(MacroTileSkeleton);
