import { FC } from 'react';
import LogoSVG from '~/assets/images/logo.svg';

interface LogoProps {
    size?: number;
}

export const Logo: FC<LogoProps> = ({ size = 48 }) => {
    return <LogoSVG width={size} height={size} />;
};
