import { View } from 'react-native';
import styled from 'styled-components/native';

import { ContainerProps } from './BottomTabWrapper.types';

export const Container = styled(View)<ContainerProps>`
    width: 100%;
    background-color: ${({ theme }) => theme.fill.background};
    padding: 0 4px;
    height: ${({ inset, theme }) => (theme.isWebMobile ? 48 : inset.bottom + 48)}px;
    flex-direction: row;
    justify-content: center;
    display: ${({ theme }) => (theme.isWebDesktop ? 'none' : 'flex')};
`;
