import { loadAsync } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { FC, ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { FullScreenLoader } from '~/components/fullScreenLoader';
import { useShowAppLoader } from '~/enhancers/appContext/hooks/useShowAppLoader';

export interface AppContextType {
    isAppReady: boolean;
}

export const AppContext = createContext<AppContextType>({
    isAppReady: false,
});

export interface AppContextProviderProps {
    children: ReactNode;
}

export const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
    const showAppLoader = useShowAppLoader();
    const [isAppReady, setIsAppReady] = useState(false);
    const [isAppPrepared, setIsAppPrepared] = useState(false);
    const [isContainerMeasured, setIsContainerMeasured] = useState(false);

    useEffect(() => {
        async function prepare() {
            try {
                await loadAsync({
                    'Basier-Bold': require('../assets/fonts/BasierCircle-Bold.ttf'),
                    'Basier-SemiBold': require('../assets/fonts/BasierCircle-SemiBold.ttf'),
                    'Basier-Medium': require('../assets/fonts/BasierCircle-Medium.ttf'),
                    'Basier-Regular': require('../assets/fonts/BasierCircle-Regular.ttf'),
                });
            } catch (e) {
                console.warn(e);
            } finally {
                setIsAppPrepared(true);
            }
        }

        void prepare();
    }, []);

    useEffect(() => {
        if (isAppPrepared && isContainerMeasured) {
            setIsAppReady(true);
            setTimeout(() => {
                void SplashScreen.hideAsync();
            });
        }
    }, [isAppPrepared, isContainerMeasured]);

    const handleLayoutRootView = useCallback(async () => {
        setIsContainerMeasured(true);
    }, []);

    const value = useMemo(
        () => ({
            isAppReady,
        }),
        [isAppReady],
    );

    if (!isAppPrepared) {
        return null;
    }

    return (
        <AppContext.Provider value={value}>
            <GestureHandlerRootView style={{ flex: 1 }} onLayout={handleLayoutRootView}>
                {!showAppLoader && children}
                <FullScreenLoader visible={showAppLoader} />
            </GestureHandlerRootView>
        </AppContext.Provider>
    );
};
