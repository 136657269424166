import { View } from 'react-native';
import styled from 'styled-components/native';
import { TileGradientButton } from '~/components/tileGradientButton';

export const Container = styled(View)`
    padding: 16px;
    width: 100%;
    flex: 1;
    display: flex;
`;

export const NutritionDetailsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`;

export const NutritionDetailsWrapper = styled(View)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;

export const ActionsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`;

export const Button = styled(TileGradientButton)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;

export const EmptyState = styled(View)`
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
