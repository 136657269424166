import { Exercise, ExerciseData } from '@pg/backend';
import React, { FC, useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { TrainingScreenFinishedExercise, TrainingScreenNextExercise } from '~/screens/training/TrainingScreen.types';
import { SeriesTableRowForm } from '~/screens/training/components/seriesTableRowForm';

import * as S from './SeriesTable.styled';

export interface SeriesTableProps {
    trainingExerciseId: string;
    isSuperSet?: boolean;
    exerciseData: ExerciseData;
    exercise: Exercise;
    nextExercise?: TrainingScreenNextExercise;
    prevExerciseForSuperset: TrainingScreenNextExercise | null;
    finishedSeries: TrainingScreenFinishedExercise['series'];
    finishedPreviousCycleExercises: TrainingScreenFinishedExercise['series'];
}

export const SeriesTable: FC<SeriesTableProps> = ({
    trainingExerciseId,
    exerciseData,
    exercise,
    nextExercise,
    isSuperSet,
    prevExerciseForSuperset,
    finishedSeries,
    finishedPreviousCycleExercises,
}) => {
    const theme = useTheme();
    const series = useMemo(() => new Array(exerciseData?.series ?? 0).fill(exerciseData), [exerciseData]);

    return (
        <S.SeriesTable>
            <S.SeriesTableHeader>
                <S.SeriesNumberCell>S</S.SeriesNumberCell>
                <S.SeriesInputHeaderCell>Powtórzenia</S.SeriesInputHeaderCell>
                <S.SeriesInputHeaderCell>Ciężar</S.SeriesInputHeaderCell>
                <S.SeriesCheckboxCell>
                    <Icon name="check-list" size={14} color={theme.icon.tertiary} />
                </S.SeriesCheckboxCell>
            </S.SeriesTableHeader>

            {series.map((seriesItem, index) => (
                <SeriesTableRowForm
                    trainingExerciseId={trainingExerciseId}
                    key={`${seriesItem.id}-${index}`}
                    index={index}
                    data={seriesItem}
                    maxSeries={series.length}
                    exercise={exercise}
                    last={series.length - 1 === index}
                    nextExercise={nextExercise}
                    prevExerciseForSuperset={prevExerciseForSuperset}
                    isSuperSet={isSuperSet}
                    finishedData={finishedSeries?.[index]}
                    previousCycleFinishedData={finishedPreviousCycleExercises?.[index]}
                />
            ))}
        </S.SeriesTable>
    );
};
