import { Text, View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    padding: 0 16px;
    width: 100%;
    flex: 1;
    display: flex;
`;

export const HeaderContainer = styled(View)`
    padding: 8px 0;
`;

export const RichContentContainer = styled(View)`
    flex: 1;
    padding-bottom: 16px;
    // TODO: remove this border
    border: 1px solid ${({ theme }) => theme.fill.level2};
`;

// TODO: remove this
export const TmpText = styled(Text)`
    color: white;
`;
