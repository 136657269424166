import { Pressable, Text as TextBase, View } from 'react-native';
import styled from 'styled-components/native';
import { PlanMenuItemProps } from '~/components/planMenuItem/PlanMenuItem';
import { mixins } from '~/theme/mixins';
import { HoverableProps } from '~/types/hoverable';

interface ButtonHoverableStyledProps extends HoverableProps {}

export const Container = styled(Pressable)<ButtonHoverableStyledProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.opacity['12'].rgba};
    border-bottom-width: 1px;
`;

export const TextContent = styled(View)`
    flex: 1;
    margin-left: 14px;
`;

export const Text = styled(TextBase)<Pick<PlanMenuItemProps, 'selected'>>`
    ${mixins.typography.ui.medium14};
    color: ${({ theme, selected }) => (selected ? theme.text.positive : theme.text.secondary)};
    margin-right: 20px;
`;
