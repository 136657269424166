import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 110px;
    padding: 16px 16px 48px;
`;

export const IconContainer = styled(View)`
    margin-right: 12px;
`;
export const TextContainer = styled(Text)`
    flex: 1;
    ${mixins.typography.h.bold32};
    color: ${({ theme }) => theme.text.primary};
`;

export const ButtonContainer = styled(View)`
    border-radius: 8px;
    background-color: ${({ theme }) => theme.fill.background};
`;
