import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled(View)`
    display: flex;
`;

export const HeaderContainer = styled(View)`
    margin: 0 16px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin: 0 20px;
        `}
`;

export const ChartWrapper = styled(View)`
    margin: 16px 16px 0
    align-items: center;
    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin: 16px 20px 0;
        `}
`;

export const TabsWrapper = styled(View)`
    width: 100%;
`;

export const TabsContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
`;

export const ControlsWrapper = styled(View)`
    width: 100%;
    padding: 20px 16px 0;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: auto;
            padding: 20px 20px 0;
        `}
`;

export const ProgressWrapper = styled(View)`
    flex-grow: 1;
    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        `}
`;
