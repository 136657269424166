import { Pressable, StyleSheet, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const chatDesktop = StyleSheet.create({
    messagesContainer: {
        paddingBottom: 32,
        paddingTop: 60,
    },
});

export const chatMobileWeb = StyleSheet.create({
    messagesContainer: {
        paddingBottom: 32,
    },
});

export const Container = styled(View)`
    position: relative;
    flex: 1;
    width: 600px;
    max-height: 800px;
    background-color: ${({ theme }) => theme.fill.background};
    border: 1px solid ${({ theme }) => theme.border['16']};
    border-radius: 12px;
    shadow-color: #000;
    shadow-offset: 0px 12px;
    shadow-opacity: 0.5;
    shadow-radius: 32px;
`;

export const TopContainer = styled(View)`
    height: 60px;
    position: absolute;
    top: 0;
    padding: 10px 16px;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.fill.background};
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    z-index: 1;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;

export const BackButton = styled(Pressable)`
    margin-right: 20px;
`;

export const Slash = styled(Text)`
    color: ${({ theme }) => theme.text.disabled};
`;
