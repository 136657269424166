import React, { ComponentProps } from 'react';
import { useTheme } from 'styled-components/native';
import { useDiet } from '~/enhancers/dietContext';
import { MainLayout } from '~/layouts/web';
import { DietSubmenu } from '~/screens/diet/components/dietSubmenu';

export type DietLayoutProps = Omit<ComponentProps<typeof MainLayout>, 'title'>;

export const DietLayout = (props: DietLayoutProps) => {
    const theme = useTheme();
    const { userHasDietSubscription } = useDiet();

    return (
        <MainLayout
            {...props}
            title="Dieta"
            scrollable={false}
            submenu={theme.isWebDesktop && userHasDietSubscription ? <DietSubmenu /> : undefined}
        />
    );
};
