import { ReactNode, forwardRef } from 'react';
import { View } from 'react-native';
import Popup from 'reactjs-popup';
import { EventType, PopupPosition } from 'reactjs-popup/dist/types';
import { useTheme } from 'styled-components/native';

import { BottomSheetModal } from '../bottomSheet';
import { Tooltip as TooltipNative } from './Tooltip.native';
import * as S from './Tooltip.styled';

interface TooltipProps {
    triggerComponent: ReactNode;
    text: string;
    position: PopupPosition | PopupPosition[];
    triggerOn: EventType | EventType[];
}

export const Tooltip = forwardRef<BottomSheetModal | null, TooltipProps>(
    ({ triggerComponent, text, position, triggerOn }, ref) => {
        const theme = useTheme();

        if (theme.isWebDesktop) {
            return (
                <Popup
                    trigger={<View>{triggerComponent}</View>}
                    position={position}
                    arrow={false}
                    keepTooltipInside
                    on={triggerOn}
                >
                    <S.Content style={S.shadowStyles.shadow}>
                        <S.Description>{text}</S.Description>
                    </S.Content>
                </Popup>
            );
        }

        return <TooltipNative text={text} ref={ref} triggerComponent={triggerComponent} />;
    },
);
