import { Measurement } from '@pg/backend';
import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useGetUserMeasurements } from '~/api/userMeasurment/useGetUserMeasurements';
import { Tabs } from '~/components/tabs';

import { ComparisonPhoto } from '../comparisonPhoto';
import { categories } from './MetamorphosisContent.constants';
import * as S from './MetamorphosisContent.styled';
import { Category, MetamorphosisScreenStyledProps } from './MetamorphosisContent.types';

const getImage = (category: Category, measurement?: Measurement): string | null | undefined => {
    const photos = {
        front: measurement?.frontPhotoPath,
        back: measurement?.backPhotoPath,
        side: measurement?.sidePhotoPath,
    };

    return photos[category];
};

interface MetamorphosisContentProps extends MetamorphosisScreenStyledProps {}

export const MetamorphosisContent = ({ fullWidth }: MetamorphosisContentProps) => {
    const [categoryTab, setCategoryTab] = useState<Category>(categories[0].value);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const { data: measurements } = useGetUserMeasurements();

    useEffect(() => {
        if (measurements) {
            if (!startDate) {
                setStartDate(measurements[0]?.date ?? '');
            }
            if (!endDate) {
                setEndDate(measurements[measurements.length - 1]?.date ?? '');
            }
        }
    }, [endDate, measurements, startDate]);

    const { availableStartDates, availableEndDates, startMeasurement, endMeasurement } = useMemo(() => {
        const startMeasurements = measurements.filter((measurement) =>
            isBefore(parseISO(measurement.date), parseISO(endDate)),
        );
        const endMeasurements = measurements.filter((measurement) =>
            isAfter(parseISO(measurement.date), parseISO(startDate)),
        );

        return {
            startMeasurement: startMeasurements.find((measurement) =>
                isSameDay(parseISO(measurement.date), parseISO(startDate)),
            ),
            endMeasurement: endMeasurements.find((measurement) =>
                isSameDay(parseISO(measurement.date), parseISO(endDate)),
            ),
            availableStartDates: startMeasurements.map((measurement) => measurement.date),
            availableEndDates: endMeasurements.map((measurement) => measurement.date),
        };
    }, [endDate, measurements, startDate]);

    const startImageUri = useMemo(() => getImage(categoryTab, startMeasurement), [categoryTab, startMeasurement]);
    const endImageUri = useMemo(() => getImage(categoryTab, endMeasurement), [categoryTab, endMeasurement]);

    return (
        <S.Container fullWidth={fullWidth}>
            <S.TabsContainer>
                <S.TabsWrapper horizontal showsHorizontalScrollIndicator={false}>
                    <Tabs
                        tabs={categories}
                        activeTab={categoryTab}
                        onSelectTab={(value) => setCategoryTab(value as Category)}
                    />
                </S.TabsWrapper>
            </S.TabsContainer>

            <S.ComparisonPhotosContainer>
                <S.ComparisonPhotoWrapper>
                    <ComparisonPhoto
                        imageUri={startImageUri}
                        measurement={startMeasurement}
                        date={startDate}
                        onChangeDate={setStartDate}
                        enabledDates={availableStartDates}
                    />
                </S.ComparisonPhotoWrapper>
                <S.ComparisonPhotoWrapper last>
                    <ComparisonPhoto
                        imageUri={endImageUri}
                        measurement={endMeasurement}
                        comparisonBase={startMeasurement}
                        date={endDate}
                        onChangeDate={setEndDate}
                        enabledDates={availableEndDates}
                    />
                </S.ComparisonPhotoWrapper>
            </S.ComparisonPhotosContainer>
        </S.Container>
    );
};
