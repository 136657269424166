import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useDiet } from '~/enhancers/dietContext';
import { MainLayout } from '~/layouts/web';
import { DietPlanInProgress } from '~/screens/diet/components/dietPlanInProgress';
import { DietSlider } from '~/screens/diet/components/dietSlider';
import { NoDietPlan } from '~/screens/diet/components/noDietPlan';

import { DietComingSoon } from './components/dietComingSoon';
import { DietLayout } from './components/dietLayout/DietLayout';

export const DietScreen: FC = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();
    const { isActivePlanInProgress, userHasDietSubscription } = useDiet();

    const renderContent = () => {
        if (!userHasDietSubscription) {
            return <NoDietPlan />;
        }

        if (isActivePlanInProgress) {
            return <DietPlanInProgress />;
        }

        return <DietSlider />;
    };

    if (!features.diet) {
        return <MainLayout title="Dieta" noBackground={!theme.isWebDesktop} content={<DietComingSoon />} />;
    }

    return (
        <DietLayout
            noBackground={!theme.isWebDesktop && (!userHasDietSubscription || isActivePlanInProgress)}
            content={renderContent()}
        />
    );
};
