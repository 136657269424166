import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Pressable, StyleProp, View, ViewStyle } from 'react-native';
import { usePopper } from 'react-popper';
import { DropdownMenu } from '~/components/dropdown/DropdownMenu';
import { IconButton } from '~/components/iconButton';

import { DropdownProps } from './Dropdown.types';

export const Dropdown: React.FC<DropdownProps> = ({ items, onTriggerHoverIn, onTriggerHoverOut }) => {
    const [referenceElement, setReferenceElement] = useState<View | null>(null);
    const [popperElement, setPopperElement] = useState<View | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const { styles, attributes } = usePopper(
        referenceElement as unknown as HTMLElement,
        popperElement as unknown as HTMLElement,
        {
            strategy: 'fixed',
            placement: 'bottom-end',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 4],
                    },
                },
            ],
        },
    );

    const handleClickOutside = useCallback(
        (e: Event) => {
            if (
                !popperElement ||
                (popperElement as unknown as HTMLElement).contains(e.target as Node | null) ||
                (referenceElement as unknown as HTMLElement).contains(e.target as Node | null)
            ) {
                return;
            }

            setIsOpen(false);
        },
        [popperElement, referenceElement],
    );

    const addListener = useCallback(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
    }, [handleClickOutside]);

    const removeListener = useCallback(() => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
    }, [handleClickOutside]);

    useEffect(() => {
        if (isOpen) {
            addListener();
        } else {
            removeListener();
        }

        return removeListener;
    }, [isOpen, addListener, removeListener]);

    return (
        <>
            <Pressable ref={setReferenceElement} onHoverIn={onTriggerHoverIn} onHoverOut={onTriggerHoverOut}>
                <IconButton icon="more" size="s" onPress={() => setIsOpen((currentOpen) => !currentOpen)} />
            </Pressable>
            {createPortal(
                <>
                    {isOpen && (
                        <View
                            ref={setPopperElement}
                            style={styles.popper as StyleProp<ViewStyle>}
                            {...attributes.popper}
                        >
                            <DropdownMenu items={items} onClose={() => setIsOpen(false)} />
                        </View>
                    )}
                </>,
                document.getElementById('dropdown-root')!,
            )}
        </>
    );
};
