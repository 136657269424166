import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Measurement } from '@pg/backend';
import { useQueryClient } from '@tanstack/react-query';
import { addMonths, endOfMonth, format, isSameDay, parse, parseISO, startOfMonth, subMonths } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { useStorageAsset } from '~/api/assets/useStorageAsset';
import { useCreateOrUpdateUserMeasurement } from '~/api/userMeasurment/useCreateOrUpdateUserMeasurement';
import { useGetMeasurement } from '~/api/userMeasurment/useGetMeasurement';
import { useGetUserMeasurements } from '~/api/userMeasurment/useGetUserMeasurements';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import {
    MeasurementsInstructionsSheet,
    MeasurementsInstructionsSheetRef,
} from '~/components/bottomSheet/measurementsInstructionsSheet';
import { Button } from '~/components/button';
import { DateInput } from '~/components/dateInput';
import { FormControl } from '~/components/formControl';
import { IndexDisc } from '~/components/indexDisc';
import { Input } from '~/components/input';
import { Loader } from '~/components/loader';
import { SectionHeading } from '~/components/sectionHeading';
import { SubmittableForm } from '~/components/submittableForm/SubmittableForm';
import { useImagePicker } from '~/enhancers/imagePicker';
import {
    getConfirmationSheetSubtitle,
    shouldOpenConfirmationSheet,
    uploadImage,
} from '~/screens/measurements/Measurement/utils';

import { FormValues, PhotoValue } from '../../Measurement/MeasurementScreen.types';
import { defaultValues, photoValues } from './MeasurementForm.constants';
import { schema } from './MeasurementForm.schema';
import * as S from './MeasurementForm.styled';
import { PhotosPicker } from './PhotosPicker';

interface MeasurementFormProps {
    measurementId?: string;
    style?: ViewStyle;
    onSave?: () => void;
}

const AnimatedLoaderWrapper = Animated.createAnimatedComponent(S.LoaderWrapper);

export const MeasurementForm: React.FC<MeasurementFormProps> = ({ measurementId, style, onSave }) => {
    const queryClient = useQueryClient();
    const inset = useSafeAreaInsets();
    const theme = useTheme();
    const measurement = useGetMeasurement(measurementId || '', { enabled: !!measurementId });

    const frontImagePath = useStorageAsset(measurement.data?.frontPhotoPath ?? null, 'private');
    const sideImagePath = useStorageAsset(measurement.data?.sidePhotoPath ?? null, 'private');
    const backImagePath = useStorageAsset(measurement.data?.backPhotoPath ?? null, 'private');

    const [isPendingSave, setIsPendingSave] = useState(false);
    const { chooseImage } = useImagePicker();

    const { control, formState, getValues, setValue, reset, handleSubmit } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues,
    });
    const errorSheetRef = useRef<ConfirmSheetRef>(null);
    const confirmSheetRef = useRef<ConfirmSheetRef>(null);
    const instructionsSheetRef = useRef<MeasurementsInstructionsSheetRef>(null);
    const [visibleCalendarDate, setVisibleCalendarDate] = useState(getValues().date);
    const { data: allMeasurements } = useGetUserMeasurements(
        {
            startDate: startOfMonth(subMonths(parse(visibleCalendarDate, 'yyyy-MM-dd', new Date()), 3)),
            endDate: endOfMonth(addMonths(parse(visibleCalendarDate, 'yyyy-MM-dd', new Date()), 3)),
        },
        {
            keepPreviousData: true,
            initialData: undefined,
        },
    );

    const { mutateAsync: createOrUpdateUserMeasurement } = useCreateOrUpdateUserMeasurement({
        onError: () => {
            // TODO: need to handle BE form validation errors
            setIsPendingSave(false);
        },
        onSuccess: () => {
            queryClient.refetchQueries<Measurement>(['getAllUserMeasurements']);
            queryClient.refetchQueries<Measurement>(['getCurrentMeasurement']);
            setIsPendingSave(false);
            onSave?.();
        },
    });

    useEffect(() => {
        if (measurement.data) {
            const values = getValues();
            const { data } = measurement;

            const newValues = {
                frontImage: values.frontImage || null,
                sideImage: values.sideImage || null,
                backImage: values.backImage || null,
                weight: values.weight || data?.weight.toString() || defaultValues.weight,
                waist: values.waist || data?.waist.toString() || defaultValues.waist,
                hips: values.hips || data?.hips.toString() || defaultValues.hips,
                thigh: values.thigh || data?.thigh.toString() || defaultValues.thigh,
                arm: values.arm || data?.arm.toString() || defaultValues.arm,
                chest: values.chest || data?.chest.toString() || defaultValues.chest,
                calf: values.calf || data?.calf.toString() || defaultValues.calf,
                date:
                    values.date !== format(new Date(), 'yyyy-MM-dd')
                        ? values.date
                        : format(data?.date ? new Date(data?.date) : new Date(), 'yyyy-MM-dd'),
            };

            reset(newValues);
            setVisibleCalendarDate(newValues.date);
        }
    }, [measurement.data]);

    useEffect(() => {
        if (frontImagePath) {
            setValue('frontImage', {
                uri: frontImagePath,
                mime: 'image/jpeg',
            });
        }
    }, [frontImagePath, setValue]);

    useEffect(() => {
        if (sideImagePath) {
            setValue('sideImage', {
                uri: sideImagePath,
                mime: 'image/jpeg',
            });
        }
    }, [sideImagePath, setValue]);

    useEffect(() => {
        if (backImagePath) {
            setValue('backImage', {
                uri: backImagePath,
                mime: 'image/jpeg',
            });
        }
    }, [backImagePath, setValue]);

    const disabledDates = useMemo(() => {
        const updatedMeasurementsDate = measurement.data?.date;

        return allMeasurements
            ?.map((measurement) => measurement.date)
            .filter((date) => {
                return !updatedMeasurementsDate || !isSameDay(parseISO(date), parseISO(updatedMeasurementsDate));
            });
    }, [measurement.data?.date, allMeasurements]);

    const handleSave = (values: FormValues) => {
        if (shouldOpenConfirmationSheet(values)) {
            confirmSheetRef.current?.present();
        } else {
            handleSaveConfirmation();
        }
    };

    const handleSaveConfirmation = async () => {
        confirmSheetRef.current?.dismiss();
        setIsPendingSave(true);
        const { backImage, frontImage, sideImage, date, ...measures } = schema.cast(getValues());

        try {
            const nameSuffix = format(date, 'MM-dd-yyyy');

            const [frontPhotoPath, sidePhotoPath, backPhotoPath] = await Promise.all([
                frontImage?.uri === frontImagePath
                    ? Promise.resolve(measurement.data?.frontPhotoPath)
                    : uploadImage({ ...frontImage, name: `front-${nameSuffix}` }),
                sideImage?.uri === sideImagePath
                    ? Promise.resolve(measurement.data?.sidePhotoPath)
                    : uploadImage({ ...sideImage, name: `side-${nameSuffix}` }),
                backImage?.uri === backImagePath
                    ? Promise.resolve(measurement.data?.backPhotoPath)
                    : uploadImage({ ...backImage, name: `back-${nameSuffix}` }),
            ]);

            await createOrUpdateUserMeasurement({
                id: measurementId,
                date: date.toISOString(),
                frontPhotoPath,
                sidePhotoPath,
                backPhotoPath,
                ...measures,
            });
        } catch (e) {
            console.log(e);
            errorSheetRef?.current?.present();
            setIsPendingSave(false);
        }
    };

    const handleAddPhoto = async (value: PhotoValue['value']) => {
        try {
            const image = await chooseImage({ width: 570, height: 960 });
            setValue(value, image);
        } catch (e) {
            errorSheetRef?.current?.present();
            console.log(e);
        }
    };

    const handleRemovePhoto = (value: PhotoValue['value']) => setValue(value, null);

    if (measurement.isInitialLoading) {
        return (
            <AnimatedLoaderWrapper pointerEvents="none">
                <Loader size="xl" color={theme.icon.interactive} />
            </AnimatedLoaderWrapper>
        );
    }

    return (
        <>
            <SubmittableForm
                style={style}
                disabled={!formState.isValid}
                onSubmit={(e) => {
                    handleSubmit(handleSave)(e);
                }}
            >
                <S.Content inset={inset}>
                    <S.PhotosSection>
                        <SectionHeading
                            leftContent={<IndexDisc index={1} />}
                            variant="subheader"
                            title="Dodaj zdjęcia"
                        />
                        <PhotosPicker
                            control={control}
                            value={photoValues}
                            onAdd={handleAddPhoto}
                            onRemove={handleRemovePhoto}
                        />
                    </S.PhotosSection>
                    <S.MeasurementsSection>
                        <SectionHeading
                            leftContent={<IndexDisc index={2} />}
                            variant="subheader"
                            title="Pomiary"
                            rightContent={
                                <Button
                                    size="xs"
                                    variant="secondary"
                                    onPress={() => instructionsSheetRef?.current?.present()}
                                >
                                    Jak się mierzyć?
                                </Button>
                            }
                        />

                        <S.InputsContainer>
                            <S.InputWrapper>
                                <Controller
                                    name="weight"
                                    control={control}
                                    render={({ field: { onChange, ...field }, fieldState }) => (
                                        <FormControl error={fieldState.error?.message}>
                                            <Input
                                                {...field}
                                                label="Waga"
                                                suffix="kg"
                                                editable={!formState.isSubmitting}
                                                error={!!fieldState.error?.message}
                                                keyboardType="numeric"
                                                onChangeText={onChange}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </S.InputWrapper>

                            <S.InputWrapper>
                                <Controller
                                    name="waist"
                                    control={control}
                                    render={({ field: { onChange, ...field }, fieldState }) => (
                                        <FormControl error={fieldState.error?.message}>
                                            <Input
                                                {...field}
                                                label="Obwód pasa"
                                                suffix="cm"
                                                editable={!formState.isSubmitting}
                                                error={!!fieldState.error?.message}
                                                keyboardType="numeric"
                                                onChangeText={onChange}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </S.InputWrapper>

                            <S.InputWrapper>
                                <Controller
                                    name="hips"
                                    control={control}
                                    render={({ field: { onChange, ...field }, fieldState }) => (
                                        <FormControl error={fieldState.error?.message}>
                                            <Input
                                                {...field}
                                                label="Obwód bioder"
                                                suffix="cm"
                                                editable={!formState.isSubmitting}
                                                error={!!fieldState.error?.message}
                                                keyboardType="numeric"
                                                onChangeText={onChange}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </S.InputWrapper>

                            <S.InputWrapper>
                                <Controller
                                    name="thigh"
                                    control={control}
                                    render={({ field: { onChange, ...field }, fieldState }) => (
                                        <FormControl error={fieldState.error?.message}>
                                            <Input
                                                {...field}
                                                label="Obwód uda"
                                                suffix="cm"
                                                editable={!formState.isSubmitting}
                                                error={!!fieldState.error?.message}
                                                keyboardType="numeric"
                                                onChangeText={onChange}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </S.InputWrapper>

                            <S.InputWrapper>
                                <Controller
                                    name="arm"
                                    control={control}
                                    render={({ field: { onChange, ...field }, fieldState }) => (
                                        <FormControl error={fieldState.error?.message}>
                                            <Input
                                                {...field}
                                                label="Obwód ramienia"
                                                suffix="cm"
                                                editable={!formState.isSubmitting}
                                                error={!!fieldState.error?.message}
                                                keyboardType="numeric"
                                                onChangeText={onChange}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </S.InputWrapper>

                            <S.InputWrapper>
                                <Controller
                                    name="chest"
                                    control={control}
                                    render={({ field: { onChange, ...field }, fieldState }) => (
                                        <FormControl error={fieldState.error?.message}>
                                            <Input
                                                {...field}
                                                label="Obwód kl. piersiowej"
                                                suffix="cm"
                                                editable={!formState.isSubmitting}
                                                error={!!fieldState.error?.message}
                                                keyboardType="numeric"
                                                onChangeText={onChange}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </S.InputWrapper>

                            <S.InputWrapper>
                                <Controller
                                    name="calf"
                                    control={control}
                                    render={({ field: { onChange, ...field }, fieldState }) => (
                                        <FormControl error={fieldState.error?.message}>
                                            <Input
                                                {...field}
                                                label="Obwód łydki"
                                                suffix="cm"
                                                editable={!formState.isSubmitting}
                                                error={!!fieldState.error?.message}
                                                keyboardType="numeric"
                                                onChangeText={onChange}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </S.InputWrapper>

                            <S.InputWrapper>
                                <Controller
                                    render={({ field: { onChange, value } }) => (
                                        <DateInput
                                            icon="training history"
                                            label="Data dodania pomiaru"
                                            value={value}
                                            disabledDates={disabledDates}
                                            onChange={onChange}
                                            onMonthChange={setVisibleCalendarDate}
                                        />
                                    )}
                                    name="date"
                                    control={control}
                                />
                            </S.InputWrapper>

                            <S.SubmitButtonWrapper>
                                <Button
                                    fullWidth
                                    size="m"
                                    loading={isPendingSave}
                                    disabled={!formState.isValid}
                                    onPress={handleSubmit(handleSave)}
                                >
                                    Zapisz
                                </Button>
                            </S.SubmitButtonWrapper>
                        </S.InputsContainer>
                    </S.MeasurementsSection>
                </S.Content>
            </SubmittableForm>

            <MeasurementsInstructionsSheet ref={instructionsSheetRef} />

            <ConfirmSheet
                ref={confirmSheetRef}
                title="Czy na pewno chcesz zapisać pomiary?"
                subtitle={getConfirmationSheetSubtitle(getValues())}
                primaryButtonLabel="Tak, zapisz"
                secondaryButtonLabel="Nie"
                onConfirm={handleSaveConfirmation}
                onClose={() => confirmSheetRef?.current?.close()}
            />

            <ConfirmSheet
                ref={errorSheetRef}
                title="Nastąpił nieoczekiwany błąd"
                subtitle="Spróbuj ponownie za chwilę."
                secondaryButtonLabel="OK"
                variant="error"
                icon="warning-stop"
                onClose={() => errorSheetRef?.current?.close()}
            />
        </>
    );
};
