import React, { ReactNode } from 'react';

import * as S from './SettingsSection.styled';

export interface SettingsSectionProps {
    title: string;
    subtitle?: string;
    rightContent?: ReactNode;
    children?: ReactNode;
}

export const SettingsSection: React.FC<SettingsSectionProps> = ({ title, subtitle, rightContent, children }) => {
    return (
        <S.Container>
            <S.Content>
                <S.Header>
                    <S.Title>{title}</S.Title>
                    {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
                </S.Header>
                {rightContent && <S.RightContent>{rightContent}</S.RightContent>}
            </S.Content>
            {children}
        </S.Container>
    );
};
