import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { ActivityCalendarScreen } from '~/screens/activityCalendar';
import { ActivityDayDetailsScreen } from '~/screens/dashboard/activityDayDetails';

import { ActivityDetailsTopNavigation, CalendarTodayTopNavigation } from '../components/topNavigation';
import { DashboardStackParamsList } from './DashboardStackNavigation.types';

const Stack = createNativeStackNavigator<DashboardStackParamsList>();

const ActivityHistoryNavigation = (props: NativeStackHeaderProps) => (
    <CalendarTodayTopNavigation onBackPress={() => props.navigation.navigate('Tab', { screen: 'Home' })} {...props} />
);

const ActivityDayDetailsNavigation = (props: NativeStackHeaderProps) => (
    <ActivityDetailsTopNavigation onBackPress={() => props.navigation.navigate('Tab', { screen: 'Home' })} {...props} />
);

export const DashboardStackNavigation = () => {
    return (
        <Stack.Navigator screenOptions={{ header: () => null }}>
            <Stack.Screen
                name="ActivityHistory"
                component={ActivityCalendarScreen}
                options={{ header: ActivityHistoryNavigation }}
            />
            <Stack.Screen
                name="ActivityDetails"
                component={ActivityDayDetailsScreen}
                options={{ header: ActivityDayDetailsNavigation }}
            />
        </Stack.Navigator>
    );
};
