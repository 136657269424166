import React, { Fragment } from 'react';
import { Icon } from '~/components/icon';
import { getFormattedDuration } from '~/utils/dates';

import * as S from './TrainingHeader.styled';

export interface TrainingHeaderProps {
    cycle?: string;
    training?: string;
    duration?: number;
    series?: string | number;
}
export const TrainingHeader: React.FC<TrainingHeaderProps> = ({ cycle, training, duration, series }) => {
    const shouldShowSummary = !!duration && !!series;

    return (
        <S.Container>
            {cycle && training && (
                <S.TitleContainer>
                    <S.Cycle>{cycle} / </S.Cycle>
                    <S.Training>{training}</S.Training>
                </S.TitleContainer>
            )}
            {shouldShowSummary && (
                <S.SummaryContainer>
                    <S.SummaryItem>
                        <S.SummaryItemTitle>
                            <S.SummaryItemTitleTextWrapper>
                                {getFormattedDuration(duration)
                                    .filter((item) => item.value)
                                    .map((item) => (
                                        <Fragment key={item.suffix}>
                                            <S.SummaryItemTitleText>{item.value}</S.SummaryItemTitleText>
                                            <S.SummaryItemTitleTextUnit last={item.suffix === 'm'}>
                                                {item.suffix}
                                            </S.SummaryItemTitleTextUnit>
                                        </Fragment>
                                    ))}
                            </S.SummaryItemTitleTextWrapper>
                            <S.SummaryItemTitleIcon>
                                <Icon name="total-training-time" size={24} />
                            </S.SummaryItemTitleIcon>
                        </S.SummaryItemTitle>
                        <S.SummaryItemSubtitle>Szacowany czas treningu</S.SummaryItemSubtitle>
                    </S.SummaryItem>

                    <S.SummaryItem last>
                        <S.SummaryItemTitle>
                            <S.SummaryItemTitleTextWrapper>
                                <S.SummaryItemTitleText>{series}</S.SummaryItemTitleText>
                            </S.SummaryItemTitleTextWrapper>
                            <S.SummaryItemTitleIcon>
                                <Icon name="personal-trainer" size={24} />
                            </S.SummaryItemTitleIcon>
                        </S.SummaryItemTitle>
                        <S.SummaryItemSubtitle>Łączna liczba serii</S.SummaryItemSubtitle>
                    </S.SummaryItem>
                </S.SummaryContainer>
            )}
        </S.Container>
    );
};
