import { FC } from 'react';

import { Loader } from '../loader';
import { RulesContent as RulesContentMobile } from './RulesContent.native';
import * as S from './RulesContent.styled';
import { Rules } from './RulesContent.types';

interface RulesContentProps {
    content?: Rules;
    isLoading: boolean;
}

export const RulesContent: FC<RulesContentProps> = ({ content, isLoading }) => {
    return (
        <>
            <S.WebContainer isLoading={isLoading}>
                <RulesContentMobile content={content} />
            </S.WebContainer>
            {isLoading && (
                <S.WebLoaderContainer>
                    <Loader />
                </S.WebLoaderContainer>
            )}
        </>
    );
};
