import { Platform } from 'react-native';

import { Font, font } from './font';
import { Breakpoint } from './media';
import { Palette, darkPalette } from './palette';
import { Tokens, getTokens } from './tokens';

export * from './palette';
export * from './tokens';
export * from './media';
export { mixins } from './mixins';
export * from './global';

export interface Theme extends Tokens {
    palette: Palette;
    font: Font;
}

export interface ResponsiveTheme extends Theme {
    platform: typeof Platform.OS;
    media: Record<Breakpoint, boolean>;
    isWebMobile: boolean;
    isWebDesktop: boolean;
    isWebLargeDesktop: boolean;
    isWebExtraLargeDesktop: boolean;
    isWeb: boolean;
}

export const getTheme = (palette: Palette, mapPaletteToTokens = getTokens): Theme => {
    const tokens = mapPaletteToTokens(palette);
    return {
        ...tokens,
        palette,
        font,
    };
};

export const defaultTheme: Theme = getTheme(darkPalette);
