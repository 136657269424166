import { useMemo } from 'react';
import { interpolate, interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { ToggleProps } from '~/components/toggle/Toggle';
import { ToggleSize } from '~/components/toggle/Toggle.types';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface ToggleAnimationsRenderPropValue {
    borderAnimatedStyles: {
        borderColor: string;
    };
    dotAnimatedStyles: {
        backgroundColor: string;
        transform: {
            translateX: number;
        }[];
    };
    backgroundAnimatedStyles: {
        backgroundColor: string;
    };
}

export interface ToggleAnimationsProps extends AnimationsProps<ToggleAnimationsRenderPropValue> {
    checked: boolean;
    disabled: boolean;
    size: ToggleSize;
}

export const ToggleAnimations = ({ checked, disabled, size, children }: ToggleAnimationsProps) => {
    const theme = useTheme();

    const checkedProgress = useDerivedValue(() => withSpring(Number(checked), springConfig.default));

    const toggleTheme = useMemo(() => {
        if (disabled) {
            return {
                border: {
                    borderColor: [theme.palette.opacity['12'].rgba, theme.palette.opacity['12'].rgba],
                },
                dot: {
                    backgroundColor: [theme.text.disabled, theme.text.disabled],
                },
                background: {
                    color: [theme.palette.opacity['08'].rgba, theme.palette.opacity['08'].rgba],
                },
            };
        }

        return {
            border: {
                borderColor: [theme.border['40'], theme.fill.informative],
            },
            dot: {
                backgroundColor: [theme.icon.secondary, theme.icon.primary],
            },
            background: {
                color: [theme.palette.opacity['16'].rgba, theme.fill.informative],
            },
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    const borderAnimatedStyles = useAnimatedStyle(() => ({
        borderColor: interpolateColor(checkedProgress.value, [0, 1], toggleTheme.border.borderColor),
    }));

    const dotOffsetMap: Record<NonNullable<ToggleProps['size']>, number> = {
        s: 8,
        m: 12,
        l: 18,
    };
    const dotAnimatedStyles = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(checkedProgress.value, [0, 1], toggleTheme.dot.backgroundColor),
        transform: [{ translateX: interpolate(checkedProgress.value, [0, 1], [0, dotOffsetMap[size]]) }],
    }));

    const backgroundAnimatedStyles = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(checkedProgress.value, [0, 1], toggleTheme.background.color),
    }));

    return children({ borderAnimatedStyles, dotAnimatedStyles, backgroundAnimatedStyles });
};
