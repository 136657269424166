import { useRoute } from '@react-navigation/native';
import { Logo } from '~/components/logo';

import { SidebarMenuItem } from './SidebarMenuItem';
import { Link as LinkType, footerLinks, mainLinks } from './SidebarNavigation.constants';
import * as S from './SidebarNavigation.styled';

export const SidebarNavigation = () => {
    const route = useRoute();

    const renderLinks = (links: LinkType[]) => {
        return links.map((item) => (
            <SidebarMenuItem key={item.path} item={item} active={item.activeStatusScreens?.includes(route.name)} />
        ));
    };

    return (
        <S.Container>
            <S.MainMenu>{renderLinks(mainLinks)}</S.MainMenu>
            <S.FooterMenu>
                {renderLinks(footerLinks)}
                <S.LogoContainer>
                    <Logo />
                </S.LogoContainer>
            </S.FooterMenu>
        </S.Container>
    );
};
