import { DataRange } from './MeasurementsChart.types';

export const dataRange: Record<DataRange, { label: string; value: DataRange }> = {
    all: {
        label: 'Cała współpraca',
        value: 'all',
    },
    goal: {
        label: 'Od początku celu',
        value: 'goal',
    },
};

export const dataRangeList = [dataRange.all, dataRange.goal];
