import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    display: flex;
    width: ${({ theme }) => (theme.isWebDesktop ? '364px' : 'auto')};
`;

export const LogoContainer = styled(View)`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 28px;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.medium24};
    color: ${({ theme }) => theme.text.primary};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.h.medium20};
        `}
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.secondary};
    margin-top: 12px;
`;

export const FormContent = styled(View)`
    margin-top: 32px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-top: 40px;
        `}
`;

export const BottomActions = styled(View)`
    margin-top: 44px;
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
`;

export const ErrorContainer = styled(View)`
    margin-top: 32px;
`;

export const ErrorText = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.critical};
`;
