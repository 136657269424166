import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormControl } from '~/components/formControl';
import { Input } from '~/components/input';
import { SubmittableForm } from '~/components/submittableForm/SubmittableForm';
import { Field } from '~/screens/auth/components/Field';
import { SubmitButton } from '~/screens/auth/components/SubmitButton';

import { passwordRecoveryFormSchema } from './PasswordRecoveryForm.schema';
import * as S from './PasswordRecoveryForm.styled';
import { FormValues } from './PasswordRecoveryForm.types';

interface PasswordRecoveryFormProps {
    onSubmit: SubmitHandler<FormValues>;
}

export const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({ onSubmit }) => {
    const { control, handleSubmit, formState } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: yupResolver(passwordRecoveryFormSchema),
        defaultValues: {
            email: '',
        },
    });

    return (
        <S.Container>
            <SubmittableForm
                disabled={!formState.isValid}
                onSubmit={(e) => {
                    handleSubmit(onSubmit)(e);
                }}
            >
                <Field last>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <Input
                                    label="Email"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    textContentType="emailAddress"
                                    autoCapitalize="none"
                                    keyboardType="email-address"
                                    autoComplete="email"
                                    autoCorrect={false}
                                    onChangeText={onChange}
                                />
                            </FormControl>
                        )}
                    />
                </Field>
                <SubmitButton
                    size="m"
                    fullWidth
                    loading={formState.isSubmitting}
                    disabled={!formState.isValid}
                    onPress={handleSubmit(onSubmit)}
                >
                    Zresetuj hasło
                </SubmitButton>
            </SubmittableForm>
        </S.Container>
    );
};
