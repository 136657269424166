import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    height: 100%;
    width: 100%;
    border-radius: 16px;
    border: 2px solid ${({ theme }) => theme.border[12]};
    padding: 16px 20px;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
    margin-bottom: 4px;
`;

export const Value = styled(Text)`
    ${mixins.typography.ui.semiBold16};
    color: ${({ theme }) => theme.text.primary};
`;

export const Unit = styled(Value)`
    color: ${({ theme }) => theme.text.tertiary};
`;
