import { ScrollView, View } from 'react-native';
import styled from 'styled-components/native';
import { MetamorphosisScreenStyledProps } from '~/screens/measurements/components/metamorphosisContent/MetamorphosisContent.types';

export const Container = styled(ScrollView)<MetamorphosisScreenStyledProps>`
    padding: ${({ fullWidth }) => (fullWidth ? '0' : '0 16px')};
`;

export const TabsContainer = styled(View)`
    margin-top: ${({ theme }) => (theme.isWebDesktop ? 0 : 16)}px;
`;

export const TabsWrapper = styled(ScrollView)`
    width: 100%;
    margin-bottom: 20px;
`;

export const ComparisonPhotosContainer = styled(View)`
    display: flex;
    flex-direction: row;

    margin-bottom: 40px;
`;

export const ComparisonPhotoWrapper = styled(View)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;

export const SavePhotoButtonWrapper = styled(View)`
    margin-bottom: 32px;
`;
