import { ReactElement } from 'react';
import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';

export interface ActivityButtonAnimationsRenderPropValue {
    borderColor: string;
}

export interface ActivityButtonAnimationsProps {
    progress: number;
    disabled: boolean | null;
    children: (value: ActivityButtonAnimationsRenderPropValue) => ReactElement;
}

export const ActivityButtonAnimation = ({ progress, children, disabled }: ActivityButtonAnimationsProps) => {
    const theme = useTheme();

    const borderColorProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const borderColorMap: Record<string, string> = {
        default: theme.border['20'],
        hovered: theme.border['32'],
        pressed: theme.border.interactive,
    };

    const animatedStyle = useAnimatedStyle(() => {
        return {
            borderColor: disabled
                ? borderColorMap.default
                : interpolateColor(
                      borderColorProgress.value,
                      [0, 1, 2],
                      [borderColorMap.default, borderColorMap.hovered, borderColorMap.pressed],
                  ),
        };
    }, [disabled]);

    return children(animatedStyle);
};
