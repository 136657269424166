import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useQueryClient } from '@tanstack/react-query';
import { FC, ReactNode, createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { useDeleteMeasurement } from '~/api/userMeasurment/useDeleteMeasurement';
import { useGetCurrentMeasurement } from '~/api/userMeasurment/useGetCurrentMeasurement';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import { MeasurementModal } from '~/components/modal/measurementModal';
import { HomeParamsList } from '~/navigation/home';
import { MeasurementsStackParamsList } from '~/navigation/home/measurements';

interface MeasurementModalState {
    isOpen: boolean;
    measurementId?: string;
}

export interface MeasurementContextType {
    measurementModalState: {
        isOpen: boolean;
        measurementId?: string;
    };
    addMeasurement: () => void;
    updateMeasurement: (measurementId: string) => void;
    deleteMeasurement: (measurementId: string) => void;
}

export const MeasurementContext = createContext<MeasurementContextType>({
    measurementModalState: {
        isOpen: false,
        measurementId: undefined,
    },
    addMeasurement: () => {
        throw new Error('Not implemented');
    },
    updateMeasurement: () => {
        throw new Error('Not implemented');
    },
    deleteMeasurement: () => {
        throw new Error('Not implemented');
    },
});

export interface MeasurementProviderProps {
    children: ReactNode;
}

export type MeasurementsNavigationProp = CompositeNavigationProp<
    NativeStackNavigationProp<HomeParamsList, 'MeasurementsStack'>,
    NativeStackNavigationProp<MeasurementsStackParamsList>
>;

export const MeasurementContextProvider: FC<MeasurementProviderProps> = ({ children }) => {
    const theme = useTheme();
    const navigation = useNavigation<MeasurementsNavigationProp>();
    const confirmSheetRef = useRef<ConfirmSheetRef>(null);
    const [measurementModalState, setMeasurementModalState] = useState<MeasurementModalState>({
        isOpen: false,
        measurementId: undefined,
    });
    const { data: currentMeasurement } = useGetCurrentMeasurement();
    const queryClient = useQueryClient();
    const deleteMeasurementMutation = useDeleteMeasurement({
        onSuccess: () => {
            queryClient.refetchQueries(['getAllUserMeasurements']);
            queryClient.refetchQueries(['getCurrentMeasurement']);
        },
    });

    const addMeasurement = useCallback(() => {
        if (currentMeasurement?.id) {
            confirmSheetRef.current?.present();
            return;
        }

        if (theme.isWebDesktop) {
            setMeasurementModalState({
                isOpen: true,
                measurementId: undefined,
            });
        } else {
            navigation.navigate('MeasurementsStack', {
                screen: 'Measurement',
                params: {
                    measurementId: '',
                },
            });
        }
    }, [theme.isWebDesktop, navigation, currentMeasurement?.id]);

    const updateMeasurement = useCallback(
        (measurementId: string) => {
            if (theme.isWebDesktop) {
                setMeasurementModalState({
                    isOpen: true,
                    measurementId,
                });
            } else {
                navigation.navigate('MeasurementsStack', {
                    screen: 'Measurement',
                    params: {
                        measurementId,
                    },
                });
            }
        },
        [theme.isWebDesktop, navigation],
    );

    const deleteMeasurement = useCallback(
        (measurementId: string) => {
            deleteMeasurementMutation.mutate(measurementId);
        },
        [deleteMeasurementMutation],
    );

    const handleMeasurementClose = () =>
        setMeasurementModalState({
            isOpen: false,
        });

    const handleUpdateCurrentMeasurementConfirm = () => {
        confirmSheetRef.current?.close();
        updateMeasurement(currentMeasurement?.id || '');
    };

    const value = useMemo(
        () => ({ measurementModalState, addMeasurement, updateMeasurement, deleteMeasurement }),
        [measurementModalState, addMeasurement, updateMeasurement, deleteMeasurement],
    );

    return (
        <MeasurementContext.Provider value={value}>
            {children}
            <MeasurementModal {...measurementModalState} onClose={handleMeasurementClose} />
            <ConfirmSheet
                ref={confirmSheetRef}
                title="Pomiar w wybranym dniu został już dodany. Czy chcesz go edytować?"
                icon="help-fill"
                iconColor={theme.icon.interactive}
                variant="primary"
                primaryButtonLabel="Edytuj"
                secondaryButtonLabel="Anuluj"
                onConfirm={handleUpdateCurrentMeasurementConfirm}
                onClose={() => confirmSheetRef.current?.close()}
            />
        </MeasurementContext.Provider>
    );
};

export const useMeasurement = () => useContext(MeasurementContext);
