export const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

export const getYFromX = (
    point1: {
        x: number;
        y: number;
    },
    point2: {
        x: number;
        y: number;
    },
    currentX: number,
) => {
    var gradient = (point2.y - point1.y) / (point2.x - point1.x);
    var intercept = point1.y - gradient * point1.x;
    return gradient * currentX + intercept;
};
