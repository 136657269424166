import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormControl } from '~/components/formControl';
import { PasswordInput } from '~/components/passwordInput';
import { SubmittableForm } from '~/components/submittableForm/SubmittableForm';
import { CodeInput } from '~/screens/auth/components/CodeInput';
import { Field } from '~/screens/auth/components/Field';
import { SubmitButton } from '~/screens/auth/components/SubmitButton';

import { NewPasswordChallengeFormSchema, NewPasswordFormSchema } from './NewPasswordForm.schema';
import * as S from './NewPasswordForm.styled';
import { FormValues } from './NewPasswordForm.types';

interface NewPasswordFormProps {
    onSubmit: SubmitHandler<FormValues>;
    challenge?: boolean;
}

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit, challenge }) => {
    const { control, handleSubmit, formState } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: yupResolver(challenge ? NewPasswordChallengeFormSchema : NewPasswordFormSchema),
        defaultValues: {
            code: '',
        },
    });

    return (
        <S.Container>
            <SubmittableForm
                disabled={!formState.isValid}
                onSubmit={(e) => {
                    handleSubmit(onSubmit)(e);
                }}
            >
                {!challenge && (
                    <Field>
                        <Controller
                            name="code"
                            control={control}
                            render={({ field: { onChange, ...field }, fieldState }) => (
                                <CodeInput
                                    {...field}
                                    keepLastDigitActive
                                    error={fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    onChangeText={onChange}
                                />
                            )}
                        />
                    </Field>
                )}
                <Field>
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <PasswordInput
                                    label="Hasło"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    onChangeText={onChange}
                                />
                            </FormControl>
                        )}
                    />
                </Field>
                <Field last>
                    <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <PasswordInput
                                    label="Powtórz hasło"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    secureTextEntry
                                    onChangeText={onChange}
                                />
                            </FormControl>
                        )}
                    />
                </Field>
                <SubmitButton
                    size="m"
                    fullWidth
                    loading={formState.isSubmitting}
                    disabled={!formState.isValid}
                    onPress={handleSubmit(onSubmit)}
                >
                    Ustaw hasło
                </SubmitButton>
            </SubmittableForm>
        </S.Container>
    );
};
