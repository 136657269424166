import React from 'react';
import { ComingSoon } from '~/components/comingSoon';

export const DietComingSoon = () => (
    <ComingSoon
        title="W ramach pakietu z dietą dostępne będzie:"
        features={[
            'Dowolna wymiana posiłków',
            'Automatycznie generowana lista zakupów',
            'Każdy posiłek dobrany pod Twoje zapotrzebowanie',
        ]}
        imageSrc={require('~/assets/images/diet_coming_soon.png')}
    />
);
