import { Text as TextBase, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme/mixins';

export const Container = styled(View)`
    background-color: ${({ theme }) => theme.fill.level3};
    width: 24px;
    height: 24px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    user-select: none;
`;

export const Index = styled(TextBase)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.primary};
`;
