import { Measurement } from '@pg/backend';
import React, { useEffect } from 'react';
import { useStorageAsset } from '~/api/assets/useStorageAsset';
import { DateInput, DateInputProps } from '~/components/dateInput';
import { UnitSuffix } from '~/components/unitSuffix';
import { PhotoTile, PhotoTileProps } from '~/screens/measurements/components/photoTile';
import { prefetchImage } from '~/utils/file';

import { measurementsList } from '../../MeasurementsScreen.constants';
import * as S from './ComparisonPhoto.styled';
import { ComparisonChip } from './comparisonChip';

export interface ComparisonPhotoProps {
    imageUri: PhotoTileProps['imageUri'];
    date: DateInputProps['value'];
    measurement?: Measurement;
    comparisonBase?: Measurement;
    enabledDates?: string[];
    onChangeDate: (date: string) => void;
}

export const ComparisonPhoto: React.FC<ComparisonPhotoProps> = ({
    imageUri,
    date,
    measurement,
    comparisonBase,
    enabledDates,
    onChangeDate,
}) => {
    const imagePath = useStorageAsset(imageUri ?? null, 'private');

    useEffect(() => {
        if (!imagePath) return;

        prefetchImage(imagePath);
    }, [imagePath]);

    return (
        <S.Container>
            <S.PhotoTileWrapper>
                <PhotoTile imageUri={imagePath} placeholderLabel="Brak zdjęcia z wybranego okresu" />
            </S.PhotoTileWrapper>
            <S.DateInputWrapper>
                <DateInput
                    icon="training history"
                    label="Data"
                    value={date}
                    onChange={onChangeDate}
                    enabledDates={enabledDates}
                />
            </S.DateInputWrapper>
            <S.Measures>
                {measurementsList.map(({ value, label, unit }, index) => (
                    <S.MeasureContainer key={value} last={index === measurementsList.length - 1}>
                        <S.Measure>
                            <S.Border />
                            <S.MeasureLabel>{label}</S.MeasureLabel>
                            <S.MeasureValue>
                                <UnitSuffix unit={unit}>{measurement?.[value] ?? '- '}</UnitSuffix>
                            </S.MeasureValue>
                        </S.Measure>
                        {comparisonBase && (
                            <ComparisonChip value={(measurement?.[value] ?? 0) - (comparisonBase?.[value] ?? 0)} />
                        )}
                    </S.MeasureContainer>
                ))}
            </S.Measures>
        </S.Container>
    );
};
