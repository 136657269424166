import { View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    display: flex;
`;

export const InputWrapper = styled(View)`
    margin-bottom: 24px;
`;
