import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { TrainingTimerSheet } from '~/components/bottomSheet';
import { TrainingTimerSheetProps } from '~/components/bottomSheet/trainingTimerSheet/TrainingTimerSheet.types';
import { TrainingTimerModal } from '~/components/modal/trainingTimerModal';
import { useSoundPlayer } from '~/enhancers/soundPlayer';

export interface StartRestParams {
    id: string;
    duration: number;
    title: string;
    subtitle: string;
    series: string;
    onEnd?: () => void;
}

interface StartCardioParams {
    id: string;
    duration: number;
    countdownDuration: number;
    title: string;
    onSkip?: () => void;
    onEnd?: (progress?: number) => void;
}

export interface TimerContextType {
    startRest: (params: StartRestParams) => void;
    startCardio: (params: StartCardioParams) => void;
    close: () => void;
}

export const TimerContext = React.createContext<TimerContextType>({
    startRest: () => {
        throw new Error('Not implemented');
    },
    startCardio: () => {
        throw new Error('Not implemented');
    },
    close: () => {
        throw new Error('Not implemented');
    },
});

export interface TimerContextProviderProps {
    children: ReactNode;
}

export interface TimerState extends TrainingTimerSheetProps {
    onEnd: (progress?: boolean) => void;
}

export const TimerContextProvider: React.FC<TimerContextProviderProps> = ({ children }) => {
    const theme = useTheme();
    const { sounds } = useSoundPlayer();
    const [timerState, setTimerState] = useState<TimerState>({
        isOpen: false,
        type: 'training',
        initialDuration: 0,
        title: '',
        onFinish: () => {},
        onEnd: () => {},
    });

    const finishRunningTimer = useCallback(() => {
        if (timerState.isOpen) {
            timerState.onEnd();
        }
    }, [timerState]);

    const close = useCallback(() => {
        sounds.tik.stop();
        setTimerState((currentTimerState) => ({ ...currentTimerState, isOpen: false }));
    }, [sounds.tik]);

    const closeModal = useCallback(() => {
        timerState?.onEnd();
        close();
    }, [timerState, close]);

    const startRest = useCallback(
        ({ id, title, subtitle, series, duration, onEnd }: StartRestParams) => {
            finishRunningTimer();

            setTimerState({
                id,
                isOpen: true,
                type: 'rest',
                initialDuration: duration,
                title,
                subtitle,
                series,
                onEnd: () => {
                    onEnd?.();
                },
                onFinish: (progress, finished) => {
                    if (finished) {
                        sounds.restEnd.play();
                    }
                    onEnd?.();
                    close();
                },
            });
        },
        [sounds.restEnd, finishRunningTimer, close],
    );

    const startCardio = useCallback(
        ({ id, title, duration, countdownDuration, onEnd, onSkip }: StartCardioParams) => {
            finishRunningTimer();
            sounds.tik.playInLoop();

            setTimerState({
                id,
                isOpen: true,
                type: 'countdown',
                initialDuration: countdownDuration,
                title,
                onEnd: () => {
                    onSkip?.();
                    sounds.tik.stop();
                },
                onFinish: () => {
                    sounds.tik.stop();

                    setTimeout(() => {
                        setTimerState((countdownTimeState) => ({
                            ...countdownTimeState,
                            type: 'training',
                            initialDuration: duration,
                            onEnd: () => {
                                onEnd?.();
                            },
                            onFinish: (progress, finished) => {
                                if (finished) {
                                    sounds.cardioEnd.play();
                                }

                                onEnd?.(progress);
                                close();
                            },
                        }));
                    });
                },
            });
        },
        [sounds.tik, sounds.cardioEnd, finishRunningTimer, close],
    );

    const value: TimerContextType = useMemo(
        () => ({
            startRest,
            startCardio,
            close,
        }),
        [startRest, startCardio, close],
    );

    return (
        <TimerContext.Provider value={value}>
            {children}
            {theme.isWebDesktop ? (
                <TrainingTimerModal {...timerState} onClose={closeModal} />
            ) : (
                <TrainingTimerSheet {...timerState} />
            )}
        </TimerContext.Provider>
    );
};

export const useTimer = () => useContext(TimerContext);
