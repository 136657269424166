import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    border-radius: 16px;
    border: 2px solid ${({ theme }) => theme.border[12]};
    padding: 16px 20px 20px;
`;

export const TitleContainer = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.semiBold16};
    color: ${({ theme }) => theme.text.primary};
    margin-right: 4px;
`;

export const IconWrapper = styled(View)``;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
    margin-bottom: 12px;
`;

export const MacroList = styled(View)``;

export const MacroItem = styled(View)<{ last?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ last }) => (last ? 0 : 4)}px;
`;

export const MacroTitle = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
    margin-right: 4px;
`;

export const MacroValue = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.primary};
`;
