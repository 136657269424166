import { StyleProp, ViewStyle } from 'react-native';

import { mapReactionTypeToEmoji } from './DayBarReaction.const';
import * as S from './DayBarReaction.styled';

type DayBarReactionProps =
    | {
          emoji: 'workout' | 'break' | 'today';
          style?: StyleProp<ViewStyle>;
          count?: never;
      }
    | {
          emoji?: never;
          count: number;
          style?: StyleProp<ViewStyle>;
      };

export const DayBarReaction = ({ style, count, emoji }: DayBarReactionProps) => {
    return (
        <S.Container style={style}>
            {emoji && <S.IconImage source={mapReactionTypeToEmoji[emoji]} />}
            {count && (
                <S.CountContainer>
                    <S.Count>+{count}</S.Count>
                </S.CountContainer>
            )}
        </S.Container>
    );
};
