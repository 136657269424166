import uuid from 'react-native-uuid';

import { getCalendarWeeks } from './utils';

export type Meal = {
    id: string;
    name: string;
    imageSrc?: string;
    substitutes?: Meal[];
};

const mealsNames: Meal['name'][] = [
    'Jaglanka na mleku roślinnym z gruszką i jabłkiem',
    'Ryż z kurczakiem z warzywami po meksykańsku',
    'Sałatka z tuńczykiem, jajkiem i awokado',
    'Tofu w sosie teriyaki z pieczonymi warzywami',
    'Kurczak w jogurtowym sosie z kuminem i kolendrą',
    'Quinoa z warzywami i krewetkami',
    'Placki z batatów z sosem jogurtowo-ziołowym',
    'Zupa krem z brokułów i kalafiora',
    'Pierś z kurczaka z pieczonymi batatami',
    'Sałatka grecka z dodatkiem oliwek i sera feta',
    'Makaron pełnoziarnisty z pesto z rukoli i orzechów włoskich',
    'Krewetki w sosie czosnkowo-ziołowym z pieczonymi warzywami',
    'Kasza gryczana z pieczonymi warzywami i kozim serem',
    'Tatar z awokado i łososia na chrupiących paluszkach ryżowych',
    'Warzywa grillowane z dipem jogurtowo-ziołowym',
    'Kurczak po marokańsku z suszonymi owocami',
    'Zupa minestrone z dodatkiem ciecierzycy',
    'Pieczone bataty z kotletem jagnięcym',
    'Sałatka z mango, krewetkami i awokado',
    'Ryż basmati z warzywami i indykiem',
    'Sałatka z ciecierzycą, pomidorami i fetą',
    'Pstrąg pieczony z cytryną i ziołami',
    'Kasza jaglana z duszonymi warzywami',
    'Pieczone piersi z kurczaka z sosem jogurtowym i ziołami',
    'Placki z cukinii z jogurtem greckim',
    'Tatar z łososia podany na chrupiących grzankach',
    'Zupa krem z dyni z imbirem i prażonymi pestkami dyni',
    'Kuskus z warzywami i kurczakiem',
    'Sałatka z kolorową kapustą, granatem i orzechami',
    'Kurczak duszony z warzywami w sosie pomidorowym',
];

const imageSources: Meal['imageSrc'][] = [
    'https://plus.unsplash.com/premium_photo-1663047707111-c022dee3abe7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bWVhbHxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1519708227418-c8fd9a32b7a2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWVhbHxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1564436872-f6d81182df12?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bWVhbHxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1564671165093-20688ff1fffa?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1574484284002-952d92456975?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1592417817098-8fd3d9eb14a5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1555126634-323283e090fa?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://plus.unsplash.com/premium_photo-1679435691497-25855eb4bf7f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjF8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1587116861219-230ac19df971?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1606258868842-79eb9eff76af?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1619371042685-827b1c646923?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzd8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1511994714008-b6d68a8b32a2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDJ8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1613844237701-8f3664fc2eff?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1560684352-8497838a2229?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTB8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1605311572312-a926afe51604?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Njd8fG1lYWx8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1525351484163-7529414344d8?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YnJlYWtmYXN0fGVufDB8fDB8fHww',
    'https://images.unsplash.com/photo-1484723091739-30a097e8f929?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YnJlYWtmYXN0fGVufDB8fDB8fHww',
    'https://images.unsplash.com/photo-1581559178851-b99664da71ba?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGJyZWFrZmFzdHxlbnwwfHwwfHx8MA%3D%3D',
    'https://plus.unsplash.com/premium_photo-1669559809547-6e4c4fe41371?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjl8fGJyZWFrZmFzdHxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1499638309848-e9968540da83?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fGJyZWFrZmFzdHxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1627308594190-a057cd4bfac8?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDh8fGJyZWFrZmFzdHxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1510693206972-df098062cb71?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjZ8fGJyZWFrZmFzdHxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1604152135912-04a022e23696?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGRpZXQlMjBmb29kfGVufDB8fDB8fHww',
    'https://images.unsplash.com/photo-1563379926898-05f4575a45d8?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHN1cHBlcnxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1571809839227-b2ac3d261257?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHN1cHBlcnxlbnwwfHwwfHx8MA%3D%3D',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
];

const generateMealsData = (mealsCount = 5, withSubstitutes?: boolean): Meal[] => {
    const generatedMeals: Meal[] = [];

    for (let i = 0; i < mealsCount; i++) {
        const randomIndex = Math.floor(Math.random() * mealsNames.length);
        const name = mealsNames[randomIndex];
        const imageSrc = imageSources[randomIndex];

        generatedMeals.push({
            id: uuid.v4().toString(),
            name,
            imageSrc,
            substitutes: withSubstitutes ? generateMealsData(8) : [],
        });
    }

    return generatedMeals;
};

const generateDaysData = (calendarWeeks: ReturnType<typeof getCalendarWeeks>) => {
    const daysData = [];

    for (const week of calendarWeeks) {
        const weekStart = new Date(week.startDate);
        const weekEnd = new Date(week.endDate);

        for (let currentDate = weekStart; currentDate <= weekEnd; currentDate.setDate(currentDate.getDate() + 1)) {
            const isActive = currentDate >= new Date(mockedPlanStartDate) && currentDate <= new Date(mockedPlanEndDate);
            const dayData = {
                active: isActive,
                date: currentDate.toISOString(),
                nutritionData: getNutritionData(),
                meals: generateMealsData(5, true),
            };
            daysData.push(dayData);
        }
    }

    return daysData;
};

const getNutritionData = () =>
    [
        Math.floor(Math.random() * 50 + 175),
        Math.floor(Math.random() * 50 + 300),
        Math.floor(Math.random() * 20 + 90),
        Math.floor(Math.random() * 200 + 3500),
    ] as [number, number, number, number];

export type DayData = {
    active: boolean;
    date: string;
    nutritionData: [number, number, number, number];
    meals: Meal[];
};

export type DietPlan = {
    readonly id: string;
    readonly startDate?: string | null;
    readonly endDate?: string | null;
    readonly daysData?: DayData[];
};

const getDateRange = (days: number, end?: boolean) => {
    const today = new Date();
    const targetDate = new Date();
    targetDate.setDate(today.getDate() + days);
    targetDate.setHours(0, 0, 0, 0);
    targetDate.setUTCHours(0, 0, 0, 0);

    if (end) targetDate.setHours(23, 59, 59, 999);

    return targetDate.toISOString();
};

const mockedPlanStartDate = getDateRange(-14);
const mockedPlanEndDate = getDateRange(21, true);

export const mockedDietPlans: DietPlan[] = [
    {
        id: '1',
        startDate: null,
        endDate: null,
        daysData: [],
    },
    {
        id: '2',
        startDate: mockedPlanStartDate,
        endDate: mockedPlanEndDate,
        daysData: generateDaysData(getCalendarWeeks(mockedPlanStartDate, mockedPlanEndDate)),
    },
];
