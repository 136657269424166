import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '~/components/button';
import { FormControl } from '~/components/formControl';
import { PasswordInput } from '~/components/passwordInput';
import { SubmittableForm } from '~/components/submittableForm/SubmittableForm';

import { ChangePasswordFormSchema } from './ChangePasswordForm.schema';
import * as S from './ChangePasswordForm.styled';
import { FormValues } from './ChangePasswordForm.types';

interface ChangePasswordFormProps {
    onSubmit: SubmitHandler<FormValues>;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onSubmit }) => {
    const { control, handleSubmit, watch, formState, trigger, reset } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: yupResolver(ChangePasswordFormSchema),
        defaultValues: {
            newPassword: '',
            confirmNewPassword: '',
            oldPassword: '',
        },
    });
    const oldPassword = watch('oldPassword');

    useEffect(() => {
        //Revalidate new password when old password is provided
        if (oldPassword) {
            trigger('newPassword');
        }
    }, [oldPassword, trigger]);

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset();
        }
    }, [formState.isSubmitSuccessful, reset]);

    return (
        <S.Container>
            <SubmittableForm
                disabled={!formState.isValid}
                onSubmit={(e) => {
                    handleSubmit(onSubmit)(e);
                }}
            >
                <S.InputWrapper>
                    <Controller
                        name="newPassword"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <PasswordInput
                                    label="Nowe hasło"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    onChangeText={onChange}
                                    textContentType="newPassword"
                                />
                            </FormControl>
                        )}
                    />
                </S.InputWrapper>
                <S.InputWrapper>
                    <Controller
                        name="confirmNewPassword"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <PasswordInput
                                    label="Powtórz nowe hasło"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    onChangeText={onChange}
                                    textContentType="newPassword"
                                />
                            </FormControl>
                        )}
                    />
                </S.InputWrapper>
                <S.InputWrapper>
                    <Controller
                        name="oldPassword"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <PasswordInput
                                    label="Stare hasło"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    onChangeText={onChange}
                                    textContentType="password"
                                />
                            </FormControl>
                        )}
                    />
                </S.InputWrapper>
                <Button
                    size="m"
                    fullWidth
                    loading={formState.isSubmitting}
                    disabled={!formState.isValid}
                    onPress={handleSubmit(onSubmit)}
                >
                    Zapisz
                </Button>
            </SubmittableForm>
        </S.Container>
    );
};
