import { ScrollView, View } from 'react-native';
import styled, { css } from 'styled-components/native';

import { ContentProps } from './MeasurementForm.types';

export const Content = styled(ScrollView)<ContentProps>`
    padding: 0 16px ${({ inset }) => inset.bottom || 0}px;
`;

export const PhotosSection = styled(View)`
    margin-top: 8px;
`;

export const PhotoTilesContainer = styled(View)`
    display: flex;
    flex-direction: row;
`;

export const PhotoTileWrapper = styled(View)<{ last?: boolean }>`
    flex: 1;
    aspect-ratio: 122 / 210;
    margin-top: 24px;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;

export const MeasurementsSection = styled(View)`
    margin-top: 40px;
`;

export const InputsContainer = styled(View)`
    padding-top: 4px;
    width: 100%;
    align-self: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            max-width: 343px;
        `}
`;

export const InputWrapper = styled(View)`
    margin-top: 20px;
`;

export const SubmitButtonWrapper = styled(View)`
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const LoaderWrapper = styled(View)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
