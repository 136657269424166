import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTimer } from '~/hooks/useTimer';

import * as S from './TrainingTimerModal.styled';
import { TrainingTimerModalProps } from './TrainingTimerModal.types';
import { TrainingTimerModalContent } from './trainingTimerModalContent';

export const TrainingTimerModal: FC<TrainingTimerModalProps> = ({ id, type, initialDuration, isOpen, onFinish }) => {
    const closeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const { time, progress, duration, resetTimer, increaseDuration, decreaseDuration, stopTimer } = useTimer({
        initialDuration,
        isActive: isOpen,
        onFinish: (finishedProgress) => {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = setTimeout(() => {
                onFinish(finishedProgress, true);
                resetTimer();
            }, 1000);
        },
    });

    const handleClose = () => {
        onFinish(progress, false);
        stopTimer();
    };

    useEffect(() => {
        resetTimer();
    }, [id, initialDuration, type, resetTimer]);

    useEffect(() => {
        return () => clearTimeout(closeTimeoutRef.current);
    }, []);

    const closeButtonLabel = useMemo(() => {
        if (type === 'countdown') {
            return 'Rozpocznij teraz';
        }
        return 'Zakończ teraz';
    }, [type]);

    const handleTimeIncrease = useCallback(() => {
        increaseDuration(10);
        clearInterval(closeTimeoutRef.current);
    }, [increaseDuration]);

    const handleTimeDecrease = useCallback(() => {
        decreaseDuration(10);
    }, [decreaseDuration]);

    if (!isOpen) return null;

    return createPortal(
        <S.Container style={S.shadowStyles.shadow}>
            <TrainingTimerModalContent
                type={type}
                time={time}
                progress={progress}
                duration={duration}
                closeButtonLabel={closeButtonLabel}
                onTimeIncrease={handleTimeIncrease}
                onTimeDecrease={handleTimeDecrease}
                onClose={handleClose}
            />
        </S.Container>,
        document.body,
    );
};
