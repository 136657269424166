import { useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import { Tabs } from '../tabs';
import * as S from './ShoppingTabs.styled';

type TabType = {
    label: string;
    value: 'categories' | 'recipes';
};

const tabs: TabType[] = [
    { label: 'Według kategorii', value: 'categories' },
    { label: 'Według przepisów', value: 'recipes' },
];

type ShoppingTabsProps = {
    style?: StyleProp<ViewStyle>;
};

export const ShoppingTabs = ({ style }: ShoppingTabsProps) => {
    const [selectedTab, setSelectedTab] = useState<TabType['value']>('categories');
    const handleOnSelectTab = (value: TabType['value']) => {
        setSelectedTab(value);
        Emitter.emit(EmitterEvent.shoppingListTabChange, { value });
    };

    return (
        <S.TabsWrapper style={style}>
            <Tabs tabs={tabs} activeTab={selectedTab} onSelectTab={handleOnSelectTab} wide />
        </S.TabsWrapper>
    );
};
