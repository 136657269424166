import { TrainingRules } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const fetchTrainingRules: QueryFunction<TrainingRules | null, ['getTrainingRules', string | undefined]> = async ({
    queryKey,
}) => {
    try {
        const [_, id] = queryKey;

        if (!id) {
            return null;
        }

        return (await DataStore.query(TrainingRules, id)) ?? null;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const useGetTrainingRules = (id?: string) => useQuery(['getTrainingRules', id], fetchTrainingRules);
