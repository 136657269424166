import { AsyncCollection, AsyncItem, ModelInit } from '@aws-amplify/datastore';
import { PersistentModel, PersistentModelConstructor } from '@aws-amplify/datastore/lib-esm/types';
import { InfiniteData } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

export type KeyOfType<T, U> = NonNullable<
    {
        [K in keyof T]: [U] extends [T[K]] ? (T[K] extends U ? K : never) : never;
    }[keyof T]
>;

export const groupModels = <T extends ModelInit<any>>(models: T[], key: keyof T = 'id'): Record<T[keyof T], T> =>
    models.reduce((acc, model) => {
        acc[model[key]] = model;
        return acc;
    }, {} as Record<T[keyof T], T>);

export function flattenPages<T>(pages: InfiniteData<T[]>['pages'] = []): T[] {
    return pages?.flatMap((page) => page);
}

export async function resolveModelNestedField<T extends ModelInit<any>, U extends ModelInit<any>>(
    model: T,
    fieldName: KeyOfType<T, AsyncItem<U>>,
): Promise<U | null> {
    return (await model[fieldName]) ?? null;
}

export async function resolveModelsNestedField<T extends ModelInit<any>, U extends ModelInit<any>>(
    models: T[],
    fieldName: KeyOfType<T, AsyncItem<U>>,
): Promise<U[]> {
    return (await Promise.all(models.map((model) => resolveModelNestedField(model, fieldName))))
        .filter(Boolean)
        .flat() as U[];
}

export async function resolveModelArray<T extends ModelInit<any>, U extends ModelInit<any>>(
    model: T,
    fieldName: KeyOfType<T, AsyncCollection<U>>,
): Promise<U[]> {
    const field: AsyncCollection<U> = model[fieldName];
    return field.toArray();
}

export async function resolveModelsArray<T extends ModelInit<any>, U extends ModelInit<any>>(
    models: T[],
    fieldName: KeyOfType<T, AsyncCollection<U>>,
): Promise<U[]> {
    const resolvedPromises = await Promise.all(models.map((model) => resolveModelArray(model, fieldName)));
    return resolvedPromises.flat();
}

export async function resolveModelNestedIdField<T extends ModelInit<any>, U extends PersistentModel>(
    data: T,
    fieldName: keyof T,
    model: PersistentModelConstructor<U>,
): Promise<U | null> {
    const id = data[fieldName];

    if (!id) {
        return null;
    }

    const returned = await DataStore.query(model, id);
    return returned as U;
}

export async function resolveModelsNestedIdField<T extends ModelInit<any>, U extends PersistentModel>(
    data: T[],
    fieldName: keyof T,
    model: PersistentModelConstructor<U>,
): Promise<U[]> {
    const promises = data.map(async (item) => await resolveModelNestedIdField(item, fieldName, model));
    return (await Promise.all(promises)).filter(Boolean) as U[];
}
