import { registerRootComponent } from 'expo';
import { activateKeepAwake } from 'expo-keep-awake';
import 'expo/build/Expo.fx';
import { createRoot } from 'react-dom/client';
import { Platform } from 'react-native';

import App from './src';

if (__DEV__) {
    activateKeepAwake();
}

if (Platform.OS === 'web') {
    if (typeof window !== 'undefined') {
        window._frameTimestamp = null;
    }

    const rootElement = document.getElementById('root') ?? document.getElementById('main');
    const root = createRoot(rootElement);

    const modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'dropdown-root');
    rootElement.parentNode.appendChild(modalRoot);

    root.render(<App />);
} else {
    registerRootComponent(App);
}
