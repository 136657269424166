import React from 'react';
import Animated from 'react-native-reanimated';
import { Icon, IconProps } from '~/components/icon';
import { PressableIconAnimations } from '~/components/pressableIcon/PressableIcon.animated';

import * as S from './PressableIcon.styled';

export interface PressableIconProps extends IconProps {
    pressedColor: string;
    hoverColor?: string;
    pressableAnimationProgress: number;
}

const AnimatedIcon = Animated.createAnimatedComponent(S.IconWrapper);

export const PressableIcon: React.FC<PressableIconProps> = ({
    name,
    size,
    color,
    pressedColor,
    hoverColor,
    pressableAnimationProgress,
}) => {
    return (
        <S.Container size={size}>
            <PressableIconAnimations progress={pressableAnimationProgress}>
                {({ pressedIconAnimatedStyles, hoveredIconAnimatedStyles }) => (
                    <>
                        <S.IconWrapper>
                            <Icon name={name} size={size} color={color} />
                        </S.IconWrapper>
                        <AnimatedIcon style={hoveredIconAnimatedStyles}>
                            <Icon name={name} size={size} color={hoverColor ?? color} />
                        </AnimatedIcon>
                        <AnimatedIcon style={pressedIconAnimatedStyles}>
                            <Icon name={name} size={size} color={pressedColor} />
                        </AnimatedIcon>
                    </>
                )}
            </PressableIconAnimations>
        </S.Container>
    );
};
