import { Pressable, Text as TextBase, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme/mixins';
import { HoverableProps } from '~/types/hoverable';

interface ButtonHoverableStyledProps extends HoverableProps {}

export const Container = styled(Pressable)<ButtonHoverableStyledProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
`;

const columnGap = 20;

export const TextContent = styled(View)`
    flex: 1;
    margin-left: ${columnGap}px;
`;

export const Text = styled(TextBase)`
    ${mixins.typography.ui.medium16};
    color: ${({ theme }) => theme.text.primary};
    margin-right: ${columnGap}px;
`;

export const Description = styled(TextBase)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.tertiary};
    margin-right: ${columnGap}px;
    margin-top: 4px;
`;

export const Counter = styled(TextBase)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.primary};
`;
