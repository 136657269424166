import { Text as TextBase, View } from 'react-native';
import { FlattenInterpolation } from 'styled-components';
import styled, { DefaultTheme, ThemeProps, css } from 'styled-components/native';
import { mixins } from '~/theme';

import { InfoBoxIconPosition, InfoBoxStyledProps, InfoBoxVariant } from './InfoBox.types';

const backgroundByVariant: Record<InfoBoxVariant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    warning: css`
        background-color: ${({ theme }) => theme.fill.warning};
        color: ${({ theme }) => theme.text.criticalSurface};
    `,
    informative: css`
        background-color: ${({ theme }) => theme.fill.informative};
        color: ${({ theme }) => theme.text.interactiveSurface};
    `,
    critical: css`
        background-color: ${({ theme }) => theme.fill.critical};
        color: ${({ theme }) => theme.text.criticalSurface};
    `,
};

export const InfoBoxContainer = styled(View)<InfoBoxStyledProps>`
    align-items: flex-start;
    flex-direction: row;
    padding: 20px;
    border-radius: 8px;

    ${({ variant }) => backgroundByVariant[variant]};

    ${({ iconPosition }) =>
        iconPosition === 'top' &&
        css`
            flex-direction: column;
        `};
`;

export const Content = styled(View)`
    flex: 1;
`;

export const Text = styled(TextBase)`
    ${mixins.typography.p.regular16};
    color: inherit;
`;

const iconStyleByPosition: Record<InfoBoxIconPosition, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    left: css`
        margin-right: ${({ theme }) => (theme.isWebDesktop ? 20 : 16)}px;
    `,
    right: css`
        margin-left: ${({ theme }) => (theme.isWebDesktop ? 20 : 16)}px;
        order: 2;
    `,
    top: css`
        margin-bottom: 20px;
    `,
};

export const IconWrapper = styled(View)<{ iconPosition: InfoBoxIconPosition }>`
    ${({ iconPosition }) => iconStyleByPosition[iconPosition]};
`;
