import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { useAuth } from '~/enhancers/auth.context';
import { useRedirection } from '~/navigation/root/hooks/useRedirection';
import { NotFound } from '~/screens/notFound/NotFound';

import { AuthNavigation } from '../auth';
import { HomeNavigation } from '../home';
import { RootParamsList } from './RootNavigation.types';

const Stack = createNativeStackNavigator<RootParamsList>();

export const RootNavigation = () => {
    const auth = useAuth();
    useRedirection();

    return (
        <Stack.Navigator screenOptions={{ header: () => null, contentStyle: { flexDirection: 'row' } }}>
            {!auth.isAuthenticated ? (
                <Stack.Screen name="Auth" component={AuthNavigation} />
            ) : (
                <Stack.Screen name="Home" component={HomeNavigation} />
            )}
            <Stack.Screen name="NotFound" component={NotFound} />
        </Stack.Navigator>
    );
};
