import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Header = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;

export const Subheader = styled(Text)`
    ${mixins.typography.h.semiBold18};
    color: ${({ theme }) => theme.text.primary};
`;

export const Label = styled(Text)`
    ${mixins.typography.h.semiBold16};
    color: ${({ theme }) => theme.text.primary};
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
    margin-top: 4px;
`;

export const Description = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
    margin-top: 4px;
`;

export const Wrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
`;

export const Content = styled(View)`
    flex: 1;
`;

export const LeftIconWrapper = styled(Pressable)`
    margin-right: 12px;
`;

export const RightIconWrapper = styled(Pressable)`
    margin-left: auto;
`;

export const LeftContentWrapper = styled(View)`
    margin-right: 12px;
`;

export const RightContentWrapper = styled(View)`
    flex-direction: row;
`;
