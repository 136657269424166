import { View, ViewProps } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';
import styled, { css } from 'styled-components/native';

import { Toast as ToastBase } from '../toast';

export type ContainerProps = {
    inset: EdgeInsets;
    fullScreen?: boolean;
} & ViewProps;

export const Container = styled(View)<ContainerProps>`
    position: absolute;
    left: 20px;
    right: 20px;
`;

export const ToastContainer = styled(View)<ContainerProps>`
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;

    ${({ theme, inset }) =>
        !theme.isWeb &&
        css`
            bottom: ${inset.bottom}px;
        `};

    ${({ fullScreen, inset }) =>
        fullScreen &&
        css`
            flex: 1;
            top: ${inset?.top}px;
        `}
`;

export const Toast = styled(ToastBase)<{ fullScreen?: boolean }>`
    border-radius: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        `}

    ${({ fullScreen }) =>
        fullScreen &&
        css`
            flex: 1;
            border-radius: 20px;
            padding: 48px 32px;
        `}
`;
