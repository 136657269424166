import React from 'react';

import * as S from './TrainingAxisHeader.styled';

export interface TrainingAxisProps {
    title: string;
}
export const TrainingAxisHeader: React.FC<TrainingAxisProps> = ({ title }) => {
    return (
        <S.Container>
            <S.Title>{title}</S.Title>
        </S.Container>
    );
};
