import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { useGetCurrentGoal } from '~/api/goal/useGetCurrentGoal';
import { Button } from '~/components/button';
import { SectionHeading } from '~/components/sectionHeading';
import { TileGradientButton } from '~/components/tileGradientButton';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useMeasurement } from '~/enhancers/measurement.context';
import { MainLayout } from '~/layouts/web';
import { DashboardComingSoon } from '~/screens/dashboard/components/dashboardComingSoon';

import { ActivityCalendarContent } from '../activityCalendar/components/activityCalendarContent';
import DashboardScreenMobile from './DashboardScreen.native';
import * as S from './DashboardScreen.styled';
import { WeeklyActivity } from './components/WeeklyActivity';
import { Target } from './components/target';

const title = 'Dashboard';

export const DashboardScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();
    const { data: currentGoal } = useGetCurrentGoal();
    const { addMeasurement } = useMeasurement();
    const [displaySidebar, setDisplaySidebar] = useState(false);

    const content = (
        <S.Container>
            <S.HeaderContainer>
                <SectionHeading variant="header" title={title} />
                {features.activity && !theme.media.desktop && (
                    <Button size="m" variant="secondary" onPress={() => setDisplaySidebar(true)}>
                        Kalendarz aktywności
                    </Button>
                )}
            </S.HeaderContainer>
            <S.ContentWrapper>
                <S.Content>
                    {/* TODO: Change it to grid */}
                    <S.LeftContainer>
                        <S.DashboardItemContainer>
                            <Target {...currentGoal} />
                        </S.DashboardItemContainer>

                        {features.measurements && (
                            <S.DashboardItemContainer>
                                <TileGradientButton icon="ruler-fill" label="Dodaj pomiar" onPress={addMeasurement} />
                            </S.DashboardItemContainer>
                        )}
                    </S.LeftContainer>

                    <S.RightContainer>
                        {features.activity && (
                            <S.ActivityContainer>
                                <WeeklyActivity />
                            </S.ActivityContainer>
                        )}
                    </S.RightContainer>
                </S.Content>
                {!features.activity && (
                    <S.ComingSoonContainer>
                        <DashboardComingSoon />
                    </S.ComingSoonContainer>
                )}
            </S.ContentWrapper>
        </S.Container>
    );

    if (theme.isWebDesktop) {
        return (
            <MainLayout
                noBackground={!theme.isWebDesktop}
                title="Dashboard"
                headerHidden
                content={content}
                sidebar={
                    features.activity
                        ? {
                              visible: displaySidebar,
                              title: 'Kalendarz aktywności',
                              content: <ActivityCalendarContent />,
                              onClose: () => setDisplaySidebar(false),
                          }
                        : undefined
                }
            />
        );
    }

    return <DashboardScreenMobile />;
};
