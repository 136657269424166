import {
    CardioExerciseData,
    ExerciseData,
    FinishedSeriesData,
    TrainingCardioExercise,
    TrainingDay,
    TrainingDayGroup,
    TrainingExercise,
} from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';
import { groupModels, resolveModelsArray, resolveModelsNestedField, resolveModelsNestedIdField } from '~/utils/api';

export interface TrainingPlanData {
    data: TrainingDay[];
    trainingExercisesData: ExerciseData[];
    trainingDayGroups: TrainingDayGroup[];
    trainingExercises: TrainingExercise[];
    cardioExercises: TrainingCardioExercise[];
    finishedSeries: FinishedSeriesData[];
    finishedCardioExercises: CardioExerciseData[];
    finishedCardioExercisesMap: Record<string, CardioExerciseData>;
    trainingExercisesDataMap: Record<string, ExerciseData>;
}

const fetchTrainingDaysByTraining: QueryFunction<
    TrainingPlanData | null,
    ['getTrainingDaysByTrainingId', string | null]
> = async ({ queryKey }) => {
    const [_, trainingId] = queryKey;
    try {
        if (!trainingId) return null;

        const trainingDays = await DataStore.query(TrainingDay, (c) => c.trainingID.eq(trainingId), {
            sort: (condition) => condition.cycle(SortDirection.ASCENDING),
        });

        const trainingDayGroups = await resolveModelsArray<TrainingDay, TrainingDayGroup>(
            trainingDays,
            'TrainingDayGroups',
        );

        const trainingExercises = await resolveModelsArray<TrainingDayGroup, TrainingExercise>(
            trainingDayGroups,
            'TrainingExercises',
        );

        const trainingExercisesData = await resolveModelsNestedField<TrainingExercise, ExerciseData>(
            trainingExercises,
            'ExerciseData',
        );

        const finishedSeries = await resolveModelsArray<TrainingExercise, FinishedSeriesData>(
            trainingExercises,
            'FinishedSeriesData',
        );

        const cardioExercises = await resolveModelsArray<TrainingDayGroup, TrainingCardioExercise>(
            trainingDayGroups,
            'TrainingCardioExercises',
        );

        const finishedCardioExercises = await resolveModelsNestedIdField<TrainingCardioExercise, CardioExerciseData>(
            cardioExercises,
            'trainingCardioExerciseFinishedCardioExerciseDataId',
            CardioExerciseData,
        );

        return {
            data: trainingDays,
            trainingDayGroups,
            trainingExercises,
            cardioExercises,
            finishedSeries,
            trainingExercisesData,
            finishedCardioExercises,
            trainingExercisesDataMap: groupModels(trainingExercisesData),
            finishedCardioExercisesMap: groupModels(finishedCardioExercises),
        };
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const useGetTrainingDaysByTraining = (trainingId: string | null, enabled: boolean) =>
    useQuery(['getTrainingDaysByTrainingId', trainingId], fetchTrainingDaysByTraining, {
        enabled,
    });
