import { Pressable, Text } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme/mixins';

import { TagStyledProps } from './Tag.types';

export const Container = styled(Pressable)<TagStyledProps>`
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid;
    user-select: none;

    ${({ size }) =>
        size === 'small' &&
        css`
            padding: 3px 8px;
        `};

    ${({ size }) =>
        size === 'large' &&
        css`
            padding: 6px 12px;
        `};
`;

export const Label = styled(Text)<{ size: TagStyledProps['size'] }>`
    ${({ size }) => size === 'small' && mixins.typography.ui.regular12};

    ${({ size }) => size === 'large' && mixins.typography.ui.regular16};
`;
