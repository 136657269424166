import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ViewStyle } from 'react-native';
import {
    ComplexAnimationBuilder,
    FadeInDown,
    FadeInUp,
    FadeOutDown,
    FadeOutUp,
    useAnimatedStyle,
    useSharedValue,
    withSpring,
} from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig as commonSpringConfig } from '~/constants/animations';

export interface FadeAnimationProps {
    webExitingDelay?: number;
    direction?: 'up' | 'down';
    mass?: number;
    phase?: 'entering' | 'exiting';
    onExitingEnd?: () => void;
    onEnteringEnd?: () => void;
    children: ({
        style,
        entering,
        exiting,
    }: {
        style?: ViewStyle;
        entering?: ComplexAnimationBuilder;
        exiting?: ComplexAnimationBuilder;
    }) => React.ReactElement;
}

const directionDownConfig = {
    entering: { opacity: 1, translateY: 0 },
    exiting: { opacity: 0, translateY: -50 },
};

const directionUpConfig = {
    entering: { opacity: 1, translateY: 0 },
    exiting: { opacity: 0, translateY: 50 },
};

export const FadeAnimation = (props: FadeAnimationProps) => {
    const {
        children,
        mass = 0.5,
        direction = 'up',
        webExitingDelay,
        onExitingEnd,
        onEnteringEnd,
        phase: animationPhase,
    } = props;
    const theme = useTheme();
    const config = direction === 'up' ? directionUpConfig : directionDownConfig;
    const springConfig = useMemo<{ mass: number }>(() => ({ ...commonSpringConfig.default, mass }), [mass]);
    const exitingTimeout = useRef<ReturnType<typeof setTimeout>>();

    const opacity = useSharedValue(config.exiting.opacity);
    const posY = useSharedValue(config.exiting.translateY);

    const animate = useCallback(
        (phase: NonNullable<FadeAnimationProps['phase']>, onAnimationEnd?: () => void) => {
            opacity.value = withSpring(config[phase].opacity, springConfig);
            posY.value = withSpring(config[phase].translateY, springConfig, onAnimationEnd);
        },
        [opacity, posY, config, springConfig],
    );

    const startEntering = useCallback(() => {
        animate('entering', onEnteringEnd);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onEnteringEnd]);

    const startExiting = useCallback(() => {
        animate('exiting', onExitingEnd);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onEnteringEnd]);

    useEffect(() => {
        if (animationPhase === 'entering') {
            startEntering();
        }
        if (animationPhase === 'exiting') {
            startExiting();
        }
    }, [animationPhase, startEntering, startExiting]);

    useEffect(() => {
        if (theme.isWeb) {
            startEntering();

            if (webExitingDelay !== undefined) {
                exitingTimeout.current = setTimeout(() => {
                    startExiting();
                }, webExitingDelay);
            }

            return () => {
                clearTimeout(exitingTimeout.current);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startEntering, startExiting, webExitingDelay]);

    const animatedStyle = useAnimatedStyle(() => ({
        opacity: opacity.value * config.entering.opacity,
        transform: [
            {
                translateY: posY.value,
            },
        ],
    }));

    const animationConfig = useMemo(() => {
        if (theme.isWeb) {
            return {
                style: animatedStyle,
            };
        }

        if (direction === 'down') {
            return {
                entering: FadeInUp.springify().mass(springConfig.mass),
                exiting: FadeOutUp.springify().mass(springConfig.mass),
            };
        }

        return {
            entering: FadeInDown.springify().mass(springConfig.mass),
            exiting: FadeOutDown.springify().mass(springConfig.mass),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme.isWeb, animatedStyle]);

    return children(animationConfig);
};
