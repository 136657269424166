import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 240px;
    align-items: center;
    height: 100%;
    background-color: ${({ theme }) => theme.fill.level1};
    position: relative;
`;

export const Header = styled(View)`
    width: 100%;
    padding: 20px;
`;

export const HeaderText = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;

export const Menu = styled(View)`
    padding: 0 12px;
`;

export const AppInfoContainer = styled(View)`
    position: absolute;
    bottom: 12px;
    left: 20px;
    width: 100%;
`;

export const AppInfo = styled(Text)`
    ${mixins.typography.ui.regular11};
    color: ${({ theme }) => theme.text.disabled};
`;
