import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface MenuItemAnimationsRenderPropValue {
    backgroundColor: string;
}

export interface MenuItemAnimationsProps extends PressableAnimationsProps<MenuItemAnimationsRenderPropValue> {
    active?: boolean;
}

export const MenuItemBackgroundAnimation = ({ progress, active, children }: MenuItemAnimationsProps) => {
    const theme = useTheme();

    const backgroundProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const currentBackground: Record<string, string> = {
        default: theme.fill.background,
        hovered: theme.fill.level1,
        pressed: theme.fill.level1,
    };

    const animatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(
                active ? 2 : backgroundProgress.value,
                [0, 1, 2],
                [currentBackground.default, currentBackground.hovered, currentBackground.pressed],
            ),
        };
    }, []);

    return children(animatedStyle);
};
