import { Pressable, View } from 'react-native';
import styled from 'styled-components/native';

export const dotWidth = 24;
export const separatorWidth = 8;

export const Container = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;
`;

export const Bar = styled(View)<{ totalItems: number }>`
    width: ${({ totalItems }) => totalItems * dotWidth + (totalItems - 1) * separatorWidth}px;
    height: 2px;
    background: ${({ theme }) => theme.icon.disabled};
    position: relative;
`;

export const DotButtonsContainer = styled(View)`
    position: absolute;
    left: 0;
    top: -12px;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const DotButton = styled(Pressable)`
    width: ${dotWidth}px;
    height: 100%;
    padding: 12px 4px;
`;

export const Separator = styled(View)`
    height: 26px;
    width: ${separatorWidth}px;
    background: ${({ theme }) => theme.fill.level1};
`;

export const ActiveDot = styled(View)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${dotWidth}px;
    height: 2px;
    background: ${({ theme }) => theme.icon.interactive};
`;
