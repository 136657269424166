import { useFocusEffect, useLinkTo, useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { desktopBlacklistScreens } from '~/constants/path';
import { NotFoundNavigationProp } from '~/screens/notFound/NotFound.types';

export const useWebDesktopBlockedScreen = (screen: (typeof desktopBlacklistScreens)[number]) => {
    const { isWebDesktop } = useTheme();
    const navigation = useNavigation<NotFoundNavigationProp>();
    const linkTo = useLinkTo();

    useFocusEffect(() => {
        if (isWebDesktop) {
            setTimeout(() => {
                linkTo(`/${screen}`);
                navigation.navigate('NotFound');
            });
        }
    });
};
