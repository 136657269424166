import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)<{ last?: boolean }>`
    margin-bottom: ${({ last }) => (last ? 0 : 16)}px;
`;

export const InfoContainer = styled(View)`
    flex: 1;
    padding: 12px 16px;
    border-radius: 12px;
    margin-left: ${({ theme }) => (theme.isWebDesktop ? '20px' : '16px')};
    margin-right: 16px;
    margin-bottom: 16px;
    background: ${({ theme }) => theme.fill.informative};
    flex-basis: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

export const InfoText = styled(Text)`
    ${mixins.typography.ui.medium12};
    color: ${({ theme }) => theme.palette.neutral['00']};
`;

export const ExerciseContainer = styled(View)`
    flex: 1;
    padding: 12px 16px;
    border-radius: 16px;
    margin-left: ${({ theme }) => (theme.isWebDesktop ? '20px' : '16px')};
    margin-right: 16px;
    background: ${({ theme }) => theme.fill.level1};
    flex-basis: auto;
`;

export const Border = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 16px;
    border-width: 2px;
`;

export const TitleContainer = styled(View)`
    flex-direction: row;
    margin-bottom: 16px;
`;

export const InstructionButtonContainer = styled(View)`
    margin-top: 3px;
    margin-left: ${({ theme }) => (theme.isWebDesktop ? '20px' : '16px')};
`;

export const TitleText = styled(Text)`
    flex: 1;

    ${mixins.typography.h.semiBold18};
    color: ${({ theme }) => theme.text.primary};
`;

export const ExerciseItemHeaderContainer = styled(View)`
    margin-bottom: 16px;
`;
