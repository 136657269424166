import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { FC } from 'react';
import { StandardNavigation } from '~/components/topNavigation';

import * as S from './ShoppingListTopNavigation.styled';
import {useAppFeatures} from "~/enhancers/appFeaturesProvider";

export interface ShoppingListTopProps extends NativeStackHeaderProps {}
export const ShoppingListTopNavigation: FC<ShoppingListTopProps> = ({ options, navigation }) => {
    const { features } = useAppFeatures();
    const bottomContent = features.shoppingList ? <S.MobileTabs /> : undefined;

    const handleOnBackPress = () =>
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Tab', { screen: 'More' });

    return <StandardNavigation title={options.title} bottomContent={bottomContent} onBackPress={handleOnBackPress} />;
};
