import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const FullHeightContainer = styled(View)`
    flex: 1px;
    padding: 0 16px;
`;

export const SearchContainer = styled(View)`
    padding: 16px 0;
    background-color: ${({ theme }) => theme.fill.level1};
`;

export const SubtitleContainer = styled(View)`
    padding-bottom: 16px;
    background-color: ${({ theme }) => theme.fill.level1};
`;
export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
`;

export const LoaderContainer = styled(View)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
