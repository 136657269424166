import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, ListRenderItem } from 'react-native';
import { interpolateColor, useAnimatedScrollHandler, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { BottomSheetHandle } from '../bottomSheetHandle';
import {
    BottomSheetBackdrop,
    BottomSheetBackdropProps,
    BottomSheetFlatList,
    BottomSheetModal,
} from '../bottomSheetModal';
import * as S from './ExerciseGroupsSheet.styled';
import { ExerciseGroupDataItem } from './ExerciseGroupsSheet.types';

interface ExerciseGroupsSheetProps {
    data: ExerciseGroupDataItem[];
    isOpen: boolean;
    onItemPress: (item: ExerciseGroupDataItem) => void;
    onDismiss: () => void;
}

export const ExerciseGroupsSheet: FC<ExerciseGroupsSheetProps> = ({ data, isOpen, onItemPress, onDismiss }) => {
    const theme = useTheme();

    const translateY = useSharedValue(0);
    const bottomSheetModalRef = useRef<BottomSheetModal>(null);
    const Container = theme.isWeb ? FlatList : BottomSheetFlatList;
    const [isContentScrollEnabled, setIsContentScrollEnabled] = useState(false);

    useEffect(() => {
        if (isOpen) {
            bottomSheetModalRef.current?.present();
        } else {
            bottomSheetModalRef.current?.close();
        }
    }, [isOpen]);

    const scrollHandler = useAnimatedScrollHandler((event) => {
        translateY.value = event.contentOffset.y;
    });

    const topWrapperStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(translateY.value, [0, 100], [theme.fill.level2, theme.fill.level3]),
        };
    });

    const snapPoints = useMemo(() => ['50%', '90%'], []);

    const pressableState = usePressableState();

    const renderBackdrop = useCallback(
        ({ handleKeyUpdate, ...props }: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop
                {...props}
                appearsOnIndex={0}
                disappearsOnIndex={-1}
                opacity={0.8}
                onPress={() => {
                    onDismiss();
                    handleKeyUpdate();
                }}
            />
        ),
        [onDismiss],
    );

    const handleItemPress = useCallback(
        (item: ExerciseGroupDataItem) => {
            onItemPress(item);
            onDismiss();
        },
        [onItemPress, onDismiss],
    );

    const handleBottomSheetChange = useCallback((index: number) => {
        setIsContentScrollEnabled(index === 1);
    }, []);

    const renderItem: ListRenderItem<ExerciseGroupDataItem> = useCallback(({ item }) => {
        return (
            <S.ContentWrapper onPress={() => handleItemPress(item)}>
                <S.ItemLabel>{item.label}</S.ItemLabel>
                {item.isSelected && (
                    <S.ItemSelectedIcon>
                        <Icon name="check-fill" color={theme.icon.positive} size={20} />
                    </S.ItemSelectedIcon>
                )}
            </S.ContentWrapper>
        );
    }, []);

    const renderHandle = () => (
        <S.TopContainer style={topWrapperStyle}>
            <S.TopHandleWrapper>
                <BottomSheetHandle />
            </S.TopHandleWrapper>
            <S.TopContentWrapper>
                <S.TitleWrapper>
                    <S.Title>Wybierz kategorię</S.Title>
                </S.TitleWrapper>
                <S.ButtonWrapper>
                    <S.CloseButtonWrapper onPress={onDismiss}>
                        <PressableIcon
                            pressedColor={''}
                            pressableAnimationProgress={pressableState.pressableAnimationProgress}
                            name="close"
                            size={20}
                        />
                    </S.CloseButtonWrapper>
                </S.ButtonWrapper>
            </S.TopContentWrapper>
        </S.TopContainer>
    );

    return (
        <BottomSheetModal
            ref={bottomSheetModalRef}
            index={0}
            snapPoints={snapPoints}
            backdropComponent={renderBackdrop}
            handleComponent={renderHandle}
            handleIndicatorStyle={{ backgroundColor: theme.border[20] }}
            backgroundStyle={{ backgroundColor: theme.fill.level2 }}
            enableContentPanningGesture={theme.isWeb ? !isContentScrollEnabled : true}
            onDismiss={onDismiss}
            onChange={handleBottomSheetChange}
        >
            <Container
                data={data}
                keyExtractor={(item) => item.id}
                renderItem={renderItem}
                renderScrollComponent={(props) => {
                    return (
                        <S.MainContainer {...props} onScroll={scrollHandler} scrollEnabled={isContentScrollEnabled} />
                    );
                }}
            />
        </BottomSheetModal>
    );
};
