import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useGetCurrentGoal } from '~/api/goal/useGetCurrentGoal';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useMeasurement } from '~/enhancers/measurement.context';
import { MainLayout } from '~/layouts/native';
import { DashboardNavigationProp } from '~/screens/dashboard/DashboardScreen.types';
import { DashboardComingSoon } from '~/screens/dashboard/components/dashboardComingSoon';

import * as S from './DashboardScreen.styled.native';
import { WeeklyActivity } from './components/WeeklyActivity';
import { Target } from './components/target';

export const DashboardScreen = () => {
    const { features, canShowComingSoon } = useAppFeatures();
    const navigation = useNavigation<DashboardNavigationProp>();
    const { data: currentGoal, refetch } = useGetCurrentGoal();
    const { addMeasurement } = useMeasurement();

    useEffect(() => {
        const handler = () => {
            refetch();
        };

        navigation.addListener('focus', handler);

        return () => {
            navigation.removeListener('focus', handler);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainLayout>
            <S.TargetContainer>
                <Target {...currentGoal} />
            </S.TargetContainer>
            {features.activity && (
                <S.ActivityContainer>
                    <WeeklyActivity />
                </S.ActivityContainer>
            )}
            <S.ActionsContainer>
                <S.Button
                    icon="atlas-gym-fill"
                    label="Zrób trening"
                    onPress={() => {
                        navigation.navigate('Tab', {
                            screen: 'TrainingTabStack',
                            params: {
                                screen: 'TrainingPlan',
                            },
                        });
                    }}
                />
                {features.measurements && (
                    <S.Button last icon="ruler-fill" label="Dodaj pomiar" onPress={addMeasurement} />
                )}
            </S.ActionsContainer>

            {canShowComingSoon && <DashboardComingSoon />}
        </MainLayout>
    );
};

export default DashboardScreen;
