import { StyleSheet, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    position: absolute;
    border-radius: 12px;
    height: auto;
    width: 350px;
    background-color: ${({ theme }) => theme.fill.level1};
    height: 500px;
    top: -570px;
    left: -175px;
    overflow: hidden;
`;

export const Spacer = styled(View)`
    width: 20px;
    height: 20px;
`;

export const Header = styled(View)`
    background-color: ${({ theme }) => theme.fill.background};
    width: 100%;
    padding: 12px;
    flex-direction: row;
    justify-content: space-between;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold16}
    color: ${({ theme }) => theme.icon.primary};
`;

export const BaseStyle = StyleSheet.create({
    shadow: {
        shadowOpacity: 0.8,
        shadowRadius: 32,
        shadowOffset: {
            height: 12,
            width: 0,
        },
        shadowColor: 'black',
    },
});
