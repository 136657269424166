import { View } from 'react-native';
import styled from 'styled-components/native';

export const MenuItem = styled(View)<{ active?: boolean; last?: boolean }>`
    width: 64px;
    height: 64px;
    align-items: center;
    justify-content: center;
    background-color: ${({ active, theme }) => (active ? theme.fill.level1 : theme.fill.background)};
    cursor: pointer;
`;
