import { Image, Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '~/components/button';
import { mixins } from '~/theme';

import { BottomSheetModal } from '../bottomSheetModal';

export const BottomSheet = styled(BottomSheetModal)`
    border-radius: 20px;
`;

export const HandleContainer = styled(View)`
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 0;
    padding: 8px 0;
`;

export const Container = styled(View)`
    padding: 54px 32px 16px;
`;

export const IconContainer = styled(View)`
    margin-bottom: 32px;
`;

export const IconImage = styled(Image)`
    width: 48px;
    height: 48px;
`;

export const TitleContainer = styled(View)`
    margin-bottom: 32px;
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.secondary};
    margin-top: 12px;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;

export const ButtonContainer = styled(View)`
    margin-bottom: 20px;
`;

export const DesktopModalContainer = styled(View)<{ maxWidth?: number }>`
    padding: 24px 32px;
    min-width: 530px;

    ${({ maxWidth }) =>
        maxWidth &&
        `
        max-width: ${maxWidth}px;
    `}
`;

export const DesktopButtons = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const DesktopButtonSeparator = styled(View)`
    flex: none;
    width: 20px;
`;

export const DesktopButton = styled(Button)`
    flex: 1;
    justify-content: center;
`;

export const DoubleConfirmationContainer = styled(View)`
    display: flex;
    margin-top: 16px;
    flex-direction: row;
    align-items: center;
`;

export const DoubleConfirmationLabelWrapper = styled(Pressable)``;

export const DoubleConfirmationLabel = styled(Text)`
    ${mixins.typography.ui.regular14}
    width: 100%;
    color: ${({ theme }) => theme.text.tertiary};
    margin-left: 8px;
`;
