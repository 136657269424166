import { View } from 'react-native';
import styled from 'styled-components/native';

import { ChatLayoutStyledProps, ContainerProps } from './ChatLayout.types';

export const Container = styled(View)<ContainerProps>`
    flex: 1;
    border-radius: 20px;
    padding-bottom: ${({ inset, bottomContent }) => (bottomContent ? inset?.bottom : 0)}px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.fill.level1};
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
`;

export const ContentWrapper = styled(View)`
    flex: 1;
`;

export const ContentContainer = styled(View)<ChatLayoutStyledProps>`
    padding: ${({ fullWidth }) => (fullWidth ? '16px 0' : '16px')};
    flex: 1;
    padding-top: 0;
`;

export const LinearGradient = styled(View)`
    flex: 1;
    background-image: ${({ theme }) =>
        `linear-gradient(-35deg, ${theme.fill.level1} 45%, ${theme.interactive.primaryDefault} 100%)`};
`;
