import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { ChatScreen } from '~/screens/chat';
import { ChatSelection } from '~/screens/chatSelection';

import { ChatTopNavigation } from '../components/topNavigation';
import { ChatStackParamsList } from './ChatStackNavigation.types';

const Stack = createNativeStackNavigator<ChatStackParamsList>();

const ChatSelectionHeader = (props: NativeStackHeaderProps) => <ChatTopNavigation {...props} />;
const ChatScreenHeader = (props: NativeStackHeaderProps) => <ChatTopNavigation isBackButton isKeyboard {...props} />;

export const ChatStackNavigation = () => {
    return (
        <Stack.Navigator initialRouteName="ChatSelection" screenOptions={{ header: () => null }}>
            <Stack.Screen name="ChatSelection" component={ChatSelection} options={{ header: ChatSelectionHeader }} />
            <Stack.Screen name="ChatScreen" component={ChatScreen} options={{ header: ChatScreenHeader }} />
        </Stack.Navigator>
    );
};
