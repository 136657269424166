import { Pressable, Text } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme/mixins';

export const Container = styled(Pressable)`
    cursor: pointer;
    ${mixins.typography.ui.medium16};
    margin-right: 32px;
`;

export const Label = styled(Text)`
    ${mixins.typography.ui.medium16};
`;
