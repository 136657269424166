import { Image, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular16};
    color: ${({ theme }) => theme.text.primary};
    width: 100%;
    margin-bottom: 16px;
`;

export const ImageWrapper = styled(View)`
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 396 / 215;
`;

export const LoaderContainer = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.fill.level3};
`;

export const InstructionImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
