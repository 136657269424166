import React from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './Dropdown.styled';
import { DropdownMenuItemProps } from './Dropdown.types';
import { DropdownMenuItemAnimation } from './DropdownMenuItem.animated';

export interface Props extends DropdownMenuItemProps {
    onClose: () => void;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.DropdownMenuItemContainer);
const AnimatedLabel = Animated.createAnimatedComponent(S.DropdownMenuItemLabel);

export const DropdownMenuItem: React.FC<Props> = ({ label, icon, onPress, onClose }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    const handlePress = () => {
        onPress();
        onClose();
    };

    return (
        <DropdownMenuItemAnimation progress={pressableAnimationProgress}>
            {({ containerAnimatedStyle, labelAnimatedStyle }) => (
                <AnimatedContainer {...pressableProps} style={containerAnimatedStyle} onPress={handlePress}>
                    {icon && (
                        <S.DropdownMenuItemIcon>
                            <PressableIcon
                                name={icon}
                                size={16}
                                color={theme.icon.primary}
                                pressedColor={theme.icon.interactive}
                                pressableAnimationProgress={pressableProps.hovered || pressableProps.pressed ? 1 : 0}
                            />
                        </S.DropdownMenuItemIcon>
                    )}
                    <AnimatedLabel style={labelAnimatedStyle}>{label}</AnimatedLabel>
                </AnimatedContainer>
            )}
        </DropdownMenuItemAnimation>
    );
};
