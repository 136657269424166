import { FC } from 'react';
import { useTheme } from 'styled-components/native';

import { Instruction } from '../instruction';
import { instructions } from './Instructions.constants';
import * as S from './Instructions.styled';

export const Instructions: FC = () => {
    const theme = useTheme();

    if (theme.isWebDesktop) {
        return (
            <S.Container>
                <S.InstructionsGrid>
                    {instructions.map(({ id, title, imageSrc }) => (
                        <Instruction key={id} title={title} imageSrc={imageSrc} />
                    ))}
                </S.InstructionsGrid>
            </S.Container>
        );
    }

    return (
        <S.Container>
            {instructions.map(({ id, title, imageSrc }) => (
                <S.InstructionWrapper key={id}>
                    <Instruction title={title} imageSrc={imageSrc} />
                </S.InstructionWrapper>
            ))}
        </S.Container>
    );
};
