import { Text, View } from 'react-native';
import { FlattenInterpolation, ThemeProps, css } from 'styled-components';
import styled, { DefaultTheme } from 'styled-components/native';
import { HeaderProps } from '~/screens/diet/components/dietSlider/components/header/Header';
import { mixins } from '~/theme';

type Variant = NonNullable<HeaderProps['variant']>;

export const Container = styled(View)`
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border['12']};
    padding-bottom: 12px;
`;

const titleStylesMap: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    default: css`
        color: ${({ theme }) => theme.text.primary};
    `,
    active: css`
        color: ${({ theme }) => theme.text.critical};
    `,
    disabled: css`
        color: ${({ theme }) => theme.text.disabled};
    `,
};

export const Title = styled(Text)<{ variant: Variant }>`
    ${mixins.typography.h.semiBold16};
    ${({ variant }) => titleStylesMap[variant]};
`;

export const NutritionDataContainer = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const nutritionDataItemStylesMap: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    default: css`
        color: ${({ theme }) => theme.text.tertiary};
    `,
    active: css`
        color: ${({ theme }) => theme.text.primary};
    `,
    disabled: css`
        color: ${({ theme }) => theme.text.disabled};
    `,
};

export const NutritionDataItem = styled(Text)<{ variant: Variant }>`
    margin-top: 3px;
    ${mixins.typography.ui.regular14};
    ${({ variant }) => nutritionDataItemStylesMap[variant]};
`;
