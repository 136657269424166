import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { Defs, G, LinearGradient, Path, Stop, Svg, Text as SvgText } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { font } from '~/theme/font';

import { DataPoint } from './DataPoint';
import { EMPTY_STATE_DATA } from './ProgressLineChart.constants';
import { capitalize, getYFromX } from './ProgressLineChart.helpers';
import { useChart } from './ProgressLineChart.hooks';
import * as S from './ProgressLineChart.styled';
import { ProgressLineChartProps } from './ProgressLineChart.types';
import { useDataPoint } from './useDataPoint';

export const ProgressLineChart: React.FC<ProgressLineChartProps> = memo(
    ({ data = [], referencePoint = data[0], unit = 'cm' }) => {
        const [{ width, height, left }, setChartParentDimensions] = useState<{
            width: number;
            height: number;
            left: number;
        }>({
            width: 0,
            height: 0,
            left: 0,
        });

        const theme = useTheme();
        const isEmpty = !data.length;
        const initialData = isEmpty ? EMPTY_STATE_DATA : data;
        const chartData = useChart({ data: initialData, width, height });

        const [selectedMeasureIndex, setSelectedMeasureIndex] = useState<number>(isEmpty ? -1 : initialData.length - 1);
        const selectedMeasure = initialData[selectedMeasureIndex];
        const measuresDiff = isEmpty ? 0 : (selectedMeasure?.value ?? 0) - (referencePoint?.value ?? 0);
        const currentDataPoint = chartData.dataPoints[selectedMeasureIndex];

        const handleY = useCallback(
            (locationX: number, setY: (arg: number) => void) => {
                const closest = chartData.dataPoints.reduce(function (prev, curr) {
                    return Math.abs(curr.x - locationX) < Math.abs(prev.x - locationX) ? curr : prev;
                });
                let firstPoint;
                let secondPoint;
                if (closest.x === locationX) {
                    return;
                } else if (closest.x < locationX) {
                    firstPoint = closest;
                    secondPoint = chartData.dataPoints[closest.index + 1];
                } else {
                    secondPoint = closest;
                    firstPoint = chartData.dataPoints[closest.index - 1];
                }

                if (Math.abs(firstPoint.x - locationX) < 10) {
                    setY(firstPoint.y);
                } else if (Math.abs(secondPoint.x - locationX) < 10) {
                    setY(secondPoint.y);
                } else {
                    const calculatedY = getYFromX(firstPoint, secondPoint, locationX);
                    setY(calculatedY);
                }
            },
            [chartData.dataPoints],
        );

        const handlePointerMove = useCallback(
            (locationX: number, setX: (arg: number) => void, setY: (arg: number) => void) => {
                const first = chartData.dataPoints[0]?.x;
                const last = chartData.dataPoints[chartData.dataPoints.length - 1]?.x;
                if (locationX < first || locationX > last) return;
                const closest = chartData.dataPoints.find((point) => {
                    const diff = Math.abs(point.x - locationX);
                    if (diff < 10) return point;
                });

                const closestIndex = closest?.index;

                setX(locationX);
                handleY(locationX, setY);

                // snap to index
                if (closestIndex !== undefined) {
                    setSelectedMeasureIndex(closestIndex);
                    setX(chartData.dataPoints[closestIndex].x);
                }
            },
            [chartData.dataPoints, handleY],
        );

        const { x, y, chartContainerProps } = useDataPoint(
            { dataPoints: chartData.dataPoints, currentDataPoint, left },
            handlePointerMove,
            setSelectedMeasureIndex,
        );

        const handleLayoutChange = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
            const layout = nativeEvent.layout;
            // @ts-ignore
            setChartParentDimensions(layout);
        }, []);

        const handleDataPointClick = useCallback(({ index }: { index: number }) => {
            setSelectedMeasureIndex(index);
        }, []);

        useEffect(() => {
            if (theme.isWeb) {
                // Inject style to remove outline from all form elements (hack for web)
                const style = document.createElement('style');
                style.textContent = `svg * { outline: none !important; }`;
                document.head.append(style);
            }
        }, [theme.isWeb]);

        useEffect(() => {
            if (!selectedMeasure && data?.length) {
                setSelectedMeasureIndex(data.length - 1);
            }
        }, [data.length, selectedMeasure]);

        const renderDefs = () => (
            <Defs>
                <LinearGradient x1="0" y1="0" x2="0" y2="100%" id="gradient">
                    <Stop offset={0.25} stopColor={theme.border.interactive} stopOpacity={0.32} />
                    <Stop offset={1} stopColor="#09090c" stopOpacity={0.5} />
                </LinearGradient>
                <LinearGradient x1="0" y1="0" x2="0" y2="100%" id="empty-gradient">
                    <Stop offset={0.25} stopColor="#858585" stopOpacity={0.32} />
                    <Stop offset={1} stopColor="#09090C" stopOpacity={0.5} />
                </LinearGradient>
                <LinearGradient x1="0" y1="0" x2="0" y2="100%" id="indicator-regular">
                    <Stop offset={0} stopColor="#000" stopOpacity={0} />
                    <Stop offset={0.22} stopColor={theme.border.interactive} stopOpacity={1} />
                    <Stop offset={0.74} stopColor={theme.border.interactive} stopOpacity={1} />
                    <Stop offset={1} stopColor="#000" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient x1="0" y1="0" x2="0" y2="100%" id="indicator-reduced">
                    <Stop offset={0} stopColor="#000" stopOpacity={0} />
                    <Stop offset={0.22} stopColor={theme.border.interactive} stopOpacity={1} />
                </LinearGradient>
            </Defs>
        );

        if (isEmpty) {
            return (
                <S.Container>
                    <S.EmptyStateTextWrapper>
                        <S.EmptyStateText>Brak wystarczającej ilości danych</S.EmptyStateText>
                    </S.EmptyStateTextWrapper>
                    <S.ChartContainer onLayout={handleLayoutChange}>
                        <Svg width={chartData.width} height={chartData.height}>
                            {renderDefs()}
                            <G translateX={-chartData.xOffset}>
                                <Path d={chartData.gradient} fill="url(#empty-gradient)" />
                                <Path
                                    d={chartData.line}
                                    strokeWidth="3"
                                    stroke={theme.fill.level4}
                                    fill="transparent"
                                />
                            </G>
                        </Svg>
                    </S.ChartContainer>
                </S.Container>
            );
        }

        return (
            <S.Container>
                <S.Summary>
                    <S.DataItem>
                        <S.Metric>
                            <S.MetricValue>{selectedMeasure?.value?.toFixed(1)}</S.MetricValue>
                            <S.MetricUnit>{unit}</S.MetricUnit>
                        </S.Metric>
                        <S.MetricDescription>
                            Pomiar z {selectedMeasure?.date.toLocaleDateString('pl')}
                        </S.MetricDescription>
                    </S.DataItem>
                    <S.DataItem>
                        <S.Metric>
                            <S.IconWrapper>
                                <Icon name={measuresDiff > 0 ? 'arrow-up' : 'arrow-down'} size={20} />
                            </S.IconWrapper>
                            <S.MetricValue>{Math.abs(measuresDiff).toFixed(1)}</S.MetricValue>
                            <S.MetricUnit>{unit}</S.MetricUnit>
                        </S.Metric>
                        {referencePoint?.label && <S.MetricDescription>{referencePoint.label}</S.MetricDescription>}
                    </S.DataItem>
                </S.Summary>
                <S.ChartContainer onLayout={handleLayoutChange} {...chartContainerProps}>
                    <Svg width={chartData.width} height={chartData.height}>
                        {renderDefs()}
                        <G name="dataLine">
                            <Path d={chartData.gradient} fill="url(#gradient)" />
                            <Path
                                d={chartData.line}
                                strokeWidth="3"
                                stroke={theme.border.interactive}
                                fill="transparent"
                            />
                        </G>
                        <G name="xAxis">
                            {chartData.ticks.map(({ value, ...tickProps }) => (
                                <SvgText
                                    key={tickProps.index}
                                    fontFamily={font.primaryRegular}
                                    fontSize={12}
                                    letterSpacing={-0.02}
                                    fontWeight={400}
                                    textAnchor={tickProps.index === 0 ? 'start' : 'middle'}
                                    fill={theme.text.secondary}
                                    {...tickProps}
                                >
                                    {capitalize(format(value, 'LLL', { locale: plLocale }))}
                                </SvgText>
                            ))}
                        </G>
                        <G name="yAxis">
                            {chartData.yTicks.map(({ value, ...tickProps }) => (
                                <SvgText
                                    key={tickProps.index}
                                    fontFamily={font.primaryRegular}
                                    fontSize={12}
                                    letterSpacing={-0.02}
                                    fontWeight={400}
                                    textAnchor="end"
                                    fill={theme.text.secondary}
                                    {...tickProps}
                                >
                                    {value.toFixed(1)}
                                </SvgText>
                            ))}
                        </G>
                        {currentDataPoint && (
                            <DataPoint
                                onPress={handleDataPointClick}
                                size={chartData.dataPointSize}
                                indicatorHeight={chartData.chartHeight}
                                {...currentDataPoint}
                                x={x ?? currentDataPoint.x}
                                y={y ?? currentDataPoint.y}
                            />
                        )}
                    </Svg>
                </S.ChartContainer>
            </S.Container>
        );
    },
);
