import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useRef } from 'react';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import { LeftSubmenuItem } from '~/components/leftSubmenuItem';
import { useAuth } from '~/enhancers/auth.context';
import { useApplication } from '~/hooks/useApplication';

import { links } from './MoreNavigation.constants';
import * as S from './MoreNavigation.styled';
import { MoreNavigationProp } from './MoreNavigation.types';

export const MoreNavigation = () => {
  const route = useRoute();
  const auth = useAuth();
  const navigation = useNavigation<MoreNavigationProp>();
  const logOutConfirmSheetRef = useRef<ConfirmSheetRef>(null);
  const application = useApplication();

  const handleLogout = () => {
    logOutConfirmSheetRef.current?.close();
    auth.signOut();
  };

  return (
    <S.Container>
      <S.Header>
        <S.HeaderText>Konto</S.HeaderText>
      </S.Header>
      <S.Menu>
        {links.map((item) => {
          const isActive = item.screen === route.name;
          const onPress = () => {
            // @ts-ignore
            // I have no idea how to type this
            navigation.navigate(item.stack, { screen: item.screen });
          };

          return (
            <LeftSubmenuItem key={item.label} active={isActive} onPress={onPress}>
              {item.label}
            </LeftSubmenuItem>
          );
        })}
        <LeftSubmenuItem onPress={() => logOutConfirmSheetRef.current?.present()}>Wyloguj</LeftSubmenuItem>
      </S.Menu>

      <S.AppInfoContainer>
        <S.AppInfo>{application.name}</S.AppInfo>
        <S.AppInfo>{application.version}</S.AppInfo>
      </S.AppInfoContainer>

      <ConfirmSheet
        ref={logOutConfirmSheetRef}
        title="Czy na pewno chcesz się wylogować?"
        icon="log-out"
        primaryButtonLabel="Tak, wyloguj mnie"
        secondaryButtonLabel="Nie"
        onConfirm={handleLogout}
        onClose={() => logOutConfirmSheetRef.current?.close()}
      />
    </S.Container>
  );
};
