import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { GiftedChat } from 'react-native-gifted-chat';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';
import { chatTypeContentMap } from '~/utils/chatTypeContentMap';

import { ChatInput } from '../chatInput';
import { ChatMessage } from '../chatMessage';
import { Chat as ChatMobile } from './Chat.native';
import * as S from './Chat.styled';
import { ChatNavigationProp, ChatRouteProps } from './Chat.types';

export const Chat = () => {
    const [messages, setMessages] = useState([]);
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    const theme = useTheme();

    const route = useRoute<ChatRouteProps['route']>();
    const navigation = useNavigation<ChatNavigationProp>();

    const handleNavigationBack = () => {
        console.log('CLICK');
        navigation.navigate('ChatSelection');
    };

    const chatTypeContent = chatTypeContentMap[route?.params?.chatType];
    const chatTypeName = chatTypeContent && `${chatTypeContent.title}`;
    const chatTypeText = chatTypeName && (
        <>
            <S.Slash>/</S.Slash>
            {`${chatTypeName}`}
        </>
    );

    //Remove when BE is integrated
    useEffect(() => {
        setMessages([
            {
                _id: 1,
                text: 'Hello developer',
                createdAt: new Date(),
                user: {
                    _id: 2,
                    name: 'React Native',
                    avatar: 'https://reactnative.dev/img/tiny_logo.png',
                },
            },
        ]);
    }, []);

    const onSend = useCallback((messages = []) => {
        setMessages((previousMessages) => GiftedChat.append(previousMessages, messages));
    }, []);

    if (!theme.isWebDesktop) return <ChatMobile />;

    return (
        <S.Container>
            <S.TopContainer>
                <S.BackButton {...pressableProps} onPress={handleNavigationBack}>
                    <PressableIcon
                        pressedColor={theme.icon.primary}
                        color={theme.icon.tertiary}
                        pressableAnimationProgress={pressableAnimationProgress}
                        name={'chevron-big-left'}
                        size={20}
                    />
                </S.BackButton>
                <S.Title>Czat{chatTypeText}</S.Title>
            </S.TopContainer>
            <GiftedChat
                messages={messages}
                onSend={(messages) => onSend(messages)}
                messagesContainerStyle={S.chatDesktop.messagesContainer}
                renderMessage={ChatMessage}
                renderInputToolbar={(props) => <ChatInput {...props} />}
                alwaysShowSend
                user={{
                    _id: 1,
                }}
            />
        </S.Container>
    );
};
