import React from 'react';
import { ComingSoon } from '~/components/comingSoon';

export const ChatComingSoon = () => (
    <ComingSoon
        title="Wkrótce w tym miejscu znajdziesz również:"
        features={[
            'W pełni funkcjonalny czat ze mną',
            'Odpowiedzi na wszelkie Twoje pytania i wątpliwości',
            'Pomoc ze wszystkim związanym z dietą i treningiem',
        ]}
        imageSrc={require('~/assets/images/chat_coming_soon.png')}
    />
);
