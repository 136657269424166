import { Image, Text, View } from 'react-native';
import { css } from 'styled-components';
import styled from 'styled-components/native';
import { LinearGradient } from '~/components/linearGradient';
import { mixins } from '~/theme';

import { ContainerProps } from './ComingSoon.types';

export const Container = styled(View)<ContainerProps>`
    margin-bottom: ${({ inset }) => (inset?.bottom ? inset.bottom : 0)}px;
    width: 100%;
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: flex-end;

    ${({ theme }) =>
        theme.isWeb &&
        css`
            min-height: fit-content;
        `}
`;

export const Content = styled(View)`
    display: flex;
    width: 100%;
    max-width: 428px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            align-items: center;
        `}
`;

export const FeaturesContainer = styled(View)<ContainerProps>`
    display: flex;
    padding: ${({ inner }) => (inner ? 0 : '12px 16px 0')};
    margin-bottom: 12px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: fit-content;
        `}
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
    margin-bottom: 16px;
`;

export const FeatureContainer = styled.View<{ last?: boolean }>`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    ${({ last }) =>
        last &&
        css`
            margin-bottom: 0;
        `}
`;

export const IconWrapper = styled(View)`
    height: 18px;
    display: flex;
    justify-content: center;
`;

export const Feature = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
    margin-left: 12px;
`;

export const ImageContainer = styled(View)`
    width: 100%;
    aspect-ratio: 428 / 594;
    position: relative;
`;

export const Gradient = styled(LinearGradient)`
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 172px;
`;

const imageStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const PhoneBodyImage = styled(Image)`
    ${imageStyles};
`;

export const FeatureImage = styled(Image)`
    ${imageStyles};
`;

export const ComingSoonImage = styled(Image)`
    ${imageStyles};
`;

export const DesktopComingSoonImage = styled(Image)`
    position: absolute;
    top: -20px;
    left: -30px;
    width: 216px;
    height: 91px;
`;

export const DesktopContainer = styled(View)<ContainerProps>`
    width: 100%;
    height: 100%;
    flex: 1;
`;

export const DesktopContent = styled(View)`
    height: 100%;
    padding: 130px;
    position: relative;
    overflow: hidden;
`;

export const DesktopImageContainer = styled(View)`
    width: 100%;
    aspect-ratio: 1200 / 675;
    min-width: 1200px;
    max-width: 1800px;
    position: absolute;
    left: 4%;
    bottom: -7%;
`;

export const DesktopFeatureImage = styled(Image)`
    ${imageStyles};
    overflow: hidden;
`;

export const DesktopBackgroundGradient = styled(View)<{ backgroundColor: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-image: ${({ backgroundColor }) =>
        `linear-gradient(90deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`};
`;

export const DesktopGradient = styled(View)<{ backgroundColor: string }>`
    position: absolute;
    width: 60%;
    height: 100%;
    right: 0;
    top: 0;
    background-image: ${({ backgroundColor }) =>
        `linear-gradient(90deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`};
`;

export const DesktopFeaturesContainer = styled(FeaturesContainer)`
    position: absolute;
    bottom: 106%;
    left: 3%;
`;
