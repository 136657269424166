import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { RadioAnimations } from '~/components/radio/Radio.animated';

import * as S from './Radio.styled';
import { RadioStyledProps } from './Radio.types';

export interface RadioProps extends Omit<RadioStyledProps, 'size'>, Omit<PressableProps, 'onPress'> {
    size?: RadioStyledProps['size'];
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);
const AnimatedBackground = Animated.createAnimatedComponent(S.Background);
const AnimatedDot = Animated.createAnimatedComponent(S.Dot);

export const Radio: React.FC<RadioProps> = ({ checked = false, size = 'l', ...props }) => {
    const handlePress = () => {
        props.onChange?.(!checked);
    };

    return (
        <S.Container {...props} size={size} onPress={handlePress}>
            <RadioAnimations checked={checked}>
                {({ borderAnimatedStyles, backgroundAnimatedStyles, dotAnimatedStyles }) => (
                    <>
                        <AnimatedBorder pointerEvents="none" size={size} style={borderAnimatedStyles} />
                        <AnimatedBackground pointerEvents="none" size={size} style={backgroundAnimatedStyles} />
                        <AnimatedDot pointerEvents="none" size={size} style={dotAnimatedStyles} />
                    </>
                )}
            </RadioAnimations>
        </S.Container>
    );
};
