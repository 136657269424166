import uuid from 'react-native-uuid';
import { formatDate } from '~/utils/dates';

export const orderHistoryData = [
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
    { id: uuid.v4().toString(), date: formatDate(), cost: 200, type: 'premium' },
];
