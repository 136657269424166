import { FC, ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/loader';
import { BottomTabWrapper } from '~/components/wrappers';

import { SidebarLayout } from '../sidebarLayout';
import * as S from './MainLayout.styled';

export type MainLayoutProps = {
    children: ReactNode;
    style?: ViewStyle;
    bottomContent?: ReactNode;
    isBottomRounded?: boolean;
    isLoading?: boolean;
};

export const MainLayout: FC<MainLayoutProps> = ({
    children,
    style,
    bottomContent,
    isBottomRounded = true,
    isLoading,
}) => {
    const inset = useSafeAreaInsets();
    const theme = useTheme();

    return (
        <SidebarLayout>
            <S.ContentWrapper>
                <S.Container
                    inset={inset}
                    style={style}
                    bottomContent={!!bottomContent}
                    isBottomRounded={theme.isWebDesktop ? false : isBottomRounded}
                >
                    {isLoading && (
                        <S.LoaderWrapper>
                            <Loader size="xl" color={theme.icon.interactive} />
                        </S.LoaderWrapper>
                    )}
                    {children}
                </S.Container>
                {bottomContent && <BottomTabWrapper>{bottomContent}</BottomTabWrapper>}
            </S.ContentWrapper>
        </SidebarLayout>
    );
};
