import { View } from 'react-native';
import styled from 'styled-components/native';
import { css } from 'styled-components/native';

export const SupplementTileWrapper = styled(View)<{ last?: boolean }>`
    width: 100%;
    margin-bottom: 16px;

    ${({ last, theme }) =>
        last &&
        theme.isWeb &&
        css`
            margin-bottom: 0;
        `}

    ${({ last, theme }) =>
        last &&
        !theme.isWeb &&
        css`
            margin-bottom: 48px;
        `}
`;

export const Center = styled(View)`
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
