import React, { FC, Fragment } from 'react';
import { Icon } from '~/components/icon';
import { getFormattedDuration } from '~/utils/dates';

import * as S from './TrainingPreviewHeader.styled';

interface TrainingPreviewHeaderProps {
    duration: number;
    series: number;
}
export const TrainingPreviewHeader: FC<TrainingPreviewHeaderProps> = ({ duration, series }) => {
    return (
        <S.SummaryContainer>
            <S.SummaryItem>
                <S.SummaryItemTitle>
                    <S.SummaryItemTitleTextWrapper>
                        {getFormattedDuration(duration)
                            .filter((item) => item.value)
                            .map((item) => (
                                <Fragment key={item.suffix}>
                                    <S.SummaryItemTitleText>{item.value}</S.SummaryItemTitleText>
                                    <S.SummaryItemTitleTextUnit>{item.suffix}</S.SummaryItemTitleTextUnit>
                                </Fragment>
                            ))}
                    </S.SummaryItemTitleTextWrapper>
                    <S.SummaryItemTitleIcon>
                        <Icon name="total-training-time" size={24} />
                    </S.SummaryItemTitleIcon>
                </S.SummaryItemTitle>
                <S.SummaryItemSubtitle>Szac. czas treningu</S.SummaryItemSubtitle>
            </S.SummaryItem>

            <S.SummaryItem last>
                <S.SummaryItemTitle>
                    <S.SummaryItemTitleTextWrapper>
                        <S.SummaryItemTitleText>{series}</S.SummaryItemTitleText>
                    </S.SummaryItemTitleTextWrapper>
                    <S.SummaryItemTitleIcon>
                        <Icon name="personal-trainer" size={24} />
                    </S.SummaryItemTitleIcon>
                </S.SummaryItemTitle>
                <S.SummaryItemSubtitle>Łączna liczba serii</S.SummaryItemSubtitle>
            </S.SummaryItem>
        </S.SummaryContainer>
    );
};
