import { FinishedSeriesData } from '@pg/backend';
import React, { memo, useMemo, useRef } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { BottomSheetModal } from '~/components/bottomSheet';
import { Icon } from '~/components/icon';
import { Tooltip } from '~/components/tooltip';
import { useTrainingScreen } from '~/screens/training/TrainingScreen.context';
import { SeriesTable } from '~/screens/training/components/seriesTable';

import { ExerciseItemHeader } from '../exerciseItemHeader';
import { InstructionButton } from '../instructionButton';
import { ExerciseItemAnimations } from './ExerciseItem.animated';
import * as S from './ExerciseItem.styled';

interface ExerciseItemProps {
    id: string;
    last?: boolean;
    onInstructionPress?: (videoPath: string) => void;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);

export const ExerciseItem = memo(({ id, onInstructionPress, last }: ExerciseItemProps) => {
    const trainingScreen = useTrainingScreen();
    const data = trainingScreen.getMapData('trainingExercisesMap', id)!;
    const exercise = trainingScreen.getMapData('exercisesMap', data.exerciseID)!;
    const exerciseData = trainingScreen.getMapData('exercisesDataMap', data.trainingExerciseExerciseDataId)!;
    const finishedSeries = trainingScreen.finishedExercises?.[data.id]?.series;
    const finishedPreviousCycleExercises = trainingScreen.finishedPreviousCycleExercises?.[data.exerciseID]?.series;

    const finished =
        finishedSeries?.find((i) => {
            return !i || (i as FinishedSeriesData)?.done === false;
        }) === undefined;

    const nextExercise = trainingScreen.getNextExercise(data.id);
    const prevExerciseForSuperset = trainingScreen.getPreviousExerciseForSuperSet(data.id);

    const infoSheetRef = useRef<BottomSheetModal>(null);
    const theme = useTheme();

    const header = useMemo(() => {
        return {
            stop: exerciseData?.stop ?? '',
            rir: exerciseData?.rir ?? '',
            pace: exerciseData?.pace ?? '',
        };
    }, [exerciseData?.pace, exerciseData?.rir, exerciseData?.stop]);

    return (
        <S.Container last={last}>
            {data.isSuperSet && (
                <S.InfoContainer>
                    <S.InfoText>Następne dwa ćwiczenia wykonuj w superserii </S.InfoText>
                    <Tooltip
                        ref={infoSheetRef}
                        triggerComponent={<Icon name="help-outline" color={theme.palette.neutral['00']} size={20} />}
                        triggerOn={['click', 'hover']}
                        position={'bottom left'}
                        text="Super seria to zaawansowana technika treningowa, polegająca na wykonywaniu dwóch serii ćwiczeń bez przerwy między nimi."
                    />
                </S.InfoContainer>
            )}

            <S.ExerciseContainer>
                <ExerciseItemAnimations filled={!!finished}>
                    {({ borderAnimatedStyles }) => (
                        <>
                            <AnimatedBorder style={borderAnimatedStyles} />

                            <S.TitleContainer>
                                <S.TitleText>{exercise?.name}</S.TitleText>

                                {exercise?.videoPath && exercise?.videoPath !== '' && onInstructionPress && (
                                    <S.InstructionButtonContainer>
                                        <InstructionButton onPress={() => onInstructionPress(exercise?.videoPath!)} />
                                    </S.InstructionButtonContainer>
                                )}
                            </S.TitleContainer>

                            {header && (
                                <S.ExerciseItemHeaderContainer>
                                    <ExerciseItemHeader {...header} />
                                </S.ExerciseItemHeaderContainer>
                            )}

                            <SeriesTable
                                trainingExerciseId={data.id}
                                exerciseData={exerciseData}
                                exercise={exercise}
                                nextExercise={nextExercise}
                                prevExerciseForSuperset={prevExerciseForSuperset}
                                isSuperSet={!!data.isSuperSet}
                                finishedSeries={finishedSeries}
                                finishedPreviousCycleExercises={finishedPreviousCycleExercises}
                            />
                        </>
                    )}
                </ExerciseItemAnimations>
            </S.ExerciseContainer>
        </S.Container>
    );
});

ExerciseItem.displayName = 'ExerciseItem';
