import throttle from 'lodash.throttle';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { ImageRequireSource } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { Checkbox } from '~/components/checkbox';
import { Icon } from '~/components/icon';
import { IconName } from '~/icons';

import { BottomSheetHandle } from '../bottomSheetHandle';
import {
    BottomSheetBackdrop,
    BottomSheetBackdropProps,
    BottomSheetModal,
    BottomSheetView,
    useBottomSheetDynamicSnapPoints,
} from '../bottomSheetModal';
import * as S from './ConfirmSheet.styled';

export interface ConfirmSheetProps {
    title: string;
    subtitle?: string;
    icon?: IconName;
    iconColor?: string;
    image?: ImageRequireSource;
    variant?: 'error' | 'primary';
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    initiallyOpen?: boolean;
    doubleConfirmation?: boolean;
    doubleConfirmationLabel?: string;
    maxWidth?: number;
    onConfirm?: () => void;
    onClose?: () => void;
}

export const ConfirmSheet = forwardRef<BottomSheetModal | null, ConfirmSheetProps>(
    (
        {
            title,
            subtitle,
            icon,
            iconColor,
            image,
            variant = 'error',
            primaryButtonLabel = 'OK',
            secondaryButtonLabel = 'Anuluj',
            doubleConfirmation,
            doubleConfirmationLabel = 'Potwierdzam',
            onClose,
            onConfirm,
        },
        ref,
    ) => {
        const theme = useTheme();
        const [doubleConfirmationChecked, setDoubleConfirmationChecked] = useState(false);

        const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], []);
        const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
            useBottomSheetDynamicSnapPoints(initialSnapPoints);

        const handleClose = useMemo(() => (onClose ? throttle(onClose, 1000, { trailing: false }) : null), [onClose]);
        const handleConfirm = useMemo(
            () => (onConfirm ? throttle(onConfirm, 1000, { trailing: false }) : null),
            [onConfirm],
        );

        const handleDismiss = useCallback(() => {
            if (handleClose) {
                handleClose();
                return;
            }

            handleConfirm?.();
        }, [handleClose, handleConfirm]);

        const renderBackdrop = useCallback(
            ({ handleKeyUpdate, ...props }: BottomSheetBackdropProps) => (
                <BottomSheetBackdrop
                    {...props}
                    onPress={() => {
                        handleKeyUpdate();
                        handleDismiss();
                    }}
                    appearsOnIndex={0}
                    disappearsOnIndex={-1}
                    opacity={0.8}
                />
            ),
            [handleDismiss],
        );

        const renderHandle = useCallback(
            () => (
                <S.HandleContainer>
                    <BottomSheetHandle />
                </S.HandleContainer>
            ),
            [],
        );

        const variantIconColor = useMemo(() => {
            const iconColorMap: Record<NonNullable<ConfirmSheetProps['variant']>, string> = {
                error: theme.icon.critical,
                primary: theme.icon.primary,
            };

            return iconColorMap[variant];
        }, [theme.icon.critical, theme.icon.primary, variant]);

        return (
            <S.BottomSheet
                ref={ref}
                snapPoints={animatedSnapPoints}
                backdropComponent={renderBackdrop}
                handleComponent={renderHandle}
                handleHeight={animatedHandleHeight}
                contentHeight={animatedContentHeight}
                backgroundStyle={{ backgroundColor: theme.fill.level2 }}
                enablePanDownToClose
            >
                <BottomSheetView onLayout={handleContentLayout}>
                    <S.Container>
                        {(image || icon) && (
                            <S.IconContainer>
                                {icon && <Icon name={icon} size={48} color={iconColor || variantIconColor} />}
                                {image && <S.IconImage source={image} />}
                            </S.IconContainer>
                        )}
                        <S.TitleContainer>
                            <S.Title>{title}</S.Title>
                            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
                            {doubleConfirmation && (
                                <S.DoubleConfirmationContainer>
                                    <Checkbox
                                        checked={doubleConfirmationChecked}
                                        onChange={setDoubleConfirmationChecked}
                                    />
                                    <S.DoubleConfirmationLabelWrapper
                                        onPress={() => setDoubleConfirmationChecked((value) => !value)}
                                    >
                                        <S.DoubleConfirmationLabel>{doubleConfirmationLabel}</S.DoubleConfirmationLabel>
                                    </S.DoubleConfirmationLabelWrapper>
                                </S.DoubleConfirmationContainer>
                            )}
                        </S.TitleContainer>
                        {handleConfirm && (
                            <S.ButtonContainer>
                                <Button
                                    size="m"
                                    variant={variant}
                                    fullWidth
                                    onPress={handleConfirm}
                                    disabled={doubleConfirmation && !doubleConfirmationChecked}
                                >
                                    {primaryButtonLabel}
                                </Button>
                            </S.ButtonContainer>
                        )}
                        {handleClose && (
                            <S.ButtonContainer>
                                <Button size="m" variant="secondary" fullWidth onPress={handleClose}>
                                    {secondaryButtonLabel}
                                </Button>
                            </S.ButtonContainer>
                        )}
                    </S.Container>
                </BottomSheetView>
            </S.BottomSheet>
        );
    },
);
