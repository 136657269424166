import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { MainLayout } from '~/layouts/web';
import { MeasurementsStackScreens } from '~/navigation/paths';

import { MetamorphosisContent } from '../components/metamorphosisContent';

export const MetamorphosisScreen = () => {
    useWebDesktopBlockedScreen(MeasurementsStackScreens.screens.Metamorphosis);

    return <MainLayout title="Metamorfoza" fullWidth verticalSpacing={false} content={<MetamorphosisContent />} />;
};
