import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    display: flex;
`;

export const PhotoTileWrapper = styled(View)`
    margin-bottom: 16px;
    width: 100%;
    aspect-ratio: 190 / 320;
`;

export const DateInputWrapper = styled(View)`
    margin-bottom: 12px;
`;

export const Measures = styled(View)`
    display: flex;
`;

export const MeasureContainer = styled(View)<{ last: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({ last }) => (last ? 0 : 12)}px;
    justify-content: space-between;
    ${({ theme, last }) =>
        theme.isWebDesktop &&
        css`
            margin-bottom: ${last ? 0 : '15px'};
        `}
`;

export const Measure = styled(View)`
    display: flex;
    padding-left: 14px;
    position: relative;
`;

export const Border = styled(View)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: ${({ theme }) => theme.border['12']};
`;

export const MeasureLabel = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.tertiary};
`;

export const MeasureValue = styled(Text)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.primary};
`;
