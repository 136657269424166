import { FC } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Animated, { interpolateColor, useAnimatedStyle } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';

import { BottomSheetHandleProps } from '../../bottomSheetModal';
import * as S from './TrainingTimerSheetHandle.styled';

const AnimatedElement = Animated.createAnimatedComponent(S.Element);

interface TrainingTimerSheetHandleProps extends BottomSheetHandleProps {
    style?: StyleProp<ViewStyle>;
}

export const TrainingTimerSheetHandle: FC<TrainingTimerSheetHandleProps> = ({ animatedIndex, style }) => {
    const theme = useTheme();

    const elementAnimatedStyle = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(animatedIndex.value, [0, 1], [theme.text.primary, theme.border['40']]),
    }));

    return (
        <S.Container style={style}>
            <AnimatedElement style={elementAnimatedStyle} />
        </S.Container>
    );
};
