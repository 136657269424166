import { ScrollView, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { ModalCloseButton as ModalCloseButtonBase } from '~/components/modalCloseButton';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 352px;
    height: 534px;
`;

export const Header = styled(View)`
    padding: 12px 12px 12px 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold16};
    color: ${({ theme }) => theme.text.primary};
`;

export const ModalCloseButton = styled(ModalCloseButtonBase)`
    background: transparent;
    border: none;
    width: auto;
    padding: 6px;
    color: ${({ theme }) => theme.icon.tertiary};
`;

export const Content = styled(ScrollView)`
    flex: 1;
    background-color: ${({ theme }) => theme.fill.level1};
    padding: 16px;
`;

export const MealItemWrapper = styled(View)<{ last?: boolean }>`
    margin-bottom: ${({ last }) => (last ? 0 : 12)}px;
`;
