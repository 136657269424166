import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    flex-direction: row;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
    flex: 1;
`;

export const CounterWrapper = styled(View)`
    width: 138px;
`;
