import { BottomTabHeaderProps } from '@react-navigation/bottom-tabs';
import { FC } from 'react';
import { LeftTitleNavigation } from '~/components/topNavigation';

interface LeftTitleTopNavigationProps extends BottomTabHeaderProps {
    title: string;
}

export const LeftTitleTopNavigation: FC<LeftTitleTopNavigationProps> = ({ title }) => {
    return <LeftTitleNavigation title={title} />;
};
