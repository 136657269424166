import React from 'react';
import { Pressable, PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { usePressableState } from '~/hooks/usePressableState';

import { TagAnimations } from './Tag.animated';
import * as S from './Tag.styled';
import { TagStyledProps } from './Tag.types';

export interface TagProps extends TagStyledProps, PressableProps {
    label: string;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);
const AnimatedLabel = Animated.createAnimatedComponent(S.Label);

export const Tag = React.forwardRef<typeof Pressable | null, TagProps>(({ label, size, ...restOfProps }, ref) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    return (
        <TagAnimations progress={pressableAnimationProgress}>
            {({ containerAnimatedStyles, labelAnimatedStyles }) => (
                <AnimatedContainer
                    size={size}
                    {...pressableProps}
                    ref={ref}
                    style={containerAnimatedStyles}
                    {...restOfProps}
                >
                    <AnimatedLabel style={labelAnimatedStyles} size={size}>
                        {label}
                    </AnimatedLabel>
                </AnimatedContainer>
            )}
        </TagAnimations>
    );
});
