import {
    CardioExerciseData,
    FinishedSeriesData,
    TrainingCardioExercise,
    TrainingDayGroup,
    TrainingDayGroupType,
    TrainingExercise,
    TrainingItemStatus,
} from '@pg/backend';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useQueryClient } from '@tanstack/react-query';
import React, {
    FC,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTheme } from 'styled-components/native';
import { useCreateOrUpdateFinishedCardioExerciseData } from '~/api/training/useCreateOrUpdateFinishedCardioExerciseData';
import { useCreateOrUpdateFinishedSeriesData } from '~/api/training/useCreateOrUpdateFinishedSeriesData';
import { ExpandedTrainingDay, useGetExpandedTrainingDay } from '~/api/training/useGetExpandedTrainingDay';
import { useResetCardioExercisesData } from '~/api/training/useResetCardioExercisesData';
import { useResetFinishedSeriesData } from '~/api/training/useResetFinishedSeriesData';
import { useUpdateTrainingDay } from '~/api/training/useUpdateTrainingDay';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import { useTrainingPlan } from '~/enhancers/trainingPlanContext/trainingPlan.context';
import { TrainingStackParamsList } from '~/navigation/home/training';
import {
    TrainingScreenNextExercise,
    TrainingScreenProps,
    TrainingScreenSection,
} from '~/screens/training/TrainingScreen.types';
import { buildFinishedExercises } from '~/screens/training/TrainingScreen.utils';
import { KeyOfType } from '~/utils/api';
import {
    getGroupTitle,
    getSectionName,
    getTotalNumberOfSeries,
    getTotalTime,
    sortByOrderField,
} from '~/utils/training';

import { TrainingScreenFinishedExercise } from './TrainingScreen.types';

export type TrainingScreenFinishedExercises = Record<string, TrainingScreenFinishedExercise>;

export interface TrainingScreenFormFinishedExercise {
    seriesNumber: number;
    done: boolean;
    reps: string | number;
    weight: string | number;
}

export interface TrainingScreenContextType {
    data: ExpandedTrainingDay | null;
    orderedGroups: TrainingDayGroup[];
    trainingDayId: string;
    isFetching: boolean;
    isRefetching: boolean;
    trainingName: string;
    cycleName: string;
    duration: number;
    totalNumberOfSeries: number;
    sections: TrainingScreenSection[];
    getMapData: <T extends KeyOfType<ExpandedTrainingDay, Record<string, any>>>(
        key: T,
        id: keyof ExpandedTrainingDay[T],
    ) => ExpandedTrainingDay[T][keyof ExpandedTrainingDay[T]] | null;
    finishedExercises: TrainingScreenFinishedExercises;
    finishedPreviousCycleExercises: TrainingScreenFinishedExercises;
    updateSeriesData: (trainingExerciseId: string, seriesData: TrainingScreenFormFinishedExercise) => void;
    updateCardioData: (trainingCardioExerciseId: string, cardioData: { time: number }) => void;
    getNextExercise: (trainingExerciseId: string) => TrainingScreenNextExercise | undefined;
    getPreviousExerciseForSuperSet: (trainingExerciseId: string) => TrainingScreenNextExercise | null;
    finishTraining: () => Promise<void>;
    resetTraining: () => Promise<void>;
    refetch: () => void;
}

export const TrainingScreenContext = createContext<TrainingScreenContextType>({
    data: null,
    isFetching: false,
    isRefetching: false,
    trainingDayId: '',
    trainingName: '',
    cycleName: '',
    duration: 0,
    totalNumberOfSeries: 0,
    orderedGroups: [],
    sections: [],
    finishedExercises: {},
    finishedPreviousCycleExercises: {},
    finishTraining: () => {
        throw new Error('Not implemented');
    },
    resetTraining: () => {
        throw new Error('Not implemented');
    },
    getPreviousExerciseForSuperSet: () => {
        throw new Error('Not implemented');
    },
    getNextExercise: () => {
        throw new Error('Not implemented');
    },
    getMapData: () => {
        throw new Error('Not implemented');
    },
    updateSeriesData: () => {
        throw new Error('Not implemented');
    },
    updateCardioData: () => {
        throw new Error('Not implemented');
    },
    refetch: () => {
        throw new Error('Not implemented');
    },
});

export interface TrainingScreenContextProviderProps {
    trainingDayId: string;
    children: ReactNode;
}

export const TrainingScreenContextProvider: FC<TrainingScreenContextProviderProps> = ({ children, trainingDayId }) => {
    const exerciseDataErrorModalRef = useRef<ConfirmSheetRef>(null);
    const trainingDayErrorModalRef = useRef<ConfirmSheetRef>(null);
    const { data, isFetching, isFetched, refetch, isRefetching } = useGetExpandedTrainingDay(trainingDayId);
    const queryClient = useQueryClient();
    const trainingPlan = useTrainingPlan();

    const trainingCycle = trainingPlan.getTrainingCycleByDayId(trainingDayId);
    const trainingDay = trainingPlan.getTrainingDayById(trainingDayId);
    const navigation = useNavigation<NativeStackNavigationProp<TrainingStackParamsList>>();
    const trainingCycleIndex = trainingCycle ? trainingPlan.trainingCycles.indexOf(trainingCycle) : -1;
    const prevTrainingCycle = trainingPlan.trainingCycles[trainingCycleIndex - 1];
    const trainingDayIndex = trainingDay && trainingCycle ? trainingCycle.days.indexOf(trainingDay) : -1;
    const trainingDayFromPrevCycle = prevTrainingCycle?.days[trainingDayIndex];
    const { data: trainingDayFromPrevCycleData } = useGetExpandedTrainingDay(trainingDayFromPrevCycle?.id);

    const [finishedExercises, setFinishedExercises] = useState<TrainingScreenFinishedExercises>({});
    const finishedExercisesInitialized = useRef(false);

    useEffect(() => {
        finishedExercisesInitialized.current = false;
    }, [data]);

    const {
        mutateAsync: updateTrainingDay,
        isError: isUpdateTrainingDayError,
        reset: resetUpdateTrainingDay,
    } = useUpdateTrainingDay();
    const {
        mutateAsync: createOrUpdateFinishedSeriesData,
        isError: createOrUpdateFinishedSeriesDataError,
        reset: resetCreateOrUpdateFinishedSeriesDataError,
    } = useCreateOrUpdateFinishedSeriesData();
    const {
        mutateAsync: createOrUpdateFinishedCardioExerciseData,
        isError: createOrUpdateFinishedCardioExerciseDataError,
        reset: resetCreateOrUpdateFinishedCardioExerciseData,
    } = useCreateOrUpdateFinishedCardioExerciseData();
    const { mutateAsync: resetFinishedSeriesData } = useResetFinishedSeriesData();
    const { mutateAsync: resetCardioExercisesData } = useResetCardioExercisesData();

    const duration = useMemo(() => getTotalTime(data), [data]);
    const totalNumberOfSeries = useMemo(() => getTotalNumberOfSeries(data), [data]);
    const orderedGroups = useMemo(() => data?.groups?.sort(sortByOrderField) ?? [], [data]);

    const { isWeb } = useTheme();

    useEffect(() => {
        if (createOrUpdateFinishedSeriesDataError || createOrUpdateFinishedCardioExerciseDataError) {
            exerciseDataErrorModalRef.current?.present();
        } else {
            exerciseDataErrorModalRef.current?.dismiss();
        }
    }, [createOrUpdateFinishedSeriesDataError, createOrUpdateFinishedCardioExerciseDataError]);

    useEffect(() => {
        if (isUpdateTrainingDayError) {
            trainingDayErrorModalRef.current?.present();
        } else {
            trainingDayErrorModalRef.current?.dismiss();
        }
    }, [isUpdateTrainingDayError]);

    useEffect(() => {
        if (!isWeb && data?.status === TrainingItemStatus.FINISHED) {
            navigation.setParams({
                finished: true,
            });
        }
    }, [data, isWeb, navigation]);

    //Get all the already finished exercises and store in the map for easier access
    useEffect(() => {
        if (isFetched) {
            setFinishedExercises(buildFinishedExercises(data));
            finishedExercisesInitialized.current = true;
        }
    }, [data, isFetched]);

    const finishedPreviousCycleExercises = useMemo(() => {
        return buildFinishedExercises(trainingDayFromPrevCycleData, 'exerciseID', 'cardioexerciseID');
    }, [trainingDayFromPrevCycleData]);

    //Remove all the finished series/cardio exercises data
    const resetTraining = useCallback(async () => {
        const finishedSeriesIds = Object.values(data?.finishedSeries ?? []).map((i) => i.id);
        const finishedCardioIds = Object.values(data?.finishedCardioExercises ?? []).map((i) => i.id);

        await resetCardioExercisesData({
            ids: finishedCardioIds,
        });

        await resetFinishedSeriesData({
            ids: finishedSeriesIds,
        });

        await updateTrainingDay({
            id: data?.id!,
            status: TrainingItemStatus.NOT_STARTED,
        });

        await queryClient.refetchQueries(['getTrainingDaysByTrainingId', data?.trainingID]);
        await queryClient.refetchQueries(['getInProgressTrainingDay']);

        finishedExercisesInitialized.current = false;
        refetch();
    }, [
        data?.finishedCardioExercises,
        data?.finishedSeries,
        data?.id,
        data?.trainingID,
        queryClient,
        refetch,
        resetCardioExercisesData,
        resetFinishedSeriesData,
        updateTrainingDay,
    ]);

    //Update the training day status to finished nad set the date
    const finishTraining = useCallback(async () => {
        await updateTrainingDay({
            id: data?.id!,
            status: TrainingItemStatus.FINISHED,
            date: new Date().toISOString(),
        });
        await queryClient.refetchQueries(['getTrainingDaysByTrainingId', data?.trainingID]);
        await queryClient.refetchQueries(['getInProgressTrainingDay']);

        if (isWeb) {
            await queryClient.refetchQueries(['getTrainingDayWithExercises', trainingDayId]);
        }
    }, [data?.id, data?.trainingID, isWeb, queryClient, trainingDayId, updateTrainingDay]);

    //Update the training day status to in progress if it's not started yet when interacting with the exercise
    const markTrainingAsInProgressWhenNotStarted = useCallback(
        async ({ done }: { done?: boolean }) => {
            try {
                if (data?.status === 'NOT_STARTED' && done) {
                    await updateTrainingDay({
                        id: data.id!,
                        status: TrainingItemStatus.IN_PROGRESS,
                    });
                    await queryClient.refetchQueries(['getTrainingDaysByTrainingId', data?.trainingID]);
                    await queryClient.refetchQueries(['getInProgressTrainingDay']);
                }
            } catch (e) {}
        },
        [data?.id, data?.status, data?.trainingID, queryClient, updateTrainingDay],
    );

    const updateSeriesData = useCallback(
        async (trainingExerciseId: string, seriesData: TrainingScreenFormFinishedExercise) => {
            const currentData = finishedExercises[trainingExerciseId].series[seriesData.seriesNumber];

            const dataToSave = {
                id: (currentData as FinishedSeriesData)?.id,
                done: seriesData.done,
                reps: Number(seriesData.reps),
                seriesNumber: Number(seriesData.seriesNumber),
                weight: Number(seriesData.weight),
                trainingexerciseID: trainingExerciseId,
            };

            try {
                const newData = await createOrUpdateFinishedSeriesData(dataToSave);

                setFinishedExercises((prev) => {
                    const updated = { ...prev };
                    updated[trainingExerciseId].series[seriesData.seriesNumber] = newData;
                    return updated;
                });

                await markTrainingAsInProgressWhenNotStarted(newData);
            } catch (e) {
                throw e;
            }
        },
        [createOrUpdateFinishedSeriesData, finishedExercises, markTrainingAsInProgressWhenNotStarted],
    );

    const updateCardioData = useCallback(
        async (trainingCardioExerciseId: string, cardioData: { time: number }) => {
            const currentData = finishedExercises[trainingCardioExerciseId].finishedCardio;

            const dataToSave = {
                id: (currentData as CardioExerciseData)?.id,
                time: cardioData.time,
                trainingCardioExerciseId,
            };

            try {
                const newData = await createOrUpdateFinishedCardioExerciseData(dataToSave);

                setFinishedExercises((prev) => {
                    const updated = { ...prev };
                    updated[trainingCardioExerciseId].finishedCardio = newData;
                    return updated;
                });

                await markTrainingAsInProgressWhenNotStarted({ done: true });
            } catch (e) {
                throw e;
            }
        },
        [createOrUpdateFinishedCardioExerciseData, finishedExercises, markTrainingAsInProgressWhenNotStarted],
    );

    const getMapData: TrainingScreenContextType['getMapData'] = useCallback(
        (key, id) => data?.[key]?.[id] ?? null,
        [data],
    );

    const getIsSectionNotEmpty = useCallback(
        (type: TrainingDayGroupType, groupExercises: (TrainingExercise | TrainingCardioExercise)[]) => {
            if (type === TrainingDayGroupType.CARDIO) {
                return (groupExercises as TrainingCardioExercise[]).some(
                    (i) => data?.cardioExercisesDataMap[i.trainingCardioExerciseCardioExerciseDataId]?.time,
                );
            }

            return (groupExercises as TrainingExercise[]).some(
                (i) => data?.exercisesDataMap[i.trainingExerciseExerciseDataId]?.series,
            );
        },
        [data],
    );

    const sections: TrainingScreenSection[] = useMemo(
        () =>
            (
                orderedGroups.map((group, index) => {
                    const isCardio = group.type === TrainingDayGroupType.CARDIO;
                    const groupExercises = data?.[isCardio ? 'trainingCardioExercises' : 'trainingExercises'] as (
                        | TrainingExercise
                        | TrainingCardioExercise
                    )[];

                    const isFirstOfGroupType = orderedGroups[index - 1]?.type !== group.type;
                    const sectionData =
                        groupExercises.filter((i) => i.trainingdaygroupID === group.id).sort(sortByOrderField) ?? [];
                    const isNotEmpty = getIsSectionNotEmpty(group.type as TrainingDayGroupType, sectionData);

                    if (!isNotEmpty) {
                        return;
                    }

                    const filteredData = sectionData.filter((i) => {
                        if (isCardio) {
                            const item = i as TrainingCardioExercise;
                            return !!data?.cardioExercisesDataMap[item.trainingCardioExerciseCardioExerciseDataId]
                                ?.time;
                        }

                        const item = i as TrainingExercise;
                        return !!data?.exercisesDataMap[item.trainingExerciseExerciseDataId]?.series;
                    });

                    return {
                        name: getSectionName(group, data!.exerciseGroupsMap),
                        groupTitle: getGroupTitle(group),
                        groupType: group.type as TrainingDayGroupType,
                        isFirstOfGroupType,
                        data: filteredData.map((i) => i.id),
                    };
                }) ?? []
            ).filter(Boolean) as TrainingScreenSection[],
        [data, getIsSectionNotEmpty, orderedGroups],
    );

    const getNextExercise = useCallback(
        (trainingExerciseId: string) => {
            const currentExercise = data?.trainingExercisesMap[trainingExerciseId];
            const nextExerciseInGroup = data?.trainingExercises
                .filter(
                    (i) =>
                        i.trainingdaygroupID === currentExercise?.trainingdaygroupID &&
                        i.order > currentExercise?.order,
                )
                .sort(sortByOrderField)[0];

            if (nextExerciseInGroup) {
                return {
                    name: data?.exercisesMap[nextExerciseInGroup.exerciseID].name!,
                    numberOfSeries: data?.exercisesDataMap[nextExerciseInGroup.trainingExerciseExerciseDataId].series,
                };
            }

            const nextGroupId =
                orderedGroups[orderedGroups.findIndex((i) => i.id === currentExercise?.trainingdaygroupID) + 1]?.id;

            const firstExerciseInNextGroup = data?.trainingExercises
                .filter((i) => i.trainingdaygroupID === nextGroupId)
                .sort(sortByOrderField)[0];

            if (firstExerciseInNextGroup) {
                return {
                    name: data?.exercisesMap[firstExerciseInNextGroup.exerciseID].name!,
                    numberOfSeries:
                        data?.exercisesDataMap[firstExerciseInNextGroup.trainingExerciseExerciseDataId].series,
                };
            }

            return {
                name: data?.cardioExercisesMap[data?.trainingCardioExercises[0]?.cardioexerciseID]?.name!,
                time: data?.cardioExercisesDataMap[
                    data?.trainingCardioExercises[0]?.trainingCardioExerciseCardioExerciseDataId
                ]?.time,
            };
        },
        [
            data?.cardioExercisesDataMap,
            data?.cardioExercisesMap,
            data?.exercisesDataMap,
            data?.exercisesMap,
            data?.trainingCardioExercises,
            data?.trainingExercises,
            data?.trainingExercisesMap,
            orderedGroups,
        ],
    );

    const getPreviousExerciseForSuperSet = useCallback(
        (trainingExerciseId: string) => {
            const currentExercise = data?.trainingExercisesMap[trainingExerciseId];
            const currentGroupExercises = data?.trainingExercises
                .filter((i) => i.trainingdaygroupID === currentExercise?.trainingdaygroupID)
                .sort(sortByOrderField);
            const currentExerciseIndex = currentGroupExercises?.findIndex((i) => i.id === trainingExerciseId);
            const previousExercise = currentGroupExercises?.[currentExerciseIndex! - 1];

            if (previousExercise?.isSuperSet) {
                return {
                    name: data?.exercisesMap[previousExercise.exerciseID].name!,
                    numberOfSeries: data?.exercisesDataMap[previousExercise.trainingExerciseExerciseDataId].series,
                };
            }

            return null;
        },
        [data?.exercisesDataMap, data?.exercisesMap, data?.trainingExercises, data?.trainingExercisesMap],
    );

    const value: TrainingScreenContextType = useMemo(
        () => ({
            data: data ?? null,
            orderedGroups,
            isFetching: isFetching || !finishedExercisesInitialized.current,
            trainingDayId,
            trainingName: trainingDay?.title ?? '',
            cycleName: trainingCycle?.shortTitle ?? '',
            duration,
            totalNumberOfSeries,
            sections,
            getMapData,
            finishedExercises,
            finishedPreviousCycleExercises,
            updateSeriesData,
            getNextExercise,
            getPreviousExerciseForSuperSet,
            updateCardioData,
            finishTraining,
            resetTraining,
            refetch,
            isRefetching,
        }),
        [
            isRefetching,
            data,
            trainingDay,
            trainingCycle,
            orderedGroups,
            isFetching,
            trainingDayId,
            duration,
            totalNumberOfSeries,
            sections,
            getMapData,
            finishedExercises,
            finishedPreviousCycleExercises,
            updateSeriesData,
            getNextExercise,
            getPreviousExerciseForSuperSet,
            updateCardioData,
            finishTraining,
            resetTraining,
            refetch,
        ],
    );

    return (
        <TrainingScreenContext.Provider value={value}>
            <ConfirmSheet
                ref={exerciseDataErrorModalRef}
                title="Błąd"
                subtitle="Nie udało się zapisać danych ćwiczenia. Spróbuj ponownie."
                primaryButtonLabel="OK"
                variant="error"
                onConfirm={() => {
                    resetCreateOrUpdateFinishedSeriesDataError();
                    resetCreateOrUpdateFinishedCardioExerciseData();
                }}
            />

            <ConfirmSheet
                ref={trainingDayErrorModalRef}
                title="Błąd"
                primaryButtonLabel="OK"
                subtitle="Nie udało się zaktualizować treningu. Spróbuj ponownie."
                variant="error"
                onClose={resetUpdateTrainingDay}
            />
            {children}
        </TrainingScreenContext.Provider>
    );
};

export const useTrainingScreen = () => useContext(TrainingScreenContext);
export function withTrainingScreenContext<T extends TrainingScreenProps>(WrappedComponent: React.ComponentType<T>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithTrainingScreenContext = (props: T) => {
        const { trainingDayId } = props.route?.params ?? {};

        return (
            <TrainingScreenContextProvider trainingDayId={trainingDayId}>
                <WrappedComponent {...(props as T)} />
            </TrainingScreenContextProvider>
        );
    };

    ComponentWithTrainingScreenContext.displayName = `withTrainingScreenContext(${displayName})`;

    return ComponentWithTrainingScreenContext;
}
