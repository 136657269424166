import { Measurement } from '@pg/backend';
import { QueryFunction, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const fetchMeasurement: QueryFunction<Measurement | null, ['getMeasurement', string]> = async ({ queryKey }) => {
    try {
        const [_, id] = queryKey;

        return (await DataStore.query(Measurement, id)) ?? null;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const useGetMeasurement = (
    id: string,
    options?: UseQueryOptions<Measurement | null, unknown, Measurement | null, ['getMeasurement', string]>,
) => useQuery(['getMeasurement', id], fetchMeasurement, options);
