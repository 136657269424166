import { Pressable, View } from 'react-native';
import styled, { DefaultTheme } from 'styled-components/native';

export const Container = styled(Pressable)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 12px;
`;

const getBackgroundColor = ({
    started,
    hovered,
    pressed,
    theme,
}: {
    theme: DefaultTheme;
    started?: boolean;
    hovered?: boolean;
    pressed?: boolean;
}) => {
    if (started && (hovered || pressed)) {
        return theme.palette.red['60'];
    }

    if (started) {
        return theme.interactive.critical;
    }

    if (hovered || pressed) {
        return theme.interactive.primaryHover;
    }

    return theme.interactive.primaryDefault;
};

export const Background = styled(View)<{ started?: boolean; hovered?: boolean; pressed?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background-color: ${getBackgroundColor};
`;

export const PlayIcon = styled(View)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 1px;
    width: 100%;
    height: 100%;
`;

export const StopIcon = styled(View)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
