import { SectionType } from './ShoppingListCategories.types';

export const data: SectionType[] = [
    {
        title: 'Warzywa',
        data: [
            {
                id: '1',
                productName: 'Ogórek',
                details: '500g',
                checked: true,
            },
            {
                id: '2',
                productName: 'Ogórek',
                details: '500g',
                checked: false,
            },
            {
                id: '3',
                productName: 'Ogórek',
                details: '500g',
                checked: true,
            },
            {
                id: '4',
                productName: 'Ogórek',
                details: '500g',
                checked: false,
            },
            {
                id: '5',
                productName: 'Ogórek',
                details: '500g',
                checked: true,
            },
        ],
    },
];
