import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { DEFAULT_DATE_FORMAT } from '~/constants/calendar';
import { IconName } from '~/icons';

import { FloatingLabelAnimation } from '../animated';
import { MeasurementCalendarSheet } from '../bottomSheet/measurementCalendarSheet/MeasurementCalendarSheet';
import { Icon } from '../icon';
import { CalendarModal } from '../modal/calendarModal';
import { Popup } from '../popup';
import * as S from './DateInput.styled';

const AnimatedLabel = Animated.createAnimatedComponent(S.Label);

export interface DateInputProps {
    icon: IconName;
    label: string;
    dateFormat?: string;
    value: string;
    defaultValue?: number | Date;
    disabled?: boolean;
    error?: boolean;
    style?: ViewStyle;
    enabledDates?: string[];
    disabledDates?: string[];
    onChange: (date: string) => void;
    onMonthChange?: (date: string) => void;
}

export const DateInput: FC<DateInputProps> = ({
    icon,
    label,
    value,
    defaultValue,
    disabled = false,
    error = false,
    style,
    dateFormat = 'd.MM.yyyy',
    enabledDates,
    disabledDates,
    onChange,
    onMonthChange,
}) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);

    const smallLabel = !!value || !!defaultValue;
    const [layout, setLayout] = useState<LayoutRectangle>({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    });

    const iconFill = disabled
        ? theme.icon.disabled
        : error
        ? theme.icon.critical
        : value
        ? theme.icon.primary
        : theme.icon.tertiary;

    const handleLayout = useCallback((e: LayoutChangeEvent) => {
        setLayout(e.nativeEvent.layout);
    }, []);

    const handleCalendarOpen = () => {
        if (disabled) return null;
        setIsOpen(true);
    };

    const handleModalDateChange = (date: string) => {
        onChange(date);
        setIsOpen(false);
    };

    const renderCalendar = () => {
        if (theme.isWebDesktop && isOpen) {
            return (
                <Popup
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    position={'top center'}
                    trigger={<S.TriggerWrapper />}
                >
                    <CalendarModal
                        onChange={handleModalDateChange}
                        onMonthChange={onMonthChange}
                        currentDate={value}
                        onClose={() => setIsOpen(false)}
                        enabledDates={enabledDates}
                        disabledDates={disabledDates}
                    />
                </Popup>
            );
        }
        return (
            <MeasurementCalendarSheet
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
                onChange={onChange}
                onMonthChange={onMonthChange}
                currentDate={value ? format(new Date(value), DEFAULT_DATE_FORMAT) : undefined}
                enabledDates={enabledDates}
                disabledDates={disabledDates}
            />
        );
    };

    return (
        <>
            <S.Container onPress={handleCalendarOpen} style={style}>
                <S.Content>
                    <S.IconWrapper pointerEvents="none">
                        <Icon name={icon} size="100%" color={iconFill} />
                    </S.IconWrapper>

                    <FloatingLabelAnimation smallLabel={smallLabel} layout={layout} focused={false}>
                        {(animatedStyle) => (
                            <AnimatedLabel
                                pointerEvents="none"
                                style={animatedStyle}
                                onLayout={handleLayout}
                                error={error}
                            >
                                {label}
                            </AnimatedLabel>
                        )}
                    </FloatingLabelAnimation>

                    <S.Input
                        editable={false}
                        disabled={disabled}
                        error={error}
                        value={value ? format(new Date(value ?? null), dateFormat) : undefined}
                        defaultValue={defaultValue ? format(defaultValue, dateFormat) : ''}
                    />
                </S.Content>
                <S.Border error={error} />
                {renderCalendar()}
            </S.Container>
        </>
    );
};
