import { FC, ReactNode } from 'react';

import * as S from './UnitSuffix.styled';

export interface UnitSuffixProps {
    unit: 'g' | 'kg' | 'cm' | 'm' | 'zł';
    children: ReactNode;
}

export const UnitSuffix: FC<UnitSuffixProps> = ({ unit, children }) => {
    return (
        <>
            {children}
            <S.Suffix>{unit}</S.Suffix>
        </>
    );
};
