import React, { ReactNode } from 'react';
import { useTheme } from 'styled-components/native';
import { Logo } from '~/components/logo';
import { TextButton } from '~/components/textButton';

import * as S from './AuthFormLayout.styled';

export interface BottomAction {
    id: string;
    label: ReactNode;
    onPress?: () => void;
    disabled?: boolean;
}

export interface AuthFormLayoutProps {
    title: string;
    error?: string;
    subtitle?: string;
    bottomActions?: BottomAction[];
    disabled?: boolean;
    children: ReactNode;
}

export const AuthFormLayout: React.FC<AuthFormLayoutProps> = ({
    title,
    subtitle,
    children,
    bottomActions,
    error,
    disabled,
}) => {
    const { isWebDesktop } = useTheme();
    return (
        <S.Container>
            {isWebDesktop && (
                <S.LogoContainer>
                    <Logo size={100} />
                </S.LogoContainer>
            )}
            <S.Title>{title}</S.Title>
            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
            <S.FormContent>{children}</S.FormContent>
            {error && (
                <S.ErrorContainer>
                    <S.ErrorText>{error}</S.ErrorText>
                </S.ErrorContainer>
            )}
            {bottomActions?.length && (
                <S.BottomActions>
                    {bottomActions.map(
                        ({ id, label, onPress, disabled: actionDisabled }) =>
                            onPress && (
                                <TextButton key={id} onPress={onPress} disabled={disabled || actionDisabled}>
                                    {label}
                                </TextButton>
                            ),
                    )}
                </S.BottomActions>
            )}
        </S.Container>
    );
};
