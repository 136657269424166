import React, { FormEvent, ReactNode } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';

export interface SubmittableFormProps {
    style?: ViewStyle;
    children: ReactNode;
    disabled?: boolean;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export const SubmittableForm = ({ style, children, disabled = false, onSubmit }: SubmittableFormProps) => {
    const theme = useTheme();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(e);
    };

    if (theme.isWeb) {
        return (
            <form onSubmit={handleSubmit} style={StyleSheet.flatten(style) as React.CSSProperties}>
                {children}
                <button type="submit" disabled={disabled} style={{ display: 'none' }} />
            </form>
        );
    }

    return <>{children}</>;
};
