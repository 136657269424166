import React from 'react';
import { ComingSoon } from '~/components/comingSoon';

export const HealthTestsComingSoon = () => (
    <ComingSoon
        title="Wkrótce w tym miejscu:"
        features={[
            'Będzie możliwość dodawania badań, abym jeszcze dokładniej mógł spersonalizować dla Ciebie trening i dietę',
        ]}
        imageSrc={require('~/assets/images/health_tests_coming_soon.png')}
    />
);
