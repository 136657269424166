import { User } from '@pg/backend';
import { MutationFunction } from '@tanstack/query-core/build/lib/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const updateUser =
    (id: string): MutationFunction<User, Partial<User>> =>
    async (user) => {
        const original = await DataStore.query(User, id);

        if (!original) throw new Error(`User with id ${id} not found`);

        return await DataStore.save(
            User.copyOf(original, (updated) => {
                if (user.firstName) updated.firstName = user.firstName;
                if (user.lastName) updated.lastName = user.lastName;
                if (user.avatarPath) updated.avatarPath = user.avatarPath;
                if (user.settings) updated.settings = user.settings;
            }),
        );
    };

export const useUpdateUser = (id: string, options?: UseMutationOptions<User, unknown, Partial<User>>) =>
    useMutation<User, unknown, Partial<User>>(updateUser(id), options);
