import { ExerciseGroup } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchExerciseGroups: QueryFunction<ExerciseGroup[], ['getExerciseGroups']> = async () => {
    try {
        return await DataStore.query(ExerciseGroup, null, {
            sort: (s) => s.name(SortDirection.ASCENDING),
        });
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const useGetExerciseGroups = () => useQuery(['getExerciseGroups'], fetchExerciseGroups, { initialData: [] });
