import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { SEO } from '~/components/wrappers';
import { useAuth } from '~/enhancers/auth.context';
import { useNotifications } from '~/enhancers/notifications.context';
import { AuthLayout } from '~/layouts/authLayout';
import { PasswordRecoveryForm } from '~/screens/auth/passwordRecoveryScreen/components/PasswordRecoveryForm';
import { FormValues } from '~/screens/auth/passwordRecoveryScreen/components/PasswordRecoveryForm/PasswordRecoveryForm.types';
import { getErrorMessage } from '~/utils/authErrorMessage';

import { AuthFormLayout } from '../layouts/authFormLayout';
import { notificationMessage } from './PasswordRecoveryScreen.constants';
import { PasswordRecoveryScreenProps } from './PasswordRecoveryScreen.types';

export const PasswordRecoveryScreen: React.FC<PasswordRecoveryScreenProps> = ({ navigation }) => {
    const theme = useTheme();
    const auth = useAuth();
    const { addNotification } = useNotifications();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const handleRecoveryPasswordErrors = useCallback(
        (e: any, email: FormValues['email']) => {
            const error = e as { code: string };

            if (error.code === 'UserNotFoundException') {
                auth.setNewPasswordConfirmation(true);
                navigation.navigate('NewPassword', { email });

                return;
            }

            if (error.code === 'LimitExceededException') {
                addNotification({
                    message: getErrorMessage(e, {
                        LimitExceededException: notificationMessage.resendCodeLimit,
                    }),
                    type: 'error',
                });

                return;
            }

            auth.setNewPasswordConfirmation(false);
            setErrorMessage(getErrorMessage(e));
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [addNotification, navigation],
    );

    const handleRecoverPassword: SubmitHandler<FormValues> = useCallback(
        async ({ email }) => {
            setErrorMessage(undefined);
            setLoading(true);
            try {
                await auth.forgotPassword(email);
                auth.setNewPasswordConfirmation(true);
                navigation.navigate('NewPassword', { email });
            } catch (e) {
                console.log(e);
                handleRecoveryPasswordErrors(e, email);
            } finally {
                setLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth, navigation],
    );

    const layoutImageSrc = useMemo(() => {
        return theme.isWebDesktop
            ? require('~/assets/images/password_recovery_web.webp')
            : require('~/assets/images/password_recovery.png');
    }, [theme.isWebDesktop]);

    return (
        <AuthLayout imageSrc={layoutImageSrc} nativeImageSize="large">
            <SEO title="Przywracanie hasła" />
            <AuthFormLayout
                title="Odzyskaj hasło"
                subtitle="Podaj adres email, którego używasz do logowania"
                disabled={loading}
                error={errorMessage}
                bottomActions={[
                    {
                        id: 'sign-in',
                        label: 'Powrót do logowania',
                        onPress: () => navigation.navigate('SignIn'),
                    },
                ]}
            >
                <PasswordRecoveryForm onSubmit={handleRecoverPassword} />
            </AuthFormLayout>
        </AuthLayout>
    );
};
