import { Circle, G } from 'react-native-svg';
import styled from 'styled-components/native';

export const Container = styled(G)`
    cursor: pointer;
`;

export const DataPointBorder = styled(Circle)`
    fill: ${({ theme }) => theme.fill.background};
    stroke: ${({ theme }) => theme.border.interactive};
`;

export const DataPointDot = styled(Circle)`
    fill: ${({ theme }) => theme.fill.level100};
`;
