import { Control, Controller } from 'react-hook-form';

import { FormValues, PhotoValue } from '../../Measurement/MeasurementScreen.types';
import { PhotoTile } from '../photoTile';
import * as S from './MeasurementForm.styled';

interface PhotosPickerProps {
    value: PhotoValue[];
    control: Control<FormValues>;
    onAdd: (value: PhotoValue['value']) => Promise<void>;
    onRemove: (value: PhotoValue['value']) => void;
}

export const PhotosPicker: React.FC<PhotosPickerProps> = ({ value, control, onAdd, onRemove }) => {
    const lastElementIndex = value.length - 1;

    return (
        <S.PhotoTilesContainer>
            {value.map(({ caption, value: photo }, index) => {
                return (
                    <S.PhotoTileWrapper key={photo} last={index === lastElementIndex}>
                        <Controller
                            name={photo}
                            control={control}
                            render={({ field }) => (
                                <PhotoTile
                                    imageUri={field.value?.uri}
                                    caption={caption}
                                    onAdd={() => onAdd(photo)}
                                    onRemove={() => onRemove(photo)}
                                />
                            )}
                        />
                    </S.PhotoTileWrapper>
                );
            })}
        </S.PhotoTilesContainer>
    );
};
