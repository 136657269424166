import React, { FC } from 'react';

import { DetailsTile } from '../detailsTile';
import * as S from './DetailsTiles.styled';

interface DetailsTilesProps {
    bmi: number;
    ppm: number;
    cpm: number;
}

export const DetailsTiles: FC<DetailsTilesProps> = ({ bmi, ppm, cpm }) => {
    return (
        <S.Container>
            <S.Wrapper>
                <DetailsTile title="Twoje BMI" value={bmi} />
            </S.Wrapper>
            <S.Wrapper>
                <DetailsTile title="Twoje PPM" value={ppm} unit="kcal" />
            </S.Wrapper>
            <S.Wrapper last>
                <DetailsTile title="Twoje CPM" value={cpm} unit="kcal" />
            </S.Wrapper>
        </S.Container>
    );
};
