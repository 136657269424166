import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/loader';
import { usePressableState } from '~/hooks/usePressableState';

import { PeriodSelectionButtonAnimation } from './PeriodSelectionButton.animated';
import * as S from './PeriodSelectionButton.styled';

export interface PeriodSelectionButtonProps
    extends Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'disabled'> {
    isOpen: boolean;
    isLoading: boolean;
    title: string;
    subtitle: string;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);
const AnimatedIcon = Animated.createAnimatedComponent(S.IconWrapper);

export const PeriodSelectionButton: React.FC<PeriodSelectionButtonProps> = ({
    isOpen,
    isLoading,
    title,
    subtitle,
    onPress,
}) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <PeriodSelectionButtonAnimation progress={pressableAnimationProgress} isOpen={isOpen}>
            {({ containerAnimationStyles, iconAnimationStyles }) => (
                <AnimatedContainer {...pressableProps} style={containerAnimationStyles} onPress={onPress}>
                    <S.TextContent>
                        {isLoading ? (
                            <Loader size="m" />
                        ) : (
                            <>
                                <S.Title>{title}</S.Title>
                                <S.Subtitle>{subtitle}</S.Subtitle>
                            </>
                        )}
                    </S.TextContent>
                    <S.RightContentWrapper>
                        <AnimatedIcon style={iconAnimationStyles}>
                            <S.Icon color={theme.icon.tertiary} name={'chevron-up'} size={20} />
                        </AnimatedIcon>
                    </S.RightContentWrapper>
                </AnimatedContainer>
            )}
        </PeriodSelectionButtonAnimation>
    );
};
