import React, { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { BottomSheetModal } from '~/components/bottomSheet';
import { ConfirmSheet } from '~/components/bottomSheet/confirmSheet';
import { Button } from '~/components/button';
import { DesktopSettingsHeading } from '~/components/desktopSettingsHeading';
import { Pagination } from '~/components/pagination';
import { Table } from '~/components/table';
import { SEO } from '~/components/wrappers';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useHealthTests } from '~/enhancers/healthTests.context';
import { MainLayout } from '~/layouts';
import { MoreLayout } from '~/layouts/moreLayout';
import { MainLayout as MainLayoutWeb } from '~/layouts/web';

import { HealthTestsMobileContent } from './HealthTestsScreen.native';
import * as S from './HealthTestsScreen.styled';
import { HealthTestsComingSoon } from './components/healthTestesComingSoon';
import { useHealthTestsTable } from './hooks/useHealthTestsTable';

const title = 'Badania';

export const HealthTestsScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();
    const { table, pagination, removalConfirmSheet, healthTestIdToDelete } = useHealthTestsTable();
    const emptyState = !table.data.length;
    const healthTestRemovalConfirmSheet = useRef<BottomSheetModal>(null);
    const { addHealthTests } = useHealthTests();

    useEffect(() => {
        if (healthTestIdToDelete) {
            healthTestRemovalConfirmSheet.current?.present();
        } else {
            healthTestRemovalConfirmSheet.current?.close();
        }
    }, [healthTestIdToDelete]);

    const renderDesktop = () => {
        return (
            <MainLayout>
                <MoreLayout>
                    <DesktopSettingsHeading
                        title={title}
                        sideContent={
                            emptyState ? undefined : (
                                <Button variant="secondary" size="s" icon="add" onPress={addHealthTests}>
                                    Dodaj badanie
                                </Button>
                            )
                        }
                    />
                    {!features.healthTests ? (
                        <HealthTestsComingSoon />
                    ) : (
                        <S.Content>
                            <S.TableContainer fullSize={emptyState}>
                                <Table {...table} />
                            </S.TableContainer>
                            {pagination && (
                                <S.PaginationContainer>
                                    <Pagination page={1} totalPages={2} onPageChange={() => {}} />
                                </S.PaginationContainer>
                            )}
                        </S.Content>
                    )}
                </MoreLayout>

                <ConfirmSheet ref={healthTestRemovalConfirmSheet} {...removalConfirmSheet} />
            </MainLayout>
        );
    };

    const renderMobile = () => {
        if (!features.healthTests) {
            return <MainLayoutWeb title={title} content={<HealthTestsComingSoon />} />;
        }

        return <MainLayoutWeb title={title} fullWidth verticalSpacing={false} content={<HealthTestsMobileContent />} />;
    };

    return (
        <>
            <SEO title={title} />
            {theme.isWebDesktop ? renderDesktop() : renderMobile()}
        </>
    );
};
