import { Pressable, Text, TextInput, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme/mixins';

import { InputStyledProps } from './Input.types';

export const Container = styled(View)<{ multiline?: boolean }>`
    height: 52px;
    width: 100%;
    z-index: 100;

    ${({ multiline }) =>
        multiline &&
        css`
            height: auto;
        `}
`;

export const Content = styled(View)<{ multiline?: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;

    ${({ multiline }) =>
        multiline &&
        css`
            height: auto;
        `}
`;

export const IconWrapper = styled(Pressable)<{ alignment: 'left' | 'right' }>`
    position: absolute;
    top: 16px;
    z-index: 2;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ alignment }) =>
        alignment === 'left' &&
        css`
            left: 12px;
        `}

    ${({ alignment }) =>
        alignment === 'right' &&
        css`
            right: 12px;
        `}
`;

const inputLineHeight = 20;
const inputPaddingTop = 24;
const inputPaddingBottom = 8;

export const Input = styled(TextInput)<
    InputStyledProps & { focused: boolean; withLeftIcon?: boolean; withRightIcon?: boolean }
>`
    ${mixins.typography.ui.medium16};
    line-height: 20px;
    padding: ${inputPaddingTop}px 12px ${inputPaddingBottom}px;
    flex: 1;
    background: ${({ theme, editable }) => (!editable ? theme.fill.level2 : theme.fill.background)};
    color: ${({ theme, editable }) => (!editable ? theme.text.disabled : theme.text.primary)};
    vertical-align: top;
    outline-width: 0;

    ${({ withLeftIcon }) => withLeftIcon && 'padding-left: 40px;'};
    ${({ withRightIcon }) => withRightIcon && 'padding-right: 40px;'};

    ${({ numberOfLines }) =>
        numberOfLines && `height: ${numberOfLines * inputLineHeight + (inputPaddingTop + inputPaddingBottom)}px;`};
`;

export const Border = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 8px;
`;

export const SuffixWrapper = styled(View)<{ disabled?: boolean }>`
    height: 100%;
    justify-content: center;
    padding-right: 12px;
    background: ${({ theme, disabled }) => (disabled ? theme.fill.level2 : theme.fill.background)};
`;

export const Suffix = styled(Text)<{ disabled?: boolean }>`
    ${mixins.typography.ui.regular14};
    color: ${({ theme, disabled }) => (disabled ? theme.text.tertiary : theme.text.disabled)};
`;
