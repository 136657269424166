import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Content = styled(View)`
    width: 100%;
    flex: 1;
    padding: 0 32px 32px;
    overflow: auto;
`;

export const TableContainer = styled(View)<{ fullSize?: boolean }>`
    width: 100%;
    max-width: 854px;

    ${({ fullSize }) =>
        fullSize &&
        css`
            max-width: none;
            flex: 1;
        `}
`;

export const PaginationContainer = styled(View)`
    margin-top: 24px;
`;
