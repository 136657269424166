import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { Pagination } from '~/components/pagination';
import { SectionHeading } from '~/components/sectionHeading';
import { Table } from '~/components/table';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useMeasurement } from '~/enhancers/measurement.context';
import { MainLayout } from '~/layouts/web/mainLayout';
import { MeasurementsComingSoon } from '~/screens/measurements/components/measurementsComingSoon';

import { MeasurementsScreenContent } from './MeasurementsScreen.native';
import * as S from './MeasurementsScreen.styled';
import { MeasurementChart } from './components/measurementsChart';
import { MetamorphosisContent } from './components/metamorphosisContent';
import { useMeasurementsTable } from './hooks/useMeasurementsTable';

const title = 'Pomiary';

export const MeasurementsScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();
    const [displaySidebar, setDisplaySidebar] = useState(false);
    const { addMeasurement, updateMeasurement, deleteMeasurement } = useMeasurement();

    const { table, pagination } = useMeasurementsTable({
        onEdit: (id) => {
            updateMeasurement(id);
        },
        onRemove: (id) => {
            deleteMeasurement(id);
        },
    });

    if (!features.measurements) {
        return <MainLayout title={title} noBackground={!theme.isWebDesktop} content={<MeasurementsComingSoon />} />;
    }

    const renderContent = () => {
        if (theme.isWebDesktop) {
            return (
                <S.Content>
                    <S.ButtonsContainer>
                        <S.Button
                            variant="primary"
                            size="big"
                            icon="add"
                            label="Dodaj nowy pomiar"
                            onPress={addMeasurement}
                        />
                        {!theme.media.desktop ? (
                            <S.Button
                                size="big"
                                icon="compare"
                                label="Zobacz metamorfozę"
                                last
                                onPress={() => setDisplaySidebar(true)}
                            />
                        ) : null}
                    </S.ButtonsContainer>
                    <S.ChartContainer>
                        <MeasurementChart />
                    </S.ChartContainer>

                    <S.MeasurementsTableWrapper>
                        <SectionHeading variant="subheader" title="Tabela pomiarów" />
                        <S.TableWrapper empty={!table.data.length}>
                            <Table {...table} />
                        </S.TableWrapper>
                        {pagination && pagination.totalPages > 1 && (
                            <S.PaginationWrapper>
                                <Pagination {...pagination} />
                            </S.PaginationWrapper>
                        )}
                    </S.MeasurementsTableWrapper>
                </S.Content>
            );
        }

        return <MeasurementsScreenContent />;
    };

    return (
        <MainLayout
            title={title}
            content={renderContent()}
            fullWidth={!theme.isWebDesktop}
            sidebar={{
                visible: displaySidebar,
                title: 'Metamorfoza',
                content: (
                    <S.SidebarWrapper>
                        <MetamorphosisContent fullWidth />
                    </S.SidebarWrapper>
                ),
                onClose: () => setDisplaySidebar(false),
            }}
        />
    );
};
