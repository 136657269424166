import { TrainingItemStatus } from '@pg/backend';
import React, { FC, useState } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { ActivityButton } from '~/components/activityButton';
import { TrainingDayOverviewSheet } from '~/components/bottomSheet';
import { Icon } from '~/components/icon';
import { Popup } from '~/components/popup';
import { TrainingPreviewPopup } from '~/components/popup/trainingPreviewPopup';
import { usePressableState } from '~/hooks/usePressableState';

import { TrainingAxisCellAnimations } from './TrainingAxisCell.animated';
import * as S from './TrainingAxisCell.styled';
import { TrainingAxisCellProps } from './TrainingAxisCell.types';
import { getTrainingSubtitle } from './TrainingAxisCell.utils';

const AnimatedBackground = Animated.createAnimatedComponent(S.Background);

export const TrainingAxisCell: FC<TrainingAxisCellProps> = ({
    id,
    title,
    status,
    date,
    last = false,
    onPress,
    selected,
    finishedSeries,
}) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);

    const subtitle = getTrainingSubtitle(status, date, finishedSeries);

    const handleOpenPreview = () => setIsPreviewVisible(true);
    const handleClosePreview = () => setIsPreviewVisible(false);

    const handlePlay = () => {
        setIsPreviewVisible(false);
        onPress();
    };

    return (
        <>
            <S.Container>
                <S.AxisContainer>
                    <S.Dot status={status} />
                    {!last && (
                        <>
                            <S.Axis />
                            <S.AxisMax />
                        </>
                    )}
                </S.AxisContainer>
                <S.ContentContainer {...pressableProps} onPress={onPress}>
                    <TrainingAxisCellAnimations progress={pressableAnimationProgress} selected={selected}>
                        {({ backgroundAnimatedStyles }) => (
                            <>
                                <AnimatedBackground style={backgroundAnimatedStyles} />
                                <S.TextContainer>
                                    <S.Title status={status}>{title}</S.Title>
                                    <S.SubtitleContainer>
                                        {status === TrainingItemStatus.FINISHED && (
                                            <S.Check>
                                                <Icon name="check-fill" size={12} color={theme.icon.positive} />
                                            </S.Check>
                                        )}
                                        <S.Subtitle status={status}>{subtitle}</S.Subtitle>
                                    </S.SubtitleContainer>
                                </S.TextContainer>

                                <S.ViewButton>
                                    {theme.isWebDesktop ? (
                                        <Popup
                                            isOpen={isPreviewVisible && theme.isWebDesktop}
                                            trigger={<ActivityButton icon="view" onPress={handleOpenPreview} />}
                                            onClose={handleClosePreview}
                                            position={['right top', 'right center', 'right bottom']}
                                        >
                                            <TrainingPreviewPopup trainingDayId={id} onClose={handleClosePreview} />
                                        </Popup>
                                    ) : (
                                        <ActivityButton icon="view" onPress={handleOpenPreview} />
                                    )}
                                </S.ViewButton>

                                <Icon name="chevron-right" size={16} color={theme.icon.tertiary} />
                            </>
                        )}
                    </TrainingAxisCellAnimations>
                </S.ContentContainer>
            </S.Container>

            {isPreviewVisible && !theme.isWebDesktop && (
                <TrainingDayOverviewSheet trainingDayId={id} onPlay={handlePlay} onDismiss={handleClosePreview} />
            )}
        </>
    );
};
