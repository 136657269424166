import { FC } from 'react';
import { FlatList } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import * as S from './RulesContent.styled';
import { RuleItem, Rules } from './RulesContent.types';
import { getIconNameAndColor } from './RulesContent.utils';

interface RulesContentProps {
    content?: Rules;
}

export const RulesContent: FC<RulesContentProps> = ({ content }) => {
    const theme = useTheme();

    if (!content) return null;

    const renderContent = ({ item, index }: { item: RuleItem; index: number }) => {
        const isElementLast = index === content.length - 1;

        if (item.type === 'header') {
            return (
                <S.Heading variant={item.itemProperties.variant} key={`${item.type}-${index}`} isLast={isElementLast}>
                    {item.itemProperties.text}
                </S.Heading>
            );
        }

        if (item.type === 'paragraph') {
            return (
                <S.Paragraph key={`${item.type}-${index}`} isLast={isElementLast}>
                    {item.itemProperties.text}
                </S.Paragraph>
            );
        }

        if (item.type === 'image') {
            return <S.Image source={{ uri: item.itemProperties.url }} isLast={isElementLast} />;
        }

        if (item.type === 'box') {
            const icon = getIconNameAndColor(item.itemProperties.variant);
            return (
                <S.Box key={`${item.type}-${index}`} variant={item.itemProperties.variant} isLast={isElementLast}>
                    {icon && (
                        <S.BoxIconWrapper>
                            <Icon name={icon.name} size={32} color={icon.color} />
                        </S.BoxIconWrapper>
                    )}
                    <S.BoxHeader variant={item.itemProperties.variant}>{item.itemProperties?.header}</S.BoxHeader>
                    <S.BoxText variant={item.itemProperties.variant}>{item.itemProperties?.text}</S.BoxText>
                </S.Box>
            );
        }

        return null;
    };

    return (
        <S.Container>
            <FlatList
                data={content}
                renderItem={renderContent}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                    paddingTop: theme.isWebDesktop ? 16 : 0,
                    paddingBottom: theme.isWebDesktop ? 16 : 0,
                }}
            />
        </S.Container>
    );
};
