import { ChatType } from '@pg/backend';

export const TabScreens = {
    screens: {
        Dashboard: '',
        Measurements: 'pomiary',
        ChatTabStack: {
            screens: {
                ChatSelection: 'czat',
                ChatScreen: {
                    path: 'czat/:chatType',
                    stringify: {
                        chatType: (chatType: ChatType) => chatType.toLowerCase(),
                    },
                },
            },
        },
        Diet: 'dieta',
        More: 'wiecej',
        TrainingTabStack: {
            screens: { TrainingPlan: 'plan-treningowy' },
        },
    },
};

export const TrainingStackScreens = {
    screens: {
        Training: 'trening/:trainingDayId/:loading?/:finished?',
        TrainingRules: 'zasady-treningu/:trainingRulesId',
        TrainingPlanChoice: 'wybierz-plan-treningowy',
    },
} as const;

export const MeasurementsStackScreens = {
    screens: {
        Measurement: 'pomiar/:measurementId?',
        Table: 'tabela-pomiarow',
        Metamorphosis: 'metamorfoza',
    },
} as const;

export const DietStackScreens = {
    screens: {
        Meal: 'posilek/:mealId',
        Substitute: 'wybierz-zamiennik/:mealId',
        Settings: 'ustawienia-diety',
        PlanChoice: 'wybierz-plan-dietetyczny',
        Basics: 'podstawy-diety',
        Metabolism: 'twoj-metabolizm',
    },
} as const;

export const MoreStackScreens = {
    screens: {
        ShoppingList: 'lista-zakupow',
        Supplements: 'suplementy',
        ExercisesAtlasStack: {
            screens: {
                ExercisesAtlas: 'atlas-cwiczen',
                SearchExercises: 'atlas-cwiczen/szukaj',
            },
        },
        HealthTests: 'badania',
        MobileApp: 'aplikacja-mobilna',
        Settings: 'ustawienia',
        OrdersHistory: 'historia-zamowien',
        AccountSettings: 'ustawienia-konta',
        Report: 'raport',
    },
};

export const AuthStackScreens = {
    screens: {
        SignIn: 'logowanie',
        // SignUp: 'rejestracja',
        PasswordRecovery: 'odzyskaj-haslo',
        SignUpConfirmation: 'potwierdzenie-rejestracji/:email',
        NewPassword: 'nowe-haslo/:email',
    },
};

export const DashboardStackScreens = {
    screens: {
        ActivityHistory: 'historia-aktywnosci',
        ActivityDetails: 'aktywnosc/:date',
    },
};
