import { SectionList, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

import { ItemType, SectionType } from './ShoppingListRecipes.types';

export const List = styled(SectionList<ItemType, SectionType>).attrs(() => ({
    contentContainerStyle: {
        paddingHorizontal: 20,
    },
}))``;

export const HeaderWrapper = styled(View)``;

export const HeaderContainer = styled(View)`
    flex-direction: row;
    padding-top: 16px;
    padding-bottom: 12px;
    align-items: center;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.medium20};
    color: ${({ theme }) => theme.text.primary};
`;

export const CounterContainer = styled(View)`
    padding: 8px 0;
`;

export const Thumbnail = styled(View)`
    width: 50px;
    height: 35px;
    margin-right: 20px;
    border-radius: 3.75px;
    overflow: hidden;
`;
