import { useEffect } from 'react';
import { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface TrainingCheckAnimationsRenderPropValue {
    borderAnimatedStyles: {
        borderColor: string;
    };
    backgroundAnimatedStyles: {
        backgroundColor: string;
        opacity: number;
    };
    checkIconAnimatedStyles: {
        opacity: number;
    };
}

export interface TrainingCheckAnimationsProps extends AnimationsProps<TrainingCheckAnimationsRenderPropValue> {
    checked: boolean;
    hovered: boolean;
    pressed: boolean;
}

export const TrainingCheckAnimations = ({ checked, hovered, pressed, children }: TrainingCheckAnimationsProps) => {
    const theme = useTheme();

    const getBorderColor = () => {
        if (checked && (hovered || pressed)) {
            return theme.palette.green['30'];
        }

        if (checked) {
            return theme.interactive.positive;
        }

        if (hovered || pressed) {
            return theme.border['40'];
        }

        return theme.border['32'];
    };

    const getBackgroundOpacity = () => Number(checked);

    const getCheckIconOpacity = () => Number(checked);

    const sharedBorderColor = useSharedValue(getBorderColor());
    const sharedBackgroundOpacity = useSharedValue(getBackgroundOpacity());
    const sharedCheckIconOpacity = useSharedValue(getCheckIconOpacity());

    useEffect(() => {
        sharedBorderColor.value = getBorderColor();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked, hovered, pressed]);

    useEffect(() => {
        sharedBackgroundOpacity.value = getBackgroundOpacity();
        sharedCheckIconOpacity.value = getCheckIconOpacity();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    const borderAnimatedStyles = useAnimatedStyle(() => ({
        borderColor: withSpring(sharedBorderColor.value, springConfig.default),
    }));

    const backgroundAnimatedStyles = useAnimatedStyle(() => ({
        backgroundColor: withSpring(sharedBorderColor.value, springConfig.default),
        opacity: withSpring(sharedBackgroundOpacity.value, springConfig.default),
    }));

    const checkIconAnimatedStyles = useAnimatedStyle(() => ({
        opacity: withSpring(sharedCheckIconOpacity.value, springConfig.default),
    }));

    return children({
        borderAnimatedStyles,
        backgroundAnimatedStyles,
        checkIconAnimatedStyles,
    });
};
