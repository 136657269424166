import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
`;

export const Content = styled(View)`
    flex: 1;
    padding: 12px 16px;
`;

export const ActionsContainer = styled(View)`
    padding: 16px;
`;

export const ActionWrapper = styled(View)<{ last?: boolean }>`
    margin-bottom: 12px;

    ${({ last }) =>
        last &&
        `
        margin-bottom: 0;
    `}
`;

export const WeeksContainer = styled(View)``;

export const NavigationContainer = styled(View)`
    margin-top: 8px;
`;

export const NavigationTitle = styled(Text)`
    padding: 8px 16px;
    ${mixins.typography.ui.medium12};
    color: ${({ theme }) => theme.text.disabled};
`;

export const IconWrapper = styled(View)`
    margin-right: 8px;
    position: relative;
    top: 2px;
`;
