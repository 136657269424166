import { forwardRef, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { Icon } from '~/components/icon';

import { BottomSheetHandle } from '../bottomSheetHandle';
import {
    BottomSheetBackdrop,
    BottomSheetBackdropProps,
    BottomSheetModal,
    BottomSheetView,
    useBottomSheetDynamicSnapPoints,
} from '../bottomSheetModal';
import * as S from './InfoSheet.styled';

interface InfoSheetProps {
    text: string;
}

export const InfoSheet = forwardRef<BottomSheetModal | null, InfoSheetProps>(({ text }, ref) => {
    const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], []);
    const theme = useTheme();
    const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
        useBottomSheetDynamicSnapPoints(initialSnapPoints);

    const renderBackdrop = useCallback(
        (props: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop
                {...props}
                pressBehavior="close"
                appearsOnIndex={0}
                disappearsOnIndex={-1}
                opacity={0.8}
            />
        ),
        [],
    );
    const renderHandle = useCallback(
        () => (
            <S.HandleContainer>
                <BottomSheetHandle />
            </S.HandleContainer>
        ),
        [],
    );
    return (
        <S.BottomSheet
            ref={ref}
            snapPoints={animatedSnapPoints}
            backdropComponent={renderBackdrop}
            handleComponent={renderHandle}
            handleHeight={animatedHandleHeight}
            contentHeight={animatedContentHeight}
            backgroundStyle={{ backgroundColor: theme.fill.level2 }}
            enablePanDownToClose
        >
            <BottomSheetView onLayout={handleContentLayout}>
                <S.Container>
                    <Icon name="help-fill" color={theme.icon.interactive} size={48} />
                    <S.Description>{text}</S.Description>
                    {/* @ts-ignore */}
                    <Button variant="secondary" fullWidth size="m" onPress={() => ref?.current?.dismiss()}>
                        OK
                    </Button>
                </S.Container>
            </BottomSheetView>
        </S.BottomSheet>
    );
});
