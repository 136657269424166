import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { FlatList, ListRenderItem } from 'react-native';
import { interpolateColor, useAnimatedScrollHandler, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import {
    BottomSheetBackdrop,
    BottomSheetBackdropProps,
    BottomSheetFlatList,
    BottomSheetHandleProps,
    BottomSheetModal,
} from '~/components/bottomSheet';
import { BottomSheetHandle } from '~/components/bottomSheet/bottomSheetHandle';
import { Icon } from '~/components/icon';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './ActivitySheet.styled';
import { DataType } from './ActivitySheet.types';

interface ActivitySheetProps {
    isOpen: boolean;
    data: DataType[];
    onDismiss: () => void;
}

export const ActivitySheet: FC<ActivitySheetProps> = ({ isOpen, data, onDismiss }) => {
    const theme = useTheme();

    const Container = theme.isWeb ? FlatList : BottomSheetFlatList;

    const translateY = useSharedValue(0);
    const bottomSheetModalRef = useRef<BottomSheetModal>(null);

    const scrollHandler = useAnimatedScrollHandler((event) => {
        translateY.value = event.contentOffset.y;
    });

    const topWrapperStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(translateY.value, [0, 100], [theme.fill.level2, theme.fill.level3]),
        };
    });

    useEffect(() => {
        isOpen ? bottomSheetModalRef.current?.present() : bottomSheetModalRef.current?.close();
    }, [isOpen]);

    const snapPoints = useMemo(() => ['80%'], []);

    const pressableState = usePressableState();

    const renderBackdrop = useCallback(
        ({ handleKeyUpdate, ...props }: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop
                {...props}
                appearsOnIndex={0}
                disappearsOnIndex={-1}
                opacity={0.8}
                onPress={() => {
                    onDismiss();
                    handleKeyUpdate();
                }}
            />
        ),
        [onDismiss],
    );

    const handleActivityPress = () => {
        console.log('Implement adding activity');
        onDismiss();
    };

    const renderItem: ListRenderItem<DataType> = useCallback(
        ({ item, index }) => (
            //TODO Adjust the keys when we'll get proper schema
            <S.ExerciseWrapper key={`${item.name} + ${index}`} onPress={handleActivityPress}>
                <S.ExerciseName>{item.name}</S.ExerciseName>
                <Icon name="add" size={16} color={theme.icon.tertiary} />
            </S.ExerciseWrapper>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const renderHandle = ({ handleKeyUpdate }: BottomSheetHandleProps) => (
        <S.TopContainer style={topWrapperStyle}>
            <BottomSheetHandle />
            <S.TopContentWrapper>
                <S.TopContainerTitle>Dodaj inny rodzaj aktywności</S.TopContainerTitle>
                <S.CloseButtonWrapper
                    onPress={() => {
                        onDismiss();
                        handleKeyUpdate();
                    }}
                >
                    <PressableIcon
                        pressedColor={''}
                        pressableAnimationProgress={pressableState.pressableAnimationProgress}
                        name="close"
                        size={20}
                    />
                </S.CloseButtonWrapper>
            </S.TopContentWrapper>
        </S.TopContainer>
    );

    return (
        <BottomSheetModal
            ref={bottomSheetModalRef}
            index={0}
            snapPoints={snapPoints}
            backdropComponent={renderBackdrop}
            handleComponent={renderHandle}
            onDismiss={onDismiss}
            handleIndicatorStyle={{ backgroundColor: theme.border[20] }}
            backgroundStyle={{ backgroundColor: theme.fill.level2 }}
        >
            <Container
                data={data}
                keyExtractor={(item) => `${item.name}`} //TODO Adjust the keys when we'll get proper schema
                renderItem={renderItem}
                showsVerticalScrollIndicator={false}
                renderScrollComponent={(props) => {
                    return <S.MainContainer {...props} onScroll={scrollHandler} contentInset={{ bottom: 100 }} />;
                }}
            />
        </BottomSheetModal>
    );
};
