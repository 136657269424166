import { ExerciseGroup } from '@pg/backend';
import { useMemo } from 'react';

export const useExerciseGroupsOptions = (
    exerciseGroups: ExerciseGroup[],
    selectedExerciseGroup: string | undefined,
) => {
    const exerciseGroupsOptions = useMemo(() => {
        const allGroup = {
            id: 'all',
            label: 'Wszystkie',
            isSelected: selectedExerciseGroup === undefined,
        };

        const otherGroups = exerciseGroups.map((exerciseGroup) => ({
            id: exerciseGroup.id,
            label: exerciseGroup.name ?? '',
            isSelected: exerciseGroup.id === selectedExerciseGroup,
        }));

        return [allGroup, ...otherGroups];
    }, [selectedExerciseGroup, exerciseGroups]);

    const selectedGroupLabel = useMemo(() => {
        if (!selectedExerciseGroup) {
            return exerciseGroupsOptions.find((item) => item.id === 'all')?.label;
        }
        return exerciseGroupsOptions.find((item) => item.id === selectedExerciseGroup)?.label;
    }, [selectedExerciseGroup, exerciseGroupsOptions]);

    return {
        exerciseGroupsOptions,
        selectedGroupLabel,
    };
};
