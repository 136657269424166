import { Pressable, Text as TextBase, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { mixins } from '~/theme/mixins';
import { HoverableProps } from '~/types/hoverable';

import { ButtonSize, ButtonStyledProps } from './Button.types';

interface ButtonHoverableStyledProps extends ButtonStyledProps, HoverableProps {}

const sizeStylesMap: Record<ButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    xs: css`
        padding: 0 8px;
        height: 24px;
    `,
    s: css`
        padding: 0 12px;
        height: 34px;
    `,
    m: css`
        padding: 0 16px;
        height: 44px;
    `,
};

export const Container = styled(Pressable)<ButtonHoverableStyledProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    border: 2px solid transparent;

    ${({ variant, disabled }) =>
        (variant === 'secondary' || disabled) &&
        css`
            border: 2px solid ${({ theme }) => theme.border['20']};
        `}

    ${({ size }) => sizeStylesMap[size]};

    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  width: 100%;
              `
            : css`
                  align-self: flex-start;
              `}
`;

const sizeTextStylesMap: Record<ButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    xs: css`
        ${mixins.typography.ui.medium12};
    `,
    s: css`
        ${mixins.typography.ui.medium14};
    `,
    m: css`
        ${mixins.typography.ui.medium16};
    `,
};

export const Text = styled(TextBase)<ButtonStyledProps & { loading?: boolean; disabled?: boolean }>`
    color: ${({ theme }) => theme.text.primary};
    ${({ size }) => sizeTextStylesMap[size]};
    text-align: center;
    user-select: none;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            flex: 1;
        `}

    ${({ variant }) =>
        variant === 'error' &&
        css`
            color: ${({ theme }) => theme.text.criticalSurface};
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            color: ${({ theme }) => theme.text.disabled};
        `};

    ${({ loading }) =>
        loading &&
        css`
            opacity: 0;
        `}
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
`;

const iconSizeStylesMap: Record<ButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    xs: css`
        width: 12px;
        height: 12px;
        margin-right: 6px;
    `,
    s: css`
        width: 16px;
        height: 16px;
        margin-right: 8px;
    `,
    m: css`
        width: 20px;
        height: 20px;
        margin-right: 12px;
    `,
};

export const IconWrapper = styled(View)<Pick<ButtonStyledProps, 'size'> & { loading?: boolean; noMargin?: boolean }>`
    ${({ size = 'xs' }) => iconSizeStylesMap[size]};

    ${({ loading }) =>
        loading &&
        css`
            opacity: 0;
        `}

    ${({ noMargin }) =>
        noMargin &&
        css`
            margin-right: 0;
        `}
`;
