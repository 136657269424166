import { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { IconName, icons } from '~/icons';

export interface IconProps {
    name: IconName;
    size?: number | string | { width: number | string; height: number | string };
    color?: string;
}

export const Icon: FC<IconProps> = ({ name, size, color }) => {
    const theme = useTheme();
    const CurrentIcon = icons[name].default;

    const width = typeof size === 'object' ? size.width : size;
    const height = typeof size === 'object' ? size.height : size;

    return (
        <CurrentIcon
            width={width}
            height={height}
            color={color || theme.icon.primary}
            fill={color || theme.icon.primary}
        />
    );
};
