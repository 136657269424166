import { useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface TrainingAxisCellAnimationsRenderPropValue {
    backgroundAnimatedStyles: {
        opacity: number;
    };
}

export interface TrainingAxisCellAnimationsProps extends AnimationsProps<TrainingAxisCellAnimationsRenderPropValue> {
    progress: number;
    selected?: boolean;
}

export const TrainingAxisCellAnimations = ({ progress, children, selected }: TrainingAxisCellAnimationsProps) => {
    const hoverableProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const backgroundAnimatedStyles = useAnimatedStyle(() => {
        return {
            opacity: selected ? 1 : hoverableProgress.value,
        };
    }, [selected]);

    return children({ backgroundAnimatedStyles });
};
