import NetInfo from '@react-native-community/netinfo';
import { NavigationContainerRef } from '@react-navigation/native';
import { differenceInDays } from 'date-fns';
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { useGetInProgressTrainingDay } from '~/api/training/useGetInProgressTrainingDay';
import { useGetRecentUserMeasurement } from '~/api/userMeasurment/useGetRecentUserMeasurement';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { RootParamsList } from '~/navigation/root';

export type GlobalToastType = 'measurement' | 'connection-error' | 'training-in-progress';

export type GlobalToastPosition = 'top' | 'bottom';

export interface GlobalToastsContextType {
    activeToastType?: GlobalToastType;
    toastPosition?: GlobalToastPosition;
    hide: (type: GlobalToastType) => void;
    show: (type: GlobalToastType) => void;
}

export const GlobalToastsContext = createContext<GlobalToastsContextType>({
    activeToastType: undefined,
    toastPosition: undefined,
    hide: () => {
        throw new Error('Not implemented');
    },
    show: () => {
        throw new Error('Not implemented');
    },
});

export interface GlobalToastsContextProviderProps {
    navigation: NavigationContainerRef<RootParamsList> | null;
    children: ReactNode;
}

export const GlobalToastsContextProvider: FC<GlobalToastsContextProviderProps> = ({ navigation, children }) => {
    const { data: recentUserMeasurement } = useGetRecentUserMeasurement();
    const { data: inProgressTrainingDay } = useGetInProgressTrainingDay();
    const [isOffline, setIsOffline] = useState(false);
    const theme = useTheme();
    const [hidden, setHidden] = useState<Record<GlobalToastType, boolean>>({
        'connection-error': false,
        measurement: false,
        'training-in-progress': false,
    });
    const [currentRouteName, setCurrentRouteName] = useState<string | undefined>(undefined);
    const { features } = useAppFeatures();

    const handleNavigationStateChange = useCallback(() => {
        setCurrentRouteName(navigation?.getCurrentRoute()?.name);
    }, [navigation]);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener((state) => {
            setIsOffline(!state.isConnected && !state.isInternetReachable);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (navigation) {
            navigation.addListener('state', handleNavigationStateChange);
        }

        return () => {
            navigation?.removeListener('state', handleNavigationStateChange);
        };
    }, [navigation, handleNavigationStateChange]);

    const activeToastType = useMemo((): GlobalToastType | undefined => {
        if (isOffline) {
            return 'connection-error';
        }

        //Display info when recently added measurement is older than 14 days
        const daysFromLastMeasurement = differenceInDays(new Date(), new Date(recentUserMeasurement?.date!));

        if (daysFromLastMeasurement > 14 && !hidden.measurement && features.measurements) {
            return 'measurement';
        }

        if (
            !!inProgressTrainingDay &&
            !hidden['training-in-progress'] &&
            currentRouteName !== 'Training' &&
            (!theme.isWebDesktop || currentRouteName !== 'TrainingPlan')
        ) {
            return 'training-in-progress';
        }
    }, [
        hidden,
        isOffline,
        recentUserMeasurement?.date,
        inProgressTrainingDay,
        features.measurements,
        currentRouteName,
        theme.isWebDesktop,
    ]);

    const toastPosition = useMemo((): GlobalToastPosition | undefined => {
        if (activeToastType === 'connection-error') {
            return theme.isWebDesktop ? 'top' : 'bottom';
        }

        if (activeToastType === 'measurement') {
            return 'bottom';
        }

        if (activeToastType === 'training-in-progress') {
            return 'top';
        }
    }, [activeToastType, theme.isWebDesktop]);

    const hide = useCallback((type: GlobalToastType) => {
        setHidden((prev) => ({ ...prev, [type]: true }));
    }, []);

    const show = useCallback((type: GlobalToastType) => {
        setHidden((prev) => ({ ...prev, [type]: false }));
    }, []);

    const value = useMemo(
        () => ({
            activeToastType,
            toastPosition,
            hide,
            show,
        }),
        [activeToastType, toastPosition, hide, show],
    );

    return <GlobalToastsContext.Provider value={value}>{children}</GlobalToastsContext.Provider>;
};

export const useGlobalToasts = () => useContext(GlobalToastsContext);
