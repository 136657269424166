import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { usePressableState } from '~/hooks/usePressableState';

import { TimerButtonAnimations } from './TimerButton.animated';
import * as S from './TimerButton.styled';

export interface TimerButtonProps extends Omit<PressableProps, 'onPress'> {
    started?: boolean;
    onChange?: (checked: boolean) => void;
}

const AnimatedPlayIcon = Animated.createAnimatedComponent(S.PlayIcon);
const AnimatedStopIcon = Animated.createAnimatedComponent(S.StopIcon);

export const TimerButton: React.FC<TimerButtonProps> = ({ started = false, ...props }) => {
    const theme = useTheme();
    const { pressableProps } = usePressableState();

    const handlePress = () => {
        props.onChange?.(!started);
    };

    return (
        <S.Container {...props} {...pressableProps} onPress={handlePress}>
            <TimerButtonAnimations started={started}>
                {({ playIconAnimatedStyles, stopIconAnimatedStyles }) => (
                    <>
                        <S.Background
                            pointerEvents="none"
                            started={started}
                            hovered={pressableProps.hovered}
                            pressed={pressableProps.pressed}
                        />
                        <AnimatedPlayIcon style={playIconAnimatedStyles}>
                            <Icon name="play" size={12} color={theme.icon.primary} />
                        </AnimatedPlayIcon>
                        <AnimatedStopIcon style={stopIconAnimatedStyles}>
                            <Icon name="stop" size={12} color={theme.icon.primary} />
                        </AnimatedStopIcon>
                    </>
                )}
            </TimerButtonAnimations>
        </S.Container>
    );
};
