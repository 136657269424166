import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { MEAL_IMAGE_ASPECT_RATIO } from './MealScreen.constants';

export const Header = styled(View)`
    background-color: ${({ theme }) => theme.fill.level1};
`;

export const MealImageWrapper = styled(View)`
    width: 100%;
    aspect-ratio: ${MEAL_IMAGE_ASPECT_RATIO};
`;

export const HeadingContainer = styled(View)`
    padding: 8px 16px;
`;

export const Spacing = styled(View)`
    width: 100%;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
    transform-origin: bottom right;
`;

export const PortionContainer = styled(View)`
    padding: 8px 16px;
`;

export const TabsContainer = styled(View)`
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
`;

export const TabsWrapper = styled(View)`
    padding: 0 16px;
`;

export const MacrosContainer = styled(View)`
    width: 100%;
    padding: 20px 16px;
    display: flex;
    flex-direction: row;
`;

export const MacroTileWrapper = styled(View)<{ last?: boolean }>`
    flex: 1;
    margin-right: 8px;

    ${({ last }) =>
        last &&
        css`
            margin-right: 0;
        `}
`;

export const IngredientsContainer = styled(View)`
    width: 100%;
    padding: 0 16px;
`;

export const IngredientWrapper = styled(View)`
    width: 100%;
    padding: 8px 0;
`;

export const StepsContainer = styled(View)`
    width: 100%;
    padding: 16px 16px 24px;
`;

export const StepWrapper = styled(View)`
    width: 100%;
    padding: 12px 0;
`;

export const CenterContainer = styled(View)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ErrorMessage = styled(Text)`
    ${mixins.typography.p.regular16};
    color: ${({ theme }) => theme.text.tertiary};
    text-align: center;
`;
