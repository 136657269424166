import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { MobileMenuTile } from '~/components/mobileMenuTile';

import { BottomSheetHandle } from '../bottomSheetHandle';
import {
    BottomSheetBackdrop,
    BottomSheetBackdropProps,
    BottomSheetModal,
    BottomSheetView,
    useBottomSheetDynamicSnapPoints,
} from '../bottomSheetModal';
import * as S from './MobileMenuSheet.styled';

export interface MobileMenuSheetProps {
    isOpen: boolean;
    children: ReactNode;
    onClose: () => void;
}

export const MobileMenuSheet: FC<MobileMenuSheetProps> = ({ isOpen, children, onClose }) => {
    const theme = useTheme();
    const bottomSheetRef = useRef<BottomSheetModal>(null);
    const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], []);
    const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
        useBottomSheetDynamicSnapPoints(initialSnapPoints);

    useEffect(() => {
        if (isOpen) {
            bottomSheetRef.current?.present();
        } else {
            bottomSheetRef.current?.close();
        }
    }, [isOpen]);

    const renderBackdrop = useCallback(
        (props: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop
                {...props}
                pressBehavior="close"
                appearsOnIndex={0}
                disappearsOnIndex={-1}
                opacity={0.8}
            />
        ),
        [],
    );

    const renderHandle = useCallback(
        () => (
            <S.HandleContainer>
                <BottomSheetHandle />
            </S.HandleContainer>
        ),
        [],
    );

    return (
        <S.BottomSheet
            ref={bottomSheetRef}
            index={0}
            snapPoints={animatedSnapPoints}
            backdropComponent={renderBackdrop}
            handleComponent={renderHandle}
            handleHeight={animatedHandleHeight}
            contentHeight={animatedContentHeight}
            backgroundStyle={{ backgroundColor: theme.fill.level2 }}
            onDismiss={onClose}
            enablePanDownToClose
        >
            <BottomSheetView onLayout={handleContentLayout}>
                <S.Container>
                    <S.MenuList>{children}</S.MenuList>
                    <MobileMenuTile textVariant="secondary" textAlign="center" last onPress={() => onClose()}>
                        Anuluj
                    </MobileMenuTile>
                </S.Container>
            </BottomSheetView>
        </S.BottomSheet>
    );
};
