import { ScrollView, View } from 'react-native';
import styled, { css } from 'styled-components/native';

import { ContainerProps, MainLayoutStyledProps } from './MainLayout.types';

export const Container = styled(View)<ContainerProps>`
    flex: 1;
    border-radius: 20px;
    padding-bottom: ${({ inset, bottomContent }) => (bottomContent ? inset?.bottom : 0)}px;
    overflow: hidden;
    background-color: ${({ noBackground, theme }) => (noBackground ? theme.fill.background : theme.fill.level1)};
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
`;

export const ContentWrapper = styled(View)`
    flex: 1;
`;

const containerStyle = css<MainLayoutStyledProps>`
    padding: 16px;
    flex: 1;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            padding-left: 0;
            padding-right: 0;
        `}

    ${({ noBackground }) =>
        noBackground &&
        css`
            padding-top: 0;
        `}

    ${({ verticalSpacing }) =>
        !verticalSpacing &&
        css`
            padding-top: 0;
            padding-bottom: 0;
        `}
`;

export const StaticContainer = styled(View)<MainLayoutStyledProps>`
    ${containerStyle};
`;

export const ScrollContainer = styled(ScrollView).attrs({
    showsVerticalScrollIndicator: false,
})<MainLayoutStyledProps>`
    ${containerStyle};
`;
