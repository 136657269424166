import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '~/components/button';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 400px;
    height: 350px;
`;

export const Header = styled(View)`
    padding: 10px 16px;
    flex-direction: row;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
    justify-content: space-between;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold20};
    color: ${({ theme }) => theme.text.primary};
    text-transform: capitalize;
`;

export const ExerciseWrapper = styled(View)`
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
`;

export const ExerciseName = styled(Text)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.primary};
    margin-left: 12px;
`;

export const LeftContentWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
`;

export const flashListContainer = {
    paddingBottom: 50,
};

export const MainContentWrapper = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
`;

export const MainContentIconWrapper = styled(View)`
    margin-top: 8px;
    border-color: ${({ theme }) => theme.border[40]};
    background-color: ${({ theme }) => theme.fill.level5};
    padding: 4px;
    border-radius: 100px;
    border-width: 2px;
    height: 48px;
    width: 48px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const MainContentText = styled(Text)`
    ${mixins.typography.p.regular16}
    color: ${({ theme }) => theme.text.tertiary};
    margin-top: 24px;
    text-align: center;
    max-width: 230px;
`;

export const HeaderButtonsWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    gap: 24px;
`;

export const AddButton = styled(Button)`
    margin: 24px auto 0;
`;
