import { StyleSheet, View } from 'react-native';
import styled from 'styled-components/native';
import { defaultTheme } from '~/theme';

export const toolbar = StyleSheet.create({
    mainContainer: {
        backgroundColor: defaultTheme.fill.background,
        height: 44,
        borderWidth: 1.5,
        borderColor: defaultTheme.border['12'],
        marginHorizontal: 16,
        borderRadius: 2,
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        overflow: 'scroll',
        borderTopWidth: 1.5,
        borderTopColor: defaultTheme.border['12'],
        marginTop: 16,
        position: 'relative',
    },
    wrapper: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1,
    },
    textInput: {
        color: defaultTheme.text.primary,
        fontSize: 14,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 12,
        flex: 1,
    },
    sendConatiner: {
        borderWidth: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 28,
        borderRadius: 4,
        backgroundColor: defaultTheme.interactive.secondary,
        marginHorizontal: 8,
    },
    accessory: {
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 12,
    },
});

export const Wrapper = styled(View)`
    background-color: ${({ theme }) => theme.fill.level1};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 100%;
    flex: 1;
    position: absolute;
    bottom: 0;
    padding-bottom: 16px;
`;
