import React from 'react';

import * as S from './ExerciseItemHeader.styled';

export interface ExerciseItemHeaderProps {
    stop: string | number;
    rir: string | number;
    pace: string;
}

export const ExerciseItemHeader: React.FC<ExerciseItemHeaderProps> = ({ stop, rir, pace }) => {
    return (
        <S.Container>
            <S.Item>
                <S.ItemHeader>Przerwa</S.ItemHeader>
                <S.ItemValue>{stop}s</S.ItemValue>
            </S.Item>
            <S.ItemSeparator />
            <S.Item>
                <S.ItemHeader>RIR</S.ItemHeader>
                <S.ItemValue>{rir}</S.ItemValue>
            </S.Item>
            <S.ItemSeparator />
            <S.Item>
                <S.ItemHeader>Tempo</S.ItemHeader>
                <S.ItemValue>{pace}</S.ItemValue>
            </S.Item>
        </S.Container>
    );
};
