import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useApplication } from '~/hooks/useApplication';

export interface SEOProps {
    title: string;
    description?: string;
    type?: string;
    image?: string;
}

export const SEO: FC<SEOProps> = ({ title, description = 'by Paweł Głuchowski', type, image }) => {
    const application = useApplication();

    return (
        <Helmet>
            <title>
                {application.name} | {title}
            </title>
            <meta name="description" content={description} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {type && <meta name="twitter:card" content={type} />}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {image && <meta property="og:image" content={image} />}
        </Helmet>
    );
};
