import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    flex: 1;
    background-color: ${({ theme }) => theme.fill.background};
`;

export const Header = styled(View)`
    width: 100%;
    padding: 20px 8px 20px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;

export const Content = styled(View)`
    flex: 1;
    position: relative;
`;

export const CloseButton = styled(Pressable)`
    padding: 12px;
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
