import { SectionType } from './ShoppingListRecipes.types';

export const data: SectionType[] = [
    {
        title: 'Jaglanka na mleku roślinnym z gruszką i jabłkiem',
        imgUrl: undefined,
        portions: 2,
        data: [
            {
                id: '1',
                productName: 'Ogórek',
                details: '500g',
                checked: true,
            },
            {
                id: '2',
                productName: 'Ogórek',
                details: '500g',
                checked: false,
            },
            {
                id: '3',
                productName: 'Ogórek',
                details: '500g',
                checked: true,
            },
            {
                id: '4',
                productName: 'Ogórek',
                details: '500g',
                checked: false,
            },
            {
                id: '5',
                productName: 'Ogórek',
                details: '500g',
                checked: true,
            },
        ],
    },
];
