import { Icon } from '~/components/icon';

import { containerColors } from './ComparisonChip.constants';
import * as S from './ComparisonChip.styled';

type ComparisonChipProps = {
    value: number;
};

export const ComparisonChip = ({ value }: ComparisonChipProps) => {
    const isZeroValue = value === 0;

    return (
        <S.Container>
            <S.Background colors={containerColors} />
            {!isZeroValue && <Icon color="white" size={12} name={value > 0 ? 'arrow-up' : 'arrow-down'} />}
            <S.ValueText isZeroValue={isZeroValue}>{Math.abs(value).toFixed(1)}</S.ValueText>
        </S.Container>
    );
};
