import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

export const shadowStyles = StyleSheet.create({
    shadow: {
        shadowOpacity: 0.8,
        shadowRadius: 32,
        shadowOffset: {
            height: 12,
            width: 0,
        },
        shadowColor: 'black',
    },
});
export const Container = styled(View)`
    padding: 32px;
    position: relative;
    background-color: ${({ theme }) => theme.fill.level1};
    border-radius: 12px;
    min-width: 360px;
    border: 1px solid ${({ theme }) => theme.border[12]};
    position: absolute;
    bottom: 32px;
    right: 32px;
`;
