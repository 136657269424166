import { interpolate, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface PressableIconAnimationsRenderPropValue {
    pressedIconAnimatedStyles: {
        opacity: number;
    };
    hoveredIconAnimatedStyles: {
        opacity: number;
    };
}

export interface PressableIconAnimationsProps
    extends PressableAnimationsProps<PressableIconAnimationsRenderPropValue> {}

export const PressableIconAnimations = ({ progress, children }: PressableIconAnimationsProps) => {
    const hoverableProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const pressedIconAnimatedStyles = useAnimatedStyle(() => ({
        opacity: interpolate(hoverableProgress.value, [0, 1, 2], [0, 0, 1]),
    }));

    const hoveredIconAnimatedStyles = useAnimatedStyle(() => ({
        opacity: interpolate(hoverableProgress.value, [0, 1, 2], [0, 1, 0]),
    }));

    return children({ pressedIconAnimatedStyles, hoveredIconAnimatedStyles });
};
