import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '~/components/button';
import { FormControl } from '~/components/formControl';
import { Input } from '~/components/input';
import { SubmittableForm } from '~/components/submittableForm/SubmittableForm';

import { reportFormSchema } from './RaportForm.schema';
import * as S from './RaportForm.styled';
import { FormValues } from './RaportForm.types';

interface ReportFormProps {
    onSubmit: SubmitHandler<FormValues>;
}

export const ReportForm: React.FC<ReportFormProps> = ({ onSubmit }) => {
    const { control, handleSubmit, formState, reset } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: yupResolver(reportFormSchema),
        defaultValues: {},
    });

    useEffect(() => {
        if (formState.submitCount) {
            reset({ report: '' });
        }
    }, [formState.submitCount, reset]);

    return (
        <S.Container>
            <SubmittableForm
                disabled={!formState.isValid}
                onSubmit={(e) => {
                    handleSubmit(onSubmit)(e);
                }}
            >
                <S.InputWrapper>
                    <Controller
                        name="report"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <Input
                                    label="Opisz problem lub sugestię związaną z aplikacją"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    onChangeText={onChange}
                                    multiline
                                    numberOfLines={5}
                                />
                            </FormControl>
                        )}
                    />
                </S.InputWrapper>
                <Button
                    size="m"
                    fullWidth
                    loading={formState.isSubmitting}
                    disabled={!formState.isValid}
                    onPress={handleSubmit(onSubmit)}
                >
                    Wyślij
                </Button>
            </SubmittableForm>
        </S.Container>
    );
};
