import { Exercise } from '@pg/backend';
import React, { useCallback, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { useTheme } from 'styled-components/native';
import { ExerciseGroupDataItem, ExerciseGroupsSheet } from '~/components/bottomSheet/exerciseGroupsSheet';
import { DropdownWithFloatingLabel, DropdownWithFloatingLabelRef } from '~/components/dropdownWithFloatingLabel';
import { Loader } from '~/components/loader';
import { useExercise } from '~/enhancers/exercise.context';
import { NoExercises } from '~/screens/exercisesAtlas/components/noExercises';
import { useExerciseGroupsOptions } from '~/screens/exercisesAtlas/hooks/useExerciseGroupsOptions';

import { ExerciseItem } from '../exerciseItem';
import * as S from './ExercisesAtlasContent.styled';

export const ExercisesAtlasContent = () => {
    const theme = useTheme();
    const dropdownWithFloatingLabelRef = useRef<DropdownWithFloatingLabelRef>(null);
    const {
        exercises,
        selectedExerciseGroup,
        exerciseGroups,
        groupNamesById,
        isLoadingExercises,
        setSelectedExerciseGroup,
        setQuery,
        openVideoModal,
    } = useExercise();
    const [isExerciseGroupsSheetOpen, setIsExerciseGroupsSheetOpen] = useState(false);
    const { exerciseGroupsOptions, selectedGroupLabel } = useExerciseGroupsOptions(
        exerciseGroups,
        selectedExerciseGroup,
    );

    const handleItemPress = (item: ExerciseGroupDataItem) => {
        setQuery(undefined);
        setSelectedExerciseGroup(item.id === 'all' ? undefined : item.id);
    };
    const handleDismiss = () => {
        dropdownWithFloatingLabelRef.current?.close();
        setIsExerciseGroupsSheetOpen(false);
    };

    const handleTagPress = useCallback(
        (tagValue: string) => {
            setSelectedExerciseGroup(tagValue);
        },
        [setSelectedExerciseGroup],
    );

    const handleExerciseItemPress = useCallback(
        (exercise: Exercise) => {
            if (exercise.videoPath) {
                openVideoModal(exercise.videoPath);
            }
        },
        [openVideoModal],
    );

    return (
        <S.FullHeightContainer>
            {!theme.isWebDesktop && (
                <S.CategoryContainer>
                    <DropdownWithFloatingLabel
                        ref={dropdownWithFloatingLabelRef}
                        label="Kategoria"
                        value={selectedGroupLabel}
                        onFocus={() => setIsExerciseGroupsSheetOpen(true)}
                    />
                </S.CategoryContainer>
            )}

            <S.ListContainer>
                <FlatList
                    data={!isLoadingExercises ? exercises : []}
                    numColumns={theme.isWebDesktop ? 3 : 1}
                    renderItem={({ item }) => (
                        <ExerciseItem
                            title={item.name || ''}
                            videoPath={item.videoPath ?? undefined}
                            tags={[
                                {
                                    value: item.exercisegroupID,
                                    label: groupNamesById[item.exercisegroupID],
                                },
                            ]}
                            onPress={() => handleExerciseItemPress(item)}
                            onTagPress={handleTagPress}
                        />
                    )}
                    keyExtractor={(item) => item.id}
                    contentContainerStyle={{ flexGrow: 1 }}
                    ListEmptyComponent={
                        isLoadingExercises ? (
                            <S.LoaderContainer>
                                <Loader size="xl" color={theme.icon.interactive} />
                            </S.LoaderContainer>
                        ) : (
                            <NoExercises />
                        )
                    }
                />
            </S.ListContainer>

            {!theme.isWebDesktop && (
                <ExerciseGroupsSheet
                    data={exerciseGroupsOptions}
                    isOpen={isExerciseGroupsSheetOpen}
                    onItemPress={handleItemPress}
                    onDismiss={handleDismiss}
                />
            )}
        </S.FullHeightContainer>
    );
};
