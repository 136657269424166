import { GestureResponderEvent, PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { usePressableState } from '~/hooks/usePressableState';

import { PressableIcon } from '../pressableIcon';
import { ModalCloseButtonAnimation } from './ModalCloseButton.animated';
import * as S from './ModalCloseButton.styled';

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

type ModalCloseButtonProps = {
    onPress?: (event: GestureResponderEvent) => void;
} & Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'disabled'>;

export const ModalCloseButton = ({ onPress, style, ...props }: ModalCloseButtonProps) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    return (
        <ModalCloseButtonAnimation progress={pressableAnimationProgress}>
            {(animatedStyles) => (
                <AnimatedContainer {...pressableProps} style={[style, animatedStyles]} onPress={onPress} {...props}>
                    <PressableIcon
                        name="close"
                        size={20}
                        color={theme.icon.secondary}
                        pressedColor={theme.icon.interactive}
                        hoverColor={theme.icon.primary}
                        pressableAnimationProgress={pressableAnimationProgress}
                    />
                </AnimatedContainer>
            )}
        </ModalCloseButtonAnimation>
    );
};
