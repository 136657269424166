import { CardioExerciseData } from '@pg/backend';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const resetCardioExercisesData: MutationFunction<void, { ids: string[] }> = async ({ ids }) => {
    const promises = ids.map(async (id) => {
        const original = await DataStore.query(CardioExerciseData, id);

        if (!original) {
            return;
        }

        await DataStore.save(
            CardioExerciseData.copyOf(original, (updated) => {
                updated.time = null;
            }),
        );
    });
    await Promise.all(promises);
};

export const useResetCardioExercisesData = () =>
    useMutation({
        mutationKey: ['resetCardioExercisesData'],
        mutationFn: resetCardioExercisesData,
    });
