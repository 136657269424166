import { View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    padding: 0 16px;
`;

export const SearchContainer = styled(View)`
    margin-bottom: 20px;
`;

export const ListContainer = styled(View)``;
