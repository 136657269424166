import React from 'react';
import Animated from 'react-native-reanimated';
import { TableAnimationsRenderPropValue } from '~/components/table/Table.animated';
import { CellContent, CellContentProps } from '~/components/table/components/cellContent';

import * as S from './Cell.styled';
import { CellStyledProps } from './Cell.types';

export interface CellProps extends CellContentProps, CellStyledProps {
    animatedStyles?: TableAnimationsRenderPropValue['styles']['stickyCellAnimatedStyles'] | null;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const Cell = ({ animatedStyles, content, ...props }: CellProps) => {
    const cellContent = (
        <CellContent hidden={props.hidden} content={content} alignment={props.alignment} head={props.head} />
    );

    if (animatedStyles) {
        return (
            <AnimatedContainer sticky style={animatedStyles} {...props}>
                {cellContent}
                {props.backgroundColor && (
                    <S.StickyCellShadow
                        colors={[props.backgroundColor, `${props.backgroundColor}00`]}
                        useAngle
                        angle={270}
                    />
                )}
            </AnimatedContainer>
        );
    }

    return <S.Container {...props}>{cellContent}</S.Container>;
};
