import React from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import * as S from './ExerciseItemThumbnail.styled';

export interface ExerciseItemPlaceholderProps {
    url?: string;
}

export const ExerciseItemThumbnail = ({ url }: ExerciseItemPlaceholderProps) => {
    const theme = useTheme();

    if (!url) {
        return (
            <S.Placeholder>
                <Icon name="training-atlas-outline" size={20} color={theme.icon.disabled} />
            </S.Placeholder>
        );
    }

    return <video src={url} width="100%" height="100%" preload="metadata" muted style={{ objectFit: 'cover' }} />;
};
