import { Storage } from 'aws-amplify';
import { pathToBlob } from '~/utils/file';
import { pluralize } from '~/utils/string';

import { FormValues } from './MeasurementScreen.types';

export const shouldOpenConfirmationSheet = ({ frontImage, sideImage, backImage }: FormValues) =>
    !frontImage || !sideImage || !backImage;

export const getConfirmationSheetSubtitle = ({ frontImage, sideImage, backImage }: Partial<FormValues>) => {
    const images = [frontImage, sideImage, backImage];
    const positions = ['przedniej', 'bocznej', 'tylnej'];
    const numPhotos = images.filter(Boolean).length;

    if (!numPhotos) {
        return 'Nie dodałeś żadnego zdjęcia swojej sylwetki.';
    }

    const positionsText = positions.filter((_, index) => !images[index]).join(' i ');

    return `Nie dodałeś ${pluralize(numPhotos, 'zdjęć', 'zdjęcia')} swojej sylwetki w pozycji ${positionsText}.`;
};

export async function uploadImage(image: { uri?: string; name: String; mime?: string }) {
    if (!image.uri) {
        return null;
    }

    const blob = await pathToBlob(image.uri);
    const contentType = image.mime || blob.type;
    const fileType = contentType.split('/')[1];
    const { key } = await Storage.put(`measurements/${image.name}.${fileType}`, blob, {
        level: 'private',
        contentType,
    });

    return key;
}
