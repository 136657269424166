import { ReactElement } from 'react';
import { interpolate, interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';

export interface PeriodSelectionButtonAnimationsRenderPropValue {
    containerAnimationStyles: {
        borderColor: string;
    };
    iconAnimationStyles: {
        transform: { rotate: string }[];
    };
}

export interface PeriodSelectionButtonAnimationsProps {
    progress: number;
    isOpen: boolean;
    children: (value: PeriodSelectionButtonAnimationsRenderPropValue) => ReactElement;
}

export const PeriodSelectionButtonAnimation = ({
    progress,
    isOpen,
    children,
}: PeriodSelectionButtonAnimationsProps) => {
    const theme = useTheme();

    const pressProgress = useDerivedValue(() => withSpring(progress, springConfig.default));
    const openProgress = useDerivedValue(() => withSpring(Number(isOpen), springConfig.default));

    const borderColorMap: Record<string, string> = {
        default: theme.border['12'],
        hovered: theme.border['16'],
        pressed: theme.border['20'],
    };

    const backgroundColorMap: Record<string, string> = {
        default: 'transparent',
        hovered: theme.palette.opacity['04'].rgba,
        pressed: theme.palette.opacity['08'].rgba,
    };

    const containerAnimationStyles = useAnimatedStyle(() => ({
        borderColor: interpolateColor(
            pressProgress.value,
            [0, 1, 2],
            [borderColorMap.default, borderColorMap.hovered, borderColorMap.pressed],
        ),
        backgroundColor: interpolateColor(
            pressProgress.value,
            [0, 1, 2],
            [backgroundColorMap.default, backgroundColorMap.hovered, backgroundColorMap.pressed],
        ),
    }));

    const iconAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [{ rotate: `${interpolate(openProgress.value, [0, 1], [0, 180])}deg` }],
        };
    });

    return children({ containerAnimationStyles, iconAnimationStyles });
};
