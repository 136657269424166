import React, { FC } from 'react';
import { IndexDisc } from '~/components/indexDisc';

import * as S from './Step.styled';

interface StepProps {
    index: number;
    content: string;
}

export const Step: FC<StepProps> = ({ index, content }) => {
    return (
        <S.Container>
            <IndexDisc index={index} />
            <S.Content>{content}</S.Content>
        </S.Container>
    );
};
