import { CardioExerciseData, TrainingCardioExercise } from '@pg/backend';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const createOrUpdateFinishedCardioExerciseData: MutationFunction<
    CardioExerciseData,
    Pick<CardioExerciseData, 'time'> & { id?: string; trainingCardioExerciseId?: string }
> = async ({ id, time, trainingCardioExerciseId }) => {
    if (id) {
        const original = await DataStore.query(CardioExerciseData, id);

        if (!original) throw new Error(`CardioExerciseData with id ${id} not found`);

        return await DataStore.save(
            CardioExerciseData.copyOf(original, (updated) => {
                updated.time = time;
            }),
        );
    }

    const exerciseData = await DataStore.save(
        new CardioExerciseData({
            time,
        }),
    );

    const originalCardioExercise = await DataStore.query(TrainingCardioExercise, trainingCardioExerciseId!);

    if (!originalCardioExercise) throw new Error(`CardioExercise with id ${trainingCardioExerciseId} not found`);

    await DataStore.save(
        TrainingCardioExercise.copyOf(originalCardioExercise, (updated) => {
            updated.trainingCardioExerciseFinishedCardioExerciseDataId = exerciseData.id;
        }),
    );

    return exerciseData;
};

export const useCreateOrUpdateFinishedCardioExerciseData = () =>
    useMutation({
        mutationKey: ['createOrUpdateFinishedCardioExerciseData'],
        mutationFn: createOrUpdateFinishedCardioExerciseData,
    });
