import React from 'react';

import * as S from './IndexDisc.styled';

export interface IndexDiscProps {
    index: number;
}

export const IndexDisc: React.FC<IndexDiscProps> = ({ index }) => (
    <S.Container>
        <S.Index>{index}</S.Index>
    </S.Container>
);
