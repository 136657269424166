import DeviceInfo from 'react-native-device-info';

import pkg from '../../package.json';

const urls = {
    termsAndConditions: 'https://www.pawelgluchowski.pl/regulamin',
    privacyPolicy: 'https://www.pawelgluchowski.pl/polityka-prywatnosci',
};

const getApplicationVersion = () => {
    const deviceInfoVersion = DeviceInfo.getVersion();

    return deviceInfoVersion !== 'unknown' ? deviceInfoVersion : pkg.version;
};

export const useApplication = () => {
    return {
        name: 'Koncepcja Hipertroficzna',
        version: `v${getApplicationVersion()}`,
        termsAndConditionsUrl: urls.termsAndConditions,
        privacyPolicyUrl: urls.privacyPolicy,
    };
};
