import React from 'react';
import { Loader } from '~/components/loader';
import { Table } from '~/components/table';
import { useMeasurement } from '~/enhancers/measurement.context';
import { MainLayout } from '~/layouts';

import { useMeasurementsTable } from '../hooks/useMeasurementsTable';
import * as S from './MeasurementsTableScreen.styled';

export const MeasurementsTableScreenContent = () => {
    const { updateMeasurement, deleteMeasurement } = useMeasurement();

    const { table } = useMeasurementsTable({
        onEdit: (id) => {
            updateMeasurement(id);
        },
        onRemove: (id) => {
            deleteMeasurement(id);
        },
    });

    if (table.loading) {
        return (
            <S.Container>
                <Loader />
            </S.Container>
        );
    }

    return <Table {...table} />;
};

export const MeasurementsTableScreen = () => {
    return (
        <MainLayout>
            <MeasurementsTableScreenContent />
        </MainLayout>
    );
};
