import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { useCreateUserReport } from '~/api/user/useCreateUserReport';
import { BottomSheetModal } from '~/components/bottomSheet';
import { ConfirmSheet } from '~/components/bottomSheet/confirmSheet';
import { DesktopSettingsHeading } from '~/components/desktopSettingsHeading';
import { Icon } from '~/components/icon';
import { SEO } from '~/components/wrappers';
import { MainLayout } from '~/layouts';
import { MoreLayout } from '~/layouts/moreLayout';

import * as S from './ReportScreen.styled';
import { ReportNavigationProp } from './ReportScreen.types';
import { FormValues, ReportForm } from './components/reportForm';

export const ReportScreen = () => {
    const theme = useTheme();
    const navigation = useNavigation<ReportNavigationProp>();
    const createUserReportMutation = useCreateUserReport();
    const confirmSheetRef = useRef<BottomSheetModal>(null);
    const errorSheetRef = useRef<BottomSheetModal>(null);

    const handleSubmit: SubmitHandler<FormValues> = useCallback(
        async (formValues) => {
            try {
                await createUserReportMutation.mutateAsync({
                    message: formValues.report,
                });
                confirmSheetRef.current?.present();
            } catch (e) {
                errorSheetRef.current?.present();
            }
        },
        [createUserReportMutation],
    );

    const handleClose = useCallback(() => {
        confirmSheetRef.current?.close();
        navigation.navigate('More');
    }, [navigation]);

    return (
        <MainLayout>
            <SEO title="Zgłoś błąd lub sugestię" />
            <MoreLayout>
                <DesktopSettingsHeading title="Zgłoś błąd lub sugestię" />
                <S.Container>
                    <ReportForm onSubmit={handleSubmit} />
                </S.Container>

                <S.Background>
                    <Icon name="bug" size={!theme.isWebDesktop ? 188 : 300} color={theme.palette.opacity[16].rgba} />
                </S.Background>

                <ConfirmSheet
                    ref={confirmSheetRef}
                    variant="primary"
                    icon="check-fill"
                    iconColor={theme.icon.positive}
                    title="Zgłoszenie zostało wysłane"
                    secondaryButtonLabel="OK"
                    onClose={handleClose}
                />

                <ConfirmSheet
                    ref={errorSheetRef}
                    title="Nastąpił nieoczekiwany błąd"
                    subtitle="Spróbuj ponownie za chwilę."
                    secondaryButtonLabel="OK"
                    variant="error"
                    icon="warning-stop"
                    onClose={() => errorSheetRef.current?.close()}
                />
            </MoreLayout>
        </MainLayout>
    );
};
