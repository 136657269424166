import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const PlanInProgressLayout = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            background: ${theme.fill.level2};
        `}
`;
