import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { TileGradientButton } from '~/components/tileGradientButton';

export const Container = styled(View)`
    flex: 1;
    overflow: hidden;
`;

export const ContentWrapper = styled(View)`
    flex: 1;
`;

export const Content = styled(View)`
    padding: 0 16px;
    flex: 1;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            display: flex;
            flex-direction: row;
            padding: 0;
        `};
`;

export const LeftContainer = styled(View)`
    height: 100%;
    width: 300px;
    margin-left: 24px;
`;

export const HeaderContainer = styled(View)`
    width: 100%;
    padding: 8px 20px 12px;
    margin-bottom: 16px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
`;

export const RightContainer = styled(View)`
    height: 100%;
    width: 100%;
    margin-left: 16px;
`;

export const DashboardItemContainer = styled(View)`
    margin-bottom: 20px;
    width: 300px;
`;

export const TargetContainer = styled(View)`
    margin-bottom: 20px;
`;

export const ActionsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
`;

export const Button = styled(TileGradientButton)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;

export const ActivityContainer = styled(View)`
    width: 500px;
`;

export const ComingSoonContainer = styled(View)`
    flex: 1;
    position: relative;
    min-height: fit-content;
`;
