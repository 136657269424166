import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { StandardNavigation, StandardNavigationProps } from '~/components/topNavigation';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useHealthTests } from '~/enhancers/healthTests.context';
import { ShoppingListTopNavigation } from '~/navigation/home/components/topNavigation';
import { ExercisesAtlasStackNavigation } from '~/navigation/home/exercisesAtlas';
import { ChangePasswordScreen } from '~/screens/changePassword';
import { HealthTestsScreen } from '~/screens/healthTests';
import { MobileAppScreen } from '~/screens/mobileApp';
import { OrderHistoryScreen } from '~/screens/orderHistory';
import { ReportScreen } from '~/screens/report';
import { SettingsScreen } from '~/screens/settings';
import { ShoppingListScreen } from '~/screens/shoppingList';
import { SupplementsScreen } from '~/screens/supplements';

import { MoreStackParamsList } from './MoreStackNavigation.types';

const Stack = createNativeStackNavigator<MoreStackParamsList>();

const SupplementsHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Suplementy"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);

const HealthTestsHeader = (
    props: NativeStackHeaderProps & Pick<StandardNavigationProps, 'rightIcon' | 'onRightPress'>,
) => (
    <StandardNavigation
        title="Badania"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);
const SettingsHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Ustawienia aplikacji"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);
const OrdersHistoryHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Historia zamówień"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);
const AccountSettingsHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Ustawienia konta"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);
const ReportHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Zgłoś błąd lub sugestię"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);
const MobileAppHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Aplikacja mobilna"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'More' })}
        {...props}
    />
);

export const MoreStackNavigation = () => {
    const { features } = useAppFeatures();
    const { addHealthTests } = useHealthTests();

    return (
        <Stack.Navigator screenOptions={{ header: () => null }}>
            <Stack.Screen
                name="ShoppingList"
                component={ShoppingListScreen}
                options={{ header: ShoppingListTopNavigation, title: 'Lista zakupów' }}
            />
            <Stack.Screen
                name="Supplements"
                component={SupplementsScreen}
                options={{
                    header: SupplementsHeader,
                }}
            />
            <Stack.Screen name="ExercisesAtlasStack" component={ExercisesAtlasStackNavigation} />
            <Stack.Screen
                name="HealthTests"
                component={HealthTestsScreen}
                options={{
                    header: (props) =>
                        HealthTestsHeader({
                            ...props,
                            rightIcon: features.healthTests ? 'add' : undefined,
                            onRightPress: addHealthTests,
                        }),
                }}
            />
            <Stack.Screen
                name="Settings"
                component={SettingsScreen}
                options={{
                    header: SettingsHeader,
                }}
            />
            <Stack.Screen
                name="OrdersHistory"
                component={OrderHistoryScreen}
                options={{
                    header: OrdersHistoryHeader,
                }}
            />
            <Stack.Screen
                name="AccountSettings"
                component={ChangePasswordScreen}
                options={{
                    header: AccountSettingsHeader,
                }}
            />
            <Stack.Screen
                name="Report"
                component={ReportScreen}
                options={{
                    header: ReportHeader,
                }}
            />
            <Stack.Screen
                name="MobileApp"
                component={MobileAppScreen}
                options={{
                    header: MobileAppHeader,
                }}
            />
        </Stack.Navigator>
    );
};
