import { Text, View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    flex: 1;
`;

export const HeaderContainer = styled(View)`
    width: 100%;
    padding: 8px 20px;
    position: relative;
`;

export const Content = styled(View)<{ wide?: boolean }>`
    padding: 16px 20px 0;
    flex: 1;
    height: 100%;
    width: 100%;
    max-width: ${({ wide }) => (wide ? '100%' : '808px')};
`;

export const DetailsTilesWrapper = styled(View)`
    width: 100%;
    margin-bottom: 24px;
`;

export const RichContentContainer = styled(View)`
    flex: 1;
    padding-bottom: 16px;
    // TODO: remove this border
    border: 1px solid ${({ theme }) => theme.fill.level2};
`;

// TODO: remove this
export const TmpText = styled(Text)`
    color: white;
`;
