import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import {
    LayoutChangeEvent,
    LayoutRectangle,
    NativeSyntheticEvent,
    TextInput,
    TextInputFocusEventData,
    TouchableWithoutFeedback,
} from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { FloatingLabelAnimation, InputBorderAnimation } from '~/components/animated';
import { FloatingLabel, FloatingLabelMask } from '~/components/floatingLabel';
import { Icon } from '~/components/icon';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './Input.styled';
import { InputProps } from './Input.types';

const AnimatedLabel = Animated.createAnimatedComponent(FloatingLabel);
const AnimatedBorder = Animated.createAnimatedComponent(S.Border);

export const Input = React.forwardRef<TextInput | null, InputProps>(
    (
        {
            onFocus,
            onBlur,
            label,
            style,
            editable = true,
            value,
            leftIcon,
            rightIcon,
            suffix,
            multiline,
            onLeftIconPress,
            onRightIconPress,
            ...props
        },
        ref,
    ) => {
        const [focused, setFocused] = useState(false);
        const inputRef = useRef<TextInput | null>(null);
        const [layout, setLayout] = useState<LayoutRectangle>({
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        });
        const smallLabel = (value?.length ?? 0) > 0 || focused;
        const theme = useTheme();
        const iconPressableState = usePressableState();
        const disabled = !editable;

        useImperativeHandle<TextInput | null, TextInput | null>(ref, () => inputRef.current);

        const iconFill = disabled ? theme.icon.disabled : theme.icon.tertiary;
        const iconPressed = disabled ? theme.icon.disabled : theme.icon.secondary;
        const iconSize = 18;

        const handleInputLayout = (e: LayoutChangeEvent) => {
            if (
                (theme.isWeb && e.nativeEvent.target && props.keyboardType === 'numeric') ||
                props.keyboardType === 'number-pad'
            ) {
                (e.nativeEvent.target as unknown as HTMLInputElement)?.setAttribute?.('type', 'number');
            }
        };

        const setInputFocus = useCallback(() => {
            inputRef.current?.focus();
        }, []);

        const handleFocus = useCallback(
            (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                onFocus?.(e);
                setFocused(true);
            },
            [onFocus],
        );

        const handleBlur = useCallback(
            (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                onBlur?.(e);
                setFocused(false);
            },
            [onBlur],
        );

        const handleLayout = useCallback((e: LayoutChangeEvent) => {
            setLayout(e.nativeEvent.layout);
        }, []);

        return (
            <S.Container style={style} pointerEvents={disabled ? 'none' : 'auto'} multiline={multiline}>
                <TouchableWithoutFeedback onFocus={setInputFocus} onPress={setInputFocus}>
                    <S.Content multiline={multiline}>
                        {leftIcon && (
                            <S.IconWrapper
                                alignment="left"
                                pointerEvents={!onLeftIconPress ? 'none' : undefined}
                                {...iconPressableState.pressableProps}
                                onPress={onLeftIconPress}
                                focusable={false}
                            >
                                {!onLeftIconPress ? (
                                    <Icon name={leftIcon} size={iconSize} color={iconFill} />
                                ) : undefined}
                                {onLeftIconPress ? (
                                    <PressableIcon
                                        name={leftIcon}
                                        size={iconSize}
                                        color={iconFill}
                                        pressedColor={iconPressed}
                                        pressableAnimationProgress={iconPressableState.pressableAnimationProgress}
                                    />
                                ) : undefined}
                            </S.IconWrapper>
                        )}
                        {label && (
                            <FloatingLabelAnimation smallLabel={smallLabel} focused={focused} layout={layout}>
                                {(animatedStyle) => (
                                    <>
                                        <AnimatedLabel
                                            pointerEvents="none"
                                            style={animatedStyle}
                                            withIcon={!!leftIcon}
                                            error={props.error}
                                            disabled={disabled}
                                            onLayout={handleLayout}
                                        >
                                            {label}
                                        </AnimatedLabel>
                                        <FloatingLabelMask disabled={disabled} />
                                    </>
                                )}
                            </FloatingLabelAnimation>
                        )}
                        <S.Input
                            {...props}
                            onLayout={handleInputLayout}
                            value={value}
                            ref={inputRef}
                            focused={focused}
                            editable={editable}
                            placeholderTextColor={disabled ? theme.text.disabled : theme.text.tertiary}
                            withLeftIcon={!!leftIcon}
                            withRightIcon={!!rightIcon}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            multiline={multiline}
                            returnKeyType={
                                props.returnKeyType ??
                                (['number-pad', 'numeric'].includes(props.keyboardType || '') ? 'done' : undefined)
                            }
                            style={{ textAlignVertical: 'top' }}
                        />
                        {rightIcon && (
                            <S.IconWrapper
                                alignment="right"
                                pointerEvents={!onRightIconPress ? 'none' : undefined}
                                {...iconPressableState.pressableProps}
                                onPress={onRightIconPress}
                                focusable={false}
                            >
                                {!onRightIconPress ? (
                                    <Icon name={rightIcon} size={iconSize} color={iconFill} />
                                ) : undefined}
                                {onRightIconPress ? (
                                    <PressableIcon
                                        name={rightIcon}
                                        size={iconSize}
                                        color={iconFill}
                                        pressedColor={iconPressed}
                                        pressableAnimationProgress={iconPressableState.pressableAnimationProgress}
                                    />
                                ) : undefined}
                            </S.IconWrapper>
                        )}
                        {suffix && (
                            <S.SuffixWrapper disabled={disabled}>
                                <S.Suffix disabled={disabled}>{suffix}</S.Suffix>
                            </S.SuffixWrapper>
                        )}
                    </S.Content>
                </TouchableWithoutFeedback>
                <InputBorderAnimation focused={focused} disabled={disabled} error={props.error}>
                    {(animatedStyle) => <AnimatedBorder pointerEvents="none" style={animatedStyle} />}
                </InputBorderAnimation>
            </S.Container>
        );
    },
);

Input.displayName = 'Input';
