import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { FC } from 'react';
import { BottomTabWrapper } from '~/components/wrappers/bottomTabWrapper';

import { TabBarItem } from './TabBarItem/TabBarItem';

export const TabBar: FC<BottomTabBarProps> = ({ state, navigation }) => {
    return (
        <BottomTabWrapper>
            {state.routes.map((route) => (
                <TabBarItem state={state} navigation={navigation} route={route} key={route.key} />
            ))}
        </BottomTabWrapper>
    );
};
