import { View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    flex: 1;
    width: 100%;
    padding: 12px 20px;
    overflow-y: auto;
`;

export const SupplementsGrid = styled(View)`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, 280px);
    max-width: 1200px;
`;

export const LoaderContainer = styled(View)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
