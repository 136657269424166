import { format, parseISO } from 'date-fns';

const defaultDateFormat = 'dd.MM.yyyy';

export const formatDate = (date?: Date | string, dateFormat?: string) => {
    const formatToUse = dateFormat || defaultDateFormat;
    const inputDate = date || new Date();
    const parsedDate = typeof inputDate === 'string' ? new Date(parseISO(inputDate)) : inputDate;
    let formattedDate = format(parsedDate, formatToUse);

    if (['d LLL yyyy', 'dd LLL yyyy'].includes(formatToUse)) {
        formattedDate = formattedDate
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(' ');
    }

    return formattedDate;
};

export const getFormattedDuration = (durationInSeconds: number) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.round((durationInSeconds - hours * 3600) / 60);
    const values = [
        { value: hours, suffix: 'h' },
        { value: minutes, suffix: 'm' },
    ];

    return values.filter(({ value }) => value > 0);
};

export const getFormattedDurationString = (durationInSeconds: number) => {
    const formatted = getFormattedDuration(durationInSeconds);
    return formatted.map((i) => `${i.value}${i.suffix}`).join(' ');
};

export const getDateRangeWithPlaceholder = ({
    startDate,
    endDate,
    placeholder,
    dateFormat = 'd LLL yyyy',
}: {
    startDate?: Date | string | null;
    endDate?: Date | string | null;
    placeholder: string;
    dateFormat?: string;
}) =>
    startDate && endDate
        ? [formatDate(new Date(startDate), dateFormat), formatDate(new Date(endDate), dateFormat)].join(' - ')
        : placeholder;
