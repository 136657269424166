import { Pressable } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Button = styled(Pressable)<{ topBorder?: boolean }>`
    padding: 12px 16px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ topBorder }) =>
        topBorder &&
        css`
            border-top-width: 1px;
            border-top-color: ${({ theme }) => theme.border[12]};
        `}
`;
