import { Training } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const fetchTraining: QueryFunction<Training | null, ['getTraining', string | undefined]> = async ({ queryKey }) => {
    try {
        const [_, id] = queryKey;

        if (!id) {
            return null;
        }

        return (await DataStore.query(Training, id)) ?? null;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const useGetTraining = (id?: string) => useQuery(['getTraining', id], fetchTraining);
