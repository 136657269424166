import { BottomSheetMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppState } from 'react-native';
import { Easing, useSharedValue, withTiming } from 'react-native-reanimated';
import { useTimer } from '~/hooks/useTimer';

import {
    BottomSheetBackdrop,
    BottomSheetBackdropProps,
    BottomSheetBackgroundProps,
    BottomSheetHandleProps,
    BottomSheetView,
    useBottomSheetDynamicSnapPoints,
} from '../bottomSheetModal';
import * as S from './TrainingTimerSheet.styled';
import { TrainingTimerSheetProps } from './TrainingTimerSheet.types';
import { TrainingTimerSheetBackground } from './trainingTimerSheetBackground';
import { TrainingTimerSheetCollapsedContent } from './trainingTimerSheetCollapsedContent';
import { TrainingTimerSheetExpandedContent } from './trainingTimerSheetExpandedContent';
import { TrainingTimerSheetHandle } from './trainingTimerSheetHandle';

export const TrainingTimerSheet: FC<TrainingTimerSheetProps> = ({
    id,
    type,
    title,
    subtitle,
    series,
    initialDuration,
    isOpen,
    onFinish,
}) => {
    const renderedAt = useRef<number | null>(null);
    const bottomSheetRef = useRef<BottomSheetMethods>(null);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const sharedProgress = useSharedValue(0);
    const closeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const appState = useRef(AppState.currentState);

    useEffect(() => {
        renderedAt.current = Date.now();
    }, [id]);

    const { time, progress, duration, resetTimer, increaseDuration, decreaseDuration, stopTimer } = useTimer({
        initialDuration,
        isActive: isOpen,
        onFinish: (progress) => {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = setTimeout(() => {
                onFinish(progress, true);
                resetTimer();
            }, 1000);
        },
        onProgressChange: (changedProgress) => {
            sharedProgress.value = withTiming(changedProgress, { duration: 1000, easing: Easing.linear });
        },
    });
    const initialSnapPoints = useMemo(() => [110, 'CONTENT_HEIGHT'], []);
    const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
        useBottomSheetDynamicSnapPoints(initialSnapPoints);

    const handleClose = () => {
        onFinish(progress, false);
        stopTimer();
    };

    useEffect(() => {
        if (isOpen) {
            sharedProgress.value = 0;
            bottomSheetRef.current?.snapToIndex(1);
        } else {
            bottomSheetRef.current?.forceClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        resetTimer();
        sharedProgress.value = 0;

        // NOTE: open sheet when switching from rest to countdown
        if (type === 'countdown') {
            bottomSheetRef.current?.snapToIndex(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, initialDuration, type, resetTimer]);

    useEffect(() => {
        const subscription = AppState.addEventListener('change', (nextAppState) => {
            const appComeToForeground = appState.current.match(/inactive|background/) && nextAppState === 'active';

            if (renderedAt.current && appComeToForeground) {
                const timeSinceRender = Date.now() - renderedAt.current;

                if (timeSinceRender > duration * 1000) {
                    // NOTE: if app was in background for longer than duration, close the timer
                    bottomSheetRef.current?.forceClose();
                    resetTimer();
                    sharedProgress.value = 0;
                    renderedAt.current = null;
                    onFinish();
                    return;
                }
            }

            appState.current = nextAppState;
        });

        return () => {
            subscription.remove();
        };
    }, [duration, onFinish, resetTimer, sharedProgress]);

    useEffect(() => {
        setIsTransitioning(true);
    }, [type]);

    useEffect(() => {
        // NOTE: we have to hide background during type change to prevent weird animation
        const timeout = setTimeout(() => {
            if (isTransitioning) {
                setIsTransitioning(false);
            }
        });

        return () => clearTimeout(timeout);
    }, [isTransitioning]);

    useEffect(() => {
        return () => clearTimeout(closeTimeoutRef.current);
    }, []);

    const renderBackdrop = useCallback(
        (props: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop
                {...props}
                pressBehavior="collapse"
                appearsOnIndex={1}
                disappearsOnIndex={0}
                opacity={0.8}
            />
        ),
        [],
    );

    const renderBackground = useCallback(
        (props: BottomSheetBackgroundProps) => (
            <TrainingTimerSheetBackground
                progress={sharedProgress}
                type={type}
                duration={duration}
                isTransitioning={isTransitioning}
                {...props}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [type, duration, isTransitioning],
    );

    const renderHandle = useCallback((props: BottomSheetHandleProps) => <TrainingTimerSheetHandle {...props} />, []);

    const closeButtonLabel = useMemo(() => {
        if (type === 'countdown') {
            return 'Rozpocznij teraz';
        }
        return 'Zakończ teraz';
    }, [type]);

    const handleTimeIncrease = useCallback(() => {
        increaseDuration(10);
        clearInterval(closeTimeoutRef.current);
    }, [increaseDuration]);

    const handleTimeDecrease = useCallback(() => {
        decreaseDuration(10);
    }, [decreaseDuration]);

    return (
        <S.BottomSheet
            ref={bottomSheetRef}
            index={-1}
            snapPoints={animatedSnapPoints}
            backdropComponent={renderBackdrop}
            backgroundComponent={renderBackground}
            handleComponent={renderHandle}
            handleHeight={animatedHandleHeight}
            contentHeight={animatedContentHeight}
        >
            <BottomSheetView onLayout={handleContentLayout}>
                <S.Container>
                    <TrainingTimerSheetExpandedContent
                        type={type}
                        title={title}
                        subtitle={subtitle}
                        series={series}
                        time={time}
                        progress={progress}
                        duration={duration}
                        closeButtonLabel={closeButtonLabel}
                        onTimeIncrease={handleTimeIncrease}
                        onTimeDecrease={handleTimeDecrease}
                        onClose={handleClose}
                    />
                    <TrainingTimerSheetCollapsedContent
                        type={type}
                        time={time}
                        closeButtonLabel={closeButtonLabel}
                        onClose={handleClose}
                    />
                </S.Container>
            </BottomSheetView>
        </S.BottomSheet>
    );
};
