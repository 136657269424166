import { RefObject } from 'react';
import { useWindowDimensions } from 'react-native';
import Animated, { interpolate, useAnimatedRef, useAnimatedStyle, useScrollViewOffset } from 'react-native-reanimated';
import { AnimationsProps } from '~/types/animations';

import { MEAL_IMAGE_ASPECT_RATIO } from './MealScreen.constants';

export interface MealAnimationsRenderPropValue {
    animatedRef: RefObject<Animated.ScrollView>;
    styles: {
        titleAnimatedStyles: {
            transform: ({ scale: number; translateX?: number } | { scale?: number; translateX: number })[];
        };
        spacingAnimatedStyles: {
            height: number;
        };
    };
}

export interface MealAnimationsProps extends AnimationsProps<MealAnimationsRenderPropValue> {}

const scale = 0.8;

export const MealAnimations = ({ children }: MealAnimationsProps) => {
    const { width } = useWindowDimensions();
    const animatedRef = useAnimatedRef<Animated.ScrollView>();
    const scrollHandler = useScrollViewOffset(animatedRef);
    const imageHeight = width * (1 / MEAL_IMAGE_ASPECT_RATIO);
    const scrollAnimationStart = 0.2 * imageHeight;
    const scrollAnimationEnd = imageHeight;

    const titleAnimatedStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    scale: interpolate(scrollHandler.value, [scrollAnimationStart, scrollAnimationEnd], [1, scale], {
                        extrapolateLeft: 'clamp',
                        extrapolateRight: 'clamp',
                    }),
                },
                {
                    translateX: interpolate(
                        scrollHandler.value,
                        [scrollAnimationStart, scrollAnimationEnd],
                        [0, -((1 - scale) / 2) * width],
                        {
                            extrapolateLeft: 'clamp',
                            extrapolateRight: 'clamp',
                        },
                    ),
                },
            ],
        };
    });

    const spacingAnimatedStyles = useAnimatedStyle(() => {
        return {
            height: interpolate(scrollHandler.value, [scrollAnimationStart, scrollAnimationEnd], [8, 0], {
                extrapolateLeft: 'clamp',
                extrapolateRight: 'clamp',
            }),
        };
    });

    return children({
        animatedRef,
        styles: {
            titleAnimatedStyles,
            spacingAnimatedStyles,
        },
    });
};
