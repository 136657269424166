import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { TabsStyledProps } from '~/components/tabs/Tabs.types';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface TabAnimationsRenderPropValue {
    containerAnimatedStyles: {
        borderColor: string;
        backgroundColor: string;
    };
    labelAnimatedStyles: {
        color: string;
    };
}

export interface TabAnimationsProps extends PressableAnimationsProps<TabAnimationsRenderPropValue> {
    active?: boolean;
    appearance: TabsStyledProps['appearance'];
}

export const TabAnimations = ({ active, progress, appearance, children }: TabAnimationsProps) => {
    const theme = useTheme();

    const animationProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const containerStyles = {
        borderColor: {
            default: theme.border['20'],
            hovered: theme.border['40'],
            pressed: theme.border.interactive,
            active: theme.border.interactive,
        },
        backgroundColor: {
            default: 'transparent',
            hovered: 'transparent',
            pressed: appearance === 'primary' ? theme.fill.background : 'transparent',
            active: appearance === 'primary' ? theme.fill.background : 'transparent',
        },
    };

    const labelStyles = {
        color: {
            default: theme.text.secondary,
            hovered: theme.text.primary,
            pressed: theme.text.primary,
            active: theme.text.primary,
        },
    };

    const containerAnimatedStyles = useAnimatedStyle(() => {
        return {
            borderColor: active
                ? containerStyles.borderColor.active
                : interpolateColor(
                      animationProgress.value,
                      [0, 1, 2],
                      [
                          containerStyles.borderColor.default,
                          containerStyles.borderColor.hovered,
                          containerStyles.borderColor.pressed,
                      ],
                  ),
            backgroundColor: active
                ? containerStyles.backgroundColor.active
                : interpolateColor(
                      animationProgress.value,
                      [0, 1, 2],
                      [
                          containerStyles.backgroundColor.default,
                          containerStyles.backgroundColor.hovered,
                          containerStyles.backgroundColor.pressed,
                      ],
                  ),
        };
    }, [active, progress]);

    const labelAnimatedStyles = useAnimatedStyle(() => {
        return {
            color: active
                ? labelStyles.color.active
                : interpolateColor(
                      animationProgress.value,
                      [0, 1, 2],
                      [labelStyles.color.default, labelStyles.color.hovered, labelStyles.color.pressed],
                  ),
        };
    });

    return children({ containerAnimatedStyles, labelAnimatedStyles });
};
