import { FlashList, ListRenderItem } from '@shopify/flash-list';
import { endOfDay, format, isAfter } from 'date-fns';
import plLocale from 'date-fns/locale/pl';
import { Dispatch, FC, SetStateAction } from 'react';
import { Pressable } from 'react-native';
import { useTheme } from 'styled-components/native';
import { ActivityIcon } from '~/components/activityIcon';
import { Button } from '~/components/button';
import { Icon } from '~/components/icon';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './ActivityDayPopup.styled';
import { DataType } from './ActivityDayPopup.types';

const mockData = [
    { name: 'Cardio/bieganie' },
    { name: 'Crossfit' },
    { name: 'Jeździectwo' },
    { name: 'Joga' },
    { name: 'Inny' },
    { name: 'Pływanie' },
    { name: 'Rower/rolki' },
    { name: 'Skakanka' },
    { name: 'Spacer' },
    { name: 'Sporty zimowe' },
    { name: 'Sztuki walki' },
    { name: 'Trening lekkoatletyczny' },
    { name: 'Trening siłowy poza planem' },
    { name: 'Wspinaczka/spacer po górach' },
];

interface ActivityDayPopupProps {
    onClosePress: () => void;
    changePopupType: Dispatch<SetStateAction<'add' | 'history'>>;
    date: string;
}

export const ActivityDayPopup: FC<ActivityDayPopupProps> = ({ onClosePress, changePopupType, date }) => {
    const handleRemove = () => {
        console.log('Implement removing item');
    };

    const { pressableProps: addPressableProps, pressableAnimationProgress: addPressableAnimationProgress } =
        usePressableState();
    const { pressableProps: closePressableProps, pressableAnimationProgress: closePressableAnimationProgress } =
        usePressableState();

    const today = endOfDay(new Date());
    const isFutureDate = date && isAfter(new Date(date), today);
    const theme = useTheme();
    const isAddButtonVisible = mockData.length && !isFutureDate;

    const renderItem: ListRenderItem<DataType> = ({ item, index }) => {
        return (
            <S.ExerciseWrapper key={`${item.name} + ${index}`}>
                <S.LeftContentWrapper>
                    <ActivityIcon name="flexed-biceps-3d" size={16} variant="positive" />
                    <S.ExerciseName>{item.name}</S.ExerciseName>
                </S.LeftContentWrapper>
                <Button variant="secondary" size="xs" icon="trash-outline" onPress={handleRemove}>
                    Usuń
                </Button>
            </S.ExerciseWrapper>
        );
    };

    const renderMainContent = () => {
        if (isFutureDate) {
            return (
                <S.MainContentWrapper>
                    <S.MainContentIconWrapper>
                        <Icon name="fire-3d" size={34} />
                    </S.MainContentIconWrapper>
                    <S.MainContentText>Ten dzień jeszcze nie nadszedł, ale cieszy mnie Twój zapał</S.MainContentText>
                </S.MainContentWrapper>
            );
        }

        //To bedzie zmienione w mojej innej PRce zwiazanej z dodawaniem aktywnosci
        if (mockData.length) {
            return (
                <FlashList
                    renderItem={renderItem}
                    data={mockData}
                    estimatedItemSize={60}
                    contentContainerStyle={S.flashListContainer}
                />
            );
        }
        return (
            <S.MainContentWrapper>
                <S.MainContentIconWrapper>
                    <Icon name="sleeping-face-3d" size={34} />
                </S.MainContentIconWrapper>
                <S.MainContentText>Brak aktywności w tym dniu</S.MainContentText>
                <S.AddButton variant="secondary" size="s" icon="add" onPress={() => changePopupType('add')}>
                    Dodaj inną aktywność
                </S.AddButton>
            </S.MainContentWrapper>
        );
    };

    return (
        <S.Container>
            <S.Header>
                <S.Title>{format(new Date(date), 'EEEE, dd.MM.yyyy', { locale: plLocale })}</S.Title>
                <S.HeaderButtonsWrapper>
                    {isAddButtonVisible && (
                        <Pressable {...addPressableProps} onPress={() => changePopupType('add')}>
                            <PressableIcon
                                name="add"
                                size={20}
                                color={theme.icon.tertiary}
                                pressedColor={theme.icon.primary}
                                pressableAnimationProgress={addPressableAnimationProgress}
                            />
                        </Pressable>
                    )}
                    <Pressable {...closePressableProps} onPress={onClosePress}>
                        <PressableIcon
                            name="close"
                            size={20}
                            color={theme.icon.tertiary}
                            pressedColor={theme.icon.primary}
                            pressableAnimationProgress={closePressableAnimationProgress}
                        />
                    </Pressable>
                </S.HeaderButtonsWrapper>
            </S.Header>
            {renderMainContent()}
        </S.Container>
    );
};
