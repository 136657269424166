import { Pressable, Text as TextBase, View } from 'react-native';
import styled from 'styled-components/native';
import { MobileMenuTileProps } from '~/components/mobileMenuTile/types';
import { mixins } from '~/theme/mixins';
import { HoverableProps } from '~/types/hoverable';

interface ContainerStyleProps extends HoverableProps, Pick<MobileMenuTileProps, 'last'> {}
export const Container = styled(Pressable)<ContainerStyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-bottom-width: ${({ last }) => (last ? 0 : 1)}px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) => theme.palette.opacity[12].rgba};
`;

export const IconContainer = styled(View)`
    margin-right: 16px;
`;

export const TextContent = styled(View)<{ align: NonNullable<MobileMenuTileProps['textAlign']> }>`
    flex: 1;
    align-items: ${({ align }) => (align === 'center' ? 'center' : 'flex-start')};
`;

export const Text = styled(TextBase)<{ variant: NonNullable<MobileMenuTileProps['textVariant']> }>`
    ${mixins.typography.ui.medium16};
    color: ${({ variant, theme }) => theme.text[variant]};
`;

export const RightContainer = styled(View)`
    margin-left: 16px;
`;
