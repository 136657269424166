import { MainLayout } from '~/layouts';

import { ActivityCalendarContentMobile } from './components/activityCalendarContent';

export const ActivityCalendarScreen = () => {
    return (
        <MainLayout isBottomRounded={false}>
            <ActivityCalendarContentMobile />
        </MainLayout>
    );
};
