import { Dimensions, Image as ImageBase, Text, View } from 'react-native';
import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { BoxVariant, DesktopContainerType, HeadingVariant } from './RulesContent.types';

const DESKTOP_MODAL_WIDTH = 600;

const dimensions = Dimensions.get('window');
const imageHeight = (height: number) => Math.round((height * 9) / 16);

const headingVariantStylesMap: Record<HeadingVariant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    h1: css`
        ${mixins.typography.h.semiBold24}
    `,
    h2: css`
        ${mixins.typography.h.medium20}
    `,
    h3: css`
        ${mixins.typography.h.medium18}
    `,
};

const boxVariantStylesMap: Record<BoxVariant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    warning: css`
        background-color: ${({ theme }) => theme.fill.critical};
    `,
    info: css`
        background-color: ${({ theme }) => theme.fill.informative};
    `,
};

const boxTextVariantStylesMap: Record<BoxVariant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    warning: css`
        color: ${({ theme }) => theme.text.criticalSurface};
    `,
    info: css`
        color: ${({ theme }) => theme.text.interactiveSurface};
    `,
};

export const Container = styled(View)`
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 600px;
`;

export const WebContainer = styled(View)<DesktopContainerType>`
    flex: 1;
    opacity: ${({ isLoading }) => (!isLoading ? 1 : 0)};
`;

export const WebLoaderContainer = styled(View)`
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const Heading = styled(Text)<{ variant: HeadingVariant; isLast: boolean }>`
    ${({ variant }) => headingVariantStylesMap[variant]};
    margin-bottom: 16px;
    color: ${({ theme }) => theme.text.primary};

    ${({ isLast }) =>
        isLast &&
        css`
            margin-bottom: 0;
        `};
`;

export const Paragraph = styled(Text)<{ isLast: boolean }>`
    ${mixins.typography.p.regular16}
    margin-bottom: 16px;
    color: ${({ theme }) => theme.text.primary};

    ${({ isLast }) =>
        isLast &&
        css`
            margin-bottom: 0;
        `};
`;

export const Box = styled(View)<{ variant: BoxVariant; isLast: boolean }>`
    ${({ variant }) => boxVariantStylesMap[variant]};
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 16px;

    ${({ isLast }) =>
        isLast &&
        css`
            margin-bottom: 0;
        `};
`;

export const Image = styled(ImageBase)<{ isLast: boolean }>`
    border-radius: 8px;
    width: 100%;
    height: ${({ theme }) => (theme.isWebDesktop ? imageHeight(DESKTOP_MODAL_WIDTH) : imageHeight(dimensions.width))}px;
    margin-bottom: 16px;

    ${({ isLast }) =>
        isLast &&
        css`
            margin-bottom: 0;
        `};
`;

export const BoxHeader = styled(Text)<{ variant: BoxVariant }>`
    ${mixins.typography.p.semiBold16}
    ${({ variant }) => boxTextVariantStylesMap[variant]};
    margin-bottom: 16px;
`;

export const BoxText = styled(Text)<{ variant: BoxVariant }>`
    ${mixins.typography.p.regular16}
    ${({ variant }) => boxTextVariantStylesMap[variant]};
`;

export const BoxIconWrapper = styled(View)`
    margin-bottom: 16px;
`;
