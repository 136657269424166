import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { BottomSheetModal } from '~/components/bottomSheet';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import { Button } from '~/components/button';
import { SectionHeading } from '~/components/sectionHeading';
import { SEO } from '~/components/wrappers';
import { useAuth } from '~/enhancers/auth.context';
import { useDeleteAccountConfirmSheet } from '~/hooks/useDeleteAccountConfirmSheet';
import { MainLayout } from '~/layouts';
import { MoreLayout } from '~/layouts/moreLayout';
import { ChangePasswordForm, ChangePasswordFormValues } from '~/screens/changePassword/components/changePasswordForm';
import { getErrorMessage } from '~/utils/authErrorMessage';

import * as S from './ChangePasswordScreen.styled';
import { ChangePasswordNavigationProp } from './ChangePasswordScreen.types';
import { changePasswordErrorMessages } from './consts';

export const ChangePasswordScreen = () => {
    const auth = useAuth();
    const navigation = useNavigation<ChangePasswordNavigationProp>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const confirmSheetRef = useRef<BottomSheetModal>(null);
    const deleteAccountConfirmSheetRef = useRef<ConfirmSheetRef>(null);
    const theme = useTheme();
    const { confirmSheetProps } = useDeleteAccountConfirmSheet();

    const handleSubmit: SubmitHandler<ChangePasswordFormValues> = useCallback(
        async (values) => {
            setErrorMessage(undefined);
            try {
                await auth.changePassword(values.oldPassword, values.newPassword);
                confirmSheetRef.current?.present();
            } catch (e) {
                setErrorMessage(getErrorMessage(e, changePasswordErrorMessages));
            }
        },
        [auth],
    );

    const handleClose = useCallback(() => {
        confirmSheetRef.current?.close();

        if (!theme.isWebDesktop) {
            navigation.navigate('More');
        }
    }, [navigation, theme.isWebDesktop]);

    return (
        <MainLayout>
            <SEO title="Ustawienia konta" />
            <MoreLayout>
                <S.SectionHeadingWrapper>
                    <SectionHeading title="Zmień hasło" variant={theme.isWebDesktop ? 'header' : 'subheader'} />
                </S.SectionHeadingWrapper>
                <S.Container>
                    <ChangePasswordForm onSubmit={handleSubmit} />
                    {errorMessage && (
                        <S.ErrorContainer>
                            <S.ErrorText>{errorMessage}</S.ErrorText>
                        </S.ErrorContainer>
                    )}
                </S.Container>

                <S.SectionHeadingWrapper>
                    <SectionHeading title="Usuń konto" variant={theme.isWebDesktop ? 'header' : 'subheader'} />
                </S.SectionHeadingWrapper>
                <S.Container>
                    <Button
                        size="m"
                        variant="secondary"
                        fullWidth
                        onPress={() => deleteAccountConfirmSheetRef.current?.present()}
                    >
                        Usuń konto
                    </Button>
                </S.Container>

                <ConfirmSheet
                    ref={confirmSheetRef}
                    variant="primary"
                    image={require('~/assets/emojis/lock.png')}
                    title="Hasło zostało zmienione"
                    secondaryButtonLabel="OK"
                    onClose={handleClose}
                />

                <ConfirmSheet
                    ref={deleteAccountConfirmSheetRef}
                    onClose={() => deleteAccountConfirmSheetRef.current?.close()}
                    {...confirmSheetProps}
                />
            </MoreLayout>
        </MainLayout>
    );
};
