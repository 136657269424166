import { Pressable, View } from 'react-native';
import { FlattenInterpolation } from 'styled-components';
import styled, { DefaultTheme, ThemeProps, css } from 'styled-components/native';
import { HoverableProps } from '~/types/hoverable';

import { IconButtonSize, IconButtonStyledProps } from './IconButton.types';

interface IconButtonHoverableStyledProps extends IconButtonStyledProps, HoverableProps {}

const containerStylesBySize: Record<IconButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    s: css`
        width: 32px;
        height: 32px;
        padding: 12px;
    `,
    m: css`
        width: 44px;
        height: 44px;
        padding: 12px;
    `,
};

export const Container = styled(Pressable)<IconButtonHoverableStyledProps>`
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    ${({ size }) => containerStylesBySize[size]};
`;

export const IconWrapper = styled(View)``;
