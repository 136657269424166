import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface TextButtonAnimationsRenderPropValue {
    labelAnimatedStyles: {
        color: string;
    };
}

export interface TextButtonAnimationsProps extends PressableAnimationsProps<TextButtonAnimationsRenderPropValue> {
    disabled?: boolean;
}

export const TextButtonAnimations = ({ progress, disabled, children }: TextButtonAnimationsProps) => {
    const theme = useTheme();

    const animationProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const labelStyles = {
        color: {
            default: theme.text.interactive,
            hovered: theme.interactive.primaryHover,
            pressed: theme.interactive.primaryPress,
            disabled: theme.text.disabled,
        },
    };

    const labelAnimatedStyles = useAnimatedStyle(() => {
        return {
            color: disabled
                ? labelStyles.color.disabled
                : interpolateColor(
                      animationProgress.value,
                      [0, 1, 2],
                      [labelStyles.color.default, labelStyles.color.hovered, labelStyles.color.pressed],
                  ),
        };
    });

    return children({ labelAnimatedStyles });
};
