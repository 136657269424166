import { Pressable, ScrollView, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { LinearGradient } from '~/components/linearGradient';

export const Container = styled(View)`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    flex: 1;
    flex-shrink: 0;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            flex: auto;
        `}
`;

export const HorizontalScroll = styled(ScrollView)`
    min-width: 100%;
    min-height: 100%;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 100%;
        `}
`;

export const ListWrapper = styled(View)`
    min-width: 100%;
    min-height: 100%;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 100%;
        `}
`;

export const Row = styled(Pressable)<{ head?: boolean; hidden?: boolean; backgroundColor?: string; even?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    cursor: default;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 100%;
            height: 50px;
        `}

    ${({ hidden }) =>
        hidden &&
        css`
            height: 0;
            visibility: hidden;
        `}
   
   ${({ head }) =>
        head &&
        css`
            align-items: flex-end;
        `}
`;

export const RowBackground = styled(View)<{ hovered?: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #ffffff00 0%, #ffffff01 20%, #ffffff01 80%, #ffffff00 100%);

    ${({ hovered }) =>
        hovered &&
        css`
            background-image: linear-gradient(90deg, #ffffff00 0%, #ffffff05 20%, #ffffff05 80%, #ffffff00 100%);
        `}
`;

export const Separator = styled(View)`
    position: relative;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.border['12']};
    z-index: -1;
`;

export const HeadersShadow = styled(LinearGradient)`
    position: absolute;
    left: 0;
    width: 100%;
    height: 8px;
    bottom: -8px;
`;

export const CenteredContainer = styled(View)<{ width: number }>`
    width: ${({ width }) => width}px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoaderContainer = styled(View)<{ bottomInset?: number }>`
    margin-bottom: ${({ bottomInset }) => bottomInset || 24}px;
    margin-top: ${({ bottomInset }) => bottomInset || 24}px;
    display: flex;
    align-items: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            justify-content: center;
        `}
`;

export const EmptyScreenContainer = styled(View)`
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InsetSpacing = styled(View)<{ bottomInset?: number }>`
    width: 100%;
    height: ${({ bottomInset }) => bottomInset || 0}px;
`;
