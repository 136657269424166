import { CategoryTabType } from './MetamorphosisContent.types';

export const categories: CategoryTabType[] = [
    {
        label: 'Przód',
        value: 'front',
    },
    {
        label: 'Bok',
        value: 'side',
    },
    {
        label: 'Tył',
        value: 'back',
    },
];
