import React from 'react';
import { Rect } from 'react-native-svg';

import * as S from './DataPoint.styled';

export interface DataPointProps {
    index: number;
    x: number;
    y: number;

    size: number;
    indicatorHeight: number;
    onPress: ({ index }: { index: number }) => void;
}

export const DataPoint: React.FC<DataPointProps> = (props) => {
    const handlePress = () => {
        props.onPress({ index: props.index });
    };

    const shouldReduceIndicatorAtBottom = props.y < 0.9 * props.indicatorHeight;
    const gradientId = shouldReduceIndicatorAtBottom ? 'indicator-regular' : 'indicator-reduced';

    return (
        <S.Container x={props.x} y={props.y} onPress={handlePress} onClick={handlePress}>
            <Rect x={-1} y={-props.y} width={2} height={props.indicatorHeight} fill={`url(#${gradientId})`} />
            <S.DataPointBorder r={props.size / 2} strokeWidth="3" />
            <S.DataPointDot r={props.size / 6} onPress={handlePress} onClick={handlePress} />
        </S.Container>
    );
};
