import { PrevNextControls } from '../prevNextControls';
import * as S from './PortionCounter.styled';

type PortionCounterProps = {
    title?: string;
    value: number;
    maxValue?: number;
    onChange: (number: number) => void;
};

export const PortionCounter = ({
    title = 'Ile porcji chcesz zrobić?',
    value,
    maxValue,
    onChange,
}: PortionCounterProps) => {
    const handleOnNextPress = () => {
        onChange(value + 1);
    };
    const handleOnPrevPress = () => {
        onChange(value - 1);
    };

    return (
        <S.Container>
            <S.Title>{title}</S.Title>
            <S.CounterWrapper>
                <PrevNextControls
                    prevIcon="minus"
                    nextIcon="add"
                    label={value}
                    prevDisabled={value <= 1}
                    nextDisabled={maxValue ? value >= maxValue : undefined}
                    fullWidth
                    onNextPress={handleOnNextPress}
                    onPrevPress={handleOnPrevPress}
                />
            </S.CounterWrapper>
        </S.Container>
    );
};
