import { View } from 'react-native';
import CarouselBase from 'react-native-reanimated-carousel';
import styled from 'styled-components/native';

export const Container = styled(View)`
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
`;

export const CarouselWrapper = styled(View)`
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
`;

export const DotsContainer = styled(View)`
    width: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
`;

export const Carousel = styled(CarouselBase)`
    overflow: visible;
`;

export const SliderItemWrapper = styled(View)<{ first?: boolean; last?: boolean }>`
    flex: 1;
    padding: 16px 8px 0;

    ${({ first }) => first && 'padding-left: 0;'}
    ${({ last }) => last && 'padding-right: 0;'}
`;
