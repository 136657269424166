import { RecursiveModelPredicate } from '@aws-amplify/datastore';
import { Measurement } from '@pg/backend';
import { QueryFunction, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

export interface UserMeasurementsFilters {
    startDate?: Date | null;
    endDate?: Date | null;
    sortDirection?: SortDirection;
}

const fetchAllUserMeasurements: QueryFunction<
    Measurement[],
    ['getAllUserMeasurements', UserMeasurementsFilters]
> = async ({ queryKey }) => {
    const [_, filters] = queryKey;
    return await DataStore.query(
        Measurement,
        (criteria) =>
            criteria.and(
                (c) =>
                    [
                        filters.startDate && c.date.ge(filters.startDate.toISOString()),
                        filters.endDate && c.date.le(filters.endDate?.toISOString()),
                    ].filter(Boolean) as RecursiveModelPredicate<Measurement>[],
            ),
        {
            sort: (s) => s.date(filters.sortDirection ?? SortDirection.ASCENDING),
        },
    );
};

export const useGetUserMeasurements = (
    filters: UserMeasurementsFilters = {},
    options?: UseQueryOptions<
        Measurement[],
        unknown,
        Measurement[],
        ['getAllUserMeasurements', UserMeasurementsFilters]
    >,
) => useQuery(['getAllUserMeasurements', filters], fetchAllUserMeasurements, { initialData: [], ...options });
