import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useToasts } from '~/enhancers/toasts.context';

import * as S from './Toasts.styled';

export const Toasts = () => {
    const inset = useSafeAreaInsets();
    const { currentToast } = useToasts();

    return (
        <S.ToastContainer inset={inset} pointerEvents="box-none">
            {currentToast && <S.Toast {...currentToast.config} id={currentToast.id} />}
        </S.ToastContainer>
    );
};
