import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { LinearGradient } from '~/components/linearGradient';
import { CellStyledProps } from '~/components/table';

const alignmentMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

export const Container = styled(View)<CellStyledProps>`
    padding: 16px 8px;
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    justify-content: ${({ alignment }) => alignmentMap[alignment || 'center']};

    ${({ head }) =>
        head &&
        css`
            padding: 12px 8px 8px;
        `}

    ${({ first, theme }) =>
        first &&
        css`
            padding-left: ${theme.isWebDesktop ? 0 : 16}px;
        `}

  ${({ last, theme }) =>
        last &&
        css`
            padding-right: ${theme.isWebDesktop ? 0 : 16}px;
        `}

  ${({ sticky, backgroundColor }) =>
        sticky &&
        css`
            height: 100%;
            background-color: ${backgroundColor || 'transparent'};
            position: absolute;
            top: 0;
        `}

  ${({ theme, width }) =>
        !theme.isWebDesktop &&
        width &&
        css`
            width: ${width}px;
        `}

  ${({ theme, width }) =>
        theme.isWebDesktop &&
        width &&
        css`
            flex: ${width};
        `}

  ${({ minWidth }) =>
        minWidth &&
        css`
            min-width: ${minWidth}px;
        `}

  ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth}px;
        `}
`;

export const StickyCellShadow = styled(LinearGradient)`
    position: absolute;
    left: -8px;
    top: 0;
    bottom: 0;
    width: 8px;
`;
