import React, { useMemo } from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';

import { LeftSubmenuMenuItemBackgroundAnimation } from './LeftSubmenuItem.animated';
import * as S from './LeftSubmenuItem.styled';

export interface PlanMenuItemProps
    extends Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'disabled'> {
    active?: boolean;
    icon?: IconName;
    children: string;
    rightContent?: React.ReactNode;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const LeftSubmenuItem: React.FC<PlanMenuItemProps> = ({
    active = false,
    icon,
    children,
    rightContent,
    ...props
}) => {
    const { pressableProps } = usePressableState();
    const theme = useTheme();

    const contentColor = useMemo(() => {
        if (pressableProps.hovered || pressableProps.pressed || active) return theme.text.primary;

        return theme.text.tertiary;
    }, [theme, pressableProps.hovered, pressableProps.pressed, active]);

    return (
        <LeftSubmenuMenuItemBackgroundAnimation
            active={active}
            hovered={pressableProps.hovered}
            pressed={pressableProps.pressed}
        >
            {(animatedStyle) => (
                <AnimatedContainer {...pressableProps} {...props} style={[props.style, animatedStyle]}>
                    {icon && (
                        <S.IconContainer>
                            <Icon name={icon} size={20} color={contentColor} />
                        </S.IconContainer>
                    )}
                    <S.TextContent>
                        <S.Text color={contentColor}>{children}</S.Text>
                    </S.TextContent>
                    {rightContent}
                </AnimatedContainer>
            )}
        </LeftSubmenuMenuItemBackgroundAnimation>
    );
};
