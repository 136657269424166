import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold32}
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 32px;
`;

export const Disclaimer = styled(Text)`
    ${mixins.typography.ui.regular16}
    color: ${({ theme }) => theme.text.tertiary};
`;

export const Container = styled(View)`
    flex: 1;
    width: 600px;
    max-height: 800px;
    background-color: ${({ theme }) => theme.fill.background};
    border: 1px solid ${({ theme }) => theme.border['16']};
    border-radius: 12px;
    shadow-color: #000;
    shadow-offset: 0px 12px;
    shadow-opacity: 0.5;
    shadow-radius: 32px;
`;

export const Wrapper = styled(View)`
    flex: 1;
    padding: 32px 24px;
    border-radius: 12px;
    background-image: ${({ theme }) =>
        `linear-gradient(-35deg, ${theme.fill.level1} 45%, ${theme.interactive.primaryDefault} 100%)`};
`;
