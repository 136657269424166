import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '~/components/input';
import { LeftSubmenuItem } from '~/components/leftSubmenuItem';
import { useExercise } from '~/enhancers/exercise.context';
import { useExerciseGroupsOptions } from '~/screens/exercisesAtlas/hooks/useExerciseGroupsOptions';

import * as S from './ExercisesAtlasSubmenu.styled';

export const ExercisesAtlasSubmenu = () => {
    const [searchValue, setSearchValue] = useState('');
    const { exerciseGroups, selectedExerciseGroup, setQuery, setSelectedExerciseGroup } = useExercise();
    const { exerciseGroupsOptions } = useExerciseGroupsOptions(exerciseGroups, selectedExerciseGroup);

    useEffect(() => {
        if (searchValue) {
            setSelectedExerciseGroup(undefined);
        }

        setQuery(searchValue);
    }, [searchValue, setQuery, setSelectedExerciseGroup]);

    const handleListItemPress = (id: string) => {
        setSearchValue('');
        setQuery(undefined);
        setSelectedExerciseGroup(id === 'all' ? undefined : id);
    };

    const handleCloseIconPress = useCallback(() => {
        setSearchValue('');
    }, [setSearchValue]);

    return (
        <S.Container>
            <S.SearchContainer>
                <Input
                    value={searchValue}
                    label="Szukaj"
                    leftIcon="search"
                    rightIcon={searchValue.length > 0 ? 'close' : undefined}
                    onRightIconPress={handleCloseIconPress}
                    onChangeText={setSearchValue}
                />
            </S.SearchContainer>
            <S.ListContainer>
                {exerciseGroupsOptions.map(({ id, label, isSelected }) => (
                    <LeftSubmenuItem key={id} active={isSelected} onPress={() => handleListItemPress(id)}>
                        {label}
                    </LeftSubmenuItem>
                ))}
            </S.ListContainer>
        </S.Container>
    );
};
