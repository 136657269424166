import { useMemo } from 'react';
import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface ExerciseInputAnimationsRenderPropValue {
    borderAnimatedStyles: {
        borderColor: string;
    };
}

export interface ExerciseInputAnimationsProps extends AnimationsProps<ExerciseInputAnimationsRenderPropValue> {
    hovered: boolean;
    focused: boolean;
    filled: boolean;
}

export const ExerciseInputAnimations = ({ hovered, focused, filled, children }: ExerciseInputAnimationsProps) => {
    const theme = useTheme();

    const hoveredProgress = useDerivedValue(() => withSpring(Number(hovered), springConfig.default));
    const focusedProgress = useDerivedValue(() => withSpring(Number(focused), springConfig.default));
    const filledProgress = useDerivedValue(() => withSpring(Number(filled), springConfig.default));

    const exerciseInputTheme = useMemo(() => {
        if (focused && filled) {
            return {
                border: {
                    borderColor: [theme.text.interactive, theme.text.positive],
                },
                progress: filledProgress,
            };
        }

        if (hovered && filled) {
            return {
                border: {
                    borderColor: [theme.border['56'], theme.text.positive],
                },
                progress: filledProgress,
            };
        }

        if (filled) {
            return {
                border: {
                    borderColor: [theme.border['40'], theme.text.positive],
                },
                progress: filledProgress,
            };
        }

        if (hovered && focused) {
            return {
                border: {
                    borderColor: [theme.border['56'], theme.text.interactive],
                },
                progress: focusedProgress,
            };
        }

        if (focused) {
            return {
                border: {
                    borderColor: [theme.border['40'], theme.text.interactive],
                },
                progress: focusedProgress,
            };
        }

        return {
            border: {
                borderColor: [theme.border['40'], theme.border['56']],
            },
            progress: hoveredProgress,
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hovered, focused, filled]);

    const borderAnimatedStyles = useAnimatedStyle(() => {
        return {
            borderColor: interpolateColor(
                exerciseInputTheme.progress.value,
                [0, 1],
                exerciseInputTheme.border.borderColor,
            ),
        };
    }, [hovered, focused, filled]);

    return children({ borderAnimatedStyles });
};
