import { formatISO, startOfDay } from 'date-fns';
import { FC, useCallback, useEffect, useRef } from 'react';
import { Pressable } from 'react-native';
import { DateData } from 'react-native-calendars';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import { BottomSheetBackdropProps, BottomSheetModal } from '../bottomSheetModal';
import { BottomSheetBackdrop, HeaderWrapper } from './MeasurementCalendarSheet.styled';
import { Calendar } from './calendar';

interface MeasurementCalendarSheetProps {
    isOpen: boolean;
    currentDate?: string;
    enabledDates?: string[];
    disabledDates?: string[];
    onDismiss: () => void;
    onChange: (date: string) => void;
    onMonthChange?: (date: string) => void;
}

export const MeasurementCalendarSheet: FC<MeasurementCalendarSheetProps> = ({
    isOpen,
    onDismiss,
    onChange,
    onMonthChange,
    currentDate,
    enabledDates,
    disabledDates,
}) => {
    const bottomSheetModalRef = useRef<BottomSheetModal>(null);
    const renderBackdrop = useCallback(
        (props: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} opacity={1} />
        ),
        [],
    );
    const pressableState = usePressableState();
    const inset = useSafeAreaInsets();
    const theme = useTheme();

    const handleButtonPress = () => {
        Emitter.emit(EmitterEvent.scrollToDate, null);
    };

    useEffect(() => {
        isOpen ? bottomSheetModalRef.current?.present() : bottomSheetModalRef.current?.close();
    }, [isOpen]);

    const onDayPress = useCallback(
        (day: DateData) => {
            onChange(day.dateString);
            onDismiss();
        },
        [onChange, onDismiss],
    );
    return (
        <BottomSheetModal
            ref={bottomSheetModalRef}
            index={0}
            snapPoints={['100%']}
            handleIndicatorStyle={{ backgroundColor: theme.border[20] }}
            backgroundStyle={{ backgroundColor: theme.fill.background }}
            handleComponent={null}
            onDismiss={onDismiss}
            backdropComponent={renderBackdrop}
            topInset={inset.top}
            enableContentPanningGesture={false}
        >
            <HeaderWrapper>
                <Pressable onPress={onDismiss}>
                    <PressableIcon
                        name="close"
                        pressableAnimationProgress={pressableState.pressableAnimationProgress}
                        size={20}
                        color={theme.icon.tertiary}
                        pressedColor={theme.icon.tertiary}
                    />
                </Pressable>
                <Button onPress={handleButtonPress} variant="secondary" size="xs">
                    Dzisiaj
                </Button>
            </HeaderWrapper>
            <Calendar
                onDayPress={onDayPress}
                onMonthChange={onMonthChange}
                currentDate={currentDate}
                enabledDates={enabledDates}
                disabledDates={disabledDates}
                maxDate={formatISO(startOfDay(new Date()))}
            />
        </BottomSheetModal>
    );
};
