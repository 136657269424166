import React, { useCallback, useState } from 'react';
import { NativeSyntheticEvent, TextInput, TextInputFocusEventData } from 'react-native';
import { FormControl } from '~/components/formControl';
import { InputProps } from '~/components/input';
import { VERIFICATION_CODE_LENGTH } from '~/constants/auth';

import { DigitBox } from '../DigitBox';
import * as S from './CodeInput.styled';

export interface CodeInputProps extends Omit<InputProps, 'value' | 'error'> {
    value: string;
    error?: string;
    codeLength?: number;
    keepLastDigitActive?: boolean;
}

export const CodeInput = React.forwardRef<TextInput | null, CodeInputProps>(
    ({ value, error, codeLength = VERIFICATION_CODE_LENGTH, keepLastDigitActive, ...props }, ref) => {
        const [focused, setFocused] = useState(false);

        const handleFocus = useCallback(
            (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                props.onFocus?.(e);
                setFocused(true);
            },
            [props],
        );

        const handleBlur = useCallback(
            (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                props.onBlur?.(e);
                setFocused(false);
            },
            [props],
        );

        const handleTextChange = (textValue: string) => {
            if (Number.isInteger(Number(textValue)) || textValue === '') {
                props.onChangeText?.(textValue);
            }
        };

        const getIsDigitBoxActive = (index: number) => {
            const shouldHighlightFirstDigit = !value.length && index === 0;
            const shouldHighlightSubsequentDigit = value.length === index;
            const shouldHighlightLastDigit =
                keepLastDigitActive && value.length === codeLength && index === codeLength - 1;

            return focused && (shouldHighlightFirstDigit || shouldHighlightSubsequentDigit || shouldHighlightLastDigit);
        };

        return (
            <FormControl error={error}>
                <S.InputContainer>
                    {[...Array(codeLength)].map((item, index) => (
                        <DigitBox
                            key={index}
                            error={!!error}
                            value={value?.[index] || ''}
                            active={getIsDigitBoxActive(index)}
                            disabled={!props.editable}
                            noMargin={index === codeLength - 1}
                        />
                    ))}
                    <S.Input
                        {...props}
                        ref={ref}
                        value={value}
                        maxLength={codeLength}
                        keyboardType="number-pad"
                        returnKeyType="done"
                        textContentType="oneTimeCode"
                        onChangeText={handleTextChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </S.InputContainer>
            </FormControl>
        );
    },
);
