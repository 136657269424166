import React, { ReactNode } from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { usePressableState } from '~/hooks/usePressableState';

import { TextButtonAnimations } from './TextButton.animated';
import * as S from './TextButton.styled';

export interface TextButtonProps
    extends Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'onPress'> {
    children: ReactNode;
    disabled?: boolean;
    onPress?: () => void;
}

const AnimatedLabel = Animated.createAnimatedComponent(S.Label);

export const TextButton: React.FC<TextButtonProps> = ({ children, disabled, onPress, ...props }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    return (
        <TextButtonAnimations disabled={disabled} progress={pressableAnimationProgress}>
            {({ labelAnimatedStyles }) => (
                <S.Container {...pressableProps} disabled={disabled} onPress={onPress} {...props}>
                    <AnimatedLabel style={labelAnimatedStyles}>{children}</AnimatedLabel>
                </S.Container>
            )}
        </TextButtonAnimations>
    );
};
