import { Image, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 3px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.fill.level5};
    border-color: ${({ theme }) => theme.border[40]};
    border-width: 1px;
`;

export const IconImage = styled(Image)`
    height: 20px;
    width: 20px;
`;

export const CountContainer = styled(View)`
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
`;

export const Count = styled(Text)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.fill.positive};
`;
