import { FinishedSeriesData } from '@pg/backend';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const createOrUpdateFinishedSeriesData: MutationFunction<
    FinishedSeriesData,
    Pick<FinishedSeriesData, 'reps' | 'weight' | 'seriesNumber' | 'trainingexerciseID' | 'done'> & { id?: string }
> = async ({ id, reps, seriesNumber, trainingexerciseID, weight, done }) => {
    if (id) {
        const original = await DataStore.query(FinishedSeriesData, id);

        if (!original) throw new Error(`FinishedSeriesData with id ${id} not found`);

        return await DataStore.save(
            FinishedSeriesData.copyOf(original, (updated) => {
                updated.reps = reps;
                updated.weight = weight;
                updated.done = done;
            }),
        );
    }

    return await DataStore.save(
        new FinishedSeriesData({
            reps,
            seriesNumber,
            trainingexerciseID,
            weight,
            done,
        }),
    );
};

export const useCreateOrUpdateFinishedSeriesData = () =>
    useMutation({
        mutationKey: ['createOrUpdateFinishedSeriesData'],
        mutationFn: createOrUpdateFinishedSeriesData,
    });
