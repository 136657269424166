import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { GlobalToasts } from '~/components/GlobalToasts';
import { TrainingContextProvider } from '~/enhancers/training.context';
import { TrainingPlanContextProvider } from '~/enhancers/trainingPlanContext/trainingPlan.context';

import { HomeParamsList } from './HomeNavigation.types';
import { TabNavigation } from './TabNavigation';
import { ChatStackNavigation } from './chat';
import { DashboardStackNavigation } from './dashboard';
import { DietStackNavigation } from './diet';
import { MeasurementsStackNavigation } from './measurements';
import { MoreStackNavigation } from './more';
import { TrainingStackNavigation } from './training';

const Stack = createNativeStackNavigator<HomeParamsList>();

export const HomeNavigation = () => {
    return (
        <TrainingContextProvider>
            <TrainingPlanContextProvider>
                <>
                    <Stack.Navigator
                        initialRouteName="Tab"
                        screenOptions={{
                            header: () => <GlobalToasts position="top" />,
                            contentStyle: { flexDirection: 'row' },
                        }}
                    >
                        <Stack.Screen name="Tab" component={TabNavigation} />
                        <Stack.Screen name="DietStack" component={DietStackNavigation} />
                        <Stack.Screen name="TrainingStack" component={TrainingStackNavigation} />
                        <Stack.Screen name="ChatStack" component={ChatStackNavigation} />
                        <Stack.Screen name="MeasurementsStack" component={MeasurementsStackNavigation} />
                        <Stack.Screen name="MoreStack" component={MoreStackNavigation} />
                        <Stack.Screen name="DashboardStack" component={DashboardStackNavigation} />
                    </Stack.Navigator>
                    <GlobalToasts position="bottom" />
                </>
            </TrainingPlanContextProvider>
        </TrainingContextProvider>
    );
};
