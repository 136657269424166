import React from 'react';
import { DropdownMenuItem } from '~/components/dropdown/DropdownMenuItem';

import * as S from './Dropdown.styled';
import { DropdownMenuItemProps } from './Dropdown.types';

export interface MenuItemProps {
    items: DropdownMenuItemProps[];
    onClose: () => void;
}

export const DropdownMenu: React.FC<MenuItemProps> = ({ items, onClose }) => {
    return (
        <S.DropdownMenuContainer style={S.shadowStyles.dropdownMenu}>
            {items.map((item, index) => (
                <DropdownMenuItem key={index} {...item} onClose={onClose} />
            ))}
        </S.DropdownMenuContainer>
    );
};
