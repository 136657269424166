import { Area } from 'react-easy-crop';

export const createImage = (url: any) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });
};

export const getCroppedImage = async (
    fileBase64: string,
    size: { width: number; height: number },
    type: string,
    croppedArea?: Area,
): Promise<string | undefined> => {
    const image: HTMLImageElement = await createImage(fileBase64);
    const canvas = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height);
    const resizedCanvas = document.createElement('canvas');
    const rCtx: CanvasRenderingContext2D | null = resizedCanvas.getContext('2d');

    canvas.width = maxSize;
    canvas.height = maxSize;

    if (!ctx || !rCtx) {
        console.error('Method `getCroppedImage` cannot receive canvas context.');

        return;
    }

    ctx.drawImage(image, maxSize / 2 - image.width * 0.5, maxSize / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, maxSize, maxSize);

    canvas.width = croppedArea?.width || image.width;
    canvas.height = croppedArea?.height || image.height;

    ctx.putImageData(
        data,
        0 - maxSize / 2 + image.width * 0.5 - (croppedArea?.x || 0),
        0 - maxSize / 2 + image.height * 0.5 - (croppedArea?.y || 0),
    );

    resizedCanvas.width = size.width;
    resizedCanvas.height = size.height;

    rCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, size.width, size.height);

    return resizedCanvas.toDataURL(type);
};
