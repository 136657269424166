import React, { FC } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { DietPlanButtonAnimation } from './DietPlanButton.animated';
import * as S from './DietPlanButton.styled';

interface TrainingDayButtonProps {
    title: string;
    label: string;
    onPress: () => void;
}

const AnimatedButton = Animated.createAnimatedComponent(S.Button);
const AnimatedLabel = Animated.createAnimatedComponent(S.Label);

export const DietPlanButton: FC<TrainingDayButtonProps> = ({ title, label, onPress }) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    return (
        <DietPlanButtonAnimation progress={pressableAnimationProgress}>
            {({ containerAnimationStyles, labelAnimationStyles }) => (
                <S.Container>
                    <AnimatedButton style={containerAnimationStyles} {...pressableProps} onPress={onPress}>
                        <S.Content>
                            <AnimatedLabel style={labelAnimationStyles}>{label}</AnimatedLabel>
                            <S.Title>{title}</S.Title>
                        </S.Content>
                        <S.IconWrapper>
                            <PressableIcon
                                name="chevron-right"
                                size={24}
                                color={theme.icon.tertiary}
                                pressedColor={theme.icon.primary}
                                pressableAnimationProgress={pressableAnimationProgress}
                            />
                        </S.IconWrapper>
                    </AnimatedButton>
                </S.Container>
            )}
        </DietPlanButtonAnimation>
    );
};
