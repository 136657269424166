import { Pressable, View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const OverlayBackground = styled(Pressable)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme }) => theme.fill.background80};
    cursor: default;
`;

export const Container = styled(View)`
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    display: flex;
    height: 100%;

    ${({ theme }) =>
        theme.isWebLargeDesktop &&
        css`
            position: static;
        `}
`;
