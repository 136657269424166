import { Exercise } from '@pg/backend';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useGetExercises } from '~/api/exercise/useGetExercises';
import { Input } from '~/components/input';
import { Loader } from '~/components/loader';
import { useExercise } from '~/enhancers/exercise.context';
import { useDebouncedState } from '~/hooks/useDebouncedState';
import { NoExercises } from '~/screens/exercisesAtlas/components/noExercises';

import { ExercisesAtlasNavigationProp } from '../../../ExercisesAtlasScreen.types';
import { ExerciseItem } from '../../../components/exerciseItem';
import * as S from './SearchExercisesContent.styled';

export const SearchExercisesContent = () => {
    const theme = useTheme();
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useDebouncedState(searchValue);
    const navigation = useNavigation<ExercisesAtlasNavigationProp>();
    const { groupNamesById, openVideoModal, setSelectedExerciseGroup } = useExercise();
    const { data: exercises, isFetching: isLoadingExercises } = useGetExercises({ name: debouncedSearchValue });

    const isLoading = useMemo(
        () => isLoadingExercises || searchValue !== debouncedSearchValue,
        [searchValue, debouncedSearchValue, isLoadingExercises],
    );

    useEffect(() => {
        setDebouncedSearchValue(searchValue);
    }, [searchValue, setDebouncedSearchValue]);

    const handleTagPress = useCallback(
        (tagValue: string) => {
            setSelectedExerciseGroup(tagValue);
            navigation.navigate('MoreStack', {
                screen: 'ExercisesAtlasStack',
                params: { screen: 'ExercisesAtlas' },
            });
        },
        [setSelectedExerciseGroup, navigation],
    );

    const handleCloseIconPress = useCallback(() => {
        setSearchValue('');
        setDebouncedSearchValue('');
    }, [setSearchValue, setDebouncedSearchValue]);

    const handleExerciseItemPress = useCallback(
        (exercise: Exercise) => {
            if (exercise.videoPath) {
                openVideoModal(exercise.videoPath);
            }
        },
        [openVideoModal],
    );

    const listEmptyComponent = useMemo(() => {
        if (isLoading) {
            return (
                <S.LoaderContainer>
                    <Loader size="xl" color={theme.icon.interactive} />
                </S.LoaderContainer>
            );
        }

        if (searchValue) {
            return <NoExercises />;
        }

        return undefined;
    }, [isLoading, searchValue, theme.icon.interactive]);

    return (
        <S.FullHeightContainer>
            <>
                <S.SearchContainer>
                    <Input
                        value={searchValue}
                        label="Szukaj ćwiczenia..."
                        onChangeText={setSearchValue}
                        rightIcon={searchValue.length > 0 ? 'close' : undefined}
                        onRightIconPress={handleCloseIconPress}
                    />
                </S.SearchContainer>
                <S.SubtitleContainer>
                    <S.Subtitle>Wyszukujesz pośród wszystkich ćwiczeń</S.Subtitle>
                </S.SubtitleContainer>
            </>
            <FlatList
                data={debouncedSearchValue.length > 0 && !isLoading ? exercises : []}
                renderItem={({ item }) => (
                    <ExerciseItem
                        title={item.name || ''}
                        videoPath={item.videoPath ?? undefined}
                        tags={[
                            {
                                value: item.exercisegroupID,
                                label: groupNamesById[item.exercisegroupID],
                            },
                        ]}
                        onPress={() => handleExerciseItemPress(item)}
                        onTagPress={handleTagPress}
                    />
                )}
                keyExtractor={(item) => item.id}
                contentContainerStyle={{ flexGrow: 1 }}
                ListEmptyComponent={listEmptyComponent}
            />
        </S.FullHeightContainer>
    );
};
