import { useNavigation } from '@react-navigation/native';
import React, { useRef } from 'react';
import { ScrollView } from 'react-native';
import { useTheme } from 'styled-components/native';

import { DietNavigationProp } from '../../../../DietScreen.types';
import { MealItem } from '../../../mealItem';
import { Header, HeaderProps } from '../header';
import { TrainingDayButton } from '../trainingDayButton';
import * as S from './SliderItem.styled';
import { SliderProps } from './SliderItem.types';

export const SliderItem = ({ isToday, active, date, nutritionData, meals, trainingMeal, onMealPress }: SliderProps) => {
    const { isWebDesktop } = useTheme();
    const navigation = useNavigation<DietNavigationProp>();
    const listRef = useRef<ScrollView | null>(null);

    const handleMealPress = (mealId: string) => {
        if (isWebDesktop) {
            onMealPress?.(mealId);
            return;
        }

        navigation.navigate('DietStack', {
            screen: 'Meal',
            params: {
                mealId,
            },
        });
    };

    const handleMealEdit = (mealId: string) => {
        if (isWebDesktop) {
            return;
        }

        navigation.navigate('DietStack', {
            screen: 'Substitute',
            params: {
                mealId,
            },
        });
    };

    const getHeaderVariant = (): HeaderProps['variant'] => {
        if (!active) {
            return 'disabled';
        }

        if (isToday) {
            return 'active';
        }

        return 'default';
    };

    const renderContent = () => {
        if (!active) {
            return (
                <S.InactiveState>
                    Ten dzień nie jest uwzględniony w bieżącym planie lub nasza współpraca nie była aktywna w ciągu tego
                    dnia. Zmień aktualny plan w ustawieniach diety, by zobaczyć poprzednie plany.
                </S.InactiveState>
            );
        }

        return (
            <S.MealsList ref={listRef} showsVerticalScrollIndicator={false}>
                <S.MealItemContainer>
                    {trainingMeal ? (
                        <MealItem
                            isTrainingMeal
                            id={trainingMeal.id}
                            imageSrc={trainingMeal.imageSrc}
                            name={trainingMeal.name}
                            substitutes={trainingMeal.substitutes}
                            onPress={() => handleMealPress(trainingMeal.id)}
                            onEdit={() => handleMealEdit(trainingMeal.id)}
                            onRemove={() => {}}
                        />
                    ) : (
                        <TrainingDayButton onPress={() => {}} />
                    )}
                </S.MealItemContainer>
                {meals.map((meal) => (
                    <S.MealItemContainer key={meal.id}>
                        <MealItem
                            id={meal.id}
                            imageSrc={meal.imageSrc}
                            name={meal.name}
                            substitutes={meal.substitutes}
                            isTrainingMeal={meal.isTrainingMeal}
                            onPress={() => handleMealPress(meal.id)}
                            onEdit={() => handleMealEdit(meal.id)}
                        />
                    </S.MealItemContainer>
                ))}
            </S.MealsList>
        );
    };

    return (
        <S.Container>
            <Header variant={getHeaderVariant()} date={date} nutritionData={nutritionData} />
            {renderContent()}
        </S.Container>
    );
};
