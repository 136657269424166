import React, { FC } from 'react';
import { SEO } from '~/components/wrappers';
import { useTrainingScreen, withTrainingScreenContext } from '~/screens/training/TrainingScreen.context';

import * as S from './TrainingScreen.styled';
import { TrainingScreenProps } from './TrainingScreen.types';
import { TrainingContent } from './components/trainingContent';

export const TrainingScreenComponent: FC<TrainingScreenProps> = () => {
    const { isFetching } = useTrainingScreen();
    return (
        <S.MainLayout isBottomRounded={false} isLoading={isFetching}>
            <SEO title="Trening" />
            <TrainingContent />
        </S.MainLayout>
    );
};

export const TrainingScreen = withTrainingScreenContext(TrainingScreenComponent);
