import { View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
`;

export const Wrapper = styled(View)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;
