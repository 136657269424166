import { Howl } from 'howler';
import { MutableRefObject, useEffect, useRef } from 'react';

import tikSoundFile from '../assets/1s.mp3';
import cardioEndSoundFile from '../assets/cardio_excercise_end.mp3';
import restEndSoundFile from '../assets/rest_end.mp3';
import { UseSoundsOptions } from './useSounds.types';

export const useSounds = ({ isMuted }: UseSoundsOptions) => {
    const tikSound = useRef<Howl>();
    const cardioEndSound = useRef<Howl>();
    const restEndSound = useRef<Howl>();

    useEffect(() => {
        tikSound.current = new Howl({ src: [tikSoundFile] });
        cardioEndSound.current = new Howl({ src: [cardioEndSoundFile] });
        restEndSound.current = new Howl({ src: [restEndSoundFile] });

        return () => {
            tikSound.current?.unload();
            cardioEndSound.current?.unload();
            restEndSound.current?.unload();
        };
    }, []);

    const buildSound = (soundRef: MutableRefObject<Howl | undefined>) => {
        return {
            play: () => {
                if (!isMuted) {
                    soundRef.current?.stop().loop(false).play();
                }
            },
            playInLoop: () => {
                if (!isMuted) {
                    soundRef.current?.stop().loop(true).play();
                }
            },
            stop: () => {
                soundRef.current?.stop().loop(false);
            },
        };
    };

    return {
        tik: buildSound(tikSound),
        cardioEnd: buildSound(cardioEndSound),
        restEnd: buildSound(restEndSound),
    };
};
