import { FC, ReactNode } from 'react';
import { IconButton } from '~/components/iconButton';
import { Loader } from '~/components/loader';

import * as S from './Sidebar.styled';

export interface SidebarProps {
    title: string;
    children: ReactNode;
    isLoading?: boolean;
    onClose?: () => void;
}

export const Sidebar: FC<SidebarProps> = ({ title, children, isLoading, onClose }: SidebarProps) => {
    return (
        <S.Container>
            <S.Header>
                <S.Title>{title}</S.Title>
                {onClose && <IconButton icon="close" size="m" onPress={onClose} />}
            </S.Header>
            <S.Content>
                {isLoading ? (
                    <S.LoaderWrapper>
                        <Loader size="xl" />
                    </S.LoaderWrapper>
                ) : (
                    children
                )}
            </S.Content>
        </S.Container>
    );
};
