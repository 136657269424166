import { Pressable, View } from 'react-native';
import styled from 'styled-components/native';
import { HoverableProps } from '~/types/hoverable';

interface ActivityButtonHoverableStyledProps extends HoverableProps {}

export const Container = styled(Pressable)<ActivityButtonHoverableStyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-sizing: border-box;
    width: 32px;
    height: 24px;
    border-width: 1.5px;
    border-style: solid;
`;

export const IconWrapper = styled(View)``;
