import { useNavigation } from '@react-navigation/native';
import { MenuItem } from '~/components/menuItem';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useMeasurement } from '~/enhancers/measurement.context';
import { MainLayout } from '~/layouts/native';
import { MeasurementsComingSoon } from '~/screens/measurements/components/measurementsComingSoon';

import * as S from './MeasurementsScreen.native.styled';
import { MeasurementsNavigationProp } from './MeasurementsScreen.types';
import { MeasurementChart } from './components/measurementsChart';

export const MeasurementsScreenContent = () => {
    const navigation = useNavigation<MeasurementsNavigationProp>();
    const { addMeasurement } = useMeasurement();

    return (
        <>
            <S.ButtonsContainer>
                <S.Button variant="primary" size="big" icon="add" label="Dodaj nowy pomiar" onPress={addMeasurement} />
                <S.Button
                    size="big"
                    icon="compare"
                    label="Zobacz metamorfozę"
                    last
                    onPress={() =>
                        navigation.navigate('MeasurementsStack', {
                            screen: 'Metamorphosis',
                        })
                    }
                />
            </S.ButtonsContainer>
            <S.ChartContainer>
                <MeasurementChart />
            </S.ChartContainer>
            <S.MenuItemWrapper>
                <MenuItem
                    icon="table"
                    onPress={() =>
                        navigation.navigate('MeasurementsStack', {
                            screen: 'Table',
                        })
                    }
                >
                    Tabela pomiarów
                </MenuItem>
            </S.MenuItemWrapper>
        </>
    );
};

export const MeasurementsScreen = () => {
    const { features } = useAppFeatures();

    if (!features.measurements) {
        return (
            <MainLayout noBackground fullWidth>
                <MeasurementsComingSoon />
            </MainLayout>
        );
    }

    return (
        <MainLayout fullWidth>
            <MeasurementsScreenContent />
        </MainLayout>
    );
};
