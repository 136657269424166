import { Palette } from '../palette';
import { Tokens } from './types';

export const getTokens = (palette: Palette): Tokens => ({
    text: {
        primary: palette.neutral['100'],
        secondary: palette.neutral['60'],
        tertiary: palette.neutral['50'],
        disabled: palette.neutral['30'],
        critical: palette.red['80'],
        criticalSurface: palette.red['00'],
        positive: palette.green['40'],
        positiveSurface: palette.green['00'],
        interactive: palette.violet['70'],
        interactiveSurface: palette.violet['00'],
    },
    icon: {
        primary: palette.neutral['100'],
        secondary: palette.neutral['60'],
        tertiary: palette.neutral['50'],
        disabled: palette.neutral['30'],
        critical: palette.red['80'],
        criticalSurface: palette.red['00'],
        positive: palette.green['40'],
        positiveSurface: palette.green['00'],
        interactive: palette.violet['70'],
        interactiveSurface: palette.violet['00'],
    },
    fill: {
        background: palette.neutral['00'],
        background80: palette.opacity.bg80.rgba,
        level1: palette.neutral['05'],
        level2: palette.neutral['10'],
        level3: palette.neutral['15'],
        level4: palette.neutral['20'],
        level5: palette.neutral['30'],
        level100: palette.neutral['100'],
        informative: palette.violet['70'],
        informative20: palette.opacity.violet20.rgba,
        positive: palette.green['40'],
        critical: palette.red['80'],
        warning: palette.yellow['60'],
    },
    border: {
        '12': palette.opacity['12'].rgba,
        '16': palette.opacity['16'].rgba,
        '20': palette.opacity['20'].rgba,
        '32': palette.opacity['32'].rgba,
        '40': palette.opacity['40'].rgba,
        '56': palette.opacity['56'].rgba,
        positive: palette.green['40'],
        critical: palette.red['80'],
        interactive: palette.violet['50'],
        warning: palette.yellow['60'],
    },
    interactive: {
        primaryDefault: palette.violet['50'],
        primaryHover: palette.violet['40'],
        primaryPress: palette.violet['20'],
        secondary: palette.neutral['10'],
        positive: palette.green['40'],
        critical: palette.red['80'],
        warning: palette.yellow['60'],
        opacityDefault: palette.opacity['12'].rgba,
        opacityHover: palette.opacity['16'].rgba,
        opacityPress: palette.opacity['20'].rgba,
    },
});
