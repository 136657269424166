import { View } from 'react-native';
import styled from 'styled-components/native';

import { BottomSheetModal } from '../bottomSheetModal';

export const BottomSheet = styled(BottomSheetModal)`
    border-radius: 20px;
`;

export const Container = styled(View)`
    padding: 22px 16px;
`;

export const MenuList = styled(View)`
    background: ${({ theme }) => theme.fill.level3};
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 8px;
`;

export const HandleContainer = styled(View)`
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 0;
    padding: 8px 0;
`;
