import { DataStore, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useAuth } from '~/enhancers/auth.context';

export const useShowAppLoader = () => {
    const [dataStoreReady, setDataStoreReady] = useState(false);
    const { isLoading: isLoadingAuth, isAuthenticated } = useAuth();

    useEffect(() => {
        const listener = Hub.listen('datastore', async ({ payload: { event } }) => {
            if (event === 'ready') {
                setDataStoreReady(true);
            }
        });

        return () => listener();
    }, []);

    useEffect(() => {
        const handleDataStore = async () => {
            if (!isAuthenticated && dataStoreReady) {
                await DataStore.clear();
                await DataStore.stop();
                setDataStoreReady(false);
                return;
            }

            if (isAuthenticated && !dataStoreReady) {
                await DataStore.start();
            }
        };

        void handleDataStore();
    }, [dataStoreReady, isAuthenticated]);

    return isLoadingAuth || (!dataStoreReady && isAuthenticated);
};
