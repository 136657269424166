import { IconName } from '~/icons';

export const routesIcons: Record<string, { icon: IconName; iconActive: IconName }> = {
    Dashboard: {
        icon: 'dashboard-outline',
        iconActive: 'dashboard-fill',
    },
    Measurements: {
        icon: 'ruler-outline',
        iconActive: 'ruler-fill',
    },
    TrainingTabStack: {
        icon: 'atlas-gym-outline',
        iconActive: 'atlas-gym-fill',
    },
    Diet: {
        icon: 'diet-outline',
        iconActive: 'diet-filled',
    },
    ChatTabStack: {
        icon: 'chat-outline',
        iconActive: 'chat-fill',
    },
    More: {
        icon: 'menu',
        iconActive: 'menu',
    },
};
