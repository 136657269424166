import { useMemo } from 'react';
import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface LeftSubmenuItemAnimationsRenderPropValue {
    backgroundColor: string;
}

export interface LeftSubmenuMenuItemAnimationsProps extends AnimationsProps<LeftSubmenuItemAnimationsRenderPropValue> {
    hovered: boolean;
    pressed: boolean;
    active: boolean;
}

export const LeftSubmenuMenuItemBackgroundAnimation = ({
    hovered,
    pressed,
    active,
    children,
}: LeftSubmenuMenuItemAnimationsProps) => {
    const theme = useTheme();

    const progress = useMemo(() => {
        if (active && (hovered || pressed)) return 2;
        if (active) return 1;
        return 0;
    }, [hovered, pressed, active]);

    const backgroundProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const currentBackground: Record<string, string> = {
        default: 'transparent',
        active: theme.palette.opacity['08'].rgba,
        activeAndPressed: theme.palette.opacity['16'].rgba,
    };

    const animatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(
                backgroundProgress.value,
                [0, 1, 2],
                [currentBackground.default, currentBackground.active, currentBackground.activeAndPressed],
            ),
        };
    }, []);

    return children(animatedStyle);
};
