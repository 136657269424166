import * as yup from 'yup';

export const SeriesTableRowFormSchema = yup.object().shape({
    seriesNumber: yup.number().required().min(0).integer(),
    reps: yup.number().required().min(0).integer(),
    weight: yup.number().required().min(0),
});

export const SeriesTableRowFormSchemaCardio = yup.object().shape({
    time: yup.number().required().positive(),
});
