import React, { FC } from 'react';

import * as S from './MealSubstitutesPopup.styled';

interface MealSubstitutesPopup {
    children: React.ReactNode;
    onClosePress: () => void;
}

export const MealSubstitutesPopup: FC<MealSubstitutesPopup> = ({ onClosePress, children }) => {
    return (
        <S.Container>
            <S.Header>
                <S.Title>Wybierz zamiennik</S.Title>
                <S.ModalCloseButton onPress={onClosePress} />
            </S.Header>
            <S.Content>{children}</S.Content>
        </S.Container>
    );
};
