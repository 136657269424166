import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { LinearGradient } from '~/components/linearGradient';
import { mixins } from '~/theme';

type ValueTextType = {
    isZeroValue: boolean;
};

export const Background = styled(LinearGradient)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const Container = styled(View)`
    padding: 4px 8px 4px 6px;
    border-radius: 12px;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

export const ValueText = styled(Text)<ValueTextType>`
    ${mixins.typography.ui.medium12}
    color: ${({ theme, isZeroValue }) => (isZeroValue ? theme.text.secondary : theme.text.primary)};
    margin-left: 2px;
`;
