import { useRoute } from '@react-navigation/native';
import { FlashList, ListRenderItem } from '@shopify/flash-list';
import { endOfDay, format, isAfter } from 'date-fns';
import plLocale from 'date-fns/locale/pl';
import { useEffect, useState } from 'react';
import { Button } from '~/components/button';
import { Icon } from '~/components/icon';
import { SEO } from '~/components/wrappers';
import { MainLayout } from '~/layouts';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import * as S from './ActivityDayDetailsScreen.styled';
import { ActivityDetailsScreenProps, DataType } from './ActivityDayDetailsScreen.types';
import { ActivitySheet } from './components/activitySheet';

export const mockData = [
    { name: 'Cardio/bieganie' },
    { name: 'Crossfit' },
    { name: 'Jeździectwo' },
    { name: 'Joga' },
    { name: 'Inny' },
    { name: 'Pływanie' },
    { name: 'Rower/rolki' },
    { name: 'Skakanka' },
    { name: 'Spacer' },
    { name: 'Sporty zimowe' },
    { name: 'Sztuki walki' },
    { name: 'Trening lekkoatletyczny' },
    { name: 'Trening siłowy poza planem' },
    { name: 'Wspinaczka/spacer po górach' },
];

export const ActivityDayDetailsScreen = () => {
    const route = useRoute<ActivityDetailsScreenProps['route']>();
    const [isOpen, setIsOpen] = useState(false);

    const handleRemove = () => {
        console.log('Implement removing item');
    };

    const today = endOfDay(new Date());

    const handleEmmiter = () => {
        !isFutureDate && setIsOpen(true);
    };

    useEffect(() => {
        if (route.params?.sheet) {
            setIsOpen(true);
        }
    }, [route.params?.sheet]);

    useEffect(() => {
        Emitter.on(EmitterEvent.openSheet, handleEmmiter);
        return () => {
            Emitter.off(EmitterEvent.openSheet, handleEmmiter);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFutureDate = route.params?.date && isAfter(new Date(route.params?.date), today);
    const getTitle =
        route.params?.date && format(new Date(route.params?.date), 'EEEE, dd.MM.yyyy', { locale: plLocale });

    const renderItem: ListRenderItem<DataType> = ({ item, index }) => {
        return (
            <S.ExerciseWrapper key={`${item.name} + ${index}`}>
                <S.LeftContentWrapper>
                    {/* TODO Use ActivityIcon component*/}
                    <S.IconWrapper>
                        <Icon name="flexed-biceps-3d" size={16} />
                    </S.IconWrapper>

                    <S.ExerciseName>{item.name}</S.ExerciseName>
                </S.LeftContentWrapper>
                <Button variant="secondary" size="xs" icon="trash-outline" onPress={handleRemove}>
                    Usuń
                </Button>
            </S.ExerciseWrapper>
        );
    };

    const renderMainContent = () => {
        if (isFutureDate) {
            return (
                <S.MainContentWrapper>
                    <S.MainContentIconWrapper>
                        <Icon name="fire-3d" size={34} />
                    </S.MainContentIconWrapper>
                    <S.MainContentText>Ten dzień jeszcze nie nadszedł, ale cieszy mnie Twój zapał</S.MainContentText>
                </S.MainContentWrapper>
            );
        }

        //To bedzie zmienione w mojej innej PRce zwiazanej z dodawaniem aktywnosci
        if (mockData.length) {
            return (
                <FlashList
                    renderItem={renderItem}
                    data={mockData}
                    estimatedItemSize={60}
                    contentContainerStyle={S.flashListContainer}
                />
            );
        }
        return (
            <S.MainContentWrapper>
                <S.MainContentIconWrapper>
                    <Icon name="sleeping-face-3d" size={34} />
                </S.MainContentIconWrapper>
                <S.MainContentText>Brak aktywności w tym dniu</S.MainContentText>
            </S.MainContentWrapper>
        );
    };

    return (
        <>
            <SEO title="Aktywność" />
            <MainLayout isBottomRounded={false}>
                <S.Title>{getTitle}</S.Title>
                {renderMainContent()}
            </MainLayout>
            <ActivitySheet isOpen={isOpen} data={mockData} onDismiss={() => setIsOpen(false)} />
        </>
    );
};
