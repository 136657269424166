import { StyleSheet, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const shadowStyles = StyleSheet.create({
    shadow: {
        shadowOpacity: 0.48,
        shadowRadius: 12,
        shadowOffset: {
            height: 4,
            width: 0,
        },
        shadowColor: 'black',
    },
});

export const Content = styled(View)`
    background-color: ${({ theme }) => theme.fill.level1};
    border: 1px solid ${({ theme }) => theme.border[12]};
    border-radius: 8px;
    padding: 8px 12px;
    margin-top: 4px;
    max-width: 238px;
`;

export const Description = styled(Text)`
    ${mixins.typography.ui.regular14}
    color: ${({ theme }) => theme.text.primary}
`;
