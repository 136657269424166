import { FC, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import Animated, { interpolate, interpolateColor, useAnimatedStyle } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';

import * as S from './TrainingTimerSheetBackground.styled';
import { TrainingTimerSheetBackgroundProps } from './TrainingTimerSheetBackground.types';

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);
const AnimatedProgress = Animated.createAnimatedComponent(S.Progress);

export const TrainingTimerSheetBackground: FC<TrainingTimerSheetBackgroundProps> = ({
    type,
    progress,
    duration,
    isTransitioning,
    animatedIndex,
    style,
}) => {
    const theme = useTheme();
    const [layout, setLayout] = useState<LayoutRectangle>({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    });
    const containerAnimatedStyle = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(animatedIndex.value, [0, 1], [theme.fill.background, theme.fill.level2]),
    }));
    const containerStyle = useMemo(() => [style, containerAnimatedStyle], [style, containerAnimatedStyle]);

    const progressAnimatedStyle = useAnimatedStyle(
        () => ({
            opacity: interpolate(animatedIndex.value, [0, 1], [1, 0]),
            transform: [
                {
                    translateX: interpolate(
                        progress.value,
                        [0, duration],
                        type === 'countdown' ? [0, -layout.width] : [-layout.width, 0],
                    ),
                },
            ],
        }),
        [type, duration, layout.width],
    );

    const handleLayout = useCallback((e: LayoutChangeEvent) => {
        setLayout(e.nativeEvent.layout);
    }, []);

    return (
        <AnimatedContainer pointerEvents="none" style={containerStyle} onLayout={handleLayout}>
            <AnimatedProgress type={type} isTransitioning={isTransitioning} style={progressAnimatedStyle} />
        </AnimatedContainer>
    );
};
