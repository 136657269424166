export const getCalendarWeeks = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const weeks = [];

    let currentWeekStart = new Date(start);
    currentWeekStart.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));

    while (currentWeekStart <= end) {
        const currentWeekEnd = new Date(currentWeekStart);
        currentWeekEnd.setDate(currentWeekStart.getDate() + 6);

        const week = {
            startDate: currentWeekStart.toISOString(),
            endDate: currentWeekEnd.toISOString(),
        };

        weeks.push(week);

        currentWeekStart.setDate(currentWeekStart.getDate() + 7);
    }

    return weeks;
};

export const getCurrentWeekIndex = (weeks: ReturnType<typeof getCalendarWeeks> | undefined): string => {
    if (!weeks?.length) return '0';

    const today = new Date();

    for (let i = 0; i < weeks.length; i++) {
        const startDate = new Date(weeks[i].startDate);
        const endDate = new Date(weeks[i].endDate);

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        if (today >= startDate && today <= endDate) {
            return i.toString();
        }
    }

    return '0';
};
