import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const SummaryContainer = styled(View)`
    flex-direction: row;
`;

export const SummaryItem = styled(View)<{ last?: boolean }>`
    flex: 1;
    padding: 12px 4px;
    background-color: ${({ theme }) => theme.fill.level3};
    border: 2px solid ${({ theme }) => theme.fill.level3};
    border-radius: 12px;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
    flex-basis: auto;
`;

export const SummaryItemTitle = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
export const SummaryItemTitleTextWrapper = styled(View)`
    flex-direction: row;
`;
export const SummaryItemTitleText = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;
export const SummaryItemTitleTextUnit = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;

export const SummaryItemTitleIcon = styled(View)`
    margin-left: 8px;
`;

export const SummaryItemSubtitle = styled(Text)`
    text-align: center;
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.tertiary};
`;
