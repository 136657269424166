import { ExpandedTrainingDay } from '~/api/training/useGetExpandedTrainingDay';
import { TrainingScreenFinishedExercises } from '~/screens/training/TrainingScreen.context';
import { TrainingScreenFinishedExercise } from '~/screens/training/TrainingScreen.types';

export const buildFinishedExercises = (
    expandedTrainingDay?: ExpandedTrainingDay | null,
    exerciseIndexField: 'id' | 'exerciseID' = 'id',
    cardioExerciseIndexField: 'id' | 'cardioexerciseID' = 'id',
) => {
    const initialFinishedExercises: TrainingScreenFinishedExercises = {};

    for (const trainingExercise of expandedTrainingDay?.trainingExercises ?? []) {
        const exerciseData = expandedTrainingDay?.exercisesDataMap?.[trainingExercise.trainingExerciseExerciseDataId];
        const item: TrainingScreenFinishedExercise = {
            series: new Array(exerciseData?.series ?? 0).fill(false),
            finishedCardio: null,
        };

        expandedTrainingDay?.finishedSeries
            .filter((i) => i.trainingexerciseID === trainingExercise.id)
            .forEach((itemData) => {
                item.series[itemData.seriesNumber] = itemData;
            });

        initialFinishedExercises[trainingExercise[exerciseIndexField]] = item;
    }

    for (const trainingCardioExercise of expandedTrainingDay?.trainingCardioExercises ?? []) {
        const finishedCardioExerciseData =
            expandedTrainingDay?.finishedCardioExercisesMap?.[
                trainingCardioExercise.trainingCardioExerciseFinishedCardioExerciseDataId!
            ];

        initialFinishedExercises[trainingCardioExercise[cardioExerciseIndexField]] = {
            series: [],
            finishedCardio: finishedCardioExerciseData ?? null,
        };
    }

    return initialFinishedExercises;
};
