import React from 'react';
import { SectionHeading } from '~/components/sectionHeading';
import { MainLayout } from '~/layouts/native';

import * as S from './DietBasicsScreen.styled.native';

export const DietBasicsScreenContent = () => {
    return (
        <S.Container>
            <S.HeaderContainer>
                <SectionHeading variant="header" title="Podstawy diety" />
            </S.HeaderContainer>
            <S.RichContentContainer>
                <S.TmpText>json content</S.TmpText>
            </S.RichContentContainer>
        </S.Container>
    );
};

export const DietBasicsScreen = () => {
    return (
        <MainLayout scrollable={false} isBottomRounded={false}>
            <DietBasicsScreenContent />
        </MainLayout>
    );
};
