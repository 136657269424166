import React from 'react';
import { PressableProps, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';

import { TileGradientButtonAnimation } from './TileGradientButton.animated';
import * as S from './TileGradientButton.styled';
import { TileGradientButtonStyledProps } from './TileGradientButton.types';

export interface TileGradientButtonProps
    extends Partial<TileGradientButtonStyledProps>,
        Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'disabled'> {
    label: string;
    description?: string;
    icon?: IconName;
    notification?: boolean;
    style?: ViewStyle;
}

Animated.addWhitelistedUIProps({
    colors: true,
});

const AnimatedBorderGradient = Animated.createAnimatedComponent(S.BorderGradient);
const AnimatedBackgroundGradient = Animated.createAnimatedComponent(S.BackgroundGradient);

export const TileGradientButton: React.FC<TileGradientButtonProps> = ({
    size = 'medium',
    variant = 'default',
    icon,
    label,
    description,
    notification,
    style,
    ...props
}) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <S.Container {...pressableProps} variant={variant} size={size} style={style} {...props}>
            <TileGradientButtonAnimation progress={pressableAnimationProgress} variant={variant}>
                {({ backgroundAnimatedProps, borderAnimatedProps }) => (
                    <>
                        <AnimatedBorderGradient animatedProps={borderAnimatedProps} />
                        <AnimatedBackgroundGradient animatedProps={backgroundAnimatedProps} />
                    </>
                )}
            </TileGradientButtonAnimation>
            {icon && (
                <S.IconWrapper size={size} variant={variant}>
                    <Icon name={icon} size="100%" color={theme.icon.primary} />
                </S.IconWrapper>
            )}
            {notification && <S.NotificationBadge size={size} variant={variant} />}
            <S.TextContainer size={size} variant={variant}>
                <S.Label variant={variant} size={size}>
                    {label}
                </S.Label>
                {description && size === 'big' && (
                    <S.Description variant={variant} size={size}>
                        {description}
                    </S.Description>
                )}
            </S.TextContainer>
        </S.Container>
    );
};
