import { LayoutRectangle } from 'react-native';
import { interpolate, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface FloatingLabelAnimationRenderPropValue {
    transform: { translateX?: number; translateY?: number; scale?: number }[];
}

export interface FloatingLabelAnimationProps extends AnimationsProps<FloatingLabelAnimationRenderPropValue> {
    focused: boolean;
    smallLabel: boolean;
    layout: LayoutRectangle;
}

export const FloatingLabelAnimation = ({ smallLabel, layout, children }: FloatingLabelAnimationProps) => {
    const labelProgress = useDerivedValue(() => withSpring(Number(smallLabel), springConfig.default));

    const animatedStyle = useAnimatedStyle(
        () => ({
            transform: [
                { translateY: layout.height / 2 },
                { translateX: -layout.width / 2 },
                {
                    // 0.69 = small line height (16) / large line height (23)
                    scale: interpolate(labelProgress.value, [0, 1], [1, 0.69]),
                },
                {
                    // -21 = -(large line height (23) / small line height (16) * to padding (15))
                    translateY: interpolate(labelProgress.value, [0, 1], [0, -21]),
                },
                { translateX: layout.width / 2 },
                { translateY: -layout.height / 2 },
            ],
        }),
        [layout.width, layout.height, smallLabel],
    );

    return children(animatedStyle);
};
