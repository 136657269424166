import { CognitoUser } from 'amazon-cognito-identity-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { SEO } from '~/components/wrappers';
import { useAuth } from '~/enhancers/auth.context';
import { useNotifications } from '~/enhancers/notifications.context';
import { AuthLayout } from '~/layouts/authLayout';
import { ResendCodeLabel } from '~/screens/auth/components/ResendCodeLabel';
import { getErrorMessage } from '~/utils/authErrorMessage';

import { AuthFormLayout, BottomAction } from '../layouts/authFormLayout';
import { notificationMessage } from './NewPasswordScreen.constants';
import { NewPasswordScreenProps } from './NewPasswordScreen.types';
import { NewPasswordForm } from './components/NewPasswordForm';
import { FormValues } from './components/NewPasswordForm/NewPasswordForm.types';

export const NewPasswordScreen: React.FC<NewPasswordScreenProps> = ({ route, navigation }) => {
    const theme = useTheme();
    const auth = useAuth();
    const userEmail = route.params?.email || '';
    const { forgotPassword, forgotPasswordSubmit, resendCodeCountdown } = useAuth();
    const { addNotification } = useNotifications();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const isChallenge = auth.newPasswordConfirmation && typeof auth.newPasswordConfirmation !== 'boolean';

    useEffect(() => {
        if (!userEmail.length || !auth.newPasswordConfirmation) {
            setTimeout(() => {
                navigation.navigate('SignIn');
            });
        }
    }, [auth.newPasswordConfirmation, navigation, userEmail.length]);

    const handleResendCodeErrors = useCallback(
        (e: any) => {
            const error = e as { code: string };

            if (error.code === 'UserNotFoundException') {
                addNotification({
                    type: 'informative',
                    message: notificationMessage.resendCodeSuccess,
                });

                return;
            }

            addNotification({ message: getErrorMessage(e), type: 'error' });
        },
        [addNotification],
    );

    const handleResendCode = useCallback(async () => {
        setErrorMessage(undefined);
        try {
            await forgotPassword(userEmail);
            addNotification({ message: notificationMessage.resendCodeSuccess, type: 'informative' });
        } catch (e) {
            handleResendCodeErrors(e);
        } finally {
            resendCodeCountdown.start();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail]);

    const handleSubmitErrors = useCallback(
        (e: any) => {
            const error = e as { code: string };

            if (error.code === 'LimitExceededException') {
                addNotification({
                    message: notificationMessage.tooManyAttempts,
                    type: 'error',
                });

                return;
            }

            setErrorMessage(
                getErrorMessage(e, {
                    UserNotFoundException: notificationMessage.invalidCode,
                }),
            );
        },
        [addNotification],
    );

    const handleSubmit: SubmitHandler<FormValues> = useCallback(
        async (values) => {
            setErrorMessage(undefined);
            try {
                if (isChallenge) {
                    await auth.completeNewPasswordChallenge(
                        auth.newPasswordConfirmation as CognitoUser,
                        values.password,
                    );
                    return;
                }

                await forgotPasswordSubmit(userEmail, values.code, values.password);
                navigation.navigate('SignIn');
                auth.setNewPasswordConfirmation(false);
                addNotification({ message: notificationMessage.newPasswordSuccess, type: 'informative' });
            } catch (e) {
                console.log(e);
                handleSubmitErrors(e);
            } finally {
                setLoading(false);
            }
        },
        [addNotification, auth, forgotPasswordSubmit, handleSubmitErrors, isChallenge, navigation, userEmail],
    );

    const handleCancel = useCallback(() => {
        if (isChallenge) {
            auth.setNewPasswordConfirmation(false);
            auth.signOut;
        }
        navigation.navigate('SignIn');
    }, [auth, isChallenge, navigation]);

    const layoutImageSrc = useMemo(() => {
        return theme.isWebDesktop
            ? require('~/assets/images/verification_code_web.webp')
            : require('~/assets/images/verification_code.png');
    }, [theme.isWebDesktop]);

    return (
        <AuthLayout imageSrc={layoutImageSrc}>
            <SEO title="Nowe hasło" />
            <AuthFormLayout
                title={isChallenge ? 'Ustaw nowe hasło' : 'Wpisz kod weryfikacyjny i ustaw nowe hasło'}
                subtitle={
                    !isChallenge && userEmail
                        ? `Wysłano wiadomość z kodem weryfikacyjnym na ${route.params?.email || ''}`
                        : 'Po pierwszym zalogowaniu przy użyciu tymczasowego hasła, konieczna jest jego zmiana.'
                }
                disabled={loading}
                error={errorMessage}
                bottomActions={
                    [
                        !isChallenge && {
                            id: 'resend-code',
                            label: (
                                <ResendCodeLabel
                                    disabled={resendCodeCountdown.disabled}
                                    timeRemaining={resendCodeCountdown.timeRemaining}
                                />
                            ),
                            onPress: handleResendCode,
                            disabled: resendCodeCountdown.disabled,
                        },
                        {
                            id: 'cancel',
                            label: 'Anuluj',
                            onPress: handleCancel,
                        },
                    ].filter(Boolean) as BottomAction[]
                }
            >
                <NewPasswordForm onSubmit={handleSubmit} challenge={isChallenge} />
            </AuthFormLayout>
        </AuthLayout>
    );
};
