import { addDays, endOfMonth } from 'date-fns';

import { ProgressLineChartItem } from './ProgressLineChart.types';

const initialDate = endOfMonth(new Date());

export const EMPTY_STATE_DATA: ProgressLineChartItem[] = [
    { date: addDays(initialDate, -8), value: 100 },
    { date: addDays(initialDate, -7), value: 90 },
    { date: addDays(initialDate, -6), value: 95 },
    { date: addDays(initialDate, -5), value: 90 },
    { date: addDays(initialDate, -4), value: 80 },
    { date: addDays(initialDate, -3), value: 85 },
    { date: addDays(initialDate, -2), value: 75 },
    { date: addDays(initialDate, -1), value: 70 },
    { date: initialDate, value: 60 },
];
