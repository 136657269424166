import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    border-radius: 16px;
    padding: 16px 24px;
    border: 2px solid ${({ theme }) => theme.border['12']};
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            min-height: 188px;
            display: block;
            padding: 32px;
        `}
`;

export const Content = styled(View)`
    flex: 1;
    margin-left: 24px;
    display: flex;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-left: 0;
            margin-top: 24px;
            height: auto;
        `}
`;

export const Date = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.ui.regular14};
        `}
`;

export const Target = styled(Text)`
    ${mixins.typography.ui.semiBold16};
    color: ${({ theme }) => theme.text.primary};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-top: 4px;
            ${mixins.typography.ui.semiBold20};
        `}
`;

export const NoTarget = styled(Target)`
    color: ${({ theme }) => theme.text.secondary};
`;

export const Description = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-top: 4px;
            ${mixins.typography.ui.regular14};
        `}
`;
