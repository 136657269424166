import { UnitSuffixProps } from '~/components/unitSuffix';

export const mockedMeal = {
    name: 'Jaglanka na mleku roślinnym z gruszką i jabłkiem',
    imageSrc:
        'https://images.unsplash.com/photo-1564671165093-20688ff1fffa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2766&q=80',
    macros: {
        calories: {
            value: 3150,
            label: 'kcal',
        },
        protein: {
            value: 40,
            unit: 'g' as UnitSuffixProps['unit'],
            label: 'białko',
        },
        carbs: {
            value: 89,
            unit: 'g' as UnitSuffixProps['unit'],
            label: 'węglow.',
        },
        fat: {
            value: 32,
            unit: 'g' as UnitSuffixProps['unit'],
            label: 'tłuszcze',
        },
    },
    ingredients: [
        {
            name: 'Płatki jaglane',
            amount: {
                value: 50,
                unit: 'g' as UnitSuffixProps['unit'],
            },
        },
        {
            name: 'Mleko roślinne',
            amount: {
                value: 300,
                unit: 'g' as UnitSuffixProps['unit'],
            },
        },
        {
            name: 'Gruszka',
            amount: {
                value: 150,
                unit: 'g' as UnitSuffixProps['unit'],
            },
        },
        {
            name: 'Cynamon',
            amount: {
                value: 2,
                unit: 'g' as UnitSuffixProps['unit'],
            },
        },
        {
            name: 'Odżywka białkowa',
            amount: {
                value: 16,
                unit: 'g' as UnitSuffixProps['unit'],
            },
        },
    ],
    steps: [
        'Do rondelka wsypujemy płatki jaglane i zalewamy mlekiem. Gotujemy na małym ogniu, aż płatki będą miękkie. Mieszamy co chwilę.',
        'Jabłko i gruszkę kroimy w kostkę.',
        'Gdy jaglanka zgęstnieje, dodajemy pokrojone owoce, odżywkę białkową i cynamon. Dokładnie mieszamy.',
        'Gotową jaglankę przekładamy do miseczki. Smacznego!',
    ],
};
