import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface TrainingDayButtonAnimationsRenderPropValue {
    containerAnimationStyles: {
        backgroundColor: string;
    };
    titleAnimationStyles: {
        color: string;
    };
    subtitleAnimationStyles: {
        color: string;
    };
}

export interface TrainingDayButtonAnimationsProps
    extends PressableAnimationsProps<TrainingDayButtonAnimationsRenderPropValue> {}

export const TrainingDayButtonAnimation = ({ progress, children }: TrainingDayButtonAnimationsProps) => {
    const theme = useTheme();

    const pressableProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const backgroundColors: Record<string, string> = {
        default: 'transparent',
        hovered: theme.palette.opacity['12'].rgba,
        pressed: theme.palette.opacity['12'].rgba,
    };

    const titleColors: Record<string, string> = {
        default: theme.text.secondary,
        hovered: theme.text.primary,
        pressed: theme.text.primary,
    };

    const subtitleColors: Record<string, string> = {
        default: theme.text.tertiary,
        hovered: theme.text.secondary,
        pressed: theme.text.secondary,
    };

    const containerAnimationStyles = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(
                pressableProgress.value,
                [0, 1, 2],
                [backgroundColors.default, backgroundColors.hovered, backgroundColors.pressed],
            ),
        };
    });

    const titleAnimationStyles = useAnimatedStyle(() => {
        return {
            color: interpolateColor(
                pressableProgress.value,
                [0, 1, 2],
                [titleColors.default, titleColors.hovered, titleColors.pressed],
            ),
        };
    });

    const subtitleAnimationStyles = useAnimatedStyle(() => {
        return {
            color: interpolateColor(
                pressableProgress.value,
                [0, 1, 2],
                [subtitleColors.default, subtitleColors.hovered, subtitleColors.pressed],
            ),
        };
    });

    return children({ containerAnimationStyles, titleAnimationStyles, subtitleAnimationStyles });
};
