import React, { ReactNode, useCallback, useContext, useMemo } from 'react';
import { useUser } from '~/enhancers/user.context';

import { useSounds } from './hooks/useSounds';
import { Sound, SoundName } from './soundPlayer.types';

export interface SoundPlayerContextType {
    sounds: Record<SoundName, Sound>;
    isMuted: boolean;
    mute: () => void;
    unmute: () => void;
}

export const SoundPlayerContext = React.createContext<SoundPlayerContextType>({
    sounds: {} as Record<SoundName, Sound>,
    isMuted: false,
    mute: () => {
        throw new Error('Not implemented');
    },
    unmute: () => {
        throw new Error('Not implemented');
    },
});

export interface SoundPlayerContextProviderProps {
    children: ReactNode;
}

export const SoundPlayerContextProvider: React.FC<SoundPlayerContextProviderProps> = ({ children }) => {
    const user = useUser();
    const isMuted = !!user.settings?.soundsMuted;
    const sounds = useSounds({ isMuted });

    const mute = useCallback(() => {
        user.updateSettings({ soundsMuted: true });
    }, [user]);

    const unmute = useCallback(() => {
        user.updateSettings({ soundsMuted: false });
    }, [user]);

    const value: SoundPlayerContextType = useMemo(
        () => ({
            sounds: sounds,
            isMuted,
            mute,
            unmute,
        }),
        [sounds, isMuted, mute, unmute],
    );

    return <SoundPlayerContext.Provider value={value}>{children}</SoundPlayerContext.Provider>;
};

export const useSoundPlayer = () => useContext(SoundPlayerContext);
