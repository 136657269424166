import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { EmptyScreen } from '~/components/emptyScreen';

export const DietPlanInProgress: FC = () => {
    const theme = useTheme();

    return (
        <EmptyScreen
            icon="total-training-time"
            iconColor={theme.icon.interactive}
            title="Plan jest w trakcie przygotowywania"
            titleColor={theme.text.secondary}
            description="Działam! Dam Ci znać kiedy plan będzie gotowy. Pamiętaj, że zawsze możesz zobaczysz swoje poprzednie plany i z nich korzystać."
        />
    );
};
