import { ModelInit } from '@aws-amplify/datastore';
import { UserReport } from '@pg/backend';
import { MutationFunction } from '@tanstack/query-core/build/lib/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const createUserReport = (): MutationFunction<UserReport, ModelInit<UserReport>> => async (user) => {
    return await DataStore.save(new UserReport(user));
};

export const useCreateUserReport = (options?: UseMutationOptions<UserReport, unknown, ModelInit<UserReport>>) =>
    useMutation<UserReport, unknown, ModelInit<UserReport>>(createUserReport(), options);
