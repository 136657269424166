import { Pressable, Text, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { css } from 'styled-components';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const TopContainer = styled(Animated.View)`
    width: 100%;
    padding: 12px 16px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    top: 0;
`;

export const MainContainer = styled(Animated.ScrollView)`
    padding: 80px 0 0;
`;

export const TopContentWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ExerciseWrapper = styled(Pressable)`
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
`;

const BaseButtonWrapperStyles = css`
    padding: 8px;
    border-radius: 12px;
`;

export const CloseButtonWrapper = styled(Pressable)`
    ${BaseButtonWrapperStyles};
    background-color: ${({ theme }) => theme.fill.level4};
`;

export const ExerciseName = styled(Text)`
    ${mixins.typography.ui.medium14}
    color: ${({ theme }) => theme.text.primary};
`;

export const TopContainerTitle = styled(Text)`
    ${mixins.typography.h.semiBold18}
    color: ${({ theme }) => theme.text.primary};
    margin-top: 12px;
`;
