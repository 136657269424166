import { BottomSheetSectionList } from '@gorhom/bottom-sheet';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform, SectionList } from 'react-native';
import {
    Extrapolate,
    interpolate,
    interpolateColor,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { useGetExpandedTrainingDay } from '~/api/training/useGetExpandedTrainingDay';
import { PressableIcon } from '~/components/pressableIcon';
import { useTrainingPlan } from '~/enhancers/trainingPlanContext/trainingPlan.context';
import { usePressableState } from '~/hooks/usePressableState';
import { TrainingPreviewHeader } from '~/screens/training/components/trainingPreviewHeader';
import { getTotalNumberOfSeries, getTotalTime } from '~/utils/training';

import { BottomSheetHandle } from '../bottomSheetHandle';
import { BottomSheetBackdrop, BottomSheetBackdropProps, BottomSheetModal } from '../bottomSheetModal';
import * as S from './TrainingOverviewSheet.styled';
import { DataItem, SectionItem } from './TrainingOverviewSheet.types';
import { transformData } from './trainingOverviewSheet.utils';

interface TrainingDayOverviewSheetProps {
    trainingDayId: string;
    onDismiss: () => void;
    onPlay: () => void;
}

export const TrainingDayOverviewSheet: FC<TrainingDayOverviewSheetProps> = ({ trainingDayId, onDismiss, onPlay }) => {
    const { data } = useGetExpandedTrainingDay(trainingDayId);
    const trainingPlan = useTrainingPlan();
    const duration = useMemo(() => getTotalTime(data), [data]);
    const totalNumberOfSeries = useMemo(() => getTotalNumberOfSeries(data), [data]);

    const trainingCycle = trainingPlan.getTrainingCycleByDayId(trainingDayId);
    const trainingDay = trainingPlan.getTrainingDayById(trainingDayId);

    const transformedData = useMemo(() => transformData(data), [data]);

    const theme = useTheme();
    const Container = theme.isWeb ? SectionList : BottomSheetSectionList;
    const [isContentScrollEnabled, setIsContentScrollEnabled] = useState(false);

    const contentContainerStyle = { paddingBottom: Platform.OS === 'android' ? 100 : 0 };

    const translateY = useSharedValue(0);
    const bottomSheetModalRef = useRef<BottomSheetModal>(null);

    useEffect(() => {
        bottomSheetModalRef.current?.present();
    }, []);

    const scrollHandler = useAnimatedScrollHandler((event) => {
        translateY.value = event.contentOffset.y;
    });

    const topTextWrapperStyle = useAnimatedStyle(() => {
        return {
            opacity: interpolate(translateY.value, [0, 100], [0, 1], Extrapolate.CLAMP),
        };
    });

    const topWrapperStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(translateY.value, [0, 100], [theme.fill.level2, theme.fill.level3]),
        };
    });

    const snapPoints = useMemo(() => ['50%', '90%'], []);

    const pressableState = usePressableState();

    const handleBottomSheetChange = useCallback((index: number) => {
        setIsContentScrollEnabled(index === 1);
    }, []);

    const renderBackdrop = useCallback(
        ({ handleKeyUpdate, ...props }: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop
                {...props}
                appearsOnIndex={0}
                disappearsOnIndex={-1}
                opacity={0.8}
                onPress={() => {
                    onDismiss();
                    handleKeyUpdate();
                }}
            />
        ),
        [onDismiss],
    );

    const renderSectionHeader = useCallback(
        ({ section }: { section: SectionItem }) => (
            <S.SectionHeaderContainer key={section.name}>
                {section.isFirstOfGroupType && <S.SectionHeader>{section.groupTitle}</S.SectionHeader>}
                {section.groupType === 'EXERCISE' && (
                    <S.SectionSubHeader withPadding={section.isFirstOfGroupType}>{section.name}</S.SectionSubHeader>
                )}
            </S.SectionHeaderContainer>
        ),
        [],
    );

    const renderItem = useCallback(
        ({ item }: { item: DataItem }) => (
            <S.ContentWrapper key={item.id}>
                <S.ExerciseName>{item.name}</S.ExerciseName>
                <S.ExerciseData>{item.sets ?? item.time ?? ''}</S.ExerciseData>
            </S.ContentWrapper>
        ),
        [],
    );

    const renderHandle = () => (
        <S.TopContainer style={topWrapperStyle}>
            <S.TopHandleWrapper>
                <BottomSheetHandle />
            </S.TopHandleWrapper>
            <S.TopContentWrapper>
                <S.TrainingTitleTopWrapper style={topTextWrapperStyle}>
                    <S.CycleTitleTop numberOfLines={1}>{trainingCycle?.shortTitle} /&nbsp;</S.CycleTitleTop>
                    <S.TrainingTitleTop numberOfLines={1} ellipsizeMode="tail">
                        {trainingDay?.title}
                    </S.TrainingTitleTop>
                </S.TrainingTitleTopWrapper>
                <S.ButtonWrapper>
                    <S.PlayButtonWrapper onPress={onPlay}>
                        <PressableIcon
                            pressedColor={''}
                            pressableAnimationProgress={pressableState.pressableAnimationProgress}
                            name="play"
                            size={20}
                        />
                    </S.PlayButtonWrapper>

                    <S.CloseButtonWrapper onPress={onDismiss}>
                        <PressableIcon
                            pressedColor={''}
                            pressableAnimationProgress={pressableState.pressableAnimationProgress}
                            name="close"
                            size={20}
                        />
                    </S.CloseButtonWrapper>
                </S.ButtonWrapper>
            </S.TopContentWrapper>
        </S.TopContainer>
    );

    const renderHeader = () => (
        <>
            <S.TrainingTitleInsideWrapper>
                <S.CycleTitleInside>{trainingCycle?.shortTitle} / </S.CycleTitleInside>
                <S.TrainingTitleInside>{trainingDay?.title}</S.TrainingTitleInside>
            </S.TrainingTitleInsideWrapper>
            <S.TrainingHeaderWrapper>
                <TrainingPreviewHeader duration={duration} series={totalNumberOfSeries} />
            </S.TrainingHeaderWrapper>
        </>
    );

    return (
        <BottomSheetModal
            ref={bottomSheetModalRef}
            index={0}
            snapPoints={snapPoints}
            backdropComponent={renderBackdrop}
            handleComponent={renderHandle}
            handleIndicatorStyle={{ backgroundColor: theme.border[20] }}
            enableContentPanningGesture={theme.isWeb ? !isContentScrollEnabled : true}
            backgroundStyle={{ backgroundColor: theme.fill.level2 }}
            onDismiss={onDismiss}
            onChange={handleBottomSheetChange}
        >
            <Container
                sections={transformedData ?? []}
                keyExtractor={(item) => item.id}
                renderItem={renderItem}
                renderSectionHeader={renderSectionHeader}
                stickySectionHeadersEnabled={false}
                ListHeaderComponent={renderHeader}
                renderScrollComponent={(props) => {
                    return (
                        <S.MainContainer
                            {...props}
                            onScroll={scrollHandler}
                            contentInset={{ bottom: 100 }}
                            //Workaround on Android ascontentInset and contentOffset is not working on Android (https://github.com/facebook/react-native/issues/30533)
                            contentContainerStyle={contentContainerStyle}
                            scrollEnabled={isContentScrollEnabled}
                        />
                    );
                }}
            />
        </BottomSheetModal>
    );
};
