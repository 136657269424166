import { Text, View } from 'react-native';
import { css } from 'styled-components';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 450px;
    height: 500px;
`;

export const Spacer = styled(View)`
    width: 20px;
    height: 20px;
`;

export const Header = styled(View)`
    background-color: ${({ theme }) => theme.fill.level2};
    width: 100%;
    padding: 10px 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-color: ${({ theme }) => theme.border[12]};
`;

export const TrainingPreviewHeaderWrapper = styled(View)`
    padding: 16px 16px 0;
`;

export const SectionHeaderContainer = styled(View)`
    padding: 24px 16px 8px;
`;

export const SectionHeader = styled(Text)`
    ${mixins.typography.h.semiBold20}
    color: ${({ theme }) => theme.text.primary};
`;

export const SectionSubHeader = styled(Text)<{ withPadding: boolean }>`
    ${mixins.typography.h.medium16}
    color: ${({ theme }) => theme.text.secondary};
    
    ${({ withPadding }) =>
        withPadding &&
        css`
            padding-top: 16px;
        `})}
`;

export const ContentWrapper = styled(View)`
    margin: 0 16px;
    padding: 8px 0;
`;

export const ExerciseName = styled(Text)`
    ${mixins.typography.ui.medium14}
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 4px;
`;

export const ExerciseSets = styled(Text)`
    ${mixins.typography.ui.regular12}
    color: ${({ theme }) => theme.text.secondary};
`;

export const TrainingTitleWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
`;

export const TrainingTitle = styled(Text)`
    ${mixins.typography.h.semiBold20}
    color: ${({ theme }) => theme.text.primary};
`;

export const CycleTitle = styled(Text)`
    ${mixins.typography.h.semiBold20}
    color: ${({ theme }) => theme.text.secondary};
`;
