import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { PaginationStyledProps } from './Pagination.types';

export const Container = styled(View)<PaginationStyledProps>`
    display: flex;
    flex-direction: row;
    align-items: center;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            min-width: 100%;
            justify-content: space-between;
        `}
`;

export const ButtonsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PrevButton = styled(View)`
    margin-right: 4px;
`;

export const Label = styled(Text)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.tertiary};
    margin: 0 12px;
    display: flex;
    flex-direction: row;
    user-select: none;
`;

const pageStyles = css`
    min-width: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const ActivePage = styled(Text)`
    color: ${({ theme }) => theme.text.primary};
    ${pageStyles};
`;

export const TotalPages = styled(Text)`
    ${pageStyles};
`;

export const NextButton = styled(View)`
    margin-left: 4px;
`;
