import React, { FC, ReactNode } from 'react';
import { View } from 'react-native';

import * as S from './FormControl.styled';

export interface FormControlProps {
    error?: string;
    children: ReactNode;
}

export const FormControl: FC<FormControlProps> = ({ error, children }) => (
    <View>
        <View>{children}</View>
        {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </View>
);
