type Link = {
    label: string;
    screen: string;
    stack: string;
};

export const links: Link[] = [
    {
        label: 'Informacje podstawowe',
        screen: 'More',
        stack: 'Tab',
    },
    {
        label: 'Badania',
        screen: 'HealthTests',
        stack: 'MoreStack',
    },
    {
        label: 'Aplikacja mobilna',
        screen: 'MobileApp',
        stack: 'MoreStack',
    },
    {
        label: 'Ustawienia aplikacji',
        screen: 'Settings',
        stack: 'MoreStack',
    },
    {
        label: 'Historia zamówień',
        screen: 'OrdersHistory',
        stack: 'MoreStack',
    },
    {
        label: 'Ustawienia konta',
        screen: 'AccountSettings',
        stack: 'MoreStack',
    },
    {
        label: 'Zgłoś błąd lub sugestię',
        screen: 'Report',
        stack: 'MoreStack',
    },
];
