import { format } from 'date-fns';
import { Ref, forwardRef } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { TabAnimations } from '~/components/tabs/components/tab/Tab.animated';
import { usePressableState } from '~/hooks/usePressableState';

import { ShippingListDateRange } from '../../ShoppingListScreen.types';
import * as S from './DateRangeButton.styled';

type DateRangeButtonProps = {
    active: boolean;
    range: ShippingListDateRange;
    onPress: () => void;
    style?: StyleProp<ViewStyle>;
};

const AnimatedContainer = Animated.createAnimatedComponent(S.PressableContainer);

export const DateRangeButton = forwardRef(({ active, range, onPress, style }: DateRangeButtonProps, ref: Ref<View>) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();
    const { startingDay, endingDay } = range;

    const renderLeftText = () => {
        if (startingDay) {
            return <S.Value>{format(new Date(startingDay), 'EEEEEE, d MMM')}</S.Value>;
        }

        return <S.Value>nie wybrano</S.Value>;
    };

    const renderRightText = () => {
        if (endingDay) {
            return <S.Value>{format(new Date(endingDay), 'EEEEEE, d MMM')}</S.Value>;
        }

        return <S.Value>nie wybrano</S.Value>;
    };

    return (
        <TabAnimations active={active} appearance={'primary'} progress={pressableAnimationProgress}>
            {({ containerAnimatedStyles }) => (
                <AnimatedContainer
                    onPress={onPress}
                    style={[style, containerAnimatedStyles]}
                    ref={ref}
                    {...pressableProps}
                >
                    <Icon name="training history" color={theme.icon.tertiary} size={20} />
                    <S.TextContainer>
                        <S.Label>Zakres dat</S.Label>
                        <S.Value>
                            {renderLeftText()} - {renderRightText()}
                        </S.Value>
                    </S.TextContainer>
                </AnimatedContainer>
            )}
        </TabAnimations>
    );
});
