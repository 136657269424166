import { Pressable, StyleSheet, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';
import { HoverableProps } from '~/types/hoverable';

export const shadowStyles = StyleSheet.create({
    dropdownMenu: {
        shadowOpacity: 0.5,
        shadowRadius: 32,
        shadowOffset: {
            height: 12,
            width: 0,
        },
        shadowColor: 'black',
    },
});

export const DropdownMenuContainer = styled(View)`
    padding: 8px;
    background: ${({ theme }) => theme.fill.level1};
    border: 1px solid ${({ theme }) => theme.palette.opacity['12'].rgba};
    border-radius: 12px;
`;

interface ButtonHoverableStyledProps extends HoverableProps {}

export const DropdownMenuItemContainer = styled(Pressable)<ButtonHoverableStyledProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
`;

export const DropdownMenuItemIcon = styled(View)`
    margin-right: 12px;
`;

export const DropdownMenuItemLabel = styled(Text)`
    ${mixins.typography.ui.medium14};
`;
