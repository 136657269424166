import { useEffect, useState } from 'react';
import { TabType } from '~/components/tabs';
import { mockedMeal } from '~/screens/diet/meal/hooks/tmpData';

const tabs: TabType<string>[] = [
    {
        label: 'Dzień treningowy',
        value: 'trainingDay',
    },
    {
        label: 'Dzień nietreningowy',
        value: 'nonTrainingDay',
    },
];

interface MealProps {
    id?: string | null;
}

export const useMeal = ({ id }: MealProps) => {
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [id]);

    return {
        isLoading,
        isError: false,
        meal: id ? mockedMeal : null,
        activeTab,
        setActiveTab,
        tabs,
    };
};
