import { yupResolver } from '@hookform/resolvers/yup';
import { Exercise, ExerciseData, FinishedSeriesData } from '@pg/backend';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from '~/enhancers/user.context';
import { useDebouncedSubmit } from '~/hooks/useDebouncedSubmit';
import { TrainingScreenFormFinishedExercise, useTrainingScreen } from '~/screens/training/TrainingScreen.context';
import { TrainingScreenNextExercise } from '~/screens/training/TrainingScreen.types';
import { ExerciseInput } from '~/screens/training/components/exerciseInput';
import { SeriesTableRowFormSchema } from '~/screens/training/components/seriesTableRowForm/SeriesTableRowForm.schema';
import { TrainingCheck } from '~/screens/training/components/trainingCheck';

import { useTimer } from '../../enhancers/timer.context';
import * as S from './SeriesTableRowForm.styled';

export interface SeriesTableRowProps {
    trainingExerciseId: string;
    data: ExerciseData;
    exercise: Exercise;
    finishedData: FinishedSeriesData | false;
    previousCycleFinishedData: FinishedSeriesData | false;
    index: number;
    maxSeries: number;
    last?: boolean;
    isSuperSet?: boolean;
    nextExercise?: TrainingScreenNextExercise;
    prevExerciseForSuperset: TrainingScreenNextExercise | null;
}

export const SeriesTableRowForm: FC<SeriesTableRowProps> = ({
    data,
    index,
    maxSeries,
    nextExercise,
    last,
    trainingExerciseId,
    isSuperSet,
    prevExerciseForSuperset,
    finishedData,
    previousCycleFinishedData,
    ...props
}) => {
    const trainingScreen = useTrainingScreen();
    const { settings } = useUser();
    const exerciseData = data as ExerciseData;
    const exercise = props.exercise as Exercise;
    const initialData = finishedData as FinishedSeriesData;
    const prevCycleData = previousCycleFinishedData as FinishedSeriesData;

    const { watch, control, getValues, handleSubmit, reset, setValue } = useForm<TrainingScreenFormFinishedExercise>({
        defaultValues: {
            reps: Number(initialData?.reps ?? exerciseData?.reps) ?? '',
            weight: Number(initialData?.weight ?? exerciseData?.weight) ?? '',
            done: !!initialData?.done,
            seriesNumber: index,
        },
        resolver: yupResolver(SeriesTableRowFormSchema),
        mode: 'onChange',
    });

    const done = watch('done');

    const onSubmit: SubmitHandler<TrainingScreenFormFinishedExercise> = useCallback(
        async (formValues) => {
            try {
                await trainingScreen.updateSeriesData(trainingExerciseId, formValues);
            } catch (e) {}
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [reset, trainingExerciseId, trainingScreen],
    );

    const onSubmitDebounced = useDebouncedSubmit<SubmitHandler<TrainingScreenFormFinishedExercise>>((...args) =>
        onSubmit(...args),
    );

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmitDebounced)();
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [handleSubmit, onSubmit, watch, onSubmitDebounced]);

    useEffect(() => {
        if (!settings?.usePreviousCycleWeights) {
            return;
        }

        //Use previous cycle data when current is empty and it's not done
        if (!initialData?.done && prevCycleData?.weight && !initialData.weight) {
            console.log('set!!!!');
            setValue('weight', prevCycleData?.weight);
            // handleSubmit(onSubmitDebounced)();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevCycleData?.weight, initialData?.done, initialData?.weight, setValue, handleSubmit]);

    const timer = useTimer();

    const startRestTimer = () => {
        if (!last && prevExerciseForSuperset && index + 1 < prevExerciseForSuperset.numberOfSeries!) {
            timer.startRest({
                id: `${exercise.id}.${index}`,
                duration: exerciseData.stop!,
                title: prevExerciseForSuperset?.name ?? '',
                subtitle: 'Następne ćwiczenie',
                series: `Seria ${index + 2} z ${prevExerciseForSuperset.numberOfSeries}`,
            });
            return;
        }

        if (isSuperSet) {
            timer.startRest({
                id: `${exercise.id}.${index}`,
                duration: exerciseData.stop!,
                title: nextExercise?.name ?? '',
                subtitle: 'Następne ćwiczenie',
                series: `Seria ${index + 1} z ${nextExercise?.numberOfSeries!}`,
            });
            return;
        }

        if (!last) {
            timer.startRest({
                id: `${exercise.id}.${index}`,
                duration: exerciseData.stop!,
                title: exercise.name ?? '',
                subtitle: 'Następne ćwiczenie',
                series: `Seria ${index + 2} z ${maxSeries}`,
            });
            return;
        }

        timer.startRest({
            id: `${exercise.id}.${index}`,
            duration: exerciseData.stop!,
            title: nextExercise?.name ?? '',
            subtitle: 'Następne ćwiczenie',
            series: nextExercise?.numberOfSeries
                ? `Seria 1 z ${nextExercise.numberOfSeries}`
                : `${nextExercise?.time!}min`,
        });
    };

    return (
        <S.SeriesTableRow key={`series-${index}`}>
            <S.SeriesNumberCell filled={false}>{index + 1}</S.SeriesNumberCell>

            <S.SeriesInputCell>
                <Controller
                    name="reps"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <ExerciseInput
                            {...field}
                            value={`${field.value}`}
                            filled={done}
                            keyboardType="number-pad"
                            onChangeText={onChange}
                            type="reps"
                        />
                    )}
                />
            </S.SeriesInputCell>

            <S.SeriesInputCell>
                <Controller
                    name="weight"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <ExerciseInput
                            {...field}
                            placeholder="kg"
                            value={`${field.value}`}
                            filled={done}
                            keyboardType="number-pad"
                            onChangeText={onChange}
                            type="weight"
                        />
                    )}
                />
            </S.SeriesInputCell>

            <S.SeriesCheckboxCell>
                <Controller
                    name="done"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TrainingCheck
                            checked={value}
                            onChange={(checked: boolean) => {
                                onChange(checked);

                                if (checked) {
                                    startRestTimer();
                                } else {
                                    timer.close();
                                }
                            }}
                        />
                    )}
                />
            </S.SeriesCheckboxCell>
        </S.SeriesTableRow>
    );
};
