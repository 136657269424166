import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const PressableContainer = styled(Pressable)`
    flex-direction: row;
    align-items: center;
    border-width: 1.5px;
    border-color: ${({ theme }) => theme.border[12]};
    background-color: ${({ theme }) => theme.fill.background};
    padding: 4px 10px;
    border-radius: 8px;
    shadow-color: #000;
    shadow-offset: 0px 1px;
    shadow-opacity: 0.3;
    shadow-radius: 1px;
`;

export const TextContainer = styled(View)`
    margin-left: 10px;
    min-width: 206px;
`;

export const Label = styled(Text)`
    ${mixins.typography.ui.regular12}
    color: ${({ theme }) => theme.text.tertiary};
`;

export const Value = styled(Text)`
    ${mixins.typography.ui.medium16}
    color: ${({ theme }) => theme.text.primary};
`;
