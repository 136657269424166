import throttle from 'lodash.throttle';
import { forwardRef, useCallback, useMemo } from 'react';
import { ScrollView } from 'react-native';
import { interpolateColor, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { BottomSheetHandle } from '../bottomSheetHandle';
import {
    BottomSheetBackdrop,
    BottomSheetBackdropProps,
    BottomSheetHandleProps,
    BottomSheetModal,
    BottomSheetScrollView,
} from '../bottomSheetModal';
import * as S from './MeasurementsInstructionsSheet.native.styled';
import { Instructions } from './components/instructions';

export interface MeasurementsInstructionsSheetProps {
    onClose?: () => void;
}

export const MeasurementsInstructionsSheet = forwardRef<BottomSheetModal | null, MeasurementsInstructionsSheetProps>(
    ({ onClose }, ref) => {
        const theme = useTheme();
        const pressableState = usePressableState();
        const inset = useSafeAreaInsets();
        const handleClose = useMemo(() => (onClose ? throttle(onClose, 1000, { trailing: false }) : null), [onClose]);
        const translateY = useSharedValue(0);

        const handleDismiss = useCallback(() => {
            if (handleClose) {
                handleClose();
                return;
            }
        }, [handleClose]);

        const renderBackdrop = useCallback(
            ({ handleKeyUpdate, ...props }: BottomSheetBackdropProps) => (
                <BottomSheetBackdrop
                    {...props}
                    onPress={() => {
                        handleDismiss();
                        handleKeyUpdate();
                    }}
                    appearsOnIndex={0}
                    disappearsOnIndex={-1}
                    opacity={0.8}
                />
            ),
            [handleDismiss],
        );

        const topWrapperStyle = useAnimatedStyle(() => {
            return {
                backgroundColor: interpolateColor(translateY.value, [0, 100], [theme.fill.level2, theme.fill.level3]),
            };
        });

        const renderHandle = ({ handleKeyUpdate }: BottomSheetHandleProps) => (
            <S.TopContainer style={topWrapperStyle}>
                {!theme.isWeb && <BottomSheetHandle />}
                <S.TopContentWrapper>
                    <S.TopContainerTitle>Jak się mierzyć?</S.TopContainerTitle>
                    <S.CloseButtonWrapper
                        onPress={() => {
                            handleDismiss();
                            handleKeyUpdate();
                        }}
                    >
                        <PressableIcon
                            pressedColor={''}
                            pressableAnimationProgress={pressableState.pressableAnimationProgress}
                            name="close"
                            size={20}
                        />
                    </S.CloseButtonWrapper>
                </S.TopContentWrapper>
            </S.TopContainer>
        );

        const ScrollableContent = theme.isWeb ? ScrollView : BottomSheetScrollView;

        return (
            <BottomSheetModal
                ref={ref}
                snapPoints={['95%']}
                backdropComponent={renderBackdrop}
                handleComponent={renderHandle}
                backgroundStyle={{ backgroundColor: theme.fill.level2 }}
                topInset={inset.top || 50}
                enablePanDownToClose={!theme.isWeb}
            >
                <S.Container>
                    <ScrollableContent showsVerticalScrollIndicator={false}>
                        <Instructions />
                    </ScrollableContent>
                </S.Container>
            </BottomSheetModal>
        );
    },
);
