import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

import { BottomSheetModal } from '../bottomSheetModal';

export const BottomSheet = styled(BottomSheetModal)`
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
`;

export const HandleContainer = styled(View)`
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 0;
    padding: 8px 0;
`;

export const Container = styled(View)`
    padding: 32px;
`;

export const Description = styled(Text)`
    ${mixins.typography.h.regular20}
    color: ${({ theme }) => theme.text.primary};
    margin: 32px 0;
`;
