import { Image as ImageComponent, ScrollView, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';

import { AuthLayoutProps, ContainerProps } from './AuthLayout.types';

export const Container = styled(View)<ContainerProps>`
    flex: 1;
    padding-top: ${({ inset, theme }) => (!theme.isWebDesktop && inset?.top) || 0}px;
    background: ${({ theme }) => theme.fill.background};
    height: 100%;
    position: relative;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            flex-direction: row;
        `}
`;

const imageContainerSizeStyles: Record<
    NonNullable<AuthLayoutProps['nativeImageSize']>,
    FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
    normal: css`
        height: 126px;
        position: relative;
        justify-content: center;
    `,
    large: css`
        height: 196px;
        position: relative;
        justify-content: center;
    `,
};

export const ImageContainer = styled(View)<Pick<AuthLayoutProps, 'nativeImageSize'>>`
    ${({ nativeImageSize = 'normal', theme }) => !theme.isWebDesktop && imageContainerSizeStyles[nativeImageSize]}

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            height: 100%;
            width: calc(50% + 30px);
            right: 0;
            top: 0;
        `};
`;

const imageSizeStyles: Record<
    NonNullable<AuthLayoutProps['nativeImageSize']>,
    FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
    normal: css`
        height: 100px;
        width: auto;
    `,
    large: css`
        height: 146px;
        width: auto;
    `,
};

export const Image = styled(ImageComponent)<Pick<AuthLayoutProps, 'webImagePosition' | 'nativeImageSize'>>`
    ${({ nativeImageSize = 'normal', theme }) => !theme.isWebDesktop && imageSizeStyles[nativeImageSize]}

    ${({ theme, webImagePosition }) =>
        theme.isWebDesktop &&
        css`
            width: ${webImagePosition === 'full' ? '100%' : '324px'};
            height: ${webImagePosition === 'full' ? '100%' : '324px'};
        `}
`;

export const LogoContainer = styled(View)`
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const Content = styled(ScrollView)`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.fill.level1};

    ${({ theme }) =>
        !theme.isWebDesktop &&
        css`
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 28px 32px;
        `}

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 816px;
            max-width: 50%;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        `}
`;

export const Inner = styled(View)`
    ${({ theme }) =>
        !theme.isWebDesktop &&
        css`
            margin-bottom: 56px;
        `}
`;
