import { ReactNode, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import * as S from './Accordion.styled';

type AccordionProps = {
    title: string;
    children: ReactNode;
};

export const Accordion = ({ title, children }: AccordionProps) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const handlePress = () => {
        setIsOpen((open) => !open);
    };

    return (
        <S.Container>
            <S.ExpandPressable onPress={handlePress}>
                <S.Title>{title}</S.Title>
                <Icon color={theme.icon.secondary} name={isOpen ? 'chevron-up' : 'chevron-down'} size={24} />
            </S.ExpandPressable>
            {isOpen && <S.ListContainer>{children}</S.ListContainer>}
        </S.Container>
    );
};
