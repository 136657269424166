import { Measurement } from '@pg/backend';
import { TableHeaders } from '~/components/table';
import { formatDate } from '~/utils/dates';

import { MeasurementsCell } from '../../components/measurementsCell';

interface GetTableHeadersProps {
    isWebDesktop?: boolean;
}

export const getTableHeaders = ({ isWebDesktop = false }: GetTableHeadersProps): TableHeaders<Measurement> => {
    return [
        {
            id: 'date',
            label: 'Data pomiaru',
            alignment: 'left',
            width: isWebDesktop ? 1 : 25,
            render: ({ date }) => formatDate(date),
        },
        {
            id: 'weight',
            label: 'Waga',
            alignment: 'left',
            width: isWebDesktop ? 1 : 20,
            render: (measurement) => <MeasurementsCell measurement={measurement} measureType="weight" />,
        },
        {
            id: 'waist',
            label: 'Obwód pasa',
            alignment: 'left',
            width: isWebDesktop ? 1 : 20,
            render: (measurement) => <MeasurementsCell measurement={measurement} measureType="waist" />,
        },
        {
            id: 'hips',
            label: 'Obwód bioder',
            alignment: 'left',
            width: isWebDesktop ? 1 : 20,
            render: (measurement) => <MeasurementsCell measurement={measurement} measureType="hips" />,
        },
        {
            id: 'arm',
            label: 'Obwód ramienia',
            alignment: 'left',
            width: isWebDesktop ? 1 : 20,
            render: (measurement) => <MeasurementsCell measurement={measurement} measureType="arm" />,
        },
        {
            id: 'thigh',
            label: 'Obwód uda',
            alignment: 'left',
            width: isWebDesktop ? 1 : 20,
            render: (measurement) => <MeasurementsCell measurement={measurement} measureType="thigh" />,
        },
        {
            id: 'chest',
            label: isWebDesktop ? 'Obwód klatki piersiowej' : 'Obwód kl. piersiowej',
            alignment: 'left',
            width: isWebDesktop ? 1 : 20,
            render: (measurement) => <MeasurementsCell measurement={measurement} measureType="chest" />,
        },
        {
            id: 'calf',
            label: 'Obwód łydki',
            alignment: 'left',
            width: isWebDesktop ? 1 : 20,
            render: (measurement) => <MeasurementsCell measurement={measurement} measureType="calf" />,
        },
    ];
};
