import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts/web';
import { SupplementTile } from '~/screens/supplements/components/supplementTile';
import { SupplementsEmptyScreen } from '~/screens/supplements/components/supplementsEmptyScreen';

import { SupplementsMobileContent } from './SupplementsScreen.native';
import * as S from './SupplementsScreen.styled';
import { SupplementsComingSoon } from './components/supplementsComingSoon';
import { useSupplements } from './hooks/useSupplements';

const title = 'Suplementy';

export const SupplementsScreen: FC = () => {
    const { features } = useAppFeatures();
    const { supplements, isLoading } = useSupplements();

    const theme = useTheme();

    if (!features.supplements) {
        return <MainLayout title={title} noBackground={!theme.isWebDesktop} content={<SupplementsComingSoon />} />;
    }

    if (!theme.isWebDesktop) {
        return <MainLayout title={title} isLoading={isLoading} content={<SupplementsMobileContent />} />;
    }

    const renderContent = () => {
        if (isLoading) {
            return null;
        }

        if (!supplements.length) {
            return <SupplementsEmptyScreen />;
        }

        return (
            <S.SupplementsGrid>
                {supplements.map(({ id, info, supplement }) => (
                    <SupplementTile
                        key={id}
                        name={supplement?.name || ''}
                        info={info || ''}
                        description={supplement?.description || ''}
                    />
                ))}
            </S.SupplementsGrid>
        );
    };

    return <MainLayout title={title} isLoading={isLoading} content={<S.Container>{renderContent()}</S.Container>} />;
};
