import { RefObject } from 'react';
import Animated, {
    interpolate,
    useAnimatedRef,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useScrollViewOffset,
    useSharedValue,
} from 'react-native-reanimated';
import { AnimationsProps } from '~/types/animations';

export interface TableAnimationsRenderPropValue {
    animatedRef: RefObject<Animated.ScrollView>;
    animatedScrollHandler: (event: any) => void;
    styles: {
        stateContainerStyles: {
            transform: { translateX: number }[];
        };
        stickyCellAnimatedStyles: {
            transform: { translateX: number }[];
        };
    };
}

export interface TableAnimationsProps extends AnimationsProps<TableAnimationsRenderPropValue> {
    screenWidth: number;
    stickyCellWidth?: number;
}

export const TableAnimations = ({ children, stickyCellWidth, screenWidth }: TableAnimationsProps) => {
    const animatedRef = useAnimatedRef<Animated.ScrollView>();
    const scrollHandler = useScrollViewOffset(animatedRef);
    const contentSize = useSharedValue(0);

    const animatedScrollHandler = useAnimatedScrollHandler((event) => {
        contentSize.value = event.contentSize.width;
    });

    const stateContainerStyles = useAnimatedStyle(() => ({
        transform: [{ translateX: scrollHandler.value }],
    }));

    const stickyCellAnimatedStyles = useAnimatedStyle(() => {
        if (stickyCellWidth) {
            const translateX = interpolate(
                scrollHandler.value,
                [0, 1],
                [screenWidth - stickyCellWidth, 1 + screenWidth - stickyCellWidth],
            );

            return {
                transform: [{ translateX }],
            };
        }

        return {
            transform: [{ translateX: 0 }],
        };
    });

    return children({
        animatedRef,
        animatedScrollHandler,
        styles: {
            stateContainerStyles,
            stickyCellAnimatedStyles,
        },
    });
};
