import { CardioExercise, CardioExerciseData } from '@pg/backend';
import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { TrainingScreenNextExercise } from '~/screens/training/TrainingScreen.utils';
import { SeriesTableCardioRowForm } from '~/screens/training/components/seriesTableRowForm/SeriesTableCardioRowForm';

import * as S from './SeriesTable.styled';

export interface CardioSeriesTableProps {
    exerciseData: CardioExerciseData;
    finishedData?: CardioExerciseData | null;
    exercise: CardioExercise;
    nextExercise?: TrainingScreenNextExercise;
    trainingCardioExerciseId: string;
}

export const CardioSeriesTable: FC<CardioSeriesTableProps> = ({
    exercise,
    exerciseData,
    trainingCardioExerciseId,
    finishedData,
}) => {
    const theme = useTheme();

    return (
        <S.SeriesTable>
            <S.SeriesTableHeader>
                <S.SeriesNumberCell>S</S.SeriesNumberCell>
                <S.SeriesInputHeaderCell>Czas</S.SeriesInputHeaderCell>
                <S.SeriesCheckboxCell>
                    <Icon name="check-list" size={14} color={theme.icon.tertiary} />
                </S.SeriesCheckboxCell>
            </S.SeriesTableHeader>

            <SeriesTableCardioRowForm
                trainingCardioExerciseId={trainingCardioExerciseId}
                data={exerciseData}
                finishedData={finishedData}
                exercise={exercise}
                index={0}
            />
        </S.SeriesTable>
    );
};
