import { Pressable, Text, View } from 'react-native';
import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { Variant } from './Day.types';

interface WithVariantProps {
    variant?: Variant;
}

const dayTextVariantStylesMap: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    today: css`
        border-radius: 3px;
        background-color: ${({ theme }) => theme.fill.critical};
        width: 28px;
        height: 18px;
        justify-content: center;
    `,
    marked: css`
        color: ${({ theme }) => theme.text.primary};
    `,
    default: css`
        color: ${({ theme }) => theme.text.primary};
    `,
};

const dayWrapperVariantStylesMap: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    today: css``,
    marked: css`
        border: none;
    `,
    default: css`
        border: none;
    `,
};

const iconWrapperVariantStylesMap: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    today: css`
        border-color: #ababff;
        background-color: ${({ theme }) => theme.fill.level5};
    `,
    marked: css`
        border-color: #58f9ab;
        background-color: ${({ theme }) => theme.fill.level5};
    `,
    default: css`
        border-color: transparent;
        background-color: transparent;
    `,
};

export const DayWrapper = styled(Pressable)<WithVariantProps>`
    width: 100%;
    height: auto;
    padding: 6px 0 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    ${({ variant = 'default' }) => dayWrapperVariantStylesMap[variant]};
`;

export const DayText = styled(Text)<WithVariantProps>`
    ${mixins.typography.ui.medium14}
    text-align: center;
    ${({ variant = 'default' }) => dayTextVariantStylesMap[variant]};
`;

export const TodayWrapper = styled(View)`
    border-radius: 3px;
    background-color: ${({ theme }) => theme.fill.critical};
    width: 28px;
    height: 22px;
    justify-content: center;
    align-items: center;
`;

export const IconWrapper = styled(View)<WithVariantProps>`
    margin-top: 8px;

    padding: 4px;
    border-radius: 100px;
    border-width: 1px;
    justify-content: center;
    align-items: center;
    ${({ variant = 'default' }) => iconWrapperVariantStylesMap[variant]};
`;

export const MultipleActivitiesText = styled(Text)`
    ${mixins.typography.ui.medium14}
    color: #58f9ab;
`;
