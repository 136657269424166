import { useNavigation } from '@react-navigation/native';
import { useAuth } from '~/enhancers/auth.context';

import * as S from './NotFound.styled';
import { NotFoundNavigationProp } from './NotFound.types';

export const NotFound = () => {
    const navigation = useNavigation<NotFoundNavigationProp>();
    const auth = useAuth();

    const handleNavigation = () => {
        if (auth.isAuthenticated) {
            navigation.navigate('Home', { screen: 'Tab', params: { screen: 'Dashboard' } });
        }
        navigation.navigate('Auth', { screen: 'SignIn' });
    };

    return (
        <S.Container>
            <S.Wrapper>
                <S.Number>404</S.Number>
                <S.Info>Nie martw się, nie zepsułeś/-aś internetu.</S.Info>
                <S.Disclaimer>Po prostu nie ma strony, której szukasz.</S.Disclaimer>
                <S.Button size="m" onPress={handleNavigation}>
                    Wróć do strony głównej
                </S.Button>
            </S.Wrapper>

            <S.HeroImageWrapper>
                <S.HeroImage source={require('~/assets/images/gluchowski_pointing.webp')} resizeMode="contain" />
            </S.HeroImageWrapper>
        </S.Container>
    );
};
