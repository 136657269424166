import { FC, ReactNode } from 'react';
import { useTheme } from 'styled-components/native';
import { MoreNavigation } from '~/navigation/home/components/moreNavigation';

import * as S from './MoreLayout.styled';

interface MoreLayoutProps {
    children: ReactNode;
}

export const MoreLayout: FC<MoreLayoutProps> = ({ children }) => {
    const { isWebDesktop } = useTheme();

    return (
        <S.Container>
            {isWebDesktop && <MoreNavigation />}
            <S.Children>{children}</S.Children>
        </S.Container>
    );
};
