import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled(View)`
    padding: 0 16px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            padding: 0 32px 24px;
        `}
`;

export const InstructionWrapper = styled(View)`
    width: 100%;
    padding: 16px 0 24px;
`;

export const InstructionsGrid = styled(View)`
    margin-top: 32px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 32px 24px;
`;
