import '@expo/match-media';
import { NavigationContainer, createNavigationContainerRef } from '@react-navigation/native';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setDefaultOptions } from 'date-fns';
import { pl } from 'date-fns/locale';
import * as SplashScreen from 'expo-splash-screen';
import React, { useMemo, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { StatusBar } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ThemeProvider } from 'styled-components/native';
import { AppFeaturesContextProvider } from '~/enhancers/appFeaturesProvider';
import { HealthTestsContextProvider } from '~/enhancers/healthTests.context';

import { BottomSheetModalProvider } from './components/bottomSheet';
import { LocaleConfig } from './constants/calendar';
import { AppContextProvider } from './enhancers/app.context';
import { AuthContextProvider } from './enhancers/auth.context';
import { DietContextProvider } from './enhancers/dietContext';
import { GlobalToastsContextProvider } from './enhancers/globalToasts.context';
import { ImagePickerContextProvider } from './enhancers/imagePicker';
import { MeasurementContextProvider } from './enhancers/measurement.context';
import { NotificationsContextProvider } from './enhancers/notifications.context';
import { SoundPlayerContextProvider } from './enhancers/soundPlayer';
import { ToastsContextProvider } from './enhancers/toasts.context';
import { UserContextProvider } from './enhancers/user.context';
import { useResponsiveTheme } from './hooks/useResponsiveTheme';
import { linking } from './navigation/linking';
import { RootNavigation, RootParamsList } from './navigation/root';
import { Notifications } from './screens/auth/components/Notifications';
import { configureBackend } from './services/backend';
import { Global, defaultTheme } from './theme';

SplashScreen.preventAutoHideAsync();
configureBackend();

LocaleConfig.defaultLocale = 'pl';
setDefaultOptions({ locale: pl });
const queryClient = new QueryClient({});

export default function App() {
    const theme = useResponsiveTheme({ theme: defaultTheme });
    const navigationRef = createNavigationContainerRef<RootParamsList>();
    const [navigation, setNavigation] = useState(navigationRef.current);

    const navigationTheme = useMemo(
        () => ({
            dark: true,
            colors: {
                primary: theme.interactive.primaryDefault,
                background: theme.fill.background,
                card: theme.fill.background,
                text: theme.text.primary,
                border: theme.fill.background,
                notification: theme.interactive.primaryDefault,
            },
        }),
        [theme.fill.background, theme.interactive.primaryDefault, theme.text.primary],
    );

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <SafeAreaProvider>
                    <AuthContextProvider>
                        <NavigationContainer
                            ref={navigationRef}
                            linking={linking}
                            theme={navigationTheme}
                            documentTitle={{ enabled: false }}
                            onReady={() => {
                                setNavigation(navigationRef.current);
                            }}
                        >
                            <AppContextProvider>
                                <AppFeaturesContextProvider>
                                    <UserContextProvider>
                                        <SoundPlayerContextProvider>
                                            <BottomSheetModalProvider>
                                                <GlobalToastsContextProvider navigation={navigation}>
                                                    <HelmetProvider>
                                                        <Global />
                                                        <ImagePickerContextProvider>
                                                            <NotificationsContextProvider>
                                                                <ToastsContextProvider>
                                                                    <MeasurementContextProvider>
                                                                        <DietContextProvider>
                                                                            <HealthTestsContextProvider>
                                                                                <RootNavigation />
                                                                            </HealthTestsContextProvider>
                                                                        </DietContextProvider>
                                                                    </MeasurementContextProvider>
                                                                </ToastsContextProvider>
                                                                <Notifications />
                                                            </NotificationsContextProvider>
                                                        </ImagePickerContextProvider>
                                                    </HelmetProvider>
                                                </GlobalToastsContextProvider>
                                            </BottomSheetModalProvider>
                                        </SoundPlayerContextProvider>
                                    </UserContextProvider>
                                </AppFeaturesContextProvider>
                            </AppContextProvider>
                        </NavigationContainer>
                    </AuthContextProvider>
                </SafeAreaProvider>
                <StatusBar barStyle="light-content" />
            </ThemeProvider>
        </QueryClientProvider>
    );
}
