import React, { FC, ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import { SectionHeading } from '~/components/sectionHeading';

import * as S from './DesktopSettingsHeading.styled';

interface DesktopSettingsHeadingProps {
    title: string;
    sideContent?: ReactNode;
    style?: ViewStyle;
}

export const DesktopSettingsHeading: FC<DesktopSettingsHeadingProps> = ({ title, style, sideContent }) => {
    return (
        <S.Wrapper style={style}>
            <SectionHeading variant="header" title={title} />
            {sideContent && <S.SideContentWrapper>{sideContent}</S.SideContentWrapper>}
        </S.Wrapper>
    );
};
