import React from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { usePressableState } from '~/hooks/usePressableState';

import { MobileMenuTileAnimation } from './MobileMenuTile.animated';
import * as S from './MobileMenuTile.styled';
import { MobileMenuTileProps } from './types';

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const MobileMenuTile: React.FC<MobileMenuTileProps> = ({
    children,
    icon,
    iconColor,
    textVariant = 'primary',
    textAlign = 'left',
    last,
    rightContent,
    ...props
}) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <MobileMenuTileAnimation progress={pressableAnimationProgress}>
            {(animatedStyle) => (
                <AnimatedContainer {...pressableProps} {...props} last={last} style={[props.style, animatedStyle]}>
                    {icon && (
                        <S.IconContainer>
                            <Icon name={icon} size={20} color={iconColor ?? theme.icon.tertiary} />
                        </S.IconContainer>
                    )}
                    <S.TextContent align={textAlign}>
                        <S.Text variant={textVariant}>{children}</S.Text>
                    </S.TextContent>
                    {rightContent && <S.RightContainer>{rightContent}</S.RightContainer>}
                </AnimatedContainer>
            )}
        </MobileMenuTileAnimation>
    );
};
