import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { FC } from 'react';
import { StandardNavigation } from '~/components/topNavigation';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

export interface ActivityDetailsTopNavigationProps extends NativeStackHeaderProps {
    onBackPress?: () => void;
}

export const ActivityDetailsTopNavigation: FC<ActivityDetailsTopNavigationProps> = ({ onBackPress }) => {
    const handleRightPress = () => {
        Emitter.emit(EmitterEvent.openSheet, null);
    };
    return (
        <StandardNavigation
            customLeftIconName="close"
            shouldRenderRightButton
            variant="rightButton"
            rightButtonText="Dodaj aktywność"
            onRightPress={handleRightPress}
            onBackPress={onBackPress}
        />
    );
};
