import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NewPasswordScreen } from '~/screens/auth/newPasswordScreen';
import { PasswordRecoveryScreen } from '~/screens/auth/passwordRecoveryScreen/PasswordRecoveryScreen';
import { SignInScreen } from '~/screens/auth/signInScreen';
import { SignUpConfirmationScreen } from '~/screens/auth/signUpConfirmationScreen';

import { AuthParamsList } from './AuthNavigation.types';

const Stack = createNativeStackNavigator<AuthParamsList>();

export function AuthNavigation() {
    return (
        <Stack.Navigator initialRouteName="SignIn" screenOptions={{ header: () => null }}>
            <Stack.Screen name="SignIn" component={SignInScreen} />
            {/*<Stack.Screen name="SignUp" component={SignUpScreen} />*/}
            <Stack.Screen name="SignUpConfirmation" component={SignUpConfirmationScreen} />
            <Stack.Screen name="PasswordRecovery" component={PasswordRecoveryScreen} />
            <Stack.Screen name="NewPassword" component={NewPasswordScreen} />
        </Stack.Navigator>
    );
}
