import { useNavigation } from '@react-navigation/native';
import { PlanMenuItem } from '~/components/planMenuItem';
import { SEO } from '~/components/wrappers';
import { useDiet } from '~/enhancers/dietContext';
import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { MainLayout } from '~/layouts';
import { DietStackScreens } from '~/navigation/paths';
import { getDateRangeWithPlaceholder } from '~/utils/dates';

import * as S from './DietPlanChoiceScreen.styled';
import { DietPlanChoiceNavigationProp } from './DietPlanChoiceScreen.types';

export const DietPlanChoiceScreen = () => {
    useWebDesktopBlockedScreen(DietStackScreens.screens.PlanChoice);
    const navigation = useNavigation<DietPlanChoiceNavigationProp>();
    const { activePlan, plans, selectActivePlan } = useDiet();

    const handleNavigation = (dietPlanId: string) => {
        selectActivePlan(dietPlanId);
        navigation.navigate('DietStack', {
            screen: 'Settings',
        });
    };

    return (
        <MainLayout isBottomRounded={false}>
            <SEO title="Wybór Planu Dietetycznego" />
            <S.PlanMenuItemsContainer>
                {plans?.map((dietPlan, index) => (
                    <PlanMenuItem
                        key={dietPlan.id}
                        index={plans.length - index}
                        selected={activePlan?.id === dietPlan.id}
                        onPress={() => handleNavigation(dietPlan.id)}
                    >
                        {getDateRangeWithPlaceholder({
                            startDate: dietPlan?.startDate,
                            endDate: dietPlan?.endDate,
                            placeholder: 'W trakcie przygotowywania',
                            dateFormat: 'dd.MM.yyyy',
                        })}
                    </PlanMenuItem>
                ))}
            </S.PlanMenuItemsContainer>
        </MainLayout>
    );
};
