import { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { Button, ButtonProps } from '~/components/button';
import { Icon } from '~/components/icon';
import { IconName } from '~/icons';

import * as S from './EmptyScreen.styled';

interface EmptyScreenProps {
    icon: IconName;
    iconColor?: string;
    title: string;
    titleColor?: string;
    description?: string;
    button?: {
        label: ButtonProps['children'];
        icon?: ButtonProps['icon'];
        variant: ButtonProps['variant'];
        onPress: ButtonProps['onPress'];
    };
    maxContentWidth?: number;
}

export const EmptyScreen: FC<EmptyScreenProps> = ({
    icon,
    iconColor,
    title,
    titleColor,
    description,
    button,
    maxContentWidth,
}) => {
    const theme = useTheme();

    return (
        <S.Container>
            <S.Content maxWidth={maxContentWidth}>
                <S.IconContainer>
                    <Icon name={icon} size={40} color={iconColor || theme.icon.interactive} />
                </S.IconContainer>

                <S.TitleContainer>
                    <S.Title color={titleColor}>{title}</S.Title>
                </S.TitleContainer>

                {description && (
                    <S.DescriptionContainer>
                        <S.Description>{description}</S.Description>
                    </S.DescriptionContainer>
                )}

                {button && (
                    <S.ButtonContainer>
                        <Button size="s" variant={button.variant} onPress={button.onPress} icon={button.icon}>
                            {button.label}
                        </Button>
                    </S.ButtonContainer>
                )}
            </S.Content>
        </S.Container>
    );
};
