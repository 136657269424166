import { FC, ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './SectionHeading.styled';
import { HeadingVariants, PressableIconType } from './SectionHeading.types';

interface SectionHeadingProps {
    variant: HeadingVariants;
    title: string;
    subtitle?: string;
    description?: string;
    leftIcon?: PressableIconType;
    rightIcon?: PressableIconType;
    leftContent?: ReactNode;
    rightContent?: ReactNode;
    style?: ViewStyle;
    onLeftPress?: () => void;
    onRightPress?: () => void;
}

export const SectionHeading: FC<SectionHeadingProps> = ({
    variant,
    title,
    subtitle,
    description,
    leftIcon,
    rightIcon,
    leftContent,
    rightContent,
    style,
    onLeftPress,
    onRightPress,
}: SectionHeadingProps) => {
    const theme = useTheme();
    const pressableStateLeft = usePressableState();
    const pressableStateRight = usePressableState();

    const renderVariant = () => {
        switch (variant) {
            case 'header':
                return <S.Header>{title}</S.Header>;
            case 'subheader':
                return <S.Subheader>{title}</S.Subheader>;
            case 'label':
                return <S.Label>{title}</S.Label>;
            default:
                return <S.Header>{title}</S.Header>;
        }
    };

    const renderLeftContent = () => {
        if (leftIcon?.name && !leftContent) {
            return (
                <S.LeftIconWrapper onPress={onLeftPress} {...pressableStateLeft.pressableProps}>
                    <PressableIcon
                        name={leftIcon.name}
                        pressedColor={leftIcon.pressedColor || theme.icon.tertiary}
                        color={theme.icon.tertiary}
                        size={leftIcon.size || 24}
                        pressableAnimationProgress={pressableStateLeft.pressableAnimationProgress}
                    />
                </S.LeftIconWrapper>
            );
        }

        if (!leftIcon?.name && leftContent) {
            return <S.LeftContentWrapper>{leftContent}</S.LeftContentWrapper>;
        }

        return null;
    };

    const renderRightContent = () => {
        if (rightIcon?.name && !rightContent) {
            return (
                <S.RightIconWrapper onPress={onRightPress} {...pressableStateRight.pressableProps}>
                    <PressableIcon
                        name={rightIcon.name}
                        pressedColor={rightIcon.pressedColor || theme.icon.tertiary}
                        color={theme.icon.tertiary}
                        size={rightIcon.size || 24}
                        pressableAnimationProgress={pressableStateRight.pressableAnimationProgress}
                    />
                </S.RightIconWrapper>
            );
        }

        if (!rightIcon?.name && rightContent) {
            return <S.RightContentWrapper>{rightContent}</S.RightContentWrapper>;
        }

        return null;
    };

    return (
        <S.Wrapper style={style}>
            {renderLeftContent()}
            <S.Content>
                {renderVariant()}
                {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
                {description && <S.Description>{description}</S.Description>}
            </S.Content>
            {renderRightContent()}
        </S.Wrapper>
    );
};
