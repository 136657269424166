import { TrainingStatus } from '@pg/backend';
import React, { FC, ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { useGetTrainingDaysByTraining } from '~/api/training/useGetTrainingDaysWithGroupsByTraining';
import { useTraining } from '~/enhancers/training.context';

import { TrainingCycle, TrainingDay } from './trainingPlan.types';
import { getNextTrainingDay, getTrainingCycles } from './trainingPlan.utils';

export interface TrainingPlanContextType {
    trainingCycles: TrainingCycle[];
    nextTrainingDay?: TrainingDay;
    nextTrainingCycle?: TrainingCycle;
    isFetching: boolean;
    isRefetching: boolean;
    getTrainingCycleByDayId: (trainingDayId: string) => TrainingCycle | undefined;
    getTrainingDayById: (trainingDayId: string) => TrainingDay | undefined;
    refetch: () => void;
}

export const TrainingPlanContext = createContext<TrainingPlanContextType>({
    trainingCycles: [],
    nextTrainingDay: undefined,
    nextTrainingCycle: undefined,
    isFetching: false,
    isRefetching: false,
    refetch: () => {
        throw new Error('Not implemented');
    },
    getTrainingCycleByDayId: () => {
        throw new Error('Not implemented');
    },
    getTrainingDayById: () => {
        throw new Error('Not implemented');
    },
});

export interface TrainingPlanContextProviderProps {
    children: ReactNode;
}

export const TrainingPlanContextProvider: FC<TrainingPlanContextProviderProps> = ({ children }) => {
    const theme = useTheme();
    const training = useTraining();
    const isDraft = training.data?.status === TrainingStatus.DRAFT;
    const {
        data = null,
        isFetching,
        isRefetching,
        refetch,
    } = useGetTrainingDaysByTraining(training.id, !(isDraft || training.isFetching));
    const trainingCycles = useMemo(() => getTrainingCycles(data), [data]);
    const nextTrainingDay = getNextTrainingDay(data, theme.isWebDesktop);

    const getTrainingCycleByDayId = useCallback(
        (trainingDayId: string) => {
            return trainingCycles.find((cycle) => cycle.days.some((day) => day.id === trainingDayId));
        },
        [trainingCycles],
    );

    const getTrainingDayById = useCallback(
        (trainingDayId: string) => {
            return trainingCycles
                .reduce((acc: TrainingDay[], trainingCycle) => [...trainingCycle.days, ...acc], [])
                .find((trainingDay) => trainingDay.id === trainingDayId);
        },
        [trainingCycles],
    );

    const value = useMemo(
        () => ({
            trainingCycles,
            nextTrainingDay: nextTrainingDay ? getTrainingDayById(nextTrainingDay.id) : undefined,
            nextTrainingCycle: nextTrainingDay ? getTrainingCycleByDayId(nextTrainingDay.id) : undefined,
            isFetching,
            isRefetching,
            getTrainingCycleByDayId,
            getTrainingDayById,
            refetch,
        }),
        [
            trainingCycles,
            nextTrainingDay,
            isFetching,
            isRefetching,
            getTrainingCycleByDayId,
            getTrainingDayById,
            refetch,
        ],
    );

    return <TrainingPlanContext.Provider value={value}>{children}</TrainingPlanContext.Provider>;
};

export const useTrainingPlan = () => useContext(TrainingPlanContext);
