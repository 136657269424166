import { useNavigation } from '@react-navigation/native';
import { FC, ReactNode } from 'react';
import { Pressable } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { Logo } from '~/components/logo';
import { PressableIcon } from '~/components/pressableIcon';
import { TopNavWrapper } from '~/components/wrappers';
import { VariantType } from '~/components/wrappers/topNavWrapper/TopNavWrapper.types';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';

import * as S from './StandardNavigation.styled';

export interface StandardNavigationProps {
    title?: string;
    bottomContent?: ReactNode;
    leftContent?: ReactNode;
    shouldRenderRightButton?: boolean;
    shouldRenderLogo?: boolean;
    loading?: boolean;
    rightButtonText?: string;
    customLeftIconName?: IconName;
    variant?: VariantType;
    rightIcon?: IconName;
    onRightPress?: () => void;
    onBackPress?: () => void;
    rightButtonIcon?: IconName;
}

export const StandardNavigation: FC<StandardNavigationProps> = ({
    title,
    bottomContent,
    leftContent,
    shouldRenderRightButton = false,
    shouldRenderLogo = false,
    rightButtonText = 'Zakończ',
    customLeftIconName,
    variant = 'standard',
    rightIcon,
    onRightPress,
    onBackPress,
    loading,
    rightButtonIcon,
}) => {
    const leftContentPressableState = usePressableState();
    const rightIconPressableState = usePressableState();
    const theme = useTheme();
    const navigation = useNavigation();

    const leftCommonContent = (
        <>
            <Pressable
                onPress={navigation.canGoBack() ? navigation.goBack : onBackPress}
                hitSlop={{ top: 8, bottom: 8, left: 16, right: 16 }}
                {...leftContentPressableState.pressableProps}
            >
                <PressableIcon
                    name={customLeftIconName || 'chevron-big-left'}
                    pressableAnimationProgress={leftContentPressableState.pressableAnimationProgress}
                    size={20}
                    color={theme.icon.tertiary}
                    pressedColor={theme.icon.primary}
                />
            </Pressable>
            {leftContent}
        </>
    );

    const rightContent = () => {
        if (shouldRenderRightButton) {
            return (
                <Button onPress={onRightPress} variant="secondary" size="xs" icon={rightButtonIcon}>
                    {rightButtonText}
                </Button>
            );
        }

        if (rightIcon) {
            return (
                <Pressable onPress={onRightPress} {...rightIconPressableState.pressableProps}>
                    <PressableIcon
                        name={rightIcon}
                        pressableAnimationProgress={rightIconPressableState.pressableAnimationProgress}
                        size={20}
                        color={theme.icon.tertiary}
                        pressedColor={theme.icon.primary}
                    />
                </Pressable>
            );
        }

        return null;
    };

    const centerContent = () => {
        if (shouldRenderLogo) return <Logo size={40} />;

        if (!title) return null;

        return <S.Title>{title}</S.Title>;
    };

    return (
        <TopNavWrapper
            variant={variant}
            leftContent={leftCommonContent}
            rightContent={rightContent()}
            centerContent={centerContent()}
            bottomContent={bottomContent}
            loading={loading}
        />
    );
};
