import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';

import { IconButtonAnimation } from './IconButton.animated';
import * as S from './IconButton.styled';
import { IconButtonStyledProps } from './IconButton.types';

export interface IconButtonProps
    extends IconButtonStyledProps,
        Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut'> {
    icon: IconName;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const IconButton: React.FC<IconButtonProps> = ({ icon, disabled = false, size = 's', ...props }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <IconButtonAnimation progress={pressableAnimationProgress} disabled={disabled}>
            {(animatedStyle) => (
                <AnimatedContainer
                    {...pressableProps}
                    {...props}
                    size={size}
                    disabled={disabled}
                    style={[props.style, animatedStyle]}
                >
                    <S.IconWrapper>
                        <PressableIcon
                            name={icon}
                            size={20}
                            color={disabled ? theme.icon.disabled : theme.icon.tertiary}
                            pressedColor={theme.icon.primary}
                            pressableAnimationProgress={pressableAnimationProgress}
                        />
                    </S.IconWrapper>
                </AnimatedContainer>
            )}
        </IconButtonAnimation>
    );
};
