import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { EmptyScreen } from '~/components/emptyScreen';

export const SupplementsEmptyScreen: FC = () => {
    const theme = useTheme();

    return (
        <EmptyScreen
            icon="pharmacy-outline"
            iconColor={theme.icon.tertiary}
            title="Brak dodanych suplementów"
            titleColor={theme.text.secondary}
            description="Skontaktuj się ze mną jeśli chcesz o tym podyskutować."
        />
    );
};
