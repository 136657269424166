import React, { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import uuid from 'react-native-uuid';
import { ToastProps } from '~/components/toast';
import { ToastVariant } from '~/components/toast/Toast.types';

export interface Toast {
    id: string;
    config: Omit<ToastProps, 'id'> & {
        fullScreen?: boolean;
    };
}

export interface ToastsContextType {
    toasts: Toast[];
    currentToast?: Toast;
    showToast: (config: Toast['config']) => Toast['id'];
    hideToast: (id: Toast['id']) => void;
}

export const ToastsContext = createContext<ToastsContextType>({
    toasts: [],
    showToast: () => {
        throw new Error('Not implemented');
    },
    hideToast: () => {
        throw new Error('Not implemented');
    },
});

export interface ToastsContextProviderProps {
    children: ReactNode;
}

const getId = () => uuid.v4().toString();

export const ToastsContextProvider: FC<ToastsContextProviderProps> = ({ children }) => {
    const [currentToasts, setCurrentToasts] = useState<Toast[]>([]);

    const showToast = useCallback<ToastsContextType['showToast']>((config) => {
        const id = getId();
        setCurrentToasts((prev) => [...prev, { id, config }]);

        return id;
    }, []);

    const hideToast = useCallback<ToastsContextType['hideToast']>((id) => {
        setCurrentToasts((prev) => prev.filter((request) => request.id !== id));
    }, []);

    const currentToast = useMemo(() => {
        const groupedByVariant = currentToasts.reduce<Record<ToastVariant, Toast[]>>(
            (toastsMap, toast) => {
                const { variant = 'informative' } = toast.config;
                toastsMap[variant].push(toast);
                return toastsMap;
            },
            { informative: [], critical: [], warning: [] },
        );

        return (
            groupedByVariant.critical?.pop() ?? groupedByVariant.warning?.pop() ?? groupedByVariant.informative?.pop()
        );
    }, [currentToasts]);

    const value = useMemo(
        () => ({
            toasts: currentToasts,
            showToast,
            hideToast,
            currentToast,
        }),
        [currentToasts, showToast, hideToast, currentToast],
    );

    return <ToastsContext.Provider value={value}>{children}</ToastsContext.Provider>;
};

export const useToasts = () => useContext(ToastsContext);
