import { Icon } from '~/components/icon';

import * as S from './Day.styled';
import { getDayVariant } from './Day.utils';

export const Day = ({ ...props }) => {
    const { state, date, onPress, marking } = props;

    const renderDayIcon = () => {
        if (state === 'today') {
            return <Icon name="smirking-face-3d" size={18} />;
        }
        if (marking?.activityType === 'rest') {
            return <Icon name="sleeping-face-3d" size={18} />;
        }
        if (marking?.activityType === 'single') {
            return <Icon name="flexed-biceps-3d" size={18} />;
        }
        if (marking?.activityType === 'multiple') {
            return <S.MultipleActivitiesText>+2</S.MultipleActivitiesText>;
        }
    };

    return (
        <>
            <S.DayWrapper onPress={() => onPress(date)} variant={getDayVariant(state, marking?.activityType)}>
                <S.DayText variant={getDayVariant(state, marking?.activityType)}>{date.day}</S.DayText>
                <S.IconWrapper variant={getDayVariant(state, marking?.activityType)}>{renderDayIcon()}</S.IconWrapper>
            </S.DayWrapper>
        </>
    );
};
