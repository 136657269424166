import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled(View)`
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
`;

export const Content = styled(View)`
    height: 100%;
    display: flex;
    width: 100%;
`;

export const ScrollableContainer = styled(View)`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 20px 24px;
    margin-bottom: 32px;
    overflow-y: scroll;
    scroll-padding-left: 24px;
    scroll-padding-right: 24px;
`;

export const SliderItemWrapper = styled(View)<{ first?: boolean; last?: boolean }>`
    width: 320px;
    max-height: calc(100% - 32px);
    margin-right: 16px;

    ${({ last }) =>
        last &&
        css`
            margin-right: 0;
        `}
`;
