import { Pressable, ScrollView, View } from 'react-native';
import styled from 'styled-components/native';

export const Wrapper = styled(View)`
    flex-direction: row;
`;

export const Button = styled(Pressable)`
    margin-left: 24px;
`;

export const TabsWrapper = styled(ScrollView)`
    margin-top: 16px;
    width: 100%;
`;
