import { Dimensions, View } from 'react-native';
import styled from 'styled-components/native';

const windowHeight = Dimensions.get('window').height;

export const Container = styled(View)`
    flex: 1 1 600px;
    min-width: 600px;
    max-width: 100%;
    max-height: 100%;
    min-height: ${windowHeight * 0.9}; // Set 90% of the window height
`;
