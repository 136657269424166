import { Pressable, Text, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { css } from 'styled-components';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const TopContainer = styled(Animated.View)`
    width: 100%;
    padding: 14px 16px 12px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    top: 0;
`;

const BaseButtonWrapperStyles = css`
    padding: 8px;
    border-radius: 12px;
`;

export const TopContentWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const CloseButtonWrapper = styled(Pressable)`
    ${BaseButtonWrapperStyles};
    background-color: ${({ theme }) => theme.fill.level4};
`;

export const TopContainerTitle = styled(Text)`
    ${mixins.typography.h.semiBold18}
    color: ${({ theme }) => theme.text.primary};
`;

export const Container = styled(View)`
    height: 100%;
    padding-top: 62px;
`;
