import { format, parseISO } from 'date-fns';
import { FC, useEffect, useMemo, useRef } from 'react';
import { DateData } from 'react-native-calendars';
import { CalendarListImperativeMethods } from 'react-native-calendars/src/calendar-list';
import { MarkedDates } from 'react-native-calendars/src/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DEFAULT_DATE_FORMAT, INITIAL_DATE, LocaleConfig, RANGE } from '~/constants/calendar';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import * as S from './Calendar.styled';
import { calendarTheme } from './Calendar.styled';
import { Day } from './day';

interface CalendarProps {
    currentDate?: string;
    enabledDates?: string[];
    disabledDates?: string[];
    maxDate?: string;
    minDate?: string;
    calendarWidth?: number;
    isHeaderRounded?: boolean;
    onDayPress: (date: DateData) => void;
    onMonthChange?: (date: string) => void;
}

export const Calendar: FC<CalendarProps> = ({
    onDayPress,
    onMonthChange,
    currentDate = INITIAL_DATE,
    enabledDates = [],
    disabledDates = [],
    maxDate,
    minDate,
    calendarWidth,
    isHeaderRounded = true,
}) => {
    const inset = useSafeAreaInsets();
    const calendarRef = useRef<CalendarListImperativeMethods | null>(null);

    const handleEmmiter = () => {
        calendarRef.current?.scrollToDay(new Date(), 100, true);
    };

    useEffect(() => {
        Emitter.on(EmitterEvent.scrollToDate, handleEmmiter);
        return () => {
            Emitter.off(EmitterEvent.scrollToDate, handleEmmiter);
        };
    }, []);

    const { disabledByDefault, markedDates } = useMemo(() => {
        const initalMarkedDates: MarkedDates = { [currentDate]: { selected: true } };
        const markedEnabledDates = enabledDates.reduce<MarkedDates>((dates, date) => {
            const formattedDate = format(parseISO(date), DEFAULT_DATE_FORMAT);
            dates[formattedDate] = {
                ...dates[formattedDate],
                selected: formattedDate === currentDate,
                disabled: false,
                disableTouchEvent: false,
            };
            return dates;
        }, initalMarkedDates);

        const markedDisabledDates = disabledDates.reduce<MarkedDates>((dates, date) => {
            const formattedDate = format(parseISO(date), DEFAULT_DATE_FORMAT);
            dates[formattedDate] = {
                ...dates[formattedDate],
                selected: formattedDate === currentDate,
                disabled: true,
                disableTouchEvent: true,
            };
            return dates;
        }, initalMarkedDates);

        return {
            disabledByDefault: enabledDates.length > 0,
            markedDates: {
                ...markedEnabledDates,
                ...markedDisabledDates,
            },
        };
    }, [enabledDates, disabledDates, currentDate]);

    return (
        <S.Container inset={inset}>
            <S.MonthWrapper isHeaderRounded={isHeaderRounded}>
                {LocaleConfig.locales.pl.dayNamesShort.map((day: string) => (
                    <S.MonthText key={day}>{day}</S.MonthText>
                ))}
            </S.MonthWrapper>
            <S.CalendarList
                current={currentDate}
                pastScrollRange={RANGE}
                futureScrollRange={RANGE}
                onDayPress={onDayPress}
                firstDay={1}
                ref={calendarRef}
                theme={calendarTheme as any}
                hideDayNames
                dayComponent={Day}
                maxDate={maxDate}
                minDate={minDate}
                disabledByDefault={disabledByDefault}
                markedDates={markedDates}
                calendarWidth={calendarWidth}
                calendarHeight={440}
                onMonthChange={({ dateString }) => onMonthChange?.(dateString)}
            />
        </S.Container>
    );
};
