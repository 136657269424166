import React, { FC } from 'react';

import * as S from './DetailsTile.styled';

interface DetailsTileProps {
    title: string;
    value: number;
    unit?: string;
}

export const DetailsTile: FC<DetailsTileProps> = ({ title, value, unit }) => {
    return (
        <S.Container>
            <S.Title>{title}</S.Title>
            <S.Value>
                {value}
                {unit && <S.Unit> {unit}</S.Unit>}
            </S.Value>
        </S.Container>
    );
};
