import { Training } from '@pg/backend';
import { useNavigation } from '@react-navigation/native';
import { FlatList, ListRenderItem } from 'react-native';
import { useGetTrainings } from '~/api/training/useGetTrainings';
import { PlanMenuItem } from '~/components/planMenuItem';
import { SEO } from '~/components/wrappers';
import { useTraining } from '~/enhancers/training.context';
import { useRefresh } from '~/hooks/useRefresh';
import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { MainLayout } from '~/layouts';
import { TrainingStackScreens } from '~/navigation/paths';
import { getDateRangeWithPlaceholder } from '~/utils/dates';

import { TrainingPlanChoiceNavigationProp } from './TrainingPlanChoiceScreen.types';

export const TrainingPlanChoiceScreen = () => {
    useWebDesktopBlockedScreen(TrainingStackScreens.screens.TrainingPlanChoice);
    const navigation = useNavigation<TrainingPlanChoiceNavigationProp>();
    const { data = [], refetch, isRefetching } = useGetTrainings();
    const training = useTraining();
    const { refresh, isRefreshing } = useRefresh(refetch, isRefetching);

    const handleNavigation = (trainingPlanId: string) => {
        training.setTraining(trainingPlanId);
        navigation.navigate('Tab', {
            screen: 'TrainingTabStack',
            params: { screen: 'TrainingPlan' },
        });
    };

    const renderItem: ListRenderItem<Training> = ({ item: trainingPlan, index }) => {
        return (
            <PlanMenuItem
                key={trainingPlan.id}
                index={data.length - index}
                selected={training.id === trainingPlan.id}
                onPress={() => handleNavigation(trainingPlan.id)}
            >
                {getDateRangeWithPlaceholder({
                    startDate: trainingPlan?.startDate,
                    endDate: trainingPlan?.endDate,
                    placeholder: 'W trakcie przygotowywania',
                    dateFormat: 'dd.MM.yyyy',
                })}
            </PlanMenuItem>
        );
    };

    return (
        <MainLayout isBottomRounded={false}>
            <SEO title="Wybór Planu Treningowego" />
            <FlatList data={data} renderItem={renderItem} onRefresh={refresh} refreshing={isRefreshing} />
        </MainLayout>
    );
};
