import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.border['12']};
    justify-content: center;
`;

export const Value = styled(Text)`
    ${mixins.typography.ui.semiBold16};
    color: ${({ theme }) => theme.text.primary};
    text-align: center;
`;

export const Label = styled(Text).attrs({ numberOfLines: 1 })`
    margin-top: 8px;
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
    text-align: center;
`;
