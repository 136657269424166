import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const CircleProgressContainer = styled(View)`
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
`;

export const ProgressText = styled(Text)`
    ${mixins.typography.h.semiBold40};
    color: ${({ theme }) => theme.text.primary};
`;

export const FooterButtonsRow = styled(View)`
    flex-direction: row;
    margin-bottom: 20px;
`;

export const FooterButton = styled(View)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;
