import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    height: auto;
    width: 100%;
    border-radius: 16px;
    border: 2px solid ${({ theme }) => theme.border['20']};
    background: ${({ theme }) => theme.fill.background};
    overflow: hidden;
`;

export const ChartContainer = styled(Pressable)`
    height: 235px;
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
    display: flex;
`;

export const Summary = styled(View)`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 12px 24px;
`;

export const DataItem = styled(View)`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
`;

export const IconWrapper = styled(View)`
    margin-right: 8px;
`;

export const Metric = styled(View)`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

export const MetricValue = styled(Text)`
    ${mixins.typography.h.semiBold40};
    color: ${({ theme }) => theme.text.primary};
`;

export const MetricUnit = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
    align-self: flex-end;
    margin-bottom: 7px;
    margin-left: 3px;
`;

export const MetricDescription = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
    min-width: 115px;
`;

export const EmptyStateTextWrapper = styled(View)`
    height: 92px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
`;
export const EmptyStateText = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
`;
