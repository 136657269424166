import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo } from 'react';
import BasePopup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';
import { useTheme } from 'styled-components/native';

import * as S from './DesktopModal.styled';

export interface DesktopModalProps extends Omit<PopupProps, 'open' | 'arrow' | 'modal' | 'onClose'> {
    isOpen?: boolean;
    onClose?: () => void;
}

export const DesktopModal: React.FC<DesktopModalProps> = ({ children, ...props }) => {
    const navigation = useNavigation();
    const { isWebDesktop } = useTheme();

    useEffect(() => {
        if (props.isOpen) {
            navigation.addListener('state', () => {
                props.onClose?.();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const overlayStyle = useMemo(
        () => ({ ...S.popupStyles.overlayStyle, ...props.overlayStyle }),
        [props.overlayStyle],
    );
    const contentStyle = useMemo(
        () => ({ ...S.popupStyles.contentStyle, ...props.contentStyle }),
        [props.contentStyle],
    );

    if (!isWebDesktop) {
        return null;
    }

    return (
        <BasePopup
            on="click"
            closeOnEscape
            closeOnDocumentClick={false}
            repositionOnResize
            keepTooltipInside
            arrow={false}
            {...props}
            open={props.isOpen}
            overlayStyle={overlayStyle}
            contentStyle={contentStyle}
            modal
        >
            <S.Container>
                <S.Children>
                    <S.ScrollContainer>{children}</S.ScrollContainer>
                    <S.CloseButton onPress={props.onClose} />
                </S.Children>
            </S.Container>
        </BasePopup>
    );
};
