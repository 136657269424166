import { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ListRenderItem, SectionList } from 'react-native';
import { useGetExpandedTrainingDay } from '~/api/training/useGetExpandedTrainingDay';
import { DataItem, SectionItem } from '~/components/bottomSheet/trainingOverviewSheet/TrainingOverviewSheet.types';
import { transformData } from '~/components/bottomSheet/trainingOverviewSheet/trainingOverviewSheet.utils';
import { IconButton } from '~/components/iconButton';
import { useTrainingPlan } from '~/enhancers/trainingPlanContext/trainingPlan.context';
import { TrainingPreviewHeader } from '~/screens/training/components/trainingPreviewHeader';
import { getTotalNumberOfSeries, getTotalTime } from '~/utils/training';

import * as S from './TrainingPreviewPopup.styled';

interface TrainingPreviewPopupProps {
    trainingDayId: string;
    onClose: (event: GestureResponderEvent) => void;
}

export const TrainingPreviewPopup: React.FC<TrainingPreviewPopupProps> = ({ trainingDayId, onClose }) => {
    const { data } = useGetExpandedTrainingDay(trainingDayId);
    const trainingPlan = useTrainingPlan();
    const duration = useMemo(() => getTotalTime(data), [data]);
    const totalNumberOfSeries = useMemo(() => getTotalNumberOfSeries(data), [data]);

    const trainingCycle = trainingPlan.getTrainingCycleByDayId(trainingDayId);
    const trainingDay = trainingPlan.getTrainingDayById(trainingDayId);

    const transformedData = useMemo(() => transformData(data), [data]);

    const renderSectionHeader = useCallback(
        ({ section }: { section: SectionItem }) => (
            <S.SectionHeaderContainer key={section.name}>
                {section.isFirstOfGroupType && <S.SectionHeader>{section.groupTitle}</S.SectionHeader>}
                {section.groupType === 'EXERCISE' && (
                    <S.SectionSubHeader withPadding={section.isFirstOfGroupType}>{section.name}</S.SectionSubHeader>
                )}
            </S.SectionHeaderContainer>
        ),
        [],
    );

    const renderItem = useCallback(
        ({ item }: { item: DataItem }) => (
            <S.ContentWrapper key={item.id}>
                <S.ExerciseName>{item.name}</S.ExerciseName>
                <S.ExerciseSets>{item.sets ?? item.time ?? ''}</S.ExerciseSets>
            </S.ContentWrapper>
        ),
        [],
    );

    const renderListHeader = () => (
        <S.TrainingPreviewHeaderWrapper>
            <TrainingPreviewHeader duration={duration} series={totalNumberOfSeries} />
        </S.TrainingPreviewHeaderWrapper>
    );

    return (
        <S.Container>
            <S.Header>
                <S.TrainingTitleWrapper>
                    <S.CycleTitle>{trainingCycle?.shortTitle} / </S.CycleTitle>
                    <S.TrainingTitle>{trainingDay?.title}</S.TrainingTitle>
                </S.TrainingTitleWrapper>
                <IconButton onPress={onClose} icon="close" size="s" />
            </S.Header>

            <SectionList
                sections={transformedData || []}
                keyExtractor={(item) => `${(item as DataItem).id}`}
                renderItem={renderItem as ListRenderItem<unknown>}
                renderSectionHeader={renderSectionHeader}
                showsVerticalScrollIndicator={false}
                ListHeaderComponent={renderListHeader}
            />
        </S.Container>
    );
};
