import { Exercise, ExerciseGroup } from '@pg/backend';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetExerciseGroups } from '~/api/exercise/useGetExerciseGroups';
import { useGetExercises } from '~/api/exercise/useGetExercises';
import { VideoModal } from '~/components/modal';
import { useDebouncedState } from '~/hooks/useDebouncedState';

export interface ExerciseContextType {
    exercises: Exercise[];
    exerciseGroups: ExerciseGroup[];
    exercisesVideos: Record<string, string | undefined>;
    groupNamesById: Record<string, string>;
    query: string | undefined;
    selectedExerciseGroup: string | undefined;
    isLoadingExercises: boolean;
    setQuery: (query: string | undefined) => void;
    setSelectedExerciseGroup: (exerciseGroup: string | undefined) => void;
    setExerciseVideo: (videoUrl: string, video: string | undefined) => void;
    openVideoModal: (videoUrl: string) => void;
}

export const ExerciseContext = React.createContext<ExerciseContextType>({
    exercises: [],
    exerciseGroups: [],
    exercisesVideos: {},
    groupNamesById: {},
    query: undefined,
    selectedExerciseGroup: undefined,
    isLoadingExercises: false,
    setQuery: () => {
        throw new Error('Not implemented');
    },
    setSelectedExerciseGroup: () => {
        throw new Error('Not implemented');
    },
    setExerciseVideo: () => {
        throw new Error('Not implemented');
    },
    openVideoModal: () => {
        throw new Error('Not implemented');
    },
});

export interface ExerciseContextProviderProps {
    children: ReactNode;
}

export const ExerciseContextProvider: React.FC<ExerciseContextProviderProps> = ({ children }) => {
    const [query, setQuery] = useState<string>();
    const [debouncedQuery, setDebouncedQuery] = useDebouncedState(query);
    const [selectedExerciseGroup, setSelectedExerciseGroup] = useState<string>();
    const { data: exercises, isFetching: isFetchingExercises } = useGetExercises({
        groupId: selectedExerciseGroup,
        name: debouncedQuery,
    });
    const { data: exerciseGroups } = useGetExerciseGroups();
    const [selectedVideoPath, setSelectedVideoPath] = useState<string>();
    const [exercisesVideos, setExercisesVideos] = useState<Record<string, string | undefined>>({});

    const groupNamesById = useMemo(() => {
        return exerciseGroups.reduce((acc: Record<string, string>, exerciseGroup) => {
            acc[exerciseGroup.id] = exerciseGroup.name || '';
            return acc;
        }, {});
    }, [exerciseGroups]);

    const openVideoModal = useCallback((videoUrl: string) => {
        setSelectedVideoPath(videoUrl);
    }, []);

    const setExerciseVideo = useCallback((videoUrl: string, video: string | undefined) => {
        setExercisesVideos((prevExercisesVideos) => ({ ...prevExercisesVideos, [videoUrl]: video }));
    }, []);

    useEffect(() => {
        setDebouncedQuery(query);
    }, [query, setDebouncedQuery]);

    const value: ExerciseContextType = useMemo(
        () => ({
            exercises,
            exerciseGroups,
            exercisesVideos,
            groupNamesById,
            selectedExerciseGroup,
            query,
            isLoadingExercises: isFetchingExercises || query !== debouncedQuery,
            setSelectedExerciseGroup,
            setExerciseVideo,
            setQuery,
            openVideoModal,
        }),
        [
            exercises,
            exerciseGroups,
            exercisesVideos,
            groupNamesById,
            selectedExerciseGroup,
            query,
            debouncedQuery,
            isFetchingExercises,
            setSelectedExerciseGroup,
            setExerciseVideo,
            setQuery,
            openVideoModal,
        ],
    );

    return (
        <ExerciseContext.Provider value={value}>
            {children}
            <VideoModal
                videoUrl={selectedVideoPath}
                visible={!!selectedVideoPath}
                onRequestClose={() => setSelectedVideoPath(undefined)}
            />
        </ExerciseContext.Provider>
    );
};

export const useExercise = () => useContext(ExerciseContext);
