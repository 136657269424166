import { TrainingItemStatus } from '@pg/backend';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { Pressable } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useUpdateTrainingDay } from '~/api/training/useUpdateTrainingDay';
import { PressableIcon } from '~/components/pressableIcon';
import { StandardNavigation } from '~/components/topNavigation';
import { useSoundPlayer } from '~/enhancers/soundPlayer';
import { useTraining } from '~/enhancers/training.context';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './TrainingInProgressTopNavigation.styled';

export interface TrainingInProgressTopNavigationProps extends NativeStackHeaderProps {}

export const TrainingInProgressTopNavigation: FC<TrainingInProgressTopNavigationProps> = ({ route, navigation }) => {
    const { trainingDayId, finished } = (route.params ?? {}) as {
        loading?: string;
        trainingDayId: string;
        finished?: string;
    };
    const theme = useTheme();
    const soundIconPressableState = usePressableState();
    const soundPlayer = useSoundPlayer();
    const { mutateAsync } = useUpdateTrainingDay();
    const { id } = useTraining();
    const queryClient = useQueryClient();
    const parsedFinished = finished && JSON.parse(finished);

    const navigateBack = () => {
        navigation.navigate('Tab', {
            screen: 'TrainingTabStack',
            params: { screen: 'TrainingPlan' },
        });
    };

    const handleRightButtonPress = async () => {
        try {
            if (parsedFinished) {
                await mutateAsync({
                    id: trainingDayId,
                    status: TrainingItemStatus.IN_PROGRESS,
                });
                await queryClient.refetchQueries(['getTrainingDayWithExercises', trainingDayId]);
                navigation.setParams({ finished: JSON.stringify(false) });
            } else {
                await mutateAsync({
                    id: trainingDayId,
                    status: TrainingItemStatus.FINISHED,
                    date: new Date().toISOString(),
                });
                navigation.canGoBack() ? navigation.goBack() : navigateBack();
            }
            await queryClient.refetchQueries(['getTrainingDaysByTrainingId', id]);
            await queryClient.refetchQueries(['getInProgressTrainingDay']);
        } catch (e) {}
    };

    const leftContent = (
        <S.LeftContentContainer>
            <Pressable
                onPress={() => {
                    soundPlayer.isMuted ? soundPlayer.unmute() : soundPlayer.mute();
                }}
                hitSlop={{ top: 8, bottom: 8, left: 16, right: 16 }}
                {...soundIconPressableState.pressableProps}
            >
                <PressableIcon
                    name={soundPlayer.isMuted ? 'sound-off' : 'sound-on'}
                    pressableAnimationProgress={soundIconPressableState.pressableAnimationProgress}
                    size={20}
                    color={theme.icon.secondary}
                    pressedColor={theme.icon.primary}
                />
            </Pressable>
        </S.LeftContentContainer>
    );

    return (
        <StandardNavigation
            leftContent={leftContent}
            onBackPress={navigateBack}
            shouldRenderLogo
            shouldRenderRightButton
            onRightPress={handleRightButtonPress}
            variant="rightButton"
            rightButtonText={parsedFinished ? 'Edytuj' : 'Zakończ'}
            rightButtonIcon={parsedFinished ? 'edit' : undefined}
        />
    );
};
