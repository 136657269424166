import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { EmptyScreen } from '~/components/emptyScreen';

export const NoDietPlan: FC = () => {
    const theme = useTheme();

    return (
        <EmptyScreen
            icon="warning-stop"
            iconColor={theme.icon.critical}
            title="Nie masz diety w swoim planie."
            titleColor={theme.text.secondary}
            description="Jeśli chcesz mieć pełen plan treningowo-dietetyczny i zmaksymalizować swoje osiągi oraz przestać ręcznie liczyć kalorie i spisywać listę zakupów na kartkę to wskocz na wyższy plan!"
            button={{
                label: 'Ulepsz plan',
                variant: 'primary',
                // TODO handle onPress
                onPress: () => console.log('Ulepsz plan'),
            }}
        />
    );
};
