import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

interface TodayWrapperProps {
    isToday?: boolean;
    selected?: boolean;
}

interface DayTextProps {
    isToday?: boolean;
    selected?: boolean;
}

export const DayWrapper = styled(Pressable)`
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    color: ${({ theme }) => theme.text.primary};
    opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`;

export const DayText = styled(Text)<DayTextProps>`
    ${mixins.typography.ui.medium14}
    text-align: center;
    color: ${({ theme, isToday }) => (isToday ? theme.text.criticalSurface : theme.text.primary)};
`;

export const TodayWrapper = styled(View)<TodayWrapperProps>`
    border-radius: 3px;
    background-color: ${({ theme, isToday, selected }) => {
        if (isToday) {
            return theme.fill.critical;
        }

        if (selected) {
            return theme.fill.informative;
        }

        return 'transparent';
    }};
    width: 28px;
    height: 22px;
    justify-content: center;
    align-items: center;
`;
