import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    background-color: ${({ theme }) => theme.fill.level2};
    border-radius: 12px;
    overflow: hidden;
`;

export const Button = styled(Pressable)`
    flex-direction: row;
    width: 100%;
    padding: 16px;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.secondary};
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular11};
    color: ${({ theme }) => theme.text.tertiary};
    margin-top: 2px;
`;

export const Content = styled(View)``;
export const IconWrapper = styled(View)`
    justify-content: center;
    margin-right: 16px;
`;
