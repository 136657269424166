import { ShippingListDateRange } from '../../ShoppingListScreen.types';
import * as S from './ShoppingListCalendar.styled';
import { ShoppingListCalendarContent } from './ShoppingListCalendarContent';

type ShoppingListCalendarProps = {
    onDismiss: () => void;
    range: ShippingListDateRange;
    onChange: (range: ShippingListDateRange) => void;
};

export const ShoppingListCalendar = ({ range, onDismiss, onChange }: ShoppingListCalendarProps) => {
    return (
        <S.WebWrapper>
            <ShoppingListCalendarContent onDismiss={onDismiss} onChange={onChange} range={range} calendarWidth={428} />
        </S.WebWrapper>
    );
};
