import { UserSupplement } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchAllUserSupplements: QueryFunction<UserSupplement[], ['getAllUserSupplements']> = async () => {
    return await DataStore.query(UserSupplement, (criteria) => criteria, {
        sort: (s) => s.createdAt(SortDirection.ASCENDING),
        limit: 1000,
    });
};

export const useGetUserSupplements = () =>
    useQuery(['getAllUserSupplements'], fetchAllUserSupplements, { initialData: [] });
