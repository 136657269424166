import { Measurement } from '@pg/backend';
import { parseISO } from 'date-fns';

import type { MeasureType } from '../../MeasurementsScreen.types';

export const convertMeasurementsToPoint = (measurement: Measurement, measureType: MeasureType) => {
    return {
        date: parseISO(measurement.date),
        value: measurement[measureType],
    };
};
