import React, { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Area } from 'react-easy-crop';
import { useTheme } from 'styled-components/native';
import { ImagePickerSheet } from '~/components/bottomSheet/imagePickerSheet';
import { ImageCropModal } from '~/components/modal/imageCropModal';
import { usePicker } from '~/enhancers/imagePicker/hooks/usePicker';
import { getCroppedImage } from '~/utils/image';

import { Image } from './imagePicker.types';

export interface ChooseImageOptions {
    width: number;
    height: number;
}

export interface ImagePickerContextType {
    chooseImage: (options: ChooseImageOptions) => Promise<Image>;
}

export const ImagePickerContext = React.createContext<ImagePickerContextType>({
    chooseImage: () => {
        throw new Error('Not implemented');
    },
});

export interface ImagePickerContextProviderProps {
    children: ReactNode;
}

export const ImagePickerContextProvider: React.FC<ImagePickerContextProviderProps> = ({ children }) => {
    const theme = useTheme();
    const [isPhotoMobileMenuSheetOpen, setIsPhotoMobileMenuSheetOpen] = useState(false);
    const [originalDesktopImage, setOriginalDesktopImage] = useState<Image>();
    const promiseResolverRef = useRef<((value: Image) => void) | null>(null);
    const optionsRef = useRef<ChooseImageOptions>();
    const picker = usePicker();

    const chooseImage = useCallback(
        (options: ChooseImageOptions) => {
            return new Promise<Image>(async (resolve) => {
                promiseResolverRef.current = resolve;
                optionsRef.current = options;

                if (theme.isWebMobile) {
                    const image = await picker.openPicker(options);
                    promiseResolverRef.current?.(image);
                } else if (theme.isWebDesktop) {
                    const image = await picker.openPicker(options);
                    setOriginalDesktopImage(image);
                } else {
                    setIsPhotoMobileMenuSheetOpen(true);
                }
            });
        },
        [picker, theme.isWebDesktop, theme.isWebMobile],
    );

    const handleMobilePhotoSelect = async (type: 'openPicker' | 'openCamera') => {
        try {
            const image = await picker[type](optionsRef.current);
            setIsPhotoMobileMenuSheetOpen(false);
            promiseResolverRef.current?.(image);
        } catch (e) {
            console.log(e);
        }
    };

    const handleWebImageCrop = async (croppedAreaPixels?: Area) => {
        if (originalDesktopImage) {
            const croppedBase64Image = await getCroppedImage(
                originalDesktopImage.uri,
                optionsRef.current!,
                originalDesktopImage.mime,
                croppedAreaPixels,
            );

            if (croppedBase64Image) {
                promiseResolverRef.current?.({
                    uri: croppedBase64Image,
                    mime: originalDesktopImage!.mime,
                });
            }
        }
        setOriginalDesktopImage(undefined);
    };

    const value: ImagePickerContextType = useMemo(
        () => ({
            chooseImage,
        }),
        [chooseImage],
    );

    return (
        <ImagePickerContext.Provider value={value}>
            {children}

            <ImagePickerSheet
                isOpen={isPhotoMobileMenuSheetOpen}
                onClose={() => setIsPhotoMobileMenuSheetOpen(false)}
                onPhotoSelect={() => handleMobilePhotoSelect('openPicker')}
                onCameraSelect={() => handleMobilePhotoSelect('openCamera')}
            />

            {theme.isWebDesktop && (
                <ImageCropModal
                    isOpen={!!originalDesktopImage}
                    image={originalDesktopImage?.uri ?? ''}
                    aspect={optionsRef.current && optionsRef.current.width! / optionsRef.current.height!}
                    onCrop={handleWebImageCrop}
                    onClose={() => setOriginalDesktopImage(undefined)}
                />
            )}
        </ImagePickerContext.Provider>
    );
};

export const useImagePicker = () => useContext(ImagePickerContext);
