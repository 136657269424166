import { TrainingDay } from '@pg/backend';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const updateTrainingDay: MutationFunction<TrainingDay, Pick<TrainingDay, 'date' | 'status' | 'id'>> = async ({
    id,
    date,
    status,
}) => {
    const original = await DataStore.query(TrainingDay, id);

    if (!original) throw new Error(`TrainingDay with id ${id} not found`);

    return await DataStore.save(
        TrainingDay.copyOf(original, (updated) => {
            if (date) {
                updated.date = date;
            }

            if (status) {
                updated.status = status;
            }
        }),
    );
};

export const useUpdateTrainingDay = () =>
    useMutation({
        mutationKey: ['updateTrainingDay'],
        mutationFn: updateTrainingDay,
    });
