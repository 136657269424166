import { SignInOpts, SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { useCountdownTimer } from '~/screens/auth/hooks/useCountdownTimer';

export interface AuthContextType {
    isAuthenticated: boolean;
    user: CognitoUser | null;
    isLoading: boolean;
    signUpConfirmationEnabled: boolean;
    setSignUpConfirmationEnabled: (value: boolean) => void;
    newPasswordConfirmation: CognitoUser | boolean;
    setNewPasswordConfirmation: (value: CognitoUser | boolean) => void;
    signIn: (params: SignInOpts) => Promise<CognitoUser>;
    signUp: (params: SignUpParams) => Promise<void>;
    confirmSignUp: (username: string, code: string) => Promise<void>;
    resendSignUpVerificationCode: (username: string) => Promise<void>;
    completeNewPasswordChallenge: (user: CognitoUser, answer: string) => Promise<void>;
    signOut: () => Promise<void>;
    forgotPassword: (username: string) => Promise<void>;
    forgotPasswordSubmit: (username: string, code: string, password: string) => Promise<void>;
    resendCodeCountdown: {
        start: () => void;
        disabled: boolean;
        timeRemaining: string;
    };
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    initialPath: string;
    deleteUser: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType>({
    isAuthenticated: false,
    user: null,
    isLoading: true,
    signUpConfirmationEnabled: false,
    initialPath: '/',
    setSignUpConfirmationEnabled: () => {
        throw new Error('Not implemented');
    },
    newPasswordConfirmation: false,
    setNewPasswordConfirmation: () => {
        throw new Error('Not implemented');
    },
    signIn: () => {
        throw new Error('Not implemented');
    },
    signUp: () => {
        throw new Error('Not implemented');
    },
    confirmSignUp: () => {
        throw new Error('Not implemented');
    },
    resendSignUpVerificationCode: () => {
        throw new Error('Not implemented');
    },
    signOut: () => {
        throw new Error('Not implemented');
    },
    forgotPassword: () => {
        throw new Error('Not implemented');
    },
    forgotPasswordSubmit: () => {
        throw new Error('Not implemented');
    },
    completeNewPasswordChallenge: () => {
        throw new Error('Not implemented');
    },
    resendCodeCountdown: {
        start: () => {
            throw new Error('Not implemented');
        },
        disabled: false,
        timeRemaining: '00:00',
    },
    changePassword: () => {
        throw new Error('Not implemented');
    },
    deleteUser: () => {
        throw new Error('Not implemented');
    },
});

export interface AuthContextProviderProps {
    children: ReactNode;
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const [user, setUser] = useState<CognitoUser | null>(null);
    const { start, counting, timeRemaining } = useCountdownTimer();
    const [isLoading, setIsLoading] = useState(true);
    const [signUpConfirmationEnabled, setSignUpConfirmationEnabled] = useState(false);
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<CognitoUser | boolean>(false);
    const initialPath = useRef(Platform.OS === 'web' ? window.location.pathname : '/');

    useEffect(() => {
        const getInitialUser = async () => {
            try {
                const initialUser = await Auth.currentAuthenticatedUser();
                setUser(initialUser);
            } catch (e) {
                setUser(null);
            } finally {
                setIsLoading(false);
            }
        };

        getInitialUser();
    }, []);

    useEffect(() => {
        const listener = Hub.listen('auth', async ({ payload }) => {
            if (payload.event === 'signIn') {
                setUser(payload.data);
            }

            if (payload.event === 'signOut') {
                setUser(null);
            }
        });
        return () => listener();
    }, []);

    const signIn = useCallback(async (params: SignInOpts): Promise<CognitoUser> => {
        return Auth.signIn(params);
    }, []);

    const signUp = useCallback(async (params: SignUpParams) => {
        await Auth.signUp(params);
    }, []);

    const confirmSignUp = useCallback(async (username: string, code: string) => {
        await Auth.confirmSignUp(username, code);
    }, []);

    const resendSignUpVerificationCode = useCallback(async (username: string) => {
        await Auth.resendSignUp(username);
    }, []);

    const signOut = useCallback(async () => {
        await Auth.signOut();
    }, []);

    const forgotPasswordSubmit = useCallback(async (username: string, code: string, password: string) => {
        await Auth.forgotPasswordSubmit(username, code, password);
    }, []);

    const forgotPassword = useCallback(async (username: string) => {
        await Auth.forgotPassword(username);
    }, []);

    const completeNewPassword = useCallback(async (user: CognitoUser, answer: string) => {
        await Auth.completeNewPassword(user, answer);
    }, []);

    const resendCodeCountdown = useMemo(
        () => ({
            start,
            disabled: counting,
            timeRemaining,
        }),
        [start, counting, timeRemaining],
    );

    const changePassword = useCallback(
        async (oldPassword: string, newPassword: string) => {
            await Auth.changePassword(user, oldPassword, newPassword);
        },
        [user],
    );

    const deleteUser = useCallback(async () => {
        await Auth.deleteUser();
    }, []);

    const value: AuthContextType = useMemo(
        () => ({
            isAuthenticated: !!user,
            user,
            isLoading,
            signUpConfirmationEnabled,
            setSignUpConfirmationEnabled,
            newPasswordConfirmation,
            setNewPasswordConfirmation,
            signIn,
            signUp,
            confirmSignUp,
            resendSignUpVerificationCode,
            signOut,
            forgotPassword,
            forgotPasswordSubmit,
            resendCodeCountdown,
            changePassword,
            initialPath: initialPath.current,
            completeNewPasswordChallenge: completeNewPassword,
            deleteUser,
        }),
        [
            user,
            isLoading,
            signUpConfirmationEnabled,
            newPasswordConfirmation,
            signIn,
            signUp,
            confirmSignUp,
            resendSignUpVerificationCode,
            signOut,
            forgotPassword,
            forgotPasswordSubmit,
            resendCodeCountdown,
            changePassword,
            initialPath,
            completeNewPassword,
            deleteUser,
        ],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
