import React from 'react';
import { Skeleton } from '~/components/skeleton';
import { UnitSuffix, UnitSuffixProps } from '~/components/unitSuffix';

import * as S from './Ingredient.styled';

interface IngredientProps {
    name: string;
    amount: {
        value: string | number;
        unit: UnitSuffixProps['unit'];
    };
}

export const Ingredient = ({ name, amount }: IngredientProps) => {
    return (
        <S.Container>
            <S.Name>{name}</S.Name>
            <S.Amount>
                <UnitSuffix unit={amount.unit}>{amount.value}</UnitSuffix>
            </S.Amount>
        </S.Container>
    );
};

export const IngredientSkeleton = () => {
    return (
        <S.Container>
            <S.Name>
                <Skeleton width="180" height="18" />
            </S.Name>
            <S.Amount>
                <Skeleton width="40" height="18" />
            </S.Amount>
        </S.Container>
    );
};

Ingredient.Skeleton = React.memo(IngredientSkeleton);
