import { IconName } from '~/icons';
import { MoreStackScreens, TabScreens } from '~/navigation/paths';

export type Link = {
    icon: IconName;
    iconActive: IconName;
    activeStatusScreens: string[];
    path: string;
    title: string;
};

export const mainLinks: Link[] = [
    {
        icon: 'dashboard-outline',
        iconActive: 'dashboard-fill',
        activeStatusScreens: ['Dashboard'],
        path: TabScreens.screens.Dashboard,
        title: 'Dashboard',
    },
    {
        icon: 'ruler-outline',
        iconActive: 'ruler-fill',
        activeStatusScreens: ['Measurements'],
        path: TabScreens.screens.Measurements,
        title: 'Pomiary',
    },
    {
        icon: 'atlas-gym-outline',
        iconActive: 'atlas-gym-fill',
        activeStatusScreens: ['TrainingTabStack', 'TrainingPlan', 'Training'],
        path: TabScreens.screens.TrainingTabStack.screens.TrainingPlan,
        title: 'Plan treningowy',
    },
    {
        icon: 'chat-outline',
        iconActive: 'chat-fill',
        activeStatusScreens: ['ChatTabStack', 'ChatScreen', 'ChatSelection'],
        path: TabScreens.screens.ChatTabStack.screens.ChatSelection,
        title: 'Czat',
    },
    {
        icon: 'diet-outline',
        iconActive: 'diet-filled',
        activeStatusScreens: ['Diet', 'Basics', 'Metabolism'],
        path: TabScreens.screens.Diet,
        title: 'Dieta',
    },
    {
        icon: 'shopping-list-outline',
        iconActive: 'shopping-list-filled',
        activeStatusScreens: ['ShoppingList'],
        path: MoreStackScreens.screens.ShoppingList,
        title: 'Lista zakupów',
    },
    {
        icon: 'pharmacy-outline',
        iconActive: 'pharmacy-filled',
        activeStatusScreens: ['Supplements'],
        path: MoreStackScreens.screens.Supplements,
        title: 'Suplementy',
    },
    {
        icon: 'training-atlas-outline',
        iconActive: 'training-atlas-filled',
        activeStatusScreens: ['ExercisesAtlas'],
        path: MoreStackScreens.screens.ExercisesAtlasStack.screens.ExercisesAtlas,
        title: 'Atlas ćwiczeń',
    },
];

export const footerLinks: Link[] = [
    {
        icon: 'account-outline',
        iconActive: 'account-fill',
        activeStatusScreens: ['More', 'HealthTests', 'Settings', 'OrdersHistory', 'ChangePassword'],
        path: TabScreens.screens.More,
        title: 'Konto',
    },
];
