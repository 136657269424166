import React, { ForwardedRef, forwardRef } from 'react';
import { Pressable, PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { TabAnimations } from '~/components/tabs/components/tab/Tab.animated';
import { TabStyledProps } from '~/components/tabs/components/tab/Tab.types';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './Tab.styled';

export interface TabProps<T extends string>
    extends TabStyledProps,
        Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'onPress'> {
    label: string;
    value: T;
    active?: boolean;
    onPress?: (value: T) => void;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);
const AnimatedLabel = Animated.createAnimatedComponent(S.Label);

export const TabComponent = <T extends string>(
    { label, appearance = 'primary', value, active, wide, marginRight, onPress }: TabProps<T>,
    ref: ForwardedRef<typeof Pressable | null>,
) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    const handlePress = () => onPress?.(value);

    return (
        <TabAnimations active={active} appearance={appearance} progress={pressableAnimationProgress}>
            {({ containerAnimatedStyles, labelAnimatedStyles }) => (
                <AnimatedContainer
                    {...pressableProps}
                    ref={ref}
                    style={containerAnimatedStyles}
                    wide={wide}
                    appearance={appearance}
                    marginRight={marginRight}
                    onPress={handlePress}
                >
                    <AnimatedLabel style={labelAnimatedStyles} appearance={appearance}>
                        {label}
                    </AnimatedLabel>
                </AnimatedContainer>
            )}
        </TabAnimations>
    );
};

export const Tab = forwardRef(TabComponent) as <T extends string>(
    props: TabProps<T> & { ref?: ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof TabComponent>;
