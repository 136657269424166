import { BottomTabBarProps, BottomTabHeaderProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { DashboardScreen } from '~/screens/dashboard';
import { DietScreen } from '~/screens/diet';
import { MeasurementsScreen } from '~/screens/measurements';
import { MoreScreen } from '~/screens/more';

import { TabParamsList } from './TabNavigation.types';
import { ChatStackNavigation } from './chat';
import { TabBar as TabBarComponent } from './components/tabBar';
import { DietTopNavigation, LeftTitleTopNavigation } from './components/topNavigation';
import { TrainingTabStackNavigation } from './trainingTab';

const Tab = createBottomTabNavigator<TabParamsList>();

const TabBar = (props: BottomTabBarProps) => <TabBarComponent {...props} />;
const DashboardHeader = (props: BottomTabHeaderProps) => <LeftTitleTopNavigation title="Dashboard" {...props} />;
const MeasurementsHeader = (props: BottomTabHeaderProps) => <LeftTitleTopNavigation title="Pomiary" {...props} />;
const DietHeader = (props: BottomTabHeaderProps) => <DietTopNavigation {...props} />;

export const TabNavigation = () => {
    const { canShowComingSoon, features } = useAppFeatures();
    return (
        <Tab.Navigator initialRouteName="Dashboard" screenOptions={{ header: () => null }} tabBar={TabBar}>
            {canShowComingSoon && (
                <Tab.Screen name="Dashboard" component={DashboardScreen} options={{ header: DashboardHeader }} />
            )}
            {(features.measurements || canShowComingSoon) && (
                <Tab.Screen
                    name="Measurements"
                    component={MeasurementsScreen}
                    options={{ header: MeasurementsHeader }}
                />
            )}
            <Tab.Screen
                name="TrainingTabStack"
                component={TrainingTabStackNavigation}
                options={{ header: () => null }}
            />

            {(features.diet || canShowComingSoon) && (
                <Tab.Screen name="Diet" component={DietScreen} options={{ header: DietHeader }} />
            )}
            {(features.chat || canShowComingSoon) && <Tab.Screen name="ChatTabStack" component={ChatStackNavigation} />}
            <Tab.Screen name="More" component={MoreScreen} />
        </Tab.Navigator>
    );
};
