import React, { useCallback, useEffect, useRef } from 'react';
import { ScrollView, useWindowDimensions, View } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Tab } from './components/tab';
import * as S from './Tabs.styled';
import { TabsStyledProps } from './Tabs.types';

export type TabType<T extends string> = {
    label: string;
    value: T;
};

export interface TabsProps<T extends string> extends TabsStyledProps {
    tabs: TabType<T>[];
    scrollable?: boolean;
    activeTab?: T;
    onSelectTab?: (value: T) => void;
}

export const Tabs = <T extends string>({
    tabs,
    appearance = 'primary',
    gap = 8,
    wide,
    scrollable = false,
    activeTab,
    onSelectTab,
    ...props
}: TabsProps<T>) => {
    const { width: screenWidth } = useWindowDimensions();
    const tabsRefs = useRef<any[]>(tabs.map(() => React.createRef())).current;
    const scrollRef = useRef<ScrollView | null>(null);
    const activeIndex = tabs.findIndex((tab) => tab.value === activeTab);
    const theme = useTheme();

    const PADDING_HORIZONTAL = theme.isWebDesktop ? 20 : 16;

    const scrollIntoView = useCallback(
        (index: number) => {
            tabsRefs[index]?.current?.measure((fx: number, fy: number, width: number, py: number, px: number) => {
                if (px < PADDING_HORIZONTAL || px + width > screenWidth - PADDING_HORIZONTAL) {
                    scrollRef.current?.scrollTo({
                        x:
                            px < PADDING_HORIZONTAL
                                ? fx - PADDING_HORIZONTAL
                                : fx + width - screenWidth + PADDING_HORIZONTAL,
                        y: 0,
                        animated: true,
                    });
                }
            });
        },
        [screenWidth, tabsRefs, PADDING_HORIZONTAL],
    );

    useEffect(() => {
        if (scrollable && !wide) {
            scrollIntoView(activeIndex);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    return (
        <S.Container
            as={scrollable && !wide ? ScrollView : View}
            ref={scrollRef}
            horizontal
            showsHorizontalScrollIndicator={false}
            wide={wide}
            {...props}
            contentContainerStyle={
                scrollable
                    ? {
                          paddingHorizontal: PADDING_HORIZONTAL,
                      }
                    : undefined
            }
        >
            {tabs.map(({ label, value }, index) => (
                <Tab
                    ref={tabsRefs[index]}
                    key={label}
                    appearance={appearance}
                    label={label}
                    value={value}
                    active={activeTab === value}
                    wide={wide}
                    marginRight={index !== tabs.length - 1 ? gap : undefined}
                    onPress={onSelectTab}
                />
            ))}
        </S.Container>
    );
};
