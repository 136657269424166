export const formatDate = (dateISOString: string): string => {
    const daysOfWeek: string[] = ['Nd', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'];
    const months: string[] = [
        'Stycznia',
        'Lutego',
        'Marca',
        'Kwietnia',
        'Maja',
        'Czerwca',
        'Lipca',
        'Sierpnia',
        'Września',
        'Października',
        'Listopada',
        'Grudnia',
    ];

    const date = new Date(dateISOString);
    const dayOfWeek = daysOfWeek[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];

    return `${dayOfWeek}, ${day} ${month}`;
};
