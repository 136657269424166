import {
    DashboardStackScreens,
    DietStackScreens,
    MeasurementsStackScreens,
    TrainingStackScreens,
} from '~/navigation/paths';

// NOTE: List of screens that should not be displayed on desktop
export const desktopBlacklistScreens = [
    TrainingStackScreens.screens.TrainingPlanChoice,
    TrainingStackScreens.screens.TrainingRules,
    MeasurementsStackScreens.screens.Measurement,
    MeasurementsStackScreens.screens.Table,
    MeasurementsStackScreens.screens.Metamorphosis,
    DietStackScreens.screens.PlanChoice,
    DietStackScreens.screens.Meal,
    DashboardStackScreens.screens.ActivityDetails,
    DashboardStackScreens.screens.ActivityHistory,
] as const;
