import React, { FC, useState } from 'react';
import { Circle, Rect } from 'react-content-loader/native';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { DesktopModal, DesktopModalProps } from '~/components/modal/desktopModal';
import { PortionCounter } from '~/components/portionCounter';
import { SectionHeading } from '~/components/sectionHeading';
import { Skeleton } from '~/components/skeleton';
import { Tabs } from '~/components/tabs';
import { MealImage } from '~/screens/diet/components/mealImage';
import { Ingredient } from '~/screens/diet/meal/components/ingredient';
import { MacroTile } from '~/screens/diet/meal/components/macroTile';
import { Step } from '~/screens/diet/meal/components/step';
import { useMeal } from '~/screens/diet/meal/hooks/useMeal';

import * as S from './MealModal.styled';

export interface MealModalProps extends Omit<DesktopModalProps, 'children'> {
    mealId?: string | null;
}

export const MealModal: FC<MealModalProps> = ({ mealId, ...props }) => {
    const { meal, isLoading, tabs, activeTab, setActiveTab } = useMeal({ id: mealId });
    const [portionsCount, setPortionsCount] = useState(1);
    const theme = useTheme();

    const renderLeftSide = () => {
        if (!meal || isLoading) {
            return <LeftSideSkeleton />;
        }

        return (
            <>
                <S.MealImageWrapper>
                    <MealImage
                        source={meal.imageSrc}
                        backgroundColor={theme.fill.level2}
                        foregroundColor={theme.fill.level3}
                    />
                </S.MealImageWrapper>
                <S.Title>{meal.name}</S.Title>
                <S.PortionContainer>
                    <PortionCounter value={portionsCount} maxValue={10} onChange={setPortionsCount} />
                </S.PortionContainer>
                <S.TabsContainer>
                    <S.TabsWrapper>
                        <Tabs
                            wide
                            appearance={'secondary'}
                            tabs={tabs}
                            activeTab={activeTab}
                            onSelectTab={setActiveTab}
                        />
                    </S.TabsWrapper>
                </S.TabsContainer>
                <S.MacrosContainer>
                    {Object.values(meal.macros).map((macro, index) => (
                        <S.MacroTileWrapper key={macro.label} last={Object.values(meal.macros).length - 1 === index}>
                            <MacroTile
                                value={macro.value * portionsCount}
                                label={macro.label}
                                unit={'unit' in macro ? macro.unit : undefined}
                            />
                        </S.MacroTileWrapper>
                    ))}
                </S.MacrosContainer>
            </>
        );
    };

    const renderRightSide = () => {
        if (!meal || isLoading) {
            return <RightSideSkeleton />;
        }

        return (
            <S.RightSideInner>
                <S.HeadingWrapper>
                    <SectionHeading variant="header" title="Składniki" />
                </S.HeadingWrapper>
                {meal.ingredients.map((ingredient) => (
                    <S.IngredientWrapper key={ingredient.name}>
                        <Ingredient
                            name={ingredient.name}
                            amount={{
                                value: ingredient.amount.value * portionsCount,
                                unit: ingredient.amount.unit,
                            }}
                        />
                    </S.IngredientWrapper>
                ))}

                <S.StepsContainer>
                    <S.HeadingWrapper>
                        <SectionHeading variant="header" title="Sposób przygotowania" />
                    </S.HeadingWrapper>
                    <S.ScrollableSteps>
                        {meal.steps.map((step, index) => (
                            <S.StepWrapper key={`step-${index}`}>
                                <Step index={index + 1} content={step} />
                            </S.StepWrapper>
                        ))}
                    </S.ScrollableSteps>
                </S.StepsContainer>
            </S.RightSideInner>
        );
    };

    return (
        <DesktopModal {...props}>
            <S.Container>
                <S.LeftSide>{renderLeftSide()}</S.LeftSide>
                <S.RightSide>{renderRightSide()}</S.RightSide>
            </S.Container>
        </DesktopModal>
    );
};

const LeftSideSkeleton = () => (
    <>
        <S.MealImageWrapper>
            <Skeleton rx={0} />
        </S.MealImageWrapper>
        <S.Title>
            <Skeleton width="80%" height="31" />
        </S.Title>
        <S.PortionContainer>
            <Skeleton width="25%" height="24" />
        </S.PortionContainer>
        <S.TabsContainer>
            <S.TabsWrapper>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ marginRight: 12, flex: 1 }}>
                        <Skeleton height="40" rx={0} />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Skeleton height="40" rx={0} />
                    </View>
                </View>
            </S.TabsWrapper>
        </S.TabsContainer>
        <S.MacrosContainer>
            {[...Array(4)].map((_, index) => (
                <S.MacroTileWrapper key={index} last={index === 3}>
                    <MacroTile.Skeleton />
                </S.MacroTileWrapper>
            ))}
        </S.MacrosContainer>
    </>
);

const RightSideSkeleton = () => (
    <S.RightSideInner>
        <S.HeadingWrapper>
            <View style={{ marginTop: 8, marginBottom: 8 }}>
                <Skeleton width="45%" height="24" />
            </View>
        </S.HeadingWrapper>
        {[...Array(5)].map((_, index) => (
            <S.IngredientWrapper key={index}>
                <Ingredient.Skeleton />
            </S.IngredientWrapper>
        ))}

        <S.StepsContainer>
            <S.HeadingWrapper>
                <View style={{ marginTop: 8, marginBottom: 8 }}>
                    <Skeleton width="45%" height="24" />
                </View>
            </S.HeadingWrapper>
            <S.ScrollableSteps>
                {[...Array(5)].map((_, index) => (
                    <S.StepWrapper key={index}>
                        <Skeleton height="24">
                            <Circle cx="12" cy="12" r="12" />
                            <Rect x="44" y="5" width="400" height="14" rx={6} ry={6} />
                        </Skeleton>
                    </S.StepWrapper>
                ))}
            </S.ScrollableSteps>
        </S.StepsContainer>
    </S.RightSideInner>
);
