import { Pressable, Text } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme/mixins';

import { TabStyledProps } from './Tab.types';

export const Container = styled(Pressable)<TabStyledProps>`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    border-radius: 8px;
    border: 2px solid;
    user-select: none;

    ${({ wide }) =>
        wide &&
        css`
            flex: 1;
        `}

    ${({ marginRight }) =>
        marginRight &&
        css`
            margin-right: ${marginRight}px;
        `}

    ${({ appearance }) =>
        appearance === 'secondary' &&
        css`
            border: 0;
            border-bottom-width: 2px;
            border-radius: 0;
            padding: 8px 8px 16px;
        `}
`;

export const Label = styled(Text)<TabStyledProps>`
    ${mixins.typography.ui.regular14};

    ${({ appearance }) =>
        appearance === 'secondary' &&
        css`
            ${mixins.typography.ui.semiBold16};
        `}
`;
