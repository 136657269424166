import { FC, ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/loader';
import { Toasts } from '~/components/toasts';
import { BottomTabWrapper } from '~/components/wrappers';
import { MainLayoutStyledProps } from '~/layouts/native/mainLayout/MainLayout.types';

import * as S from './MainLayout.styled';

export interface MainLayoutProps extends MainLayoutStyledProps {
    children: ReactNode;
    style?: ViewStyle;
    scrollable?: boolean;
    isLoading?: boolean;
}

export const MainLayout: FC<MainLayoutProps> = ({
    children,
    fullWidth,
    style,
    bottomContent,
    isBottomRounded = true,
    scrollable = true,
    isLoading,
    verticalSpacing = true,
    noBackground,
}) => {
    const inset = useSafeAreaInsets();
    const theme = useTheme();

    const radiusStyles = !isBottomRounded
        ? {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
          }
        : {};

    return (
        <S.ContentWrapper>
            <S.Container
                inset={inset}
                style={{
                    ...radiusStyles,
                    ...style,
                }}
                bottomContent={!!bottomContent}
                isBottomRounded={theme.isWebDesktop ? false : isBottomRounded}
                noBackground={noBackground}
            >
                {isLoading && (
                    <S.LoaderWrapper>
                        <Loader size="xl" color={theme.icon.interactive} />
                    </S.LoaderWrapper>
                )}
                {scrollable ? (
                    <S.ScrollContainer
                        fullWidth={fullWidth}
                        noBackground={noBackground}
                        verticalSpacing={verticalSpacing}
                        automaticallyAdjustKeyboardInsets
                    >
                        {children}
                    </S.ScrollContainer>
                ) : (
                    <S.StaticContainer fullWidth={true} verticalSpacing={false}>
                        {children}
                    </S.StaticContainer>
                )}
            </S.Container>
            <Toasts />
            {bottomContent && <BottomTabWrapper>{bottomContent}</BottomTabWrapper>}
        </S.ContentWrapper>
    );
};
