export const instructions = [
    {
        id: 'arm-instruction',
        title: 'Obwód ramienia',
        imageSrc: require('~/assets/images/instruction_arm.webp'),
    },
    {
        id: 'chest-instruction',
        title: 'Obwód klatki piersiowej',
        imageSrc: require('~/assets/images/instruction_chest.webp'),
    },
    {
        id: 'waist-instruction',
        title: 'Obwód pasa',
        imageSrc: require('~/assets/images/instruction_waist.webp'),
    },
    {
        id: 'hips-instruction',
        title: 'Obwód bioder',
        imageSrc: require('~/assets/images/instruction_hips.webp'),
    },
    {
        id: 'thigh-instruction',
        title: 'Obwód uda',
        imageSrc: require('~/assets/images/instruction_thigh.webp'),
    },
    {
        id: 'calf-instruction',
        title: 'Obwód łydki',
        imageSrc: require('~/assets/images/instruction_calf.webp'),
    },
];
