import { getPathFromState, getStateFromPath } from '@react-navigation/native';

//Workaround for 404 pathname - https://github.com/react-navigation/react-navigation/issues/9102

type GetLinkingPathOptions = () => {
    getStateFromPath: typeof getStateFromPath;
    getPathFromState: typeof getPathFromState;
};

export const getLinkingPathOptions: GetLinkingPathOptions = () => {
    let currentUrl = '';

    const pageNotFoundPath = '/NotFound';

    return {
        getStateFromPath: (path, config) => {
            currentUrl = path;
            return getStateFromPath(path, config);
        },
        getPathFromState: (state, config) => {
            const path = getPathFromState(state, config);

            return path === pageNotFoundPath ? currentUrl : path;
        },
    };
};
