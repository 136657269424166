import { TrainingCycle } from '~/enhancers/trainingPlanContext/trainingPlan.types';

import { TrainingHeader, TransformedDataItem } from './TrainingPlanScreen.types';

export const transformData = (data: TrainingCycle[]): TransformedDataItem[] => {
    return data.reduce((acc, cycle) => {
        const header: TrainingHeader = {
            title: cycle.title,
        };

        acc.push(header);
        acc.push(...cycle.days);

        return acc;
    }, [] as TransformedDataItem[]);
};
