import { SQLiteAdapter } from '@aws-amplify/datastore-storage-adapter/SQLiteAdapter';
import config from '@pg/backend/dist/config';
import { Amplify } from 'aws-amplify';
import { Platform } from 'react-native';

export const configureBackend = async () => {
    Amplify.configure({
        ...config,
        logLevel: 'DEBUG',
        storageAdapter: Platform.OS === 'web' ? undefined : SQLiteAdapter,
    });
};
