import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { usePressableState } from '~/hooks/usePressableState';

import { TrainingCheckAnimations } from './TrainingCheck.animated';
import * as S from './TrainingCheck.styled';

export interface TrainingCheckProps extends Omit<PressableProps, 'onPress'> {
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);
const AnimatedBackground = Animated.createAnimatedComponent(S.Background);
const AnimatedCheckIcon = Animated.createAnimatedComponent(S.CheckIcon);

export const TrainingCheck: React.FC<TrainingCheckProps> = ({ checked = false, ...props }) => {
    const theme = useTheme();
    const { pressableProps } = usePressableState();

    const handlePress = () => {
        props.onChange?.(!checked);
    };

    return (
        <S.Container {...props} {...pressableProps} onPress={handlePress}>
            <TrainingCheckAnimations
                checked={checked}
                hovered={pressableProps.hovered}
                pressed={pressableProps.pressed}
            >
                {({ borderAnimatedStyles, backgroundAnimatedStyles, checkIconAnimatedStyles }) => (
                    <>
                        <AnimatedBorder pointerEvents="none" style={borderAnimatedStyles} />
                        <AnimatedBackground pointerEvents="none" style={backgroundAnimatedStyles} />
                        <AnimatedCheckIcon style={checkIconAnimatedStyles}>
                            <Icon name="check-small" size={24} color={theme.icon.positiveSurface} />
                        </AnimatedCheckIcon>
                    </>
                )}
            </TrainingCheckAnimations>
        </S.Container>
    );
};
