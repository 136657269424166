import { Pressable, Text, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { mixins } from '~/theme';

import { Variant } from './Day.types';

interface DayWrapperProps {
    variant?: Variant;
}

interface DayTextProps {
    variant?: Variant;
}

const dayWrapperVariantStylesMap: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    start: css`
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: ${({ theme }) => theme.fill.informative};
    `,
    end: css`
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: ${({ theme }) => theme.fill.informative};
    `,
    both: css`
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: ${({ theme }) => theme.fill.informative};
    `,
    between: css`
        background-color: ${({ theme }) => theme.fill.informative20};
    `,
    default: css`
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        background-color: transparent;
    `,
};

const dayTextVariantStylesMap: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    start: css`
        color: ${({ theme }) => theme.text.interactiveSurface};
    `,
    end: css`
        color: ${({ theme }) => theme.text.interactiveSurface};
    `,
    both: css`
        color: ${({ theme }) => theme.text.interactiveSurface};
    `,
    between: css`
        color: ${({ theme }) => theme.text.primary};
    `,
    default: css`
        color: ${({ theme }) => theme.text.primary};
    `,
};

export const DayWrapper = styled(Pressable)<DayWrapperProps>`
    width: 100%;
    height: 56px;
    padding-top: 6px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    ${({ variant }) => dayWrapperVariantStylesMap[variant || 'default']};
`;
export const DayText = styled(Text)<DayTextProps>`
    ${mixins.typography.ui.medium14}
    text-align: center;
    ${({ variant }) => dayTextVariantStylesMap[variant || 'default']};
`;
export const TodayWrapper = styled(View)`
    border-radius: 3px;
    background-color: ${({ theme }) => theme.fill.critical};
    width: 28px;
    height: 22px;
    justify-content: center;
    align-items: center;
`;

export const TodayText = styled(Text)`
    ${mixins.typography.ui.medium14}
    color: ${({ theme }) => theme.text.criticalSurface};
    text-align: center;
`;
