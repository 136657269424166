import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useRef } from 'react';
import { CustomMarkedDates, DateData } from 'react-native-calendars';
import { CalendarListImperativeMethods } from 'react-native-calendars/src/calendar-list';
import { Button } from '~/components/button';
import { INITIAL_DATE, LocaleConfig, RANGE } from '~/constants/calendar';
import { DashboardNavigationProp } from '~/screens/dashboard/DashboardScreen.types';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import * as S from './ActivityCalendarContent.styled';
import { Day } from './day/Day';

const markedDates = {
    '2023-05-27': { activityType: 'rest' },
    '2023-05-28': { activityType: 'single' },
    '2023-05-29': { activityType: 'multiple' },
};

export const ActivityCalendarContent = () => {
    const navigation = useNavigation<DashboardNavigationProp>();
    const calendarRef = useRef<CalendarListImperativeMethods | null>(null);

    const onDayPress = useCallback(
        (day: DateData) => {
            navigation.navigate('ActivityDetails', { date: day.dateString });
        },
        [navigation],
    );

    const handleEmmiter = () => {
        calendarRef.current?.scrollToDay(new Date(), 100, true);
    };

    useEffect(() => {
        Emitter.on(EmitterEvent.scrollToDate, handleEmmiter);
        return () => {
            Emitter.off(EmitterEvent.scrollToDate, handleEmmiter);
        };
    }, []);

    return (
        <S.Container>
            <S.WeekWrapper>
                {LocaleConfig.locales.pl.dayNamesShort.map((day: string) => (
                    <S.WeekName key={day}>{day}</S.WeekName>
                ))}
            </S.WeekWrapper>
            <S.CalendarList
                current={INITIAL_DATE}
                pastScrollRange={RANGE}
                futureScrollRange={RANGE}
                markingType="custom"
                ref={calendarRef}
                //@ts-ignore For some reason it accepts string and this works, but TS is complaining that is should be number
                calendarWidth="100%"
                theme={S.calendarTheme as any}
                onDayPress={onDayPress}
                markedDates={markedDates as CustomMarkedDates} //Dodałem custom typ do marking i się pluje :D
                firstDay={1}
                hideDayNames
                dayComponent={Day}
            />
            <S.TodayContainer>
                <Button variant="secondary" size="s" onPress={handleEmmiter} icon="calendar">
                    Dzisiaj
                </Button>
            </S.TodayContainer>
        </S.Container>
    );
};
