import React from 'react';
import { useTheme } from 'styled-components/native';
import { SectionHeading } from '~/components/sectionHeading';
import { useDiet } from '~/enhancers/dietContext';
import { DietPlanInProgress } from '~/screens/diet/components/dietPlanInProgress';
import { NutritionTile } from '~/screens/diet/dietSettings/components/nutritionTile';

import { DietLayout } from '../components/dietLayout/DietLayout';
import { NoDietPlan } from '../components/noDietPlan';
import { DietBasicsScreenContent } from './DietBasicsScreen.native';
import * as S from './DietBasicsScreen.styled';

export const DietBasicsScreen = () => {
    const { isActivePlanInProgress, userHasDietSubscription} = useDiet();
    const theme = useTheme();

    const renderContent = () => {
        if (!theme.isWebDesktop) {
            return <DietBasicsScreenContent />;
        }

        if (!userHasDietSubscription) {
          return <NoDietPlan />;
        }

        return (
            <S.Container>
                <S.HeaderContainer>
                    <SectionHeading variant="header" title="Podstawy diety" />
                </S.HeaderContainer>
                <S.Content wide={isActivePlanInProgress}>
                    {isActivePlanInProgress ? (
                        <DietPlanInProgress />
                    ) : (
                        <>
                            <S.NutritionTilesContainer>
                                <S.NutritionTileWrapper>
                                    <NutritionTile kcal={3150} fat={98} carbs={420} protein={120} type="training" />
                                </S.NutritionTileWrapper>
                                <S.NutritionTileWrapper last>
                                    <NutritionTile kcal={2850} fat={90} carbs={380} protein={110} type="non-training" />
                                </S.NutritionTileWrapper>
                            </S.NutritionTilesContainer>
                            {/* TODO handle rich content*/}
                            <S.RichContentContainer>
                                <S.TmpText>json content</S.TmpText>
                            </S.RichContentContainer>
                        </>
                    )}
                </S.Content>
            </S.Container>
        );
    };

    return <DietLayout seo={{ title: 'Podstawy diety' }} content={renderContent()} />;
};
