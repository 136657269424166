import { TrainingCardioExercise, TrainingDayGroupType, TrainingExercise } from '@pg/backend';
import { ExpandedTrainingDay } from '~/api/training/useGetExpandedTrainingDay';
import { getFormattedDurationString } from '~/utils/dates';
import { getGroupTitle, getSectionName, isTrainingCardioExercise, sortByOrderField } from '~/utils/training';

import { SectionItem } from './TrainingOverviewSheet.types';

export const transformData = (data?: ExpandedTrainingDay | null): SectionItem[] => {
    if (!data) return [];

    const orderedGroups = data.groups.sort(sortByOrderField);

    return orderedGroups
        .map((group, index) => {
            const groupExercises = data?.[
                group.type === TrainingDayGroupType.CARDIO ? 'trainingCardioExercises' : 'trainingExercises'
            ] as (TrainingExercise | TrainingCardioExercise)[];

            const isFirstOfGroupType = orderedGroups[index - 1]?.type !== group.type;

            return {
                name: getSectionName(group, data!.exerciseGroupsMap),
                groupTitle: getGroupTitle(group),
                groupType: group.type as TrainingDayGroupType,
                isFirstOfGroupType,
                data: groupExercises
                    .filter((i) => i.trainingdaygroupID === group.id)
                    .sort(sortByOrderField)
                    .map((trainingExercise) => {
                        if (isTrainingCardioExercise(trainingExercise)) {
                            const time =
                                data.cardioExercisesDataMap[trainingExercise.trainingCardioExerciseCardioExerciseDataId]
                                    ?.time;

                            if (!time) {
                                return null;
                            }

                            return {
                                name: data.cardioExercisesMap[trainingExercise.cardioexerciseID].name!,
                                time: getFormattedDurationString(time ?? 0),
                                id: trainingExercise.id,
                            };
                        }

                        const series = data.exercisesDataMap[trainingExercise.trainingExerciseExerciseDataId].series;

                        if (!series) {
                            return null;
                        }
                        return {
                            name: data.exercisesMap[trainingExercise.exerciseID].name!,
                            sets: `${series} x ${
                                data.exercisesDataMap[trainingExercise.trainingExerciseExerciseDataId].reps
                            }`,
                            id: trainingExercise.id,
                        };
                    })
                    .filter(Boolean) as SectionItem['data'],
            };
        })
        .filter((i) => i.data.length > 0);
};
