import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { HoverableProps } from '~/types/hoverable';

export const Container = styled(Pressable)<HoverableProps>`
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border-width: 1px;
    background-color: ${({ theme }) => theme.fill.level3};
    align-items: center;
    justify-content: center;
`;
