import { Text, View } from 'react-native';
import styled from 'styled-components/native';

export const SeriesTableRow = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
`;

export const SeriesNumberCell = styled(Text)<{ filled?: boolean }>`
    align-items: center;
    justify-content: center;
    width: 32px;
    color: ${({ theme, filled }) => (filled ? theme.text.positive : theme.text.tertiary)};
`;

export const SeriesInputCell = styled(View)`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    color: ${({ theme }) => theme.text.tertiary};
`;

export const SeriesCheckboxCell = styled(View)`
    align-items: center;
    justify-content: center;
    width: 24px;
`;
