import { yupResolver } from '@hookform/resolvers/yup';
import { CardioExercise, CardioExerciseData } from '@pg/backend';
import React, { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDebouncedSubmit } from '~/hooks/useDebouncedSubmit';
import { useTrainingScreen } from '~/screens/training/TrainingScreen.context';
import { ExerciseInput } from '~/screens/training/components/exerciseInput';
import { SeriesTableRowFormSchemaCardio } from '~/screens/training/components/seriesTableRowForm/SeriesTableRowForm.schema';
import { TimerButton } from '~/screens/training/components/timerButton';

import { useTimer } from '../../enhancers/timer.context';
import * as S from './SeriesTableRowForm.styled';
import { CardioFormValues } from './SeriesTableRowForm.types';

export interface SeriesTableCardioRowProps {
    data: CardioExerciseData;
    finishedData?: CardioExerciseData | null;
    exercise: CardioExercise;
    index: number;
    trainingCardioExerciseId: string;
}

export const SeriesTableCardioRowForm: FC<SeriesTableCardioRowProps> = ({
    exercise,
    data,
    index,
    trainingCardioExerciseId,
    finishedData,
}) => {
    const trainingScreen = useTrainingScreen();
    const { watch, control, handleSubmit, setValue } = useForm<CardioFormValues>({
        defaultValues: {
            time: finishedData?.time ?? data.time ?? '',
        },
        resolver: yupResolver(SeriesTableRowFormSchemaCardio),
    });

    const onSubmit = useDebouncedSubmit<SubmitHandler<CardioFormValues>>(async (formValues) => {
        try {
            await trainingScreen.updateCardioData(trainingCardioExerciseId, {
                time: Number(formValues.time),
            });
        } catch (e) {}
    });

    const done = watch('done');

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [handleSubmit, onSubmit, watch]);

    const timer = useTimer();

    const startCardioTimer = () => {
        timer.startCardio({
            id: `${data.id}.${index}`,
            duration: data.time ?? 0,
            countdownDuration: 5,
            title: exercise.name ?? '',
            onEnd: (progress) => {
                setValue(`started`, false);
                setValue('time', progress ?? data.time ?? 0);
            },
        });
    };

    return (
        <S.SeriesTableRow>
            <S.SeriesNumberCell filled={false}>{index + 1}</S.SeriesNumberCell>

            <S.SeriesInputCell>
                <Controller
                    name="time"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <ExerciseInput
                            {...field}
                            type="time"
                            value={`${field.value}`}
                            filled={done}
                            keyboardType="number-pad"
                            onChangeText={onChange}
                        />
                    )}
                />
            </S.SeriesInputCell>

            <S.SeriesCheckboxCell>
                <Controller
                    name="started"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TimerButton
                            started={value}
                            onChange={(started: boolean) => {
                                if (started) {
                                    startCardioTimer();
                                }
                                if (!started) {
                                    timer.close();
                                }
                                onChange(started);
                            }}
                        />
                    )}
                />
            </S.SeriesCheckboxCell>
        </S.SeriesTableRow>
    );
};
