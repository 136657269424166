import { Pressable, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(Pressable)`
    padding: 12px 0 18px;
    flex-direction: row;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            padding: 24px 16px;
            flex: 0 0 33.33333%;
            max-width: 376px;
            flex-direction: column;
        `}
`;

export const Content = styled(View)`
    flex: 1;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            flex: auto;
        `}
`;

export const ThumbnailContainer = styled(View)`
    width: 80px;
    height: 60px;
    margin-right: 16px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 100%;
            height: auto;
            aspect-ratio: 1.77;
            margin-right: 0;
            margin-bottom: 12px;
        `}
`;

export const ThumbnailWrapper = styled(View)`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    background: ${({ theme }) => theme.fill.level3};
`;

export const ThumbnailPlaceholder = styled(View)`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TitleContainer = styled(View)`
    margin-bottom: 8px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-bottom: 12px;
        `}
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.h.medium16};
        `}
`;

export const TagContainer = styled(View)`
    margin-right: 4px;
`;
