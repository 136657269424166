import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { ExerciseGroupHeaderProps } from '~/screens/training/components/exerciseGroupHeader/ExerciseGroupHeader.types';
import { mixins } from '~/theme';

export const Container = styled(View)`
    background: ${({ theme }) => theme.fill.level2};
`;

export const Title = styled(Text)<{ size: ExerciseGroupHeaderProps['size'] }>`
    ${({ size }) => size === 'large' && mixins.typography.h.semiBold20};
    ${({ size }) => size === 'normal' && mixins.typography.h.medium16};
    text-align: ${({ theme }) => (theme.isWebDesktop ? 'left' : 'center')};
    color: ${({ theme }) => theme.text.secondary};
`;
