import { View } from 'react-native';
import styled from 'styled-components/native';

import { BottomSheet as BottomSheetBase } from '../bottomSheetModal';

export const BottomSheet = styled(BottomSheetBase)`
    border-radius: 20px;
`;

export const Container = styled(View)`
    position: relative;
`;
