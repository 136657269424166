import { format } from 'date-fns';
import { LocaleConfig } from 'react-native-calendars';

LocaleConfig.locales.en = LocaleConfig.locales[''];
LocaleConfig.locales.pl = {
    monthNames: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
    ],
    monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    dayNames: ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'],
    dayNamesShort: ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb', 'Nd'],
    today: 'Dzisiaj',
};

export const RANGE = 24;
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const INITIAL_DATE = format(new Date(), DEFAULT_DATE_FORMAT);

export { LocaleConfig };
