import React from 'react';
import { ComingSoon } from '~/components/comingSoon';

export const ExercisesAtlasComingSoon = () => (
    <ComingSoon
        title="Wkrótce w tym miejscu znajdziesz również:"
        features={[
            'Atlas wszystkich ćwiczeń, z których korzystam przy układaniu treningów',
            'Autorskie nagrania video z odpowiednio wykonywaną techniką',
        ]}
        imageSrc={require('~/assets/images/exercise_atlas_coming_soon.png')}
    />
);
