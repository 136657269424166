import { Goal } from '@pg/backend';
import React from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { formatDate } from '~/utils/dates';

import * as S from './Target.styled';

export interface TargetProps extends Partial<Goal> {}

export const Target: React.FC<TargetProps> = ({ startDate, name, description }) => {
    const theme = useTheme();

    return (
        <S.Container>
            <Icon name="trophy-fill" size={28} color={theme.icon.disabled} />

            <S.Content>
                {startDate ? (
                    <>
                        <S.Date>Twój cel od {formatDate(startDate)}</S.Date>
                        <S.Target>{name}</S.Target>
                        {description && <S.Description>{description}</S.Description>}
                    </>
                ) : (
                    <>
                        <S.NoTarget>Brak celu</S.NoTarget>
                        <S.Description>Pojawi się on wraz z otrzymaniem planu</S.Description>
                    </>
                )}
            </S.Content>
        </S.Container>
    );
};
