import { Training } from '@pg/backend';
import React, { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useGetActiveTraining } from '~/api/training/useGetActiveTraining';
import { useGetTraining } from '~/api/training/useGetTraining';

export interface TrainingContextType {
    id: string | null;
    data: Training | null;
    setTraining: (id: string) => void;
    isFetching: boolean;
}

export const TrainingContext = createContext<TrainingContextType>({
    id: null,
    data: null,
    isFetching: true,
    setTraining: () => {
        throw new Error('Not implemented');
    },
});

export interface TrainingContextProviderProps {
    children: ReactNode;
}

export const TrainingContextProvider: FC<TrainingContextProviderProps> = ({ children }) => {
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const activeTraining = useGetActiveTraining();
    const selectedTraining = useGetTraining(selectedId ?? '');

    const value = useMemo(
        () => ({
            id: selectedId ?? activeTraining?.data?.id ?? null,
            data: selectedTraining?.data ?? activeTraining?.data ?? null,
            setTraining: setSelectedId,
            isFetching: selectedTraining?.isFetching ?? activeTraining?.isFetching ?? false,
        }),
        [
            activeTraining?.data,
            activeTraining?.isFetching,
            selectedId,
            selectedTraining?.data,
            selectedTraining?.isFetching,
        ],
    );

    return <TrainingContext.Provider value={value}>{children}</TrainingContext.Provider>;
};

export const useTraining = () => useContext(TrainingContext);
