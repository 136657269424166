import { Text, View } from 'react-native';
import { CalendarList as CalendarListBase } from 'react-native-calendars';
import styled, { css } from 'styled-components/native';
import { defaultTheme, mixins } from '~/theme';

import { ContainerProps } from './Calendar.types';

export const calendarTheme = {
    calendarBackground: defaultTheme.fill.level1,
    'stylesheet.calendar.header': {
        monthText: {
            color: defaultTheme.text.primary,
            fontWeight: '500',
            fontFamily: defaultTheme.font.primaryMedium,
            marginBottom: 18,
            fontSize: 16,
            lineHeight: 20,
            letterSpacing: -0.16,
        },
        header: {
            justifyContent: 'flex-start',
            marginTop: 20,
        },
    },
    'stylesheet.day.basic': {
        base: {
            height: 50,
            width: 32,
            alignItems: 'center',
        },
        text: {
            fontSize: 14,
        },
    },
};

export const CalendarList = styled(CalendarListBase)``;

export const MonthWrapper = styled(View)<{ isHeaderRounded: boolean }>`
    flex-direction: row;
    justify-content: center;
    padding: 17px 16px 5px;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
    background-color: ${({ theme }) => theme.fill.level1};
    width: 100%;
    ${({ isHeaderRounded }) =>
        isHeaderRounded &&
        css`
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
        `}
`;

export const MonthText = styled(Text)`
    ${mixins.typography.ui.regular12};
    text-align: center;
    flex: 1;
    color: ${({ theme }) => theme.text.tertiary};
`;

export const Container = styled(View)<ContainerProps>`
    flex: 1;
`;
