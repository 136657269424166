import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface IconButtonAnimationsRenderPropValue {
    backgroundColor: string;
}

export interface IconButtonAnimationsProps extends PressableAnimationsProps<IconButtonAnimationsRenderPropValue> {
    disabled: boolean | null;
}

export const IconButtonAnimation = ({ progress, children, disabled }: IconButtonAnimationsProps) => {
    const theme = useTheme();

    const backgroundColorProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const backgroundColorMap: Record<string, string> = {
        default: 'transparent',
        hovered: theme.palette.opacity['08'].rgba,
        pressed: theme.palette.opacity['20'].rgba,
    };

    const animatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: disabled
                ? backgroundColorMap.default
                : interpolateColor(
                      backgroundColorProgress.value,
                      [0, 1, 2],
                      [backgroundColorMap.default, backgroundColorMap.hovered, backgroundColorMap.pressed],
                  ),
        };
    }, [disabled]);

    return children(animatedStyle);
};
