import { format } from 'date-fns';

import { Avatar } from '../avatar';
import * as S from './ChatMessage.styled';

export const ChatMessage = ({ ...props }) => {
    //console.log(props);

    return (
        <S.Container>
            <Avatar url={props.currentMessage.user.avatar} />
            <S.MessageWrapper>
                <S.HeaderWrapper>
                    <S.UserName>{props.currentMessage.user.name ?? 'Ty'}</S.UserName>
                    <S.DateTime>{format(props.currentMessage.createdAt, 'dd.MM.yyyy HH:mm')}</S.DateTime>
                </S.HeaderWrapper>
                <S.Message>{props.currentMessage.text}</S.Message>
            </S.MessageWrapper>
        </S.Container>
    );
};
