import React, { useState } from 'react';
import Cropper, { Area, MediaSize, Point } from 'react-easy-crop';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { DesktopModal, DesktopModalProps } from '~/components/modal/desktopModal';

import * as S from './ImageCropModal.styled';

export interface ImageCropModalProps extends Omit<DesktopModalProps, 'children'> {
    image: string;
    aspect?: number;
    onCrop: (croppedAreaPixels?: Area) => void;
}

export const ImageCropModal: React.FC<ImageCropModalProps> = ({ image, aspect, onCrop, ...props }) => {
    const theme = useTheme();
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [imageSize, setImageSize] = useState<MediaSize>();
    const imageAspect = imageSize ? imageSize?.naturalWidth / imageSize?.naturalHeight : undefined;

    const handleZoomChange = (zoomValue: number) => {
        setZoom(zoomValue);
    };

    const handleCropComplete = (percentages: Area, pixels: Area) => {
        setCroppedAreaPixels(pixels);
    };

    const handleConfirmClick = () => {
        const isOriginalImage = zoom === 1 && aspect === imageAspect;

        onCrop(isOriginalImage ? undefined : croppedAreaPixels);
    };

    const handleResetClick = () => {
        setZoom(1);
        setCrop({ x: 0, y: 0 });
        setCroppedAreaPixels(undefined);
    };

    const handleMediaLoad = (mediaSize: MediaSize) => {
        setImageSize(mediaSize);
    };

    return (
        <DesktopModal {...props}>
            <S.Container>
                <S.CropperContainer>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        showGrid={false}
                        style={{
                            cropAreaStyle: {
                                color: theme.fill.level1,
                                opacity: 0.5,
                            },
                        }}
                        onCropChange={setCrop}
                        onMediaLoaded={handleMediaLoad}
                        onCropComplete={handleCropComplete}
                        onZoomChange={handleZoomChange}
                    />
                </S.CropperContainer>
                <S.Footer>
                    <Button size="m" variant="secondary" fullWidth onPress={() => handleResetClick()}>
                        Przywróć domyślne
                    </Button>
                    <Button size="m" fullWidth onPress={() => handleConfirmClick()}>
                        Wybierz
                    </Button>
                </S.Footer>
            </S.Container>
        </DesktopModal>
    );
};
