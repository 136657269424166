import { useMemo } from 'react';
import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface DigitBoxBorderAnimationRenderPropValue {
    borderAnimatedStyles: {
        borderColor: string;
    };
}

export interface DigitBoxBorderAnimationProps extends AnimationsProps<DigitBoxBorderAnimationRenderPropValue> {
    error?: boolean;
    active?: boolean;
    disabled?: boolean;
}

export const DigitBoxBorderAnimation = ({ active, error, disabled, children }: DigitBoxBorderAnimationProps) => {
    const theme = useTheme();

    const borderColorProgress = useDerivedValue(() => withSpring(Number(active), springConfig.default));

    const borderTheme = useMemo(() => {
        if (error) {
            return {
                borderColor: [theme.border.critical, theme.border.critical],
            };
        }

        if (disabled) {
            return {
                borderColor: [theme.border['12'], theme.border['12']],
            };
        }

        return {
            borderColor: [theme.border['12'], theme.border.interactive],
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, error]);

    const borderAnimatedStyles = useAnimatedStyle(() => ({
        borderColor: interpolateColor(borderColorProgress.value, [0, 1], borderTheme.borderColor),
    }));

    return children({ borderAnimatedStyles });
};
