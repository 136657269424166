import { TrainingDayGroupType, TrainingItemStatus } from '@pg/backend';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SectionList, SectionListData, SectionListRenderItem, StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withSpring } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { useUpdateTrainingDay } from '~/api/training/useUpdateTrainingDay';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import { Button } from '~/components/button';
import { Loader } from '~/components/loader';
import { VideoModal } from '~/components/modal';
import { TrainingRulesModal } from '~/components/modal/trainingRulesModal';
import { PressableIcon } from '~/components/pressableIcon';
import { useSoundPlayer } from '~/enhancers/soundPlayer';
import { useUser } from '~/enhancers/user.context';
import { usePressableState } from '~/hooks/usePressableState';
import { useRefresh } from '~/hooks/useRefresh';
import { useTrainingScreen } from '~/screens/training/TrainingScreen.context';
import { TrainingScreenSection } from '~/screens/training/TrainingScreen.types';
import { CardioExerciseItem } from '~/screens/training/components/exerciseItem/CardioExerciseItem';
import { TrainingHeader } from '~/screens/training/components/trainingHeader';
import { TimerContextProvider } from '~/screens/training/enhancers/timer.context';

import { ExerciseGroupHeader } from '../exerciseGroupHeader';
import { ExerciseItem } from '../exerciseItem';
import * as S from './TrainingContent.styled';
import { TrainingPlanNavigationProp } from './TrainingContent.types';

const AnimatedLoaderWrapper = Animated.createAnimatedComponent(S.LoaderWrapper);

interface TrainingContentProps {
    trainingRulesId?: string | null;
}

export const TrainingContent: FC<TrainingContentProps> = ({ trainingRulesId }) => {
    const {
        trainingName,
        cycleName,
        totalNumberOfSeries,
        duration,
        sections,
        isFetching,
        data,
        resetTraining,
        refetch,
        isRefetching,
    } = useTrainingScreen();
    const [trainingModalVideoUrl, setTrainingModalVideoUrl] = useState<string | null>(null);
    const { finishTraining } = useTrainingScreen();
    const { mutateAsync: updateTrainingDay } = useUpdateTrainingDay();
    const [isTrainingRulesModalOpen, setIsTrainingRulesModalOpen] = useState(false);
    const { bottom } = useSafeAreaInsets();
    const theme = useTheme();
    const navigation = useNavigation<TrainingPlanNavigationProp>();
    const loaderVisibility = useSharedValue(1);
    const soundPlayer = useSoundPlayer();
    const soundIconPressableState = usePressableState();
    const confirmSheetRef = useRef<ConfirmSheetRef>(null);
    const { isRefreshing, refresh } = useRefresh(refetch, isRefetching);
    const { settings, updateSettings } = useUser();

    const loaderAnimatedStyles = useAnimatedStyle(() => ({
        opacity: loaderVisibility.value,
    }));

    useEffect(() => {
        if (isFetching) {
            loaderVisibility.value = 1;
        } else {
            loaderVisibility.value = withDelay(100, withSpring(0));
        }
    }, [isFetching, loaderVisibility]);

    useEffect(() => {
        if (!isFetching && settings?.usePreviousCycleWeights === null) {
            confirmSheetRef.current?.present();
        }
    }, [settings?.usePreviousCycleWeights, isFetching]);

    const handleUsePreviousCycleWeightsSet = useCallback(
        (usePreviousCycleWeights: boolean) => {
            updateSettings({ usePreviousCycleWeights });
            confirmSheetRef.current?.close();
        },
        [updateSettings],
    );

    const renderItem: SectionListRenderItem<string, TrainingScreenSection> = useCallback(
        ({ item, section, index }) =>
            section.groupType === TrainingDayGroupType.CARDIO ? (
                <CardioExerciseItem id={item} last={section.data.length - 1 === index} />
            ) : (
                <ExerciseItem
                    id={item}
                    last={section.data.length - 1 === index}
                    onInstructionPress={setTrainingModalVideoUrl}
                />
            ),
        [],
    );

    const renderHeader = useCallback(
        ({ section }: { section: SectionListData<TrainingScreenSection['data'][number], TrainingScreenSection> }) =>
            section.data.length > 0 ? (
                <S.ExerciseHeaderContainer>
                    {section.isFirstOfGroupType ? (
                        <ExerciseGroupHeader title={section.groupTitle} size="large" />
                    ) : null}
                    {section.groupType === 'EXERCISE' && section.isFirstOfGroupType ? (
                        <S.ExerciseSubHeaderContainer>
                            <ExerciseGroupHeader title={section.name} size="normal" />
                        </S.ExerciseSubHeaderContainer>
                    ) : null}
                    {section.groupType === 'EXERCISE' && !section.isFirstOfGroupType ? (
                        <ExerciseGroupHeader title={section.name} size="normal" />
                    ) : null}
                </S.ExerciseHeaderContainer>
            ) : null,
        [],
    );

    const handleFinishTraining = async () => {
        try {
            await finishTraining();

            if (!theme.isWebDesktop) {
                navigation.goBack();
            }
        } catch (error) {}
    };

    const handleTrainingInProgress = async () => {
        try {
            await updateTrainingDay({
                id: data?.id!,
                status: TrainingItemStatus.IN_PROGRESS,
            });
            refetch();
            navigation.setParams({ finished: JSON.stringify(false) });
        } catch (error) {}
    };

    return (
        <S.Container data-id="Container">
            <AnimatedLoaderWrapper style={loaderAnimatedStyles} pointerEvents="none">
                <Loader size="xl" color={theme.icon.interactive} />
            </AnimatedLoaderWrapper>
            {theme.isWebDesktop && (
                <S.DHeaderWrapper>
                    <TrainingHeader training={trainingName} cycle={cycleName} />
                    <S.DButtonWrapper>
                        <S.DMuteButtonWrapper
                            onPress={() => {
                                soundPlayer.isMuted ? soundPlayer.unmute() : soundPlayer.mute();
                            }}
                            hitSlop={{ top: 8, bottom: 8, left: 16, right: 16 }}
                            {...soundIconPressableState.pressableProps}
                        >
                            <PressableIcon
                                name={soundPlayer.isMuted ? 'sound-off' : 'sound-on'}
                                pressableAnimationProgress={soundIconPressableState.pressableAnimationProgress}
                                size={20}
                                color={theme.icon.secondary}
                                pressedColor={theme.icon.primary}
                            />
                        </S.DMuteButtonWrapper>
                        {!!trainingRulesId && (
                            <Button
                                variant="secondary"
                                icon="help-outline"
                                size="s"
                                onPress={() => setIsTrainingRulesModalOpen(true)}
                            >
                                Zasady treningu
                            </Button>
                        )}
                        {data?.status !== TrainingItemStatus.FINISHED ? (
                            <Button variant="positive" icon="check-small" size="s" onPress={finishTraining}>
                                Oznacz jako wykonany
                            </Button>
                        ) : (
                            <Button variant="secondary" icon="edit" size="s" onPress={handleTrainingInProgress}>
                                Edytuj trening
                            </Button>
                        )}
                    </S.DButtonWrapper>
                    <TrainingRulesModal
                        trainingRulesId={trainingRulesId}
                        isOpen={isTrainingRulesModalOpen}
                        onClose={() => setIsTrainingRulesModalOpen(false)}
                    />
                </S.DHeaderWrapper>
            )}
            <S.Wrapper>
                <TimerContextProvider>
                    {!isFetching && (
                        <SectionList
                            sections={sections}
                            renderItem={renderItem}
                            renderSectionHeader={renderHeader}
                            keyExtractor={(item) => item}
                            style={styles.sectionList}
                            removeClippedSubviews
                            keyboardShouldPersistTaps="handled"
                            automaticallyAdjustKeyboardInsets
                            showsVerticalScrollIndicator={false}
                            maxToRenderPerBatch={5}
                            initialNumToRender={10}
                            refreshing={isRefreshing}
                            keyboardDismissMode="none"
                            onRefresh={refresh}
                            contentContainerStyle={{
                                paddingBottom: bottom + 20,
                            }}
                            ListHeaderComponent={
                                <TrainingHeader
                                    training={theme.isWebDesktop ? undefined : trainingName}
                                    cycle={theme.isWebDesktop ? undefined : cycleName}
                                    duration={duration}
                                    series={totalNumberOfSeries}
                                />
                            }
                            ListFooterComponent={
                                data?.status !== TrainingItemStatus.FINISHED ? (
                                    <S.Footer>
                                        <S.PrimaryButtonContainer>
                                            <Button size="m" variant="primary" fullWidth onPress={handleFinishTraining}>
                                                Zakończ trening
                                            </Button>
                                        </S.PrimaryButtonContainer>
                                        <Button size="m" variant="secondary" fullWidth onPress={resetTraining}>
                                            Zresetuj wszystkie ćwiczenia
                                        </Button>
                                    </S.Footer>
                                ) : (
                                    <S.Footer>
                                        <S.PrimaryButtonContainer>
                                            <Button
                                                size="m"
                                                variant="secondary"
                                                icon="edit"
                                                onPress={handleTrainingInProgress}
                                                fullWidth
                                                textFullWidth={false}
                                                style={{ justifyContent: 'center' }}
                                            >
                                                Edytuj trening
                                            </Button>
                                        </S.PrimaryButtonContainer>
                                    </S.Footer>
                                )
                            }
                            stickySectionHeadersEnabled={false}
                        />
                    )}
                </TimerContextProvider>

                <VideoModal
                    videoUrl={trainingModalVideoUrl ?? undefined}
                    visible={!!trainingModalVideoUrl}
                    onRequestClose={() => setTrainingModalVideoUrl(null)}
                />

                <ConfirmSheet
                    ref={confirmSheetRef}
                    title="Czy używać wartości ciężarów z poprzednich treningów?"
                    subtitle="Będą one wyświetlane przy wcześniej wykonanych ćwiczeniach w kolejnych treningach. Możesz to w każdej chwili zmienić w ustawieniach."
                    icon="help-fill"
                    iconColor={theme.icon.interactive}
                    primaryButtonLabel="Tak, zachowuj"
                    variant="primary"
                    secondaryButtonLabel="Nie, będę uzupełniać ręcznie"
                    onConfirm={() => handleUsePreviousCycleWeightsSet(true)}
                    onClose={() => handleUsePreviousCycleWeightsSet(false)}
                />
            </S.Wrapper>
        </S.Container>
    );
};

const styles = StyleSheet.create({
    sectionList: {
        borderRadius: 20,
        width: '100%',
    },
});
