import { format } from 'date-fns';

import { FormValues, PhotoValue } from '../../Measurement/MeasurementScreen.types';

export const photoValues: PhotoValue[] = [
    {
        value: 'frontImage',
        caption: 'Przód',
    },
    {
        value: 'sideImage',
        caption: 'Bok',
    },
    {
        value: 'backImage',
        caption: 'Tył',
    },
];

export const defaultValues: FormValues = {
    frontImage: null,
    sideImage: null,
    backImage: null,
    weight: '',
    waist: '',
    hips: '',
    thigh: '',
    arm: '',
    chest: '',
    calf: '',
    date: format(new Date(), 'yyyy-MM-dd'),
};
