import { Measurement } from '@pg/backend';
import { CellText } from '~/components/table';
import { UnitSuffix } from '~/components/unitSuffix';

import { measurementsTypes } from '../../MeasurementsScreen.constants';
import { MeasureType } from '../../MeasurementsScreen.types';

interface MeasurementsCellProps {
    measureType: MeasureType;
    measurement: Measurement;
}

export const MeasurementsCell: React.FC<MeasurementsCellProps> = ({ measureType, measurement }) => {
    return (
        <CellText>
            <UnitSuffix unit={measurementsTypes[measureType].unit}>{measurement[measureType]}</UnitSuffix>
        </CellText>
    );
};
