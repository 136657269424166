import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle, TouchableWithoutFeedback } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { FloatingLabelAnimation, InputBorderAnimation } from '~/components/animated';
import { FloatingLabel, FloatingLabelMask } from '~/components/floatingLabel';
import { Icon } from '~/components/icon';

import * as S from './DropdownWithFloatingLabel.styled';

export interface DropdownWithFloatingLabelProps {
    label: string;
    value?: string;
    error?: boolean;
    disabled?: boolean;
    onFocus?: () => void;
}

export interface DropdownWithFloatingLabelRef {
    close: () => void;
}

const AnimatedLabel = Animated.createAnimatedComponent(FloatingLabel);
const AnimatedBorder = Animated.createAnimatedComponent(S.Border);
export const DropdownWithFloatingLabel = forwardRef<DropdownWithFloatingLabelRef, DropdownWithFloatingLabelProps>(
    ({ label, value, disabled = false, error, onFocus }, ref) => {
        const theme = useTheme();
        const [focused, setFocused] = useState(false);
        const [layout, setLayout] = useState<LayoutRectangle>({
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        });
        const smallLabel = (value?.length ?? 0) > 0;

        useImperativeHandle(ref, () => {
            return {
                close: () => {
                    setFocused(false);
                },
            };
        });

        const handleFocus = useCallback(() => {
            setFocused(true);
            onFocus?.();
        }, [onFocus]);

        const handleLayout = useCallback((e: LayoutChangeEvent) => {
            setLayout(e.nativeEvent.layout);
        }, []);

        const arrowColor = useMemo(() => {
            if (disabled) return theme.icon.disabled;
            if (error) return theme.icon.critical;
            if (focused) return theme.icon.primary;
            return theme.icon.tertiary;
        }, [disabled, error, focused, theme.icon]);

        return (
            <S.Container pointerEvents={disabled ? 'none' : 'auto'}>
                <TouchableWithoutFeedback onFocus={handleFocus} onPress={handleFocus}>
                    <S.Content>
                        <FloatingLabelAnimation smallLabel={smallLabel} focused={focused} layout={layout}>
                            {(animatedStyle) => (
                                <>
                                    <AnimatedLabel
                                        pointerEvents="none"
                                        style={animatedStyle}
                                        error={error}
                                        disabled={disabled}
                                        onLayout={handleLayout}
                                    >
                                        {label}
                                    </AnimatedLabel>
                                    <FloatingLabelMask disabled={disabled} />
                                </>
                            )}
                        </FloatingLabelAnimation>
                        <S.Input focused={focused} disabled={disabled} style={{ textAlignVertical: 'top' }}>
                            {value}
                        </S.Input>
                        <S.ArrowIconContainer>
                            <Icon name={focused ? 'chevron-up' : 'chevron-down'} color={arrowColor} size={20} />
                        </S.ArrowIconContainer>
                    </S.Content>
                </TouchableWithoutFeedback>
                <InputBorderAnimation focused={focused} disabled={disabled} error={error}>
                    {(animatedStyle) => <AnimatedBorder pointerEvents="none" style={animatedStyle} />}
                </InputBorderAnimation>
            </S.Container>
        );
    },
);
