import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { TrainingTopNavigation } from '~/navigation/home/components/topNavigation';
import { TrainingPlanScreen } from '~/screens/trainingPlan';

import { TrainingTabStackParamsList } from './TrainingTabStackNavigation.types';

const Stack = createNativeStackNavigator<TrainingTabStackParamsList>();

export const TrainingTabStackNavigation = () => {
    return (
        <Stack.Navigator screenOptions={{ header: () => null }}>
            <Stack.Screen
                name="TrainingPlan"
                component={TrainingPlanScreen}
                options={{ header: TrainingTopNavigation }}
            />
        </Stack.Navigator>
    );
};
