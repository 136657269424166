import { Text } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { CellTextStyledProps } from './CellText.types';

export const CellText = styled(Text)<CellTextStyledProps>`
    ${mixins.typography.ui.regular11};
    color: ${({ color, theme }) => (color ? color : theme.text.primary)};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.ui.regular14};
        `}

    ${({ head }) =>
        head &&
        css`
            ${mixins.typography.ui.semiBold11};
        `}

  ${({ theme, head }) =>
        theme.isWebDesktop &&
        head &&
        css`
            ${mixins.typography.ui.semiBold14};
            color: ${theme.text.secondary};
        `}

  ${({ alignment }) =>
        alignment &&
        css`
            text-align: ${alignment};
        `}

  ${({ theme, head }) =>
        theme.isWeb &&
        !head &&
        css`
            word-break: break-all;
        `}
`;
