import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import { MainLayout } from '~/layouts/native';
import { MeasurementScreenProps } from '~/screens/measurements/Measurement/MeasurementScreen.types';

import { MeasurementsNavigationProp } from '../MeasurementsScreen.types';
import { MeasurementForm } from '../components/measurementForm';
import * as S from './MeasurementScreen.styled';

export const MeasurementScreenContent = () => {
    const theme = useTheme();
    const navigation = useNavigation<MeasurementsNavigationProp>();
    const successSheetRef = useRef<ConfirmSheetRef>(null);
    const route = useRoute<MeasurementScreenProps['route']>();

    const handleCloseSuccessSheet = useCallback(() => {
        successSheetRef.current?.dismiss();
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('MeasurementsStack', { screen: 'Table' });
    }, [navigation]);

    const handleSave = useCallback(() => {
        successSheetRef.current?.present();
    }, []);

    const title = route.params?.measurementId ? 'Edytuj pomiar' : 'Dodaj pomiar';
    const successMessage = route.params?.measurementId ? 'Pomyślnie zaktualizowano pomiar' : 'Pomyślnie dodano pomiar';

    return (
        <>
            <S.SectionHeading variant="header" title={title} />
            <MeasurementForm measurementId={route.params?.measurementId} onSave={handleSave} />

            <ConfirmSheet
                ref={successSheetRef}
                variant="primary"
                icon="check-fill"
                iconColor={theme.icon.positive}
                title={successMessage}
                secondaryButtonLabel="OK"
                onClose={handleCloseSuccessSheet}
            />
        </>
    );
};

export const MeasurementScreen = () => {
    return (
        <MainLayout fullWidth isBottomRounded={false} verticalSpacing={false}>
            <MeasurementScreenContent />
        </MainLayout>
    );
};
