import { isPast, isToday } from 'date-fns';

export const getDaysOfTheWeek = () => {
    const curr = new Date();

    return Array.from(Array(7).keys()).map((offset) => {
        const firstDay = curr.getDate() - ((curr.getDay() + 6) % 7);

        return new Date(curr.setDate(firstDay + offset)).toUTCString();
    });
};

export const getDateType = (date: Date): 'past' | 'today' | 'future' => {
    if (isToday(date)) return 'today';
    if (isPast(date)) return 'past';
    return 'future';
};
