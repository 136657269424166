import { Measurement } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchRecentUserMeasurement: QueryFunction<Measurement | null, ['getRecentUserMeasurement']> = async ({
    queryKey,
}) => {
    const [_] = queryKey;
    const measurements = await DataStore.query(Measurement, undefined, {
        sort: (s) => s.date(SortDirection.DESCENDING),
        limit: 1,
    });

    return measurements?.[0] ?? null;
};

export const useGetRecentUserMeasurement = () => useQuery(['getRecentUserMeasurement'], fetchRecentUserMeasurement);
