import { View } from 'react-native';
import styled, { DefaultTheme } from 'styled-components/native';

import { TrainingTimerSheetBackgroundProps } from './TrainingTimerSheetBackground.types';

export const Container = styled(View)`
    border-radius: 20px;
    position: relative;
    overflow: hidden;
`;

type ProgressStyledProps = Pick<TrainingTimerSheetBackgroundProps, 'type' | 'isTransitioning'>;

const getBackgroundColor = ({ type, isTransitioning, theme }: ProgressStyledProps & { theme: DefaultTheme }) => {
    if (isTransitioning) {
        return 'transparent';
    }

    return type === 'training' ? theme.border.positive : theme.text.interactive;
};

export const Progress = styled(View)<ProgressStyledProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: ${getBackgroundColor};
`;
