import React, { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';
import DocumentPicker, { DocumentPickerResponse } from 'react-native-document-picker';
import uuid from 'react-native-uuid';
import { useTheme } from 'styled-components/native';
import { FileErrors, SelectedFiles, useFilePicker } from 'use-file-picker';
import { useNotifications } from '~/enhancers/notifications.context';

type HealthTest = {
    id: string;
    name: string;
    content?: string;
};

export const healthTestsData = [
    { id: uuid.v4().toString(), name: 'tarczyca_2023.pdf' },
    { id: uuid.v4().toString(), name: 'testosteron_wolny_2023.pdf' },
    { id: uuid.v4().toString(), name: 'hemoglobina_2023.pdf' },
    { id: uuid.v4().toString(), name: 'prolaktyna_2023.pdf' },
    { id: uuid.v4().toString(), name: 'morfologia_2023.pdf' },
    { id: uuid.v4().toString(), name: 'glukoza_2023.pdf' },
    { id: uuid.v4().toString(), name: 'cholesterol_2023.pdf' },
    { id: uuid.v4().toString(), name: 'wapń_2023.pdf' },
    { id: uuid.v4().toString(), name: 'magnez_2023.pdf' },
    { id: uuid.v4().toString(), name: 'witamina_D_2023.pdf' },
    { id: uuid.v4().toString(), name: 'kwas_moczowy_2023.pdf' },
    { id: uuid.v4().toString(), name: 'elektrolity_2023.pdf' },
];

export interface HealthTestsContextType {
    healthTests: HealthTest[];
    addHealthTests: () => void;
    removeHealthTest: (id: HealthTest['id']) => void;
}

export const HealthTestsContext = createContext<HealthTestsContextType>({
    healthTests: [],
    addHealthTests: () => {
        throw new Error('Not implemented');
    },
    removeHealthTest: () => {
        throw new Error('Not implemented');
    },
});

export interface HealthTestsContextProviderProps {
    children: ReactNode;
}

export const HealthTestsContextProvider: FC<HealthTestsContextProviderProps> = ({ children }) => {
    const theme = useTheme();
    const [healthTests, setHealthTests] = useState(healthTestsData);
    const { addNotification } = useNotifications();

    const displayAddNotification = (name: string | string[]) => {
        addNotification({
            type: 'success',
            icon: 'medical-examination-outline',
            message:
                Array.isArray(name) && name.length > 1
                    ? `${name.length} badania zostały dodane.`
                    : `Badanie ${name} zostało dodane.`,
        });
    };

    const displayRemoveNotification = (name: string) => {
        addNotification({
            type: 'informative',
            message: `Badanie ${name} zostało usunięte.`,
        });
    };

    const handleWebFileSelected = (selectedFiles: SelectedFiles) => {
        const newHealthTests = selectedFiles.filesContent.map((file) => ({
            id: uuid.v4().toString(),
            name: file.name,
            content: file.content,
        }));

        setHealthTests((currentHealthTests) => [...currentHealthTests, ...newHealthTests]);

        displayAddNotification(newHealthTests.map((healthTest) => healthTest.name));
    };

    const handleMobileFileSelected = (pickedFile: DocumentPickerResponse) => {
        const newHealthTest = {
            id: uuid.v4().toString(),
            name: pickedFile.name,
        };

        setHealthTests((currentHealthTests) => [...currentHealthTests, newHealthTest]);

        displayAddNotification(newHealthTest.name);
    };

    const handleWebFilesRejected = (data: FileErrors) => {
        console.log('rejected');
        console.log(data);
    };

    const [openFileSelector] = useFilePicker({
        multiple: true,
        accept: ['.jpg', '.jpeg', '.png', '.pdf'],
        onFilesSuccessfulySelected: handleWebFileSelected,
        onFilesRejected: handleWebFilesRejected,
    });

    const handleOpenMobileFileSelector = async () => {
        try {
            const [pickedFiles] = await DocumentPicker.pick({
                type: [DocumentPicker.types.images, DocumentPicker.types.pdf],
                allowMultiSelection: true,
            });

            handleMobileFileSelected(pickedFiles);
        } catch (error) {
            if (DocumentPicker.isCancel(error)) {
                return;
            } else {
                throw error;
            }
        }
    };

    const addHealthTests = () => {
        if (theme.isWeb) {
            openFileSelector();
            return;
        }

        handleOpenMobileFileSelector();
    };

    const removeHealthTest = (id: HealthTest['id']) => {
        const name = healthTests.find((item) => item.id === id)?.name;

        if (name) {
            setHealthTests((currentHealthTests) => currentHealthTests.filter((item) => item.id !== id));

            displayRemoveNotification(name);
        }
    };

    const downloadHealthTest = (id: HealthTest['id']) => {
        const file = healthTests.find((item) => item.id === id);
    };

    const value = useMemo(
        () => ({
            healthTests,
            removeHealthTest,
            addHealthTests,
        }),
        [healthTests, addHealthTests, removeHealthTest],
    );

    return <HealthTestsContext.Provider value={value}>{children}</HealthTestsContext.Provider>;
};

export const useHealthTests = () => useContext(HealthTestsContext);
