import { format } from 'date-fns';
import { Pressable } from 'react-native';

import { ShippingListDateRange } from '../../ShoppingListScreen.types';
import * as S from './ShoppingListFooter.styled';

type ShoppingListFooterProps = {
    range: ShippingListDateRange;
    onEdit: () => void;
};

export const ShoppingListFooter = ({ range, onEdit }: ShoppingListFooterProps) => {
    const { startingDay, endingDay } = range;
    const renderLeftText = () => {
        if (startingDay) {
            return <S.DateText>{format(new Date(startingDay), 'EEEEEE, d MMM')}</S.DateText>;
        }

        return <S.NoDateText>nie wybrano</S.NoDateText>;
    };

    const renderRightText = () => {
        if (endingDay) {
            return <S.DateText>{format(new Date(endingDay), 'EEEEEE, d MMM')}</S.DateText>;
        }

        return <S.NoDateText>nie wybrano</S.NoDateText>;
    };

    return (
        <S.Wrapper>
            <S.TextWrapper>
                {renderLeftText()} - {renderRightText()}
            </S.TextWrapper>
            <Pressable onPress={onEdit}>
                <S.ButtonLabel>Edytuj zakres</S.ButtonLabel>
            </Pressable>
        </S.Wrapper>
    );
};
