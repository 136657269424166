import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { PrevNextControlsStyledProps } from './PrevNextControls.types';

export const Container = styled(View)<PrevNextControlsStyledProps>`
    display: flex;
    flex-direction: row;
    align-items: center;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            min-width: 100%;
            justify-content: space-between;
        `}
`;

export const PrevButton = styled(View)`
    margin-right: 4px;
`;

export const Label = styled(Text)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.primary};
    margin: 0 25px;
    user-select: none;
`;

export const NextButton = styled(View)`
    margin-left: 4px;
`;
