import yup from '~/utils/validation';

export const VERIFICATION_CODE_LENGTH = 6;

export const schema = {
    verificationCode: yup
        .string()
        .required()
        .matches(/^[0-9]+$/)
        .min(VERIFICATION_CODE_LENGTH, 'Kod musi składać się z 6 cyfr.')
        .max(VERIFICATION_CODE_LENGTH),
    password: yup.string().min(8, 'Hasło musi składać się z co najmniej 8 znaków.').required(),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Hasła nie pasują do siebie.')
        .required(),
};

export const authErrorMessages: Record<string, string> = {
    UserNotFoundException:
        'Hasło jest nieprawidłowe. Spróbuj jeszcze raz lub kliknij “Nie pamiętasz hasła?”, by je zresetować.',
    NotAuthorizedException:
        'Hasło jest nieprawidłowe. Spróbuj jeszcze raz lub kliknij “Nie pamiętasz hasła?”, by je zresetować.',
    UsernameExistsException: 'Konto o podanym adresie e-mail już istnieje.',
    CodeMismatchException: 'Kod jest nieprawidłowy. Spróbuj ponownie lub poproś o nowy kod.',
    LimitExceededException: 'Zbyt wiele próśb o kod. Zaczekaj 5 minut i spróbuj ponownie.',
    default: 'Coś poszło nie tak. Spróbuj ponownie.',
};
