import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface ModalCloseButton {
    borderColor: string;
}

export const ModalCloseButtonAnimation = ({ children, progress }: PressableAnimationsProps<ModalCloseButton>) => {
    const theme = useTheme();

    const colors = {
        borderColor: {
            default: theme.border[16],
            hovered: theme.border[40],
            pressed: theme.border.interactive,
        },
    };

    const colorProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const animatedStyle = useAnimatedStyle(() => {
        return {
            borderColor: interpolateColor(
                colorProgress.value,
                [0, 1, 2],
                [colors.borderColor.default, colors.borderColor.hovered, colors.borderColor.pressed],
            ),
        };
    });

    return children(animatedStyle);
};
