import { Mask, createNumberMask } from 'react-native-mask-input';

export type ExerciseInputType = 'weight' | 'time' | 'reps';

const timeMask = createNumberMask({
    precision: 2,
    separator: ':',
    delimiter: '',
});

export const masks: Record<ExerciseInputType, Mask | undefined> = {
    time: timeMask,
    weight: undefined,
    reps: undefined,
};

export const getMaskedTimeFromSeconds = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const getLeadingZero = (value: number): string => (value < 10 ? `0${value}` : `${value}`);
    return `${getLeadingZero(minutes)}${getLeadingZero(remainingSeconds)}`;
};

export const getSecondsFromMaskedTime = (maskedTime: string): number => {
    const [minutes, seconds] = maskedTime.split(':');
    return Number(minutes) * 60 + Number(seconds);
};

export const valueDecoders: { [K in ExerciseInputType]: (value: string | number) => string } = {
    time: (value) => getMaskedTimeFromSeconds(Number(value)),
    weight: (value) => String(value),
    reps: (value) => String(value),
};

export const valueEncoders: { [K in ExerciseInputType]: (value: string) => string } = {
    time: (value) => getSecondsFromMaskedTime(value).toString(),
    weight: (value) => {
        let transformedValue = value;

        if (transformedValue?.length > 1 && transformedValue?.[0] === '0') {
            transformedValue = transformedValue.slice(1);
        }

        const [integer, decimal] = transformedValue.split('.');
        return [integer, decimal?.slice(0, 2)].filter((i) => i !== undefined).join('.');
    },
    reps: (value) => String(value),
};
