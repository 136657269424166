import { FC, ReactNode } from 'react';
import { SidebarNavigation } from '~/navigation/home/components/sidebarNavigation';

import * as S from './NavigationLayout.styled';

interface NavigationLayoutProps {
    children: ReactNode;
}

export const NavigationLayout: FC<NavigationLayoutProps> = ({ children }) => (
    <S.MainWrapper>
        <SidebarNavigation />
        <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
    </S.MainWrapper>
);
