import React from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts/web';

import { ExercisesAtlasComingSoon } from './components/exercisesAtlasComingSoon';
import { ExercisesAtlasContent } from './components/exercisesAtlasContent';
import { ExercisesAtlasSubmenu } from './components/exercisesAtlasSubmenu';

const title = 'Atlas ćwiczeń';

export const ExercisesAtlasScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();

    if (!features.exercisesAtlas) {
        return <MainLayout title={title} noBackground={!theme.isWebDesktop} content={<ExercisesAtlasComingSoon />} />;
    }

    return (
        <MainLayout
            title={title}
            verticalSpacing={false}
            content={<ExercisesAtlasContent />}
            submenu={theme.isWebDesktop ? <ExercisesAtlasSubmenu /> : undefined}
        />
    );
};
