import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { EmptyScreen } from '~/components/emptyScreen';

import * as S from './TrainingPlanInProgress.styled';

export const TrainingPlanInProgress: FC = () => {
    const theme = useTheme();

    return (
        <S.PlanInProgressLayout>
            <EmptyScreen
                icon="total-training-time"
                iconColor={theme.icon.interactive}
                title="Plan jest w trakcie przygotowywania"
                titleColor={theme.text.primary}
                description="Działam! Dam Ci znać kiedy plan będzie gotowy. Pamiętaj, że zawsze możesz zobaczysz swoje poprzednie plany i z nich korzystać."
            />
        </S.PlanInProgressLayout>
    );
};
