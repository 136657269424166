import { Pressable, View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(Pressable)`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 2px;
`;

export const Border = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
`;

export const Background = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.interactive.positive};
`;

export const CheckIcon = styled(View)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
