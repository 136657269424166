import React from 'react';
import { ScrollView } from 'react-native';
import { usePublicStorageAsset } from '~/api/assets/useStorageAsset';
import { Tag } from '~/components/tag';

import { ExerciseItemThumbnail } from '../exerciseItemThumbnail';
import * as S from './ExerciseItem.styled';

export type ExerciseItemTag = {
    label: string;
    value: string;
};

export interface ExerciseItemProps {
    title: string;
    videoPath?: string;
    tags?: ExerciseItemTag[];
    onPress?: () => void;
    onTagPress?: (tag: string) => void;
}

export const ExerciseItem = ({ title, videoPath, tags, onPress, onTagPress }: ExerciseItemProps) => {
    const url = usePublicStorageAsset(videoPath);

    return (
        <S.Container onPress={onPress}>
            <S.ThumbnailContainer>
                <S.ThumbnailWrapper>
                    <ExerciseItemThumbnail url={url!} />
                </S.ThumbnailWrapper>
            </S.ThumbnailContainer>

            <S.Content>
                <S.TitleContainer>
                    <S.Title>{title}</S.Title>
                </S.TitleContainer>
                {tags && tags?.length > 0 && (
                    <ScrollView horizontal contentContainerStyle={{ flexDirection: 'row' }}>
                        {tags.map((tag) => (
                            <S.TagContainer key={tag.value}>
                                <Tag label={tag.label} size="small" onPress={() => onTagPress?.(tag.value)} />
                            </S.TagContainer>
                        ))}
                    </ScrollView>
                )}
            </S.Content>
        </S.Container>
    );
};
