import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface RemoveButtonAnimationsRenderPropValue {
    containerAnimationStyles: {
        borderColor: string;
    };
}

export interface RemoveButtonAnimationsProps extends PressableAnimationsProps<RemoveButtonAnimationsRenderPropValue> {}

export const RemoveButtonAnimation = ({ progress, children }: RemoveButtonAnimationsProps) => {
    const theme = useTheme();

    const boxBorderColorValue = useDerivedValue(() => withSpring(progress, springConfig.default));

    const containerAnimationStyles = useAnimatedStyle(() => {
        return {
            borderColor: interpolateColor(
                boxBorderColorValue.value,
                [0, 1, 2],
                [theme.border['40'], theme.border.interactive, theme.border.interactive],
            ),
        };
    });

    return children({ containerAnimationStyles });
};
