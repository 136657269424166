import { Link } from '@react-navigation/native';
import { useEffect, useRef } from 'react';
import { Pressable, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { usePressableState } from '~/hooks/usePressableState';

import { Link as LinkType } from '../SidebarNavigation.constants';
import { MenuItemBackgroundAnimation } from './SidebarMenuItem.animated';
import * as S from './SidebarMenuItem.styled';

interface SidebarMenuItemProps {
    item: LinkType;
    active?: boolean;
}

const AnimatedMenuItem = Animated.createAnimatedComponent(S.MenuItem);

export const SidebarMenuItem = ({ item, active = false }: SidebarMenuItemProps) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const icon = active ? item.iconActive : item.icon;
    const color = active ? theme.icon.interactive : theme.icon.secondary;
    const containerRef = useRef<View | null>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.setNativeProps({ title: item.title });
        }
    }, [item.title]);

    return (
        <MenuItemBackgroundAnimation active={active} progress={pressableAnimationProgress} key={item.icon}>
            {(animatedStyle) => (
                <Pressable {...pressableProps} ref={containerRef}>
                    <Link to={`/${item.path}`}>
                        <AnimatedMenuItem style={animatedStyle}>
                            <Icon name={icon} size={20} color={color} />
                        </AnimatedMenuItem>
                    </Link>
                </Pressable>
            )}
        </MenuItemBackgroundAnimation>
    );
};
