import { useMemo } from 'react';
import { useGetSupplements } from '~/api/supplement/useGetSupplements';
import { useGetUserSupplements } from '~/api/supplement/useGetUserSupplements';

export const useSupplements = () => {
    const { data: userSupplements, isInitialLoading: isLoadingUserSupplements } = useGetUserSupplements();
    const { data: allSupplements, isInitialLoading: isLoadingAllSupplements } = useGetSupplements();

    const supplements = useMemo(() => {
        return (userSupplements || []).map((userSupplement) => ({
            ...userSupplement,
            supplement: allSupplements.find((supplement) => supplement.id === userSupplement.supplementID),
        }));
    }, [userSupplements, allSupplements]);

    return {
        supplements,
        isLoading: isLoadingUserSupplements || isLoadingAllSupplements,
    };
};
