import React from 'react';
import { ComingSoon } from '~/components/comingSoon';

export const SupplementsComingSoon = () => (
    <ComingSoon
        title="Wkrótce w tym miejscu znajdziesz:"
        features={['Listę suplementów dobranych specjalnie pod Ciebie', 'Dokładną instrukcję zażywania suplementacji']}
        imageSrc={require('~/assets/images/suplements_coming_soon.png')}
    />
);
