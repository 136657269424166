import { Accordion } from '~/components/accordion';

import { CheckboxListItem } from '../../checkboxListItem';
import { ItemType } from '../ShoppingListCategories.types';

type ShoppingListAccordionProps = {
    items: ItemType[];
    total: number;
};

export const ShoppingListAccordion = ({ items, total }: ShoppingListAccordionProps) => {
    return (
        <Accordion title={`Masz juz ${items.length} z ${total} produktów z tej kategorii`}>
            {items.map((item) => (
                <CheckboxListItem
                    key={item.id}
                    title={item.productName}
                    subtitle={item.details}
                    checked
                    onChange={console.log}
                />
            ))}
        </Accordion>
    );
};
