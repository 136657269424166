import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface PhotoTileAnimationsRenderPropValue {
    boxAnimatedStyles: {
        borderColor: string;
    };
}

export interface PhotoTileAnimationsProps extends PressableAnimationsProps<PhotoTileAnimationsRenderPropValue> {}

export const PhotoTileAnimation = ({ progress, children }: PhotoTileAnimationsProps) => {
    const theme = useTheme();

    const boxBorderColorValue = useDerivedValue(() => withSpring(progress, springConfig.default));

    const boxAnimatedStyles = useAnimatedStyle(() => {
        return {
            borderColor: interpolateColor(
                boxBorderColorValue.value,
                [0, 1, 2],
                [theme.border['40'], theme.border.interactive, theme.border.interactive],
            ),
        };
    });

    return children({ boxAnimatedStyles });
};
