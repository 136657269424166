import React, { memo } from 'react';
import Animated from 'react-native-reanimated';
import { useTrainingScreen } from '~/screens/training/TrainingScreen.context';

import { InstructionButton } from '../instructionButton';
import { CardioSeriesTable } from '../seriesTable/CardioSeriesTable';
import { ExerciseItemAnimations } from './ExerciseItem.animated';
import * as S from './ExerciseItem.styled';

interface CardioExerciseItemProps {
    id: string;
    last?: boolean;
    onInstructionPress?: () => void;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);

export const CardioExerciseItem = memo(({ id, onInstructionPress, last }: CardioExerciseItemProps) => {
    const trainingScreen = useTrainingScreen();
    const data = trainingScreen.getMapData('trainingCardioExercisesMap', id)!;
    const exercise = trainingScreen.getMapData('cardioExercisesMap', data.cardioexerciseID)!;
    const exerciseData = trainingScreen.getMapData(
        'cardioExercisesDataMap',
        data.trainingCardioExerciseCardioExerciseDataId,
    );
    const finishedExerciseData = trainingScreen.finishedExercises?.[data.id]?.finishedCardio;

    return (
        <S.Container last={last}>
            <S.ExerciseContainer>
                <ExerciseItemAnimations filled={!!finishedExerciseData?.time}>
                    {({ borderAnimatedStyles }) => (
                        <>
                            <AnimatedBorder style={borderAnimatedStyles} />

                            <S.TitleContainer>
                                <S.TitleText>{exercise?.name}</S.TitleText>

                                {onInstructionPress && (
                                    <S.InstructionButtonContainer>
                                        <InstructionButton onPress={onInstructionPress} />
                                    </S.InstructionButtonContainer>
                                )}
                            </S.TitleContainer>

                            {exerciseData && (
                                <CardioSeriesTable
                                    trainingCardioExerciseId={id}
                                    exercise={exercise}
                                    exerciseData={exerciseData}
                                    finishedData={finishedExerciseData}
                                />
                            )}
                        </>
                    )}
                </ExerciseItemAnimations>
            </S.ExerciseContainer>
        </S.Container>
    );
});

CardioExerciseItem.displayName = 'ExerciseItem';
