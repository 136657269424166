import { StorageAccessLevel } from '@aws-amplify/storage';
import { useQuery } from '@tanstack/react-query';
import { API, Auth, Storage } from 'aws-amplify';

export const useStorageAsset = (assetPath?: string | null, level: StorageAccessLevel = 'private', userId?: string) => {
    const { data } = useQuery(
        ['storageAsset', assetPath, level, userId],
        async ({ queryKey }) => {
            const [, assetPath, accessLevel, userId] = queryKey;
            if (!assetPath) return null;

            const response = await API.post('userStorage', '/generate-signed-url', {
                body: {
                    assetPath,
                    accessLevel,
                    userId,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
                },
            });

            return (response?.url ?? null) as string | null;
        },
        {
            onError: (error) => {
                console.error(error);
            },
        },
    );

    return data;
};
export const usePublicStorageAsset = (assetPath?: string | null) => {
    const { data } = useQuery(
        ['publicStorageAsset', assetPath],
        async ({ queryKey }) => {
            const [, assetPath] = queryKey;
            if (!assetPath) return null;

            return await Storage.get(assetPath, { level: 'public' });
        },
        {
            onError: (error) => {
                console.error(error);
            },
        },
    );

    return data;
};
