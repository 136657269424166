import React, { useCallback, useEffect, useState } from 'react';
import { GiftedChat } from 'react-native-gifted-chat';
import { useTheme } from 'styled-components/native';

import { ChatInput } from '../chatInput';
import { ChatMessage } from '../chatMessage';
import * as S from './Chat.styled';

const TAB_BAR_HEIGHT = 48;

export const Chat = () => {
    const [messages, setMessages] = useState([]);
    const theme = useTheme();
    //Remove when BE is integrated
    useEffect(() => {
        setMessages([
            {
                _id: 1,
                text: 'Hello developer',
                createdAt: new Date(),
                user: {
                    _id: 2,
                    name: 'React Native',
                    avatar: 'https://reactnative.dev/img/tiny_logo.png',
                },
            },
        ]);
    }, []);

    const onSend = useCallback((messages = []) => {
        setMessages((previousMessages) => GiftedChat.append(previousMessages, messages));
    }, []);

    return (
        <GiftedChat
            messages={messages}
            onSend={(messages) => onSend(messages)}
            renderMessage={ChatMessage}
            renderInputToolbar={(props) => <ChatInput {...props} />}
            alwaysShowSend
            messagesContainerStyle={theme.isWebMobile && S.chatMobileWeb.messagesContainer}
            bottomOffset={TAB_BAR_HEIGHT}
            user={{
                _id: 1,
            }}
        />
    );
};
