import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

import { TabsStyledProps } from './Tabs.types';

export const Container = styled(ScrollView)<TabsStyledProps>`
    display: flex;
    width: 100%;
    flex-direction: row;
`;
