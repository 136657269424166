import { View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    width: 64px;
    height: 100%;
`;

export const MainMenu = styled(View)`
    align-items: center;
    flex: 1;
`;

export const FooterMenu = styled(View)`
    align-items: center;
`;

export const LogoContainer = styled(View)`
    width: 64px;
    height: 64px;
    align-items: center;
    justify-content: center;
`;
