import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface RadioAnimationsRenderPropValue {
    borderAnimatedStyles: {
        borderColor: string;
    };
    backgroundAnimatedStyles: {
        opacity: number;
    };
    dotAnimatedStyles: {
        opacity: number;
    };
}

export interface RadioAnimationsProps extends AnimationsProps<RadioAnimationsRenderPropValue> {
    checked: boolean;
}

export const RadioAnimations = ({ checked, children }: RadioAnimationsProps) => {
    const theme = useTheme();

    const checkedProgress = useDerivedValue(() => withSpring(Number(checked), springConfig.default));

    const borderAnimatedStyles = useAnimatedStyle(() => ({
        borderColor: interpolateColor(checkedProgress.value, [0, 1], [theme.border['40'], theme.border.interactive]),
    }));

    const backgroundAnimatedStyles = useAnimatedStyle(() => ({
        opacity: checkedProgress.value,
    }));

    const dotAnimatedStyles = useAnimatedStyle(() => ({
        opacity: checkedProgress.value,
    }));

    return children({ borderAnimatedStyles, backgroundAnimatedStyles, dotAnimatedStyles });
};
