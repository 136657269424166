import { StyleSheet, View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.fill.level1};
    overflow: hidden;
    z-index: 9999;
`;

export const lottieViewStyles = StyleSheet.create({
    desktop: {
        width: 200,
        height: 200,
        flex: 1,
        marginLeft: 8,
    },
    mobile: {
        width: 150,
        height: 150,
        flex: 1,
        marginLeft: 5,
    },
});
