import { ImageBackground, Pressable, Text, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { mixins } from '~/theme';
import { HoverableProps } from '~/types/hoverable';

import { PhotoTileStyledProps } from './PhotoTile.types';

interface PhotoTileHoverableStyledProps extends PhotoTileStyledProps, HoverableProps {}

export const Container = styled(View)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const boxStylesMap: Record<PhotoTileStyledProps['variant'], FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    photo: css`
        background: ${({ theme }) => theme.fill.background};
    `,
    noPhoto: css`
        border: 1px dashed ${({ theme }) => theme.border[20]};
        background: ${({ theme }) => theme.fill.background};
    `,
    placeholder: css`
        background: ${({ theme }) => theme.fill.level2};
    `,
};

export const Box = styled(Pressable)<PhotoTileHoverableStyledProps>`
    position: relative;
    width: 100%;
    flex: 1;
    border-radius: 6px;
    overflow: hidden;

    ${({ variant }) => boxStylesMap[variant]}
`;

export const AddIconWrapper = styled(View)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const RemoveButtonWrapper = styled(View)`
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
`;

export const ImageLoaderWrapper = styled(View)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: auto;
`;

export const Image = styled(ImageBackground)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: auto;
`;

export const Caption = styled(Text)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.primary};
    text-align: center;
    margin-top: 8px;
`;

export const PlaceholderLabelWrapper = styled(View)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: auto;
`;

export const PlaceholderLabel = styled(Text)`
    ${mixins.typography.ui.regular12};
    margin-top: 16px;
    color: ${({ theme }) => theme.text.tertiary};
    text-align: center;
    max-width: 120px;
`;
