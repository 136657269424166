import { Pressable, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';

import { RadioSize, RadioStyledProps } from './Radio.types';

const sizeStylesMap: Record<RadioSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    s: css`
        height: 20px;
        width: 20px;
        border-radius: 10px;
    `,
    l: css`
        height: 24px;
        width: 24px;
        border-radius: 12px;
    `,
};

const dotSizeStylesMap: Record<RadioSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    s: css`
        height: 8px;
        width: 8px;
        border-radius: 4px;
    `,
    l: css`
        height: 12px;
        width: 12px;
        border-radius: 6px;
    `,
};

export const Container = styled(Pressable)<RadioStyledProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ size }) => sizeStylesMap[size]};
`;

export const Border = styled(View)<Pick<RadioStyledProps, 'size'>>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-style: solid;

    ${({ size }) => sizeStylesMap[size]};
`;

export const Background = styled(View)<Pick<RadioStyledProps, 'size'>>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.border.interactive};

    ${({ size }) => sizeStylesMap[size]};
`;

export const Dot = styled(View)<Pick<RadioStyledProps, 'size'>>`
    background-color: ${({ theme }) => theme.icon.primary};

    ${({ size }) => dotSizeStylesMap[size]};
`;
