import React from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';
import { IconName } from '~/icons';

import { Icon } from '../icon';
import * as S from './InfoBox.styled';
import { InfoBoxStyledProps } from './InfoBox.types';

export interface InfoBoxProps extends Partial<InfoBoxStyledProps> {
    style?: StyleProp<ViewStyle>;
    icon?: IconName;
    iconSize?: number;
    children: React.ReactNode;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
    style,
    children,
    icon,
    iconSize,
    iconPosition = 'left',
    variant = 'informative',
}) => {
    const theme = useTheme();

    return (
        <S.InfoBoxContainer variant={variant} style={style} iconPosition={iconPosition}>
            {icon ? (
                <S.IconWrapper iconPosition={iconPosition}>
                    <Icon
                        size={iconSize ?? Platform.select({ default: 40, native: 32 })}
                        color={variant === 'informative' ? theme.text.interactiveSurface : theme.text.criticalSurface}
                        name={icon}
                    />
                </S.IconWrapper>
            ) : null}
            <S.Content>{typeof children === 'string' ? <S.Text>{children}</S.Text> : children}</S.Content>
        </S.InfoBoxContainer>
    );
};
