import { View } from 'react-native';
import styled from 'styled-components/native';

export const Wrapper = styled(View)`
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: center;
`;

export const Element = styled(View)`
    width: 48px;
    height: 6px;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.border[20]};
`;
