import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { MainLayout as BaseMainLayout } from '~/layouts';

export const MainLayout = styled(BaseMainLayout)`
    position: relative;
    flex-direction: ${({ theme }) => (theme.isWebDesktop ? 'row' : 'column')};
    ${({ theme }) =>
        theme.isWeb &&
        css`
            overflow: auto;
        `}
`;

export const ListWrapper = styled(View)`
    flex: 1;
    position: relative;
    max-width: ${({ theme }) => (theme.isWebDesktop ? '375px' : '100%')};
    width: 100%;
`;

export const WebDesktopDraftTitleWrapper = styled(View)`
    flex: 1;
`;
