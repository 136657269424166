import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 20px 16px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            padding: 16px 32px;
            max-width: 460px;
        `}
`;

export const ErrorContainer = styled(View)`
    margin-top: 32px;
`;

export const ErrorText = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.critical};
`;

export const SectionHeadingWrapper = styled(View)`
    padding: 8px 16px 0;
`;
