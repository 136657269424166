import React from 'react';
import { ComingSoon } from '~/components/comingSoon';

export const ShoppingListComingSoon = () => (
    <ComingSoon
        title="W ramach pakietu z dietą dostępne będzie:"
        features={[
            'Automatycznie generowana lista na podstawie wybranych w diecie posiłków',
            'Podział na kategorie zgodnie z najpopularniejszymi sklepami',
            'Podział na posiłki i możliwość odznaczenia tego co masz już w domu - kupujesz tylko tyle ile potrzebujesz',
        ]}
        imageSrc={require('~/assets/images/shopping_list_coming_soon.png')}
    />
);
