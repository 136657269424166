import { Training, TrainingStatus } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchActiveTraining: QueryFunction<Training | null, ['getActiveTraining']> = async () => {
    try {
        const [activeTraining] = await DataStore.query(
            Training,
            (criteria) => criteria.status.eq(TrainingStatus.ACTIVE),
            {
                sort: (s) => s.startDate(SortDirection.ASCENDING),
                limit: 1,
            },
        );

        if (activeTraining) {
            return activeTraining;
        }

        const [recentTraining] = await DataStore.query(Training, undefined, {
            sort: (s) => s.startDate(SortDirection.ASCENDING),
            limit: 1,
        });

        return recentTraining ?? null;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const useGetActiveTraining = () => useQuery(['getActiveTraining'], fetchActiveTraining);
