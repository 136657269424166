import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';
import { HoverableProps } from '~/types/hoverable';

interface ButtonHoverableStyledProps extends HoverableProps {}

export const Container = styled(Pressable)<ButtonHoverableStyledProps>`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const IconContainer = styled(View)`
    margin-right: 8px;
`;

export const Label = styled(Text)`
    ${mixins.typography.ui.regular14}
`;
