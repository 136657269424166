import React from 'react';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { ChatLayout, MainLayout } from '~/layouts/native';

import { Chat } from './components/chat';
import { ChatComingSoon } from './components/chatComingSoon';

export const ChatScreen = () => {
    const { features } = useAppFeatures();

    if (!features.chat) {
        return (
            <MainLayout noBackground fullWidth>
                <ChatComingSoon />
            </MainLayout>
        );
    }

    return (
        <ChatLayout fullWidth>
            <Chat />
        </ChatLayout>
    );
};
