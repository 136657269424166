import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 8px 0;
    margin: 0 16px;
    background: ${({ theme }) => theme.fill.level1};
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.medium14};
    color: ${({ theme }) => theme.text.tertiary};
`;
