import React from 'react';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts/native';
import { SupplementsEmptyScreen } from '~/screens/supplements/components/supplementsEmptyScreen';

import * as S from './SupplementsScreen.native.styled';
import { SupplementTile } from './components/supplementTile';
import { SupplementsComingSoon } from './components/supplementsComingSoon';
import { useSupplements } from './hooks/useSupplements';

export const SupplementsMobileContent = () => {
    const { supplements, isLoading } = useSupplements();

    if (isLoading) {
        return null;
    }

    if (!supplements.length) {
        return (
            <S.Center>
                <SupplementsEmptyScreen />
            </S.Center>
        );
    }

    return (
        <>
            {supplements.map(({ id, info, supplement }, index) => (
                <S.SupplementTileWrapper key={id} last={index === supplements.length - 1}>
                    <SupplementTile
                        name={supplement?.name || ''}
                        info={info || ''}
                        description={supplement?.description || ''}
                    />
                </S.SupplementTileWrapper>
            ))}
        </>
    );
};

export const SupplementsScreen = () => {
    const { supplements, isLoading } = useSupplements();
    const { features } = useAppFeatures();

    if (!features.supplements) {
        return (
            <MainLayout noBackground fullWidth>
                <SupplementsComingSoon />
            </MainLayout>
        );
    }

    return (
        <MainLayout scrollable={!!supplements.length} isLoading={isLoading}>
            <SupplementsMobileContent />
        </MainLayout>
    );
};
