import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)``;

export const TitleContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: ${({ theme }) => (theme.isWebDesktop ? 'flex-start' : 'center')};
    padding: ${({ theme }) => (theme.isWebDesktop ? '20px' : '16px 20px')};
    flex-wrap: wrap;
`;

export const Cycle = styled(Text)`
    ${({ theme }) => (theme.isWebDesktop ? mixins.typography.h.semiBold24 : mixins.typography.h.semiBold20)};
    color: ${({ theme }) => theme.text.secondary};
`;

export const Training = styled(Text)`
    ${({ theme }) => (theme.isWebDesktop ? mixins.typography.h.semiBold24 : mixins.typography.h.semiBold20)};
    color: ${({ theme }) => theme.text.primary};
`;

export const SummaryContainer = styled(View)`
    padding: ${({ theme }) => (theme.isWebDesktop ? '8px 20px' : '8px 16px')};
    flex-direction: row;
`;

export const SummaryItem = styled(View)<{ last?: boolean }>`
    flex: 1;
    padding: 12px 4px;
    border: 2px solid ${({ theme }) => theme.palette.opacity['12'].rgba};
    border-radius: 12px;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
    flex-basis: auto;
    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            background-color: ${theme.fill.level3};
            border: 2px solid ${theme.fill.level3};
        `}
`;

export const SummaryItemTitle = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
export const SummaryItemTitleTextWrapper = styled(View)`
    flex-direction: row;
`;

export const SummaryItemTitleText = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;
export const SummaryItemTitleTextUnit = styled(Text)<{ last?: boolean }>`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
    margin-right: ${({ last }) => (last ? 0 : 4)}px;
`;

export const SummaryItemTitleIcon = styled(View)`
    margin-left: 8px;
`;

export const SummaryItemSubtitle = styled(Text)`
    text-align: center;
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.tertiary};
`;
