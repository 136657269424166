import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { ContainerProps } from './MainLayout.types';

export const Container = styled(View)<ContainerProps>`
    flex: 1;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-radius: ${({ isBottomRounded, theme }) => (isBottomRounded && !theme.isWebDesktop ? 20 : 0)}px;
    background-color: ${({ theme }) => theme.fill.level1};
    padding-bottom: ${({ inset, bottomContent }) => (bottomContent ? inset?.bottom : 0)}px;
    overflow: hidden;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            overflow: visible;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        `}
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

export const ContentWrapper = styled(View)`
    flex: 1;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            height: 100%;
        `}
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24}
    color: ${({ theme }) => theme.text.primary};
    margin-top: 20px;
    margin-left: 20px;
`;
