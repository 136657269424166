import yup from '~/utils/validation';

const maxDate = new Date();

const toNumber = (value: number) => (isNaN(value) ? undefined : value);

const parseCommas = (_value: string, originalValue: string) => Number(originalValue.replace(/,/, '.'));

export const schema = yup.object().shape({
    frontImage: yup.object().shape({ uri: yup.string(), mime: yup.string() }).nullable(),
    sideImage: yup.object().shape({ uri: yup.string(), mime: yup.string() }).nullable(),
    backImage: yup.object({ uri: yup.string(), mime: yup.string() }).nullable(),
    weight: yup.number().transform(parseCommas).positive().min(20).max(300).required(),
    waist: yup.number().transform(parseCommas).positive().min(40).max(200).transform(toNumber).required(),
    hips: yup.number().transform(parseCommas).positive().min(40).max(200).transform(toNumber).required(),
    thigh: yup.number().transform(parseCommas).positive().min(20).max(100).transform(toNumber).required(),
    arm: yup.number().transform(parseCommas).positive().min(15).max(60).transform(toNumber).required(),
    chest: yup.number().transform(parseCommas).positive().min(40).max(200).transform(toNumber).required(),
    calf: yup.number().transform(parseCommas).positive().min(20).max(80).transform(toNumber).required(),
    date: yup
        .date()
        .default(new Date(maxDate))
        .max(new Date(maxDate), `Wprowadzona data nie może być późniejsza niż data dzisiejsza.`)
        .required(),
});
