import { FC } from 'react';
import { ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import * as S from './BottomTabWrapper.styled';

export type BottomTabWrapperProps = {
    children: React.ReactNode;
    style?: ViewStyle;
};

export const BottomTabWrapper: FC<BottomTabWrapperProps> = ({ children, style }) => {
    const inset = useSafeAreaInsets();

    return (
        <S.Container style={style} inset={inset}>
            {children}
        </S.Container>
    );
};
