import React from 'react';
import { useTheme } from 'styled-components/native';
import { DesktopSettingsHeading } from '~/components/desktopSettingsHeading';
import { Pagination } from '~/components/pagination';
import { Table } from '~/components/table';
import { SEO } from '~/components/wrappers';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts';
import { MoreLayout } from '~/layouts/moreLayout';
import { MainLayout as MainLayoutWeb } from '~/layouts/web';
import { HealthTestsMobileContent } from '~/screens/healthTests/HealthTestsScreen.native';
import { useOrderHistoryTable } from '~/screens/orderHistory/hooks/useOrderHistoryTable';

import * as S from './OrderHistoryScreen.styled';

const title = 'Historia zamówień';
const renderEmptyState = () => <S.EmptyState>Historia zamówień będzie dostępna wkrótce.</S.EmptyState>;

export const OrderHistoryScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();
    const { table, pagination } = useOrderHistoryTable();

    const renderDesktop = () => {
        return (
            <MainLayout>
                <MoreLayout>
                    <DesktopSettingsHeading title={title} />
                    {!features.ordersHistory ? (
                        <>{renderEmptyState()}</>
                    ) : (
                        <S.Content>
                            <S.TableContainer fullSize={!table.data.length}>
                                <Table {...table} />
                            </S.TableContainer>
                            {pagination && (
                                <S.PaginationContainer>
                                    <Pagination page={1} totalPages={2} onPageChange={() => {}} />
                                </S.PaginationContainer>
                            )}
                        </S.Content>
                    )}
                </MoreLayout>
            </MainLayout>
        );
    };

    const renderMobile = () => {
        if (!features.ordersHistory) {
            return <MainLayoutWeb title={title} content={renderEmptyState()} />;
        }

        return <MainLayoutWeb title={title} fullWidth verticalSpacing={false} content={<HealthTestsMobileContent />} />;
    };

    return (
        <>
            <SEO title={title} />
            {theme.isWebDesktop ? renderDesktop() : renderMobile()}
        </>
    );
};
