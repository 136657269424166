import { View } from 'react-native';
import MaskInput from 'react-native-mask-input';
import styled from 'styled-components/native';
import { mixins } from '~/theme/mixins';

export const Container = styled(View)`
    height: 28px;
    width: 100%;
`;

export const Input = styled(MaskInput)`
    width: 100%;
    height: 100%;
    ${mixins.typography.ui.medium16};
    text-align: center;
    color: ${({ theme }) => theme.text.primary};
`;

export const Border = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 2px;
`;
