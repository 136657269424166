import { ChatType } from '@pg/backend';
import { IconName } from '~/icons';

type ReturnType = {
    title: string;
    icon: IconName;
};

export const chatTypeContentMap: Record<ChatType, ReturnType> = {
    [ChatType.TRAINING]: {
        title: 'Trening',
        icon: 'training-outline',
    },
    [ChatType.DIET]: {
        title: 'Dieta',
        icon: 'diet-outline',
    },
    [ChatType.GENERAL]: {
        title: 'Pytania ogólne',
        icon: 'help-outline',
    },
};
