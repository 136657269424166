import React from 'react';
import { useTheme } from 'styled-components/native';
import { SectionHeading } from '~/components/sectionHeading';
import { useDiet } from '~/enhancers/dietContext';
import { DietPlanInProgress } from '~/screens/diet/components/dietPlanInProgress';

import { DietLayout } from '../components/dietLayout/DietLayout';
import { NoDietPlan } from '../components/noDietPlan';
import { MetabolismScreenContent } from './MetabolismScreen.native';
import * as S from './MetabolismScreen.styled';
import { DetailsTiles } from './components/detailsTiles';

export const MetabolismScreen = () => {
    const theme = useTheme();
    const { isActivePlanInProgress, userHasDietSubscription } = useDiet();

    const renderContent = () => {
        if (!theme.isWebDesktop) {
            return <MetabolismScreenContent />;
        }

        if (!userHasDietSubscription) {
            return <NoDietPlan />;
        }

        return (
            <S.Container>
                <S.HeaderContainer>
                    <SectionHeading variant="header" title="Twój metabolizm" />
                </S.HeaderContainer>
                <S.Content wide={isActivePlanInProgress}>
                    {isActivePlanInProgress ? (
                        <DietPlanInProgress />
                    ) : (
                        <>
                            <S.DetailsTilesWrapper>
                                <DetailsTiles bmi={30.86} ppm={2145} cpm={3176} />
                            </S.DetailsTilesWrapper>
                            {/* TODO handle rich content*/}
                            <S.RichContentContainer>
                                <S.TmpText>json content</S.TmpText>
                            </S.RichContentContainer>
                        </>
                    )}
                </S.Content>
            </S.Container>
        );
    };

    return <DietLayout seo={{ title: 'Twój metabolizm' }} content={renderContent()} />;
};
