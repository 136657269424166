import { Pressable, StyleSheet, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const shadowStyles = StyleSheet.create({
    shadow: {
        shadowOpacity: 0.5,
        shadowRadius: 32,
        shadowOffset: {
            height: 12,
            width: 0,
        },
        shadowColor: 'black',
    },
});

export const Container = styled(Pressable)`
    height: 94px;
    width: 100%;
    position: relative;
    background-color: ${({ theme }) => theme.fill.level2};
    border-radius: 12px;
    z-index: 2;
    margin-bottom: 24px;
`;
export const Wrapper = styled(View)`
    flex: 1;
    padding: 0 16px 0 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
`;
export const Title = styled(Text)`
    ${mixins.typography.h.semiBold20}
    color: ${({ theme }) => theme.text.primary};
    z-index: 2;
`;

export const CounterWrapper = styled(View)`
    height: 38px;
    width: 35px;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.fill.critical};
    border-radius: 4px;
    margin-right: 16px;
`;

export const CounterText = styled(Text)`
    ${mixins.typography.ui.semiBold18}
`;

export const RightWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    z-index: 2;
`;

export const IconWrapper = styled(View)`
    position: absolute;
    transform: rotateZ(-15deg);
    overflow: hidden;
    z-index: 1;
    right: 45px;
    top: 0;
    opacity: 0.32;
`;
