import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 20px 16px;
    position: relative;
    flex: 1;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            padding: 16px 32px;
            max-width: 536px;
        `}
`;

export const ErrorContainer = styled(View)`
    margin-top: 32px;
`;

export const ErrorText = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.critical};
`;

export const Background = styled(View)`
    position: absolute;
    bottom: 64px;
    left: 0;
    right: 0;
    align-items: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            right: 48px;
            align-items: flex-end;
        `}
`;
