import { Pressable, Text, TextInput, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { InputStyledProps } from '../input/Input.types';

export const Container = styled(Pressable)`
    height: 52px;
    width: 100%;
`;

export const Content = styled(View)`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
`;

export const IconWrapper = styled(View)`
    position: absolute;
    top: 15px;
    left: 12px;
    z-index: 1;
    width: 20px;
    height: 20px;
`;

export const Input = styled(TextInput)<InputStyledProps & { disabled?: boolean; error?: boolean }>`
    ${mixins.typography.ui.medium16};
    line-height: 20px;
    padding: 24px 12px 8px;
    flex: 1;
    background: ${({ theme, disabled }) => (disabled ? theme.fill.level2 : theme.fill.background)};
    color: ${({ theme, disabled, error }) =>
        disabled ? theme.text.disabled : error ? theme.text.critical : theme.text.primary};
    padding-left: 40px;
`;

export const Label = styled(Text)<InputStyledProps>`
    ${mixins.typography.ui.regular16};
    top: 15px;
    flex: 1;
    position: absolute;
    z-index: 1;
    color: ${({ theme }) => theme.text.tertiary};
    left: 40px;
    pointer-events: none;
    width: 100%;
    color: ${({ theme }) => theme.text.tertiary};
    ${({ theme, error }) =>
        error &&
        css`
            color: ${theme.text.critical};
        `};
`;

export const Border = styled(View)<{ error?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1.5px solid ${({ theme }) => theme.border[12]};
    border-radius: 8px;
    ${({ theme, error }) =>
        error &&
        css`
            border-color: ${theme.text.critical};
        `};
`;

export const TriggerWrapper = styled(View)`
    border-radius: 8px;
    width: 100%;
    height: 100%;
    flex: 1;
    position: absolute;
`;
