import React, { FC } from 'react';

import * as S from './Header.styled';
import { formatDate } from './utils';

export interface HeaderProps {
    variant?: 'default' | 'active' | 'disabled';
    date: string;
    nutritionData: [number, number, number, number];
}

const labels: Record<number, string> = {
    0: 'B: ',
    1: 'W: ',
    2: 'T: ',
};

const units: Record<number, string> = {
    0: 'g',
    1: 'g',
    2: 'g',
    3: 'kcal',
};

export const Header: FC<HeaderProps> = ({ date, nutritionData, variant = 'default' }) => {
    const renderNutritionData = () =>
        nutritionData.map((item, index) => {
            return (
                <S.NutritionDataItem key={index} variant={variant}>
                    {labels[index] ?? null}
                    {item}
                    {units[index] ?? null}
                    {nutritionData.length - 1 !== index && ' · '}
                </S.NutritionDataItem>
            );
        });

    return (
        <S.Container>
            <S.Title variant={variant}>{formatDate(date)}</S.Title>
            <S.NutritionDataContainer>{renderNutritionData()}</S.NutritionDataContainer>
        </S.Container>
    );
};
