import React from 'react';
import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { MainLayout } from '~/layouts/web';
import { MoreStackScreens } from '~/navigation/paths';

import { SearchExercisesContent } from './components/searchExercisesContent';

export const SearchExercisesScreen = () => {
    useWebDesktopBlockedScreen(MoreStackScreens.screens.ExercisesAtlasStack.screens.SearchExercises);

    return <MainLayout title="Szukaj ćwiczenia" verticalSpacing={false} content={<SearchExercisesContent />} />;
};
