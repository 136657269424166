import { Measurement } from '@pg/backend';
import { MutationFunction, UseMutationOptions, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const deleteMeasurement = (): MutationFunction<void, string> => async (id: string) => {
    const toDelete = await DataStore.query(Measurement, id);
    if (toDelete) {
        await DataStore.delete(toDelete);
    }
};

export const useDeleteMeasurement = (options?: UseMutationOptions<void, unknown, string>) =>
    useMutation(deleteMeasurement(), options);
