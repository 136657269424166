import { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import * as S from './Avatar.styled';

interface AvatarProps {
    url: string;
}

export const Avatar: FC<AvatarProps> = ({ url }) => {
    const theme = useTheme();

    if (!url)
        return (
            <S.PlaceholderWrapper>
                <Icon name="account-outline" size={24} color={theme.icon.secondary} />
            </S.PlaceholderWrapper>
        );
    return <S.Container source={{ uri: url }} />;
};
