import React from 'react';
import { SectionHeading } from '~/components/sectionHeading';
import { MainLayout } from '~/layouts/native';

import * as S from './MetabolismScreen.styled.native';
import { DetailsTiles } from './components/detailsTiles';

export const MetabolismScreenContent = () => {
    return (
        <S.Container>
            <S.HeaderContainer>
                <SectionHeading variant="header" title="Twój metabolizm" />
            </S.HeaderContainer>
            <S.DetailsTilesWrapper>
                <DetailsTiles bmi={30.86} ppm={2145} cpm={3176} />
            </S.DetailsTilesWrapper>
            <S.RichContentContainer>
                <S.TmpText>json content</S.TmpText>
            </S.RichContentContainer>
        </S.Container>
    );
};

export const MetabolismScreen = () => {
    return (
        <MainLayout scrollable={false} isBottomRounded={false}>
            <MetabolismScreenContent />
        </MainLayout>
    );
};
