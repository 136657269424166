import { Image, Pressable, Text, View } from 'react-native';
import Animated from 'react-native-reanimated';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    background-color: ${({ theme }) => theme.fill.level2};
    border-radius: 12px;
    overflow: hidden;
`;

export const AnimatedContainer = styled(Animated.View)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const PressableContent = styled(Pressable)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    flex: 1;
`;

export const ImageContainer = styled(View)`
    width: 80px;
    height: 56px;
    position: relative;
`;

export const TrainingIcon = styled(Animated.View)`
    position: absolute;
    left: -8px;
    top: -8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.fill.level2};
`;

export const AnimatedTrainingIcon = styled(Animated.View)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
`;

export const ImageWrapper = styled(View)`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
`;

export const MealImage = styled(Image)`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.fill.level3};
    border-radius: 6px;
    overflow: hidden;
`;

export const Name = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
    margin-left: 16px;
    margin-right: 4px;
    flex: 1;
`;

export const ActionsContainer = styled(View)`
    height: 100%;
    display: flex;
    border-left-width: 1px;
    border-left-color: ${({ theme }) => theme.border[12]};
`;

export const ActionButton = styled(Pressable)<{ topBorder?: boolean }>`
    padding: 12px 16px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    ${({ topBorder }) =>
        topBorder &&
        css`
            border-top-width: 1px;
            border-top-color: ${({ theme }) => theme.border[12]};
        `}
`;

export const ActionButtonWrapper = styled(View)`
    flex: 1;
`;

export const MealItemWrapper = styled(View)<{ last?: boolean }>`
    margin-bottom: ${({ last }) => (last ? 0 : 12)}px;
`;
