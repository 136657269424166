import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Content = styled(View)`
    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            max-width: 460px;
            padding: 0 16px;
        `}
`;

export const SettingsItemContainer = styled(View)`
    border-radius: 8px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.fill.level2};
`;
