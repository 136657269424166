import React from 'react';
import { useTheme } from 'styled-components/native';
import { ComingSoon } from '~/components/comingSoon';

export const DashboardComingSoon = () => {
    const theme = useTheme();

    return (
        <ComingSoon
            inner
            title="Wkrótce w tym miejscu znajdziesz również:"
            features={['Dziennik i monitor aktywności w tygodniu', 'Nowości', 'Czas pozostały do końca współpracy']}
            imageSrc={require('~/assets/images/dashboard_coming_soon.png')}
            background={theme.fill.level1}
        />
    );
};
