import React, { FC, Fragment } from 'react';
import Animated, { SharedValue } from 'react-native-reanimated';

import { DotsAnimations } from './Dots.animated';
import * as S from './Dots.styled';

interface DotsProps {
    scrollProgress: SharedValue<number>;
    totalItems: number;
    onDotPress: (index: number) => void;
}

const AnimatedDot = Animated.createAnimatedComponent(S.ActiveDot);

export const Dots: FC<DotsProps> = ({ scrollProgress, totalItems, onDotPress }) => {
    return (
        <S.Container>
            <S.Bar totalItems={totalItems}>
                <DotsAnimations scrollProgress={scrollProgress}>
                    {({ activeDotAnimatedStyles }) => <AnimatedDot style={[activeDotAnimatedStyles]} />}
                </DotsAnimations>
                <S.DotButtonsContainer>
                    {Array.from({ length: totalItems }).map((_, index) => (
                        <Fragment key={index}>
                            <S.DotButton
                                onPress={() => {
                                    onDotPress(index);
                                }}
                            />
                            {index !== totalItems - 1 && <S.Separator />}
                        </Fragment>
                    ))}
                </S.DotButtonsContainer>
            </S.Bar>
        </S.Container>
    );
};
