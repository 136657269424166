import { View } from 'react-native';
import styled from 'styled-components/native';

export const Placeholder = styled(View)`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
