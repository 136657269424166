import { Image, View } from 'react-native';
import styled, { css } from 'styled-components/native';

const baseStyle = css`
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 100px;
`;

export const Container = styled(Image)`
    ${baseStyle}
`;

export const PlaceholderWrapper = styled(View)`
    ${baseStyle}
    background-color:${({ theme }) => theme.fill.level4};
    border: 1px solid ${({ theme }) => theme.border['12']};
    justify-content: center;
    align-items: center;
`;
