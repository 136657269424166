import { RecursiveModelPredicate } from '@aws-amplify/datastore';
import { Goal } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchCurrentGoal: QueryFunction<Goal | null, ['getCurrentGoal']> = async () => {
    const result = await DataStore.query(
        Goal,
        (criteria) =>
            criteria.and(
                (c) => [c.startDate.le(new Date().toISOString())].filter(Boolean) as RecursiveModelPredicate<Goal>[],
            ),
        {
            sort: (s) => s.startDate(SortDirection.DESCENDING),
            limit: 1,
        },
    );

    return result?.[0] ?? null;
};

export const useGetCurrentGoal = () => useQuery(['getCurrentGoal'], fetchCurrentGoal);
