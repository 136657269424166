import { User, UserSubscription } from '@pg/backend';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useStorageAsset } from '~/api/assets/useStorageAsset';
import { useGetActiveUserSubscription } from '~/api/user/useGetActiveUserSubscription';
import { useGetUser } from '~/api/user/useGetUser';
import { useUpdateUser } from '~/api/user/useUpdateUser';
import { useAuth } from '~/enhancers/auth.context';
import { uploadFile } from '~/services/storage';
import { prefetchImage } from '~/utils/file';

export interface UserSettings {
    trainingTimeout: number;
    soundsMuted: boolean;
    usePreviousCycleWeights: boolean | null;
}

export interface UserContextType {
    user: User | null;
    settings: UserSettings | null;
    avatar: string | null;
    username: string | null;
    activeSubscription: UserSubscription | null;
    setAvatar: (uri: string, mimeType: string, type?: 'base64' | 'uri') => Promise<void>;
    updateSettings: (settings: Partial<UserSettings>) => Promise<void>;
}

export const UserContext = React.createContext<UserContextType>({
    user: null,
    settings: null,
    avatar: null,
    username: null,
    activeSubscription: null,
    setAvatar: () => {
        throw new Error('Not implemented');
    },
    updateSettings: () => {
        throw new Error('Not implemented');
    },
});

export interface UserContextProviderProps {
    children: ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
    const auth = useAuth();
    const userId = auth.user?.getUsername()!;

    const userQuery = useGetUser({ enabled: auth.isAuthenticated });
    const userActiveSubscriptionQuery = useGetActiveUserSubscription(userId);
    const updateUserMutation = useUpdateUser(userId);

    const avatar = useStorageAsset(userQuery.data?.avatarPath ?? null, 'protected');

    const username = useMemo(() => {
        if (!userQuery.data) return null;

        return `${userQuery.data.firstName} ${userQuery.data.lastName}`.trim();
    }, [userQuery.data]);

    const settings = useMemo(() => {
        if (!userQuery.data) return null;

        const defaultSettings: UserSettings = {
            trainingTimeout: 3,
            soundsMuted: false,
            usePreviousCycleWeights: null,
        };

        // @ts-ignore
        return Object.assign(defaultSettings, userQuery.data.settings || {}) as UserSettings;
    }, [userQuery.data]);

    useEffect(() => {
        if (!avatar) return;

        prefetchImage(avatar);
    }, [avatar]);

    const setAvatar = useCallback(
        async (uri: string, mimeType: string, type: 'base64' | 'uri') => {
            const fileExtension = mimeType.split('/').pop();
            const key = `avatar.${fileExtension}`;

            await uploadFile({
                key,
                filePath: uri,
                accessLevel: 'protected',
                mimeType,
                type,
            });
            await updateUserMutation.mutateAsync({ avatarPath: key });

            await userQuery.refetch();
        },
        [updateUserMutation, userQuery],
    );

    const updateSettings = useCallback(
        async (updatedSettings: Partial<UserSettings>) => {
            // @ts-ignore
            await updateUserMutation.mutateAsync({ settings: { ...settings, ...updatedSettings } });

            await userQuery.refetch();
        },
        [settings, updateUserMutation, userQuery],
    );

    const value: UserContextType = useMemo(
        () => ({
            user: userQuery.data ?? null,
            settings,
            username,
            avatar,
            activeSubscription: userActiveSubscriptionQuery.data ?? null,
            setAvatar,
            updateSettings,
        }),
        [userQuery.data, settings, username, avatar, userActiveSubscriptionQuery.data, setAvatar, updateSettings],
    );

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
