import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: 'To pole jest obowiązkowe.',
    },
    string: {
        email: 'Niepoprawny adres email.',
        min: 'Pole musi zawierać co najmniej ${min} znaków.',
        max: 'Pole nie może zawierać więcej niż ${max} znaków.',
    },
    number: {
        min: 'Liczba nie może być mniejsza niż ${min}.',
        max: 'Liczba nie może być większa niż ${max}.',
        positive: 'Liczba musi byc dodatnia.',
    },
});

export default yup;
