import { ImageBackground, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { Button as ButtonBase } from '~/components/button';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.fill.level1};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            overflow: auto;
            flex-direction: row;
            align-items: flex-end;
        `}
`;

export const Wrapper = styled(View)`
    padding: 64px 32px 45px;
    flex: 1;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            padding-bottom: 371px;
            padding-left: 164px;
        `}
`;

export const Number = styled(Text)`
    ${mixins.typography.h.semiBold72}
    color: ${({ theme }) => theme.text.primary};
`;
export const Info = styled(Text)`
    ${mixins.typography.h.semiBold28}
    color: ${({ theme }) => theme.text.primary};
    max-width: 450px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.h.semiBold40}
        `}
`;
export const Disclaimer = styled(Text)`
    ${mixins.typography.p.regular18}
    color: ${({ theme }) => theme.text.secondary};
    margin-top: 20px;
    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-top: 24px;
        `}
`;
export const Button = styled(ButtonBase)`
    margin-top: 40px;
`;

export const HeroImageWrapper = styled(View)`
    flex: 1;
`;

export const HeroImage = styled(ImageBackground)`
    flex: 1;
    width: 100%;
    min-height: 461px;
    height: auto;
    margin-top: auto;
    position: relative;
    align-self: flex-end;
    flex-direction: row;
    background-position: center bottom;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-right: 143px;
        `}

    ${({ theme }) =>
        theme.media.desktop &&
        css`
            width: 657px;
            min-height: 822px;
            margin-right: 143px;
        `}
`;
