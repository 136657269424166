import { SectionList, Text } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

import { ItemType, SectionType } from './ShoppingListCategories.types';

export const List = styled(SectionList<ItemType, SectionType>).attrs(() => ({
    contentContainerStyle: {
        paddingHorizontal: 20,
    },
}))``;

export const SectionHeader = styled(Text)`
    ${mixins.typography.ui.medium20};
    color: ${({ theme }) => theme.text.primary};
    padding-top: 16px;
    padding-bottom: 12px;
`;
