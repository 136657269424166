import { useNavigation } from '@react-navigation/native';
import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';

import { TrainingPlanNavigationProp } from '../../TrainingPlanScreen.types';
import * as S from './TrainingPlanHeader.styled';

export interface TrainingPlanHeaderProps {
    label?: string;
    description?: string;
    trainingDayId?: string;
    trainingRulesId?: string | null;
}

export const TrainingPlanHeader: FC<TrainingPlanHeaderProps> = ({
    label,
    description,
    trainingDayId,
    trainingRulesId,
}) => {
    const navigation = useNavigation<TrainingPlanNavigationProp>();
    const { isWebDesktop } = useTheme();

    const handleNavigation = () => {
        navigation.navigate('TrainingStack', { screen: 'Training', params: { trainingDayId: trainingDayId! } });
    };

    if (isWebDesktop) {
        return <S.DTitle>Plan treningowy</S.DTitle>;
    }

    return (
        <S.Container>
            <S.ButtonsContainer>
                <S.Button
                    variant="primary"
                    size="big"
                    icon="play"
                    label={label || ''}
                    description={description}
                    onPress={handleNavigation}
                />
                {!!trainingRulesId && (
                    <S.Button
                        size="big"
                        icon="info-fill"
                        label="Zasady"
                        description="Zapoznaj się przed rozpoczęciem"
                        last
                        onPress={() =>
                            navigation.navigate('TrainingStack', {
                                screen: 'TrainingRules',
                                params: { trainingRulesId },
                            })
                        }
                    />
                )}
            </S.ButtonsContainer>
            <S.TitleContainer>
                <S.TitleWrapper>
                    <S.Title>Oś treningowa cyklu</S.Title>
                </S.TitleWrapper>
            </S.TitleContainer>
        </S.Container>
    );
};
