import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { DigitBoxStyledProps } from '../CodeInput/CodeInput.types';

export const Container = styled(View)<DigitBoxStyledProps & { disabled?: boolean }>`
    flex: 1;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    background: ${({ theme }) => theme.fill.background};
    margin-right: 8px;

    ${({ noMargin }) =>
        noMargin &&
        css`
            margin: 0;
        `};

    ${({ theme, disabled }) =>
        disabled &&
        css`
            background: ${theme.fill.level2};
        `};
`;

export const Value = styled(Text)<{ disabled?: boolean }>`
    ${mixins.typography.h.medium24};
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.text.primary};

    ${({ theme, disabled }) =>
        disabled &&
        css`
            color: ${theme.text.disabled};
        `};
`;

export const Border = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 8px;
`;
