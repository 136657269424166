import React, { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import uuid from 'react-native-uuid';
import { NotificationConfig } from '~/screens/auth/components/Notifications/components/Notification';

export interface Notification {
    id: string;
    config: NotificationConfig;
}

export interface NotificationsContextType {
    notifications: Notification[];
    addNotification: (config: NotificationConfig) => void;
    removeNotification: (id: string) => void;
}

export const NotificationsContext = createContext<NotificationsContextType>({
    notifications: [],
    addNotification: () => {
        throw new Error('Not implemented');
    },
    removeNotification: () => {
        throw new Error('Not implemented');
    },
});

export interface NotificationsContextProviderProps {
    children: ReactNode;
}

export const NotificationsContextProvider: FC<NotificationsContextProviderProps> = ({ children }) => {
    const [currentNotifications, setCurrentNotifications] = useState<Notification[]>([]);

    const addNotification = useCallback((config: NotificationConfig) => {
        const id = uuid.v4().toString();
        setCurrentNotifications((prev) => [...prev, { id, config }]);

        return id;
    }, []);

    const removeNotification = useCallback((id: string) => {
        setCurrentNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, []);

    const value = useMemo(
        () => ({
            notifications: currentNotifications,
            addNotification,
            removeNotification,
        }),
        [currentNotifications, addNotification, removeNotification],
    );

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = () => useContext(NotificationsContext);
