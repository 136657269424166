import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { StandardNavigation } from '~/components/topNavigation';
import { TrainingInProgressTopNavigation } from '~/navigation/home/components/topNavigation';
import { TrainingRulesScreen } from '~/screens/rules';
import { TrainingScreen } from '~/screens/training';
import { TrainingPlanChoiceScreen } from '~/screens/trainingPlanChoice';

import { TrainingStackParamsList } from './TrainingStackNavigation.types';

const Stack = createNativeStackNavigator<TrainingStackParamsList>();

const TrainingPlanChoiceHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Wybierz plan treningowy"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'TrainingTabStack' })}
        {...props}
    />
);

const TrainingRulesHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'TrainingTabStack' })}
        {...props}
    />
);

export const TrainingStackNavigation = () => {
    return (
        <Stack.Navigator screenOptions={{ header: () => null }}>
            <Stack.Screen
                name="Training"
                component={TrainingScreen}
                options={{ header: TrainingInProgressTopNavigation }}
            />
            <Stack.Screen
                name="TrainingPlanChoice"
                component={TrainingPlanChoiceScreen}
                options={{
                    header: TrainingPlanChoiceHeader,
                }}
            />
            <Stack.Screen
                name="TrainingRules"
                component={TrainingRulesScreen}
                options={{
                    header: TrainingRulesHeader,
                }}
            />
        </Stack.Navigator>
    );
};
