import { LinkingOptions } from '@react-navigation/native/src/types';
import { getLinkingPathOptions } from '~/utils/linking';

import {
    AuthStackScreens,
    DashboardStackScreens,
    DietStackScreens,
    MeasurementsStackScreens,
    MoreStackScreens,
    TabScreens,
    TrainingStackScreens,
} from './paths';
import { RootParamsList } from './root';

export const linking: LinkingOptions<RootParamsList> = {
    prefixes: ['pgapp://', 'http://localhost'],
    config: {
        screens: {
            Home: {
                screens: {
                    Tab: TabScreens,
                    TrainingStack: TrainingStackScreens,
                    MeasurementsStack: MeasurementsStackScreens,
                    DietStack: DietStackScreens,
                    MoreStack: MoreStackScreens,
                    DashboardStack: DashboardStackScreens,
                },
            },
            Auth: {
                ...AuthStackScreens,
            },
            NotFound: {
                path: '*',
            },
        },
    },
    ...getLinkingPathOptions(),
};
