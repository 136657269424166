import styled, { css } from 'styled-components/native';
import { Button as ButtonBase } from '~/components/button';

export const Button = styled(ButtonBase)`
    margin-top: 32px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-top: 40px;
        `}
`;
