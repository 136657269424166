import { TrainingItemStatus } from '@pg/backend';
import { format } from 'date-fns';

export const getTrainingSubtitle = (
    status: TrainingItemStatus | keyof typeof TrainingItemStatus,
    date?: string | null,
    finishedSeries?: string,
) => {
    if (status === TrainingItemStatus.FINISHED && date)
        return `${format(new Date(date), 'dd.MM.yyyy')} · ${finishedSeries} serii`;

    if (status === TrainingItemStatus.IN_PROGRESS) return 'Czas na trening';

    return 'Jeszcze nie zrobiony';
};
