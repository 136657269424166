import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

type ContentProps = {
    maxWidth?: number;
};

export const Container = styled(View)`
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const Content = styled(View)<ContentProps>`
    max-width: ${({ maxWidth }) => maxWidth ?? 360}px;
    align-items: center;
`;

export const IconContainer = styled(View)`
    margin-bottom: 24px;
`;

export const TitleContainer = styled(View)`
    margin: 0 16px 0;
`;

export const Title = styled(Text)<{ color?: string }>`
    ${mixins.typography.h.medium20};
    color: ${({ theme }) => theme.text.tertiary};
    text-align: center;

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}
`;

export const DescriptionContainer = styled(View)`
    margin: 16px 16px 0;
`;

export const Description = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
    text-align: center;
`;

export const ButtonContainer = styled(View)`
    margin-top: 24px;
`;
