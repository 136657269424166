import React, { ReactElement } from 'react';

import * as S from './ResendCodeLabel.styled';

export interface ResendCodeLabelProps {
    disabled: boolean;
    timeRemaining: string;
}

export const ResendCodeLabel: React.FC<ResendCodeLabelProps> = ({ disabled, timeRemaining }) =>
    disabled ? (
        <S.LabelWrapper>Kod nie dotarł? ({timeRemaining})</S.LabelWrapper>
    ) : (
        ('Kod nie dotarł?' as unknown as ReactElement)
    );
