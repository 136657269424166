import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { NativeSyntheticEvent, TextInput, TextInputFocusEventData, TextInputProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { usePressableState } from '~/hooks/usePressableState';
import {
    ExerciseInputType,
    masks,
    valueDecoders,
    valueEncoders,
} from '~/screens/training/components/exerciseInput/ExerciseInput.utils';

import { ExerciseInputAnimations } from './ExerciseInput.animated';
import * as S from './ExerciseInput.styled';

interface InputProps extends TextInputProps {
    filled?: boolean;
    type?: ExerciseInputType;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);

export const ExerciseInput = React.forwardRef<TextInput | null, InputProps>(
    ({ filled, onFocus, onBlur, style, editable = true, value, type = 'reps', onChangeText, ...props }, ref) => {
        const [focused, setFocused] = useState(false);
        const inputRef = useRef<TextInput | null>(null);
        const theme = useTheme();
        const { pressableProps } = usePressableState();
        const disabled = !editable;
        const [selection, setSelection] = useState<{ start: number; end?: number } | undefined>({ start: 0, end: 0 });

        const mask = masks[type];

        useImperativeHandle<TextInput | null, TextInput | null>(ref, () => inputRef.current);

        const handleFocus = useCallback(
            (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                onFocus?.(e);
                if (value?.length) {
                    setSelection(value ? { start: 0, end: value.length } : undefined);
                }
                setFocused(true);
            },
            [onFocus, value],
        );

        const handleBlur = useCallback(
            (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
                onBlur?.(e);
                setFocused(false);
            },
            [onBlur],
        );

        const handleChangeText = useCallback(
            (value: string) => {
                const masked = value.replace(',', '.');
                const lastChar = masked[masked.length - 1];
                const isLastString = lastChar && !/^[0-9]+$/.test(lastChar);

                if (type === 'weight') {
                    if (isLastString && (lastChar !== '.' || masked.indexOf('.') !== masked.length - 1)) {
                        return;
                    }
                } else {
                    if (isLastString) {
                        return;
                    }
                }

                const transformedValue = valueEncoders[type](masked);

                setSelection(
                    transformedValue.length
                        ? { start: transformedValue.length, end: transformedValue.length }
                        : undefined,
                );
                onChangeText?.(transformedValue);
            },
            [onChangeText, type],
        );

        return (
            <S.Container {...pressableProps} style={style} pointerEvents={disabled ? 'none' : 'auto'}>
                <S.Input
                    {...props}
                    selection={selection}
                    mask={mask}
                    value={valueDecoders[type](value ?? 0)}
                    ref={inputRef}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    editable={editable}
                    placeholderTextColor={theme.text.disabled}
                    onChangeText={handleChangeText}
                    keyboardType="numeric"
                    returnKeyType="done"
                    maskAutoComplete
                    selectTextOnFocus
                />
                <ExerciseInputAnimations hovered={pressableProps.hovered} focused={focused} filled={!!filled}>
                    {({ borderAnimatedStyles }) => <AnimatedBorder pointerEvents="none" style={borderAnimatedStyles} />}
                </ExerciseInputAnimations>
            </S.Container>
        );
    },
);
