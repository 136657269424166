import { DateMarking, DateState } from './Day.types';

export const getDayVariant = (state: DateState, marking: DateMarking) => {
    if (state === 'today') {
        return 'today';
    }
    if (marking) {
        return 'marked';
    }
    return 'default';
};
