import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 0 16px;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 20px;
`;

export const Content = styled(View)`
    padding: 20px 0 16px;
    flex-direction: row;
`;

export const Header = styled(View)`
    flex: 1;
`;

export const RightContent = styled(View)`
    margin-left: 32px;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.medium18};
    color: ${({ theme }) => theme.text.primary};
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.secondary};
    margin-top: 4px;
`;
