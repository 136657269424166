import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { Image, ImageProps } from '~/components/image';

import * as S from './MealImage.styled';

interface MealImageProps extends Omit<ImageProps, 'placeholder'> {}

export const MealImage: FC<MealImageProps> = ({ foregroundColor, ...props }) => {
    const theme = useTheme();

    return (
        <Image
            {...props}
            foregroundColor={foregroundColor}
            placeholder={
                <S.Placeholder>
                    <Icon name="diet-outline" size={20} color={theme.icon.disabled} />
                </S.Placeholder>
            }
        />
    );
};
