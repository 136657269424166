import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    height: 52px;
    width: 100%;
    z-index: 100;
`;

export const Content = styled(View)`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
`;

export const Input = styled(Text)<{ focused: boolean; disabled: boolean }>`
    ${mixins.typography.ui.medium16};
    line-height: 20px;
    padding: 24px 12px 8px;
    flex: 1;
    background: ${({ theme, disabled }) => (disabled ? theme.fill.level2 : theme.fill.background)};
    color: ${({ theme, disabled }) => (disabled ? theme.text.disabled : theme.text.primary)};
    vertical-align: top;
    outline-width: 0;
`;

export const Border = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 8px;
`;

export const ArrowIconContainer = styled(View)`
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 12px;
`;
