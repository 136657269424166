import React, { FC, useEffect, useRef } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';
import { ActionButtonAnimation } from '~/screens/diet/components/mealItem/components/actionButton/ActionButton.animated';

import * as S from './ActionButton.styled';

interface ActionButtonStyledProps {
    topBorder?: boolean;
}

interface ActionButtonProps extends ActionButtonStyledProps {
    title?: string;
    iconName: IconName;
    active?: boolean;
    onPress?: () => void;
}

const AnimatedButton = Animated.createAnimatedComponent(S.Button);

export const ActionButton: FC<ActionButtonProps> = ({ title, iconName, topBorder, active, onPress }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();
    const buttonRef = useRef<any | null>(null);

    useEffect(() => {
        if (title && buttonRef.current && theme.isWeb) {
            buttonRef.current.setNativeProps({ title });
        }
    }, [theme.isWeb, title]);

    return (
        <ActionButtonAnimation progress={pressableAnimationProgress} active={active}>
            {({ buttonAnimationStyles }) => (
                <AnimatedButton
                    {...pressableProps}
                    ref={buttonRef}
                    style={buttonAnimationStyles}
                    topBorder={topBorder}
                    active={active}
                    onPress={onPress}
                >
                    <PressableIcon
                        name={iconName}
                        size={16}
                        color={active ? theme.icon.primary : theme.icon.tertiary}
                        pressedColor={theme.icon.primary}
                        pressableAnimationProgress={pressableAnimationProgress}
                    />
                </AnimatedButton>
            )}
        </ActionButtonAnimation>
    );
};
