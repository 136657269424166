import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)``;

export const ExpandPressable = styled(Pressable)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 16px;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.secondary};
`;

export const ListContainer = styled(View)``;
