import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { IndexDisc } from '~/components/indexDisc';
import { usePressableState } from '~/hooks/usePressableState';

import { PlanMenuItemBackgroundAnimation } from './PlanMenuItem.animated';
import * as S from './PlanMenuItem.styled';

export interface PlanMenuItemProps
    extends Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'disabled'> {
    children: string;
    index: number;
    selected?: boolean;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const PlanMenuItem: React.FC<PlanMenuItemProps> = ({ children, index, selected = false, ...props }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <PlanMenuItemBackgroundAnimation progress={pressableAnimationProgress}>
            {(animatedStyle) => (
                <AnimatedContainer {...pressableProps} {...props} style={[props.style, animatedStyle]}>
                    {selected && <Icon name="check-fill" size={24} color={theme.icon.positive} />}
                    {!selected && <IndexDisc index={index} />}
                    <S.TextContent>
                        <S.Text selected={selected}>{children}</S.Text>
                    </S.TextContent>
                </AnimatedContainer>
            )}
        </PlanMenuItemBackgroundAnimation>
    );
};
