import React from 'react';

import * as S from './CellText.styled';
import { CellTextStyledProps } from './CellText.types';

export interface CellTextProps extends CellTextStyledProps {
    children: React.ReactNode;
}

export const CellText: React.FC<CellTextProps> = ({ children, ...props }) => (
    <S.CellText {...props}>{children}</S.CellText>
);
