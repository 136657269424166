import { Pressable, View } from 'react-native';
import { Vimeo as VimeoBase } from 'react-native-vimeo-iframe';
import styled, { css } from 'styled-components/native';
import { IconButton } from '~/components/iconButton';
import { Loader as LoaderBase } from '~/components/loader';

export const Container = styled(View)`
    flex: 1;
    background-color: black;
    align-items: center;
    justify-content: center;
    ${({ theme }) =>
        theme.isWeb &&
        css`
            background-color: ${({ theme }) => theme.fill.background80};
            justify-content: center;
            align-items: center;
        `}
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 999;
`;

export const Vimeo = styled(VimeoBase)`
    background-color: transparent;
`;

export const Footer = styled(Pressable)`
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 136px;
    align-items: center;
    justify-content: center;
`;

export const WebCloseButton = styled(IconButton)`
    position: absolute;
    top: -44px;
    right: -44px;
`;

export const VideoContainer = styled(View)`
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.fill.level1};
    width: 100%;
    align-items: center;
    justify-content: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 800px;
        `}
`;

export const Wrapper = styled(View)`
    position: relative;
`;

export const Loader = styled(LoaderBase)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;
