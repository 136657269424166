import { css } from 'styled-components/native';

const font = {
    regular: css`
        ${({ theme }) => theme.font.primaryRegular};
        font-weight: 400;
    `,
    medium: css`
        ${({ theme }) => theme.font.primaryMedium};
        font-weight: 500;
    `,
    semiBold: css`
        ${({ theme }) => theme.font.primarySemiBold};
        font-weight: 600;
    `,
    bold: css`
        ${({ theme }) => theme.font.primaryBold};
        font-weight: 700;
    `,
};

const base = {
    h72: css`
        font-size: 72px;
        line-height: 86px;
        letter-spacing: -2.16px;
    `,
    h52: css`
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1.56px;
    `,
    h40: css`
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -0.8px;
    `,
    h32: css`
        font-size: 32px;
        line-height: 41px;
        letter-spacing: -0.45px;
    `,
    h28: css`
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.28px;
    `,
    h24: css`
        font-size: 24px;
        line-height: 31px;
        letter-spacing: -0.24px;
    `,
    h20: css`
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.2px;
    `,
    h18: css`
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.18px;
    `,
    h16: css`
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.16px;
    `,
    p24: css`
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.24px;
    `,
    p20: css`
        font-size: 20px;
        line-height: 38px;
        letter-spacing: -0.2px;
    `,
    p18: css`
        font-size: 18px;
        line-height: 32px;
    `,
    p16: css`
        font-size: 16px;
        line-height: 24px;
    `,
    ui20: css`
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.09px;
    `,
    ui18: css`
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.09px;
    `,
    ui16: css`
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.08px;
    `,
    ui14: css`
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.07px;
    `,
    ui12: css`
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.06px;
    `,
    ui11: css`
        font-size: 11px;
        line-height: 14px;
        letter-spacing: -0.055px;
    `,
};

export const h = {
    semiBold72: css`
        ${font.semiBold}
        ${base.h72}
    `,
    bold52: css`
        ${font.bold};
        ${base.h52};
    `,
    semiBold52: css`
        ${font.semiBold};
        ${base.h52};
    `,
    medium52: css`
        ${font.medium};
        ${base.h52};
    `,
    regular52: css`
        ${font.regular};
        ${base.h52};
    `,
    bold40: css`
        ${font.bold};
        ${base.h40};
    `,
    semiBold40: css`
        ${font.semiBold};
        ${base.h40};
    `,
    medium40: css`
        ${font.medium};
        ${base.h40};
    `,
    regular40: css`
        ${font.regular};
        ${base.h40};
        letter-spacing: -1.2px;
    `,
    bold32: css`
        ${font.bold};
        ${base.h32};
    `,
    semiBold32: css`
        ${font.semiBold};
        ${base.h32};
    `,
    medium32: css`
        ${font.medium};
        ${base.h32};
    `,
    regular32: css`
        ${font.regular};
        ${base.h32};
        letter-spacing: -0.64px;
    `,
    bold28: css`
        ${font.bold};
        ${base.h28};
    `,
    semiBold28: css`
        ${font.semiBold};
        ${base.h28};
    `,
    medium28: css`
        ${font.medium};
        ${base.h28};
    `,
    regular28: css`
        ${font.regular};
        ${base.h28};
        letter-spacing: -0.42px;
    `,
    bold24: css`
        ${font.bold};
        ${base.h24};
    `,
    semiBold24: css`
        ${font.semiBold};
        ${base.h24};
    `,
    medium24: css`
        ${font.medium};
        ${base.h24};
    `,
    regular24: css`
        ${font.regular};
        ${base.h24};
    `,
    bold20: css`
        ${font.bold};
        ${base.h20};
        letter-spacing: -0.1px;
    `,
    semiBold20: css`
        ${font.semiBold};
        ${base.h20};
    `,
    medium20: css`
        ${font.medium};
        ${base.h20};
    `,
    regular20: css`
        ${font.regular};
        ${base.h20};
    `,
    bold18: css`
        ${font.bold};
        ${base.h18};
        letter-spacing: -0.09px;
    `,
    semiBold18: css`
        ${font.semiBold};
        ${base.h18};
    `,
    medium18: css`
        ${font.medium};
        ${base.h18};
    `,
    regular18: css`
        ${font.regular};
        ${base.h18};
    `,
    bold16: css`
        ${font.bold};
        ${base.h16};
    `,
    semiBold16: css`
        ${font.semiBold};
        ${base.h16};
    `,
    medium16: css`
        ${font.medium};
        ${base.h16};
    `,
    regular16: css`
        ${font.regular};
        ${base.h16};
    `,
};

export const p = {
    bold24: css`
        ${font.bold};
        ${base.p24};
    `,
    semiBold24: css`
        ${font.semiBold};
        ${base.p24};
        letter-spacing: -0.6px;
    `,
    medium24: css`
        ${font.medium};
        ${base.p24};
        letter-spacing: -0.6px;
    `,
    regular24: css`
        ${font.regular};
        ${base.p24};
        letter-spacing: -0.72px;
    `,
    bold20: css`
        ${font.bold};
        ${base.p20};
    `,
    semiBold20: css`
        ${font.semiBold};
        ${base.p20};
        letter-spacing: -0.4px;
    `,
    medium20: css`
        ${base.p20};
        ${font.medium};
        letter-spacing: -0.4px;
    `,
    regular20: css`
        ${base.p20};
        ${font.regular};
        letter-spacing: -0.6px;
    `,
    bold18: css`
        ${font.bold};
        ${base.p18};
    `,
    semiBold18: css`
        ${font.semiBold};
        ${base.p18};
        letter-spacing: -0.27px;
    `,
    medium18: css`
        ${font.medium};
        ${base.p18};
        letter-spacing: -0.27px;
    `,
    regular18: css`
        ${font.regular};
        ${base.p18};
        letter-spacing: -0.36px;
    `,
    bold16: css`
        ${font.bold};
        ${base.p16};
    `,
    semiBold16: css`
        ${font.semiBold};
        ${base.p16};
        letter-spacing: -0.24px;
    `,
    medium16: css`
        ${font.medium};
        ${base.p16};
        letter-spacing: -0.24px;
    `,
    regular16: css`
        ${font.regular};
        ${base.p16};
        letter-spacing: -0.32px;
    `,
};

export const ui = {
    semiBold20: css`
        ${font.semiBold};
        ${base.ui18};
        letter-spacing: -0.2px;
    `,
    medium20: css`
        ${font.medium};
        ${base.ui20};
        letter-spacing: -0.2px;
    `,
    bold18: css`
        ${font.bold};
        ${base.ui18};
    `,
    semiBold18: css`
        ${font.semiBold};
        ${base.ui18};
        letter-spacing: -0.27px;
    `,
    medium18: css`
        ${font.medium};
        ${base.ui18};
        letter-spacing: -0.27px;
    `,
    regular18: css`
        ${font.regular};
        ${base.ui18};
        letter-spacing: -0.36px;
    `,
    bold16: css`
        ${font.bold};
        ${base.ui16};
    `,
    semiBold16: css`
        ${font.semiBold};
        ${base.ui16};
        letter-spacing: -0.24px;
    `,
    medium16: css`
        ${font.medium};
        ${base.ui16};
        letter-spacing: -0.24px;
    `,
    regular16: css`
        ${font.regular};
        ${base.ui16};
        letter-spacing: -0.32px;
    `,
    bold14: css`
        ${font.bold};
        ${base.ui14};
    `,
    semiBold14: css`
        ${font.semiBold};
        ${base.ui14};
        letter-spacing: -0.14px;
    `,
    medium14: css`
        ${font.medium};
        ${base.ui14};
        letter-spacing: -0.14px;
    `,
    regular14: css`
        ${font.regular};
        ${base.ui14};
        letter-spacing: -0.28px;
    `,
    bold12: css`
        ${font.bold};
        ${base.ui12};
    `,
    semiBold12: css`
        ${font.semiBold};
        ${base.ui12};
        letter-spacing: -0.12px;
    `,
    medium12: css`
        ${font.medium};
        ${base.ui12};
        letter-spacing: -0.12px;
    `,
    regular12: css`
        ${font.regular};
        ${base.ui12};
        letter-spacing: -0.24px;
    `,
    bold11: css`
        ${font.bold};
        ${base.ui11};
    `,
    semiBold11: css`
        ${font.semiBold};
        ${base.ui11};
        font-weight: 600;
        letter-spacing: -0.11px;
    `,
    medium11: css`
        ${font.medium};
        ${base.ui11};
        letter-spacing: -0.11px;
    `,
    regular11: css`
        ${font.regular};
        ${base.ui11};
        letter-spacing: -0.22px;
    `,
};
