import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { IndexDisc } from '~/components/indexDisc';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';

import { MenuItemBackgroundAnimation } from './MenuItem.animated';
import * as S from './MenuItem.styled';

export interface MenuItemProps
    extends Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'disabled'> {
    children: string;
    description?: string;
    icon?: IconName;
    index?: number;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const MenuItem: React.FC<MenuItemProps> = ({ children, description, icon, index, ...props }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <MenuItemBackgroundAnimation progress={pressableAnimationProgress}>
            {(animatedStyle) => (
                <AnimatedContainer {...pressableProps} {...props} style={[props.style, animatedStyle]}>
                    {icon && <Icon name={icon} size={20} color={theme.icon.tertiary} />}
                    {index && <IndexDisc index={index} />}
                    <S.TextContent>
                        <S.Text>{children}</S.Text>
                        {description && <S.Description>{description}</S.Description>}
                    </S.TextContent>
                    <Icon name="chevron-right" size={24} color={theme.icon.tertiary} />
                </AnimatedContainer>
            )}
        </MenuItemBackgroundAnimation>
    );
};
