import { View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    width: 80vw;
    max-width: 1400px;
    height: 100%;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled(View)`
    padding: 8px 32px;
`;
