import { BottomTabHeaderProps } from '@react-navigation/bottom-tabs';
import { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { Tabs } from '~/components/tabs';
import { LeftTitleNavigation } from '~/components/topNavigation';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useDiet } from '~/enhancers/dietContext';
import { usePressableState } from '~/hooks/usePressableState';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import * as S from './DietTopNavigation.styled';

export interface DietTopNavigationProps extends BottomTabHeaderProps {}

export const DietTopNavigation: FC<DietTopNavigationProps> = ({ navigation }) => {
    const { features } = useAppFeatures();
    const { isActivePlanInProgress, userHasDietSubscription, weeksTabs, selectedWeek, selectWeek } = useDiet();

    const theme = useTheme();

    const { pressableProps: pressableCartProps, pressableAnimationProgress: pressableCartAnimation } =
        usePressableState();
    const { pressableProps: pressableCalendarProps, pressableAnimationProgress: pressableCalendarAnimation } =
        usePressableState();
    const { pressableProps: pressableSettingsProps, pressableAnimationProgress: pressableSettingsAnimation } =
        usePressableState();

    const navigateToShoppingList = () => {
        navigation.navigate('MoreStack', {
            screen: 'ShoppingList',
        });
    };

    const goToToday = () => {
        Emitter.emit(EmitterEvent.goToTodayDietPlan, null);
    };

    const navigateToDietSettings = () => {
        navigation.navigate('DietStack', {
            screen: 'Settings',
        });
    };

    const rightContent = (
        <S.Wrapper>
            {features.shoppingList && (
                <S.Button {...pressableCartProps} onPress={navigateToShoppingList}>
                    <PressableIcon
                        name="shopping-list-outline"
                        pressableAnimationProgress={pressableCartAnimation}
                        color={theme.icon.tertiary}
                        pressedColor={theme.icon.primary}
                        size={20}
                    />
                </S.Button>
            )}
            {features.diet && userHasDietSubscription && (
                <S.Button {...pressableCalendarProps} disabled={isActivePlanInProgress} onPress={goToToday}>
                    <PressableIcon
                        name="calendar-today-outline"
                        pressableAnimationProgress={pressableCalendarAnimation}
                        color={isActivePlanInProgress ? theme.icon.disabled : theme.icon.tertiary}
                        pressedColor={theme.icon.primary}
                        size={20}
                    />
                </S.Button>
            )}
            {features.diet && userHasDietSubscription && (
                <S.Button {...pressableSettingsProps} onPress={navigateToDietSettings}>
                    <PressableIcon
                        name="settings"
                        pressableAnimationProgress={pressableSettingsAnimation}
                        color={theme.icon.tertiary}
                        pressedColor={theme.icon.primary}
                        size={20}
                    />
                </S.Button>
            )}
        </S.Wrapper>
    );

    const renderBottomContent = () => {
        if (weeksTabs?.length) {
            return (
                <S.TabsWrapper>
                    <Tabs
                        scrollable
                        tabs={weeksTabs}
                        activeTab={selectedWeek}
                        onSelectTab={(value) => selectWeek(value)}
                    />
                </S.TabsWrapper>
            );
        }
    };

    return (
        <LeftTitleNavigation
            title="Dieta"
            rightContent={rightContent}
            bottomContent={isActivePlanInProgress || userHasDietSubscription ? undefined : renderBottomContent()}
            bottomContentFullWidth
        />
    );
};
