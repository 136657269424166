import { Image as ImageBase, View } from 'react-native';
import styled from 'styled-components/native';
import { ImageStyledProps } from '~/components/image/Image.types';

export const Container = styled(View)<ImageStyledProps>`
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: ${({ backgroundColor, theme }) => (backgroundColor ? backgroundColor : theme.fill.level3)};
`;

export const LoaderContainer = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

export const Image = styled(ImageBase)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const PlaceholderImage = styled(View)`
    width: 100%;
    height: 100%;
`;
