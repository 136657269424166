import { Measurement } from '@pg/backend';
import { MutationFunction } from '@tanstack/query-core/build/lib/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

type MeasurementUpdateVariables = Pick<
    Measurement,
    | 'frontPhotoPath'
    | 'sidePhotoPath'
    | 'backPhotoPath'
    | 'weight'
    | 'waist'
    | 'hips'
    | 'thigh'
    | 'arm'
    | 'chest'
    | 'calf'
    | 'date'
> & { id?: string };

const createOrUpdateUserMeasurement: MutationFunction<Measurement, MeasurementUpdateVariables> = async (data) => {
    const { id, ...restOfData } = data;

    if (id) {
        const original = await DataStore.query(Measurement, id);

        if (!original) throw new Error(`Measurement with id ${id} not found`);

        return await DataStore.save(
            Measurement.copyOf(original, (updated) => {
                updated.frontPhotoPath = restOfData.frontPhotoPath;
                updated.sidePhotoPath = restOfData.sidePhotoPath;
                updated.backPhotoPath = restOfData.backPhotoPath;
                updated.weight = restOfData.weight;
                updated.waist = restOfData.waist;
                updated.hips = restOfData.hips;
                updated.thigh = restOfData.thigh;
                updated.arm = restOfData.arm;
                updated.chest = restOfData.chest;
                updated.calf = restOfData.calf;
                updated.date = restOfData.date;
            }),
        );
    }

    return await DataStore.save(new Measurement(restOfData));
};

export const useCreateOrUpdateUserMeasurement = (
    options?: UseMutationOptions<Measurement, unknown, MeasurementUpdateVariables>,
) => {
    return useMutation(createOrUpdateUserMeasurement, options);
};
