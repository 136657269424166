import * as d3 from 'd3';
import { differenceInMonths, endOfDay, isSameMonth, startOfDay } from 'date-fns';
import { useMemo } from 'react';

import { ChartConfig, ProgressLineChartItem } from './ProgressLineChart.types';

export const useChart = ({ data, width, height }: ChartConfig) => {
    const dataPointSize = 24;

    // padding and margin
    const bottomMargin = 16;
    const leftMargin = 35;
    const basePadding = dataPointSize / 2;
    const leftPadding = dataPointSize;
    const rightPadding = basePadding;
    const horizontalPadding = leftPadding + rightPadding;

    // ticks
    const yTickLabelSize = 12;
    const xTicksLabelPosY = height - bottomMargin;
    const yTicksLabelPosX = leftMargin + basePadding / 2;

    // container
    const innerWidth = width - horizontalPadding;
    const innerHeight = height - basePadding * 2;
    const chartWidth = innerWidth + rightPadding;
    const chartHeight = innerHeight - bottomMargin - yTickLabelSize;

    return useMemo(() => {
        const values = data.map((item) => item.value);
        const maxValue = Math.max(...values);
        const minValue = Math.min(...values);
        const firstItem = data[0];
        const lastItem = data[data.length - 1];
        const startDate = startOfDay(firstItem?.date ?? new Date());
        const endDate = endOfDay(lastItem?.date ?? new Date());

        // x axis
        const xTicksNumber = differenceInMonths(endDate, startDate);
        const xScale = d3
            .scaleTime()
            .domain([startDate, endDate])
            .range([leftMargin + leftPadding, chartWidth]);

        const singleMonthTicks = [
            {
                value: startDate,
                index: 0,
                x: leftMargin + leftPadding + chartWidth / 2,
                y: xTicksLabelPosY,
            },
        ];

        let ticks = singleMonthTicks;

        // multiple months
        if (xTicksNumber) {
            const baseTicks = xScale.ticks(xTicksNumber);

            // add first month if not present
            if (!isSameMonth(startDate, baseTicks[0])) {
                baseTicks.unshift(startDate);
            }

            ticks = baseTicks.map((tick, index) => ({
                value: startOfDay(tick),
                index,
                x: xScale(startOfDay(tick)),
                y: xTicksLabelPosY,
            }));
        }

        // y axis
        const yAxisTicksNumber = 5; // It's aproximate number of ticks, so d3 can adjust it if needed
        const yScale = d3
            .scaleLinear()
            .domain([minValue, maxValue])
            .range([chartHeight, basePadding + dataPointSize / 2]);

        const yTicks = yScale.ticks(yAxisTicksNumber).map((tick, index) => ({
            value: tick,
            index,
            x: yTicksLabelPosX,
            y: yScale(tick),
        }));

        const [xStart, xEnd] = xScale.range();
        const [yStart, yEnd] = yScale.range();

        const dataPoints = data.map((item, index) => ({
            index,
            x: xScale(item.date),
            y: yScale(item.value),
        }));

        // line
        const linePath = d3
            .line<ProgressLineChartItem>()
            .x((item) => xScale(startOfDay(item.date)))
            .y((item) => yScale(item.value))
            .curve(d3.curveLinear)(data);

        const gradient = `${linePath} L ${chartWidth - rightPadding} ${chartHeight} L ${
            leftPadding + leftMargin
        } ${chartHeight}`;

        return {
            dataPoints,
            dataPointSize,
            maxValue,
            minValue,
            startDate,
            endDate,
            line: String(linePath),
            gradient,
            ticks,
            xScale,
            xStart,
            xEnd,
            yScale,
            yTicks,
            yStart,
            yEnd,
            width,
            height,
            innerHeight,
            innerWidth,
            chartHeight,
            chartWidth,
            xOffset: leftMargin + leftPadding,
        };
    }, [
        data,
        leftPadding,
        chartWidth,
        xTicksLabelPosY,
        chartHeight,
        basePadding,
        rightPadding,
        width,
        height,
        innerHeight,
        innerWidth,
        yTicksLabelPosX,
    ]);
};
