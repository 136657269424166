import React, { useMemo, useState } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { Loader } from '~/components/loader';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { PhotoTileAnimation } from './PhotoTile.animated';
import * as S from './PhotoTile.styled';
import { ImageStatus, PhotoTileStyledProps } from './PhotoTile.types';
import { RemoveButton } from './components/RemoveButton';

export interface PhotoTileProps {
    caption?: string;
    placeholderLabel?: string;
    imageUri?: string | null;
    onAdd?: () => void;
    onRemove?: () => void;
}

const AnimatedBox = Animated.createAnimatedComponent(S.Box);

export const PhotoTile: React.FC<PhotoTileProps> = ({ caption, placeholderLabel, imageUri, onAdd, onRemove }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();
    const [imageStatus, setIsImageLoaded] = useState<ImageStatus>(imageUri ? 'loading' : 'none');

    const imageLifecycleCallbacks = useMemo(
        () => ({
            onLoadStart: () => setIsImageLoaded('loading'),
            onLoad: () => setIsImageLoaded('loaded'),
            onError: () => setIsImageLoaded('error'),
        }),
        [],
    );

    const renderContent = () => {
        if (imageUri && imageStatus !== 'error') {
            return (
                <>
                    <S.Image source={{ uri: imageUri }} resizeMode="cover" {...imageLifecycleCallbacks} />
                    {onRemove && (
                        <S.RemoveButtonWrapper>
                            <RemoveButton onPress={onRemove} />
                        </S.RemoveButtonWrapper>
                    )}
                    {imageStatus === 'loading' && (
                        <S.ImageLoaderWrapper>
                            <Loader size="xl" color={theme.icon.interactive} />
                        </S.ImageLoaderWrapper>
                    )}
                </>
            );
        }

        if (onAdd) {
            return (
                <S.AddIconWrapper>
                    <PressableIcon
                        name="add"
                        size={24}
                        color={theme.icon.primary}
                        pressedColor={theme.icon.interactive}
                        pressableAnimationProgress={pressableAnimationProgress}
                    />
                </S.AddIconWrapper>
            );
        }

        if (placeholderLabel) {
            return (
                <S.PlaceholderLabelWrapper>
                    <Icon name="photo-gallery-outline" size={24} color={theme.icon.tertiary} />
                    <S.PlaceholderLabel>{placeholderLabel}</S.PlaceholderLabel>
                </S.PlaceholderLabelWrapper>
            );
        }

        return null;
    };

    const getVariant = (): PhotoTileStyledProps['variant'] => {
        if (imageUri && imageStatus !== 'error') {
            return 'photo';
        }

        if (onAdd) {
            return 'noPhoto';
        }

        return 'placeholder';
    };

    return (
        <S.Container>
            {onAdd ? (
                <PhotoTileAnimation progress={pressableAnimationProgress}>
                    {({ boxAnimatedStyles }) => (
                        <AnimatedBox
                            {...pressableProps}
                            style={boxAnimatedStyles}
                            variant={getVariant()}
                            onPress={!imageUri ? onAdd : undefined}
                        >
                            {renderContent()}
                        </AnimatedBox>
                    )}
                </PhotoTileAnimation>
            ) : (
                <S.Box variant={getVariant()} onPress={undefined}>
                    {renderContent()}
                </S.Box>
            )}
            {caption && <S.Caption>{caption}</S.Caption>}
        </S.Container>
    );
};
