import React, { useEffect, useRef } from 'react';
import { ConfirmSheet, ConfirmSheetRef } from '~/components/bottomSheet/confirmSheet';
import { Table } from '~/components/table';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts/native';

import { HealthTestsComingSoon } from './components/healthTestesComingSoon';
import { useHealthTestsTable } from './hooks/useHealthTestsTable';

export const HealthTestsMobileContent = () => {
    const { table, removalConfirmSheet, healthTestIdToDelete } = useHealthTestsTable();
    const healthTestRemovalConfirmSheet = useRef<ConfirmSheetRef>(null);

    useEffect(() => {
        if (healthTestIdToDelete) {
            healthTestRemovalConfirmSheet.current?.present();
        } else {
            healthTestRemovalConfirmSheet.current?.close();
        }
    }, [healthTestIdToDelete]);

    return (
        <>
            <Table {...table} />

            <ConfirmSheet ref={healthTestRemovalConfirmSheet} {...removalConfirmSheet} />
        </>
    );
};

export const HealthTestsScreen = () => {
    const { features } = useAppFeatures();

    if (!features.healthTests) {
        return (
            <MainLayout noBackground fullWidth>
                <HealthTestsComingSoon />
            </MainLayout>
        );
    }

    return (
        <MainLayout scrollable={false} fullWidth verticalSpacing={false}>
            <HealthTestsMobileContent />
        </MainLayout>
    );
};
