import React, { ReactNode } from 'react';

import * as S from './Field.styled';

export interface FieldProps {
    last?: boolean;
    children: ReactNode;
}

export const Field: React.FC<FieldProps> = ({ children, last }) => <S.Container last={last}>{children}</S.Container>;
