import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    flex: 1;
    padding: 4px 0;
    border-width: 2px;
    border-color: ${({ theme }) => theme.border['12']};
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    flex-basis: auto;
`;

export const Item = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const ItemHeader = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.tertiary};
`;

export const ItemValue = styled(Text)`
    ${mixins.typography.ui.medium14}
    color: ${({ theme }) => theme.text.primary};
`;

export const ItemSeparator = styled(View)`
    background-color: ${({ theme }) => theme.border['12']};
    width: 2px;
    height: 26px;
`;
