import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts/web';

import { ChatComingSoon } from '../chat/components/chatComingSoon';
import { ChatSelection as ChatSelectionMobile } from './ChatSelection.native';
import * as S from './ChatSelection.styled';
import { ChatSelectionContent } from './components/chatSelectionContent';

const title = 'Czat';

export const ChatSelection = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();

    if (!features.chat)
        return <MainLayout title={title} noBackground={!theme.isWebDesktop} content={<ChatComingSoon />} />;

    if (!theme.isWebDesktop) return <ChatSelectionMobile />;

    return <S.MainLayout title={title} content={<ChatSelectionContent />} />;
};
