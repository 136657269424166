import { User, UserSubscription } from '@pg/backend';
import { QueryFunction, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchActiveUserSubscription: QueryFunction<
    UserSubscription | null,
    ['getActiveUserSubscription', User['id']]
> = async ({ queryKey }) => {
    const [_, userId] = queryKey;

    try {
        return (
            (
                await DataStore.query(
                    UserSubscription,
                    (criteria) =>
                        criteria.and((criteria) => [
                            criteria.or((criteria) => [
                                criteria.endDate.eq(null),
                                criteria.endDate.gt(new Date().toISOString()),
                            ]),
                            criteria.userID.eq(userId),
                        ]),
                    {
                        sort: (s) => s.purchaseDate(SortDirection.DESCENDING),
                        limit: 1,
                    },
                )
            )?.[0] ?? null
        );
    } catch (e) {
        console.log('error', e);
        return null;
    }
};

export const useGetActiveUserSubscription = (
    userId: User['id'],
    options?: UseQueryOptions<
        UserSubscription | null,
        unknown,
        UserSubscription | null,
        ['getActiveUserSubscription', User['id']]
    >,
) => useQuery(['getActiveUserSubscription', userId], fetchActiveUserSubscription, options);
