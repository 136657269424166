import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { Loader as LoaderBase } from '~/components/loader';

import { ContainerProps, SideContentWrapperProps } from './TopNavWrapper.types';

export const Container = styled(View)<ContainerProps>`
    padding-top: ${({ inset, noExtraSpacing }) => inset.top + (noExtraSpacing ? 0 : 6)}px;
    padding-bottom: ${({ noExtraSpacing }) => (noExtraSpacing ? 0 : '12px')};
    background-color: ${({ theme }) => (theme.isWebDesktop ? theme.fill.level1 : theme.fill.background)};

    ${({ theme, noExtraSpacing }) =>
        theme.isWebMobile &&
        css`
            padding-top: ${noExtraSpacing ? 0 : 12}px;
        `}
`;

export const Wrapper = styled(View)`
    padding: 0 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const BottomContentWrapper = styled(View)<{ fullWidth?: boolean }>`
    padding: 0 16px;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            padding: 0;
        `}
`;

export const CenterContentWrapper = styled(View)`
    flex: 1;
    flex-direction: row;
    justify-content: center;
`;

export const SideContentWrapper = styled(View)<SideContentWrapperProps>`
    flex-direction: row;
    align-items: center;
    width: ${({ variant }) => (variant === 'leftTitle' || variant === 'rightButton' ? 'auto' : '20px')};
`;

export const Loader = styled(LoaderBase)`
    margin-right: 16px;
`;
