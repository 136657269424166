import * as S from './SupplementTile.styled';

type SupplementTileProps = {
    name: string;
    info?: string;
    description?: string;
};

export const SupplementTile = ({ name, info, description }: SupplementTileProps) => {
    return (
        <S.Container>
            <S.Title>{name}</S.Title>
            <S.Description>
                {info && <S.DescriptionItem last={!description}>{info}</S.DescriptionItem>}
                {description && <S.DescriptionItem last>{description}</S.DescriptionItem>}
            </S.Description>
        </S.Container>
    );
};
