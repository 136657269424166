import { useNavigation } from '@react-navigation/native';
import React, { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { TabType } from '~/components/tabs';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import { DietPlan, mockedDietPlans } from './dietTempData';
import { getCalendarWeeks, getCurrentWeekIndex } from './utils';

export interface DietContextType {
    plans: DietPlan[];
    activePlan: null | DietPlan;
    userHasDietSubscription: boolean;
    isActivePlanInProgress: boolean;
    weeksTabs: TabType<string>[] | undefined;
    selectedWeek: string;
    isSelectedWeekCurrent: boolean;
    sliderData: DietPlan['daysData'];
    selectWeek: (week?: string) => void;
    selectActivePlan: (id: string) => void;
}

export const DietContext = createContext<DietContextType>({
    activePlan: null,
    plans: [],
    userHasDietSubscription: false,
    isActivePlanInProgress: false,
    weeksTabs: undefined,
    selectedWeek: '0',
    isSelectedWeekCurrent: false,
    sliderData: [],
    selectWeek: () => {
        throw new Error('Not implemented');
    },
    selectActivePlan: () => {
        throw new Error('Not implemented');
    },
});

export interface DietContextProviderProps {
    children: ReactNode;
}

export const DietContextProvider: FC<DietContextProviderProps> = ({ children }) => {
    const userHasDietSubscription = true;
    const [activePlan, setActivePlan] = useState<DietContextType['activePlan']>(mockedDietPlans[1]);
    const isActivePlanInProgress = !(activePlan?.startDate && activePlan?.endDate);
    const weeks = isActivePlanInProgress ? undefined : getCalendarWeeks(activePlan?.startDate, activePlan?.endDate);
    const currentWeekIndex = getCurrentWeekIndex(weeks);
    const navigation = useNavigation();

    const weeksTabs = weeks?.map((week, index) => ({
        label: `Tydzień ${index + 1}`,
        value: index.toString(),
    }));
    const [selectedWeek, setSelectedWeek] = useState<string>(currentWeekIndex);
    const selectedWeekStartDate = weeks?.[Number(selectedWeek)]?.startDate;
    const selectedWeekEndDate = weeks?.[Number(selectedWeek)]?.endDate;

    useEffect(() => {
        const weekIndexReset = navigation.addListener('state', () => {
            // @ts-ignore
            if (navigation.getCurrentRoute()?.name !== 'Diet') {
                setSelectedWeek(currentWeekIndex);
            }
        });

        return weekIndexReset;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]);

    useEffect(() => {
        const handleEmmiter = () => {
            setSelectedWeek(currentWeekIndex);
        };

        Emitter.on(EmitterEvent.goToTodayDietPlan, handleEmmiter);

        return () => {
            Emitter.off(EmitterEvent.goToTodayDietPlan, handleEmmiter);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sliderData = useMemo(
        () =>
            activePlan?.daysData?.filter((day) => {
                return (
                    new Date(day.date) >= new Date(selectedWeekStartDate!) &&
                    new Date(day.date) <= new Date(selectedWeekEndDate!)
                );
            }) || [],
        [activePlan, selectedWeekStartDate, selectedWeekEndDate],
    );

    const value = useMemo(
        () => ({
            activePlan,
            plans: mockedDietPlans,
            userHasDietSubscription,
            isActivePlanInProgress,
            weeksTabs,
            selectedWeek,
            isSelectedWeekCurrent: selectedWeek === currentWeekIndex,
            sliderData,
            selectWeek: (week?: string) => setSelectedWeek(week || currentWeekIndex),
            selectActivePlan: (id: string) => {
                const dietPlan = mockedDietPlans.find((plan) => plan.id === id);
                dietPlan && setActivePlan(dietPlan);
            },
        }),
        [
            activePlan,
            userHasDietSubscription,
            isActivePlanInProgress,
            weeksTabs,
            selectedWeek,
            currentWeekIndex,
            sliderData,
        ],
    );

    return <DietContext.Provider value={value}>{children}</DietContext.Provider>;
};

export const useDiet = () => useContext(DietContext);
