import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { ToggleAnimations } from '~/components/toggle/Toggle.animated';

import * as S from './Toggle.styled';
import { ToggleStyledProps } from './Toggle.types';

export interface ToggleProps extends Omit<ToggleStyledProps, 'size'>, Omit<PressableProps, 'disabled' | 'onPress'> {
    size?: ToggleStyledProps['size'];
    label?: string;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);
const AnimatedDot = Animated.createAnimatedComponent(S.Dot);
const AnimatedBackground = Animated.createAnimatedComponent(S.Background);

export const Toggle: React.FC<ToggleProps> = ({ size = 'l', label, checked = false, disabled, ...props }) => {
    const handlePress = () => {
        props.onChange?.(!checked);
    };

    return (
        <S.Container {...props} disabled={disabled} onPress={handlePress}>
            <ToggleAnimations checked={checked} disabled={!!disabled} size={size}>
                {({ borderAnimatedStyles, dotAnimatedStyles, backgroundAnimatedStyles }) => (
                    <S.ControlContainer size={size}>
                        <AnimatedBackground pointerEvents="none" size={size} style={backgroundAnimatedStyles} />
                        <AnimatedBorder pointerEvents="none" size={size} style={borderAnimatedStyles} />
                        <AnimatedDot pointerEvents="none" size={size} style={dotAnimatedStyles} />
                    </S.ControlContainer>
                )}
            </ToggleAnimations>
            {label && <S.Label size={size}>{label}</S.Label>}
        </S.Container>
    );
};
