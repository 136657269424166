import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { SEO } from '~/components/wrappers';
import { useAuth } from '~/enhancers/auth.context';
import { useNotifications } from '~/enhancers/notifications.context';
import { AuthLayout } from '~/layouts/authLayout';
import { ResendCodeLabel } from '~/screens/auth/components/ResendCodeLabel';
import { getErrorMessage } from '~/utils/authErrorMessage';

import { AuthFormLayout } from '../layouts/authFormLayout';
import { notificationMessage } from './SignUpConfirmationScreen.constants';
import { SignUpConfirmationScreenProps } from './SignUpConfirmationScreen.types';
import { SignUpConfirmationForm } from './components/SignUpConfirmationForm';
import { FormValues } from './components/SignUpConfirmationForm/SignUpConfirmationForm.types';

export const SignUpConfirmationScreen: React.FC<SignUpConfirmationScreenProps> = ({ route, navigation }) => {
    const theme = useTheme();
    const auth = useAuth();
    const userEmail = route.params?.email || '';
    const { confirmSignUp, resendSignUpVerificationCode, resendCodeCountdown } = useAuth();
    const { addNotification } = useNotifications();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!userEmail.length || !auth.signUpConfirmationEnabled) {
            setTimeout(() => {
                navigation.navigate('SignIn');
            });
        }
    }, [auth.signUpConfirmationEnabled, navigation, userEmail.length]);

    const handleResendCode = useCallback(async () => {
        setErrorMessage(undefined);
        try {
            await resendSignUpVerificationCode(userEmail);
            addNotification({ message: notificationMessage.resendCodeSuccess, type: 'informative' });
        } catch (e) {
            setErrorMessage(getErrorMessage(e));
        } finally {
            resendCodeCountdown.start();
        }
    }, [addNotification, resendCodeCountdown, resendSignUpVerificationCode, userEmail]);

    const handleSubmit: SubmitHandler<FormValues> = useCallback(
        async (values) => {
            setErrorMessage(undefined);
            try {
                await confirmSignUp(userEmail, values.code);
                navigation.navigate('SignIn', { email: userEmail });
                auth.setSignUpConfirmationEnabled(false);
                addNotification({ message: notificationMessage.signUpSuccess, type: 'informative' });
            } catch (e) {
                setErrorMessage(getErrorMessage(e));
            } finally {
                setLoading(false);
            }
        },
        [addNotification, auth, confirmSignUp, navigation, userEmail],
    );

    const layoutImageSrc = useMemo(() => {
        return theme.isWebDesktop
            ? require('~/assets/images/verification_code_web.webp')
            : require('~/assets/images/verification_code.png');
    }, [theme.isWebDesktop]);

    return (
        <AuthLayout imageSrc={layoutImageSrc}>
            <SEO title="Rejestracja" />
            <AuthFormLayout
                title="Wpisz kod, który został wysłany na podany adres email"
                subtitle={userEmail && `Wysłano wiadomość z kodem weryfikacyjnym na ${route.params?.email || ''}`}
                disabled={loading}
                error={errorMessage}
                bottomActions={[
                    {
                        id: 'resend-code',
                        label: (
                            <ResendCodeLabel
                                disabled={resendCodeCountdown.disabled}
                                timeRemaining={resendCodeCountdown.timeRemaining}
                            />
                        ),
                        onPress: handleResendCode,
                        disabled: resendCodeCountdown.disabled,
                    },
                    {
                        id: 'cancel',
                        label: 'Anuluj',
                        onPress: () => navigation.navigate('SignIn'),
                    },
                ]}
            >
                <SignUpConfirmationForm error={!!errorMessage} onSubmit={handleSubmit} />
            </AuthFormLayout>
        </AuthLayout>
    );
};
