import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import { getDateType, getDaysOfTheWeek } from './WeeklyActivity.utils';

export const backgroundColors = ['rgba(255, 255, 255, 0.2288)', 'rgba(255, 255, 255, 0.0832)'];
export const borderColors = ['rgba(255, 255, 255, 0.11)', 'rgba(255, 255, 255, 0.07)'];

export const calendarData = getDaysOfTheWeek().map((day) => ({
    id: day,
    day: format(new Date(day), 'EEEEEE', { locale: plLocale }),
    date: format(new Date(day), 'dd.MM', { locale: plLocale }),
    dateType: getDateType(new Date(day)),
}));
