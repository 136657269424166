import { ChatType } from '@pg/backend';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';

import { ChatTopic } from '../chatTopic';
import { ChatSelectionContent as ChatSelectionContentMobile } from './ChatSelectionContent.native';
import * as S from './ChatSelectionContent.styled';
import { ChatNavigationProp } from './ChatSelectionContent.types';

const mockData = [ChatType.TRAINING, ChatType.DIET, ChatType.GENERAL];

export const ChatSelectionContent = () => {
    const navigation = useNavigation<ChatNavigationProp>();

    const handleTopicSelection = (chatType: ChatType) => {
        navigation.navigate('ChatScreen', { chatType });
    };

    const theme = useTheme();

    if (!theme.isWebDesktop) return <ChatSelectionContentMobile />;

    return (
        <S.Container>
            <S.Wrapper>
                <S.Title>O czym chcesz porozmawiać?</S.Title>
                {mockData.map((chatType, index) => (
                    <ChatTopic chatType={chatType} key={index} onPress={() => handleTopicSelection(chatType)} />
                ))}
                <S.Disclaimer>Odpowiadamy w ciągu 24h w dni robocze</S.Disclaimer>
            </S.Wrapper>
        </S.Container>
    );
};
