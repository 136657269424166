import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled(View)<{ last?: boolean }>`
    margin-bottom: 24px;

    ${({ last }) =>
        last &&
        css`
            margin-bottom: 0;
        `}
`;
