import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

import { BarType, ReactionType } from './DayBar.types';
import { DayBarReaction } from './DayBarReaction';

export const Container = styled(View)`
    align-items: center;
    padding-top: 14px;
    margin-bottom: ${({ theme }) => (theme.isWebDesktop ? '16px' : '0')};
`;

export const DateContainer = styled(View)`
    align-items: center;
    margin-top: ${({ theme }) => (theme.isWebDesktop ? '24px' : '12px')};
`;

export const DayText = styled(Text)`
    ${mixins.typography.ui.medium12};
    color: ${({ theme }) => theme.text.primary};
    text-transform: capitalize;
`;

export const DateText = styled(Text)`
    ${mixins.typography.ui.regular11};
    color: ${({ theme }) => theme.text.tertiary};
`;

export const Bar = styled(View)<BarType>`
    position: relative;
    width: 8px;
    height: ${({ theme }) => (theme.isWebDesktop ? '159px' : '83px')};
    border-radius: 4px;
    background-color: ${({ theme, dateType, activities }) => {
        if (dateType === 'today') return theme.fill.informative;
        if (dateType === 'future') return theme.border[32];
        if (activities > 0) return theme.fill.positive;
        return theme.border[32];
    }};
`;

export const TodayIndicator = styled(View)`
    width: 6px;
    height: 6px;
    border-radius: 3px;
    position: absolute;
    background-color: ${({ theme }) => theme.fill.informative};
    top: 0;
`;

export const Reaction = styled(DayBarReaction)<ReactionType>`
    position: absolute;
    z-index: 10;
    bottom: ${({ theme, emoji, activities }) => {
        if (emoji === 'today') return theme.isWebDesktop ? '66px' : '28px';
        if (activities === 1) return theme.isWebDesktop ? '107px' : '44px';
        if (activities > 1 && theme.isWebDesktop) return '75px';
        return theme.isWebDesktop ? '24px' : '12px';
    }};
    right: -10px;
    border-color: ${({ theme, emoji }) => {
        switch (emoji) {
            case 'break':
                return theme.border[40];
            case 'today':
                return theme.fill.informative;
            case 'workout':
                return theme.fill.positive;
        }
    }};
`;

export const Count = styled(DayBarReaction)`
    position: absolute;
    z-index: 10;
    bottom: ${({ theme }) => (theme.isWebDesktop ? '107px' : '44px')};
    right: -10px;
    border-color: ${({ theme }) => theme.fill.positive};
`;
