import { FC } from 'react';
import { PressableProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import * as S from './AvatarAccount.styled';

interface AvatarAccountProps extends PressableProps {
    uri?: string;
    premium?: boolean;
}

export const AvatarAccount: FC<AvatarAccountProps> = ({ uri, premium = false, ...props }) => {
    const theme = useTheme();

    return (
        <S.Container {...props}>
            <S.ContentContainer premium={premium}>
                {uri ? (
                    <S.AvatarImage source={{ uri }} />
                ) : (
                    <S.PlaceholderContainer>
                        <Icon name="account-avatar" size={theme.isWebDesktop ? 58 : 38} color={theme.icon.tertiary} />
                    </S.PlaceholderContainer>
                )}
            </S.ContentContainer>
            {premium && (
                <S.Badge>
                    <Icon name="crown" size={theme.isWebDesktop ? 18 : 12} color={theme.icon.primary} />
                </S.Badge>
            )}
        </S.Container>
    );
};
