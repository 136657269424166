import { useRef } from 'react';
import { useFilePicker } from 'use-file-picker';
import { FileContent } from 'use-file-picker/src/interfaces';

import { Image } from '../imagePicker.types';

export const usePicker = () => {
    const promiseResolverRef = useRef<((value: Image) => void) | null>(null);
    const promiseRejectorRef = useRef<(() => void) | null>(null);

    const handleFileSelected = ({ plainFiles, filesContent }: { plainFiles: File[]; filesContent: FileContent[] }) => {
        const plainFile = plainFiles[0];
        const file = filesContent[0];
        promiseResolverRef.current?.({ uri: file.content, mime: plainFile.type });
    };

    const handleFilesRejected = () => {
        promiseRejectorRef.current?.();
    };

    const [openFileSelector] = useFilePicker({
        multiple: false,
        accept: 'image/*',
        readAs: 'DataURL',
        onFilesSuccessfulySelected: handleFileSelected,
        onFilesRejected: handleFilesRejected,
    });

    const openPicker = async () => {
        promiseRejectorRef.current?.();

        return new Promise((resolve, reject) => {
            promiseResolverRef.current = resolve;
            promiseRejectorRef.current = reject;

            openFileSelector();
        });
    };

    return {
        openPicker,
        openCamera: openPicker,
    };
};
