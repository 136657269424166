import { Pressable, Text, View } from 'react-native';
import { CalendarList as CalendarListBase } from 'react-native-calendars';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled, { css } from 'styled-components/native';
import { defaultTheme, mixins } from '~/theme';

import { Variant } from './ShoppingListCalendar.types';

interface DateNotSelectedTextProps {
    variant?: Variant;
}

export const Wrapper = styled(SafeAreaView)`
    flex: 1;
`;

export const WebWrapper = styled(View)`
    width: 428px;
    height: 650px;
    background-color: ${({ theme }) => theme.fill.background};
    border-radius: 12px;
    shadow-color: #000;
    shadow-offset: 0px 12px;
    shadow-opacity: 0.5;
    shadow-radius: 32px;
`;

export const HeaderWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.semiBold16};
    color: ${({ theme }) => theme.text.primary};
    text-align: center;
    flex: 1;
`;

export const Space = styled(View)`
    flex: 1;
`;

export const IconContainer = styled(View)`
    flex: 1;
`;

export const IconButton = styled(Pressable)``;

export const RangeWrapper = styled(View)`
    flex-direction: row;
    justify-content: center;
    padding-top: 4px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
`;

export const DateText = styled(Text)`
    ${mixins.typography.ui.regular16};
    color: ${({ theme }) => theme.text.primary};
    text-transform: capitalize;
`;

export const Dash = styled(Text)`
    ${mixins.typography.ui.semiBold16};
    color: ${({ theme }) => theme.text.tertiary};
    padding-left: 12px;
    padding-right: 12px;
`;

export const calendarTheme = {
    calendarBackground: defaultTheme.fill.level1,
    'stylesheet.calendar.header': {
        monthText: {
            color: defaultTheme.text.primary,
            fontWeight: '500',
            fontFamily: 'Basier-Medium',
            marginBottom: 18,
            fontSize: 16,
            lineHeight: 20,
            letterSpacing: -0.16,
        },
        header: {
            justifyContent: 'flex-start',
            marginTop: 20,
        },
    },
};

export const CalendarList = styled(CalendarListBase)`
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`;

export const MonthWrapper = styled(View)`
    flex-direction: row;
    justify-content: center;
    padding: 17px 16px 5px;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
    background-color: ${({ theme }) => theme.fill.level1};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;

export const MonthText = styled(Text)`
    ${mixins.typography.ui.regular12};
    text-align: center;
    flex: 1;
    color: ${({ theme }) => theme.text.tertiary};
`;

export const Container = styled(View)`
    flex: 1;

    ${({ theme }) =>
        !theme.isWeb &&
        css`
            margin-bottom: 42px;
        `}
`;

export const FooterContainer = styled(View)`
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const DateNotSelectedText = styled(Text)<DateNotSelectedTextProps>`
    ${mixins.typography.ui.regular16};
    color: ${({ theme, variant }) => (variant === 'highlighted' ? theme.text.interactive : theme.text.tertiary)};
`;
