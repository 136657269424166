import { interpolate, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface TimerButtonAnimationsRenderPropValue {
    playIconAnimatedStyles: {
        opacity: number;
    };
    stopIconAnimatedStyles: {
        opacity: number;
    };
}

export interface TimerButtonAnimationsProps extends AnimationsProps<TimerButtonAnimationsRenderPropValue> {
    started: boolean;
}

export const TimerButtonAnimations = ({ started, children }: TimerButtonAnimationsProps) => {
    const startedProgress = useDerivedValue(() => withSpring(Number(started), springConfig.default));

    const playIconAnimatedStyles = useAnimatedStyle(() => ({
        opacity: interpolate(startedProgress.value, [0, 1], [1, 0]),
    }));

    const stopIconAnimatedStyles = useAnimatedStyle(() => ({
        opacity: interpolate(startedProgress.value, [0, 1], [0, 1]),
    }));

    return children({
        playIconAnimatedStyles,
        stopIconAnimatedStyles,
    });
};
