import { View } from 'react-native';
import styled from 'styled-components/native';
import { MeasurementForm as MeasurementFormBase } from '~/screens/measurements/components/measurementForm';

export const Container = styled(View)`
    width: 600px;
    max-width: 100%;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const TitleContainer = styled(View)`
    padding: 8px 32px;
`;

export const MeasurementForm = styled(MeasurementFormBase)`
    flex: 100%;
    overflow: auto;
    padding: 0 16px 10px;
`;
