import { schema } from '~/constants/auth';
import yup from '~/utils/validation';

export const NewPasswordFormSchema = yup.object().shape({
    code: schema.verificationCode,
    password: schema.password,
    confirmPassword: schema.confirmPassword,
});

export const NewPasswordChallengeFormSchema = yup.object().shape({
    password: schema.password,
    confirmPassword: schema.confirmPassword,
});
