import { FC, useEffect, useRef } from 'react';
import { View } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { Easing, interpolate } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';

import { TrainingTimerType } from '../TrainingTimerModal.types';
import * as S from './TrainingTimerModalContent.styled';

interface TrainingTimerModalContentProps {
    type: TrainingTimerType;
    time: string;
    progress: number;
    duration: number;
    closeButtonLabel: string;
    onTimeIncrease: () => void;
    onTimeDecrease: () => void;
    onClose: () => void;
}

export const TrainingTimerModalContent: FC<TrainingTimerModalContentProps> = ({
    type,
    time,
    progress,
    duration,
    closeButtonLabel,
    onTimeIncrease,
    onTimeDecrease,
    onClose,
}) => {
    const theme = useTheme();
    const circularProgressRef = useRef<AnimatedCircularProgress>(null);
    const prevDuration = useRef(duration);

    useEffect(() => {
        if (progress === 0) {
            circularProgressRef.current?.animate(type === 'countdown' ? 100 : 0, 0, Easing.linear);
        } else {
            const progressValue = interpolate(progress, [0, duration], type === 'countdown' ? [100, 0] : [0, 100]);
            const progressDuration = duration !== prevDuration.current ? 100 : 1000;

            circularProgressRef.current?.animate(progressValue, progressDuration, Easing.linear);
        }

        prevDuration.current = duration;
    }, [progress, duration, type]);

    return (
        <View>
            <S.CircleProgressContainer>
                <AnimatedCircularProgress
                    ref={circularProgressRef}
                    size={200}
                    width={17}
                    fill={0}
                    duration={0}
                    lineCap="round"
                    tintColor={type === 'training' ? theme.border.positive : theme.text.interactive}
                    backgroundColor={theme.fill.level2}
                    rotation={0}
                >
                    {() => <S.ProgressText>{time}</S.ProgressText>}
                </AnimatedCircularProgress>
            </S.CircleProgressContainer>
            {type === 'rest' && (
                <S.FooterButtonsRow>
                    <S.FooterButton>
                        <Button size="m" variant="secondary" fullWidth onPress={onTimeIncrease}>
                            +10s
                        </Button>
                    </S.FooterButton>
                    <S.FooterButton last>
                        <Button size="m" variant="secondary" fullWidth onPress={onTimeDecrease}>
                            -10s
                        </Button>
                    </S.FooterButton>
                </S.FooterButtonsRow>
            )}
            <Button size="m" variant="secondary" fullWidth onPress={onClose}>
                {closeButtonLabel}
            </Button>
        </View>
    );
};
