import { useRoute } from '@react-navigation/native';
import React from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { MainLayout } from '~/layouts/web';
import { MeasurementsStackScreens } from '~/navigation/paths';
import { MeasurementScreenProps } from '~/screens/measurements/Measurement/MeasurementScreen.types';
import { MeasurementsComingSoon } from '~/screens/measurements/components/measurementsComingSoon';

import { MeasurementScreenContent } from './MeasurementScreen.native';

export const MeasurementScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();
    const route = useRoute<MeasurementScreenProps['route']>();
    useWebDesktopBlockedScreen(MeasurementsStackScreens.screens.Measurement);
    const title = route.params?.measurementId ? 'Edytuj Pomiar' : 'Dodaj Pomiar';

    if (!features.measurements) {
        return <MainLayout title={title} noBackground={!theme.isWebDesktop} content={<MeasurementsComingSoon />} />;
    }

    return <MainLayout title={title} fullWidth verticalSpacing={false} content={<MeasurementScreenContent />} />;
};
