import { useCallback } from 'react';
import getBasicDayStyle from 'react-native-calendars/src/calendar/day/basic/style';

import { calendarTheme } from '../Calendar.styled';
import * as S from './Day.styled';

const baseStyleSheet = getBasicDayStyle(calendarTheme);

export const Day = ({ ...props }) => {
    const { marking, date, state, onPress, accessibilityLabel } = props;
    const isToday = state === 'today' && !marking;
    const isDisabledByDefault = state === 'disabled';
    const isDisabled = isDisabledByDefault || !!marking?.disabled;
    const isSelected = marking?.selected;

    const handlePress = useCallback(() => {
        if (isDisabled) return null;
        onPress(date);
    }, [date, isDisabled, onPress]);

    return (
        <S.DayWrapper
            onPress={handlePress}
            disabled={isDisabled}
            accessibilityLabel={accessibilityLabel}
            style={baseStyleSheet.base}
        >
            <S.TodayWrapper isToday={isToday} selected={isSelected} style={baseStyleSheet.text}>
                <S.DayText isToday={isToday}>{date.day}</S.DayText>
            </S.TodayWrapper>
        </S.DayWrapper>
    );
};
