import { SectionListData, SectionListRenderItem } from 'react-native';
import { PortionCounter } from '~/components/portionCounter';
import { MealImage } from '~/screens/diet/components/mealImage';

import { CheckboxListItem } from '../checkboxListItem';
import * as S from './ShoppingListRecipes.styled';
import { ItemType, SectionType } from './ShoppingListRecipes.types';
import { ShoppingListAccordion } from './shoppingListAccordion';
import { data } from './tmpData';

export const ShoppingListRecipes = () => {
    const renderSectionHeader = ({ section }: { section: SectionListData<ItemType, SectionType> }) => (
        <S.HeaderWrapper>
            <S.HeaderContainer>
                <S.Thumbnail>
                    <MealImage source={section.imgUrl} />
                </S.Thumbnail>
                <S.Title>{section.title}</S.Title>
            </S.HeaderContainer>
            <S.CounterContainer>
                {/* TODO: https://app.clickup.com/t/8692twyjh */}
                <PortionCounter title="Ile porcji chcesz zrobić?" value={section.portions} onChange={console.log} />
            </S.CounterContainer>
        </S.HeaderWrapper>
    );

    const renderItem: SectionListRenderItem<ItemType, SectionType> = ({ item }) =>
        !item.checked ? (
            <CheckboxListItem
                title={item.productName}
                subtitle={item.details}
                checked={false}
                // TODO: https://app.clickup.com/t/8692twyjh
                onChange={console.log}
            />
        ) : null;

    const renderSectionFooter = ({ section }: { section: SectionListData<ItemType, SectionType> }) => (
        <ShoppingListAccordion items={section.data.filter(({ checked }) => checked)} total={section.data.length} />
    );

    return (
        <S.List
            sections={data}
            renderSectionHeader={renderSectionHeader}
            renderSectionFooter={renderSectionFooter}
            renderItem={renderItem}
            stickySectionHeadersEnabled={false}
        />
    );
};
