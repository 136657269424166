import React, { FC } from 'react';

import * as S from './ExerciseGroupHeader.styled';
import { ExerciseGroupHeaderProps } from './ExerciseGroupHeader.types';

export const ExerciseGroupHeader: FC<ExerciseGroupHeaderProps> = ({ title, size = 'normal' }) => {
    return (
        <S.Container>
            <S.Title size={size}>{title}</S.Title>
        </S.Container>
    );
};
