import React from 'react';
import { useTheme } from 'styled-components/native';
import { MobileMenuSheet } from '~/components/bottomSheet/mobileMenuSheet';
import { MobileMenuTile } from '~/components/mobileMenuTile';

import { ImagePickerSheetProps } from './ImagePickerSheet.types';

export const ImagePickerSheet: React.FC<ImagePickerSheetProps> = ({
    isOpen,
    onClose,
    onPhotoSelect,
    onCameraSelect,
}) => {
    const theme = useTheme();

    return (
        <MobileMenuSheet isOpen={isOpen} onClose={onClose}>
            <MobileMenuTile icon="photo-gallery-outline" iconColor={theme.icon.primary} onPress={() => onPhotoSelect()}>
                Dodaj z galerii
            </MobileMenuTile>
            <MobileMenuTile icon="camera" last onPress={() => onCameraSelect()}>
                Zrób zdjęcie
            </MobileMenuTile>
        </MobileMenuSheet>
    );
};
