import React from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { MainLayout } from '~/layouts/web';
import { DietStackScreens } from '~/navigation/paths';
import { DietComingSoon } from '~/screens/diet/components/dietComingSoon';
import { MealScreenContent } from '~/screens/diet/meal/MealScreen.native';

export const MealScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();
    useWebDesktopBlockedScreen(DietStackScreens.screens.Meal);

    if (!features.diet) {
        return <MainLayout title="Posiłek" noBackground={!theme.isWebDesktop} content={<DietComingSoon />} />;
    }

    return <MainLayout title="Posiłek" scrollable={false} fullWidth content={<MealScreenContent />} />;
};
