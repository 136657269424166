import { StyleSheet, View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.fill.level2};
    border: 1px solid ${({ theme }) => theme.border[12]};
    overflow: hidden;
`;

export const BaseStyle = StyleSheet.create({
    shadow: {
        shadowOpacity: 0.8,
        shadowRadius: 32,
        shadowOffset: {
            height: 12,
            width: 0,
        },
        shadowColor: 'black',
    },
});
