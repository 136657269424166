import { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { Popup } from '~/components/popup';
import { ActivityDayPopup } from '~/components/popup/activityDayPopup';
import { AddActivityPopup } from '~/components/popup/addActivityPopup';

import { Day as DayMobile } from './Day.native';
import * as S from './Day.styled';
import { getDayVariant } from './Day.utils';

export const Day = ({ ...props }) => {
    const { state, date, marking } = props;
    const [isActivityDayVisible, setIsActivityDayVisible] = useState(false);
    const [popupType, setPopupType] = useState<'add' | 'history'>('history');
    const theme = useTheme();

    const renderDayIcon = () => {
        if (state === 'today') {
            return <Icon name="smirking-face-3d" size={18} />;
        }
        if (marking?.activityType === 'rest') {
            return <Icon name="sleeping-face-3d" size={18} />;
        }
        if (marking?.activityType === 'single') {
            return <Icon name="flexed-biceps-3d" size={18} />;
        }
        if (marking?.activityType === 'multiple') {
            return <S.MultipleActivitiesText>+2</S.MultipleActivitiesText>;
        }
    };

    const handleOpenActivityDay = () => setIsActivityDayVisible(true);
    const handleCloseActivityDay = () => {
        setIsActivityDayVisible(false);
        setPopupType('history');
    };

    const trigger = (
        <S.DayWrapper onPress={handleOpenActivityDay} variant={getDayVariant(state, marking?.activityType)}>
            <S.DayText variant={getDayVariant(state, marking?.activityType)}>{date.day}</S.DayText>
            <S.IconWrapper variant={getDayVariant(state, marking?.activityType)}>{renderDayIcon()}</S.IconWrapper>
        </S.DayWrapper>
    );
    const renderPopup = () => {
        switch (popupType) {
            case 'add': {
                return <AddActivityPopup onClosePress={handleCloseActivityDay} date={date.dateString} />;
            }
            case 'history': {
                return (
                    <ActivityDayPopup
                        onClosePress={handleCloseActivityDay}
                        date={date.dateString}
                        changePopupType={setPopupType}
                    />
                );
            }
            default:
                return null;
        }
    };

    if (!theme.isWebDesktop) return <DayMobile {...props} />;

    return (
        <Popup
            isOpen={isActivityDayVisible}
            trigger={trigger}
            onClose={handleCloseActivityDay}
            position={['left top', 'left center', 'left bottom']}
        >
            {renderPopup()}
        </Popup>
    );
};
