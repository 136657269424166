import React, { FC, ReactNode } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Sidebar, SidebarProps } from '~/components/sidebar';
import { SlidingSidebarAnimations } from '~/layouts/web/mainLayout/components/slidingOutSidebar/SlidingOutSidebar.animated';

import * as S from './SlidingOutSidebar.styled';

export interface SlidingOutSidebarProps extends Omit<SidebarProps, 'children'> {
    content: ReactNode;
    visible: boolean;
    onClose?: () => void;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const SlidingOutSidebar: FC<SlidingOutSidebarProps> = ({ visible, content, onClose, ...props }) => {
    const theme = useTheme();

    return (
        <SlidingSidebarAnimations visible={visible}>
            {({ sidebarAnimatedStyles }) => (
                <>
                    {!theme.isWebLargeDesktop && visible && <S.OverlayBackground onPress={onClose} />}
                    <AnimatedContainer style={sidebarAnimatedStyles}>
                        <Sidebar {...props} onClose={onClose}>
                            {content}
                        </Sidebar>
                    </AnimatedContainer>
                </>
            )}
        </SlidingSidebarAnimations>
    );
};
