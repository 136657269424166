import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DateRangeButton } from './components/dateRangeButton';

export const Wrapper = styled(View)`
    align-items: center;
    width: 100%;
    height: 100%;

    ${({ theme }) =>
        !theme.media.desktop &&
        css`
            align-items: flex-start;
            margin-left: 20px;
        `}
`;

export const Container = styled(View)`
    width: 600px;
    height: 100%;
`;

export const ListContainer = styled(View)`
    background-color: ${({ theme }) => theme.fill.background};
    border-radius: 12px;
    border-color: ${({ theme }) => theme.border[12]};
    border-width: 1px;
    flex: 1;
    margin-top: 15px;
    margin-bottom: 30px;
    shadow-color: #000;
    shadow-offset: 0px 12px;
    shadow-opacity: 0.5;
    shadow-radius: 32px;
`;

export const FloatingButton = styled(DateRangeButton)`
    ${({ theme }) =>
        theme.media.desktop
            ? css`
                  position: absolute;
                  top: 0;
                  left: 20px;
              `
            : css`
                  margin-bottom: 36px;
              `}
`;
