import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 16px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyState = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
`;

export const ButtonContainer = styled(View)`
    margin-top: 16px;
`;
