import { ReactElement } from 'react';
import { LinearGradientProps } from 'react-native-linear-gradient';
import { interpolateColor, useAnimatedProps, useDerivedValue, withSpring } from 'react-native-reanimated';
import { springConfig } from '~/constants/animations';

import { TileGradientButtonVariant } from './TileGradientButton.types';

export interface TileGradientButtonAnimationsRenderPropValue {
    backgroundAnimatedProps: { colors?: LinearGradientProps['colors'] };
    borderAnimatedProps: { colors?: LinearGradientProps['colors'] };
}

export interface TileGradientButtonAnimationsProps {
    progress: number;
    variant: TileGradientButtonVariant;
    children: (value: TileGradientButtonAnimationsRenderPropValue) => ReactElement;
}

type GradientColorRecord = Record<string, LinearGradientProps['colors']>;
type GradientsMap = Record<TileGradientButtonVariant, GradientColorRecord>;

const fillGradientsMap: GradientsMap = {
    primary: {
        // [default, hovered, pressed]
        start: ['rgba(102, 102, 255, 0.7)', 'rgba(102, 102, 255, 0.85)', 'rgba(102, 102, 255, 0.95)'],
        end: ['rgba(102, 102, 255, 0.25)', 'rgba(102, 102, 255, 0.31)', 'rgba(102, 102, 255, 0.34)'],
    },
    default: {
        start: ['rgba(255, 255, 255, 0.2288)', 'rgba(255, 255, 255, 0.264)', 'rgba(255, 255, 255, 0.352)'],
        end: ['rgba(255, 255, 255, 0.0832)', 'rgba(255, 255, 255, 0.096)', 'rgba(255, 255, 255, 0.128)'],
    },
};

const borderGradientsMap: GradientsMap = {
    primary: {
        start: ['rgba(176, 176, 255, 0.24)', 'rgba(176, 176, 255, 0.4)', 'rgba(176, 176, 255, 0.95)'],
        end: ['rgba(159, 159, 255, 0.24)', 'rgba(159, 159, 255, 0.4)', 'rgba(159, 159, 255, 0.95)'],
    },
    default: {
        start: ['rgba(255, 255, 255, 0.11)', 'rgba(255, 255, 255, 0.18)', 'rgba(255, 255, 255, 0.28)'],
        end: ['rgba(255, 255, 255, 0.07)', 'rgba(255, 255, 255, 0.11)', 'rgba(255, 255, 255, 0.18)'],
    },
};

export const TileGradientButtonAnimation = ({ progress, variant, children }: TileGradientButtonAnimationsProps) => {
    const colorsProgress = useDerivedValue(() => withSpring(progress, springConfig.default));
    const steps = [0, 1, 2];

    const backgroundAnimatedProps = useAnimatedProps(() => ({
        colors: [
            interpolateColor(colorsProgress.value, steps, fillGradientsMap[variant].start),
            interpolateColor(colorsProgress.value, steps, fillGradientsMap[variant].end),
        ],
    }));
    const borderAnimatedProps = useAnimatedProps(() => ({
        colors: [
            interpolateColor(colorsProgress.value, steps, borderGradientsMap[variant].start),
            interpolateColor(colorsProgress.value, steps, borderGradientsMap[variant].end),
        ],
    }));

    return children({ backgroundAnimatedProps, borderAnimatedProps });
};
