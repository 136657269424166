import { Pressable, View } from 'react-native';
import styled from 'styled-components/native';
import { HoverableProps } from '~/types/hoverable';

interface RemoveButtonHoverableStyledProps extends HoverableProps {}

export const Container = styled(Pressable)<RemoveButtonHoverableStyledProps>`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background: ${({ theme }) => theme.fill.background};
    border-width: 1px;
    border-style: solid;
    shadow-color: #000000;
    shadow-opacity: 0.5;
    shadow-radius: 3px;
    shadow-offset: 0 1px;
`;

export const RemoveIconWrapper = styled(View)`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
