import React, { FC, useState } from 'react';
import { ImageSourcePropType } from 'react-native';
import { Loader } from '~/components/loader';

import * as S from './Instruction.styled';

interface InstructionProps {
    title: string;
    imageSrc: ImageSourcePropType;
}

export const Instruction: FC<InstructionProps> = ({ title, imageSrc }) => {
    const [loading, setLoading] = useState(true);

    return (
        <S.Container>
            <S.Title>{title}</S.Title>
            <S.ImageWrapper>
                {loading && (
                    <S.LoaderContainer>
                        <Loader />
                    </S.LoaderContainer>
                )}
                <S.InstructionImage source={imageSrc} onLoad={() => setLoading(false)} />
            </S.ImageWrapper>
        </S.Container>
    );
};
