import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { EmptyScreen } from '~/components/emptyScreen';
import { CellText, TableHeaders } from '~/components/table';
import { orderHistoryData } from './tmpData';
import {UnitSuffix} from "~/components/unitSuffix";

interface OrderHistory {
    id: string;
    date: string;
    cost: number;
    type: string;
}

export const useOrderHistoryTable = () => {
    // TODO: Get health tests from BE
    const theme = useTheme();

    const tableHeaders: TableHeaders<OrderHistory> = [
        {
            id: 'date',
            label: 'Data zakupu',
            alignment: 'left',
            width: theme.isWebDesktop ? 1 : 40,
            render: ({ date }) => <CellText>{date}</CellText>,
        },
        {
            id: 'cost',
            label: 'Koszt',
            alignment: 'center',
            width: theme.isWebDesktop ? 1 : 35,
            render: ({ cost }) => (
                <CellText>
                    <UnitSuffix unit="zł">{cost}</UnitSuffix>
                </CellText>
            ),
        },
        {
            id: 'type',
            label: 'Pakiet',
            alignment: 'left',
            width: theme.isWebDesktop ? 1 : 25,
            render: ({ type }) => <CellText>{type}</CellText>,
        },
    ];

    return {
        table: {
            headers: tableHeaders,
            horizontalScroll: false,
            data: orderHistoryData,
            emptyState: (
                <EmptyScreen
                    icon="order-history"
                    iconColor={theme.icon.tertiary}
                    title="Historia zamówień jest pusta"
                    titleColor={theme.text.secondary}
                    button={{
                        label: 'Zobacz ofertę',
                        variant: 'secondary',
                        onPress: () => {},
                    }}
                />
            ),
        },
        pagination: {
            page: 1,
            totalPages: 2,
            onPageChange: () => {},
        },
    };
};
