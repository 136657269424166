import React, { FC, ReactNode, useMemo } from 'react';
import { ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/loader';
import { SectionHeading } from '~/components/sectionHeading';
import { Toasts } from '~/components/toasts';
import { SEO, SEOProps } from '~/components/wrappers';
import {
    SlidingOutSidebar,
    SlidingOutSidebarProps,
} from '~/layouts/web/mainLayout/components/slidingOutSidebar/SlidingOutSidebar';

import { NavigationLayout } from '../navigationLayout';
import * as S from './MainLayout.styled';

export interface MainLayoutProps {
    title: string;
    seo?: Partial<SEOProps>;
    content: ReactNode;
    submenu?: ReactNode;
    sidebar?: SlidingOutSidebarProps;
    isLoading?: boolean;
    noBackground?: boolean;
    scrollable?: boolean;
    fullWidth?: boolean;
    verticalSpacing?: boolean;
    headerHidden?: boolean;
    style?: ViewStyle;
}

export const MainLayout: FC<MainLayoutProps> = ({
    title,
    content,
    sidebar,
    seo,
    isLoading,
    noBackground,
    fullWidth,
    scrollable = true,
    verticalSpacing = true,
    submenu,
    headerHidden,
    style,
}) => {
    const theme = useTheme();

    const shouldDisplaySidebar = useMemo(() => {
        if (theme.isWebLargeDesktop) {
            return true;
        }

        return sidebar?.visible || false;
    }, [theme.isWebLargeDesktop, sidebar?.visible]);

    const renderLayout = () => {
        if (theme.isWebDesktop) {
            return (
                <NavigationLayout>
                    <S.LayoutWrapper>
                        {submenu && (
                            <S.Submenu>
                                {!headerHidden && (
                                    <S.HeaderContainer>
                                        <SectionHeading variant="header" title={title} />
                                    </S.HeaderContainer>
                                )}
                                {submenu}
                            </S.Submenu>
                        )}
                        <S.Container noBackground={noBackground} withSubmenu={!!submenu}>
                            <S.Main>
                                {isLoading && (
                                    <S.LoaderWrapper>
                                        <Loader size="xl" color={theme.icon.interactive} />
                                    </S.LoaderWrapper>
                                )}
                                {!headerHidden && !submenu && (
                                    <S.HeaderContainer>
                                        <SectionHeading variant="header" title={title} />
                                    </S.HeaderContainer>
                                )}
                                <S.ContentContainer style={style}>{content}</S.ContentContainer>
                            </S.Main>
                            {sidebar && (
                                <SlidingOutSidebar
                                    visible={shouldDisplaySidebar}
                                    content={sidebar.content}
                                    title={sidebar.title}
                                    isLoading={sidebar.isLoading}
                                    onClose={theme.isWebLargeDesktop ? undefined : sidebar.onClose}
                                />
                            )}
                        </S.Container>
                    </S.LayoutWrapper>
                    <Toasts />
                </NavigationLayout>
            );
        }

        return (
            <S.Container noBackground={noBackground}>
                {isLoading && (
                    <S.LoaderWrapper>
                        <Loader size="xl" color={theme.icon.interactive} />
                    </S.LoaderWrapper>
                )}
                <S.ScrollContainer
                    scrollable={scrollable}
                    fullWidth={fullWidth}
                    noBackground={noBackground}
                    verticalSpacing={verticalSpacing}
                >
                    {content}
                </S.ScrollContainer>
                <Toasts />
            </S.Container>
        );
    };

    return (
        <>
            <SEO title={title} {...seo} />
            {renderLayout()}
        </>
    );
};
