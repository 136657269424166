import EventEmitter from 'eventemitter3';
import { EmitterEvent, EmitterEventPayload } from '~/types/emitter';

const eventEmitter = new EventEmitter();

type fnType<T extends EmitterEvent> = (payload: EmitterEventPayload<T>) => void;

const Emitter = {
    on: <T extends EmitterEvent>(event: T, fn: fnType<T>) => eventEmitter.on(event, fn),
    once: <T extends EmitterEvent>(event: T, fn: fnType<T>) => eventEmitter.once(event, fn),
    off: <T extends EmitterEvent>(event: T, fn: fnType<T>) => eventEmitter.off(event, fn),
    emit: <T extends EmitterEvent>(event: T, payload: EmitterEventPayload<T>) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);
export default Emitter;
