import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { Icon } from '~/components/icon';
import { LeftSubmenuItem } from '~/components/leftSubmenuItem';
import { useDiet } from '~/enhancers/dietContext';
import { DietNavigationProp } from '~/screens/diet/DietScreen.types';
import { DietPlanSelection } from '~/screens/diet/components/dietSubmenu/components/dietPlanSelection';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import { links } from './DietSubmenu.consts';
import * as S from './DietSubmenu.styled';

export const DietSubmenu = () => {
    const theme = useTheme();
    const route = useRoute();
    const navigation = useNavigation<DietNavigationProp>();
    const { isActivePlanInProgress, weeksTabs, selectedWeek, selectWeek, activePlan, plans, selectActivePlan } =
        useDiet();
    const isActiveRoute = route.name === 'Diet';

    const navigateToDiet = () => {
        // @ts-ignore TODO: How to type it?
        !isActiveRoute && navigation.navigate('Tab', { screen: 'Diet' });
    };

    const goToToday = () => {
        navigateToDiet();
        Emitter.emit(EmitterEvent.goToTodayDietPlan, null);
    };

    const setPreviousPlan = () => {
        if (!activePlan) return;

        const previousPlan = plans[plans.indexOf(activePlan) + 1];

        if (previousPlan) {
            selectActivePlan(previousPlan ? previousPlan.id : plans[plans.length - 1].id);
        }
    };

    return (
        <S.Container>
            <S.Content>
                {!isActivePlanInProgress && (
                    <>
                        {weeksTabs && (
                            <S.WeeksContainer>
                                {weeksTabs.map((week) => (
                                    <LeftSubmenuItem
                                        key={week.value}
                                        active={isActiveRoute && week.value === selectedWeek}
                                        onPress={() => {
                                            navigateToDiet();
                                            selectWeek(week.value);
                                        }}
                                    >
                                        {week.label}
                                    </LeftSubmenuItem>
                                ))}
                            </S.WeeksContainer>
                        )}
                        <S.NavigationContainer>
                            <S.NavigationTitle>Przydatne informacje</S.NavigationTitle>
                            {links.map((item) => {
                                const isActive = item.screen === route.name;
                                const onPress = () => {
                                    // @ts-ignore
                                    navigation.navigate('DietStack', { screen: item.screen });
                                };

                                return (
                                    <LeftSubmenuItem key={item.label} active={isActive} onPress={onPress}>
                                        {item.label}
                                    </LeftSubmenuItem>
                                );
                            })}
                        </S.NavigationContainer>
                    </>
                )}
            </S.Content>
            <S.ActionsContainer>
                {isActivePlanInProgress && (
                    <S.ActionWrapper>
                        <Button variant="secondary" size="s" fullWidth onPress={setPreviousPlan}>
                            Włącz poprzedni plan
                        </Button>
                    </S.ActionWrapper>
                )}

                <S.ActionWrapper last>
                    <Button
                        variant="secondary"
                        size="s"
                        disabled={isActivePlanInProgress}
                        fullWidth
                        onPress={goToToday}
                    >
                        <S.IconWrapper>
                            <Icon
                                name="calendar-today-outline"
                                size={16}
                                color={isActivePlanInProgress ? theme.icon.disabled : theme.icon.primary}
                            />
                        </S.IconWrapper>
                        Wróć do dzisiaj
                    </Button>
                </S.ActionWrapper>
            </S.ActionsContainer>
            <DietPlanSelection />
        </S.Container>
    );
};
