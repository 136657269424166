import { useMemo } from 'react';
import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface InputBorderAnimationRenderPropValue {
    borderColor: string;
}

export interface InputBorderAnimationProps extends AnimationsProps<InputBorderAnimationRenderPropValue> {
    focused: boolean;
    error?: boolean;
    disabled: boolean;
}

export const InputBorderAnimation = ({ focused, error, disabled, children }: InputBorderAnimationProps) => {
    const theme = useTheme();

    const borderColorProgress = useDerivedValue(() => withSpring(Number(focused), springConfig.default));

    const inputTheme = useMemo(() => {
        if (error) {
            return {
                borderColor: [theme.border.critical, theme.border.critical],
            };
        }

        if (disabled) {
            return {
                borderColor: [theme.border['12'], theme.border['12']],
            };
        }

        return {
            borderColor: [theme.border['12'], theme.border.interactive],
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, error]);

    const animatedStyle = useAnimatedStyle(
        () => ({
            borderColor: interpolateColor(borderColorProgress.value, [0, 1], inputTheme.borderColor),
        }),
        [error, disabled],
    );

    return children(animatedStyle);
};
