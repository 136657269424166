import { ScrollView, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { MEAL_IMAGE_ASPECT_RATIO } from '~/screens/diet/meal/MealScreen.constants';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 80vw;
    height: 90vh;
    max-width: 1200px;
    max-height: 836px;
    display: flex;
    flex-direction: row;
`;

export const LeftSide = styled(View)`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    background: ${({ theme }) => theme.fill.level1};
    overflow: hidden;
`;

export const RightSide = styled(View)`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    background: ${({ theme }) => theme.fill.background};
`;

export const MealImageWrapper = styled(View)`
    width: 100%;
    aspect-ratio: ${MEAL_IMAGE_ASPECT_RATIO};
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
    padding: 24px 32px;
`;

export const PortionContainer = styled(View)`
    padding: 8px 32px;
`;

export const TabsContainer = styled(View)`
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
    padding-top: 16px;
`;

export const TabsWrapper = styled(View)`
    padding: 0 32px;
`;

export const MacrosContainer = styled(View)`
    width: 100%;
    padding: 20px 32px;
    display: flex;
    flex-direction: row;
`;

export const MacroTileWrapper = styled(View)<{ last?: boolean }>`
    flex: 1;
    margin-right: 8px;

    ${({ last }) =>
        last &&
        css`
            margin-right: 0;
        `}
`;

export const RightSideInner = styled(View)`
    width: 100%;
    height: 100%;
    padding: 20px 32px 0;
`;

export const HeadingWrapper = styled(View)`
    padding-bottom: 12px;
`;

export const IngredientWrapper = styled(View)`
    width: 100%;
    padding: 12px 0;
`;

export const StepsContainer = styled(View)`
    width: 100%;
    padding-top: 40px;
    flex: 1;
`;

export const StepWrapper = styled(View)`
    width: 100%;
    padding: 12px 0;
`;

export const ScrollableSteps = styled(ScrollView)`
    padding-bottom: 16px;
`;

export const CenterContainer = styled(View)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
