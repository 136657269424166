import { useNavigation } from '@react-navigation/native';
import { differenceInDays } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { BottomSheetModal } from '~/components/bottomSheet';
import { ConfirmSheet } from '~/components/bottomSheet/confirmSheet';
import { Logo } from '~/components/logo';
import { MobileMenuTile } from '~/components/mobileMenuTile';
import { SEO } from '~/components/wrappers';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { useAuth } from '~/enhancers/auth.context';
import { useImagePicker } from '~/enhancers/imagePicker';
import { useUser } from '~/enhancers/user.context';
import { useApplication } from '~/hooks/useApplication';
import { MoreLayout } from '~/layouts/moreLayout';

import * as S from './MoreScreen.styled';
import { MoreNavigationProp } from './MoreScreen.types';
import { Profile } from './components/profile';

export const MoreScreen = () => {
    const { features, canShowComingSoon } = useAppFeatures();
    const inset = useSafeAreaInsets();
    const auth = useAuth();
    const theme = useTheme();
    const { chooseImage } = useImagePicker();
    const { user, username, avatar, activeSubscription, setAvatar } = useUser();
    const navigation = useNavigation<MoreNavigationProp>();
    const [avatarPath, setAvatarPath] = useState(avatar ?? undefined);
    const errorSheetRef = useRef<BottomSheetModal>(null);
    const logOutConfirmSheetRef = useRef<BottomSheetModal>(null);
    const application = useApplication();

    useEffect(() => {
        setAvatarPath(avatar ?? undefined);
    }, [avatar]);

    const handleLogout = () => {
        logOutConfirmSheetRef.current?.close();
        auth.signOut();
    };

    const handleAvatarChoose = async () => {
        try {
            const { uri, mime } = await chooseImage({ width: 300, height: 300 });
            setAvatarPath(uri);

            await setAvatar(uri, mime, Platform.OS === 'web' ? 'base64' : 'uri');
        } catch (e) {
            console.log(e);
            errorSheetRef.current?.present();
        }
    };

    const renderMenu = () => {
        if (theme.isWebDesktop) return null;

        return (
            <>
                <S.Menu>
                    <S.MenuHeader>
                        <S.MenuHeaderText>Pozostałe funkcje</S.MenuHeaderText>
                    </S.MenuHeader>
                    <S.MenuList>
                        {(features.shoppingList || canShowComingSoon) && (
                            <MobileMenuTile
                                icon="shopping-list-outline"
                                onPress={() => navigation.navigate('MoreStack', { screen: 'ShoppingList' })}
                            >
                                Lista zakupów
                            </MobileMenuTile>
                        )}

                        {(features.supplements || canShowComingSoon) && (
                            <MobileMenuTile
                                icon="pharmacy-outline"
                                onPress={() => navigation.navigate('MoreStack', { screen: 'Supplements' })}
                            >
                                Suplementy
                            </MobileMenuTile>
                        )}

                        {(features.exercisesAtlas || canShowComingSoon) && (
                            <MobileMenuTile
                                icon="training-atlas-outline"
                                onPress={() =>
                                    navigation.navigate('MoreStack', {
                                        screen: 'ExercisesAtlasStack',
                                        params: { screen: 'ExercisesAtlas' },
                                    })
                                }
                            >
                                Atlas ćwiczeń
                            </MobileMenuTile>
                        )}
                        {(features.healthTests || canShowComingSoon) && (
                            <MobileMenuTile
                                icon="medical-examination-outline"
                                onPress={() => navigation.navigate('MoreStack', { screen: 'HealthTests' })}
                            >
                                Badania
                            </MobileMenuTile>
                        )}
                        <MobileMenuTile
                            icon="settings"
                            last
                            onPress={() => navigation.navigate('MoreStack', { screen: 'Settings' })}
                        >
                            Ustawienia aplikacji
                        </MobileMenuTile>
                    </S.MenuList>
                </S.Menu>

                <S.Menu>
                    <S.MenuHeader>
                        <S.MenuHeaderText>Konto</S.MenuHeaderText>
                    </S.MenuHeader>
                    <S.MenuList>
                        {(features.ordersHistory || canShowComingSoon) && (
                            <MobileMenuTile
                                icon="order-history"
                                onPress={() => navigation.navigate('MoreStack', { screen: 'OrdersHistory' })}
                            >
                                Historia zamówień
                            </MobileMenuTile>
                        )}
                        <MobileMenuTile
                            icon="lock"
                            onPress={() => navigation.navigate('MoreStack', { screen: 'AccountSettings' })}
                        >
                            Ustawienia konta
                        </MobileMenuTile>
                        <MobileMenuTile
                            icon="bug"
                            onPress={() => navigation.navigate('MoreStack', { screen: 'Report' })}
                        >
                            Zgłoś błąd lub sugestię
                        </MobileMenuTile>
                        <MobileMenuTile last icon="log-out" onPress={() => logOutConfirmSheetRef.current?.present()}>
                            Wyloguj
                        </MobileMenuTile>
                    </S.MenuList>
                </S.Menu>
            </>
        );
    };

    return (
        <>
            <SEO title="Więcej" />
            <S.MainLayout inset={inset}>
                <MoreLayout>
                    <S.Container>
                        {!theme.isWebDesktop && (
                            <S.LogoContainer>
                                <Logo />
                            </S.LogoContainer>
                        )}

                        <S.ScrollContainer>
                            <Profile
                                avatarUri={avatarPath}
                                name={username || ''}
                                email={user?.email || ''}
                                subscription={{
                                    status: activeSubscription ? 'active' : 'inactive',
                                    daysLeft: activeSubscription?.endDate
                                        ? differenceInDays(new Date(activeSubscription.endDate), new Date())
                                        : undefined,
                                }}
                                onAvatarChoose={handleAvatarChoose}
                            />

                            {renderMenu()}

                            {!theme.isWebDesktop && (
                                <S.AppInfoContainer>
                                    <S.AppInfo>
                                        {application.name} {application.version}
                                    </S.AppInfo>
                                </S.AppInfoContainer>
                            )}

                            <ConfirmSheet
                                ref={logOutConfirmSheetRef}
                                title="Czy na pewno chcesz się wylogować?"
                                icon="log-out"
                                primaryButtonLabel="Tak, wyloguj mnie"
                                secondaryButtonLabel="Nie"
                                onConfirm={handleLogout}
                                onClose={() => logOutConfirmSheetRef.current?.close()}
                            />

                            <ConfirmSheet
                                ref={errorSheetRef}
                                title="Nastąpił nieoczekiwany błąd"
                                subtitle="Spróbuj ponownie za chwilę."
                                variant="error"
                                icon="warning-stop"
                                onConfirm={() => errorSheetRef.current?.close()}
                            />
                        </S.ScrollContainer>
                    </S.Container>
                </MoreLayout>
            </S.MainLayout>
        </>
    );
};
