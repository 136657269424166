import React from 'react';
import { Modal, ModalProps, TouchableWithoutFeedback } from 'react-native';
import { useTheme } from 'styled-components/native';
import { usePublicStorageAsset } from '~/api/assets/useStorageAsset';
import { Icon } from '~/components/icon';

import * as S from './VideoModal.styled';

export interface VideoModalProps extends Omit<ModalProps, 'onRequestClose'> {
    videoUrl?: string;
    onRequestClose?: () => void;
}

export const VideoModal: React.FC<VideoModalProps> = ({ videoUrl, onRequestClose, ...props }) => {
    const theme = useTheme();
    const url = usePublicStorageAsset(videoUrl);

    const handleModalClose = () => {
        onRequestClose?.();
    };

    if (!theme.isWebDesktop) {
        return (
            <Modal animationType="slide" presentationStyle="fullScreen" {...props}>
                <S.Container>
                    <video src={url!} controls width="100%" height="auto" autoPlay />
                </S.Container>
                <S.Footer onPress={handleModalClose}>
                    <Icon name="close" size={40} color={theme.icon.tertiary} />
                </S.Footer>
            </Modal>
        );
    }

    return (
        <TouchableWithoutFeedback onPress={handleModalClose}>
            <Modal animationType="fade" presentationStyle="fullScreen" {...props} transparent>
                <S.Container>
                    <S.Wrapper>
                        <S.WebCloseButton icon="close" size="m" onPress={handleModalClose} />

                        <S.VideoContainer>
                            <video src={url!} controls width="100%" height="auto" autoPlay />
                        </S.VideoContainer>
                    </S.Wrapper>
                </S.Container>
            </Modal>
        </TouchableWithoutFeedback>
    );
};
