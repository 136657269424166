import React, { ReactNode } from 'react';

import { CellStyledProps } from '../../Table.types';
import { CellText } from '../cellText';

export interface CellContentProps extends CellStyledProps {
    content: ReactNode;
    hidden?: boolean;
}

export const CellContent = ({ hidden, content, alignment, head }: CellContentProps) => {
    if (hidden) return null;

    if (!content || typeof content === 'string') {
        return (
            <CellText head={head} alignment={alignment}>
                {content || ''}
            </CellText>
        );
    }

    return <>{content}</>;
};
