import { BottomSheetBackdrop, BottomSheetBackdropProps, BottomSheetModal } from '@gorhom/bottom-sheet';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts';
import { MainLayout as MainLayoutNative } from '~/layouts/native';

import { ShoppingListCalendar } from './components/shoppingListCalendar';
import { ShoppingListCategories } from './components/shoppingListCategories';
import { ShoppingListComingSoon } from './components/shoppingListComingSoon';
import { ShoppingListFooter } from './components/shoppingListFooter';
import { ShoppingListRecipes } from './components/shoppingListRecipes';
import { useShoppingListState } from './useShoppingListState';

export const ShoppingListScreen: FC = () => {
    const { features } = useAppFeatures();
    const { range, isOpen, handleOnChange, handleOpen, screen, handleClose } = useShoppingListState();
    const bottomSheetModalRef = useRef<BottomSheetModal>(null);
    const theme = useTheme();
    const renderBackdrop = useCallback(
        (props: BottomSheetBackdropProps) => (
            <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} opacity={1} />
        ),
        [],
    );

    useEffect(() => {
        isOpen ? bottomSheetModalRef.current?.present() : bottomSheetModalRef.current?.close();
    }, [isOpen]);

    if (!features.shoppingList) {
        return (
            <MainLayoutNative noBackground fullWidth>
                <ShoppingListComingSoon />
            </MainLayoutNative>
        );
    }

    return (
        // TODO: Switch to native main layout
        <MainLayout bottomContent={<ShoppingListFooter range={range} onEdit={handleOpen} />}>
            {screen === 'categories' && <ShoppingListCategories />}
            {screen === 'recipes' && <ShoppingListRecipes />}
            <BottomSheetModal
                ref={bottomSheetModalRef}
                index={0}
                snapPoints={['100%']}
                handleIndicatorStyle={{ backgroundColor: theme.border[20] }}
                backgroundStyle={{ backgroundColor: theme.fill.background }}
                handleComponent={null}
                onDismiss={handleClose}
                backdropComponent={renderBackdrop}
                enableContentPanningGesture={false}
            >
                <ShoppingListCalendar range={range} onDismiss={handleClose} onChange={handleOnChange} />
            </BottomSheetModal>
        </MainLayout>
    );
};
