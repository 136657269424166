import { useTheme } from 'styled-components/native';
import { Popup } from '~/components/popup';
import { ShoppingTabs } from '~/components/shoppingTabs';
import { MainLayout } from '~/layouts/web';

import * as S from './ShoppingListScreen.styled';
import { ShoppingListCalendar } from './components/shoppingListCalendar';
import { ShoppingListCategories } from './components/shoppingListCategories';
import { ShoppingListRecipes } from './components/shoppingListRecipes';
import { useShoppingListState } from './useShoppingListState';

export const ShoppingListScreenDesktop = () => {
    const { range, isOpen, handleOnChange, handleOpen, screen, handleClose } = useShoppingListState();
    const theme = useTheme();

    return (
        <>
            <MainLayout
                title="Lista zakupów"
                scrollable={false}
                noBackground={!theme.isWebDesktop}
                content={
                    <S.Wrapper>
                        <Popup
                            isOpen={isOpen}
                            onClose={handleClose}
                            trigger={<S.FloatingButton active={isOpen} range={range} onPress={handleOpen} />}
                            position="bottom left"
                            offsetY={4}
                        >
                            <ShoppingListCalendar range={range} onDismiss={handleClose} onChange={handleOnChange} />
                        </Popup>
                        <S.Container>
                            <ShoppingTabs />
                            <S.ListContainer>
                                {screen === 'categories' && <ShoppingListCategories />}
                                {screen === 'recipes' && <ShoppingListRecipes />}
                            </S.ListContainer>
                        </S.Container>
                    </S.Wrapper>
                }
            />
        </>
    );
};
