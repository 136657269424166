import { User } from '@pg/backend';
import { QueryFunction, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const fetchUser: QueryFunction<User | null, ['getUser']> = async ({ queryKey }) => {
    const [_] = queryKey;

    try {
        return (await DataStore.query(User))?.[0] ?? null;
    } catch (e) {
        console.log('error', e);
        return null;
    }
};

export const useGetUser = (options?: UseQueryOptions<User | null, unknown, User | null, ['getUser']>) =>
    useQuery(['getUser'], fetchUser, options);
