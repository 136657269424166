import { Training } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchTrainings: QueryFunction<Training[], ['getTrainings']> = async () => {
    try {
        return await DataStore.query(Training, null, {
            sort: (s) => s.createdAt(SortDirection.DESCENDING),
        });
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const useGetTrainings = () =>
    useQuery(['getTrainings'], fetchTrainings, {
        cacheTime: 0,
    });
