import { Image, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 20px;
    height: 100%;
    justify-content: space-between;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            padding: 100px 32px 0;
        `}
`;

export const Content = styled(View)`
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: center;
`;

export const Title = styled(View)`
    margin-bottom: 24px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-bottom: 48px;
            max-width: 720px;
        `}
`;

export const TitleText = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
    text-align: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.h.semiBold32};
        `}
`;

export const Buttons = styled(View)`
    display: flex;
    margin-bottom: 24px;
    align-items: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            flex-direction: row;
            margin-bottom: 48px;
        `}
`;

export const Footer = styled(View)`
    width: 100%;
    aspect-ratio: 768 / 505;
    position: relative;
    align-self: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            max-width: 720px;
        `}
`;

export const FooterImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
