import { SectionListData, SectionListRenderItem } from 'react-native';

import { CheckboxListItem } from '../checkboxListItem';
import * as S from './ShoppingListCategories.styled';
import { ItemType, SectionType } from './ShoppingListCategories.types';
import { ShoppingListAccordion } from './shoppingListAccordion';
import { data } from './tmpData';

export const ShoppingListCategories = () => {
    const renderSectionHeader = ({ section }: { section: SectionListData<ItemType, SectionType> }) => (
        <S.SectionHeader>{section.title}</S.SectionHeader>
    );

    const renderItem: SectionListRenderItem<ItemType, SectionType> = ({ item }) =>
        !item.checked ? (
            <CheckboxListItem
                title={item.productName}
                subtitle={item.details}
                checked={false}
                // TODO: https://app.clickup.com/t/8692twyjh
                onChange={console.log}
            />
        ) : null;

    const renderSectionFooter = ({ section }: { section: SectionListData<ItemType, SectionType> }) => (
        <ShoppingListAccordion items={section.data.filter(({ checked }) => checked)} total={section.data.length} />
    );

    return (
        <S.List
            sections={data}
            renderSectionHeader={renderSectionHeader}
            renderSectionFooter={renderSectionFooter}
            renderItem={renderItem}
            stickySectionHeadersEnabled={false}
        />
    );
};
