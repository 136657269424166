import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { EmptyScreen } from '~/components/emptyScreen';
import { CellText, TableHeaders } from '~/components/table';
import { useHealthTests } from '~/enhancers/healthTests.context';
import { IconName } from '~/icons';
import * as S from '~/screens/healthTests/HealthTestsScreen.native.styled';

interface HealthTest {
    id: string;
    name: string;
}

export const useHealthTestsTable = () => {
    // TODO: Get health tests from BE
    const theme = useTheme();
    const [hoveredItemId, setHoveredItemId] = useState<string | undefined>(undefined);
    const { healthTests, removeHealthTest } = useHealthTests();
    const [healthTestIdToDelete, setHealthTestIdToDelete] = useState<string | undefined>(undefined);

    const tableHeaders: TableHeaders<HealthTest> = [
        {
            id: 'name',
            label: 'Plik',
            alignment: 'left',
            width: theme.isWebDesktop ? 90 : 70,
            render: ({ name }) => <CellText>{name}</CellText>,
        },
        {
            id: 'actions',
            alignment: 'right',
            width: theme.isWebDesktop ? 10 : 30,
            render: ({ id }) => (
                <S.ActionsContainer>
                    <S.ActionWrapper>
                        <Button
                            variant="secondary"
                            size="xs"
                            icon="download"
                            onPress={() => {}}
                            onHoverIn={() => setHoveredItemId(id)}
                            onHoverOut={() => setHoveredItemId(undefined)}
                        />
                    </S.ActionWrapper>
                    <S.ActionWrapper>
                        <Button
                            variant="secondary"
                            size="xs"
                            icon="trash-outline"
                            onPress={() => setHealthTestIdToDelete(id)}
                            onHoverIn={() => setHoveredItemId(id)}
                            onHoverOut={() => setHoveredItemId(undefined)}
                        />
                    </S.ActionWrapper>
                </S.ActionsContainer>
            ),
        },
    ];

    const getSubtitle = () => {
        const name = healthTests.find((test) => test.id === healthTestIdToDelete)?.name;

        return `Plik ${name} zostanie usunięty.`;
    };

    return {
        table: {
            headers: tableHeaders,
            hiddenHeaders: !theme.isWebDesktop,
            horizontalScroll: false,
            data: healthTests,
            hoveredItemId,
            emptyState: (
                <EmptyScreen
                    icon="medical-examination-outline"
                    iconColor={theme.icon.tertiary}
                    title="Brak dodanych badań"
                    titleColor={theme.text.secondary}
                    maxContentWidth={400}
                    description={`Aby zmaksymalizować dobór planu i rozwiązanie Twoich problemów dołącz badania jakie posiadasz lub zapytaj mnie jakie należy wykonać w Twoim wypadku. \n\nPliki .pdf, .jpg, .png. Maksymalnie 5MB.`}
                    button={{
                        label: 'Dodaj nowy plik',
                        variant: 'secondary',
                        icon: 'add',
                        onPress: () => {},
                    }}
                />
            ),
        },
        pagination:
            healthTests.length > 10
                ? {
                      page: 1,
                      totalPages: 2,
                      onPageChange: () => {},
                  }
                : undefined,
        healthTestIdToDelete,
        removalConfirmSheet: {
            icon: 'trash-outline' as IconName,
            title: 'Czy na pewno chcesz usunąć badanie?',
            subtitle: getSubtitle(),
            primaryButtonLabel: 'Tak, usuń',
            secondaryButtonLabel: 'Nie',
            onConfirm: () => {
                removeHealthTest(healthTestIdToDelete!);
                setHealthTestIdToDelete(undefined);
            },
            onClose: () => setHealthTestIdToDelete(undefined),
        },
    };
};
