import Constants from 'expo-constants';
import React, { FC, ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import { Platform } from 'react-native';

type Feature =
    | 'activity'
    | 'measurements'
    | 'subscriptions'
    | 'chat'
    | 'diet'
    | 'shoppingList'
    | 'exercisesAtlas'
    | 'ordersHistory'
    | 'supplements'
    | 'healthTests';

export type AppFeatures<F extends string = string> = Record<F, boolean>;

export interface AppFeaturesContextType {
    features: AppFeatures<Feature>;
    canShowComingSoon: boolean;
}

export const AppFeaturesContext = createContext<AppFeaturesContextType>({
    features: {
        activity: false,
        measurements: false,
        subscriptions: false,
        chat: false,
        diet: false,
        shoppingList: false,
        exercisesAtlas: false,
        ordersHistory: false,
        supplements: false,
        healthTests: false,
    },
    canShowComingSoon: true,
});

export interface AppFeaturesContextProviderProps {
    children: ReactNode;
}

export const AppFeaturesContextProvider: FC<AppFeaturesContextProviderProps> = ({ children }) => {
    const getFeatureConstant = useCallback(
        (constantName: string) => Constants.expoConfig?.extra?.[constantName] === 'true',
        [],
    );

    const value = useMemo(
        () => ({
            features: {
                activity: getFeatureConstant('featureActivity'),
                measurements: getFeatureConstant('featureMeasurements'),
                subscriptions: getFeatureConstant('featureSubscriptions'),
                chat: getFeatureConstant('featureChat'),
                diet: getFeatureConstant('featureDiet'),
                shoppingList: getFeatureConstant('featureShoppingList'),
                exercisesAtlas: getFeatureConstant('featureExercisesAtlas'),
                ordersHistory: getFeatureConstant('featureOrdersHistory'),
                supplements: getFeatureConstant('featureSupplements'),
                healthTests: getFeatureConstant('featureHealthTests'),
            },
            canShowComingSoon: Platform.OS === 'web',
        }),
        [getFeatureConstant],
    );

    return <AppFeaturesContext.Provider value={value}>{children}</AppFeaturesContext.Provider>;
};

export const useAppFeatures = () => useContext(AppFeaturesContext);
