import { Text, View } from 'react-native';
import { CalendarList as CalendarListBase } from 'react-native-calendars';
import styled from 'styled-components/native';
import { defaultTheme, mixins } from '~/theme';

export const calendarTheme = {
    calendarBackground: defaultTheme.fill.background,
    width: '100%',

    'stylesheet.calendar.header': {
        monthText: {
            color: defaultTheme.text.primary,
            fontWeight: '500',
            fontFamily: 'Basier-Medium',
            marginBottom: 18,
            fontSize: 16,
            lineHeight: 20,
            letterSpacing: -0.16,
        },
        header: {
            justifyContent: 'flex-start',
            marginTop: 20,
        },
    },
};

export const CalendarList = styled(CalendarListBase)`
    border-radius: 20px;
`;

export const WeekWrapper = styled(View)`
    flex-direction: row;
    justify-content: center;
    padding: 0 16px 5px;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
`;

export const WeekName = styled(Text)`
    ${mixins.typography.ui.regular12};
    text-align: center;
    flex: 1;
    color: ${({ theme }) => theme.text.tertiary};
`;

export const Container = styled(View)`
    flex: 1;
    margin-bottom: 8px;
`;

export const CalendarTitle = styled(Text)`
    ${mixins.typography.h.semiBold24}
    color: ${({ theme }) => theme.text.primary};
    margin-left: 16px;
    margin-top: 16px;
`;

export const TodayContainer = styled(View)`
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`;
