import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type UseTimerProps = {
    initialDuration: number;
    isActive: boolean;
    onProgressChange?: (progress: number) => void;
    onFinish?: (progress: number) => void;
};

export const useTimer = ({ initialDuration, isActive, onProgressChange, onFinish }: UseTimerProps) => {
    const [initialTime, setInitialTime] = useState(new Date());
    const [duration, setDuration] = useState(initialDuration);
    const [progress, setProgress] = useState(0);
    const timerIntervalRef = useRef<ReturnType<typeof setInterval>>();

    const stopTimer = useCallback(() => {
        clearInterval(timerIntervalRef.current);
    }, []);

    const resetTimer = useCallback(() => {
        setProgress(0);
        setInitialTime(new Date());
        setDuration(initialDuration);
    }, [initialDuration]);

    const increaseDuration = useCallback((time: number) => {
        setDuration((currentDuration) => currentDuration + time);
    }, []);

    const decreaseDuration = useCallback(
        (time: number) => {
            setDuration((currentDuration) => currentDuration - Math.min(time, currentDuration - progress));
        },
        [progress],
    );

    const time = useMemo(() => {
        const seconds = duration - progress;
        const dateString = new Date(seconds * 1000).toISOString();

        if (seconds < 3600) {
            return dateString.substring(14, 19);
        }

        return dateString.substring(11, 16);
    }, [duration, progress]);

    useEffect(() => {
        timerIntervalRef.current = setInterval(() => {
            const currentDate = new Date();
            const currentProgress = Math.round((currentDate.getTime() - initialTime.getTime()) / 1000);
            if (isActive && currentProgress <= duration) {
                setProgress(currentProgress);
            }
        }, 100);

        return () => clearInterval(timerIntervalRef.current);
    }, [isActive, duration, initialTime]);

    useEffect(() => {
        if (isActive) {
            onProgressChange?.(progress);
        }

        // NOTE: we don't want to fire onProgressChange() when onProgressChange changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress, isActive]);

    useEffect(() => {
        if (progress === duration && progress > 0 && isActive) {
            onFinish?.(progress);
            stopTimer();
        }

        // NOTE: we don't want to fire onFinish() when onFinish changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress, duration, isActive]);

    useEffect(() => {
        if (isActive) {
            resetTimer();
        }
    }, [isActive, resetTimer]);

    return {
        time,
        progress,
        duration,
        stopTimer,
        resetTimer,
        increaseDuration,
        decreaseDuration,
    };
};
