import React, { forwardRef } from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';

import { ActivityButtonAnimation } from './ActivityButton.animated';
import * as S from './ActivityButton.styled';

export interface ActivityButtonProps extends Omit<PressableProps, 'onPressIn' | 'onPressOut'> {
    icon: IconName;
    onHoverIn?: () => void;
    onHoverOut?: () => void;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const ActivityButton = forwardRef<any, ActivityButtonProps>(
    ({ icon, disabled = false, onHoverIn, onHoverOut, ...props }, ref) => {
        const { pressableProps, pressableAnimationProgress } = usePressableState();
        const theme = useTheme();

        return (
            <ActivityButtonAnimation progress={pressableAnimationProgress} disabled={disabled}>
                {(animatedStyle) => (
                    <AnimatedContainer
                        {...props}
                        {...pressableProps}
                        disabled={disabled}
                        style={[props.style, animatedStyle]}
                        ref={ref}
                        onHoverIn={() => {
                            onHoverIn?.();
                            pressableProps.onHoverIn();
                        }}
                        onHoverOut={() => {
                            onHoverOut?.();
                            pressableProps.onHoverOut();
                        }}
                    >
                        <S.IconWrapper>
                            <Icon name={icon} size={16} color={disabled ? theme.icon.disabled : theme.icon.primary} />
                        </S.IconWrapper>
                    </AnimatedContainer>
                )}
            </ActivityButtonAnimation>
        );
    },
);
