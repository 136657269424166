// @ts-nocheck
import React, { useRef, useState } from 'react';
import { FlatList, useWindowDimensions } from 'react-native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { EmptyScreen } from '~/components/emptyScreen';
import { Loader } from '~/components/loader';
import { TableAnimations, TableAnimationsRenderPropValue } from '~/components/table/Table.animated';
import { getCellPropsFromHeader, getStickyCellWidth } from '~/components/table/utils';

import * as S from './Table.styled';
import { DataItem, TableData, TableProps } from './Table.types';
import { Cell } from './components/cell';

const AnimatedHorizontalScroll = Animated.createAnimatedComponent(S.HorizontalScroll);
const AnimatedCenteredContainer = Animated.createAnimatedComponent(S.CenteredContainer);

export const Table = ({
    headers,
    hiddenHeaders,
    data,
    footer,
    loading,
    lastColumnSticky = false,
    horizontalScroll = true,
    backgroundColor,
    emptyState,
    onEndReached,
    onEndReachedThreshold = 0.5,
    hoveredItemId,
    ...props
}: TableProps) => {
    const theme = useTheme();
    const [hoveredRowIndex, setHoveredRowIndex] = useState<null | number>(null);
    const { width: screenWidth } = useWindowDimensions();
    const { bottom } = useSafeAreaInsets();
    const stylesRef = useRef<TableAnimationsRenderPropValue['styles'] | null>(null);
    const isLastColumnSticky = theme.isWebDesktop ? false : lastColumnSticky;
    const stickyCellWidth = isLastColumnSticky
        ? getStickyCellWidth(headers[headers.length - 1], screenWidth)
        : undefined;

    const renderHeaders = (hidden?: boolean) => (
        <S.Row head hidden={hidden} backgroundColor={backgroundColor}>
            {headers.map((header, cellIndex) => {
                const animated = isLastColumnSticky && cellIndex === headers.length - 1 && !!header.label;

                return (
                    <Cell
                        key={header.id}
                        head
                        animatedStyles={animated ? stylesRef.current?.stickyCellAnimatedStyles : undefined}
                        content={header.label}
                        first={cellIndex === 0}
                        last={cellIndex === headers.length - 1}
                        backgroundColor={backgroundColor}
                        {...getCellPropsFromHeader(header, screenWidth, theme.isWebDesktop)}
                    />
                );
            })}
            {!theme.isWebDesktop && backgroundColor && (
                <S.HeadersShadow colors={[backgroundColor, `${backgroundColor}00`]} useAngle angle={180} />
            )}
        </S.Row>
    );

    const renderDataItem = ({ item, index: rowIndex }: DataItem) => {
        if (item.type === 'header') {
            return renderHeaders(hiddenHeaders);
        }

        if (item.type === 'insetSpacing') {
            return <S.InsetSpacing bottomInset={bottom} />;
        }

        if (item.id === hoveredItemId) {
            setHoveredRowIndex(rowIndex);
        }

        return (
            <>
                <S.Row
                    key={`row-${rowIndex}`}
                    onHoverIn={() => setHoveredRowIndex(rowIndex)}
                    onHoverOut={() => setHoveredRowIndex(null)}
                >
                    {theme.isWebDesktop && (hoveredRowIndex === rowIndex || rowIndex % 2 === 0) && (
                        <S.RowBackground hovered={hoveredRowIndex === rowIndex} />
                    )}
                    {headers.map((header, cellIndex) => {
                        const animated = isLastColumnSticky && cellIndex === headers.length - 1;

                        return (
                            <Cell
                                key={`${rowIndex}${cellIndex}`}
                                animatedStyles={animated ? stylesRef.current?.stickyCellAnimatedStyles : undefined}
                                content={header.render(item)}
                                first={cellIndex === 0}
                                last={cellIndex === headers.length - 1}
                                backgroundColor={backgroundColor}
                                {...getCellPropsFromHeader(header, screenWidth, theme.isWebDesktop)}
                            />
                        );
                    })}
                </S.Row>
                <S.Separator />
            </>
        );
    };

    const renderEmptyState = () => {
        if (emptyState && typeof emptyState !== 'string') {
            return emptyState;
        }

        return (
            <S.EmptyScreenContainer>
                <EmptyScreen icon="table" iconColor={theme.icon.disabled} title="Brak danych" />
            </S.EmptyScreenContainer>
        );
    };

    const renderLoader = () => (
        <S.LoaderContainer bottomInset={theme.isWebDesktop ? bottom : undefined}>
            <Loader size={theme.isWebDesktop ? 'xl' : 'm'} />
        </S.LoaderContainer>
    );

    const renderCommonFooter = () => {
        if (!loading) {
            return null;
        }

        return horizontalScroll ? (
            <AnimatedCenteredContainer style={stylesRef.current?.stateContainerStyles} width={screenWidth}>
                {renderLoader()}
            </AnimatedCenteredContainer>
        ) : (
            <>{renderLoader()}</>
        );
    };

    const renderFooter = () => {
        return (
            <>
                {renderCommonFooter()}
                {footer}
            </>
        );
    };

    const renderList = () => {
        const listProps = {
            data: [{ type: 'header' }, ...data, { type: 'insetSpacing' }],
            stickyHeaderIndices: [0],
            showsVerticalScrollIndicator: false,
            keyExtractor: (item: TableData[0], index) => ('id' in item ? item.id : index.toString()),
            scrollEnabled: !!data.length,
            renderItem: renderDataItem,
            estimatedItemSize: 47,
            ListHeaderComponent: () => renderHeaders(true),
            ListFooterComponent: renderFooter,
            onEndReached,
            onEndReachedThreshold,
        };

        return (
            <S.ListWrapper>
                <FlatList {...listProps} />
            </S.ListWrapper>
        );
    };

    if (!data.length) {
        return <S.Container>{renderEmptyState()}</S.Container>;
    }

    if (horizontalScroll) {
        return (
            <S.Container {...props}>
                <TableAnimations stickyCellWidth={stickyCellWidth} screenWidth={screenWidth}>
                    {({ animatedRef, animatedScrollHandler, styles }) => {
                        stylesRef.current = styles;

                        return (
                            <AnimatedHorizontalScroll
                                ref={animatedRef}
                                horizontal
                                onScroll={animatedScrollHandler}
                                showsHorizontalScrollIndicator={false}
                                bounces={false}
                                scrollEventThrottle={16}
                            >
                                {renderList()}
                            </AnimatedHorizontalScroll>
                        );
                    }}
                </TableAnimations>
            </S.Container>
        );
    }

    return <S.Container {...props}>{renderList()}</S.Container>;
};
