import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { MainLayout } from '~/layouts/web';

import { ShoppingListScreen as ShoppingListScreenMobile } from './ShoppingListScreen.native';
import { ShoppingListScreenDesktop } from './ShoppingListScreenDesktop';
import { ShoppingListComingSoon } from './components/shoppingListComingSoon';

export const ShoppingListScreen: FC = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();

    if (!features.shoppingList) {
        return (
            <MainLayout title="Lista zakupów" noBackground={!theme.isWebDesktop} content={<ShoppingListComingSoon />} />
        );
    }

    if (theme.isWebDesktop) {
        return <ShoppingListScreenDesktop />;
    }

    return <ShoppingListScreenMobile />;
};
