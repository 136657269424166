import { Supplement } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchAllSupplements: QueryFunction<Supplement[], ['getAllSupplements']> = async () => {
    return await DataStore.query(Supplement, (criteria) => criteria, {
        sort: (s) => s.createdAt(SortDirection.ASCENDING),
        limit: 1000,
    });
};

export const useGetSupplements = () => useQuery(['getAllSupplements'], fetchAllSupplements, { initialData: [] });
