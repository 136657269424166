import React, { useId, useImperativeHandle, useRef } from 'react';
import { View } from 'react-native';
import { LinearGradientProps } from 'react-native-linear-gradient';
import { Defs, Rect, Stop, Svg, LinearGradient as SvgLinearGradient } from 'react-native-svg';

export const LinearGradient: React.FC<LinearGradientProps> = React.forwardRef((props, ref) => {
    const componentId = useId();
    const gradientId = `gradient-${componentId}`;
    const startGradientRef = useRef<Stop>(null);
    const stopGradientRef = useRef<Stop>(null);
    const svgGradientRef = useRef<SvgLinearGradient>(null);
    const [colors, setColors] = React.useState(props.colors);

    useImperativeHandle(
        ref,
        () => ({
            /*
                There was an issue with react-native-linear-gradient on web environment.
                What's more, expo-linear-gradient also didn't work perfectly, because it doesn't support reanimated at all.
                So I decided to use react-native-svg to implement my own linear gradient for both native and web.
                But (:D) there was an issue on iOS env related with <Stop /> from react-native-svg. Everything worked fine until
                I added reanimated wrapper. Since then on iOS started to occurr an error connected with "element/component host" (which is null in that case).
                I spent a lot of time to find a solution, but I didn't find anything.
                Finally, I decided to use different implementation for native and web:
                    - react-native-linear-gradient for native
                    - react-native-svg for web
                To keep the same API, I had to implement setNativeProps method, because there is no such method
                to spread animatedProps between two components (react-native-linear-gradient uses colors list as prop).
                Fun fact: setNativeProps is used by reanimated aswell.

                I'm not proud of this solution, but it works. ;p
                ~ Mariusz
            */
            setNativeProps(nativeProps: { style: { colors: LinearGradientProps['colors'] } }) {
                setColors(nativeProps.style.colors);
            },
        }),
        [],
    );

    return (
        // eslint-disable-next-line react-native/no-inline-styles
        <View style={[props.style, { overflow: 'hidden' }]}>
            <Svg width="100%" height="100%">
                <Defs>
                    <SvgLinearGradient ref={svgGradientRef} id={gradientId} x1="0" y1="0" x2="0" y2="100%">
                        <Stop ref={startGradientRef} offset={0} stopColor={colors[0] as string} stopOpacity="1" />
                        <Stop ref={stopGradientRef} offset={1} stopColor={colors[1] as string} stopOpacity="1" />
                    </SvgLinearGradient>
                </Defs>
                <Rect fill={`url(#${gradientId})`} x="0" y="0" width="100%" height="100%" />
            </Svg>
        </View>
    );
});
