import { FC, useCallback } from 'react';
import { FlatList, ListRenderItem } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import { DataType } from './AddActivityPopup.types';
import * as S from './addActivityPopup.styled';

const mockData = [
    { name: 'Cardio/bieganie' },
    { name: 'Crossfit' },
    { name: 'Jeździectwo' },
    { name: 'Joga' },
    { name: 'Inny' },
    { name: 'Pływanie' },
    { name: 'Rower/rolki' },
    { name: 'Skakanka' },
    { name: 'Spacer' },
    { name: 'Sporty zimowe' },
    { name: 'Sztuki walki' },
    { name: 'Trening lekkoatletyczny' },
    { name: 'Trening siłowy poza planem' },
    { name: 'Wspinaczka/spacer po górach' },
];

interface AddActivityPopupProps {
    onClosePress: () => void;
    date?: string;
}

export const AddActivityPopup: FC<AddActivityPopupProps> = ({ onClosePress, date }) => {
    const theme = useTheme();
    const handleActivityPress = () => {
        console.log('Add BE logic', date);
    };

    const renderItem: ListRenderItem<DataType> = useCallback(
        ({ item, index }) => (
            <S.ExerciseWrapper key={`${item.name} + ${index}`} onPress={handleActivityPress}>
                <S.ExerciseName>{item.name}</S.ExerciseName>
                <Icon name="add" size={16} color={theme.icon.tertiary} />
            </S.ExerciseWrapper>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <S.Container>
            <S.Header>
                <S.Title>Dodaj aktywność</S.Title>

                <S.ModalCloseButton onPress={onClosePress} />
            </S.Header>
            <FlatList
                data={mockData}
                keyExtractor={(item) => `${item.name}`} //TODO Adjust the keys when we'll get proper schema
                renderItem={renderItem}
                showsVerticalScrollIndicator={false}
            />
        </S.Container>
    );
};
