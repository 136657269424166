import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { TileGradientButton } from '~/components/tileGradientButton';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 16px 16px 0;
`;

export const ButtonsContainer = styled(View)`
    flex-direction: row;
    margin-bottom: 20px;
`;

export const Button = styled(TileGradientButton)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;

export const TitleContainer = styled(View)`
    padding: 8px 0;
    flex-direction: row;
`;

export const TitleWrapper = styled(View)`
    flex: 1;
    margin-right: 12px;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold18};
    color: ${({ theme }) => theme.text.primary};
`;

export const DTitle = styled(Text)`
    ${mixins.typography.h.semiBold24}
    color: ${({ theme }) => theme.text.primary};
    padding: 16px 20px 20px;
`;
