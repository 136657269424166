import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface TagAnimationsRenderPropValue {
    containerAnimatedStyles: {
        borderColor: string;
        backgroundColor: string;
    };
    labelAnimatedStyles: {
        color: string;
    };
}

export interface TagAnimationsProps extends PressableAnimationsProps<TagAnimationsRenderPropValue> {
    active?: boolean;
}

export const TagAnimations = ({ progress, children }: TagAnimationsProps) => {
    const theme = useTheme();

    const animationProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const containerStyles = {
        borderColor: {
            default: theme.border['20'],
            hovered: theme.border.interactive,
            pressed: theme.border.interactive,
        },
        backgroundColor: {
            default: 'transparent',
            hovered: 'transparent',
            pressed: theme.interactive.opacityDefault,
        },
    };

    const labelStyles = {
        color: {
            default: theme.text.secondary,
            hovered: theme.text.primary,
            pressed: theme.text.primary,
        },
    };

    const containerAnimatedStyles = useAnimatedStyle(() => {
        return {
            borderColor: interpolateColor(
                animationProgress.value,
                [0, 1, 2],
                [
                    containerStyles.borderColor.default,
                    containerStyles.borderColor.hovered,
                    containerStyles.borderColor.pressed,
                ],
            ),
            backgroundColor: interpolateColor(
                animationProgress.value,
                [0, 1, 2],
                [
                    containerStyles.backgroundColor.default,
                    containerStyles.backgroundColor.hovered,
                    containerStyles.backgroundColor.pressed,
                ],
            ),
        };
    }, [progress]);

    const labelAnimatedStyles = useAnimatedStyle(() => {
        return {
            color: interpolateColor(
                animationProgress.value,
                [0, 1, 2],
                [labelStyles.color.default, labelStyles.color.hovered, labelStyles.color.pressed],
            ),
        };
    });

    return children({ containerAnimatedStyles, labelAnimatedStyles });
};
