import { ReactNode, forwardRef } from 'react';
import { Pressable } from 'react-native';

import { BottomSheetModal, InfoSheet } from '../bottomSheet';

interface TooltipProps {
    triggerComponent: ReactNode;
    text: string;
}

export const Tooltip = forwardRef<BottomSheetModal, TooltipProps>(({ text, triggerComponent }, ref) => {
    return (
        <>
            {/* @ts-ignore */}
            <Pressable onPress={() => ref?.current?.present()}>{triggerComponent}</Pressable>
            <InfoSheet ref={ref} text={text} />
        </>
    );
});

Tooltip;
