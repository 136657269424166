import { format } from 'date-fns';
import { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { ActivityButton } from '~/components/activityButton';
import { Popup } from '~/components/popup';

import { AddActivityPopup } from '../../../../components/popup/addActivityPopup';
import { DayBar } from './DayBar';
import { backgroundColors, borderColors, calendarData } from './WeeklyActivity.const';
import { WeeklyActivity as WeeklyActivityMobile } from './WeeklyActivity.native';
import * as S from './WeeklyActivity.styled';
import { tmpData } from './tmpData';

export const WeeklyActivity = () => {
    const theme = useTheme();
    const [isAddActivityVisible, setisAddActivityVisible] = useState(false);

    const handleOpenPreview = () => setisAddActivityVisible(true);
    const handleClosePreview = () => setisAddActivityVisible(false);

    const trigger = <ActivityButton icon="add" onPress={handleOpenPreview} />;

    if (!theme.isWebDesktop) return <WeeklyActivityMobile />;

    return (
        <S.Container>
            <S.BorderGradient colors={borderColors} />
            <S.BackgroundGradient colors={backgroundColors} />
            <S.Header>
                <S.Title>Aktywność w tygodniu</S.Title>
                <S.ActionContainer>
                    <Popup
                        isOpen={isAddActivityVisible}
                        trigger={trigger}
                        onClose={handleClosePreview}
                        position={['left top', 'left center', 'left bottom']}
                    >
                        <AddActivityPopup onClosePress={handleClosePreview} date={format(new Date(), 'yyyy-MM-dd')} />
                    </Popup>
                </S.ActionContainer>
            </S.Header>
            <S.CalendarContainer>
                {calendarData.map((data, index) => (
                    <DayBar key={data.id} {...data} activities={tmpData[index]} />
                ))}
            </S.CalendarContainer>
        </S.Container>
    );
};
