import { Pressable, Text as TextBase, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { mixins } from '~/theme/mixins';
import { HoverableProps } from '~/types/hoverable';

import { LinearGradient as LinearGradientBase } from '../linearGradient';
import { TileGradientButtonSize, TileGradientButtonStyledProps } from './TileGradientButton.types';

interface TileGradientButtonHoverableStyledProps extends TileGradientButtonStyledProps, HoverableProps {}

const containerStylesBySize: Record<TileGradientButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    small: css`
        padding: 12px 16px;
    `,
    medium: css`
        padding: 20px;
    `,
    big: css`
        padding: 20px;
    `,
};

export const Container = styled(Pressable)<TileGradientButtonHoverableStyledProps>`
    display: flex;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    background: ${({ theme }) => theme.fill.background};
    ${({ size }) => containerStylesBySize[size]};
    user-select: none;
`;

const labelStylesBySize: Record<TileGradientButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    small: css`
        ${mixins.typography.ui.regular14};
    `,
    medium: css`
        ${mixins.typography.ui.semiBold16};
    `,
    big: css`
        ${mixins.typography.ui.semiBold18};
    `,
};

const gapBySize: Record<TileGradientButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    small: css`
        margin-top: 12px;
    `,
    medium: css`
        margin-top: 12px;
    `,
    big: css`
        margin-top: 16px;
    `,
};

export const TextContainer = styled(View)<TileGradientButtonStyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${({ size }) => gapBySize[size]};
`;

export const Label = styled(TextBase)<TileGradientButtonStyledProps>`
    ${({ size }) => labelStylesBySize[size]};
    color: ${({ theme }) => theme.text.primary};
`;

export const Description = styled(TextBase)<TileGradientButtonStyledProps>`
    ${mixins.typography.ui.regular12}
    color: ${({ theme }) => theme.text.primary};
    opacity: 0.75;
    margin-top: 4px;
`;

const iconStylesBySize: Record<TileGradientButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    small: css`
        width: 24px;
        height: 24px;
    `,
    medium: css`
        width: 24px;
        height: 24px;
    `,
    big: css`
        width: 32px;
        height: 32px;
    `,
};

export const IconWrapper = styled(View)<TileGradientButtonStyledProps>`
    ${({ size }) => iconStylesBySize[size]};
`;

const badgePositionBySize: Record<TileGradientButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    small: css`
        right: 16px;
        top: 12px;
    `,
    medium: css`
        right: 25px;
        top: 20px;
    `,
    big: css`
        right: 20px;
        top: 20px;
    `,
};

export const NotificationBadge = styled(View)<TileGradientButtonStyledProps>`
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: ${({ variant, theme }) => (variant === 'default' ? theme.text.interactive : theme.text.primary)};
    shadow-color: ${({ variant, theme }) => (variant === 'default' ? '#6F6FDE' : theme.text.primary)};
    shadow-opacity: 0.3;
    shadow-radius: 4px;
    shadow-offset: 0 2px;
    elevation: 4;
    ${({ size }) => badgePositionBySize[size]};
`;

const commonLinearGradientStyles = css`
    position: absolute;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.fill.background};
`;

export const BackgroundGradient = styled(LinearGradientBase)`
    ${commonLinearGradientStyles};
    top: 0.5px;
    left: 0.5px;
    right: 0.5px;
    bottom: 0.5px;
`;

export const BorderGradient = styled(LinearGradientBase)`
    ${commonLinearGradientStyles};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
