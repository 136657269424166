import { DietStackScreens } from '~/navigation/paths';

type Link = {
    label: string;
    screen: keyof typeof DietStackScreens.screens;
};

export const links: Link[] = [
    {
        label: 'Podstawy diety',
        screen: 'Basics',
    },
    {
        label: 'Twój metabolizm',
        screen: 'Metabolism',
    },
];
