import { ReactElement } from 'react';
import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';

export interface ExerciseItemAnimationsRenderPropValue {
    borderAnimatedStyles: {
        borderColor: string;
    };
}

export interface ExerciseItemAnimationsProps {
    filled: boolean;
    children: (value: ExerciseItemAnimationsRenderPropValue) => ReactElement;
}

export const ExerciseItemAnimations = ({ filled, children }: ExerciseItemAnimationsProps) => {
    const theme = useTheme();

    const filledProgress = useDerivedValue(() => withSpring(Number(filled), springConfig.default));

    const borderAnimatedStyles = useAnimatedStyle(() => ({
        borderColor: interpolateColor(filledProgress.value, [0, 1], [theme.border['12'], theme.border.positive]),
    }));

    return children({ borderAnimatedStyles });
};
