import { useEffect } from 'react';
import {
    Easing,
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';
import { AnimationsProps } from '~/types/animations';

export interface LoaderAnimationsRenderPropValue {
    transform: {
        rotateZ: string;
    }[];
}

export interface LoaderAnimationsProps extends AnimationsProps<LoaderAnimationsRenderPropValue> {}

export const LoaderAnimations = ({ children }: LoaderAnimationsProps) => {
    const rotation = useSharedValue(0);

    useEffect(() => {
        rotation.value = withRepeat(
            withTiming(360, {
                duration: 400,
                easing: Easing.linear,
            }),
            -1,
        );

        return () => cancelAnimation(rotation);
    }, [rotation]);

    const animatedStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    rotateZ: `${rotation.value}deg`,
                },
            ],
        };
    }, [rotation.value]);

    return children(animatedStyles);
};
