import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { DesktopModal } from '~/components/modal/desktopModal';
import { SectionHeading } from '~/components/sectionHeading';
import { SEO } from '~/components/wrappers';

import { BottomSheetModal } from '../bottomSheetModal';
import {
    MeasurementsInstructionsSheetProps,
    MeasurementsInstructionsSheet as MobileMeasurementsInstructionsSheet,
} from './MeasurementsInstructionsSheet.native';
import * as S from './MeasurementsInstructionsSheet.styled';
import { Instructions } from './components/instructions';

export type MeasurementsInstructionsSheetRef =
    | BottomSheetModal
    | { present: () => void; close: () => void; dismiss: () => void };

export const MeasurementsInstructionsSheet = forwardRef<
    MeasurementsInstructionsSheetRef,
    MeasurementsInstructionsSheetProps
>((props, ref) => {
    const theme = useTheme();
    const mobileConfirmSheetRef = useRef<BottomSheetModal>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleOnClose = useCallback(() => {
        if (theme.isWebDesktop) {
            setIsModalVisible(false);
            return;
        }

        props.onClose?.();
        return mobileConfirmSheetRef?.current?.close();
    }, [props, theme.isWebDesktop]);

    useImperativeHandle(ref, () => {
        return {
            present: () => {
                if (theme.isWebDesktop) {
                    setIsModalVisible(true);
                    return;
                }

                return mobileConfirmSheetRef?.current?.present();
            },
            close: handleOnClose,
            dismiss: handleOnClose,
        };
    });

    if (theme.isWebDesktop) {
        return (
            <DesktopModal isOpen={isModalVisible} onClose={handleOnClose}>
                <SEO title="Jak się mierzyć" />
                <S.Container>
                    <S.HeaderContainer>
                        <SectionHeading variant="header" title="Jak się mierzyć?" />
                    </S.HeaderContainer>
                    <Instructions />
                </S.Container>
            </DesktopModal>
        );
    }

    return <MobileMeasurementsInstructionsSheet {...props} onClose={handleOnClose} ref={mobileConfirmSheetRef} />;
});
