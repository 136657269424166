import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import { ICarouselInstance } from 'react-native-reanimated-carousel';
import { TCarouselProps } from 'react-native-reanimated-carousel/src/types';
import { Loader } from '~/components/loader';
import { useDiet } from '~/enhancers/dietContext';
import { getTodayIndex } from '~/screens/diet/components/dietSlider/utils';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import * as S from './DietSlider.styled.native';
import { Dots } from './components/dots';
import { SliderItem } from './components/sliderItem';

const PADDING = 16;
const today = new Date();

// TODO: fix web mobile sliding behaviour
export const MobileDietSlider = () => {
    const [sliderItemSize, setSliderItemSize] = useState<undefined | { width: number; height: number }>(undefined);
    const ref = useRef<ICarouselInstance>(null);
    const scrollProgress = useSharedValue(0);
    const { sliderData, selectedWeek } = useDiet();
    const todayIndex = getTodayIndex();

    const handleOnLayout = (event: LayoutChangeEvent) => {
        const { width, height } = event.nativeEvent.layout;
        setSliderItemSize({ width: width - 2 * PADDING, height });
    };

    useEffect(() => {
        ref.current?.scrollTo({ index: 0, animated: true });
    }, [selectedWeek]);

    useEffect(() => {
        const handleEmmiter = () => {
            // NOTE: requestAnimationFrame for scheduling scrollTo after useEffect
            requestAnimationFrame(() => {
                ref.current?.scrollTo({ index: todayIndex, animated: true });
            });
        };

        Emitter.on(EmitterEvent.goToTodayDietPlan, handleEmmiter);

        return () => {
            Emitter.off(EmitterEvent.goToTodayDietPlan, handleEmmiter);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContent = () => {
        // Add loading skeleton
        // @ts-ignore
        if (!sliderData) {
            return <Loader />;
        }

        return (
            <>
                <S.CarouselWrapper onLayout={handleOnLayout}>
                    {sliderItemSize && sliderData?.length ? (
                        <S.Carousel<ComponentType<TCarouselProps<(typeof sliderData)[0]>>>
                            ref={ref}
                            loop={false}
                            width={sliderItemSize.width}
                            height={sliderItemSize.height}
                            data={sliderData}
                            defaultIndex={todayIndex}
                            renderItem={({ index, item }) => (
                                <S.SliderItemWrapper>
                                    <SliderItem
                                        key={index}
                                        isToday={new Date(item.date).toDateString() === today.toDateString()}
                                        active={item.active}
                                        date={item.date}
                                        nutritionData={item.nutritionData}
                                        meals={item.meals}
                                        // trainingMeal={item.trainingMeal}
                                    />
                                </S.SliderItemWrapper>
                            )}
                            onProgressChange={(offsetProgress, absoluteProgress) => {
                                scrollProgress.value = absoluteProgress;
                            }}
                        />
                    ) : (
                        <Loader />
                    )}
                </S.CarouselWrapper>
                <S.DotsContainer>
                    <Dots
                        scrollProgress={scrollProgress}
                        totalItems={sliderData.length}
                        onDotPress={(index) => {
                            ref.current?.scrollTo({ index, animated: true });
                        }}
                    />
                </S.DotsContainer>
            </>
        );
    };

    return <S.Container>{renderContent()}</S.Container>;
};

export const DietSlider = MobileDietSlider;
