import { TrainingCardioExercise, TrainingDayGroup, TrainingDayGroupType, TrainingExercise } from '@pg/backend';
import { ExpandedTrainingDay } from '~/api/training/useGetExpandedTrainingDay';

export const isTrainingCardioExercise = (
    trainingExercise: TrainingExercise | TrainingCardioExercise,
): trainingExercise is TrainingCardioExercise => {
    return (trainingExercise as TrainingCardioExercise).cardioexerciseID !== undefined;
};

export const getSectionName = (
    group: TrainingDayGroup,
    exerciseGroupsMap: ExpandedTrainingDay['exerciseGroupsMap'],
) => {
    if (group.type === TrainingDayGroupType.CARDIO || group.type === TrainingDayGroupType.WARMUP) {
        return '';
    }

    return exerciseGroupsMap[group.exercisegroupID!]?.name ?? 'Brak nazwy';
};

export const getGroupTitle = (group: TrainingDayGroup) => {
    if (!group.type) {
        return '';
    }

    return {
        [TrainingDayGroupType.EXERCISE]: 'Część główna',
        [TrainingDayGroupType.CARDIO]: 'Cardio',
        [TrainingDayGroupType.WARMUP]: 'Część wstępna',
    }[group.type];
};

export const sortByOrderField = (a: { order?: number | null }, b: { order?: number | null }) => {
    return (a?.order ?? 0) - (b?.order ?? 0);
};

export const getTotalNumberOfSeries = (data?: ExpandedTrainingDay | null) => {
    if (!data) {
        return 0;
    }

    return data.trainingExercises
        ?.filter((trainingExercise) => {
            const group = data?.groupsMap[trainingExercise.trainingdaygroupID!];
            return group?.type === TrainingDayGroupType.EXERCISE;
        })
        .reduce((acc, trainingExercise) => {
            const exerciseData = data?.exercisesDataMap[trainingExercise.trainingExerciseExerciseDataId!];
            acc += exerciseData?.series ?? 0;
            return acc;
        }, 0);
};

export const getPaceTime = (pace: string) =>
    pace
        .replace('x', '1')
        .split('')
        .map(Number)
        .reduce((acc, p) => (acc += p), 0);

/**
 * @param data
 * @returns total time in seconds
 * ExerciseData calculation
 * number of series * number of reps * pace + stop * number of series
 * To calculate pace we need to add all the numbers and treat x as 1
 * e.g. 21x1 = 2 + 1 + 1 + 1 = 5
 */
export const getTotalTime = (data?: ExpandedTrainingDay | null) => {
    if (!data) {
        return 0;
    }

    const totalExercisesTime = data.exercisesData.reduce((acc, exerciseData) => {
        const seriesTime = exerciseData?.series! * exerciseData?.reps! * getPaceTime(exerciseData?.pace!);
        const stopTime = exerciseData?.series! * exerciseData?.stop!;
        return acc + seriesTime + stopTime;
    }, 0);

    const totalCardioExercisesTime = data.cardioExercisesData.reduce((acc, cardioExerciseData) => {
        return (acc += cardioExerciseData.time!);
    }, 0);

    return totalExercisesTime + totalCardioExercisesTime;
};
