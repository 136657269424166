import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { ActivityButton as ActivityButtonBase } from '~/components/activityButton';
import { LinearGradient as LinearGradientBase } from '~/components/linearGradient';
import { mixins } from '~/theme';

const commonLinearGradientStyles = css`
    position: absolute;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.fill.background};
`;

export const BackgroundGradient = styled(LinearGradientBase)`
    ${commonLinearGradientStyles};
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
`;

export const BorderGradient = styled(LinearGradientBase)`
    ${commonLinearGradientStyles};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const Container = styled(View)`
    display: flex;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    background: ${({ theme }) => theme.fill.background};
    padding: 12px 16px 16px;
    shadow-color: rgba(30, 15, 69);
    shadow-offset: 0px 12px;
    shadow-opacity: 0.4;
    shadow-radius: 32px;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.semiBold16};
    color: ${({ theme }) => theme.text.primary};
`;

export const Header = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ActivityButton = styled(ActivityButtonBase)`
    margin-right: 8px;
`;

export const ActionContainer = styled(View)`
    flex-direction: row;
`;

export const CalendarContainer = styled(View)`
    padding: 3px 6px 0;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px auto 0;
    width: 100%;
    max-width: 460px;
`;
