import React, { useCallback, useMemo, useState } from 'react';
import { GestureResponderEvent } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { ToastActionsPosition } from '~/components/toast/Toast.types';
import { useToasts } from '~/enhancers/toasts.context';
import { usePressableState } from '~/hooks/usePressableState';

import { FadeAnimation, FadeAnimationProps } from '../FadeAnimation';
import { Button } from '../button';
import { InfoBoxProps } from '../infoBox';
import * as S from './Toast.styled';

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export interface ToastProps extends Partial<Omit<InfoBoxProps, 'children'>> {
    id: string;
    title?: string;
    bigTitle?: boolean;
    subTitle?: string;
    description?: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    hiddenIconOnMobile?: boolean;
    withSpacing?: boolean;
    animationDisabled?: boolean;
    actionsPosition?: ToastActionsPosition;
    onConfirmPress?: (event: GestureResponderEvent, id: string) => void;
    onCancelPress?: (event: GestureResponderEvent, id: string) => void;
    onDismiss?: (event: GestureResponderEvent, id: string) => void;
}

export const Toast: React.FC<ToastProps> = ({
    id,
    title,
    description,
    icon,
    iconSize,
    iconPosition,
    hiddenIconOnMobile = true,
    variant = 'informative',
    style,
    bigTitle,
    subTitle,
    animationDisabled,
    actionsPosition = 'bottom',
    withSpacing = false,
    ...actions
}) => {
    const theme = useTheme();
    const { hideToast } = useToasts();
    const iconName = theme.isWeb || !hiddenIconOnMobile ? icon : undefined;
    const [webAnimationPhase, setWebAnimationPhase] = useState<FadeAnimationProps['phase']>('entering');
    const dismissButtonPressableState = usePressableState();

    const closeToastWithAnimation = useCallback(() => {
        if (theme.isWeb) {
            setWebAnimationPhase('exiting');
        } else {
            hideToast(id);
        }
    }, [hideToast, id, theme.isWeb]);

    const handleConfirmPress = useCallback(
        (event: GestureResponderEvent) => {
            closeToastWithAnimation();
            actions.onConfirmPress?.(event, id);
        },
        [actions, closeToastWithAnimation, id],
    );

    const handleCancelPress = useCallback(
        (event: GestureResponderEvent) => {
            closeToastWithAnimation();
            actions.onCancelPress?.(event, id);
        },
        [actions, closeToastWithAnimation, id],
    );

    const handleDismissPress = useCallback(
        (event: GestureResponderEvent) => {
            closeToastWithAnimation();
            actions.onDismiss?.(event, id);
        },
        [actions, closeToastWithAnimation, id],
    );

    const handleExitingEnd = useCallback(() => {
        if (theme.isWeb) {
            hideToast(id);
        }
    }, [id, hideToast, theme.isWeb]);

    const closeButtonColor = useMemo(() => {
        const variantMap: Record<NonNullable<InfoBoxProps['variant']>, string> = {
            ['informative']: theme.icon.interactiveSurface,
            ['warning']: theme.icon.criticalSurface,
            ['critical']: theme.icon.criticalSurface,
        };

        return variantMap[variant];
    }, [variant, theme]);

    const renderToast = () => (
        <S.ToastContainer
            style={style}
            variant={variant}
            icon={iconName}
            iconSize={iconSize}
            iconPosition={iconPosition}
            actionsPosition={actionsPosition}
            withSpacing={withSpacing}
        >
            <S.ContentContainer actionsPosition={actionsPosition}>
                <S.Content actionsPosition={actionsPosition}>
                    {subTitle && <S.SubTitle withMargin={!!title || !!description}>{subTitle}</S.SubTitle>}
                    {title && <S.Title big={bigTitle}>{title}</S.Title>}
                    {description && (
                        <S.Description withMargin={!!title || !!subTitle} bigTitle={bigTitle}>
                            {description}
                        </S.Description>
                    )}
                </S.Content>
                {actions.onConfirmPress || actions.onCancelPress || actions.onDismiss ? (
                    <S.Actions actionsPosition={actionsPosition}>
                        {actions.onConfirmPress && (
                            <S.PrimaryButton
                                variant={variant === 'informative' ? 'on-informative' : 'on-error'}
                                size="s"
                                onPress={handleConfirmPress}
                            >
                                {actions.confirmButtonLabel ?? 'OK'}
                            </S.PrimaryButton>
                        )}
                        {actions.onCancelPress && (
                            <S.CancelButtonContainer>
                                <Button variant="on-background" size="s" onPress={handleCancelPress}>
                                    {actions.cancelButtonLabel ?? 'Cancel'}
                                </Button>
                            </S.CancelButtonContainer>
                        )}
                        {actions.onDismiss && (
                            <S.DismissButtonContainer
                                onPress={handleDismissPress}
                                {...dismissButtonPressableState.pressableProps}
                            >
                                <PressableIcon
                                    name="close"
                                    size={24}
                                    color={closeButtonColor}
                                    pressedColor={theme.interactive.secondary}
                                    hoverColor={theme.interactive.secondary}
                                    pressableAnimationProgress={dismissButtonPressableState.pressableAnimationProgress}
                                />
                            </S.DismissButtonContainer>
                        )}
                    </S.Actions>
                ) : null}
            </S.ContentContainer>
        </S.ToastContainer>
    );

    if (animationDisabled) {
        return renderToast();
    }

    return (
        <FadeAnimation phase={webAnimationPhase} direction="up" onExitingEnd={handleExitingEnd}>
            {(animationConfig) => <AnimatedContainer {...animationConfig}>{renderToast()}</AnimatedContainer>}
        </FadeAnimation>
    );
};
