import { useAppFeatures } from '~/enhancers/appFeaturesProvider';
import { ChatLayout, MainLayout } from '~/layouts/native';

import { ChatComingSoon } from '../chat/components/chatComingSoon';
import { ChatSelectionContent } from './components/chatSelectionContent';

export const ChatSelection = () => {
    const { features } = useAppFeatures();

    if (!features.chat)
        return (
            <MainLayout noBackground fullWidth>
                <ChatComingSoon />
            </MainLayout>
        );

    return (
        <ChatLayout isLinearGradientBg>
            <ChatSelectionContent />
        </ChatLayout>
    );
};
