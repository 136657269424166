import LottieView from 'lottie-react';
import { useEffect, useState } from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withTiming } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';

import * as S from './FullScreenLoader.styled';
import { FullScreenLoaderProps } from './FullScreenLoader.types';

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

const ANIMATION_DURATION = 300;

export const FullScreenLoader = ({ visible }: FullScreenLoaderProps) => {
    const [display, setDisplay] = useState(visible);
    const theme = useTheme();
    const progress = useSharedValue(Number(visible));

    useEffect(() => {
        if (visible) {
            progress.value = 1;
            setDisplay(true);
        } else {
            progress.value = withDelay(
                100,
                withTiming(0, { duration: ANIMATION_DURATION }, () => setDisplay(false)),
            );
        }
    }, [progress, visible]);

    const lottieStyles = theme.isWebDesktop ? S.lottieViewStyles.desktop : S.lottieViewStyles.mobile;

    const animatedStyle = useAnimatedStyle(() => ({
        opacity: progress.value,
    }));

    return display ? (
        <AnimatedContainer style={animatedStyle}>
            <LottieView
                animationData={require('~/assets/lottie/loading.json')}
                autoPlay
                loop
                style={{ ...lottieStyles }}
            />
        </AnimatedContainer>
    ) : null;
};
