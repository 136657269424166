import { Checkbox } from '~/components/checkbox';

import * as S from './CheckboxListItem.styled';

type CheckboxListItemProps = {
    title: string;
    subtitle: string;
    checked: boolean;
    onChange: () => void;
};

export const CheckboxListItem = ({ title, subtitle, checked, onChange }: CheckboxListItemProps) => {
    return (
        <S.Wrapper onPress={onChange}>
            <Checkbox checked={checked} />
            <S.Container>
                <S.Title checked={checked}>{title}</S.Title>
                <S.Subtitle>{subtitle}</S.Subtitle>
            </S.Container>
        </S.Wrapper>
    );
};
