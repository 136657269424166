import React from 'react';
import { SEO } from '~/components/wrappers';
import { MainLayout } from '~/layouts';
import { MoreLayout } from '~/layouts/moreLayout';

import * as S from './MobileAppScreen.styled';

export const MobileAppScreen = () => {
    return (
        <MainLayout>
            <SEO title="Aplikacja mobilna" />
            <MoreLayout>
                <S.Container>
                    <S.Content>
                        <S.Title>
                            <S.TitleText>
                                Aplikacja będzie dostępna wkrótce.
                                <br />
                                Na pewno dam Ci znać, kiedy to nastąpi.
                            </S.TitleText>
                        </S.Title>
                    </S.Content>
                    <S.Footer>
                        <S.FooterImage source={require('~/assets/images/mobile_app_background.png')} />
                    </S.Footer>
                </S.Container>
            </MoreLayout>
        </MainLayout>
    );
};
