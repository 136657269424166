export enum EmitterEvent {
    scrollToDate = 'SCROLL_TO_DATE',
    openSheet = 'OPEN_SHEET',
    shoppingListTabChange = 'SHOPPING_LIST_TAB_CHANGE',
    goToTodayDietPlan = 'DIET_PLAN_GO_TO_TODAY',
}

export type EmitterEventMappedType = {
    [EmitterEvent.scrollToDate]: null;
    [EmitterEvent.openSheet]: null;
    [EmitterEvent.shoppingListTabChange]: {
        value: 'categories' | 'recipes';
    };
    [EmitterEvent.goToTodayDietPlan]: null;
};

export type EmitterEventPayload<T extends EmitterEvent> = EmitterEventMappedType[T];
