import React from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { InstructionButtonAnimations } from './InstructionButton.animated';
import * as S from './InstructionButton.styled';

export interface InstructionButtonProps
    extends Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut'> {}

const AnimatedLabel = Animated.createAnimatedComponent(S.Label);

export const InstructionButton: React.FC<InstructionButtonProps> = (props) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <S.Container {...props} {...pressableProps}>
            <InstructionButtonAnimations progress={pressableAnimationProgress}>
                {({ labelAnimatedStyles }) => (
                    <>
                        <S.IconContainer>
                            <PressableIcon
                                name="video-player"
                                size={16}
                                color={theme.icon.secondary}
                                pressedColor={theme.icon.primary}
                                pressableAnimationProgress={pressableAnimationProgress}
                            />
                        </S.IconContainer>
                        <AnimatedLabel style={labelAnimatedStyles}>Instrukcja</AnimatedLabel>
                    </>
                )}
            </InstructionButtonAnimations>
        </S.Container>
    );
};
