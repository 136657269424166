import { View } from 'react-native';
import styled from 'styled-components/native';

import { BottomSheetBackdrop as BottomSheetBackdropBase } from '../bottomSheetModal';

export const HeaderWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
`;

export const BottomSheetBackdrop = styled(BottomSheetBackdropBase)`
    background-color: ${({ theme }) => theme.fill.background};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;
