import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Wrapper = styled(View)`
    align-items: center;
`;

export const TextWrapper = styled(Text)`
    margin-bottom: 2px;
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
`;

export const DateText = styled(Text)`
    text-transform: capitalize;
`;

export const NoDateText = styled(Text)``;

export const ButtonLabel = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.tertiary};
`;
