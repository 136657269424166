import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 428px;
    min-height: 650px;
    border-radius: 12px;
    overflow: hidden;
`;

export const Header = styled(View)`
    background-color: ${({ theme }) => theme.fill.background};
    padding: 12px 16px;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold16}
    color: ${({ theme }) => theme.text.primary};
    text-align: center;
`;

export const SelectedDateText = styled(Text)`
    ${mixins.typography.p.regular16}
    color: ${({ theme }) => theme.text.interactive};
    text-align: center;
    padding: 10px 0;
`;

export const Wrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Spacer = styled(View)`
    width: 20px;
    height: 20px;
`;
