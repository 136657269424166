import { Helmet } from 'react-helmet-async';
import { useTheme } from 'styled-components/native';

const dietScrollbarStyles = `
  #diet-scrollbar::-webkit-scrollbar {
        display: initial;
        width: 0;
        height: 12px;
    }
  #diet-scrollbar::-webkit-scrollbar-track {
      background: #2B2A2A;
      border-radius: 8px;
      margin-left: 64px;
      margin-right: 64px;
  }
  #diet-scrollbar::-webkit-scrollbar-thumb {
      background: #575757;
      border-radius: 8px;
      position: relative;
  }
  #diet-scrollbar::-webkit-scrollbar-thumb::after {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      content: "";
      border-radius: 8px;
      background: red;
  }
  #diet-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #484848;
  }
  #diet-scrollbar::-webkit-scrollbar-thumb:active {
      background: #484848;
  }
`;

const globalStyles = `
    /* Remove input autofill styles */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: none;
        transition: background-color 0s;
        transition-delay: 1000s;
    }
     
    /* Remove input focus outline */
    input {
        &:focus {
            outline: none;
        }  
    }
    
    /* Remove number input arrows */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    
    /* Hide scrollbar */
    div::-webkit-scrollbar {
      display: none;
    }
    
    body {
        background: #0C0B0B;
    }
    
    ${dietScrollbarStyles};
`;

const globalScripts = `
    document.addEventListener("wheel", function(event) {
        if (document.activeElement.type === "number"){
            event.preventDefault();
        }
    }, { passive: false });
`;

export const Global = () => {
    const theme = useTheme();

    return theme.platform === 'web' ? (
        <Helmet>
            <style>{globalStyles}</style>
            <script type="text/javascript">{globalScripts}</script>
        </Helmet>
    ) : null;
};
