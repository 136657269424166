import { IconName } from '~/icons';
import { defaultTheme } from '~/theme';

import { BoxVariant } from './RulesContent.types';

export const getIconNameAndColor = (variant: BoxVariant | undefined): { name: IconName; color: string } | null => {
    if (variant === 'warning') {
        return { name: 'warning-stop', color: defaultTheme.icon.criticalSurface };
    }
    if (variant === 'info') {
        return { name: 'chat-outline', color: defaultTheme.icon.interactiveSurface };
    }
    return null;
};
