import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { PlanSelectionModal } from '~/components/modal';
import { PeriodSelectionButton } from '~/components/periodSelectionButton';
import { useDiet } from '~/enhancers/dietContext';
import { getDateRangeWithPlaceholder } from '~/utils/dates';

import * as S from './DietPlanSelection.styled';

export const DietPlanSelection = () => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const { plans, activePlan, selectActivePlan } = useDiet();

    const triggerComponent = (
        <S.PeriodSelectionButtonWrapper>
            <PeriodSelectionButton
                isLoading={false}
                isOpen={isOpen}
                title={getDateRangeWithPlaceholder({
                    startDate: activePlan?.startDate,
                    endDate: activePlan?.endDate,
                    placeholder: 'W trakcie przygotowywania',
                })}
                subtitle="Wybierz inny plan"
                onPress={() => setIsOpen((prev) => !prev)}
            />
        </S.PeriodSelectionButtonWrapper>
    );

    if (!theme.isWebDesktop) return null;

    return (
        <PlanSelectionModal
            isOpen={isOpen}
            data={plans.map(({ id, startDate, endDate }) => ({
                id,
                selected: id === activePlan?.id,
                title: getDateRangeWithPlaceholder({
                    startDate,
                    endDate,
                    placeholder: 'W trakcie przygotowywania',
                    dateFormat: 'dd.MM.yyyy',
                }),
                onPress: () => {
                    selectActivePlan(id);
                    setIsOpen(false);
                },
            }))}
            triggerComponent={triggerComponent}
            onClose={() => setIsOpen(false)}
        />
    );
};
