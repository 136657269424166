import React from 'react';
import { ImageSourcePropType, StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import * as S from './ComingSoon.styled';
import { DesktopBackgroundGradient } from './ComingSoon.styled';

export interface ComingSoonProps {
    title?: string;
    inner?: boolean;
    features?: string[];
    background?: string;
    imageSrc: ImageSourcePropType;
    variant?: 'mobile' | 'desktop';
    desktopImageContainerStyle?: StyleProp<ViewStyle>;
}

export const ComingSoon: React.FC<ComingSoonProps> = ({
    title,
    features,
    background,
    imageSrc,
    inner,
    variant = 'mobile',
    desktopImageContainerStyle,
}) => {
    const theme = useTheme();
    const defaultBackground = theme.isWebDesktop ? theme.fill.level1 : theme.fill.background;
    const backgroundColor = background || defaultBackground;
    const inset = useSafeAreaInsets();

    const renderFeature = (feature: NonNullable<ComingSoonProps['features']>[0]) => (
        <S.FeatureContainer key={feature}>
            <S.IconWrapper>
                <Icon name="check-fill" size={16} color={theme.icon.positive} />
            </S.IconWrapper>
            <S.Feature>{feature}</S.Feature>
        </S.FeatureContainer>
    );

    const renderDesktopVariant = () => (
        <S.DesktopContainer>
            <S.DesktopContent>
                <DesktopBackgroundGradient backgroundColor={backgroundColor} />
                <S.DesktopImageContainer style={desktopImageContainerStyle}>
                    <S.DesktopFeatureImage source={imageSrc} />
                    <S.DesktopGradient backgroundColor={backgroundColor} />
                    <S.DesktopComingSoonImage source={require('~/assets/images/coming_soon_desktop.webp')} />

                    {(title || features) && (
                        <S.DesktopFeaturesContainer>
                            {title && <S.Title>{title}</S.Title>}
                            {features?.map((feature) => renderFeature(feature))}
                        </S.DesktopFeaturesContainer>
                    )}
                </S.DesktopImageContainer>
            </S.DesktopContent>
        </S.DesktopContainer>
    );

    const renderMobileVariant = () => (
        <S.Content>
            {(title || features) && (
                <S.FeaturesContainer inner={inner}>
                    {title && <S.Title>{title}</S.Title>}
                    {features?.map((feature) => renderFeature(feature))}
                </S.FeaturesContainer>
            )}
            <S.ImageContainer>
                <S.PhoneBodyImage source={require('~/assets/images/phone_body.png')} />
                <S.FeatureImage source={imageSrc} />
                <S.ComingSoonImage source={require('~/assets/images/coming_soon.png')} />
                <S.Gradient colors={[`${backgroundColor}00`, backgroundColor]} />
            </S.ImageContainer>
        </S.Content>
    );

    return (
        <S.Container inset={inset}>{variant === 'mobile' ? renderMobileVariant() : renderDesktopVariant()}</S.Container>
    );
};
