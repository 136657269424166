import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    flex-direction: row;
`;

export const Content = styled(Text)`
    flex: 1;
    margin-left: 20px;
    margin-top: 3px;
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.primary};
`;
