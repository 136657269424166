import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 32px 8px;
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold32}
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 32px;
`;

export const Disclaimer = styled(Text)`
    ${mixins.typography.ui.regular16}
    color: ${({ theme }) => theme.text.tertiary};
`;
