import { useCallback, useEffect, useState } from 'react';

type UseDataPoint = {
    dataPoints: {
        index: number;
        x: number;
        y: number;
    }[];
    currentDataPoint: {
        index: number;
        x: number;
        y: number;
    };
    left?: number;
};

export const useDataPoint = (
    { dataPoints, currentDataPoint, left = 0 }: UseDataPoint,
    handlePointerMove: (locationX: number, setX: (arg: number) => void, setY: (arg: number) => void) => void,
    setSelectedMeasureIndex: (index: number) => void,
) => {
    const [x, setX] = useState(currentDataPoint?.x);
    const [y, setY] = useState(currentDataPoint?.y);

    const updateMousePosition = useCallback(
        (ev: MouseEvent) => {
            ev.preventDefault();
            const locationX = ev.clientX - left;
            handlePointerMove(locationX, setX, setY);
        },
        [handlePointerMove, left],
    );

    useEffect(() => {
        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, [updateMousePosition]);

    const handleOnHoverIn = () => {
        window.addEventListener('mousemove', updateMousePosition);
    };

    const handleOnHoverOut = () => {
        const point = dataPoints[dataPoints.length - 1];
        setSelectedMeasureIndex(dataPoints.length - 1);
        setX(point.x);
        setY(point.y);
        window.removeEventListener('mousemove', updateMousePosition);
    };

    return {
        x,
        y,
        chartContainerProps: {
            onTouchMove: undefined,
            onTouchEnd: undefined,
            onHoverIn: handleOnHoverIn,
            onHoverOut: handleOnHoverOut,
        },
    };
};
