import { ScrollView, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    flex: 1;
`;

export const MealsList = styled(ScrollView)``;

export const MealItemContainer = styled(View)<{ last?: boolean }>`
    margin-top: 16px;
    margin-bottom: ${({ last }) => (last ? 16 : 0)}px;
`;

export const InactiveState = styled(Text)`
    flex: 1;
    padding-top: 16px;
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
`;
