import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { StandardNavigation } from '~/components/topNavigation';
import { MeasurementScreen } from '~/screens/measurements/Measurement';
import { MeasurementsTableScreen } from '~/screens/measurements/measurementsTable';
import { MetamorphosisScreen } from '~/screens/measurements/metamorphosis';

import { MeasurementsStackParamsList } from './MeasurementsStackNavigation.types';

const Stack = createNativeStackNavigator<MeasurementsStackParamsList>();

const MeasurementHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation onBackPress={() => props.navigation.navigate('Tab', { screen: 'Measurements' })} {...props} />
);

const MetamorphosisHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Zobacz metamorfozę"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'Measurements' })}
        {...props}
    />
);

const TableHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Tabela pomiarów"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'Measurements' })}
        {...props}
    />
);

export const MeasurementsStackNavigation = () => {
    return (
        <Stack.Navigator screenOptions={{ header: () => null }}>
            <Stack.Screen
                name="Measurement"
                component={MeasurementScreen}
                options={{
                    header: MeasurementHeader,
                }}
            />
            <Stack.Screen
                name="Metamorphosis"
                component={MetamorphosisScreen}
                options={{
                    header: MetamorphosisHeader,
                }}
            />
            <Stack.Screen
                name="Table"
                component={MeasurementsTableScreen}
                options={{
                    header: TableHeader,
                }}
            />
        </Stack.Navigator>
    );
};
