import { Pressable, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { Button } from '../button';
import { InfoBox } from '../infoBox';
import { ToastActionsPosition, ToastStyledProps } from './Toast.types';

export const Container = styled(View)`
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            left: 64px;
            width: calc(100% - 64px);
        `}
`;

export const ToastContainer = styled(InfoBox)<ToastStyledProps>`
    padding: ${({ theme }) => (theme.isWebDesktop ? '20px 24px' : '12px 16px')};
    border-radius: ${({ theme }) => (theme.isWebDesktop ? 0 : 12)}px;

    ${({ theme, variant }) =>
        variant === 'warning' &&
        !theme.isWebDesktop &&
        css`
            padding: 12px 16px;
        `};

    ${({ variant }) =>
        variant === 'warning' &&
        css`
            align-items: center;
        `};

    ${({ actionsPosition }) =>
        actionsPosition === 'right' &&
        css`
            align-items: center;
        `};

    ${({ withSpacing, theme }) =>
        withSpacing &&
        css`
            margin: ${theme.isWeb ? '16px' : '0'} 16px 8px;
        `};
`;

export const ContentContainer = styled(View)<{ actionsPosition: ToastActionsPosition }>`
    ${({ actionsPosition }) =>
        actionsPosition === 'right' &&
        css`
            flex-direction: row;
            align-items: center;
        `};
`;

export const Content = styled(View)<{ actionsPosition: ToastActionsPosition }>`
    flex: 1 0 auto;

    ${({ actionsPosition }) =>
        actionsPosition === 'right' &&
        css`
            flex: 1;
        `};
`;

export const Title = styled(Text)<{ big?: boolean }>`
    ${mixins.typography.h.semiBold18};
    color: inherit;

    ${({ big }) =>
        big &&
        css`
            ${mixins.typography.h.semiBold28};
        `};
`;

export const SubTitle = styled(Text)<{ withMargin?: boolean }>`
    ${mixins.typography.ui.medium14};
    color: inherit;
    text-transform: uppercase;

    ${({ withMargin }) =>
        withMargin &&
        css`
            margin-bottom: 20px;
        `};
`;

export const Description = styled(Text)<{ withMargin?: boolean; bigTitle?: boolean }>`
    ${mixins.typography.ui.regular14};
    color: inherit;

    ${({ withMargin }) =>
        withMargin &&
        css`
            margin-top: 3px;
        `};

    ${({ bigTitle, withMargin }) =>
        bigTitle &&
        withMargin &&
        css`
            margin-top: 20px;
        `};
`;

export const Actions = styled(View)<{ actionsPosition: ToastActionsPosition }>`
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin-top: 20px;

    ${({ actionsPosition }) =>
        actionsPosition === 'right' &&
        css`
            margin-top: 0;
            align-items: center;
        `};
`;

export const PrimaryButton = styled(Button)``;

export const CancelButtonContainer = styled(View)`
    margin-left: 12px;
`;
export const DismissButtonContainer = styled(Pressable)`
    margin-left: 20px;
    align-items: center;
`;
