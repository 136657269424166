import { Image, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 16px;
    height: 72px;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
`;

export const HeaderWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const UserName = styled(Text)`
    ${mixins.typography.ui.medium14}
    color:${({ theme }) => theme.text.secondary}
`;

export const DateTime = styled(Text)`
    ${mixins.typography.ui.regular12}
    color:${({ theme }) => theme.text.tertiary}
`;

export const Message = styled(Text)`
    ${mixins.typography.ui.regular14}
    color:${({ theme }) => theme.text.primary}
`;
export const MessageWrapper = styled(View)`
    flex: 1;
`;
export const Avatar = styled(Image)`
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 100px;
`;
