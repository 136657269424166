import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24};
    color: ${({ theme }) => theme.text.primary};
`;

export const LeftWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
`;

export const BackButton = styled(Pressable)`
    margin-right: 24px;
`;

export const Slash = styled(Text)`
    color: ${({ theme }) => theme.text.disabled};
`;
