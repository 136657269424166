import { View } from 'react-native';
import styled from 'styled-components/native';

import { ContainerProps } from './Notifications.types';

export const Container = styled(View)<ContainerProps>`
    position: absolute;
    left: 20px;
    right: 20px;
    top: ${({ inset }) => (inset?.top ? inset.top + 12 : 20)}px;
    bottom: ${({ inset }) => (inset?.bottom ? inset.bottom + 12 : 20)}px;
`;
