import { Measurement } from '@pg/backend';
import { SortDirection } from 'aws-amplify';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { useGetUserMeasurements } from '~/api/userMeasurment/useGetUserMeasurements';
import { ActivityButton } from '~/components/activityButton';
import { BottomSheetModal } from '~/components/bottomSheet';
import { ConfirmSheet } from '~/components/bottomSheet/confirmSheet';
import { MobileMenuSheet } from '~/components/bottomSheet/mobileMenuSheet';
import { Dropdown } from '~/components/dropdown/Dropdown';
import { EmptyScreen } from '~/components/emptyScreen';
import { MobileMenuTile } from '~/components/mobileMenuTile';
import { PaginationProps } from '~/components/pagination';
import { TableHeaders, TableProps } from '~/components/table';
import { useMeasurement } from '~/enhancers/measurement.context';
import { formatDate } from '~/utils/dates';

import { useMeasurementsPagination } from './hooks/useMeasurementsPagination';
import { getTableHeaders } from './useMeasurementsTable.utils';

type UseMeasurementsTableProps = {
    onEdit?: (id: string) => void;
    onRemove?: (id: string) => void;
};

export const useMeasurementsTable = ({ onEdit, onRemove }: UseMeasurementsTableProps = {}): {
    table: TableProps;
    pagination: PaginationProps | undefined;
} => {
    const theme = useTheme();
    const { data, isLoading } = useGetUserMeasurements({
        sortDirection: SortDirection.DESCENDING,
    });
    const { paginatedData, pagination } = useMeasurementsPagination({
        data,
    });
    const deleteConfirmSheetRef = useRef<BottomSheetModal>(null);
    const [measurementIdToDelete, setMeasurementIdToDelete] = useState<string>();
    const [selectedMeasurementId, setSelectedMeasurementId] = useState<string>();
    const [hoveredItemId, setHoveredItemId] = useState<string | undefined>(undefined);
    const { addMeasurement } = useMeasurement();

    useEffect(() => {
        if (measurementIdToDelete) {
            deleteConfirmSheetRef.current?.present();
        }
    }, [measurementIdToDelete]);

    const handleRemove = useCallback((id: string) => {
        setSelectedMeasurementId(undefined);
        setMeasurementIdToDelete(id);
    }, []);

    const handleRemoveConfirm = useCallback(() => {
        deleteConfirmSheetRef.current?.close();
        setMeasurementIdToDelete(undefined);
        onRemove?.(measurementIdToDelete!);
    }, [measurementIdToDelete, onRemove]);

    const handleConfirmClose = useCallback(() => {
        setMeasurementIdToDelete(undefined);
        deleteConfirmSheetRef.current?.close();
    }, []);

    const handleEdit = useCallback(
        (id: string) => {
            setSelectedMeasurementId(undefined);
            onEdit?.(id);
        },
        [onEdit],
    );

    const headers: TableHeaders<Measurement> = useMemo(
        () => [
            ...getTableHeaders({ isWebDesktop: theme.isWebDesktop }),
            {
                id: 'menu',
                alignment: theme.isWebDesktop ? 'right' : 'left',
                width: theme.isWebDesktop ? 1 : 14,
                maxWidth: theme.isWebDesktop ? 64 : undefined,
                render: ({ id }) => (
                    <>
                        {theme.isWebDesktop && (
                            <Dropdown
                                items={[
                                    {
                                        label: 'Edytuj',
                                        icon: 'edit',
                                        onPress: () => handleEdit(id),
                                    },
                                    {
                                        label: 'Usuń',
                                        icon: 'trash-outline',
                                        onPress: () => handleRemove(id),
                                    },
                                ]}
                                onTriggerHoverIn={() => setHoveredItemId(id)}
                                onTriggerHoverOut={() => setHoveredItemId(undefined)}
                            />
                        )}
                        {!theme.isWebDesktop && (
                            <ActivityButton icon="more" onPress={() => setSelectedMeasurementId(id)} />
                        )}
                    </>
                ),
            },
        ],
        [handleEdit, handleRemove, theme.isWebDesktop],
    );

    const getConfirmSheetSubtitle = () => {
        const date = paginatedData.find((measurement) => measurement.id === measurementIdToDelete)?.date;

        return `Pomiar z dnia ${formatDate(date)} zostanie usunięty.`;
    };

    const footer = (
        <>
            <ConfirmSheet
                ref={deleteConfirmSheetRef}
                variant="error"
                title="Czy na pewno chcesz usunąć wpis?"
                subtitle={getConfirmSheetSubtitle()}
                icon="trash-outline"
                primaryButtonLabel="Tak, usuń"
                secondaryButtonLabel="Nie"
                initiallyOpen={!!measurementIdToDelete}
                onConfirm={handleRemoveConfirm}
                onClose={handleConfirmClose}
            />
            {!theme.isWebDesktop && (
                <MobileMenuSheet isOpen={!!selectedMeasurementId} onClose={() => setSelectedMeasurementId(undefined)}>
                    <MobileMenuTile
                        icon="edit"
                        iconColor={theme.icon.primary}
                        onPress={() => handleEdit(selectedMeasurementId!)}
                    >
                        Edytuj
                    </MobileMenuTile>
                    <MobileMenuTile
                        icon="trash-outline"
                        iconColor={theme.icon.critical}
                        textVariant="critical"
                        onPress={() => handleRemove(selectedMeasurementId!)}
                        last
                    >
                        Usuń
                    </MobileMenuTile>
                </MobileMenuSheet>
            )}
        </>
    );

    return {
        table: {
            headers,
            data: paginatedData,
            footer,
            loading: isLoading,
            lastColumnSticky: !theme.isWeb,
            horizontalScroll: !theme.isWebDesktop,
            backgroundColor: theme.fill.level1,
            hoveredItemId,
            emptyState: (
                <EmptyScreen
                    icon="ruler-outline"
                    iconColor={theme.icon.tertiary}
                    maxContentWidth={theme.isWebDesktop ? 570 : undefined}
                    title="Nie dodałeś jeszcze żadnych pomiarów"
                    description="Regularnie dodawaj swoje pomiary, aby śledzić postępy w procesie metamorfozy. Dzięki temu łatwiej będzie dostosować plany do osiąganych wyników."
                    button={
                        theme.isWebDesktop
                            ? undefined
                            : {
                                  label: 'Dodaj nowy pomiar',
                                  icon: 'add',
                                  variant: 'primary',
                                  onPress: addMeasurement,
                              }
                    }
                />
            ),
        },
        pagination,
    };
};
