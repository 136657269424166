import { RecursiveModelPredicate } from '@aws-amplify/datastore';
import { Exercise } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

export interface ExercisesFilters {
    groupId?: string;
    name?: string;
}
const fetchExercises: QueryFunction<Exercise[], ['getExercises', ExercisesFilters]> = async ({ queryKey }) => {
    const [_, filters] = queryKey;

    try {
        return await DataStore.query(
            Exercise,
            (criteria) =>
                criteria.and(
                    (c) =>
                        [
                            filters.name && c.name.contains(filters.name),
                            filters.groupId && c.exercisegroupID.eq(filters.groupId),
                        ].filter(Boolean) as RecursiveModelPredicate<Exercise>[],
                ),
            {
                sort: (s) => s.name(SortDirection.ASCENDING),
            },
        );
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const useGetExercises = (filters: ExercisesFilters = {}) =>
    useQuery(['getExercises', filters], fetchExercises, { initialData: [] });
