import { Pressable } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { ActivityIconProps } from '~/components/activityIcon/ActivityIcon';

const containerTypeStylesMap: Record<
    NonNullable<ActivityIconProps['variant']>,
    FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
    positive: css`
        border-color: #58f9ab;
    `,
    default: css`
        border-color: ${({ theme }) => theme.palette.neutral['70']};
    `,
};

export const Container = styled(Pressable)<{ variant: NonNullable<ActivityIconProps['variant']> }>`
    background-color: ${({ theme }) => theme.fill.level5};
    padding: 4px;
    border-radius: 100px;
    border-width: 1px;
    justify-content: center;
    align-items: center;

    ${({ variant }) => containerTypeStylesMap[variant]}
`;
