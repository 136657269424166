import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    margin-top: 100px;
    align-items: center;
    justify-content: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin: 0;
            width: 100%;
            height: 100%;
        `}
`;

export const Icon = styled(View)`
    margin-bottom: 24px;
`;

export const Title = styled(Text)`
    margin-bottom: 16px;
    ${mixins.typography.h.medium20};
    color: ${({ theme }) => theme.text.secondary};
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
`;
