import React from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';

import * as S from './NoExercises.styled';

export const NoExercises = () => {
    const theme = useTheme();

    return (
        <S.Container>
            <S.Icon>
                <Icon name="no-results" size={35} color={theme.icon.tertiary} />
            </S.Icon>
            <S.Title>Brak ćwiczeń o podanej nazwie</S.Title>
            <S.Subtitle>Spróbuj z inną frazą wyszukiwania</S.Subtitle>
        </S.Container>
    );
};
