import { FinishedSeriesData } from '@pg/backend';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';

const resetFinishedSeriesData: MutationFunction<void, { ids: string[] }> = async ({ ids }) => {
    const promises = ids.map(async (id) => {
        const original = await DataStore.query(FinishedSeriesData, id);

        if (!original) {
            return;
        }

        await DataStore.save(
            FinishedSeriesData.copyOf(original, (updated) => {
                updated.done = false;
                updated.weight = 0;
            }),
        );
    });
    await Promise.all(promises);
};

export const useResetFinishedSeriesData = () =>
    useMutation({
        mutationKey: ['resetFinishedSeriesData'],
        mutationFn: resetFinishedSeriesData,
    });
