import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { DesktopSettingsHeading } from '~/components/desktopSettingsHeading';
import { MobileMenuTile } from '~/components/mobileMenuTile';
import { Radio } from '~/components/radio';
import { Toggle } from '~/components/toggle';
import { SEO } from '~/components/wrappers';
import { useUser } from '~/enhancers/user.context';
import { MainLayout } from '~/layouts';
import { MoreLayout } from '~/layouts/moreLayout';

import { trainingTimeoutOptions } from './SettingsScreen.constants';
import * as S from './SettingsScreen.styled';
import { SettingsSection } from './components/settingsSection';

export const SettingsScreen = () => {
    const user = useUser();
    const [selectedTrainingTimeoutOption, setSelectedTrainingTimeoutOption] = useState(user.settings?.trainingTimeout);

    useEffect(() => {
        // NOTE: set initial value after fetching settings
        if (user.settings && !selectedTrainingTimeoutOption) {
            setSelectedTrainingTimeoutOption(user.settings.trainingTimeout);
        }

        // NOTE: update settings after selectedOption change
        if (
            user.settings &&
            selectedTrainingTimeoutOption &&
            user.settings?.trainingTimeout !== selectedTrainingTimeoutOption
        ) {
            user.updateSettings({ trainingTimeout: selectedTrainingTimeoutOption });
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.settings?.trainingTimeout, selectedTrainingTimeoutOption]);

    const updateSoundMuted = useCallback(
        (soundsMuted: boolean) => {
            user.updateSettings({ soundsMuted });
        },
        [user],
    );

    const updateUsePreviousCycleWeights = useCallback(
        (usePreviousCycleWeights: boolean) => {
            user.updateSettings({ usePreviousCycleWeights });
        },
        [user],
    );

    return (
        <MainLayout>
            <SEO title="Ustawienia" />
            <MoreLayout>
                <DesktopSettingsHeading title="Ustawienia aplikacji" />

                <S.Content>
                    <SettingsSection
                        title="Odliczanie do ćwiczenia"
                        subtitle="Długość trwania odliczania przed rozpoczęciem ćwiczenia na czas."
                    >
                        <S.SettingsItemContainer>
                            {trainingTimeoutOptions.map(({ value, label }, index) => (
                                <MobileMenuTile
                                    last={index === trainingTimeoutOptions.length - 1}
                                    key={value}
                                    rightContent={
                                        <View pointerEvents="none">
                                            <Radio checked={selectedTrainingTimeoutOption === value} />
                                        </View>
                                    }
                                    onPress={() => setSelectedTrainingTimeoutOption(value)}
                                >
                                    {label}
                                </MobileMenuTile>
                            ))}
                        </S.SettingsItemContainer>
                    </SettingsSection>
                    <SettingsSection
                        title="Dźwięki podczas treningu"
                        subtitle="Dźwięki rozpoczęcia kolejnej serii, zakończenia cardio i odliczania"
                        rightContent={
                            <Toggle
                                checked={!user.settings?.soundsMuted}
                                onChange={(value) => updateSoundMuted(!value)}
                            />
                        }
                    />
                    <SettingsSection
                        title="Używaj wartości ciężarów z poprzednich treningów"
                        subtitle="Będą one wyświetlane przy wcześniej wykonanych ćwiczeniach w kolejnych treningach. "
                        rightContent={
                            <Toggle
                                checked={!!user.settings?.usePreviousCycleWeights}
                                onChange={(value) => updateUsePreviousCycleWeights(value)}
                            />
                        }
                    />
                </S.Content>
            </MoreLayout>
        </MainLayout>
    );
};
