import { TrainingDay, TrainingItemStatus } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore, SortDirection } from 'aws-amplify';

const fetchInProgressTrainingDay: QueryFunction<TrainingDay | null, ['getInProgressTrainingDay']> = async () => {
    try {
        const [inProgressTrainingDay] = await DataStore.query(
            TrainingDay,
            (criteria) => criteria.status.eq(TrainingItemStatus.IN_PROGRESS),
            {
                sort: (s) => s.date(SortDirection.DESCENDING),
                limit: 1,
            },
        );

        return inProgressTrainingDay ?? null;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const useGetInProgressTrainingDay = () => useQuery(['getInProgressTrainingDay'], fetchInProgressTrainingDay);
