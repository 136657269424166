import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { Popup } from '~/components/popup';
import { MealSubstitutesPopup } from '~/components/popup/mealSubstitutesPopup';
import { Meal } from '~/enhancers/dietContext/dietTempData';
import { usePressableState } from '~/hooks/usePressableState';
import { MealItemAnimation } from '~/screens/diet/components/mealItem/MealItem.animated';

import { MealImage } from '../mealImage';
import * as S from './MealItem.styled';
import { ActionButton } from './components/actionButton';

export interface MealItemProps {
    id: string;
    name: string;
    imageSrc?: string;
    substitutes?: Meal[];
    isTrainingMeal?: boolean;
    onPress?: () => void;
    onRemove?: () => void;
    onEdit?: () => void;
}

export const MealItem: FC<MealItemProps> = ({
    name,
    isTrainingMeal,
    imageSrc,
    substitutes,
    onPress,
    onRemove,
    onEdit,
}) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const [isMealSubstitutesPopupVisible, setIsMealSubstitutesPopupVisible] = React.useState(false);

    const handleOpenPopup = () => setIsMealSubstitutesPopupVisible(true);
    const handleClosePopup = () => setIsMealSubstitutesPopupVisible(false);

    const handleSubstitutePress = (substituteId: string) => {
        // TODO: handleSubstituteSelect
        console.log('handleSubstituteSelect', substituteId);
        handleClosePopup();
    };

    const renderEditButton = () => {
        if (!onEdit) return null;

        if (theme.isWebDesktop && substitutes?.length) {
            return (
                <Popup
                    isOpen={isMealSubstitutesPopupVisible}
                    trigger={
                        <S.ActionButtonWrapper>
                            <ActionButton
                                title="Wybierz zamiennik"
                                iconName="edit"
                                active={isMealSubstitutesPopupVisible}
                                topBorder={!!onRemove}
                                onPress={handleOpenPopup}
                            />
                        </S.ActionButtonWrapper>
                    }
                    onClose={handleClosePopup}
                    position={['left top', 'left center', 'left bottom']}
                    offsetY={-108}
                >
                    <MealSubstitutesPopup onClosePress={handleClosePopup}>
                        {substitutes.map((substitute, index) => (
                            <S.MealItemWrapper key={substitute.id} last={index === substitutes.length - 1}>
                                <MealItem
                                    id={substitute.id}
                                    name={substitute.name}
                                    imageSrc={substitute.imageSrc}
                                    onPress={() => handleSubstitutePress(substitute.id)}
                                />
                            </S.MealItemWrapper>
                        ))}
                    </MealSubstitutesPopup>
                </Popup>
            );
        }

        return <ActionButton title="Wybierz zamiennik" iconName="edit" topBorder={!!onRemove} onPress={onEdit} />;
    };

    return (
        <MealItemAnimation progress={pressableAnimationProgress}>
            {({ containerAnimationStyles }) => (
                <S.Container>
                    <S.AnimatedContainer style={containerAnimationStyles}>
                        <S.PressableContent {...pressableProps} onPress={onPress}>
                            <S.ImageContainer>
                                <S.ImageWrapper>
                                    <MealImage source={imageSrc} />
                                </S.ImageWrapper>
                                {isTrainingMeal && (
                                    <S.TrainingIcon>
                                        <S.AnimatedTrainingIcon style={containerAnimationStyles}>
                                            <Icon name="atlas-gym-outline" size={16} color={theme.icon.positive} />
                                        </S.AnimatedTrainingIcon>
                                    </S.TrainingIcon>
                                )}
                            </S.ImageContainer>
                            <S.Name>{name}</S.Name>
                        </S.PressableContent>
                        {(onRemove || onEdit) && (
                            <S.ActionsContainer>
                                {onRemove && <ActionButton title="Usuń" iconName="minus" onPress={onRemove} />}
                                {renderEditButton()}
                            </S.ActionsContainer>
                        )}
                    </S.AnimatedContainer>
                </S.Container>
            )}
        </MealItemAnimation>
    );
};
