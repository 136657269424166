import { FC } from 'react';
import { useGetTrainingRules } from '~/api/training/useGetTrainingRules';
import { Rules } from '~/components/RulesContent/RulesContent.types';
import { RulesContent } from '~/components/rulesContent';

import { DesktopModal, DesktopModalProps } from '../desktopModal';
import * as S from './TrainingRulesModal.styled';

interface TrainingRulesModalProps extends Omit<DesktopModalProps, 'children'> {
    trainingRulesId?: string | null;
}

export const TrainingRulesModal: FC<TrainingRulesModalProps> = ({ trainingRulesId, ...props }) => {
    const { data, isLoading } = useGetTrainingRules(trainingRulesId ?? '');

    return (
        <DesktopModal {...props}>
            <S.Container>
                <RulesContent content={data?.content as Rules | undefined} isLoading={isLoading} />
            </S.Container>
        </DesktopModal>
    );
};
