import React from 'react';
import { Icon, IconProps } from '~/components/icon';

import * as S from './ActivityIcon.styled';

export interface ActivityIconProps extends Pick<IconProps, 'name' | 'size'> {
    variant?: 'positive' | 'default';
}

export const ActivityIcon = ({ name, variant = 'default', size = 20 }: ActivityIconProps) => {
    return (
        <S.Container variant={variant}>
            {/* TODO Handle also numbers instead of icons */}
            <Icon name={name} size={size} />
        </S.Container>
    );
};
