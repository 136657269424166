import { isAfter, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { useGetCurrentGoal } from '~/api/goal/useGetCurrentGoal';
import { useGetUserMeasurements } from '~/api/userMeasurment/useGetUserMeasurements';
import { ProgressLineChart } from '~/components/progressLineChart';
import { SectionHeading } from '~/components/sectionHeading';
import { Tabs } from '~/components/tabs';
import { formatDate } from '~/utils/dates';

import { measurementsList, measurementsTypes } from '../../MeasurementsScreen.constants';
import { MeasureType } from '../../MeasurementsScreen.types';
import * as S from './MeasurementChart.styled';
import { dataRangeList } from './MeasurementsChart.constants';
import { DataRange } from './MeasurementsChart.types';
import { convertMeasurementsToPoint } from './measurementsChart.utils';

export const MeasurementChart = () => {
    const [selectedMeasure, setSelectedMeasure] = useState<MeasureType>('weight');
    const [selectedDataRange, setSelectedDataRange] = useState<DataRange>('all');
    const { isWebDesktop } = useTheme();
    const { data: currentGoal } = useGetCurrentGoal();
    const { data } = useGetUserMeasurements();

    const description =
        selectedDataRange === 'all'
            ? 'Od początku współpracy'
            : `Od ropoczęcia nowego celu - ${formatDate(currentGoal?.startDate)}`;

    const chartData = useMemo(() => {
        const dataPoints = data.map((measurement) => {
            return convertMeasurementsToPoint(measurement, selectedMeasure);
        });
        const startGoalDate = currentGoal?.startDate ? parseISO(currentGoal.startDate) : new Date();
        const dataForSelectedRange =
            selectedDataRange === 'all'
                ? dataPoints
                : dataPoints.filter((measurement) => {
                      return isAfter(measurement.date, startGoalDate);
                  });

        const referencePoint = {
            ...dataForSelectedRange[0],
            label: selectedDataRange === 'all' ? 'Od początku współpracy' : 'Od początku celu',
        };

        return {
            data: dataForSelectedRange,
            referencePoint: referencePoint,
        };
    }, [data, currentGoal?.startDate, selectedDataRange, selectedMeasure]);

    const renderDataRangePicker = () => (
        <S.ControlsWrapper>
            <Tabs
                tabs={dataRangeList}
                activeTab={selectedDataRange}
                wide={!isWebDesktop}
                onSelectTab={(value) => setSelectedDataRange(value as DataRange)}
            />
        </S.ControlsWrapper>
    );

    return (
        <S.Container>
            {!isWebDesktop && (
                <S.HeaderContainer>
                    <SectionHeading variant="subheader" title="Progres" description={description} />
                </S.HeaderContainer>
            )}
            <S.ProgressWrapper>
                <S.TabsContainer>
                    <S.TabsWrapper>
                        <Tabs
                            tabs={measurementsList}
                            scrollable
                            activeTab={selectedMeasure}
                            onSelectTab={(value) => setSelectedMeasure(value as MeasureType)}
                        />
                    </S.TabsWrapper>
                </S.TabsContainer>
                {isWebDesktop && renderDataRangePicker()}
            </S.ProgressWrapper>
            <S.ChartWrapper>
                <ProgressLineChart {...chartData} unit={measurementsTypes[selectedMeasure].unit} />
            </S.ChartWrapper>
            {!isWebDesktop && renderDataRangePicker()}
        </S.Container>
    );
};
