import { addDays, format, isBefore } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { DateData, LocaleConfig } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';
import { Button } from '~/components/button';
import { PressableIcon } from '~/components/pressableIcon';
import { DEFAULT_DATE_FORMAT, INITIAL_DATE, RANGE } from '~/constants/calendar';
import { usePressableState } from '~/hooks/usePressableState';

import { ShippingListDateRange } from '../../ShoppingListScreen.types';
import * as S from './ShoppingListCalendar.styled';
import { calendarTheme } from './ShoppingListCalendar.styled';
import { DateObject } from './ShoppingListCalendar.types';
import { Day } from './day';

type ShoppingListCalendarContentProps = {
    onDismiss: () => void;
    range: ShippingListDateRange;
    onChange: (range: ShippingListDateRange) => void;
    calendarWidth?: number;
};

const getMarkedDatesForRange = (range: ShippingListDateRange): DateObject => {
    const { startingDay, endingDay } = range;
    if (!startingDay || !endingDay) return {};
    const date: DateObject = {};
    let d = new Date(startingDay);
    while (isBefore(d, new Date(endingDay)) || format(d, DEFAULT_DATE_FORMAT) === endingDay) {
        date[format(d, DEFAULT_DATE_FORMAT)] = {};

        if (format(d, DEFAULT_DATE_FORMAT) === startingDay) date[format(d, DEFAULT_DATE_FORMAT)].startingDay = true;
        if (format(d, DEFAULT_DATE_FORMAT) === endingDay) date[format(d, DEFAULT_DATE_FORMAT)].endingDay = true;
        d = addDays(d, 1);
    }
    return date;
};

export const ShoppingListCalendarContent = ({
    onDismiss,
    onChange,
    range,
    calendarWidth,
}: ShoppingListCalendarContentProps) => {
    const [startingDay, setStartingDay] = useState<string | null>(range.startingDay);
    const [endingDay, setEndingDay] = useState<string | null>(range.endingDay);
    const [markedDates, setMarkedDates] = useState({});
    const theme = useTheme();
    const pressableState = usePressableState();

    const onDayPress = useCallback(
        (day: DateData) => {
            if (startingDay && isBefore(new Date(day.dateString), new Date(startingDay))) {
                setMarkedDates({});
                setEndingDay(null);
                setStartingDay(null);
                return;
            }
            if (startingDay && !endingDay) {
                const date = getMarkedDatesForRange({ startingDay, endingDay: day.dateString });

                setMarkedDates(date);
                if (theme.isWebDesktop) {
                    onChange({ startingDay: startingDay, endingDay: day.dateString });
                } else {
                    setEndingDay(day.dateString);
                }
            } else {
                setStartingDay(day.dateString);
                setEndingDay(null);
                setMarkedDates({
                    [day.dateString]: {
                        startingDay: true,
                        endingDay: true,
                    },
                });
            }
        },
        [startingDay, endingDay, theme.isWebDesktop, onChange],
    );

    useEffect(() => {
        setMarkedDates(getMarkedDatesForRange(range));
    }, [range]);

    const handleSave = () => {
        onChange({ startingDay: startingDay, endingDay: endingDay });
    };

    const renderLeftText = () => {
        if (startingDay) {
            return <S.DateText>{format(new Date(startingDay), 'EEEEEE, d MMM')}</S.DateText>;
        }

        return <S.DateNotSelectedText variant="highlighted">nie wybrano</S.DateNotSelectedText>;
    };

    const renderRightText = () => {
        if (endingDay) {
            return <S.DateText>{format(new Date(endingDay), 'EEEEEE, d MMM')}</S.DateText>;
        }

        return (
            <S.DateNotSelectedText variant={startingDay ? 'highlighted' : 'default'}>nie wybrano</S.DateNotSelectedText>
        );
    };

    return (
        <S.Wrapper>
            <S.HeaderWrapper>
                <S.IconContainer>
                    <S.IconButton onPress={onDismiss}>
                        <PressableIcon
                            name="close"
                            pressableAnimationProgress={pressableState.pressableAnimationProgress}
                            size={20}
                            color={theme.icon.tertiary}
                            pressedColor={theme.icon.tertiary}
                        />
                    </S.IconButton>
                </S.IconContainer>
                <S.Title>Wybierz datę</S.Title>
                <S.Space />
            </S.HeaderWrapper>
            <S.RangeWrapper>
                {renderLeftText()}
                <S.Dash>-</S.Dash>
                {renderRightText()}
            </S.RangeWrapper>
            <S.Container>
                <S.MonthWrapper>
                    {LocaleConfig.locales.pl.dayNamesShort.map((day: string) => (
                        <S.MonthText key={day}>{day}</S.MonthText>
                    ))}
                </S.MonthWrapper>
                <S.CalendarList
                    current={INITIAL_DATE}
                    pastScrollRange={RANGE}
                    futureScrollRange={RANGE}
                    onDayPress={onDayPress}
                    markingType="period"
                    markedDates={markedDates}
                    firstDay={1}
                    theme={calendarTheme as any}
                    hideDayNames
                    dayComponent={Day}
                    calendarWidth={calendarWidth}
                />
            </S.Container>
            {!theme.isWebDesktop && (
                <S.FooterContainer>
                    <Button fullWidth size="m" onPress={handleSave} disabled={!startingDay || !endingDay}>
                        Zapisz
                    </Button>
                </S.FooterContainer>
            )}
        </S.Wrapper>
    );
};
