import { Pressable, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

type TitleProps = {
    checked: boolean;
};

export const Wrapper = styled(Pressable)`
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
`;

export const Container = styled(View)`
    margin-left: 20px;
`;

export const Title = styled(Text)<TitleProps>`
    ${mixins.typography.ui.regular16}
    color: ${({ theme }) => theme.text.primary};

    ${({ checked }) =>
        checked &&
        css`
            text-decoration-line: line-through;
            color: ${({ theme }) => theme.text.secondary};
        `}
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular14}
    color: ${({ theme }) => theme.text.secondary};
`;
