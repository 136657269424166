import { Text } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const ErrorText = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.critical};
    margin-left: 10px;
    margin-top: 8px;
`;
