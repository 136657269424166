import { ConfirmSheetProps } from '~/components/bottomSheet/confirmSheet/ConfirmSheet.native';
import { useAuth } from '~/enhancers/auth.context';
import { useNotifications } from '~/enhancers/notifications.context';

export const useDeleteAccountConfirmSheet = (): {
    confirmSheetProps: ConfirmSheetProps;
} => {
    const auth = useAuth();
    const { addNotification } = useNotifications();

    const handleDeleteAccount = async () => {
        await auth.deleteUser();
        addNotification({
            message: 'Konto zostało usunięte.',
            type: 'informative',
        });
    };

    return {
        confirmSheetProps: {
            title: 'Czy na pewno chcesz się usunąć konto?',
            subtitle:
                'Wszystkie dane dotyczace twojego konta zostaną usunięte. Tego procesu nie da się cofnąć. Potwierdź usunięcie konta, any kontynuować.',
            icon: 'trash-outline',
            primaryButtonLabel: 'Tak, usuń konto',
            doubleConfirmation: true,
            doubleConfirmationLabel: 'Potwierdzam usunięcie konta',
            maxWidth: 640,
            secondaryButtonLabel: 'Nie',
            onConfirm: handleDeleteAccount,
        },
    };
};
