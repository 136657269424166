import { Pressable, ScrollView, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { MainLayout as MainLayoutBase } from '~/layouts';
import { mixins } from '~/theme';

import { LayoutProps } from './MoreScreen.types';

export const MainLayout = styled(MainLayoutBase)<LayoutProps>`
    background-color: ${({ theme }) => theme.fill.background};
    margin-top: ${({ inset }) => (inset?.top ? inset.top : 0)}px;
`;

export const Container = styled(View)`
    flex: 1;
`;

export const ScrollContainer = styled(ScrollView).attrs({ showsVerticalScrollIndicator: false })`
    flex: 1;
`;

export const LogoContainer = styled(View)`
    width: 100%;
    padding: 8px 16px;
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: center;
`;

export const Menu = styled(View)<{ last?: boolean }>`
    padding: 0 16px;
    margin-bottom: 32px;
`;

export const MenuHeader = styled(View)`
    padding-left: 8px;
    margin-bottom: 8px;
`;

export const MenuHeaderText = styled(Text)`
    ${mixins.typography.ui.medium12};
    color: ${({ theme }) => theme.text.tertiary};
    text-transform: uppercase;
`;

export const MenuList = styled(View)`
    background: ${({ theme }) => theme.fill.level2};
    border-radius: 8px;
    overflow: hidden;
`;

export const AppInfoContainer = styled(View)`
    width: 100%;
    padding: 0 20px 20px;
`;

export const AppInfo = styled(Text)`
    ${mixins.typography.ui.regular11};
    color: ${({ theme }) => theme.text.disabled};
`;

export const AppInfoLink = styled(Pressable)`
    text-decoration: underline;
`;

export const AppInfoLinks = styled(View)`
    flex-direction: row;
    margin-top: 8px;
`;
