import { FC } from 'react';
import { Keyboard, Pressable } from 'react-native';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { TopNavWrapper } from '~/components/wrappers';
import { usePressableState } from '~/hooks/usePressableState';
import { chatTypeContentMap } from '~/utils/chatTypeContentMap';

import * as S from './ChatTopNavigation.styled';
import { ChatScreenProps } from './ChatTopNavigation.types';

interface ChatTopNavigationProps extends ChatScreenProps {
    isBackButton?: boolean;
    isKeyboard?: boolean;
}

export const ChatTopNavigation: FC<ChatTopNavigationProps> = ({ isBackButton, isKeyboard, navigation, route }) => {
    const theme = useTheme();
    const { pressableProps: pressableBackProps, pressableAnimationProgress: pressableBackAnimationProgress } =
        usePressableState();

    const { pressableProps: pressableKeyboardProps, pressableAnimationProgress: pressableKeyboardProgress } =
        usePressableState();

    const handleNavigationBack = () => {
        navigation.navigate('ChatSelection');
    };
    const chatTypeContent = chatTypeContentMap[route?.params?.chatType];
    const chatTypeName = chatTypeContent && `${chatTypeContent.title}`;
    const chatTypeText = chatTypeName && (
        <>
            <S.Slash>/</S.Slash>
            {`${chatTypeName}`}
        </>
    );

    const leftContent = (
        <S.LeftWrapper>
            {isBackButton && (
                <S.BackButton {...pressableBackProps} onPress={handleNavigationBack}>
                    <PressableIcon
                        pressedColor={theme.icon.primary}
                        color={theme.icon.tertiary}
                        pressableAnimationProgress={pressableBackAnimationProgress}
                        name={'chevron-big-left'}
                        size={20}
                    />
                </S.BackButton>
            )}
            <S.Title>Czat{chatTypeText}</S.Title>
        </S.LeftWrapper>
    );

    const rightContent = (
        <Pressable {...pressableKeyboardProps} onPress={() => Keyboard.dismiss()}>
            <PressableIcon
                pressableAnimationProgress={pressableKeyboardProgress}
                color={theme.icon.tertiary}
                pressedColor={theme.icon.primary}
                name="keyboard-hide"
                size={20}
            />
        </Pressable>
    );
    return <TopNavWrapper leftContent={leftContent} rightContent={isKeyboard && rightContent} variant="leftTitle" />;
};
