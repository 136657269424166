import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const FloatingLabel = styled(Text)<{ withIcon?: boolean; disabled?: boolean; error?: boolean }>`
    ${mixins.typography.ui.regular16};
    top: 15px;
    flex: 1;
    position: absolute;
    z-index: 2;
    color: ${({ theme }) => theme.text.tertiary};
    left: ${({ withIcon }) => (withIcon ? '40px' : '12px')};
    pointer-events: none;
    color: ${({ theme }) => theme.text.tertiary};
    background: ${({ theme, disabled }) => (disabled ? theme.fill.level2 : theme.fill.background)};

    ${({ theme, error }) =>
        error &&
        css`
            color: ${theme.text.critical};
        `};

    ${({ theme, disabled }) =>
        disabled &&
        css`
            color: ${theme.text.disabled};
        `};
`;

export const FloatingLabelMask = styled(View)<{ disabled: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 18px;
    width: 100%;
    z-index: 1;
    background: ${({ theme, disabled }) => (disabled ? theme.fill.level2 : theme.fill.background)};
`;
