import { Pressable, Text, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { mixins } from '~/theme';

import { ToggleSize, ToggleStyledProps } from './Toggle.types';

export const Container = styled(Pressable)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const sizeStylesMap: Record<ToggleSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    s: css`
        height: 12px;
        width: 20px;
        border-radius: 6px;
    `,
    m: css`
        height: 20px;
        width: 32px;
        border-radius: 10px;
    `,
    l: css`
        height: 28px;
        width: 46px;
        border-radius: 14px;
    `,
};

export const ControlContainer = styled(View)<ToggleStyledProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.fill.background};
    shadow-color: #000000;
    shadow-opacity: 0.2;
    shadow-radius: 2px;
    shadow-offset: 0 1px;

    ${({ size }) => sizeStylesMap[size]};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `};
`;

export const Border = styled(View)<Pick<ToggleStyledProps, 'size'>>`
    position: absolute;
    top: 0;
    left: 0;
    border-width: 2px;
    border-style: solid;

    ${({ size }) => sizeStylesMap[size]};
`;

const dotSizeStylesMap: Record<ToggleSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    s: css`
        height: 6px;
        width: 6px;
        border-radius: 3px;
        top: 3px;
        left: 3px;
    `,
    m: css`
        height: 12px;
        width: 12px;
        border-radius: 6px;
        top: 4px;
        left: 4px;
    `,
    l: css`
        height: 18px;
        width: 18px;
        border-radius: 9px;
        top: 5px;
        left: 5px;
    `,
};

export const Dot = styled(View)<Pick<ToggleStyledProps, 'size'>>`
    position: absolute;

    ${({ size }) => dotSizeStylesMap[size]};
`;

export const Background = styled(View)<Pick<ToggleStyledProps, 'size'>>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${({ size }) => sizeStylesMap[size]};
`;

const labelSizeStylesMap: Record<ToggleSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    s: css`
        ${mixins.typography.ui.regular12}
        margin-left: 8px;
    `,
    m: css`
        ${mixins.typography.ui.regular14}
        margin-left: 12px;
    `,
    l: css`
        ${mixins.typography.ui.regular14}
        margin-left: 12px;
    `,
};
export const Label = styled(Text)<Pick<ToggleStyledProps, 'size'>>`
    color: ${({ theme }) => theme.text.primary};

    ${({ size }) => labelSizeStylesMap[size]};
`;
