import yup from '~/utils/validation';

export const ChangePasswordFormSchema = yup.object().shape({
    newPassword: yup.string().min(8, 'Hasło musi składać się z co najmniej 8 znaków.').required(),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Hasła nie pasują do siebie.')
        .required(),
    oldPassword: yup.string().required(),
});
