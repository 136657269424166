import React from 'react';
import uuid from 'react-native-uuid';
import { MainLayout } from '~/layouts/native';

import { MealItem } from '../components/mealItem';
import * as S from './MealSubstituteScreen.styled';

export const MealSubstituteScreenContent = () => {
    return (
        <S.MealsList>
            <S.MealItemWrapper>
                <MealItem
                    id={uuid.v4() as string}
                    name="Kanapki z hummusem, szynką i warzywami"
                    imageSrc="https://images.unsplash.com/photo-1481070414801-51fd732d7184?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGh1bW11cyUyMHNhbmR3aWNofGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60"
                />
            </S.MealItemWrapper>
            <S.MealItemWrapper>
                <MealItem
                    id={uuid.v4() as string}
                    name="Makaron penne z kurczakiem, szpinakiem, cukinią i papryką"
                    imageSrc="https://images.unsplash.com/photo-1555949258-eb67b1ef0ceb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3570&q=80"
                />
            </S.MealItemWrapper>
            <S.MealItemWrapper>
                <MealItem
                    id={uuid.v4() as string}
                    name="Bananowa owsianka z malinami"
                    imageSrc="https://images.unsplash.com/photo-1584970922591-5b91e5f927a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8b2F0bWVhbHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60"
                />
            </S.MealItemWrapper>
            <S.MealItemWrapper>
                <MealItem
                    id={uuid.v4() as string}
                    name="Jaglanka na mleku roślinnym z gruszką i jabłkiem"
                    imageSrc="https://images.unsplash.com/photo-1583687463124-ff2aa00b1160?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fG9hdG1lYWx8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60"
                />
            </S.MealItemWrapper>
            <S.MealItemWrapper last>
                <MealItem id={uuid.v4() as string} name="Owsianka Mars" />
            </S.MealItemWrapper>
        </S.MealsList>
    );
};

export const MealSubstituteScreen = () => {
    return (
        <MainLayout>
            <MealSubstituteScreenContent />
        </MainLayout>
    );
};
