import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/loader';
import { PortionCounter } from '~/components/portionCounter';
import { SectionHeading } from '~/components/sectionHeading';
import { Tabs } from '~/components/tabs';
import { MainLayout } from '~/layouts/native';
import { MealScreenProps } from '~/screens/diet/meal/MealScreen.types';
import { Ingredient } from '~/screens/diet/meal/components/ingredient';
import { Step } from '~/screens/diet/meal/components/step';

import { MealImage } from '../components/mealImage';
import { MealAnimations } from './MealScreen.animated';
import * as S from './MealScreen.styled';
import { MacroTile } from './components/macroTile';
import { useMeal } from './hooks/useMeal';

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);
const AnimatedTitle = Animated.createAnimatedComponent(S.Title);
const AnimatedSpacing = Animated.createAnimatedComponent(S.Spacing);

export const MealScreenContent = () => {
    const [portionsCount, setPortionsCount] = useState(1);
    const [bounces, setBounces] = useState(false);
    const theme = useTheme();
    const route = useRoute<MealScreenProps['route']>();
    const id = route.params?.mealId;
    const { meal, isLoading, isError, tabs, activeTab, setActiveTab } = useMeal({ id });

    const handleOnScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const scrollPosition =
            event && event.nativeEvent && event.nativeEvent.contentOffset && event.nativeEvent.contentOffset.y;

        const newBouncesValue = scrollPosition >= 50;

        if (newBouncesValue === bounces) {
            return;
        }

        setBounces(newBouncesValue);
    };

    const renderContent = () => {
        if (isError) {
            return (
                <S.CenterContainer>
                    <S.ErrorMessage>Nie znaleziono posiłku.</S.ErrorMessage>
                </S.CenterContainer>
            );
        }

        if (isLoading || !meal) {
            // TODO: Add skeleton
            return (
                <S.CenterContainer>
                    <Loader />
                </S.CenterContainer>
            );
        }

        return (
            <MealAnimations>
                {({ animatedRef, styles }) => (
                    <AnimatedScrollView
                        ref={animatedRef}
                        bounces={bounces}
                        onScroll={handleOnScroll}
                        showsVerticalScrollIndicator={false}
                        scrollEventThrottle={16}
                        stickyHeaderIndices={[1]}
                    >
                        <S.MealImageWrapper>
                            <MealImage
                                source={meal.imageSrc}
                                backgroundColor={theme.fill.level2}
                                foregroundColor={theme.fill.level3}
                            />
                        </S.MealImageWrapper>
                        <S.Header>
                            <AnimatedSpacing style={styles.spacingAnimatedStyles} />
                            <S.HeadingContainer>
                                <AnimatedTitle style={styles.titleAnimatedStyles}>{meal.name}</AnimatedTitle>
                            </S.HeadingContainer>
                            <AnimatedSpacing style={styles.spacingAnimatedStyles} />
                            <S.PortionContainer>
                                <PortionCounter value={portionsCount} maxValue={10} onChange={setPortionsCount} />
                            </S.PortionContainer>
                            <S.TabsContainer>
                                <AnimatedSpacing style={styles.spacingAnimatedStyles} />
                                <AnimatedSpacing style={styles.spacingAnimatedStyles} />
                                <S.TabsWrapper>
                                    <Tabs
                                        wide
                                        appearance={'secondary'}
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        onSelectTab={setActiveTab}
                                    />
                                </S.TabsWrapper>
                            </S.TabsContainer>
                        </S.Header>
                        <S.MacrosContainer>
                            {Object.values(meal.macros).map((macro, index) => (
                                <S.MacroTileWrapper
                                    key={macro.label}
                                    last={Object.values(meal.macros).length - 1 === index}
                                >
                                    <MacroTile
                                        value={macro.value * portionsCount}
                                        label={macro.label}
                                        unit={'unit' in macro ? macro.unit : undefined}
                                    />
                                </S.MacroTileWrapper>
                            ))}
                        </S.MacrosContainer>
                        <S.IngredientsContainer>
                            <SectionHeading variant="subheader" title="Składniki" />
                            {meal.ingredients.map((ingredient) => (
                                <S.IngredientWrapper key={ingredient.name}>
                                    <Ingredient
                                        name={ingredient.name}
                                        amount={{
                                            value: ingredient.amount.value * portionsCount,
                                            unit: ingredient.amount.unit,
                                        }}
                                    />
                                </S.IngredientWrapper>
                            ))}
                        </S.IngredientsContainer>
                        <S.StepsContainer>
                            <SectionHeading variant="subheader" title="Sposób przygotowania" />
                            {meal.steps.map((step, index) => (
                                <S.StepWrapper key={`step-${index}`}>
                                    <Step index={index + 1} content={step} />
                                </S.StepWrapper>
                            ))}
                        </S.StepsContainer>
                    </AnimatedScrollView>
                )}
            </MealAnimations>
        );
    };

    return renderContent();
};

export const MealScreen = () => (
    <MainLayout fullWidth verticalSpacing={false} scrollable={false}>
        <MealScreenContent />
    </MainLayout>
);
