import { View } from 'react-native';
import styled from 'styled-components/native';
import { ModalCloseButton } from '~/components/modalCloseButton';
import { defaultTheme } from '~/theme';

export const popupStyles = {
    overlayStyle: {
        backgroundColor: defaultTheme.fill.background80,
        padding: 40,
        overflow: 'hidden auto',
    },
    contentStyle: {
        display: 'flex',
        justifyContent: 'center',
    },
};

export const Container = styled(View)`
    height: auto;
    align-items: center;
`;

export const Children = styled(View)`
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.palette.opacity[12].rgba};
    background-color: ${({ theme }) => theme.fill.level1};
    width: fit-content;
`;

export const ScrollContainer = styled(View)`
    max-height: 90vh;
    overflow: auto;
`;

export const CloseButton = styled(ModalCloseButton)`
    position: absolute;
    top: -16px;
    right: -16px;
`;
