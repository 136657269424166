import React, { FC } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { TrainingDayButtonAnimation } from './TrainingDayButton.animated';
import * as S from './TrainingDayButton.styled';

interface TrainingDayButtonProps {
    onPress: () => void;
}

const AnimatedButton = Animated.createAnimatedComponent(S.Button);
const AnimatedTitle = Animated.createAnimatedComponent(S.Title);
const AnimatedSubtitle = Animated.createAnimatedComponent(S.Subtitle);

export const TrainingDayButton: FC<TrainingDayButtonProps> = ({ onPress }) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    return (
        <TrainingDayButtonAnimation progress={pressableAnimationProgress}>
            {({ containerAnimationStyles, titleAnimationStyles, subtitleAnimationStyles }) => (
                <S.Container>
                    <AnimatedButton style={containerAnimationStyles} {...pressableProps} onPress={onPress}>
                        <S.IconWrapper>
                            <PressableIcon
                                name="add"
                                size={20}
                                color={theme.icon.tertiary}
                                pressedColor={theme.icon.primary}
                                pressableAnimationProgress={pressableAnimationProgress}
                            />
                        </S.IconWrapper>
                        <S.Content>
                            <AnimatedTitle style={titleAnimationStyles}>Oznacz jako dzień treningowy</AnimatedTitle>
                            <AnimatedSubtitle style={subtitleAnimationStyles}>
                                I dodaj przekąskę okołotreningową
                            </AnimatedSubtitle>
                        </S.Content>
                    </AnimatedButton>
                </S.Container>
            )}
        </TrainingDayButtonAnimation>
    );
};
