import React from 'react';
import { IconName } from '~/icons';

import { ActivityButton } from '../activityButton';
import * as S from './PrevNextControls.styled';
import { PrevNextControlsStyledProps } from './PrevNextControls.types';

export interface PrevNextControlsProps extends PrevNextControlsStyledProps {
    prevIcon?: IconName;
    nextIcon?: IconName;
    label?: string | number;
    prevDisabled?: boolean;
    nextDisabled?: boolean;
    onPrevPress: () => void;
    onNextPress: () => void;
}

export const PrevNextControls: React.FC<PrevNextControlsProps> = ({
    prevIcon = 'chevron-left',
    nextIcon = 'chevron-right',
    prevDisabled,
    nextDisabled,
    label,
    onPrevPress,
    onNextPress,
    ...props
}) => (
    <S.Container {...props}>
        <S.PrevButton>
            <ActivityButton icon={prevIcon} disabled={prevDisabled} onPress={onPrevPress} />
        </S.PrevButton>
        {label !== undefined && <S.Label>{label}</S.Label>}
        <S.NextButton>
            <ActivityButton icon={nextIcon} disabled={nextDisabled} onPress={onNextPress} />
        </S.NextButton>
    </S.Container>
);
