import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface InstructionButtonAnimationsRenderPropValue {
    labelAnimatedStyles: {
        color: string;
    };
}

export interface InstructionButtonAnimationsProps
    extends PressableAnimationsProps<InstructionButtonAnimationsRenderPropValue> {}

export const InstructionButtonAnimations = ({ progress, children }: InstructionButtonAnimationsProps) => {
    const theme = useTheme();

    const hoverableProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const labelAnimatedStyles = useAnimatedStyle(() => ({
        color: interpolateColor(
            hoverableProgress.value,
            [0, 1, 2],
            [theme.icon.secondary, theme.icon.primary, theme.icon.primary],
        ),
    }));

    return children({ labelAnimatedStyles });
};
