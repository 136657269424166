import { SharedValue, interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { AnimationsProps } from '~/types/animations';

import { dotWidth, separatorWidth } from './Dots.styled';

export interface DotsAnimationsRenderPropValue {
    activeDotAnimatedStyles: {
        transform: { translateX: number }[];
    };
}

export interface DotsAnimationsProps extends AnimationsProps<DotsAnimationsRenderPropValue> {
    scrollProgress: SharedValue<number>;
}

export const DotsAnimations = ({ scrollProgress, children }: DotsAnimationsProps) => {
    const activeDotAnimatedStyles = useAnimatedStyle(() => {
        const translateX = interpolate(scrollProgress.value, [0, 1], [0, dotWidth + separatorWidth]);

        return {
            transform: [{ translateX }],
        };
    });

    return children({ activeDotAnimatedStyles });
};
