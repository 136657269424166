import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Wrapper = styled(View)`
    padding: 12px 32px;
    display: none;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        `}
`;

export const SideContentWrapper = styled(View)`
    padding: 8px 0;
`;
