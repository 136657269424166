import { TrainingDay, TrainingItemStatus, TrainingStatus } from '@pg/backend';
import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { ListRenderItemInfo } from '@shopify/flash-list/src/FlashListProps';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { SEO } from '~/components/wrappers';
import { useTraining } from '~/enhancers/training.context';
import { useTrainingPlan } from '~/enhancers/trainingPlanContext/trainingPlan.context';
import { useRefresh } from '~/hooks/useRefresh';
import { TrainingScreenContextProvider } from '~/screens/training/TrainingScreen.context';
import { TrainingContent } from '~/screens/training/components/trainingContent';
import { TrainingAxisCell } from '~/screens/trainingPlan/components/trainingAxisCell';
import { TrainingAxisHeader } from '~/screens/trainingPlan/components/trainingAxisHeader';

import * as S from './TrainingPlanScreen.styled';
import { WebDesktopDraftTitleWrapper } from './TrainingPlanScreen.styled';
import { TrainingHeader, TrainingPlanNavigationProp, TransformedDataItem } from './TrainingPlanScreen.types';
import { transformData } from './TrainingPlanScreen.utils';
import { TrainingPlanHeader } from './components/trainingPlanHeader';
import { TrainingPlanInProgress } from './components/trainingPlanInProgress';
import { TrainingPlanSelection } from './components/trainingPlanSelection';

const isTraining = (trainingOrHeader: TrainingHeader | TrainingDay): trainingOrHeader is TrainingDay => {
    return 'id' in trainingOrHeader;
};

export const TrainingPlanScreen = () => {
    const flashlistRef = useRef<FlashList<TransformedDataItem>>(null);
    const navigation = useNavigation<TrainingPlanNavigationProp>();
    const training = useTraining();
    const isDraft = training.data?.status === TrainingStatus.DRAFT;
    const { trainingCycles, nextTrainingDay, nextTrainingCycle, isRefetching, isFetching, refetch } = useTrainingPlan();

    const transformedData: TransformedDataItem[] = useMemo(() => transformData(trainingCycles), [trainingCycles]);
    const { refresh, isRefreshing } = useRefresh(refetch, isRefetching);

    const { isWeb, isWebDesktop } = useTheme();
    const [selectedTrainingDayId, setSelectedTrainingDayId] = useState<string | null>(null);
    const defaultWebTrainingDayAlreadySet = useRef(false);
    const [enableStickyHeaders, setEnableStickyHeaders] = useState(false);

    useEffect(() => {
        setEnableStickyHeaders(false);
    }, [training.id]);

    useEffect(() => {
        if (isWebDesktop && nextTrainingDay && !defaultWebTrainingDayAlreadySet.current) {
            setSelectedTrainingDayId(nextTrainingDay.id);
            defaultWebTrainingDayAlreadySet.current = true;
        }
    }, [nextTrainingDay, isWebDesktop]);

    const stickyHeaderIndices =
        enableStickyHeaders && !isWeb
            ? (transformedData
                  ?.map((item, index) => {
                      return !isTraining(item) ? index : undefined;
                  })
                  .filter((index) => index !== undefined) as number[])
            : undefined;

    const handleTrainingNavigation = (trainingDayId: string) => {
        if (isWebDesktop) {
            setSelectedTrainingDayId(trainingDayId);
            return;
        }

        navigation.navigate('TrainingStack', {
            screen: 'Training',
            params: { trainingDayId },
        });
    };

    const renderItem = ({ item }: ListRenderItemInfo<TransformedDataItem>) => {
        if (isTraining(item)) {
            return (
                <TrainingAxisCell
                    id={item.id}
                    title={item.title}
                    status={item.status}
                    date={item.date}
                    last={item.last}
                    finishedSeries={item.finishedSeries}
                    selected={item.id === selectedTrainingDayId}
                    onPress={() => handleTrainingNavigation(item.id)}
                />
            );
        }

        return <TrainingAxisHeader title={item?.title} />;
    };

    const renderHeader = () => {
        if (!nextTrainingDay || !nextTrainingCycle) {
            return null;
        }

        const { status, id } = nextTrainingDay;
        const statusLabel = status === TrainingItemStatus.IN_PROGRESS ? 'kontynuuj' : 'czas na trening';

        return (
            <TrainingPlanHeader
                label={nextTrainingDay?.title}
                description={`${nextTrainingCycle.shortTitle} - ${statusLabel}`}
                trainingDayId={id}
                trainingRulesId={training.data?.trainingrulesID}
            />
        );
    };

    const renderContent = () => {
        if ((isDraft || !training.id) && !isWebDesktop) {
            return <TrainingPlanInProgress />;
        }

        if ((isDraft || !training.id) && isWebDesktop) {
            return (
                <>
                    {training.id && (
                        <S.ListWrapper>
                            <WebDesktopDraftTitleWrapper>
                                <TrainingPlanHeader />
                            </WebDesktopDraftTitleWrapper>
                            <TrainingPlanSelection />
                        </S.ListWrapper>
                    )}
                    <TrainingPlanInProgress />
                </>
            );
        }

        return (
            <>
                <S.ListWrapper>
                    <FlashList
                        key={training.id}
                        data={transformedData}
                        renderItem={renderItem}
                        disableAutoLayout={true}
                        showsVerticalScrollIndicator={false}
                        stickyHeaderIndices={stickyHeaderIndices}
                        ListHeaderComponent={renderHeader()}
                        estimatedItemSize={62}
                        getItemType={(item) => (isTraining(item) ? 'row' : 'sectionHeader')}
                        onScroll={() => {
                            if (!enableStickyHeaders) setEnableStickyHeaders(true);
                        }}
                        ref={flashlistRef}
                        extraData={{ selectedTrainingDayId }}
                        onRefresh={refresh}
                        refreshing={isRefreshing}
                    />
                    <TrainingPlanSelection />
                </S.ListWrapper>
            </>
        );
    };

    return (
        <S.MainLayout isLoading={isFetching && !isRefreshing}>
            <SEO title="Plan Treningowy" />
            {renderContent()}

            {isWebDesktop && selectedTrainingDayId && !isDraft && (
                <TrainingScreenContextProvider trainingDayId={selectedTrainingDayId}>
                    <TrainingContent trainingRulesId={training.data?.trainingrulesID} />
                </TrainingScreenContextProvider>
            )}
        </S.MainLayout>
    );
};
