import { TrainingItemStatus } from '@pg/backend';
import { TrainingPlanData } from '~/api/training/useGetTrainingDaysWithGroupsByTraining';

import { TrainingCycle, TrainingDay } from './trainingPlan.types';

export const getTrainingCycles = (data: TrainingPlanData | null): TrainingCycle[] => {
    const groupedByCycle =
        data?.data.reduce((acc, item) => {
            const newItem = { ...item } as TrainingDay;
            const { cycle } = newItem;

            //Count finished series for finished training days
            if (item.status !== TrainingItemStatus.NOT_STARTED) {
                const itemGroupsIds = data?.trainingDayGroups
                    .filter((group) => group.trainingdayID === item.id)
                    .map((group) => group.id);

                const trainingExercises = data?.trainingExercises.filter((exercise) =>
                    itemGroupsIds?.includes(exercise.trainingdaygroupID),
                );

                const trainingExercisesIds = trainingExercises.map((i) => i.id);

                const cardioTrainingExercises = data?.cardioExercises.filter((exercise) =>
                    itemGroupsIds?.includes(exercise.trainingdaygroupID),
                );

                const finishedSeries = data?.finishedSeries.filter((series) =>
                    trainingExercisesIds.includes(series.trainingexerciseID),
                );

                const sumOfSeries = trainingExercises.reduce((acc, exercise) => {
                    const { trainingExerciseExerciseDataId } = exercise;
                    const { series } = data?.trainingExercisesDataMap?.[trainingExerciseExerciseDataId!] ?? {};
                    return acc + (series ?? 0);
                }, 0);
                const sumOfFinishedSeries = finishedSeries?.filter((series) => series.done)?.length;

                const sumOfFinishedCardioExercises =
                    cardioTrainingExercises.filter((exercise) => {
                        const finishedId = exercise.trainingCardioExerciseFinishedCardioExerciseDataId;
                        if (!finishedId) {
                            return false;
                        }

                        return (data?.finishedCardioExercisesMap?.[finishedId]?.time ?? 0) > 0;
                    })?.length ?? 0;

                newItem.finishedSeries = `${sumOfFinishedSeries + sumOfFinishedCardioExercises}/${
                    sumOfSeries + cardioTrainingExercises.length
                }`;
            }

            if (!acc[cycle!]) {
                acc[cycle!] = [newItem];
            } else {
                acc[cycle!].push(newItem);
            }

            return acc;
        }, {} as Record<string, TrainingDay[]>) ?? {};

    return Object.keys(groupedByCycle).reduce((acc, key, index) => {
        const isDeloadCycle = index > 0 && index === Object.keys(groupedByCycle).length - 1;
        const title = !isDeloadCycle ? `Mikrocykl ${Number(key) + 1}` : 'Tydzień roztrenowania';
        const shortTitle = !isDeloadCycle ? `Mikrocykl ${Number(key) + 1}` : 'Tydz. roztrenowania';
        const days: TrainingDay[] = groupedByCycle[key]
            .sort((a, b) => a.order - b.order)
            .map((day, index) => ({
                ...day,
                title: !isDeloadCycle ? `Trening ${day.order + 1}` : `Roztrenowanie ${index + 1}`,
                isDeload: isDeloadCycle,
                last: index === groupedByCycle[key].length - 1,
            }));

        acc.push({
            title,
            shortTitle,
            days,
        });

        return acc;
    }, [] as TrainingCycle[]);
};

export const getNextTrainingDay = (data: TrainingPlanData | null, returnFirstWhenUndefined: boolean) => {
    const orderedData = data?.data.sort((a, b) => a.order - b.order) ?? [];
    const nextInProgressTrainingDay = orderedData.find((item) => item.status === TrainingItemStatus.IN_PROGRESS);

    if (nextInProgressTrainingDay) {
        return nextInProgressTrainingDay;
    }

    const firstNotStartedDay = orderedData.find((item) => item.status === TrainingItemStatus.NOT_STARTED);

    if (firstNotStartedDay) {
        return firstNotStartedDay;
    }

    if (returnFirstWhenUndefined) {
        return orderedData[0];
    }

    return undefined;
};
