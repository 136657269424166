import { TableHeader } from '~/components/table/Table.types';

export const calculateCellWidth = (widthPercent: number, screenWidth: number) => (widthPercent / 100) * screenWidth;

export const getStickyCellWidth = (header: TableHeader, screenWidth: number) => {
    const cellWidth = calculateCellWidth(header.width, screenWidth);
    const cellMinWidth = header.minWidth;
    const cellMaxWidth = header.maxWidth;

    if (cellMaxWidth && cellWidth > cellMaxWidth) return cellMaxWidth;

    if (cellMinWidth && cellWidth < cellMinWidth) return cellMinWidth;

    return cellWidth;
};

export const getCellPropsFromHeader = (header: TableHeader, screenWidth: number, isDesktop: boolean) => ({
    alignment: header.alignment,
    width: isDesktop ? header.width : calculateCellWidth(header.width, screenWidth),
    maxWidth: header.maxWidth,
    minWidth: header.minWidth,
});
