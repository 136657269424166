import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { FadeAnimation } from '~/components/FadeAnimation';
import { Icon } from '~/components/icon';
import { useNotifications } from '~/enhancers/notifications.context';

import { DEFAULT_CONFIG } from './Notification.constants';
import * as S from './Notification.styled';
import { NotificationsProps } from './Notification.types';

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const Notification: React.FC<NotificationsProps> = ({ index, id, config }) => {
    const { removeNotification } = useNotifications();
    const theme = useTheme();
    const timeout = useRef<NodeJS.Timeout>();

    const notificationConfig = useMemo(
        () => ({
            ...DEFAULT_CONFIG,
            ...config,
        }),
        [config],
    );

    const shouldBeAutoClosed = useMemo(
        () => !index && notificationConfig.autoClose,
        [index, notificationConfig.autoClose],
    );

    useEffect(() => {
        if (shouldBeAutoClosed && !theme.isWeb) {
            timeout.current = setTimeout(() => {
                removeNotification(id);
            }, notificationConfig.visibilityTime);
        }
        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldBeAutoClosed]);

    const handleExitingEnd = useCallback(() => {
        if (shouldBeAutoClosed && theme.isWeb) {
            removeNotification(id);
        }
    }, [id, removeNotification, shouldBeAutoClosed, theme.isWeb]);

    const handlePress = useCallback(() => {
        clearTimeout(timeout.current);
        removeNotification(id);
        notificationConfig.onPress?.();
    }, [id, notificationConfig, removeNotification]);

    return (
        <FadeAnimation
            direction={theme.isWebDesktop ? 'up' : 'down'}
            webExitingDelay={notificationConfig.visibilityTime}
            onExitingEnd={handleExitingEnd}
        >
            {(animationConfig) => (
                <AnimatedContainer
                    index={index}
                    type={notificationConfig.type}
                    onPress={handlePress}
                    position={theme.isWebDesktop ? 'bottom' : 'top'}
                    {...animationConfig}
                >
                    {config.icon && (
                        <S.IconWrapper>
                            <Icon
                                size={theme.isWebDesktop ? 32 : 24}
                                color={
                                    config.type === 'success'
                                        ? theme.text.positiveSurface
                                        : theme.text.interactiveSurface
                                }
                                name={config.icon}
                            />
                        </S.IconWrapper>
                    )}
                    <S.Message type={notificationConfig.type!}>{notificationConfig.message}</S.Message>
                </AnimatedContainer>
            )}
        </FadeAnimation>
    );
};
