import { useEffect, useState } from 'react';

export const useRefresh = (onRefresh: () => void, isRefetching: boolean) => {
    const [isRefreshing, setIsRefreshing] = useState(false);

    useEffect(() => {
        setIsRefreshing(isRefetching);
    }, [isRefetching]);

    const refresh = async () => {
        setIsRefreshing(true);
        onRefresh();
    };

    return {
        isRefreshing: isRefreshing || isRefetching,
        refresh,
    };
};
