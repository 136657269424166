import { StorageAccessLevel } from '@aws-amplify/storage';
import { API, Auth } from 'aws-amplify';

const base64ToBlob = (base64: string, mimeType: string) => {
    const [_, data] = base64.split('base64,');
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
};

const uriToBlob = async (uri: string) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
};

export const uploadFile = async ({
    key,
    filePath,
    accessLevel,
    type,
    mimeType,
}: {
    key: string;
    filePath: string;
    accessLevel: StorageAccessLevel;
    mimeType: string;
    type?: 'base64' | 'uri';
}) => {
    const fileName = key.split('/').pop();

    const fileBlob: Blob = type === 'base64' ? base64ToBlob(filePath, mimeType) : await uriToBlob(filePath);

    const file = new File([fileBlob], fileName!, { type: mimeType });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('accessLevel', accessLevel);
    formData.append('assetPath', key);

    await API.post('userStorage', '/upload', {
        body: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
    });
};
