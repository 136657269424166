import { useCallback } from 'react';
import { SectionHeading } from '~/components/sectionHeading';
import { SEO } from '~/components/wrappers';
import { useNotifications } from '~/enhancers/notifications.context';

import { DesktopModal, DesktopModalProps } from '../desktopModal';
import * as S from './measurementModal.styled';

export interface MeasurementModalProps extends Omit<DesktopModalProps, 'children'> {
    measurementId?: string;
}

export const MeasurementModal: React.FC<MeasurementModalProps> = (props) => {
    const { addNotification } = useNotifications();
    const { measurementId, onClose } = props;

    const title = measurementId ? 'Edytuj pomiar' : 'Dodaj pomiar';
    const successMessage = measurementId ? 'Pomyślnie zaktualizowano pomiar' : 'Pomyślnie dodano pomiar';

    const handleSave = useCallback(() => {
        addNotification({
            type: 'success',
            message: successMessage,
            icon: 'check-fill',
        });
        onClose?.();
    }, [addNotification, onClose, successMessage]);

    return (
        <DesktopModal {...props}>
            <SEO title={title} />
            <S.Container>
                <S.TitleContainer>
                    <SectionHeading variant="header" title={title} />
                </S.TitleContainer>
                <S.MeasurementForm measurementId={measurementId} onSave={handleSave} />
            </S.Container>
        </DesktopModal>
    );
};
