import { Image, Pressable, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { AvatarAccountProps } from '~/components/avatarAccount/types';

export const Container = styled(Pressable)<Pick<AvatarAccountProps, 'premium'>>`
    position: relative;
`;
export const ContentContainer = styled(View)<Pick<AvatarAccountProps, 'premium'>>`
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 2px solid ${({ theme, premium }) => (premium ? theme.border.interactive : theme.border.critical)};
    background: ${({ theme }) => theme.fill.level2};
    justify-content: center;
    align-items: center;
    overflow: hidden;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 72px;
            height: 72px;
            border-radius: 36px;
            border-width: 3px;
        `}
`;

export const AvatarImage = styled(Image)`
    width: 100%;
    height: 100%;
`;

export const PlaceholderContainer = styled(View)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
`;

export const Badge = styled(View)`
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 20px;
    border: 2px solid ${({ theme }) => theme.fill.level1};
    background: ${({ theme }) => theme.fill.informative};
    border-radius: 100px;
    position: absolute;
    top: 36px;
    left: 8px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 48px;
            height: 30px;
            border-width: 3px;
            left: 12px;
            top: 54px;
        `}
`;
