import { FC } from 'react';
import Animated, { interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { TrainingTimerType } from '~/components/bottomSheet/trainingTimerSheet/TrainingTimerSheet.types';
import { Button } from '~/components/button';
import { Icon } from '~/components/icon';

import { useBottomSheet } from '../../bottomSheetModal';
import * as S from './TrainingTimerSheetCollapsedContent.styled';

interface TrainingTimerSheetCollapsedContentProps {
    type: TrainingTimerType;
    time: string;
    closeButtonLabel: string;
    onClose: () => void;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const TrainingTimerSheetCollapsedContent: FC<TrainingTimerSheetCollapsedContentProps> = ({
    type,
    time,
    closeButtonLabel,
    onClose,
}) => {
    const { animatedIndex } = useBottomSheet();

    const containerAnimatedStyle = useAnimatedStyle(() => ({
        opacity: interpolate(animatedIndex.value, [0, 1], [1, 0]),
    }));

    return (
        <AnimatedContainer style={containerAnimatedStyle}>
            <S.IconContainer>
                <Icon name={type === 'training' ? 'training-fill' : 'stopwatch-fill'} size={20} />
            </S.IconContainer>
            <S.TextContainer>{time}</S.TextContainer>
            <S.ButtonContainer>
                <Button size="s" variant="secondary" onPress={onClose}>
                    {closeButtonLabel}
                </Button>
            </S.ButtonContainer>
        </AnimatedContainer>
    );
};
