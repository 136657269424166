import React, { useState } from 'react';
import { TextInput } from 'react-native';

import { Input } from '../input';
import { InputProps } from '../input/Input.types';

export const PasswordInput = React.forwardRef<TextInput | null, InputProps>((props, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Input
            ref={ref}
            {...props}
            secureTextEntry={!showPassword}
            rightIcon={showPassword ? 'view-off' : 'view'}
            onRightIconPress={() => {
                setShowPassword((value) => !value);
            }}
        />
    );
});
