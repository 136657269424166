import { FC, ReactNode } from 'react';
import { useTheme } from 'styled-components/native';
import { SidebarNavigation } from '~/navigation/home/components/sidebarNavigation';

import * as S from './SidebarLayout.styled';

interface SidebarLayoutProps {
    children: ReactNode;
}

export const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
    const { isWebDesktop } = useTheme();
    return (
        <S.MainWrapper>
            {isWebDesktop && <SidebarNavigation />}
            {isWebDesktop ? <S.ChildrenWrapper>{children}</S.ChildrenWrapper> : children}
        </S.MainWrapper>
    );
};
