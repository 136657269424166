import { Pressable, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

import { ProfileProps } from './types';

export const Container = styled(View)`
    padding: 20px 16px 32px;
`;

export const Content = styled(View)`
    flex-direction: row;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            flex-direction: column;
        `}
`;

export const Avatar = styled(View)`
    margin-right: 16px;
    position: relative;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-bottom: 24px;
            display: flex;
            flex-direction: row;
        `}
`;

export const AvatarEditButton = styled(View)`
    position: absolute;
    top: -5px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.fill.level1};
    border: 1px solid ${({ theme }) => theme.palette.opacity[12].rgba};
    justify-content: center;
    align-items: center;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            position: static;
            border-radius: 0;
            background: transparent;
            border: 0;
            width: auto;
            height: auto;
            margin-left: 24px;
        `}
`;

export const DetailsContainer = styled(View)``;

export const NameContainer = styled(View)`
    margin-bottom: 4px;
`;
export const Name = styled(Text)`
    ${mixins.typography.ui.medium16};
    color: ${({ theme }) => theme.text.primary};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.h.medium20};
        `}
`;

export const DetailsRow = styled(View)`
    margin-bottom: 4px;
`;

export const Details = styled(Text)`
    ${mixins.typography.ui.regular12};
    color: ${({ theme }) => theme.text.tertiary};

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.ui.regular14};
        `}
`;

export const SubscriptionStatus = styled(Details)<{ subscriptionStatus: ProfileProps['subscription']['status'] }>`
    color: ${({ theme, subscriptionStatus }) =>
        subscriptionStatus === 'active' ? theme.text.interactive : theme.text.critical};
`;

export const Footer = styled(View)`
    margin-top: 16px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin-top: 32px;
        `}
`;

export const IconWrapper = styled(Pressable)``;
