import { FC, useEffect, useRef } from 'react';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import Animated, { Easing, interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { TrainingTimerType } from '~/components/bottomSheet/trainingTimerSheet/TrainingTimerSheet.types';
import { Button } from '~/components/button';

import { useBottomSheet } from '../../bottomSheetModal';
import * as S from './TrainingTimerSheetExpandedContent.styled';

interface TrainingTimerSheetExpandedContentProps {
    type: TrainingTimerType;
    title: string;
    subtitle?: string;
    series?: string;
    time: string;
    progress: number;
    duration: number;
    closeButtonLabel: string;
    onTimeIncrease: () => void;
    onTimeDecrease: () => void;
    onClose: () => void;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const TrainingTimerSheetExpandedContent: FC<TrainingTimerSheetExpandedContentProps> = ({
    type,
    title,
    subtitle,
    series,
    time,
    progress,
    duration,
    closeButtonLabel,
    onTimeIncrease,
    onTimeDecrease,
    onClose,
}) => {
    const theme = useTheme();
    const { animatedIndex } = useBottomSheet();
    const circularProgressRef = useRef<AnimatedCircularProgress>(null);
    const prevDuration = useRef(duration);

    const containerAnimatedStyle = useAnimatedStyle(() => ({
        opacity: interpolate(animatedIndex.value, [0, 1], [0, 1]),
    }));

    useEffect(() => {
        if (progress === 0) {
            circularProgressRef.current?.animate(type === 'countdown' ? 100 : 0, 0, Easing.linear);
        } else {
            const progressValue = interpolate(progress, [0, duration], type === 'countdown' ? [100, 0] : [0, 100]);
            const progressDuration = duration !== prevDuration.current ? 100 : 1000;

            circularProgressRef.current?.animate(progressValue, progressDuration, Easing.linear);
        }

        prevDuration.current = duration;
    }, [type, progress, duration]);

    return (
        <AnimatedContainer style={containerAnimatedStyle}>
            <S.Header>
                {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
                <S.Title>{title}</S.Title>
                {series && <S.Series>{series}</S.Series>}
            </S.Header>
            <S.CircleProgressContainer>
                <AnimatedCircularProgress
                    ref={circularProgressRef}
                    size={250}
                    width={24}
                    fill={0}
                    duration={0}
                    lineCap="round"
                    tintColor={type === 'training' ? theme.border.positive : theme.text.interactive}
                    backgroundColor={theme.fill.level1}
                    rotation={0}
                >
                    {() => <S.ProgressText>{time}</S.ProgressText>}
                </AnimatedCircularProgress>
            </S.CircleProgressContainer>
            <S.Footer>
                {type === 'rest' && (
                    <S.FooterButtonsRow>
                        <S.FooterButton>
                            <Button size="m" variant="secondary" fullWidth onPress={onTimeIncrease}>
                                +10s
                            </Button>
                        </S.FooterButton>
                        <S.FooterButton last>
                            <Button size="m" variant="secondary" fullWidth onPress={onTimeDecrease}>
                                -10s
                            </Button>
                        </S.FooterButton>
                    </S.FooterButtonsRow>
                )}
                <Button size="m" variant="secondary" fullWidth onPress={onClose}>
                    {closeButtonLabel}
                </Button>
            </S.Footer>
        </AnimatedContainer>
    );
};
