import { Image } from 'react-native';

export const pathToBlob = async (path: string) => {
    const response = await fetch(path);
    return await response.blob();
};

export const prefetchImage = async (imageUrl: string) => {
    try {
        await Image.prefetch(imageUrl);
    } catch (error) {
        console.error(error);
    }
};
