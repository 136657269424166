import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { MainLayoutProps } from '~/layouts/web';

export const Submenu = styled(View)`
    width: 240px;
    background: ${({ theme }) => theme.fill.level1};
    padding-right: 12px;

    ${({ theme }) =>
        theme.isWebExtraLargeDesktop &&
        css`
            width: 320px;
        `}
`;

export const LayoutWrapper = styled(View)`
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const Container = styled(View)<{
    noBackground: Required<MainLayoutProps['noBackground']>;
    withSubmenu?: boolean;
}>`
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.fill.level1};
    display: flex;
    flex-direction: row;
    border-radius: 20px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            border-radius: 0;
        `}

    ${({ noBackground }) =>
        noBackground &&
        css`
            background: ${({ theme }) => theme.fill.background};
        `}

  ${({ theme, withSubmenu }) =>
        theme.isWebDesktop &&
        withSubmenu &&
        css`
            margin-left: -10px;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-left-style: solid;
            border-left-width: 1px;
            border-left-color: ${theme.palette.opacity[12].rgba};
            shadow-color: #000;
            shadow-offset: -12px 0px;
            shadow-opacity: 0.5;
            shadow-radius: 32px;
            flex: 1;
        `}
`;

export const Main = styled(View)`
    flex: 1;
`;

export const HeaderContainer = styled(View)`
    width: 100%;
    padding: 8px 20px;
    position: relative;
`;

export const ContentContainer = styled(View)`
    flex: 1;
    height: 100%;
    overflow-y: auto;
`;

export const ScrollContainer = styled(View)<{
    noBackground: Required<MainLayoutProps['noBackground']>;
    scrollable: Required<MainLayoutProps['scrollable']>;
    fullWidth: Required<MainLayoutProps['fullWidth']>;
    verticalSpacing: Required<MainLayoutProps['verticalSpacing']>;
}>`
    padding: 16px;
    flex: 1;
    height: 100%;
    overflow-y: auto;

    ${({ noBackground, scrollable }) =>
        (noBackground || !scrollable) &&
        css`
            padding: 0;
        `}

    ${({ scrollable }) =>
        !scrollable &&
        css`
            overflow: hidden;
        `}

  ${({ fullWidth }) =>
        fullWidth &&
        css`
            padding-left: 0;
            padding-right: 0;
        `}

  ${({ verticalSpacing }) =>
        !verticalSpacing &&
        css`
            padding-top: 0;
            padding-bottom: 0;
        `}
`;

export const LoaderWrapper = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
`;
