import { BottomTabNavigationEventMap } from '@react-navigation/bottom-tabs';
import { NavigationHelpers, ParamListBase, TabActions, TabNavigationState } from '@react-navigation/native';
import { FC } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { routesIcons } from './TabBarItem.constants';
import * as S from './TabBarItem.styled';
import { NavigationRoute } from './TabBarItem.types';

const defaultIcon = 'help-outline';

export interface TabBarItemProps {
    state: TabNavigationState<ParamListBase>;
    navigation: NavigationHelpers<ParamListBase, BottomTabNavigationEventMap>;
    route: NavigationRoute<ParamListBase, string>;
}

export const TabBarItem: FC<TabBarItemProps> = ({ state: { routes, index, key }, navigation, route }) => {
    const theme = useTheme();
    const pressableState = usePressableState();
    const { pressableAnimationProgress } = pressableState;
    const inset = useSafeAreaInsets();

    const isActiveRoute = route.key === routes[index].key;
    const iconColor = isActiveRoute ? theme.palette.violet[70] : theme.palette.neutral[50];

    const getIconName = () => {
        if (!routesIcons[route.name]) return defaultIcon;
        if (isActiveRoute) return routesIcons[route.name].iconActive;
        return routesIcons[route.name].icon;
    };

    return (
        <S.PressableWrapper
            {...pressableState.pressableProps}
            key={route.key}
            hitSlop={{
                bottom: inset.bottom,
            }}
            onPress={() => {
                const event = navigation.emit({
                    type: 'tabPress',
                    target: route.key,
                    canPreventDefault: true,
                });

                if (!event.defaultPrevented) {
                    navigation.dispatch({
                        ...TabActions.jumpTo(route.name),
                        target: key,
                    });
                }
            }}
        >
            <PressableIcon
                name={getIconName()}
                size={24}
                color={iconColor}
                pressedColor={theme.palette.violet[70]}
                pressableAnimationProgress={pressableAnimationProgress}
            />
        </S.PressableWrapper>
    );
};
