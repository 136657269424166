import { View } from 'react-native';
import styled from 'styled-components/native';

export const MainWrapper = styled(View)`
    flex: 1;
    flex-direction: row;
`;

export const ChildrenWrapper = styled(View)`
    flex: 1;
    shadow-color: #000;
    shadow-offset: -12px -64px;
    shadow-opacity: 0.5;
    shadow-radius: 32px;
`;
