import { interpolateColor, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { springConfig } from '~/constants/animations';
import { PressableAnimationsProps } from '~/types/animations';

export interface DropdownMenuItemAnimationsRenderPropValue {
    containerAnimatedStyle: {
        backgroundColor: string;
    };
    labelAnimatedStyle: {
        color: string;
    };
}

export interface DropdownMenuItemAnimationsProps
    extends PressableAnimationsProps<DropdownMenuItemAnimationsRenderPropValue> {}

export const DropdownMenuItemAnimation = ({ progress, children }: DropdownMenuItemAnimationsProps) => {
    const theme = useTheme();

    const animationProgress = useDerivedValue(() => withSpring(progress, springConfig.default));

    const currentColor: Record<string, string> = {
        default: theme.text.primary,
        hovered: theme.text.interactive,
        pressed: theme.text.interactive,
    };

    const currentBackground: Record<string, string> = {
        default: 'transparent',
        hovered: 'transparent',
        pressed: theme.palette.opacity['12'].rgba,
    };

    const containerAnimatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(
                animationProgress.value,
                [0, 1, 2],
                [currentBackground.default, currentBackground.hovered, currentBackground.pressed],
            ),
        };
    }, []);

    const labelAnimatedStyle = useAnimatedStyle(() => {
        return {
            color: interpolateColor(
                animationProgress.value,
                [0, 1, 2],
                [currentColor.default, currentColor.hovered, currentColor.pressed],
            ),
        };
    }, []);

    return children({ containerAnimatedStyle, labelAnimatedStyle });
};
