import { Image, Text } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold16}
    color: ${({ theme }) => theme.text.primary};
`;

export const HeadImage = styled(Image)`
    height: 44px;
    width: 70px;
`;
