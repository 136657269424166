import { Measurement } from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';
import { endOfToday, startOfToday } from 'date-fns';

const fetchCurrentMeasurements: QueryFunction<Measurement | null, ['getCurrentMeasurement']> = async () => {
    return (
        (
            await DataStore.query(
                Measurement,
                (criteria) =>
                    criteria.and((c) => [
                        c.date.ge(startOfToday().toISOString()),
                        c.date.le(endOfToday().toISOString()),
                    ]),
                {
                    limit: 1,
                },
            )
        )[0] ?? null
    );
};

export const useGetCurrentMeasurement = () => useQuery(['getCurrentMeasurement'], fetchCurrentMeasurements);
