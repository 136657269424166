import { useState } from 'react';

export const usePressableState = () => {
    const [pressed, setPressed] = useState(false);
    const [hovered, setHovered] = useState(false);

    const pressableAnimationProgress = [hovered || pressed, pressed].filter(Boolean).length;

    return {
        pressableProps: {
            pressed,
            hovered,
            onHoverIn: () => setHovered(true),
            onHoverOut: () => setHovered(false),
            onPressIn: () => setPressed(true),
            onPressOut: () => setPressed(false),
        },
        pressableAnimationProgress,
    };
};
