import { Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    width: 100%;
    padding: 24px;
    background: ${({ theme }) => theme.fill.level2};
    border-radius: 12px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            width: 280px;
            height: 280px;
        `};
`;

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold16};
    width: 100%;
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 16px;

    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            ${mixins.typography.h.semiBold18};
        `};
`;

export const Description = styled(View)`
    width: 100%;
    overflow-y: auto;
    flex: 1;
`;

export const DescriptionItem = styled(Text)<{ last?: boolean }>`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.tertiary};
    margin-bottom: 8px;

    ${({ last }) =>
        last &&
        css`
            margin-bottom: 0;
        `};
`;
