import {
    CardioExercise,
    CardioExerciseData,
    Exercise,
    ExerciseData,
    ExerciseGroup,
    FinishedSeriesData,
    TrainingCardioExercise,
    TrainingDay,
    TrainingDayGroup,
    TrainingExercise,
} from '@pg/backend';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { DataStore } from 'aws-amplify';
import {
    groupModels,
    resolveModelArray,
    resolveModelsArray,
    resolveModelsNestedField,
    resolveModelsNestedIdField,
} from '~/utils/api';

export interface ExpandedTrainingDay extends TrainingDay {
    exercisesMap: Record<string, Exercise>;
    cardioExercisesMap: Record<string, CardioExercise>;
    trainingExercisesMap: Record<string, TrainingExercise>;
    groupsMap: Record<string, TrainingDayGroup>;
    exercisesDataMap: Record<string, ExerciseData>;
    cardioExercisesDataMap: Record<string, CardioExerciseData>;
    exerciseGroupsMap: Record<string, ExerciseGroup>;
    trainingCardioExercisesMap: Record<string, TrainingCardioExercise>;
    finishedCardioExercisesMap: Record<string, CardioExerciseData>;
    finishedSeriesMap: Record<string, FinishedSeriesData>;
    exercises: Exercise[];
    cardioExercises: CardioExercise[];
    trainingCardioExercises: TrainingCardioExercise[];
    trainingExercises: TrainingExercise[];
    groups: TrainingDayGroup[];
    exercisesData: ExerciseData[];
    cardioExercisesData: CardioExerciseData[];
    finishedCardioExercises: CardioExerciseData[];
    exerciseGroups: ExerciseGroup[];
    finishedSeries: FinishedSeriesData[];
}

//This query is used to expand all the TrainingDay nested relations data
const fetchExpandedTrainingDay: QueryFunction<
    ExpandedTrainingDay | null,
    ['getTrainingDayWithExercises', string | undefined]
> = async ({ queryKey }) => {
    const [_, trainingDayId] = queryKey;
    try {
        if (!trainingDayId) {
            return null;
        }

        const trainingDay = await DataStore.query(TrainingDay, trainingDayId);

        if (!trainingDay) {
            throw new Error('TrainingDay not found');
        }

        const groups = await resolveModelArray<TrainingDay, TrainingDayGroup>(trainingDay, 'TrainingDayGroups');
        const exerciseGroups = await resolveModelsNestedIdField<TrainingDayGroup, ExerciseGroup>(
            groups,
            'exercisegroupID',
            ExerciseGroup,
        );

        const trainingExercises = await resolveModelsArray<TrainingDayGroup, TrainingExercise>(
            groups,
            'TrainingExercises',
        );
        const trainingCardioExercises = await resolveModelsArray<TrainingDayGroup, TrainingCardioExercise>(
            groups,
            'TrainingCardioExercises',
        );
        const exercises = await resolveModelsNestedIdField<TrainingExercise, Exercise>(
            trainingExercises,
            'exerciseID',
            Exercise,
        );
        const cardioExercises = await resolveModelsNestedIdField<TrainingCardioExercise, CardioExercise>(
            trainingCardioExercises,
            'cardioexerciseID',
            CardioExercise,
        );
        const exercisesData = await resolveModelsNestedField<TrainingExercise, ExerciseData>(
            trainingExercises,
            'ExerciseData',
        );

        const cardioExercisesData = await resolveModelsNestedField<TrainingCardioExercise, CardioExerciseData>(
            trainingCardioExercises,
            'CardioExerciseData',
        );

        const finishedCardioExercises = await resolveModelsNestedIdField<TrainingCardioExercise, CardioExerciseData>(
            trainingCardioExercises,
            'trainingCardioExerciseFinishedCardioExerciseDataId',
            CardioExerciseData,
        );

        const finishedSeries = await resolveModelsArray<TrainingExercise, FinishedSeriesData>(
            trainingExercises,
            'FinishedSeriesData',
        );

        return {
            ...trainingDay,
            exercisesDataMap: groupModels(exercisesData),
            exercisesMap: groupModels(exercises),
            cardioExercisesMap: groupModels(cardioExercises),
            trainingExercisesMap: groupModels(trainingExercises),
            groupsMap: groupModels(groups),
            exerciseGroupsMap: groupModels(exerciseGroups),
            cardioExercisesDataMap: groupModels(cardioExercisesData),
            trainingCardioExercisesMap: groupModels(trainingCardioExercises),
            finishedCardioExercisesMap: groupModels(finishedCardioExercises),
            finishedSeriesMap: groupModels(finishedSeries),
            groups,
            exercises,
            cardioExercises,
            trainingExercises,
            exercisesData,
            exerciseGroups,
            cardioExercisesData,
            trainingCardioExercises,
            finishedCardioExercises,
            finishedSeries,
        };
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const useGetExpandedTrainingDay = (trainingDayId?: string) =>
    useQuery(['getTrainingDayWithExercises', trainingDayId], fetchExpandedTrainingDay, {
        cacheTime: 0,
        staleTime: 0,
        refetchOnMount: true,
    });
