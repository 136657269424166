import { format, formatISO, startOfDay } from 'date-fns';
import { useCallback } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { DateData } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';
import { Calendar } from '~/components/bottomSheet/measurementCalendarSheet/calendar';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './CalendarModal.styled';

const styles = StyleSheet.create({
    shadow: {
        shadowOpacity: 0.8,
        shadowRadius: 32,
        shadowOffset: {
            height: 12,
            width: 0,
        },
        shadowColor: 'black',
    },
});
interface CalendarModalProps {
    onChange: (date: string) => void;
    onMonthChange?: (date: string) => void;
    onClose: () => void;
    currentDate: string;
    enabledDates?: string[];
    disabledDates?: string[];
}

export const CalendarModal = ({
    onChange,
    onMonthChange,
    onClose,
    currentDate,
    enabledDates,
    disabledDates,
}: CalendarModalProps) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    const onDayPress = useCallback(
        (day: DateData) => {
            onChange(day.dateString);
        },
        [onChange],
    );

    const renderSelectedDate = () => {
        if (currentDate) {
            return <S.SelectedDateText>{format(new Date(currentDate), 'd.MM.yyyy')}</S.SelectedDateText>;
        }
        return <S.SelectedDateText>nie wybrano</S.SelectedDateText>;
    };

    return (
        <S.Container style={styles.shadow}>
            <S.Header>
                <S.Wrapper>
                    <Pressable {...pressableProps} onPress={onClose}>
                        <PressableIcon
                            name="close"
                            size={20}
                            pressedColor={theme.icon.primary}
                            color={theme.icon.tertiary}
                            pressableAnimationProgress={pressableAnimationProgress}
                        />
                    </Pressable>
                    <S.Title>Wybierz datę</S.Title>
                    <S.Spacer />
                </S.Wrapper>
                {renderSelectedDate()}
            </S.Header>
            <Calendar
                onDayPress={onDayPress}
                onMonthChange={onMonthChange}
                currentDate={currentDate}
                maxDate={formatISO(startOfDay(new Date()))}
                calendarWidth={420}
                isHeaderRounded={false}
                enabledDates={enabledDates}
                disabledDates={disabledDates}
            />
        </S.Container>
    );
};
