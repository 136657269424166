import { interpolate, useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { springConfig } from '~/constants/animations';
import { AnimationsProps } from '~/types/animations';

export interface SlidingSidebarAnimationRenderPropValue {
    sidebarAnimatedStyles: {
        transform: {
            translateX: number;
        }[];
    };
}

export interface SlidingSidebarAnimationProps extends AnimationsProps<SlidingSidebarAnimationRenderPropValue> {
    visible: boolean;
}

export const SlidingSidebarAnimations = ({ visible, children }: SlidingSidebarAnimationProps) => {
    const visibleProgress = useDerivedValue(() => withSpring(Number(visible), springConfig.default));

    const sidebarAnimatedStyles = useAnimatedStyle(() => {
        const value = interpolate(visibleProgress.value, [0, 1], [100, 0]);

        return {
            // NOTE: This is a workaround for a bug in reanimated, translate value must be a type of number
            transform: [{ translateX: `${value}%` as unknown as number }],
        };
    });

    return children({
        sidebarAnimatedStyles,
    });
};
