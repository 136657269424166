import { ChatType } from '@pg/backend';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { chatTypeContentMap } from '~/utils/chatTypeContentMap';

import * as S from './ChatTopic.styled';

interface ChatTopicProps {
    chatType: ChatType;
    newMessagesCount?: number;
    onPress: () => void;
}

export const ChatTopic = ({ chatType, newMessagesCount, onPress }: ChatTopicProps) => {
    const theme = useTheme();

    const chatTopicContent = chatTypeContentMap[chatType];

    if (!chatTopicContent) return null;

    return (
        <S.Container style={S.shadowStyles.shadow} onPress={onPress}>
            <S.Wrapper>
                <S.Title>{chatTopicContent.title}</S.Title>
                <S.RightWrapper>
                    {!!newMessagesCount && (
                        <S.CounterWrapper>
                            <S.CounterText>{newMessagesCount}</S.CounterText>
                        </S.CounterWrapper>
                    )}
                    <Icon color={theme.icon.tertiary} name="chevron-right" size={24} />
                </S.RightWrapper>

                <S.IconWrapper>
                    <Icon color={theme.icon.disabled} name={chatTopicContent.icon} size={115} />
                </S.IconWrapper>
            </S.Wrapper>
        </S.Container>
    );
};
