import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { TileGradientButton } from '~/components/tileGradientButton';

export const ButtonsContainer = styled(View)`
    padding: 0 16px;
    margin-bottom: 24px;
    flex-direction: row;
`;

export const ChartContainer = styled(View)`
    margin-bottom: 20px;
`;

export const MenuItemWrapper = styled(View)`
    margin-bottom: 24px;

    ${({ theme }) =>
        theme.isWebMobile &&
        css`
            margin-bottom: 0;
        `}
`;

export const Button = styled(TileGradientButton)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;
