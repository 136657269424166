import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { StandardNavigation } from '~/components/topNavigation';
import { DietBasicsScreen } from '~/screens/diet/dietBasics';
import { DietPlanChoiceScreen } from '~/screens/diet/dietPlanChoice';
import { DietSettingsScreen } from '~/screens/diet/dietSettings';
import { MealScreen } from '~/screens/diet/meal';
import { MealSubstituteScreen } from '~/screens/diet/mealSubstitute';
import { MetabolismScreen } from '~/screens/diet/metabolism';

import { DietStackParamsList } from './DietStackNavigation.types';

const Stack = createNativeStackNavigator<DietStackParamsList>();

const MealHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation onBackPress={() => props.navigation.navigate('Tab', { screen: 'Diet' })} {...props} />
);

const DietSettingsHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Ustawienia diety"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'Diet' })}
        {...props}
    />
);

const DietBasicsHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation onBackPress={() => props.navigation.navigate('DietStack', { screen: 'Settings' })} {...props} />
);

const MetabolismHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation onBackPress={() => props.navigation.navigate('DietStack', { screen: 'Settings' })} {...props} />
);

const SubstituteHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Wybierz zamiennik"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'Diet' })}
        {...props}
    />
);

const PlanChoiceHeader = (props: NativeStackHeaderProps) => (
    <StandardNavigation
        title="Wybierz plan dietetyczny"
        onBackPress={() => props.navigation.navigate('Tab', { screen: 'Diet' })}
        {...props}
    />
);

export const DietStackNavigation = () => {
    return (
        <Stack.Navigator screenOptions={{ header: () => null }}>
            <Stack.Screen name="Meal" component={MealScreen} options={{ header: MealHeader }} />
            <Stack.Screen name="Settings" component={DietSettingsScreen} options={{ header: DietSettingsHeader }} />
            <Stack.Screen name="Basics" component={DietBasicsScreen} options={{ header: DietBasicsHeader }} />
            <Stack.Screen name="Metabolism" component={MetabolismScreen} options={{ header: MetabolismHeader }} />
            <Stack.Screen name="Substitute" component={MealSubstituteScreen} options={{ header: SubstituteHeader }} />
            <Stack.Screen name="PlanChoice" component={DietPlanChoiceScreen} options={{ header: PlanChoiceHeader }} />
        </Stack.Navigator>
    );
};
