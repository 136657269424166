import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { Icon as BaseIcon } from '~/components/icon';
import { mixins } from '~/theme';

export const Container = styled(Pressable)`
    background-color: ${({ theme }) => theme.fill.level1};
    padding: 12px 16px;
    border-top-width: 1px;
    border-color: ${({ theme }) => theme.border[12]};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
`;

export const TextContent = styled(View)`
    user-select: none;
`;

export const Title = styled(Text)`
    ${mixins.typography.ui.regular14}
    color: ${({ theme }) => theme.text.primary};
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular12}
    color: ${({ theme }) => theme.text.tertiary};
    margin-top: 2px;
`;

export const RightContentWrapper = styled(View)`
    flex-direction: row;
    align-items: flex-start;
`;

export const Icon = styled(BaseIcon)`
    margin-left: 10px;
`;

export const IconWrapper = styled(View)``;
