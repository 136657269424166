import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useDiet } from '~/enhancers/dietContext';
import { getTodayIndex } from '~/screens/diet/components/dietSlider/utils';
import { EmitterEvent } from '~/types/emitter';
import Emitter from '~/utils/emitter';

import { MealModal } from '../../mealModal';
import { MobileDietSlider } from './DietSlider.native';
import * as S from './DietSlider.styled';
import { SliderItem } from './components/sliderItem';

export const DietSlider = () => {
    const theme = useTheme();
    const { sliderData, selectedWeek, isSelectedWeekCurrent } = useDiet();
    const sliderItemsRefs = useRef<RefObject<View>[]>((sliderData || []).map(() => React.createRef())).current;
    const todayIndex = getTodayIndex();
    const [mealModalId, setMealModalId] = useState<string | null>(null);

    const scrollTo = useCallback(
        (index: number) => {
            (sliderItemsRefs[index].current as unknown as HTMLDivElement)?.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
            });
        },
        [sliderItemsRefs],
    );

    useEffect(() => {
        scrollTo(isSelectedWeekCurrent ? todayIndex : 0);
    }, [isSelectedWeekCurrent, scrollTo, selectedWeek, todayIndex]);

    useEffect(() => {
        const handleEmmiter = () => scrollTo(todayIndex);

        Emitter.on(EmitterEvent.goToTodayDietPlan, handleEmmiter);

        return () => {
            Emitter.off(EmitterEvent.goToTodayDietPlan, handleEmmiter);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContent = () => {
        if (!theme.isWebDesktop) {
            return <MobileDietSlider />;
        }

        // @ts-ignore
        if (false) {
            // add loading skeleton
        }

        return (
            <S.Content>
                <S.ScrollableContainer nativeID="diet-scrollbar">
                    {sliderData?.map((item, index) => (
                        <S.SliderItemWrapper
                            ref={sliderItemsRefs[index]}
                            key={index}
                            last={index === sliderData?.length - 1}
                        >
                            <SliderItem
                                key={index}
                                isToday={new Date(item.date).toDateString() === new Date().toDateString()}
                                active={item.active}
                                date={item.date}
                                nutritionData={item.nutritionData}
                                meals={item.meals}
                                onMealPress={setMealModalId}
                            />
                        </S.SliderItemWrapper>
                    ))}
                </S.ScrollableContainer>
                <MealModal isOpen={!!mealModalId} mealId={mealModalId} onClose={() => setMealModalId(null)} />
            </S.Content>
        );
    };

    return <S.Container>{renderContent()}</S.Container>;
};
