import { Pressable, Text, View } from 'react-native';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import styled, { DefaultTheme, css } from 'styled-components/native';
import { mixins } from '~/theme';

import { NotificationConfig } from './Notification.types';

type NotificationType = NonNullable<NotificationConfig['type']>;

type NotificationPosition = NonNullable<NotificationConfig['position']>;

const containerTypeStylesMap: Record<NotificationType, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    success: css`
        background-color: ${({ theme }) => theme.fill.positive};
    `,
    informative: css`
        background-color: ${({ theme }) => theme.fill.informative};
    `,
    error: css`
        background-color: ${({ theme }) => theme.fill.critical};
    `,
};

const messageTypeStylesMap: Record<NotificationType, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    success: css`
        color: ${({ theme }) => theme.text.positiveSurface};
    `,
    informative: css`
        color: ${({ theme }) => theme.text.interactiveSurface};
    `,
    error: css`
        color: ${({ theme }) => theme.text.criticalSurface};
    `,
};

const positionStylesMap: Record<NotificationPosition, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    top: css`
        top: 0;
        bottom: auto;
    `,
    bottom: css`
        bottom: 0;
    `,
};

export const Container = styled(Pressable)<{ index: number; type: NotificationType; position: NotificationPosition }>`
    border-radius: 8px;
    position: absolute;
    padding: 16px;
    z-index: ${({ index }) => -index};
    shadow-color: ${({ theme }) => theme.fill.background};
    shadow-opacity: 0.3;
    shadow-radius: 4px;
    shadow-offset: 0 2px;
    max-width: 600px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    align-self: center;

    ${({ type }) => containerTypeStylesMap[type]};

    ${({ position }) => positionStylesMap[position]};
`;

export const Message = styled(Text)<{ type: NotificationType }>`
    ${mixins.typography.p.regular16};
    ${({ type }) => messageTypeStylesMap[type]};
    flex: 1;
`;

export const IconWrapper = styled(View)`
    margin-right: 20px;
`;
