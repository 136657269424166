import { View } from 'react-native';
import styled from 'styled-components/native';
import { TileGradientButton } from '~/components/tileGradientButton';

export const TargetContainer = styled(View)`
    margin-bottom: 20px;
`;

export const ActivityContainer = styled(View)`
    margin-bottom: 20px;
`;

export const ActionsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
`;

export const Button = styled(TileGradientButton)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
    max-width: 50%;
`;
