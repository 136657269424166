import { useLinkTo, useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { desktopBlacklistScreens } from '~/constants/path';
import { useAuth } from '~/enhancers/auth.context';
import { AuthStackScreens } from '~/navigation/paths';
import { NotFoundNavigationProp } from '~/screens/notFound/NotFound.types';

const isPathInList = (list: readonly string[], path: string) =>
    list.some((item) => item.split('/')[0] === (path.charAt(0) === '/' ? path.slice(1) : path).split('/')[0]);

const isAuthPath = (path: string) => isPathInList(Object.values(AuthStackScreens.screens), path);

const isPathBlacklistedForDesktop = (path: string) => isPathInList(desktopBlacklistScreens, path);

export const useRedirection = () => {
    const auth = useAuth();
    const linkTo = useLinkTo();
    const navigation = useNavigation<NotFoundNavigationProp>();
    const { isWeb, isWebDesktop } = useTheme();
    const initialPath = auth.initialPath ?? '/';

    const handleRedirection = (path: string) => {
        if (!auth.isAuthenticated || !isWeb || !path) return;

        if (isAuthPath(path)) {
            return;
        }

        if (isWebDesktop && isPathBlacklistedForDesktop(path)) {
            navigation.navigate('NotFound');
            return;
        }

        linkTo(path);
    };

    // NOTE: Redirection on initial render or login
    useEffect(() => {
        handleRedirection(initialPath);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated]);

    // NOTE: Redirection on breakpoint change
    useEffect(() => {
        const currentPath = Platform.OS === 'web' ? window.location.pathname : undefined;

        currentPath && handleRedirection(currentPath);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWebDesktop]);
};
