import { FC } from 'react';
import { SvgProps } from 'react-native-svg';

export const ICONS = [
    'info-fill',
    'info-outline',
    'account-avatar',
    'account-fill',
    'account-outline',
    'add',
    'ah',
    'app-store',
    'arrow-down',
    'arrow-up',
    'atlas-gym-fill',
    'atlas-gym-outline',
    'bug',
    'calendar-today-filled',
    'calendar-today-outline',
    'calendar',
    'camera',
    'chat-fill',
    'chat-outline',
    'check-fill',
    'check-list',
    'check-small',
    'checkbox-check-large',
    'checkbox-check-small',
    'chevron-big-left',
    'chevron-double-left',
    'chevron-double-right',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'close',
    'compare',
    'crown',
    'dashboard-fill',
    'dashboard-outline',
    'diet-filled',
    'diet-outline',
    'download',
    'edit',
    'exchange',
    'file-money-fill',
    'file-money-outline',
    'file-pdf',
    'fire-3d',
    'flexed-biceps-3d',
    'google-play',
    'hash',
    'help-fill',
    'help-outline',
    'keyboard-hide',
    'learning-hat-fill',
    'learning-hat-outline',
    'loader',
    'lock',
    'log-out',
    'medical-examination-fill',
    'medical-examination-outline',
    'menu',
    'metabolism',
    'minus',
    'more',
    'no-results',
    'order-history',
    'personal-trainer',
    'pharmacy-filled',
    'pharmacy-outline',
    'photo-gallery-outline',
    'play',
    'privacy',
    'ruler-fill',
    'ruler-outline',
    'search',
    'send',
    'settings',
    'shopping-list-filled',
    'shopping-list-outline',
    'sleeping-face-3d',
    'smirking-face-3d',
    'sound-off',
    'sound-on',
    'stop',
    'stopwatch-fill',
    'superstar',
    'table',
    'total-training-time',
    'traingle-down',
    'training history',
    'training-atlas-filled',
    'training-atlas-outline',
    'training-fill',
    'training-outline',
    'trash-filled',
    'trash-outline',
    'trophy-fill',
    'trophy-outline',
    'video-player',
    'view-off',
    'view',
    'warning-stop',
] as const;
export type IconName = (typeof ICONS)[number];
export const icons: Record<IconName, { default: FC<SvgProps> }> = {
    'info-fill': require('./Info-fill.svg'),
    'info-outline': require('./Info-outline.svg'),
    'account-avatar': require('./account-avatar.svg'),
    'account-fill': require('./account-fill.svg'),
    'account-outline': require('./account-outline.svg'),
    add: require('./add.svg'),
    ah: require('./ah.svg'),
    'app-store': require('./app-store.svg'),
    'arrow-down': require('./arrow-down.svg'),
    'arrow-up': require('./arrow-up.svg'),
    'atlas-gym-fill': require('./atlas-gym-fill.svg'),
    'atlas-gym-outline': require('./atlas-gym-outline.svg'),
    bug: require('./bug.svg'),
    'calendar-today-filled': require('./calendar-today-filled.svg'),
    'calendar-today-outline': require('./calendar-today-outline.svg'),
    calendar: require('./calendar.svg'),
    camera: require('./camera.svg'),
    'chat-fill': require('./chat-fill.svg'),
    'chat-outline': require('./chat-outline.svg'),
    'check-fill': require('./check-fill.svg'),
    'check-list': require('./check-list.svg'),
    'check-small': require('./check-small.svg'),
    'checkbox-check-large': require('./checkbox-check-large.svg'),
    'checkbox-check-small': require('./checkbox-check-small.svg'),
    'chevron-big-left': require('./chevron-big-left.svg'),
    'chevron-double-left': require('./chevron-double-left.svg'),
    'chevron-double-right': require('./chevron-double-right.svg'),
    'chevron-down': require('./chevron-down.svg'),
    'chevron-left': require('./chevron-left.svg'),
    'chevron-right': require('./chevron-right.svg'),
    'chevron-up': require('./chevron-up.svg'),
    close: require('./close.svg'),
    compare: require('./compare.svg'),
    crown: require('./crown.svg'),
    'dashboard-fill': require('./dashboard-fill.svg'),
    'dashboard-outline': require('./dashboard-outline.svg'),
    'diet-filled': require('./diet-filled.svg'),
    'diet-outline': require('./diet-outline.svg'),
    download: require('./download.svg'),
    edit: require('./edit.svg'),
    exchange: require('./exchange.svg'),
    'file-money-fill': require('./file-money-fill.svg'),
    'file-money-outline': require('./file-money-outline.svg'),
    'file-pdf': require('./file-pdf.svg'),
    'fire-3d': require('./fire-3d.svg'),
    'flexed-biceps-3d': require('./flexed-biceps-3d.svg'),
    'google-play': require('./google-play.svg'),
    hash: require('./hash.svg'),
    'help-fill': require('./help-fill.svg'),
    'help-outline': require('./help-outline.svg'),
    'keyboard-hide': require('./keyboard-hide.svg'),
    'learning-hat-fill': require('./learning-hat-fill.svg'),
    'learning-hat-outline': require('./learning-hat-outline.svg'),
    loader: require('./loader.svg'),
    lock: require('./lock.svg'),
    'log-out': require('./log-out.svg'),
    'medical-examination-fill': require('./medical-examination-fill.svg'),
    'medical-examination-outline': require('./medical-examination-outline.svg'),
    menu: require('./menu.svg'),
    metabolism: require('./metabolism.svg'),
    minus: require('./minus.svg'),
    more: require('./more.svg'),
    'no-results': require('./no-results.svg'),
    'order-history': require('./order-history.svg'),
    'personal-trainer': require('./personal-trainer.svg'),
    'pharmacy-filled': require('./pharmacy-filled.svg'),
    'pharmacy-outline': require('./pharmacy-outline.svg'),
    'photo-gallery-outline': require('./photo-gallery-outline.svg'),
    play: require('./play.svg'),
    privacy: require('./privacy.svg'),
    'ruler-fill': require('./ruler-fill.svg'),
    'ruler-outline': require('./ruler-outline.svg'),
    search: require('./search.svg'),
    send: require('./send.svg'),
    settings: require('./settings.svg'),
    'shopping-list-filled': require('./shopping-list-filled.svg'),
    'shopping-list-outline': require('./shopping-list-outline.svg'),
    'sleeping-face-3d': require('./sleeping-face-3d.svg'),
    'smirking-face-3d': require('./smirking-face-3d.svg'),
    'sound-off': require('./sound-off.svg'),
    'sound-on': require('./sound-on.svg'),
    stop: require('./stop.svg'),
    'stopwatch-fill': require('./stopwatch-fill.svg'),
    superstar: require('./superstar.svg'),
    table: require('./table.svg'),
    'total-training-time': require('./total-training-time.svg'),
    'traingle-down': require('./traingle-down.svg'),
    'training history': require('./training history.svg'),
    'training-atlas-filled': require('./training-atlas-filled.svg'),
    'training-atlas-outline': require('./training-atlas-outline.svg'),
    'training-fill': require('./training-fill.svg'),
    'training-outline': require('./training-outline.svg'),
    'trash-filled': require('./trash-filled.svg'),
    'trash-outline': require('./trash-outline.svg'),
    'trophy-fill': require('./trophy-fill.svg'),
    'trophy-outline': require('./trophy-outline.svg'),
    'video-player': require('./video-player.svg'),
    'view-off': require('./view-off.svg'),
    view: require('./view.svg'),
    'warning-stop': require('./warning-stop.svg'),
};
