import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { useGetInProgressTrainingDay } from '~/api/training/useGetInProgressTrainingDay';
import { useGlobalToasts } from '~/enhancers/globalToasts.context';
import { useMeasurement } from '~/enhancers/measurement.context';
import { HomeParamsList } from '~/navigation/home';

import { Toast } from '../toast';
import * as S from './GlobalToasts.styled';

export type GlobalToastsPosition = 'top' | 'bottom';

export type GlobalToastsProps = {
    position: GlobalToastsPosition;
};

export const GlobalToasts = ({ position }: GlobalToastsProps) => {
    const { addMeasurement } = useMeasurement();
    const { data: inProgressTrainingDay } = useGetInProgressTrainingDay();
    const { activeToastType, toastPosition, hide } = useGlobalToasts();
    const theme = useTheme();
    const insets = useSafeAreaInsets();
    const navigation = useNavigation<NativeStackNavigationProp<HomeParamsList>>();

    const getBottomInset = () => {
        if (theme.isWebDesktop) {
            return 0;
        }

        if (theme.isWebMobile) {
            return 48;
        }

        return insets.bottom + 48;
    };

    const handleNavigateToTrainingDay = () => {
        if (!inProgressTrainingDay) {
            return;
        }
        navigation.navigate('TrainingStack', {
            screen: 'Training',
            params: { trainingDayId: inProgressTrainingDay.id! },
        });
    };

    if (position !== toastPosition) {
        return null;
    }

    const renderToast = () => {
        if (activeToastType === 'connection-error') {
            return (
                <Toast
                    id={activeToastType}
                    variant="warning"
                    icon="warning-stop"
                    iconSize={24}
                    hiddenIconOnMobile={false}
                    description="Masz problemy z internetem. Zaczekaj aż wróci połączenie."
                    animationDisabled={theme.isWebDesktop}
                />
            );
        }

        if (activeToastType === 'measurement') {
            return (
                <Toast
                    id={activeToastType}
                    title="Czas na dodanie pomiaru"
                    description="Pomiar nie został dodany przez conajmniej 14 dni. Abym mógł lepiej układać kolejne plany dodawaj pomiary regularnie."
                    confirmButtonLabel="Dodaj pomiar"
                    onConfirmPress={addMeasurement}
                    cancelButtonLabel="Później"
                    onCancelPress={() => hide(activeToastType)}
                />
            );
        }

        if (activeToastType === 'training-in-progress') {
            return (
                <Toast
                    id={activeToastType}
                    title="Trening w trakcie"
                    icon="training-fill"
                    iconSize={24}
                    iconPosition="left"
                    actionsPosition="right"
                    onConfirmPress={handleNavigateToTrainingDay}
                    confirmButtonLabel="Dokończ"
                    onDismiss={() => hide(activeToastType)}
                    animationDisabled
                    withSpacing={!theme.isWebDesktop}
                />
            );
        }

        return null;
    };

    if (activeToastType && toastPosition === 'top') {
        return (
            <S.TopToastContainer key={activeToastType} top={insets.top}>
                {renderToast()}
            </S.TopToastContainer>
        );
    }

    if (activeToastType && toastPosition === 'bottom') {
        return (
            <S.BottomToastContainer key={activeToastType} bottom={getBottomInset()}>
                {renderToast()}
            </S.BottomToastContainer>
        );
    }

    return null;
};
