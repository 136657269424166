import React from 'react';
import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { MainLayout } from '~/layouts/web';
import { MeasurementsStackScreens } from '~/navigation/paths';

import { MeasurementsTableScreenContent } from './MeasurementsTableScreen.native';

export const MeasurementsTableScreen = () => {
    useWebDesktopBlockedScreen(MeasurementsStackScreens.screens.Table);

    return (
        <MainLayout
            title="Tabela Pomiarów"
            content={<MeasurementsTableScreenContent />}
            fullWidth
            verticalSpacing={false}
        />
    );
};
