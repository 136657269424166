import React from 'react';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import { RemoveButtonAnimation } from './RemoveButton.animated';
import * as S from './RemoveButton.styled';

export interface RemoveButtonProps {
    onPress: () => void;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const RemoveButton: React.FC<RemoveButtonProps> = ({ onPress }) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <RemoveButtonAnimation progress={pressableAnimationProgress}>
            {({ containerAnimationStyles }) => (
                <AnimatedContainer {...pressableProps} onPress={onPress} style={containerAnimationStyles}>
                    <S.RemoveIconWrapper>
                        <PressableIcon
                            name="close"
                            size={12}
                            color={theme.icon.primary}
                            pressedColor={theme.icon.interactive}
                            pressableAnimationProgress={pressableAnimationProgress}
                        />
                    </S.RemoveIconWrapper>
                </AnimatedContainer>
            )}
        </RemoveButtonAnimation>
    );
};
