import React, { ReactNode } from 'react';

import { ActivityButton } from '../activityButton';
import * as S from './Pagination.styled';
import { PaginationStyledProps } from './Pagination.types';

export interface PaginationProps extends PaginationStyledProps {
    page: number;
    label?: ReactNode;
    totalPages: number;
    onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ page, label, totalPages, onPageChange, ...props }) => {
    const renderLabel = () => {
        if (label) return label;

        return (
            <S.Label>
                Strona <S.ActivePage>{page}</S.ActivePage> z <S.TotalPages>{totalPages}</S.TotalPages>
            </S.Label>
        );
    };

    return (
        <S.Container {...props}>
            <S.ButtonsContainer>
                <S.PrevButton>
                    <ActivityButton icon="chevron-double-left" disabled={page <= 1} onPress={() => onPageChange(1)} />
                </S.PrevButton>
                <S.PrevButton>
                    <ActivityButton icon="chevron-left" disabled={page <= 1} onPress={() => onPageChange(page - 1)} />
                </S.PrevButton>
            </S.ButtonsContainer>
            {renderLabel()}
            <S.ButtonsContainer>
                <S.NextButton>
                    <ActivityButton
                        icon="chevron-right"
                        disabled={page >= totalPages}
                        onPress={() => onPageChange(page + 1)}
                    />
                </S.NextButton>
                <S.NextButton>
                    <ActivityButton
                        icon="chevron-double-right"
                        disabled={page >= totalPages}
                        onPress={() => onPageChange(totalPages)}
                    />
                </S.NextButton>
            </S.ButtonsContainer>
        </S.Container>
    );
};
