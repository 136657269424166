import { View } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(View)`
    padding: 24px;
    max-width: 100%;
    max-height: 100%;
`;

export const CropperContainer = styled(View)`
    flex: 1 1 600px;
    width: 600px;
    max-width: 100%;
    max-height: 100%;
`;

export const Footer = styled(View)`
    margin-top: 24px;
    gap: 12px;
`;
