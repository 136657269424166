import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Container = styled(View)`
    padding: 16px 16px 32px;
`;
export const Header = styled(View)`
    margin-bottom: 32px;
`;
export const Title = styled(Text)`
    ${mixins.typography.h.semiBold18};
    color: ${({ theme }) => theme.text.primary};
`;

export const Subtitle = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.secondary};
    margin-bottom: 8px;
`;

export const Series = styled(Text)`
    ${mixins.typography.ui.regular14};
    color: ${({ theme }) => theme.text.secondary};
    margin-top: 8px;
`;

export const CircleProgressContainer = styled(View)`
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
`;

export const ProgressText = styled(Text)`
    ${mixins.typography.h.semiBold40};
    color: ${({ theme }) => theme.text.primary};
`;

export const Footer = styled(View)``;

export const FooterButtonsRow = styled(View)`
    flex-direction: row;
    margin-bottom: 20px;
`;

export const FooterButton = styled(View)<{ last?: boolean }>`
    flex: 1;
    margin-right: ${({ last }) => (last ? 0 : 16)}px;
`;
