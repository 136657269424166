import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const Title = styled(Text)`
    ${mixins.typography.h.semiBold24}
    color: ${({ theme }) => theme.text.primary};
    padding: 16px;
    text-transform: capitalize;
`;

export const ExerciseWrapper = styled(View)`
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.border[12]};
`;

export const ExerciseName = styled(Text)`
    ${mixins.typography.ui.medium14}
    color: ${({ theme }) => theme.text.primary};
`;

// TODO: Use ActivityIcon component
export const IconWrapper = styled(View)`
    margin-right: 14px;
    background-color: ${({ theme }) => theme.fill.level5};
    padding: 4px;
    border-radius: 100px;
    border-width: 1px;
    border-color: #58f9ab;
    justify-content: center;
    align-items: center;
`;

export const LeftContentWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
`;

export const MainContentWrapper = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
`;

export const MainContentIconWrapper = styled(View)`
    margin-top: 8px;
    border-color: ${({ theme }) => theme.border[40]};
    background-color: ${({ theme }) => theme.fill.level5};
    padding: 4px;
    border-radius: 100px;
    border-width: 2px;
    height: 48px;
    width: 48px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const MainContentText = styled(Text)`
    ${mixins.typography.p.regular16}
    color: ${({ theme }) => theme.text.tertiary};
    margin-top: 16px;
    text-align: center;
    max-width: 230px;
`;

export const flashListContainer = {
    paddingBottom: 50,
};
