import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { IconProps } from '~/components/icon';

interface ContainerStyledProps {
    size: IconProps['size'];
}

export const Container = styled(View)<ContainerStyledProps>`
    position: relative;

    ${({ size }) => css`
        width: ${size}px;
        height: ${size}px;
    `}
`;

export const IconWrapper = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
