import * as S from './DayBar.styled';

type DayBarProps = {
    day: string;
    date: string;
    activities?: number;
    dateType: 'past' | 'today' | 'future';
};

export const DayBar = ({ day, date, activities, dateType }: DayBarProps) => {
    const isToday = dateType === 'today';
    const isFuture = dateType === 'future';
    const isPast = dateType === 'past';
    const getEmojiType = (): 'workout' | 'break' | 'today' => {
        if (isToday) return 'today';
        if (activities && activities > 0) return 'workout';
        return 'break';
    };

    const availableActivities = activities !== undefined;
    const isCountVisible = availableActivities && isPast && activities > 1;
    const isReactionVisible = availableActivities && !isFuture;

    return (
        <S.Container>
            {isToday && <S.TodayIndicator />}
            <S.Bar dateType={dateType} activities={activities ?? 0}>
                {isCountVisible && <S.Count count={activities - 1} />}
                {isReactionVisible && <S.Reaction emoji={getEmojiType()} activities={activities ?? 0} />}
            </S.Bar>
            <S.DateContainer>
                <S.DayText>{day}</S.DayText>
                <S.DateText>{date}</S.DateText>
            </S.DateContainer>
        </S.Container>
    );
};
