import { DateMarking } from './Day.types';

export const getDayVariant = (marking: DateMarking) => {
    if (marking && !marking.endingDay && !marking.startingDay) {
        return 'between';
    }
    if (marking?.endingDay && marking?.startingDay) {
        return 'both';
    }
    if (marking?.endingDay) {
        return 'end';
    }
    if (marking?.startingDay) {
        return 'start';
    }
    return 'default';
};
