import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { SEO } from '~/components/wrappers';
import { useAuth } from '~/enhancers/auth.context';
import { AuthLayout } from '~/layouts/authLayout';
import { SignInForm } from '~/screens/auth/signInScreen/components/signInForm';
import { FormValues } from '~/screens/auth/signInScreen/components/signInForm/SignInForm.types';
import { getErrorMessage } from '~/utils/authErrorMessage';

import { AuthFormLayout } from '../layouts/authFormLayout';
import { SignInScreenProps } from './SignInScreen.types';

export const SignInScreen: React.FC<SignInScreenProps> = ({ navigation, route }) => {
    const theme = useTheme();
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const userEmail = route.params?.email || '';

    const handleRecordPasswordErrors = useCallback(
        (e: any, values: FormValues) => {
            const error = e as { code: string };

            if (error.code === 'UserNotConfirmedException') {
                auth.setSignUpConfirmationEnabled(true);
                navigation.navigate('SignUpConfirmation', { email: values.email });
                return;
            }

            if (error.code === 'PasswordResetRequiredException') {
                auth.setNewPasswordConfirmation(true);
                navigation.navigate('NewPassword', { email: values.email });
                return;
            }

            setErrorMessage(getErrorMessage(error));
        },
        [auth, navigation],
    );

    const handleSignIn: SubmitHandler<FormValues> = useCallback(
        async (values) => {
            setErrorMessage(undefined);
            setLoading(true);
            try {
                const user = await auth.signIn({
                    username: values.email,
                    password: values.password,
                });

                if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    auth.setNewPasswordConfirmation(user);
                    navigation.navigate('NewPassword', { email: values.email });
                    return;
                }
            } catch (e) {
                handleRecordPasswordErrors(e, values);
            } finally {
                setLoading(false);
            }
        },
        [auth, handleRecordPasswordErrors],
    );

    const layoutImageSrc = useMemo(() => {
        return theme.isWebDesktop ? require('~/assets/images/auth.webp') : undefined;
    }, [theme.isWebDesktop]);

    const title = useMemo(
        () => (theme.isWebDesktop ? 'Zaloguj się używając adresu email' : 'Zaloguj się'),
        [theme.isWebDesktop],
    );

    return (
        <AuthLayout imageSrc={layoutImageSrc} webImagePosition="full">
            <SEO title="Logowanie" />
            <AuthFormLayout
                title={title}
                disabled={loading}
                error={errorMessage}
                bottomActions={[
                    {
                        id: 'password-recovery',
                        label: 'Nie pamiętasz hasła?',
                        onPress: () => navigation.navigate('PasswordRecovery'),
                    },
                ]}
            >
                <SignInForm email={userEmail} onSubmit={handleSignIn} />
            </AuthFormLayout>
        </AuthLayout>
    );
};
