import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNotifications } from '~/enhancers/notifications.context';

import * as S from './Notifications.styled';
import { Notification } from './components/Notification';

export const Notifications: React.FC = () => {
    const { notifications } = useNotifications();
    const inset = useSafeAreaInsets();

    return (
        <S.Container inset={inset} pointerEvents="box-none">
            {notifications.map(({ id, config }, index) => (
                <Notification key={id} index={index} id={id} config={config} />
            ))}
        </S.Container>
    );
};
