import { View } from 'react-native';
import styled from 'styled-components/native';

export const ActionsContainer = styled(View)`
    display: flex;
    flex-direction: row;
`;

export const ActionWrapper = styled(View)`
    margin-left: 12px;
`;
