import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

export const FullHeightContainer = styled(View)`
    flex: 1px;
    padding: 0 16px;
`;

export const ListContainer = styled(View)`
    flex: 1;
    ${({ theme }) =>
        theme.isWebDesktop &&
        css`
            margin: 0 8px;
        `}
`;

export const CategoryContainer = styled(View)`
    padding: 16px 0;
    background-color: ${({ theme }) => theme.fill.level1};
`;

export const LoaderContainer = styled(View)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
