import { FC, ReactNode } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';

import * as S from './TopNavWrapper.styled';
import { VariantType } from './TopNavWrapper.types';

export interface TopNavWrapperProps {
    leftContent?: ReactNode;
    centerContent?: ReactNode;
    rightContent?: ReactNode;
    bottomContent?: ReactNode;
    noExtraSpacing?: boolean;
    loading?: boolean;
    bottomContentFullWidth?: boolean;
    variant?: VariantType;
}

export const TopNavWrapper: FC<TopNavWrapperProps> = ({
    leftContent,
    centerContent,
    rightContent,
    bottomContent,
    noExtraSpacing,
    bottomContentFullWidth,
    variant,
    loading,
}) => {
    const inset = useSafeAreaInsets();
    const { isWebDesktop } = useTheme();

    if (isWebDesktop) return null;

    return (
        <S.Container inset={inset} noExtraSpacing={noExtraSpacing}>
            <S.Wrapper>
                <S.SideContentWrapper variant={variant}>{leftContent}</S.SideContentWrapper>
                {centerContent && <S.CenterContentWrapper>{centerContent}</S.CenterContentWrapper>}
                <S.SideContentWrapper variant={variant}>
                    {loading && <S.Loader size="l" />}
                    {rightContent}
                </S.SideContentWrapper>
            </S.Wrapper>
            <S.BottomContentWrapper fullWidth={bottomContentFullWidth}>{bottomContent}</S.BottomContentWrapper>
        </S.Container>
    );
};
