import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { Checkbox } from '~/components/checkbox';
import { DesktopModal } from '~/components/modal/desktopModal';

import { BottomSheetModal } from '../bottomSheetModal';
import { ConfirmSheetProps, ConfirmSheet as MobileConfirmSheet } from './ConfirmSheet.native';
import * as S from './ConfirmSheet.styled';

export type ConfirmSheetRef = BottomSheetModal | { present: () => void; close: () => void; dismiss: () => void };

export const ConfirmSheet = forwardRef<ConfirmSheetRef, ConfirmSheetProps>((props, ref) => {
    const {
        variant = 'error',
        title,
        primaryButtonLabel,
        secondaryButtonLabel,
        subtitle,
        doubleConfirmation,
        doubleConfirmationLabel = 'Potwierdzam',
        maxWidth = 700,
        onClose,
        onConfirm,
    } = props;
    const theme = useTheme();
    const mobileConfirmSheetRef = useRef<BottomSheetModal>(null);
    const [isModalVisible, setIsModalVisible] = useState(!!props.initiallyOpen);
    const [doubleConfirmationChecked, setDoubleConfirmationChecked] = useState(false);

    const handleOnClose = useCallback(() => {
        if (theme.isWebDesktop) {
            setIsModalVisible(false);
            return;
        }

        return mobileConfirmSheetRef?.current?.close();
    }, [theme.isWebDesktop]);

    useEffect(() => {
        return () => {
            if (isModalVisible) {
                onClose?.();
            }
        };
    }, [isModalVisible]);

    useEffect(() => {
        if (props.initiallyOpen) {
            mobileConfirmSheetRef?.current?.present();
        }
    }, [props.initiallyOpen]);

    useImperativeHandle(ref, () => {
        return {
            present: () => {
                if (theme.isWebDesktop) {
                    setIsModalVisible(true);
                    return;
                }

                return mobileConfirmSheetRef?.current?.present();
            },
            close: handleOnClose,
            dismiss: handleOnClose,
        };
    });

    if (theme.isWebDesktop) {
        return (
            <DesktopModal isOpen={isModalVisible} onClose={onClose}>
                <S.DesktopModalContainer maxWidth={maxWidth}>
                    <S.TitleContainer>
                        <S.Title>{title}</S.Title>
                        {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
                        {doubleConfirmation && (
                            <S.DoubleConfirmationContainer>
                                <Checkbox checked={doubleConfirmationChecked} onChange={setDoubleConfirmationChecked} />
                                <S.DoubleConfirmationLabelWrapper
                                    onPress={() => setDoubleConfirmationChecked((value) => !value)}
                                >
                                    <S.DoubleConfirmationLabel>{doubleConfirmationLabel}</S.DoubleConfirmationLabel>
                                </S.DoubleConfirmationLabelWrapper>
                            </S.DoubleConfirmationContainer>
                        )}
                    </S.TitleContainer>
                    <S.DesktopButtons>
                        {onClose && (
                            <S.DesktopButton size="m" variant="secondary" onPress={onClose}>
                                {secondaryButtonLabel}
                            </S.DesktopButton>
                        )}
                        {onConfirm && onClose && <S.DesktopButtonSeparator />}
                        {onConfirm && (
                            <S.DesktopButton
                                size="m"
                                variant={variant}
                                onPress={onConfirm}
                                disabled={doubleConfirmation && !doubleConfirmationChecked}
                            >
                                {primaryButtonLabel}
                            </S.DesktopButton>
                        )}
                    </S.DesktopButtons>
                </S.DesktopModalContainer>
            </DesktopModal>
        );
    }

    return <MobileConfirmSheet {...props} ref={mobileConfirmSheetRef} />;
});
