import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { format } from 'date-fns';
import { FC } from 'react';
import { Pressable } from 'react-native';
import { useTheme } from 'styled-components/native';
import { PressableIcon } from '~/components/pressableIcon';
import { LeftTitleNavigation } from '~/components/topNavigation';
import { useTraining } from '~/enhancers/training.context';
import { usePressableState } from '~/hooks/usePressableState';

export interface TrainingTopNavigationProps extends NativeStackHeaderProps {}

export const TrainingTopNavigation: FC<TrainingTopNavigationProps> = ({ navigation }) => {
    const theme = useTheme();
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const { data } = useTraining();

    const subtitle =
        data?.startDate && data?.endDate
            ? `${format(new Date(data.startDate), 'dd.MM.yyyy')} - ${format(new Date(data.endDate), 'dd.MM.yyyy')}`
            : 'W trakcie przygotowywania';

    const handlePress = () => {
        navigation.navigate('TrainingStack', {
            screen: 'TrainingPlanChoice',
        });
    };

    const rightContent = (
        <PressableIcon
            name="chevron-down"
            pressableAnimationProgress={pressableAnimationProgress}
            color={theme.icon.tertiary}
            pressedColor={theme.icon.primary}
            size={24}
        />
    );

    return (
        <Pressable {...pressableProps} onPress={handlePress}>
            <LeftTitleNavigation title="Plan treningowy" subtitle={subtitle} rightContent={rightContent} />
        </Pressable>
    );
};
