import React from 'react';
import Animated from 'react-native-reanimated';
import { Icon, IconProps } from '~/components/icon';

import { LoaderAnimations } from './Loader.animated';
import * as S from './Loader.styled';

export type LoaderSize = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';

export interface LoaderProps extends Pick<IconProps, 'color'> {
    size?: LoaderSize | IconProps['size'];
}

const iconSizeMap: Record<LoaderSize, number> = {
    xs: 12,
    s: 16,
    m: 20,
    l: 24,
    xl: 32,
    '2xl': 40,
    '3xl': 48,
    '4xl': 56,
    '5xl': 64,
};

const AnimatedIcon = Animated.createAnimatedComponent(S.IconWrapper);

export const Loader: React.FC<LoaderProps> = ({ size = 'm', color, ...props }) => {
    const iconSize = iconSizeMap[size as LoaderSize] ?? size;

    return (
        <S.Container {...props}>
            <LoaderAnimations>
                {(animatedStyle) => (
                    <AnimatedIcon style={animatedStyle}>
                        <Icon name="loader" size={iconSize} color={color} />
                    </AnimatedIcon>
                )}
            </LoaderAnimations>
        </S.Container>
    );
};
