import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SubmittableForm } from '~/components/submittableForm/SubmittableForm';
import { SubmitButton } from '~/screens/auth/components/SubmitButton';

import { CodeInput } from '../../../components/CodeInput';
import { SignUpConfirmationFormSchema } from './SignUpConfirmationForm.schema';
import * as S from './SignUpConfirmationForm.styled';
import { FormValues } from './SignUpConfirmationForm.types';

interface PasswordRecoveryFormProps {
    error?: boolean;
    onSubmit: SubmitHandler<FormValues>;
}

export const SignUpConfirmationForm: React.FC<PasswordRecoveryFormProps> = ({ error, onSubmit }) => {
    const { control, handleSubmit, formState, reset } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: yupResolver(SignUpConfirmationFormSchema),
        defaultValues: {
            code: '',
        },
    });

    useEffect(() => {
        if (formState.isValid) {
            handleSubmit(onSubmit)();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.isValid]);

    useEffect(() => {
        if (error) {
            reset();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <S.Container>
            <SubmittableForm
                disabled={!formState.isValid}
                onSubmit={(e) => {
                    handleSubmit(onSubmit)(e);
                }}
            >
                <Controller
                    name="code"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <CodeInput {...field} autoFocus editable={!formState.isSubmitting} onChangeText={onChange} />
                    )}
                />
                <SubmitButton
                    size="m"
                    fullWidth
                    loading={formState.isSubmitting}
                    disabled={!formState.isValid}
                    onPress={handleSubmit(onSubmit)}
                >
                    Zakończ rejestrację
                </SubmitButton>
            </SubmittableForm>
        </S.Container>
    );
};
