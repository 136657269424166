import React, { FC, ReactNode, useState } from 'react';
import Animated, { FadeOut } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { ImageStyledProps } from '~/components/image/Image.types';
import { Skeleton } from '~/components/skeleton';

import * as S from './Image.styled';
import ImagePlaceholder from './assets/image_placeholder.svg';

export interface ImageProps extends ImageStyledProps {
    source?: string;
    placeholder?: ReactNode;
}

const AnimatedLoaderContainer = Animated.createAnimatedComponent(S.LoaderContainer);

export const Image: FC<ImageProps> = ({ source, placeholder, backgroundColor, foregroundColor }) => {
    const [isLoading, setIsLoading] = useState(!!source);
    const [isError, setIsError] = useState(false);
    const theme = useTheme();

    const renderPlaceholder = () => {
        if (placeholder) {
            return placeholder;
        }

        return (
            <ImagePlaceholder
                fill={foregroundColor || theme.fill.level4}
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMax meet"
            />
        );
    };

    const renderImage = () => {
        if (source && !isError) {
            return (
                <S.Image
                    source={{ uri: source }}
                    onLoad={() => setIsLoading(false)}
                    onError={() => {
                        setIsError(true);
                        setIsLoading(false);
                    }}
                />
            );
        }

        return renderPlaceholder();
    };

    const renderLoader = () => {
        if (!isLoading) {
            return null;
        }

        return (
            <AnimatedLoaderContainer exiting={FadeOut}>
                <Skeleton backgroundColor={backgroundColor} foregroundColor={foregroundColor} rx={0} />
            </AnimatedLoaderContainer>
        );
    };

    return (
        <S.Container backgroundColor={backgroundColor}>
            {renderImage()}
            {renderLoader()}
        </S.Container>
    );
};
