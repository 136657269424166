import React, { ReactNode } from 'react';
import { PressableProps } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { Loader } from '~/components/loader';
import { usePressableState } from '~/hooks/usePressableState';
import { IconName } from '~/icons';

import { ButtonBackgroundAnimation } from './Button.animated';
import * as S from './Button.styled';
import { ButtonStyledProps } from './Button.types';

export interface ButtonProps
    extends Partial<ButtonStyledProps>,
        Omit<PressableProps, 'onHoverIn' | 'onHoverOut' | 'onPressIn' | 'onPressOut' | 'disabled'> {
    children?: ReactNode;
    loading?: boolean;
    icon?: IconName;
    disabled?: boolean;
    onHoverIn?: () => void;
    onHoverOut?: () => void;
    textFullWidth?: boolean;
}

const AnimatedContainer = Animated.createAnimatedComponent(S.Container);

export const Button: React.FC<ButtonProps> = ({
    children,
    variant = 'primary',
    disabled,
    loading,
    icon,
    size = 'xs',
    fullWidth,
    textFullWidth,
    ...props
}) => {
    const { pressableProps, pressableAnimationProgress } = usePressableState();
    const theme = useTheme();

    return (
        <ButtonBackgroundAnimation progress={pressableAnimationProgress} variant={variant} disabled={disabled}>
            {(animatedStyle) => (
                <AnimatedContainer
                    {...pressableProps}
                    disabled={disabled}
                    variant={variant}
                    size={size}
                    pointerEvents={loading ? 'none' : 'auto'}
                    fullWidth={fullWidth}
                    {...props}
                    style={[props.style, animatedStyle]}
                >
                    {icon && (
                        <S.IconWrapper size={size} loading={loading} noMargin={!children}>
                            <Icon
                                name={icon}
                                size="100%"
                                color={!disabled ? theme.icon.primary : theme.icon.disabled}
                            />
                        </S.IconWrapper>
                    )}
                    {loading && (
                        <S.LoaderWrapper>
                            <Loader size={size} />
                        </S.LoaderWrapper>
                    )}
                    {children && (
                        <S.Text
                            variant={variant}
                            loading={loading}
                            disabled={disabled}
                            size={size}
                            fullWidth={textFullWidth ?? fullWidth}
                        >
                            {children}
                        </S.Text>
                    )}
                </AnimatedContainer>
            )}
        </ButtonBackgroundAnimation>
    );
};
