import { FC, useState } from 'react';
import { Pressable } from 'react-native';
import {
    Composer,
    ComposerProps,
    IMessage,
    InputToolbar,
    InputToolbarProps,
    Send,
    SendProps,
} from 'react-native-gifted-chat';
import { useTheme } from 'styled-components/native';
import { Icon } from '~/components/icon';
import { PressableIcon } from '~/components/pressableIcon';
import { usePressableState } from '~/hooks/usePressableState';

import * as S from './ChatInput.styled';

export const ChatInput: FC<InputToolbarProps<IMessage>> = ({ ...props }) => {
    const theme = useTheme();
    const [text, setText] = useState('');
    const { pressableProps, pressableAnimationProgress } = usePressableState();

    return (
        <S.Wrapper>
            <InputToolbar
                {...props}
                containerStyle={S.toolbar.mainContainer}
                primaryStyle={S.toolbar.wrapper}
                accessoryStyle={S.toolbar.accessory}
                renderComposer={(composerProps: ComposerProps) => (
                    <Composer
                        {...composerProps}
                        textInputStyle={S.toolbar.textInput}
                        placeholder="Napisz wiadomość"
                        placeholderTextColor={theme.text.tertiary}
                        multiline={false}
                        textInputProps={{
                            value: text,
                            cursorColor: theme.text.primary,
                            selectionColor: theme.text.primary,
                            onChangeText: (e) => {
                                composerProps.onTextChanged(e);
                                setText(e);
                            },
                            onKeyPress: (event) => {
                                if (theme.isWeb && event.keyCode === 13 && text) {
                                    let message = event.target.value;
                                    composerProps.onSend([{ text: message }], true);
                                    setText('');
                                    const target = event.nativeEvent.target;
                                    target.focus();
                                    setTimeout(() => target.focus(), 200);
                                }
                            },
                        }}
                    />
                )}
                renderSend={(sendProps: SendProps<IMessage>) => (
                    <Send
                        {...sendProps}
                        containerStyle={S.toolbar.sendConatiner}
                        disabled={!text}
                        onSend={() => {
                            sendProps.onSend && sendProps.onSend([{ text: text }], true);
                            setText('');
                        }}
                    >
                        <Icon name="send" size={16} color={!text ? theme.icon.disabled : theme.icon.primary} />
                    </Send>
                )}
                renderAccessory={() => (
                    //Add proper implementation when BE is ready
                    <Pressable {...pressableProps} onPress={() => console.log('adding photos')}>
                        <PressableIcon
                            name="add"
                            size={17}
                            pressedColor={theme.icon.primary}
                            color={theme.icon.tertiary}
                            pressableAnimationProgress={pressableAnimationProgress}
                        />
                    </Pressable>
                )}
            />
        </S.Wrapper>
    );
};
