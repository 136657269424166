import BasePopup from 'reactjs-popup';
import { PopupProps as BasePopupProps } from 'reactjs-popup/dist/types';

import * as S from './Popup.styled';

interface PopupProps extends Omit<BasePopupProps, 'open' | 'arrow' | 'modal'> {
    isOpen?: boolean;
    children: React.ReactNode;
}

export const Popup: React.FC<PopupProps> = (props) => {
    return (
        <BasePopup
            on="click"
            closeOnEscape
            closeOnDocumentClick
            repositionOnResize
            keepTooltipInside
            arrow={false}
            {...props}
            open={props.isOpen}
            modal={false}
        >
            <S.Container style={S.BaseStyle.shadow}>{props.children}</S.Container>
        </BasePopup>
    );
};
