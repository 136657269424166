import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormControl } from '~/components/formControl';
import { Input } from '~/components/input';
import { PasswordInput } from '~/components/passwordInput';
import { SubmittableForm } from '~/components/submittableForm/SubmittableForm';
import { Field } from '~/screens/auth/components/Field';
import { SubmitButton } from '~/screens/auth/components/SubmitButton';

import { signInFormSchema } from './SignInForm.schema';
import * as S from './SignInForm.styled';
import { FormValues } from './SignInForm.types';

interface SingInFormProps {
    email?: string;
    onSubmit: SubmitHandler<FormValues>;
}

export const SignInForm: React.FC<SingInFormProps> = ({ email, onSubmit }) => {
    const { control, handleSubmit, formState, reset, setFocus } = useForm<FormValues>({
        mode: 'onBlur',
        resolver: yupResolver(signInFormSchema),
        defaultValues: {
            email: email || '',
            password: '',
        },
    });

    useEffect(() => {
        if (email) {
            reset({ email: email || '' });
        }
    }, [email, reset, setFocus]);

    return (
        <S.Container>
            <SubmittableForm
                disabled={!formState.isValid}
                onSubmit={(e) => {
                    handleSubmit(onSubmit)(e);
                }}
            >
                <Field>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <Input
                                    label="Email"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    textContentType="emailAddress"
                                    autoCapitalize="none"
                                    keyboardType="email-address"
                                    autoComplete="email"
                                    autoCorrect={false}
                                    onChangeText={onChange}
                                />
                            </FormControl>
                        )}
                    />
                </Field>
                <Field last>
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <FormControl error={fieldState.error?.message}>
                                <PasswordInput
                                    label="Hasło"
                                    {...field}
                                    error={!!fieldState.error?.message}
                                    editable={!formState.isSubmitting}
                                    onChangeText={onChange}
                                    textContentType="password"
                                />
                            </FormControl>
                        )}
                    />
                </Field>
                <SubmitButton
                    size="m"
                    fullWidth
                    loading={formState.isSubmitting}
                    disabled={!formState.isValid}
                    onPress={handleSubmit(onSubmit)}
                >
                    Zaloguj
                </SubmitButton>
            </SubmittableForm>
        </S.Container>
    );
};
