import { FC } from 'react';
import { TopNavWrapper, TopNavWrapperProps } from '~/components/wrappers';

import * as S from './LeftTitleNavigation.styled';

interface LeftTitleNavigationProps extends TopNavWrapperProps {
    title: string;
    subtitle?: string;
}

export const LeftTitleNavigation: FC<LeftTitleNavigationProps> = ({ title, subtitle, ...props }) => {
    const leftContent = (
        <S.TitleWrapper>
            <S.Title>{title}</S.Title>
            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        </S.TitleWrapper>
    );

    return <TopNavWrapper variant="leftTitle" leftContent={leftContent} {...props} />;
};
