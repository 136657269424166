import { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { AvatarAccount } from '~/components/avatarAccount';
import { Button } from '~/components/button';
import { Icon } from '~/components/icon';
import { pluralize } from '~/utils/string';

import * as S from './Profile.styled';
import { ProfileProps } from './types';

export const Profile: FC<ProfileProps> = ({
    avatarUri,
    subscription,
    name,
    email,
    onAvatarChoose,
    onSubscriptionRenew,
}) => {
    const theme = useTheme();
    const showSubscriptionButtons = false;

    const renderSubscriptionDetails = () => {
        if (subscription.status === 'active') {
            return (
                <>
                    <S.SubscriptionStatus subscriptionStatus={subscription.status}>Konto aktywne</S.SubscriptionStatus>
                    {!!subscription.daysLeft && (
                        <>
                            , jeszcze {subscription.daysLeft} {pluralize(subscription.daysLeft, 'dzień', 'dni')}
                        </>
                    )}
                </>
            );
        }

        return <S.SubscriptionStatus subscriptionStatus={subscription.status}>Konto wygasło</S.SubscriptionStatus>;
    };

    return (
        <S.Container>
            <S.Content>
                <S.Avatar>
                    <AvatarAccount
                        uri={avatarUri}
                        premium={subscription.status === 'active'}
                        onPress={onAvatarChoose}
                    />
                    <S.AvatarEditButton>
                        {theme.isWebDesktop ? (
                            <Button size="xs" variant="secondary" onPress={onAvatarChoose}>
                                Edytuj zdjęcie
                            </Button>
                        ) : (
                            <S.IconWrapper onPress={onAvatarChoose}>
                                <Icon name="edit" size={12} color={theme.icon.secondary} />
                            </S.IconWrapper>
                        )}
                    </S.AvatarEditButton>
                </S.Avatar>
                <S.DetailsContainer>
                    <S.NameContainer>
                        <S.Name>{name}</S.Name>
                    </S.NameContainer>
                    <S.DetailsRow>
                        <S.Details>{renderSubscriptionDetails()}</S.Details>
                    </S.DetailsRow>
                    <S.DetailsRow>
                        <S.Details>{email}</S.Details>
                    </S.DetailsRow>
                </S.DetailsContainer>
            </S.Content>

            {showSubscriptionButtons && (
                <S.Footer>
                    {subscription.status === 'inactive' && (
                        <Button size="s" variant="error" fullWidth={!theme.isWebDesktop} onPress={onSubscriptionRenew}>
                            Przedłuż współpracę
                        </Button>
                    )}

                    {subscription.status === 'active' && (
                        <Button
                            size="s"
                            variant="secondary"
                            fullWidth={!theme.isWebDesktop}
                            onPress={onSubscriptionRenew}
                        >
                            Zobacz ofertę przedłużenia
                        </Button>
                    )}
                </S.Footer>
            )}
        </S.Container>
    );
};
