import { View } from 'react-native';
import styled from 'styled-components/native';
import { MainLayout as MainLayoutBase } from '~/layouts/web';

export const Header = styled(View)`
    padding: 8px 16px;
`;

export const MainLayout = styled(MainLayoutBase)`
    align-items: center;
    padding-bottom: 76px;
`;
