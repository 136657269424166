import { Pressable, Text, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { css } from 'styled-components';
import styled from 'styled-components/native';
import { mixins } from '~/theme';

export const TopContainer = styled(Animated.View)`
    width: 100%;
    padding: 12px 16px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    // NOTE: prevents glitches on web when scrolling
    top: -1px;
`;

export const MainContainer = styled(Animated.ScrollView)`
    padding: 80px 16px;
`;

export const TopHandleWrapper = styled(View)`
    margin-bottom: 8px;
`;
export const TopContentWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const ContentWrapper = styled(View)`
    padding: 8px 0;
`;
const BaseTitleWrapperStyles = css`
    flex-direction: row;
    align-items: center;
`;

export const TrainingTitleInside = styled(Text)`
    ${mixins.typography.h.semiBold20}
    color: ${({ theme }) => theme.text.primary};
`;

export const CycleTitleInside = styled(Text)`
    ${mixins.typography.h.semiBold20}
    color: ${({ theme }) => theme.text.secondary};
`;

export const TrainingTitleTopWrapper = styled(Animated.View)`
    ${BaseTitleWrapperStyles};
    flex: 1;
    padding-right: 8px;
`;

export const TrainingTitleInsideWrapper = styled(View)`
    ${BaseTitleWrapperStyles};
    flex-wrap: wrap;
`;

export const TrainingHeaderWrapper = styled(View)`
    padding: 16px 0 0;
`;

export const TrainingTitleTop = styled(Text)`
    flex: 1;
    white-space: nowrap;
    ${mixins.typography.h.semiBold18}
    color: ${({ theme }) => theme.text.primary};
`;

export const CycleTitleTop = styled(Text)`
    white-space: nowrap;
    ${mixins.typography.h.semiBold18}
    color: ${({ theme }) => theme.text.secondary};
`;

export const ButtonWrapper = styled(View)`
    flex-direction: row;
`;

const BaseButtonWrapperStyles = css`
    padding: 8px;
    border-radius: 12px;
`;

export const PlayButtonWrapper = styled(Pressable)`
    ${BaseButtonWrapperStyles};
    background-color: ${({ theme }) => theme.interactive.primaryDefault};
    margin-right: 12px;
`;

export const CloseButtonWrapper = styled(Pressable)`
    ${BaseButtonWrapperStyles};
    background-color: ${({ theme }) => theme.fill.level4};
`;

export const ExerciseName = styled(Text)`
    ${mixins.typography.ui.medium14}
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 4px;
`;

export const ExerciseData = styled(Text)`
    ${mixins.typography.ui.regular12}
    color: ${({ theme }) => theme.text.secondary};
`;

export const SectionHeaderContainer = styled(View)`
    padding: 24px 0 8px;
`;

export const SectionHeader = styled(Text)`
    ${mixins.typography.h.semiBold20}
    color: ${({ theme }) => theme.text.primary};
`;

export const SectionSubHeader = styled(Text)<{ withPadding: boolean }>`
    ${mixins.typography.h.medium16}
    color: ${({ theme }) => theme.text.secondary};
    
    ${({ withPadding }) =>
        withPadding &&
        css`
            padding-top: 16px;
        `})}
`;
