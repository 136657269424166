import React from 'react';
import { useTheme } from 'styled-components/native';
import { useAppFeatures } from '~/enhancers/appFeaturesProvider';

import { ChatScreen as ChatScreenMobile } from './ChatScreen.native';
import * as S from './ChatScreen.styled';
import { Chat } from './components/chat';
import { ChatComingSoon } from './components/chatComingSoon';

const title = 'Czat';

export const ChatScreen = () => {
    const { features } = useAppFeatures();
    const theme = useTheme();

    if (!features.chat)
        return <S.MainLayout title={title} noBackground={!theme.isWebDesktop} content={<ChatComingSoon />} />;

    if (!theme.isWebDesktop) return <ChatScreenMobile />;
    return <S.MainLayout title={title} noBackground={!theme.isWebDesktop} content={<Chat />} />;
};
