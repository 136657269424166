import React from 'react';
import { Pressable, PressableProps, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { CheckboxAnimations } from '~/components/checkbox/Checkbox.animated';
import { Icon } from '~/components/icon';

import * as S from './Checkbox.styled';
import { CheckboxStyledProps } from './Checkbox.types';

export interface CheckboxProps extends Omit<CheckboxStyledProps, 'size'>, Omit<PressableProps, 'onPress'> {
    size?: CheckboxStyledProps['size'];
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);
const AnimatedBackground = Animated.createAnimatedComponent(S.Background);
const AnimatedCheckIcon = Animated.createAnimatedComponent(View);

export const Checkbox = React.forwardRef<typeof Pressable | null, CheckboxProps>(
    ({ checked = false, size = 'l', ...props }, _) => {
        const theme = useTheme();

        const checkboxCheckIconSizeMap: Record<NonNullable<CheckboxProps['size']>, number> = {
            s: 18,
            l: 24,
        };

        const handlePress = () => {
            props.onChange?.(!checked);
        };

        return (
            <S.Container {...props} size={size} onPress={handlePress}>
                <CheckboxAnimations checked={checked}>
                    {({ borderAnimatedStyles, backgroundAnimatedStyles, checkIconAnimatedStyles }) => (
                        <>
                            <AnimatedBorder pointerEvents="none" size={size} style={borderAnimatedStyles} />
                            <AnimatedBackground pointerEvents="none" size={size} style={backgroundAnimatedStyles} />
                            <AnimatedCheckIcon pointerEvents="none" style={checkIconAnimatedStyles}>
                                <Icon
                                    name="check-small"
                                    size={checkboxCheckIconSizeMap[size]}
                                    color={theme.icon.primary}
                                />
                            </AnimatedCheckIcon>
                        </>
                    )}
                </CheckboxAnimations>
            </S.Container>
        );
    },
);
