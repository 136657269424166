import { useRoute } from '@react-navigation/native';
import { useGetTrainingRules } from '~/api/training/useGetTrainingRules';
import { Rules } from '~/components/RulesContent/RulesContent.types';
import { RulesContent } from '~/components/rulesContent';
import { useWebDesktopBlockedScreen } from '~/hooks/useWebDesktopBlockedScreen';
import { TrainingStackScreens } from '~/navigation/paths';

import { TrainingRulesScreenRoute } from './TrainingRulesScreen.types';

export const TrainingRulesScreen = () => {
    useWebDesktopBlockedScreen(TrainingStackScreens.screens.TrainingRules);
    const route = useRoute<TrainingRulesScreenRoute['route']>();
    const trainingRulesId = route.params?.trainingRulesId;

    const { data } = useGetTrainingRules(trainingRulesId ?? '');

    return <RulesContent content={data?.content as Rules | undefined} />;
};
