import React from 'react';
import Animated from 'react-native-reanimated';

import { DigitBoxBorderAnimation } from './DigitBox.animated';
import * as S from './DigitBox.styled';
import { DigitBoxStyledProps } from './DigitBox.types';

export interface DigitBoxProps extends DigitBoxStyledProps {
    value: string;
    error?: boolean;
    active?: boolean;
    disabled?: boolean;
}

const AnimatedBorder = Animated.createAnimatedComponent(S.Border);

export const DigitBox: React.FC<DigitBoxProps> = ({ value, error, active = false, disabled, noMargin, ...props }) => (
    <S.Container noMargin={noMargin} disabled={disabled} {...props} pointerEvents="none">
        <S.Value disabled={disabled}>{value}</S.Value>
        <DigitBoxBorderAnimation active={active} disabled={disabled} error={error}>
            {({ borderAnimatedStyles }) => <AnimatedBorder style={borderAnimatedStyles} />}
        </DigitBoxBorderAnimation>
    </S.Container>
);
