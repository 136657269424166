import { useMemo } from 'react';
import { Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { ResponsiveTheme, Theme, breakpoints } from '~/theme';

export interface UseResponsiveThemeProps {
    theme: Theme;
}

export const useResponsiveTheme = ({ theme }: UseResponsiveThemeProps): ResponsiveTheme => {
    const mobile = useMediaQuery({
        minWidth: breakpoints.mobile,
    });
    const tablet = useMediaQuery({
        minWidth: breakpoints.tablet,
    });
    const laptop = useMediaQuery({
        minWidth: breakpoints.laptop,
    });
    const desktop = useMediaQuery({
        minWidth: breakpoints.desktop,
    });
    const desktopLarge = useMediaQuery({
        minWidth: breakpoints.desktopLarge,
    });

    const isWeb = Platform.OS === 'web';

    return useMemo(
        () => ({
            ...theme,
            platform: Platform.OS,
            media: {
                mobile,
                tablet,
                laptop,
                desktop,
                desktopLarge,
            },
            isWebMobile: isWeb && mobile,
            isWebDesktop: isWeb && (desktop || laptop),
            isWebLargeDesktop: isWeb && desktop,
            isWebExtraLargeDesktop: isWeb && desktopLarge,
            isWeb,
        }),
        [desktop, desktopLarge, isWeb, laptop, mobile, tablet, theme],
    );
};
