import { View } from 'react-native';
import styled from 'styled-components/native';

export const TopToastContainer = styled(View)<{
    top: number;
}>`
    top: ${({ top }) => top}px;
    width: 100%;
    background: ${({ theme }) => theme.fill.background};
`;

export const BottomToastContainer = styled(View)<{
    bottom: number;
}>`
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${({ bottom }) => bottom}px;
`;
