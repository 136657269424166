import React, { FC } from 'react';
import { ActivityIcon } from '~/components/activityIcon';

import * as S from './NutritionTile.styled';

interface NutritionTileProps {
    kcal: number;
    fat: number;
    carbs: number;
    protein: number;
    type: 'training' | 'non-training';
}

export const NutritionTile: FC<NutritionTileProps> = ({ kcal, type, protein, carbs, fat }) => {
    return (
        <S.Container>
            <S.TitleContainer>
                <S.Title>{kcal} kcal</S.Title>
                <S.IconWrapper>
                    {type === 'training' ? (
                        <ActivityIcon variant="positive" name="flexed-biceps-3d" />
                    ) : (
                        <ActivityIcon name="sleeping-face-3d" />
                    )}
                </S.IconWrapper>
            </S.TitleContainer>
            <S.Subtitle>Dzienne zapotrzebowanie w dni {type === 'training' ? 'treningowe' : 'nietrenigowe'}</S.Subtitle>
            <S.MacroList>
                <S.MacroItem>
                    <S.MacroTitle>Białko</S.MacroTitle>
                    <S.MacroValue>{protein}g</S.MacroValue>
                </S.MacroItem>
                <S.MacroItem>
                    <S.MacroTitle>Węglowodany</S.MacroTitle>
                    <S.MacroValue>{carbs}g</S.MacroValue>
                </S.MacroItem>
                <S.MacroItem last>
                    <S.MacroTitle>Tłuszcze</S.MacroTitle>
                    <S.MacroValue>{fat}g</S.MacroValue>
                </S.MacroItem>
            </S.MacroList>
        </S.Container>
    );
};
