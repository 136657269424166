import { useState } from 'react';
import { useTheme } from 'styled-components/native';
import { useGetTrainings } from '~/api/training/useGetTrainings';
import { PlanSelectionModal } from '~/components/modal';
import { PeriodSelectionButton } from '~/components/periodSelectionButton';
import { useTraining } from '~/enhancers/training.context';
import { getDateRangeWithPlaceholder } from '~/utils/dates';

import * as S from './TrainingPlanSelection.styled';

export const TrainingPlanSelection = () => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const { data = [] } = useGetTrainings();
    const { data: activeTrainingData, id: activeTrainingId, setTraining } = useTraining();

    const triggerComponent = (
        <S.PeriodSelectionButtonWrapper>
            <PeriodSelectionButton
                isLoading={!activeTrainingData}
                isOpen={isOpen}
                title={getDateRangeWithPlaceholder({
                    startDate: activeTrainingData?.startDate,
                    endDate: activeTrainingData?.endDate,
                    placeholder: 'W trakcie przygotowywania',
                })}
                subtitle="Wybierz poprzednie plany"
                onPress={() => setIsOpen((prev) => !prev)}
            />
        </S.PeriodSelectionButtonWrapper>
    );

    if (!theme.isWebDesktop) return null;

    return (
        <PlanSelectionModal
            isOpen={isOpen}
            data={data.map(({ id, startDate, endDate }) => ({
                id,
                selected: id === activeTrainingId,
                title: getDateRangeWithPlaceholder({
                    startDate,
                    endDate,
                    placeholder: 'W trakcie przygotowywania',
                    dateFormat: 'dd.MM.yyyy',
                }),
                onPress: () => {
                    setTraining(id);
                    setIsOpen(false);
                },
            }))}
            triggerComponent={triggerComponent}
            onClose={() => setIsOpen(false)}
        />
    );
};
