import * as S from './Day.styled';
import { getDayVariant } from './Day.utils';

export const Day = ({ ...props }) => {
    const { marking, date, state, onPress } = props;

    if (state === 'today' && !marking) {
        return (
            <S.DayWrapper onPress={() => onPress(date)}>
                <S.TodayWrapper>
                    <S.TodayText>{date.day}</S.TodayText>
                </S.TodayWrapper>
            </S.DayWrapper>
        );
    }
    return (
        <S.DayWrapper onPress={() => onPress(date)} variant={getDayVariant(marking)}>
            <S.DayText variant={getDayVariant(marking)}>{date.day}</S.DayText>
        </S.DayWrapper>
    );
};
