import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { MainLayout as BaseMainLayout } from '~/layouts';

export const MainLayout = styled(BaseMainLayout)`
    background: ${({ theme }) => theme.fill.level2};
    flex-direction: row;

    ${({ theme }) =>
        !theme.isWebDesktop &&
        css`
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
        `}
`;

export const Footer = styled(View)`
    padding: 24px 16px 48px;
`;

export const PrimaryButtonContainer = styled(View)`
    margin-bottom: 16px;
`;
