import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useDiet } from '~/enhancers/dietContext';
import { MainLayout } from '~/layouts/native';
import { DietNavigationProp } from '~/screens/diet/DietScreen.types';
import { NoDietPlan } from '~/screens/diet/components/noDietPlan';
import { DietPlanButton } from '~/screens/diet/dietSettings/components/dietPlanButton';
import { NutritionTile } from '~/screens/diet/dietSettings/components/nutritionTile';
import { getDateRangeWithPlaceholder } from '~/utils/dates';

import { DietPlanInProgress } from '../components/dietPlanInProgress';
import * as S from './DietSettingsScreen.styled';
import { NutritionDetailsWrapper } from './DietSettingsScreen.styled';

export const DietSettingsScreenContent = () => {
    const { activePlan, isActivePlanInProgress, userHasDietSubscription, selectWeek } = useDiet();
    const navigation = useNavigation<DietNavigationProp>();

    const handleDietBasicsNavigation = () => {
        navigation.navigate('DietStack', {
            screen: 'Basics',
        });
    };

    const handleMetabolismNavigation = () => {
        navigation.navigate('DietStack', {
            screen: 'Metabolism',
        });
    };

    const handleSelectPlanNavigation = () => {
        navigation.navigate('DietStack', {
            screen: 'PlanChoice',
        });
    };

    const renderDietPlanSelectButton = () => (
        <DietPlanButton
            label="Aktualny plan diety"
            title={getDateRangeWithPlaceholder({
                startDate: activePlan?.startDate,
                endDate: activePlan?.endDate,
                placeholder: 'W trakcie przygotowywania',
            })}
            onPress={handleSelectPlanNavigation}
        />
    );

    const renderContent = () => {
        if (!userHasDietSubscription) {
            return (
                <S.EmptyState>
                    <NoDietPlan />
                </S.EmptyState>
            );
        }

        if (isActivePlanInProgress) {
            return (
                <>
                    {renderDietPlanSelectButton()}
                    <S.EmptyState>
                        <DietPlanInProgress />
                    </S.EmptyState>
                </>
            );
        }

        return (
            <>
                {renderDietPlanSelectButton()}
                <S.NutritionDetailsContainer>
                    <NutritionDetailsWrapper>
                        <NutritionTile kcal={3150} fat={98} carbs={420} protein={120} type="training" />
                    </NutritionDetailsWrapper>

                    <NutritionDetailsWrapper last>
                        <NutritionTile kcal={2850} fat={90} carbs={380} protein={110} type="non-training" />
                    </NutritionDetailsWrapper>
                </S.NutritionDetailsContainer>
                <S.ActionsContainer>
                    <S.Button
                        size="small"
                        icon="help-outline"
                        label="Podstawy diety"
                        notification
                        onPress={handleDietBasicsNavigation}
                    />
                    <S.Button
                        last
                        size="small"
                        icon="metabolism"
                        label="Twój metabolizm"
                        onPress={handleMetabolismNavigation}
                    />
                </S.ActionsContainer>
            </>
        );
    };

    return <S.Container>{renderContent()}</S.Container>;
};

export const DietSettingsScreen = () => {
    return (
        <MainLayout scrollable={false}>
            <DietSettingsScreenContent />
        </MainLayout>
    );
};
